/*eslint-disable*/

import React, { Fragment } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Instruction from "components/Instruction/Instruction.js";
import CardText from "components/Card/CardText.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";



// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import CalculationIcon from "@material-ui/icons/Grade";
import BubbleIcon from "@material-ui/icons/BubbleChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/Loyalty";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import RatingIcon from "@material-ui/icons/RateReview";
import FilterIcon from "@material-ui/icons/FilterList";
import Warning from "@material-ui/icons/Warning";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
import SoftwareIcon from "@material-ui/icons/Apps";
import PictureIcon from "@material-ui/icons/Image";
import PriceIcon from "@material-ui/icons/AttachMoney";
import DesignIcon from "@material-ui/icons/DataUsage";
import FeatureIcon from "@material-ui/icons/Settings";
import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";
import ChartIcon from "@material-ui/icons/InsertChart";
import SettingIcon from "@material-ui/icons/Settings";
import EmailIcon from "@material-ui/icons/Email";
import BugReportIcon from "@material-ui/icons/BugReport";
import MessageIcon from "@material-ui/icons/Message";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import SendIcon from "@material-ui/icons/Send";
import GroupIcon from "@material-ui/icons/Group";
import LockIcon from "@material-ui/icons/Lock";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import IndividualEmailIcon from "@material-ui/icons/AlternateEmail";
import ClearIcon from "@material-ui/icons/Clear";




import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar, Pie } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component
import SweetAlert from "react-bootstrap-sweetalert";




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);
const useStylesSweetAlert = makeStyles(stylesSweetAlert);



const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



export default function CreateReport() {

  const classesSweetAlert = useStylesSweetAlert();
  const dashboardClasses = useStylesDashboard();
  const classes = useStyles();


  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Create Report"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])

  // filters
  const [allFilters, setAllFilters] = React.useState([]);

  const [brandItems1, setBrandItems1] = React.useState(['']);
  const [brandSelection1, setBrandSelection1] = React.useState('');

  const [categoryItems1, setCategoryItems1] = React.useState([]);
  const [categorySelection1, setCategorySelection1] = React.useState('');

  const [modelItems1, setModelItems1] = React.useState([]);
  const [modelSelection1, setModelSelection1] = React.useState([]);

  const [websiteItems1, setWebsiteItems1] = React.useState([]);
  const [websiteSelection1, setWebsiteSelection1] = React.useState('');

  const [countryItems1, setCountryItems1] = React.useState([]);
  const [countrySelection1, setCountrySelection1] = React.useState('');

  const [dateStartItem1, setDateStartItem1] = React.useState('');
  const [dateStartSelection1, setDateStartSelection1] = React.useState('');

  const [dateEndItem1, setDateEndItem1] = React.useState('');
  const [dateEndSelection1, setDateEndSelection1] = React.useState('');



  const [brandItems2, setBrandItems2] = React.useState(['']);
  const [brandSelection2, setBrandSelection2] = React.useState('');

  const [categoryItems2, setCategoryItems2] = React.useState([]);
  const [categorySelection2, setCategorySelection2] = React.useState('');

  const [modelItems2, setModelItems2] = React.useState([]);
  const [modelSelection2, setModelSelection2] = React.useState([]);

  const [websiteItems2, setWebsiteItems2] = React.useState([]);
  const [websiteSelection2, setWebsiteSelection2] = React.useState('');

  const [countryItems2, setCountryItems2] = React.useState([]);
  const [countrySelection2, setCountrySelection2] = React.useState('');

  const [dateStartItem2, setDateStartItem2] = React.useState('');
  const [dateStartSelection2, setDateStartSelection2] = React.useState('');

  const [dateEndItem2, setDateEndItem2] = React.useState('');
  const [dateEndSelection2, setDateEndSelection2] = React.useState('');


  const [brandItems3, setBrandItems3] = React.useState(['']);
  const [brandSelection3, setBrandSelection3] = React.useState('');

  const [categoryItems3, setCategoryItems3] = React.useState([]);
  const [categorySelection3, setCategorySelection3] = React.useState('');

  const [modelItems3, setModelItems3] = React.useState([]);
  const [modelSelection3, setModelSelection3] = React.useState([]);

  const [websiteItems3, setWebsiteItems3] = React.useState([]);
  const [websiteSelection3, setWebsiteSelection3] = React.useState('');

  const [countryItems3, setCountryItems3] = React.useState([]);
  const [countrySelection3, setCountrySelection3] = React.useState('');

  const [dateStartItem3, setDateStartItem3] = React.useState('');
  const [dateStartSelection3, setDateStartSelection3] = React.useState('');

  const [dateEndItem3, setDateEndItem3] = React.useState('');
  const [dateEndSelection3, setDateEndSelection3] = React.useState('');


  const [brandItems4, setBrandItems4] = React.useState(['']);
  const [brandSelection4, setBrandSelection4] = React.useState('');

  const [categoryItems4, setCategoryItems4] = React.useState([]);
  const [categorySelection4, setCategorySelection4] = React.useState('');

  const [modelItems4, setModelItems4] = React.useState([]);
  const [modelSelection4, setModelSelection4] = React.useState([]);

  const [websiteItems4, setWebsiteItems4] = React.useState([]);
  const [websiteSelection4, setWebsiteSelection4] = React.useState('');

  const [countryItems4, setCountryItems4] = React.useState([]);
  const [countrySelection4, setCountrySelection4] = React.useState('');

  const [dateStartItem4, setDateStartItem4] = React.useState('');
  const [dateStartSelection4, setDateStartSelection4] = React.useState('');

  const [dateEndItem4, setDateEndItem4] = React.useState('');
  const [dateEndSelection4, setDateEndSelection4] = React.useState('');


  const [brandItems5, setBrandItems5] = React.useState(['']);
  const [brandSelection5, setBrandSelection5] = React.useState('');

  const [categoryItems5, setCategoryItems5] = React.useState([]);
  const [categorySelection5, setCategorySelection5] = React.useState('');

  const [modelItems5, setModelItems5] = React.useState([]);
  const [modelSelection5, setModelSelection5] = React.useState([]);

  const [websiteItems5, setWebsiteItems5] = React.useState([]);
  const [websiteSelection5, setWebsiteSelection5] = React.useState('');

  const [countryItems5, setCountryItems5] = React.useState([]);
  const [countrySelection5, setCountrySelection5] = React.useState('');

  const [dateStartItem5, setDateStartItem5] = React.useState('');
  const [dateStartSelection5, setDateStartSelection5] = React.useState('');

  const [dateEndItem5, setDateEndItem5] = React.useState('');
  const [dateEndSelection5, setDateEndSelection5] = React.useState('');



  const [brandItems6, setBrandItems6] = React.useState(['']);
  const [brandSelection6, setBrandSelection6] = React.useState('');

  const [categoryItems6, setCategoryItems6] = React.useState([]);
  const [categorySelection6, setCategorySelection6] = React.useState('');

  const [modelItems6, setModelItems6] = React.useState([]);
  const [modelSelection6, setModelSelection6] = React.useState([]);

  const [websiteItems6, setWebsiteItems6] = React.useState([]);
  const [websiteSelection6, setWebsiteSelection6] = React.useState('');

  const [countryItems6, setCountryItems6] = React.useState([]);
  const [countrySelection6, setCountrySelection6] = React.useState('');

  const [dateStartItem6, setDateStartItem6] = React.useState('');
  const [dateStartSelection6, setDateStartSelection6] = React.useState('');

  const [dateEndItem6, setDateEndItem6] = React.useState('');
  const [dateEndSelection6, setDateEndSelection6] = React.useState('');

  const [uniqueTopic, setUniqueTopic] = React.useState(false);

  // Radio Selection
  const [publishTiming, setPublishTiming] = React.useState("Weekly");

  // Chart Category Selection
  const [chartCategorySelection, setChartCategorySelection] = React.useState("Basic Overview Monitoring");

  // Chart Selection
  const [chartItems, setChartItems] = React.useState(["SMAT AVG Rating",
                                                              "Website Rating Trend",
                                                              "Positive/Negative Trend",
                                                              "AVG Price Trend",
                                                              "Category Summary",
                                                              "Positive Topics",
                                                              "Negative Topics",
                                                              "Positive Sentiments",
                                                              "Negative Sentiments"]);

const [chartSelection, setChartSelection] = React.useState(["SMAT AVG Rating",
"Website Rating Trend",
"Positive/Negative Trend",
"AVG Price Trend",
"Category Summary",
"Positive Topics",
"Negative Topics",
"Positive Sentiments",
"Negative Sentiments"]);


  // Create Report Table
  const [createReportData, setCreateReportData] = React.useState([]);


   // Alert/Modal
   const [alert, setAlert] = React.useState(null);

   // Email
   const [createReportEmail, setCreateReportEmail] = React.useState('');
   const [createReportEmailList, setCreateReportEmailList] = React.useState('');

   // Publish Timing Date
   const [dateStartPublishingTiming, setDateStartPublishingTiming] = React.useState('');
   const [dateEndPublishingTiming, setDateEndPublishingTiming] = React.useState('');


   // Edit/Delete Group and Code
   const [groupNum, setGroupNum] = React.useState('');
   const [reportCode, setReportCode] = React.useState('');

   // Generate Report Group Number
   const [generateReportGroupNum, setGenerateReportGroupNum] = React.useState('');
   const [generateReportEmail, setGenerateReportEmail] = React.useState('');

   React.useEffect(() => {
  
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.createReport[0].Filters}`).then(function (response) { 
      // handle success

      setAllFilters(response.data.getFilter);

      let brandFilter = response.data.getFilter.map(data => data['Brand']);
      let uniqueBrand = [...new Set(brandFilter)];

      uniqueBrand.unshift("Sony");
      
      setBrandItems1(uniqueBrand);
      setBrandItems2(uniqueBrand);
      setBrandItems3(uniqueBrand);
      setBrandItems4(uniqueBrand);
      setBrandItems5(uniqueBrand);
      setBrandItems6(uniqueBrand);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });
  
  
    }, [])


// Model 1 Filters
    const handleBrand1= event => {
      setBrandSelection1(event.target.value);


      let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

      let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
      uniqueCategory = [...new Set(uniqueCategory)];
      uniqueCategory = uniqueCategory.sort();

      setCategoryItems1(uniqueCategory);

      setCategorySelection1('');
      setModelSelection1([]);
      setWebsiteSelection1('');
      setCountrySelection1('');
      setDateStartSelection1('');
      setDateEndSelection1('');


    };

    
  
    const handleCategory1 = event => {
      setCategorySelection1(event.target.value);



      let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection1);
      modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

      let uniqueModel = modelFilter.map(data => data['Model']);
      uniqueModel = [...new Set(uniqueModel)];
      uniqueModel = uniqueModel.sort();

      setModelItems1(uniqueModel);


      setModelSelection1([]);
      setWebsiteSelection1('');
      setCountrySelection1('');
      setDateStartSelection1('');
      setDateEndSelection1('');
    };


    const handleModel1 = event => {
      setModelSelection1(event.target.value);

      let selectData = event.target.value;


      let websiteFilter = allFilters.filter(data => data['Brand'] === brandSelection1);
      websiteFilter = websiteFilter.filter(data => data['Product.Category'] === categorySelection1);
      websiteFilter = websiteFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );

     

      let uniqueWebsite= websiteFilter.map(data => data['Website']);
      uniqueWebsite = [...new Set(uniqueWebsite)];
      uniqueWebsite = uniqueWebsite.sort();

   

      setWebsiteItems1(uniqueWebsite);


      setWebsiteSelection1('');
      setCountrySelection1('');
      setDateStartSelection1('');
      setDateEndSelection1('');


    };
  
    const handleWebsite1 = event => {
      setWebsiteSelection1(event.target.value);

      let selectData = modelSelection1;
      
      let countryFilter = allFilters.filter(data => data['Brand'] === brandSelection1);
      countryFilter = countryFilter.filter(data => data['Product.Category'] === categorySelection1);
      countryFilter = countryFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );
      countryFilter = countryFilter.filter(data => data['Website'] == event.target.value);

      let uniqueCountry = countryFilter.map(data => data['Country']);
      uniqueCountry = [...new Set(uniqueCountry)];
      uniqueCountry = uniqueCountry.sort();

      setCountryItems1(uniqueCountry);

      setCountrySelection1('');
      setDateStartSelection1('');
      setDateEndSelection1('');
      
    };


    const handleCountry1 = event => {
      setCountrySelection1(event.target.value);


      
      let restGETurl = `${"brandName="}${brandSelection1}${"&modelName="}${modelSelection1}${"&websiteName="}${websiteSelection1}${"&countryName="}${event.target.value}`;
      restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
      restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
    
      const axios = require('axios');
    
      axios.get(`${SMATrestAPI.createReport[0].FiltersDates}${restGETurl}`).then(function (response) { 
      // handle success

   

      setDateStartItem1(response.data.minDate);
      setDateEndItem1(response.data.maxDate);
    
      setDateStartSelection1(response.data.minDate);
      setDateEndSelection1(response.data.maxDate);

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });

      
    };
  

    const handleChangeStartDate1 = event => {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);

      setDateStartSelection1(aDate);

    }

    const handleChangeEndDate1 = event => {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
      
      setDateEndSelection1(aDate);

    }


  let yesterday1 = moment(dateStartItem1, 'YYYY-MM-DD').subtract(1, "day");
  function validStart1(current) {
    return current.isAfter(yesterday1);
  }

    
  let tommorrow1 = moment(dateEndItem1, 'YYYY-MM-DD').add(1, "day");
  function validEnd1(current) {
    return current.isBefore(tommorrow1);
  }  




  // Model 2 Filters
  const handleBrand2= event => {
    setBrandSelection2(event.target.value);


    let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

    let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
    uniqueCategory = [...new Set(uniqueCategory)];
    uniqueCategory = uniqueCategory.sort();

    setCategoryItems2(uniqueCategory);

    setCategorySelection2('');
    setModelSelection2([]);
    setWebsiteSelection2('');
    setCountrySelection2('');
    setDateStartSelection2('');
    setDateEndSelection2('');


  };

  

  const handleCategory2 = event => {
    setCategorySelection2(event.target.value);



    let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection2);
    modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

    let uniqueModel = modelFilter.map(data => data['Model']);
    uniqueModel = [...new Set(uniqueModel)];
    uniqueModel = uniqueModel.sort();

    setModelItems2(uniqueModel);


    setModelSelection2([]);
    setWebsiteSelection2('');
    setCountrySelection2('');
    setDateStartSelection2('');
    setDateEndSelection2('');
  };


  const handleModel2 = event => {
    setModelSelection2(event.target.value);
    

    let selectData = event.target.value;

    let websiteFilter = allFilters.filter(data => data['Brand'] === brandSelection2);
    websiteFilter = websiteFilter.filter(data => data['Product.Category'] === categorySelection2);
    websiteFilter = websiteFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );


    let uniqueWebsite= websiteFilter.map(data => data['Website']);
    uniqueWebsite = [...new Set(uniqueWebsite)];
    uniqueWebsite = uniqueWebsite.sort();


    setWebsiteItems2(uniqueWebsite);


    setWebsiteSelection2('');
    setCountrySelection2('');
    setDateStartSelection2('');
    setDateEndSelection2('');


  };

  const handleWebsite2 = event => {
    setWebsiteSelection2(event.target.value);
    
    let selectData = modelSelection2;
      
    let countryFilter = allFilters.filter(data => data['Brand'] === brandSelection2);
    countryFilter = countryFilter.filter(data => data['Product.Category'] === categorySelection2);
    countryFilter = countryFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );
    countryFilter = countryFilter.filter(data => data['Website'] == event.target.value);


    let uniqueCountry = countryFilter.map(data => data['Country']);
    uniqueCountry = [...new Set(uniqueCountry)];
    uniqueCountry = uniqueCountry.sort();

    setCountryItems2(uniqueCountry);

    setCountrySelection2('');
    setDateStartSelection2('');
    setDateEndSelection2('');
    
  };


  const handleCountry2 = event => {
    setCountrySelection2(event.target.value);

    
    
    let restGETurl = `${"brandName="}${brandSelection2}${"&modelName="}${modelSelection2}${"&websiteName="}${websiteSelection2}${"&countryName="}${event.target.value}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.createReport[0].FiltersDates}${restGETurl}`).then(function (response) { 
    // handle success

    console.table(response.data);

    setDateStartItem2(response.data.minDate);
    setDateEndItem2(response.data.maxDate);
  
    setDateStartSelection2(response.data.minDate);
    setDateEndSelection2(response.data.maxDate);

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

    
  };


  const handleChangeStartDate2 = event => {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);

    setDateStartSelection2(aDate);

  }

  const handleChangeEndDate2 = event => {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
    
    setDateEndSelection2(aDate);

  }


let yesterday2 = moment(dateStartItem2, 'YYYY-MM-DD').subtract(1, "day");
function validStart2(current) {
  return current.isAfter(yesterday2);
}

  
let tommorrow2 = moment(dateEndItem2, 'YYYY-MM-DD').add(1, "day");
function validEnd2(current) {
  return current.isBefore(tommorrow1);
}  


// Model 3 Filters
const handleBrand3= event => {
  setBrandSelection3(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems3(uniqueCategory);

  setCategorySelection3('');
  setModelSelection3([]);
  setWebsiteSelection3('');
  setCountrySelection3('');
  setDateStartSelection3('');
  setDateEndSelection3('');


};



const handleCategory3 = event => {
  setCategorySelection3(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection3);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems3(uniqueModel);


  setModelSelection3([]);
  setWebsiteSelection3('');
  setCountrySelection3('');
  setDateStartSelection3('');
  setDateEndSelection3('');
};


const handleModel3 = event => {
  setModelSelection3(event.target.value);

  let selectData = event.target.value;

  let websiteFilter = allFilters.filter(data => data['Brand'] === brandSelection3);
  websiteFilter = websiteFilter.filter(data => data['Product.Category'] === categorySelection3);
  websiteFilter = websiteFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );


  let uniqueWebsite= websiteFilter.map(data => data['Website']);
  uniqueWebsite = [...new Set(uniqueWebsite)];
  uniqueWebsite = uniqueWebsite.sort();

  

  setWebsiteItems3(uniqueWebsite);


  setWebsiteSelection3('');
  setCountrySelection3('');
  setDateStartSelection3('');
  setDateEndSelection3('');


};

const handleWebsite3 = event => {
  setWebsiteSelection3(event.target.value);
  
  let selectData = modelSelection3;
      
  let countryFilter = allFilters.filter(data => data['Brand'] === brandSelection3);
  countryFilter = countryFilter.filter(data => data['Product.Category'] === categorySelection3);
  countryFilter = countryFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );
  countryFilter = countryFilter.filter(data => data['Website'] == event.target.value);


  let uniqueCountry = countryFilter.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems3(uniqueCountry);

  setCountrySelection3('');
  setDateStartSelection3('');
  setDateEndSelection3('');
  
};


const handleCountry3 = event => {
  setCountrySelection3(event.target.value);

  
  
  let restGETurl = `${"brandName="}${brandSelection3}${"&modelName="}${modelSelection3}${"&websiteName="}${websiteSelection3}${"&countryName="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.createReport[0].FiltersDates}${restGETurl}`).then(function (response) { 
  // handle success

  console.table(response.data);

  setDateStartItem3(response.data.minDate);
  setDateEndItem3(response.data.maxDate);

  setDateStartSelection3(response.data.minDate);
  setDateEndSelection3(response.data.maxDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
};


const handleChangeStartDate3 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);

  setDateStartSelection3(aDate);

}

const handleChangeEndDate3 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);
  
  setDateEndSelection3(aDate);

}


let yesterday3 = moment(dateStartItem3, 'YYYY-MM-DD').subtract(1, "day");
function validStart3(current) {
return current.isAfter(yesterday3);
}


let tommorrow3 = moment(dateEndItem3, 'YYYY-MM-DD').add(1, "day");
function validEnd3(current) {
return current.isBefore(tommorrow3);
}  



// Model 4 Filters
const handleBrand4= event => {
  setBrandSelection4(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems4(uniqueCategory);

  setCategorySelection4('');
  setModelSelection4([]);
  setWebsiteSelection4('');
  setCountrySelection4('');
  setDateStartSelection4('');
  setDateEndSelection4('');


};



const handleCategory4 = event => {
  setCategorySelection4(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection4);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems4(uniqueModel);


  setModelSelection4([]);
  setWebsiteSelection4('');
  setCountrySelection4('');
  setDateStartSelection4('');
  setDateEndSelection4('');
};


const handleModel4 = event => {
  setModelSelection4(event.target.value);

  let selectData = event.target.value;


  let websiteFilter = allFilters.filter(data => data['Brand'] === brandSelection4);
  websiteFilter = websiteFilter.filter(data => data['Product.Category'] === categorySelection4);
  websiteFilter = websiteFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );



  let uniqueWebsite= websiteFilter.map(data => data['Website']);
  uniqueWebsite = [...new Set(uniqueWebsite)];
  uniqueWebsite = uniqueWebsite.sort();



  setWebsiteItems4(uniqueWebsite);


  setWebsiteSelection4('');
  setCountrySelection4('');
  setDateStartSelection4('');
  setDateEndSelection4('');


};

const handleWebsite4 = event => {
  setWebsiteSelection4(event.target.value);
  
  let selectData = modelSelection4;
      
  let countryFilter = allFilters.filter(data => data['Brand'] === brandSelection4);
  countryFilter = countryFilter.filter(data => data['Product.Category'] === categorySelection4);
  countryFilter = countryFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );
  countryFilter = countryFilter.filter(data => data['Website'] == event.target.value);


  let uniqueCountry = countryFilter.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems4(uniqueCountry);

  setCountrySelection4('');
  setDateStartSelection4('');
  setDateEndSelection4('');
  
};


const handleCountry4 = event => {
  setCountrySelection4(event.target.value);

  
  
  let restGETurl = `${"brandName="}${brandSelection4}${"&modelName="}${modelSelection4}${"&websiteName="}${websiteSelection4}${"&countryName="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.createReport[0].FiltersDates}${restGETurl}`).then(function (response) { 
  // handle success

  console.table(response.data);

  setDateStartItem4(response.data.minDate);
  setDateEndItem4(response.data.maxDate);

  setDateStartSelection4(response.data.minDate);
  setDateEndSelection4(response.data.maxDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
};


const handleChangeStartDate4 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);

  setDateStartSelection4(aDate);

}

const handleChangeEndDate4 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);
  
  setDateEndSelection4(aDate);

}


let yesterday4 = moment(dateStartItem4, 'YYYY-MM-DD').subtract(1, "day");
function validStart4(current) {
return current.isAfter(yesterday4);
}


let tommorrow4 = moment(dateEndItem4, 'YYYY-MM-DD').add(1, "day");
function validEnd4(current) {
return current.isBefore(tommorrow4);
}  



// Model 5 Filters
const handleBrand5= event => {
  setBrandSelection5(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems5(uniqueCategory);

  setCategorySelection5('');
  setModelSelection5([]);
  setWebsiteSelection5('');
  setCountrySelection5('');
  setDateStartSelection5('');
  setDateEndSelection5('');


};



const handleCategory5 = event => {
  setCategorySelection5(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection5);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems5(uniqueModel);


  setModelSelection5([]);
  setWebsiteSelection5('');
  setCountrySelection5('');
  setDateStartSelection5('');
  setDateEndSelection5('');
};


const handleModel5 = event => {
  setModelSelection5(event.target.value);

  let selectData = event.target.value;

  let websiteFilter = allFilters.filter(data => data['Brand'] === brandSelection5);
  websiteFilter = websiteFilter.filter(data => data['Product.Category'] === categorySelection5);
  websiteFilter = websiteFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );

  let uniqueWebsite= websiteFilter.map(data => data['Website']);
  uniqueWebsite = [...new Set(uniqueWebsite)];
  uniqueWebsite = uniqueWebsite.sort();

  

  setWebsiteItems5(uniqueWebsite);


  setWebsiteSelection5('');
  setCountrySelection5('');
  setDateStartSelection5('');
  setDateEndSelection5('');


};

const handleWebsite5 = event => {
  setWebsiteSelection5(event.target.value);
  
  let selectData = modelSelection5;
      
  let countryFilter = allFilters.filter(data => data['Brand'] === brandSelection5);
  countryFilter = countryFilter.filter(data => data['Product.Category'] === categorySelection5);
  countryFilter = countryFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );
  countryFilter = countryFilter.filter(data => data['Website'] == event.target.value);


  let uniqueCountry = countryFilter.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems5(uniqueCountry);

  setCountrySelection5('');
  setDateStartSelection5('');
  setDateEndSelection5('');
  
};


const handleCountry5 = event => {
  setCountrySelection5(event.target.value);

  
  
  let restGETurl = `${"brandName="}${brandSelection5}${"&modelName="}${modelSelection5}${"&websiteName="}${websiteSelection5}${"&countryName="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.createReport[0].FiltersDates}${restGETurl}`).then(function (response) { 
  // handle success

  console.table(response.data);

  setDateStartItem5(response.data.minDate);
  setDateEndItem5(response.data.maxDate);

  setDateStartSelection5(response.data.minDate);
  setDateEndSelection5(response.data.maxDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
};


const handleChangeStartDate5 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);

  setDateStartSelection5(aDate);

}

const handleChangeEndDate5 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);
  
  setDateEndSelection5(aDate);

}


let yesterday5 = moment(dateStartItem5, 'YYYY-MM-DD').subtract(1, "day");
function validStart5(current) {
return current.isAfter(yesterday5);
}


let tommorrow5 = moment(dateEndItem5, 'YYYY-MM-DD').add(1, "day");
function validEnd5(current) {
return current.isBefore(tommorrow5);
}  



// Model 6 Filters
const handleBrand6= event => {
  setBrandSelection6(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems6(uniqueCategory);

  setCategorySelection6('');
  setModelSelection6([]);
  setWebsiteSelection6('');
  setCountrySelection6('');
  setDateStartSelection6('');
  setDateEndSelection6('');


};



const handleCategory6 = event => {
  setCategorySelection6(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection6);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems6(uniqueModel);


  setModelSelection6([]);
  setWebsiteSelection6('');
  setCountrySelection6('');
  setDateStartSelection6('');
  setDateEndSelection6('');
};


const handleModel6 = event => {
  setModelSelection6(event.target.value);

  let selectData = event.target.value;

  let websiteFilter = allFilters.filter(data => data['Brand'] === brandSelection6);
  websiteFilter = websiteFilter.filter(data => data['Product.Category'] === categorySelection6);
  websiteFilter = websiteFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );


  let uniqueWebsite= websiteFilter.map(data => data['Website']);
  uniqueWebsite = [...new Set(uniqueWebsite)];
  uniqueWebsite = uniqueWebsite.sort();

  

  setWebsiteItems6(uniqueWebsite);


  setWebsiteSelection6('');
  setCountrySelection6('');
  setDateStartSelection6('');
  setDateEndSelection6('');


};

const handleWebsite6 = event => {
  setWebsiteSelection6(event.target.value);
  
  let selectData = modelSelection6;
      
  let countryFilter = allFilters.filter(data => data['Brand'] === brandSelection6);
  countryFilter = countryFilter.filter(data => data['Product.Category'] === categorySelection6);
  countryFilter = countryFilter.filter(data => data['Model'] == (selectData[0]) || data['Model'] == (selectData[1]) || data['Model'] == (selectData[2]) || data['Model'] == (selectData[3]) || data['Model'] == (selectData[4]) || data['Model'] == (selectData[5]) || data['Model'] == (selectData[6]) || data['Model'] == (selectData[7]) || data['Model'] == (selectData[8]) || data['Model'] == (selectData[9])    );
  countryFilter = countryFilter.filter(data => data['Website'] == event.target.value);

  let uniqueCountry = countryFilter.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems6(uniqueCountry);

  setCountrySelection6('');
  setDateStartSelection6('');
  setDateEndSelection6('');
  
};


const handleCountry6 = event => {
  setCountrySelection6(event.target.value);
  
  let restGETurl = `${"brandName="}${brandSelection6}${"&modelName="}${modelSelection6}${"&websiteName="}${websiteSelection6}${"&countryName="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.createReport[0].FiltersDates}${restGETurl}`).then(function (response) { 
  // handle success

  console.table(response.data);

  setDateStartItem6(response.data.minDate);
  setDateEndItem6(response.data.maxDate);

  setDateStartSelection6(response.data.minDate);
  setDateEndSelection6(response.data.maxDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
};


const handleChangeStartDate6 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);

  setDateStartSelection6(aDate);

}

const handleChangeEndDate6 = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);
  
  setDateEndSelection6(aDate);

}


let yesterday6 = moment(dateStartItem6, 'YYYY-MM-DD').subtract(1, "day");
function validStart6(current) {
return current.isAfter(yesterday6);
}


let tommorrow6 = moment(dateEndItem6, 'YYYY-MM-DD').add(1, "day");
function validEnd6(current) {
return current.isBefore(tommorrow6);
}  



const handleChartCategorySelection = event => {
  setChartCategorySelection(event.target.value);
  
};

const handlechartSelection = event => {
  setChartSelection(event.target.value);
  
};


// Publish Timing Date
const handleChangeStartDatePublishTiming = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);

  setDateStartPublishingTiming(aDate);

}

const handleChangeEndDatePublishTiming  = event => {
  let aDate = event.format();
  aDate = aDate.substring(0, 10);
  
  setDateEndPublishingTiming(aDate);

}


   // Get Data from SMAT REST API
   React.useEffect(() => {
    const axios = require('axios');
    axios.get(SMATrestAPI.createReport[0].createReportData).then(function (response) { 
      // handle success
      setCreateReportData(response.data.createReportTable);

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }, [])




  const onClickRefreshTable = () => {

    const axios = require('axios');
    axios.get(SMATrestAPI.createReport[0].createReportData).then(function (response) { 
      // handle success
      setCreateReportData(response.data.createReportTable);

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }



  // Create Report table - Table using material-table

  const createReportTableColumn = [
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Owner',
      field: 'owner'
    },
    {
      title: 'Group',
      field: 'group'
    },
    {
      title: 'Brand',
      field: 'brand'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Model',
      field: 'model'
    },
    {
      title: 'Website',
      field: 'website'
    },
    {
      title: 'Country',
      field: 'country'
    },
    {
      title: 'Unique Topic',
      field: 'uniqueTopic'
    },
    {
      title: 'Model Date Range',
      field: 'modelDateRange'
    },
    {
      title: 'Publish Enabled',
      field: 'publishEnabled'
    },
    {
      title: 'Publish Date Range',
      field: 'publishDateRange'
    },
    {
      title: 'Publish Timing',
      field: 'publishTiming'
    },
    {
      title: 'Email List',
      field: 'emailList'
    }
    // {
    //   title: 'Chart Category',
    //   field: 'chartCategory'
    // },
    // {
    //   title: 'Chart Selection',
    //   field: 'chartSelection'
    // }
  ];



  const createReportTableDataSet = createReportData.map((data) => {
    return ({
      //id: data.id,
      date: data['Date.submitted'],
      owner: data['Owner'],
      group: data['Group'],
      brand: data['Brand'],
      category: data['Category'],
      model: data['Model.Name'],
      website: data['Website'],
      country: data['Country'],
      uniqueTopic: data['Unique.Topic'],
      modelDateRange: data['Model.Date.Range'],
      publishEnabled: data['Publish.Enabled'],
      publishDateRange: data['Publish.Date.Range'],
      publishTiming: data['Publish.Timing'],
      emailList: data['Email.List']
      // chartCategory: data['Chart.Category'],
      // chartSelection: data['Chart.Selection']

    })
  });





  const onSubmitData2 = (event) => {
    event.preventDefault();


  };



  const successAlert = (titleString, bodyString) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={titleString}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
      >
        {bodyString}
      </SweetAlert>
    );
  };


  const failAlert = (titleString, bodyString) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={titleString}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
      >
        {bodyString}
      </SweetAlert>
    );
  };


  const hideAlert = () => {
    setAlert(null);
  };





  const onClickClearData = event => {


    setBrandSelection1('');
    setCategorySelection1('');
    setModelSelection1([]);
    setWebsiteSelection1('');
    setCountrySelection1('');
    setDateStartSelection1('');
    setDateEndSelection1('');

    setBrandSelection2('');
    setCategorySelection2('');
    setModelSelection2([]);
    setWebsiteSelection2('');
    setCountrySelection2('');
    setDateStartSelection2('');
    setDateEndSelection2('');

    setBrandSelection3('');
    setCategorySelection3('');
    setModelSelection3([]);
    setWebsiteSelection3('');
    setCountrySelection3('');
    setDateStartSelection3('');
    setDateEndSelection3('');

    setBrandSelection4('');
    setCategorySelection4('');
    setModelSelection4([]);
    setWebsiteSelection4('');
    setCountrySelection4('');
    setDateStartSelection4('');
    setDateEndSelection4('');

    setBrandSelection5('');
    setCategorySelection5('');
    setModelSelection5([]);
    setWebsiteSelection5('');
    setCountrySelection5('');
    setDateStartSelection5('');
    setDateEndSelection5('');

    setBrandSelection6('');
    setCategorySelection6('');
    setModelSelection6([]);
    setWebsiteSelection6('');
    setCountrySelection6('');
    setDateStartSelection6('');
    setDateEndSelection6('');


  }


  const onClickSubmitData = event => {


    let restGETurl = `${"publishEnable="}${"TRUE"}${"&publishStartDate="}${dateStartPublishingTiming}${"&publishEndDate="}${dateEndPublishingTiming}${"&publishTiming="}${publishTiming}${"&emailOwner="}${createReportEmail}${"&emailList="}${createReportEmailList}${"&chartCategory="}${chartCategorySelection}${"&chartList="}${chartSelection}${"&uniqueTopic="}${uniqueTopic}${"&brandName1="}${brandSelection1}${"&categoryName1="}${categorySelection1}${"&modelName1="}${modelSelection1}${"&websiteName1="}${websiteSelection1}${"&countryName1="}${countrySelection1}${"&startDate1="}${dateStartSelection1}${"&endDate1="}${dateEndSelection1}${"&brandName2="}${brandSelection2}${"&categoryName2="}${categorySelection2}${"&modelName2="}${modelSelection2}${"&websiteName2="}${websiteSelection2}${"&countryName2="}${countrySelection2}${"&startDate2="}${dateStartSelection2}${"&endDate2="}${dateEndSelection2}${"&brandName3="}${brandSelection3}${"&categoryName3="}${categorySelection3}${"&modelName3="}${modelSelection3}${"&websiteName3="}${websiteSelection3}${"&countryName3="}${countrySelection3}${"&startDate3="}${dateStartSelection3}${"&endDate3="}${dateEndSelection3}${"&brandName4="}${brandSelection4}${"&categoryName4="}${categorySelection4}${"&modelName4="}${modelSelection4}${"&websiteName4="}${websiteSelection4}${"&countryName4="}${countrySelection4}${"&startDate4="}${dateStartSelection4}${"&endDate4="}${dateEndSelection4}${"&brandName5="}${brandSelection5}${"&categoryName5="}${categorySelection5}${"&modelName5="}${modelSelection5}${"&websiteName5="}${websiteSelection5}${"&countryName5="}${countrySelection5}${"&startDate5="}${dateStartSelection5}${"&endDate5="}${dateEndSelection5}${"&brandName6="}${brandSelection6}${"&categoryName6="}${categorySelection6}${"&modelName6="}${modelSelection6}${"&websiteName6="}${websiteSelection6}${"&countryName6="}${countrySelection6}${"&startDate6="}${dateStartSelection6}${"&endDate6="}${dateEndSelection6}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.createReport[0].submitReport}${restGETurl}`).then(function (response) { 
    // handle success
  
    let randomCode = response.data.randomCode;

    successAlert(`${'Report Setting Saved..'}`, `${'The configuration code is : '}${randomCode}`);


  

  
    }).catch(function (error) { 
      // handle error
      failAlert("Error", "");
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }


  
  // const onClickSubmitEdit = () => {

  //   let restGETurl = `${"groupNum="}${groupNum}${"&code="}${reportCode}`;
  //   restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  //   restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  //   const axios = require('axios');
  
  //   axios.get(`${SMATrestAPI.createReport[0].loadSaveConfiguration}${restGETurl}`).then(function (response) { 
  //   // handle success
  //   if(response.data.codeStatus == "OK"){
      
  //     console.table(response.data.createReportTable);
  //     let configurationData = response.data.createReportTable;

  //     let publishDateRange = configurationData.map(data => data['Publish.Date.Range']);
  //     publishDateRange = publishDateRange[0].split(",")
  //     setDateStartPublishingTiming(publishDateRange[0]);
  //     setDateEndPublishingTiming(publishDateRange[1]);

  //     let publishTimingSaved = configurationData.map(data => data['Publish.Timing']);
  //     setPublishTiming(publishTimingSaved[0]);

  //     let emailOwner = configurationData.map(data => data['Owner']); // TODO
  //     setCreateReportEmail(emailOwner[0]);

  //     let emailList = configurationData.map(data => data['Email.List']); // TODO
  //     setCreateReportEmailList(emailList[0]);


  //     let chartCategorySaved = configurationData.map(data => data['Chart.Category']);
  //     setChartCategorySelection(chartCategorySaved[0]);

  //     let chartListSaved = configurationData.map(data => data['Chart.Selection']);
  //     chartListSaved = chartListSaved[0].split(",")
  //     setChartSelection([chartListSaved[0],chartListSaved[1],chartListSaved[2],chartListSaved[3],chartListSaved[4],chartListSaved[5],chartListSaved[6],chartListSaved[7],chartListSaved[8]]);

  //     let uniqueTopicSaved = configurationData.map(data => data['Unique.Topic']);
  //     if(uniqueTopicSaved[0] == "Yes"){
  //       setUniqueTopic(true);
  //     }else{
  //       setUniqueTopic(false);
  //     }

  //     let brandName1Saved = configurationData.map(data => data['Brand']);
  //     setBrandSelection1(brandName1Saved[0]);

  //     let categoryName1Saved = configurationData.map(data => data['Category']);
  //     setCategorySelection1(categoryName1Saved[0]); // TODO: App Crash when attempting to set the category 

  //     let modelName1Saved = configurationData.map(data => data['Model']);
  //     modelName1Saved = modelName1Saved[0].split(",")
  //     setModelSelection1([modelName1Saved[0],modelName1Saved[1],modelName1Saved[2],modelName1Saved[3],modelName1Saved[4],modelName1Saved[5],modelName1Saved[6],modelName1Saved[7],modelName1Saved[8],modelName1Saved[9]]);
  
  //     let websiteName1Saved = configurationData.map(data => data['Website']);
  //     setWebsiteSelection1(websiteName1Saved[0]);

  //     let countryName1Saved = configurationData.map(data => data['Country']);
  //     setCountrySelection1(countryName1Saved[0]);

  //     let modelDateRangeSaved = configurationData.map(data => data['Model.Date.Range']);
  //     modelDateRangeSaved = modelDateRangeSaved[0].split(",")
  //     setDateStartSelection1(modelDateRangeSaved[0]);
  //     setDateEndSelection1(modelDateRangeSaved[1]);


  //     // brandName2
  //     // categoryName2
  //     // modelName2
  //     // websiteName2
  //     // countryName2
  //     // startDate2
  //     // endDate2
  //     // brandName3
  //     // categoryName3
  //     // modelName3
  //     // websiteName3
  //     // countryName3
  //     // startDate3
  //     // endDate3
  //     // brandName4
  //     // categoryName4
  //     // modelName4
  //     // websiteName4
  //     // countryName4
  //     // startDate4
  //     // endDate4
  //     // brandName5
  //     // categoryName5
  //     // modelName5
  //     // websiteName5
  //     // countryName5
  //     // startDate5
  //     // endDate5
  //     // brandName6
  //     // categoryName6
  //     // modelName6
  //     // websiteName6
  //     // countryName6
  //     // startDate6
  //     // endDate6


  //     successAlert(`${'Report Setting Loaded..'}`, `${''}`);
  //   }else{
  //     failAlert("Code does not match Group number", "");
  //   }




  //   }).catch(function (error) { 
  //     // handle error
  //     failAlert("Error", "");
  //     console.log(error);
  //   }).then(function () { 
  //     // always executed
      
  //   });


  // }


  const onClickSubmitDelete = (event) => {
    event.preventDefault();  

    let restGETurl = `${"groupNum="}${groupNum}${"&code="}${reportCode}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.createReport[0].deleteReport}${restGETurl}`).then(function (response) { 
    // handle success

    if(response.data.deleteStatus == "OK"){

      successAlert(`${'Delete Success!'}`, `${'Group #'}${groupNum}${' has been deleted'}`);

    }else{
      failAlert("Delete Failed!", "");
    }
    


    }).catch(function (error) { 
      // handle error
      failAlert("Delete Failed!", "");
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }



  const onClickGenerateReport = (event) => {
    event.preventDefault();  


    let restGETurl = `${"groupNum="}${generateReportGroupNum}${"&email="}${generateReportEmail}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.createReport[0].sendReport}${restGETurl}`).then(function (response) { 
    // handle success

    if(response.data.status == "OK"){

      successAlert(`${'Report Sent!'}`, "");

    }else{
      failAlert("Error!", "");
    }
    


    }).catch(function (error) { 
      // handle error
      failAlert("Delete Failed!", "");
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }


  return (
    <div>

      <GridContainer>
        <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Setting Selection</b> </Button>
        </GridItem>
      </GridContainer>


      <GridContainer>
   
        <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              title= 'Step 1:'
              centered={true}
              headerColor="rose"
              
              tabs={[
                {
                  tabName: "Model-1",
                  tabIcon: ModelIcon,
                    tabContent: (
                      <div>

{/* Model Selection #1 */}
                        <FormControl fullWidth
                              className={
                                classes.selectFormControl
                            }>
                              <InputLabel htmlFor="brand-select"
                                className={
                                  classes.selectLabel
                              }>
                                Select Brand
                              </InputLabel>

                              <Select style={
                                  {minWidth: "100%"}
                                }
                                MenuProps={
                                  {className: classes.selectMenu}
                                }
                                classes={
                                  {select: classes.select}
                                }
                                //multiple
                                autoWidth={false}
                                value={brandSelection1}
                                onChange={handleBrand1}
                                inputProps={
                                  {
                                    name: "brandSelection",
                                    id: "brand-select"
                                  }
                              }>
                                <MenuItem disabled
                                  classes={
                                    {root: classes.selectMenuItem}
                                }>
                                  <b>Single Selection</b>
                                </MenuItem>

                                {
                                brandItems1.map((data, index) => (
                                  <MenuItem key={index}
                                    classes={
                                      {
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }
                                    }
                                    value={data}>
                                    {data} </MenuItem>
                                ))
                              } </Select>
                            </FormControl>

{/* Category Selection #1 */}
                          <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="category-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Category
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={categorySelection1}
                            onChange={handleCategory1}
                            inputProps={
                              {
                                name: "categorySelection",
                                id: "category-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            categoryItems1.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>


{/* Model Selection #1 */}

                      <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="model-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Model
                        </InputLabel>

                        <Select MenuProps={
                            {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                          }
                          classes={
                            {select: classes.select}
                          }
                          multiple
                          autoWidth={false}
                          value={modelSelection1}
                          onChange={handleModel1}
                          inputProps={
                            {
                              name: "modelSelection",
                              id: "model-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Multiple Selection</b>
                          </MenuItem>

                          {
                          modelItems1.map((data, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }
                              }
                              value={data}>
                              {data} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>



{/* Website Selection #1 */}

                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="website-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Website
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={websiteSelection1}
                            onChange={handleWebsite1}
                            inputProps={
                              {
                                name: "websiteSelection",
                                id: "website-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            websiteItems1.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Country Selection #1 */}
                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="country-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Country
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={countrySelection1}
                            onChange={handleCountry1}
                            inputProps={
                              {
                                name: "countrySelection",
                                id: "country-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            countryItems1.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Date Selection #1 */}

                      <GridContainer style={{height: "75px"}}>
                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select Start Date" }}
                              value={moment(dateStartSelection1, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateStartItem1, 'YYYY-MM-DD')}
                              onChange={handleChangeStartDate1}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect
                              isValidDate={ validStart1 } 
                              
                            />
                        </GridItem>

                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select End Date" }}
                              value={moment(dateEndSelection1, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateEndItem1, 'YYYY-MM-DD')}
                              onChange={handleChangeEndDate1}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect 
                              isValidDate={ validEnd1 } 
                            />
                        </GridItem>

                    </GridContainer >
                                  
          
                      </div>
                  )

                  
                },
                {
                  tabName: "Model-2",
                  tabIcon: ModelIcon,
                    tabContent: (
                      <div>

{/* Model Selection #1 */}
                        <FormControl fullWidth
                              className={
                                classes.selectFormControl
                            }>
                              <InputLabel htmlFor="brand-select"
                                className={
                                  classes.selectLabel
                              }>
                                Select Brand
                              </InputLabel>

                              <Select style={
                                  {minWidth: "100%"}
                                }
                                MenuProps={
                                  {className: classes.selectMenu}
                                }
                                classes={
                                  {select: classes.select}
                                }
                                //multiple
                                autoWidth={false}
                                value={brandSelection2}
                                onChange={handleBrand2}
                                inputProps={
                                  {
                                    name: "brandSelection",
                                    id: "brand-select"
                                  }
                              }>
                                <MenuItem disabled
                                  classes={
                                    {root: classes.selectMenuItem}
                                }>
                                  <b>Single Selection</b>
                                </MenuItem>

                                {
                                brandItems2.map((data, index) => (
                                  <MenuItem key={index}
                                    classes={
                                      {
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }
                                    }
                                    value={data}>
                                    {data} </MenuItem>
                                ))
                              } </Select>
                            </FormControl>

{/* Category Selection #2 */}
                          <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="category-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Category
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={categorySelection2}
                            onChange={handleCategory2}
                            inputProps={
                              {
                                name: "categorySelection",
                                id: "category-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            categoryItems2.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>


{/* Model Selection #2 */}

                      <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="model-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Model
                        </InputLabel>

                        <Select MenuProps={
                            {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                          }
                          classes={
                            {select: classes.select}
                          }
                          multiple
                          autoWidth={false}
                          value={modelSelection2}
                          onChange={handleModel2}
                          inputProps={
                            {
                              name: "modelSelection",
                              id: "model-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Multiple Selection</b>
                          </MenuItem>

                          {
                          modelItems2.map((data, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }
                              }
                              value={data}>
                              {data} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>

{/* Website Selection #2 */}

                      <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="website-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Website
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={websiteSelection2}
                            onChange={handleWebsite2}
                            inputProps={
                              {
                                name: "websiteSelection",
                                id: "website-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            websiteItems2.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Country Selection #2 */}
                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="country-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Country
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={countrySelection2}
                            onChange={handleCountry2}
                            inputProps={
                              {
                                name: "countrySelection",
                                id: "country-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            countryItems2.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Date Selection #2 */}

                      <GridContainer style={{height: "75px"}}>
                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select Start Date" }}
                              value={moment(dateStartSelection2, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateStartItem2, 'YYYY-MM-DD')}
                              onChange={handleChangeStartDate2}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect
                              isValidDate={ validStart2 } 
                              
                            />
                        </GridItem>

                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select End Date" }}
                              value={moment(dateEndSelection2, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateEndItem2, 'YYYY-MM-DD')}
                              onChange={handleChangeEndDate2}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect 
                              isValidDate={ validEnd2 } 
                            />
                        </GridItem>

                    </GridContainer >
                                  
          
                      </div>
                  )

                  
                },
                {
                  tabName: "Model-3",
                  tabIcon: ModelIcon,
                    tabContent: (
                      <div>

{/* Model Selection #3 */}
                        <FormControl fullWidth
                              className={
                                classes.selectFormControl
                            }>
                              <InputLabel htmlFor="brand-select"
                                className={
                                  classes.selectLabel
                              }>
                                Select Brand
                              </InputLabel>

                              <Select style={
                                  {minWidth: "100%"}
                                }
                                MenuProps={
                                  {className: classes.selectMenu}
                                }
                                classes={
                                  {select: classes.select}
                                }
                                //multiple
                                autoWidth={false}
                                value={brandSelection3}
                                onChange={handleBrand3}
                                inputProps={
                                  {
                                    name: "brandSelection",
                                    id: "brand-select"
                                  }
                              }>
                                <MenuItem disabled
                                  classes={
                                    {root: classes.selectMenuItem}
                                }>
                                  <b>Single Selection</b>
                                </MenuItem>

                                {
                                brandItems3.map((data, index) => (
                                  <MenuItem key={index}
                                    classes={
                                      {
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }
                                    }
                                    value={data}>
                                    {data} </MenuItem>
                                ))
                              } </Select>
                            </FormControl>

{/* Category Selection #3 */}
                          <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="category-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Category
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={categorySelection3}
                            onChange={handleCategory3}
                            inputProps={
                              {
                                name: "categorySelection",
                                id: "category-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            categoryItems3.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>


{/* Model Selection #3 */}

                      <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="model-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Model
                        </InputLabel>

                        <Select MenuProps={
                            {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                          }
                          classes={
                            {select: classes.select}
                          }
                          multiple
                          autoWidth={false}
                          value={modelSelection3}
                          onChange={handleModel3}
                          inputProps={
                            {
                              name: "modelSelection",
                              id: "model-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Multiple Selection</b>
                          </MenuItem>

                          {
                          modelItems3.map((data, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }
                              }
                              value={data}>
                              {data} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>

{/* Website Selection #3 */}

                      <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="website-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Website
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={websiteSelection3}
                            onChange={handleWebsite3}
                            inputProps={
                              {
                                name: "websiteSelection",
                                id: "website-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            websiteItems3.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>


{/* Country Selection #3 */}
                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="country-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Country
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={countrySelection3}
                            onChange={handleCountry3}
                            inputProps={
                              {
                                name: "countrySelection",
                                id: "country-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            countryItems3.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Date Selection #3 */}

                      <GridContainer style={{height: "75px"}}>
                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select Start Date" }}
                              value={moment(dateStartSelection3, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateStartItem3, 'YYYY-MM-DD')}
                              onChange={handleChangeStartDate3}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect
                              isValidDate={ validStart3 } 
                              
                            />
                        </GridItem>

                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select End Date" }}
                              value={moment(dateEndSelection3, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateEndItem3, 'YYYY-MM-DD')}
                              onChange={handleChangeEndDate3}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect 
                              isValidDate={ validEnd3 } 
                            />
                        </GridItem>

                    </GridContainer >
                                  
          
                      </div>
                  )

                  
                },
                {
                  tabName: "Model-4",
                  tabIcon: ModelIcon,
                    tabContent: (
                      <div>

{/* Model Selection #4 */}
                        <FormControl fullWidth
                              className={
                                classes.selectFormControl
                            }>
                              <InputLabel htmlFor="brand-select"
                                className={
                                  classes.selectLabel
                              }>
                                Select Brand
                              </InputLabel>

                              <Select style={
                                  {minWidth: "100%"}
                                }
                                MenuProps={
                                  {className: classes.selectMenu}
                                }
                                classes={
                                  {select: classes.select}
                                }
                                //multiple
                                autoWidth={false}
                                value={brandSelection4}
                                onChange={handleBrand4}
                                inputProps={
                                  {
                                    name: "brandSelection",
                                    id: "brand-select"
                                  }
                              }>
                                <MenuItem disabled
                                  classes={
                                    {root: classes.selectMenuItem}
                                }>
                                  <b>Single Selection</b>
                                </MenuItem>

                                {
                                brandItems4.map((data, index) => (
                                  <MenuItem key={index}
                                    classes={
                                      {
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }
                                    }
                                    value={data}>
                                    {data} </MenuItem>
                                ))
                              } </Select>
                            </FormControl>

{/* Category Selection #4 */}
                          <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="category-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Category
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={categorySelection4}
                            onChange={handleCategory4}
                            inputProps={
                              {
                                name: "categorySelection",
                                id: "category-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            categoryItems4.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>


{/* Model Selection #4 */}

                      <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="model-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Model
                        </InputLabel>

                        <Select MenuProps={
                            {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                          }
                          classes={
                            {select: classes.select}
                          }
                          multiple
                          autoWidth={false}
                          value={modelSelection4}
                          onChange={handleModel4}
                          inputProps={
                            {
                              name: "modelSelection",
                              id: "model-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Multiple Selection</b>
                          </MenuItem>

                          {
                          modelItems4.map((data, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }
                              }
                              value={data}>
                              {data} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>

{/* Website Selection #4 */}

                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="website-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Website
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={websiteSelection4}
                            onChange={handleWebsite4}
                            inputProps={
                              {
                                name: "websiteSelection",
                                id: "website-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            websiteItems4.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Country Selection #4 */}
                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="country-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Country
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={countrySelection4}
                            onChange={handleCountry4}
                            inputProps={
                              {
                                name: "countrySelection",
                                id: "country-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            countryItems4.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Date Selection #4 */}

                      <GridContainer style={{height: "75px"}}>
                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select Start Date" }}
                              value={moment(dateStartSelection4, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateStartItem4, 'YYYY-MM-DD')}
                              onChange={handleChangeStartDate4}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect
                              isValidDate={ validStart4 } 
                              
                            />
                        </GridItem>

                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select End Date" }}
                              value={moment(dateEndSelection4, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateEndItem4, 'YYYY-MM-DD')}
                              onChange={handleChangeEndDate4}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect 
                              isValidDate={ validEnd4 } 
                            />
                        </GridItem>

                    </GridContainer >
                                  
          
                      </div>
                  )

                  
                },
                {
                  tabName: "Model-5",
                  tabIcon: ModelIcon,
                    tabContent: (
                      <div>

{/* Model Selection #5 */}
                        <FormControl fullWidth
                              className={
                                classes.selectFormControl
                            }>
                              <InputLabel htmlFor="brand-select"
                                className={
                                  classes.selectLabel
                              }>
                                Select Brand
                              </InputLabel>

                              <Select style={
                                  {minWidth: "100%"}
                                }
                                MenuProps={
                                  {className: classes.selectMenu}
                                }
                                classes={
                                  {select: classes.select}
                                }
                                //multiple
                                autoWidth={false}
                                value={brandSelection5}
                                onChange={handleBrand5}
                                inputProps={
                                  {
                                    name: "brandSelection",
                                    id: "brand-select"
                                  }
                              }>
                                <MenuItem disabled
                                  classes={
                                    {root: classes.selectMenuItem}
                                }>
                                  <b>Single Selection</b>
                                </MenuItem>

                                {
                                brandItems5.map((data, index) => (
                                  <MenuItem key={index}
                                    classes={
                                      {
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }
                                    }
                                    value={data}>
                                    {data} </MenuItem>
                                ))
                              } </Select>
                            </FormControl>

{/* Category Selection #5 */}
                          <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="category-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Category
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={categorySelection5}
                            onChange={handleCategory5}
                            inputProps={
                              {
                                name: "categorySelection",
                                id: "category-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            categoryItems5.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>


{/* Model Selection #5 */}

                      <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="model-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Model
                        </InputLabel>

                        <Select MenuProps={
                            {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                          }
                          classes={
                            {select: classes.select}
                          }
                          multiple
                          autoWidth={false}
                          value={modelSelection5}
                          onChange={handleModel5}
                          inputProps={
                            {
                              name: "modelSelection",
                              id: "model-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Multiple Selection</b>
                          </MenuItem>

                          {
                          modelItems5.map((data, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }
                              }
                              value={data}>
                              {data} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>


{/* Website Selection #5 */}

                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="website-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Website
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={websiteSelection5}
                            onChange={handleWebsite5}
                            inputProps={
                              {
                                name: "websiteSelection",
                                id: "website-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            websiteItems5.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Country Selection #5 */}
                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="country-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Country
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={countrySelection5}
                            onChange={handleCountry5}
                            inputProps={
                              {
                                name: "countrySelection",
                                id: "country-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            countryItems5.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Date Selection #5 */}

                      <GridContainer style={{height: "75px"}}>
                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select Start Date" }}
                              value={moment(dateStartSelection5, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateStartItem5, 'YYYY-MM-DD')}
                              onChange={handleChangeStartDate5}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect
                              isValidDate={ validStart5 } 
                              
                            />
                        </GridItem>

                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select End Date" }}
                              value={moment(dateEndSelection5, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateEndItem5, 'YYYY-MM-DD')}
                              onChange={handleChangeEndDate5}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect 
                              isValidDate={ validEnd5 } 
                            />
                        </GridItem>

                    </GridContainer >
                                  
          
                      </div>
                  )

                  
                },
                {
                  tabName: "Model-6",
                  tabIcon: ModelIcon,
                    tabContent: (
                      <div>


{/* Model Selection #6 */}
                        <FormControl fullWidth
                              className={
                                classes.selectFormControl
                            }>
                              <InputLabel htmlFor="brand-select"
                                className={
                                  classes.selectLabel
                              }>
                                Select Brand
                              </InputLabel>

                              <Select style={
                                  {minWidth: "100%"}
                                }
                                MenuProps={
                                  {className: classes.selectMenu}
                                }
                                classes={
                                  {select: classes.select}
                                }
                                //multiple
                                autoWidth={false}
                                value={brandSelection6}
                                onChange={handleBrand6}
                                inputProps={
                                  {
                                    name: "brandSelection",
                                    id: "brand-select"
                                  }
                              }>
                                <MenuItem disabled
                                  classes={
                                    {root: classes.selectMenuItem}
                                }>
                                  <b>Single Selection</b>
                                </MenuItem>

                                {
                                brandItems6.map((data, index) => (
                                  <MenuItem key={index}
                                    classes={
                                      {
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }
                                    }
                                    value={data}>
                                    {data} </MenuItem>
                                ))
                              } </Select>
                            </FormControl>

{/* Category Selection #6 */}
                          <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="category-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Category
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={categorySelection6}
                            onChange={handleCategory6}
                            inputProps={
                              {
                                name: "categorySelection",
                                id: "category-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            categoryItems6.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>


{/* Model Selection #6 */}

                      <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="model-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Model
                        </InputLabel>

                        <Select MenuProps={
                            {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                          }
                          classes={
                            {select: classes.select}
                          }
                          multiple
                          autoWidth={false}
                          value={modelSelection6}
                          onChange={handleModel6}
                          inputProps={
                            {
                              name: "modelSelection",
                              id: "model-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Multiple Selection</b>
                          </MenuItem>

                          {
                          modelItems6.map((data, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }
                              }
                              value={data}>
                              {data} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>


{/* Website Selection #6 */}

                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="website-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Website
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={websiteSelection6}
                            onChange={handleWebsite6}
                            inputProps={
                              {
                                name: "websiteSelection",
                                id: "website-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            websiteItems6.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Country Selection #6 */}
                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="country-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Country
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={countrySelection6}
                            onChange={handleCountry6}
                            inputProps={
                              {
                                name: "countrySelection",
                                id: "country-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            countryItems6.map((data, index) => {
                              return (
                                <MenuItem key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={data}>
                                  {data} </MenuItem>
                              );
                            })
                          } </Select>
                        </FormControl>



{/* Date Selection #6 */}

                      <GridContainer style={{height: "75px"}}>
                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select Start Date" }}
                              value={moment(dateStartSelection6, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateStartItem6, 'YYYY-MM-DD')}
                              onChange={handleChangeStartDate6}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect
                              isValidDate={ validStart6 } 
                              
                            />
                        </GridItem>

                        <GridItem md={6}>
                          <br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select End Date" }}
                              value={moment(dateEndSelection6, 'YYYY-MM-DD')}
                              defaultValue ={moment(dateEndItem6, 'YYYY-MM-DD')}
                              onChange={handleChangeEndDate6}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect 
                              isValidDate={ validEnd6 } 
                            />
                        </GridItem>

                    </GridContainer >

                        
                                  
          
                      </div>
                  )

                  
                }
              ]}
            /> 

          </GridItem>


{/* Chart Selection */}
        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs 
            title= 'Step 2:'
            centered={true}
            headerColor="rose"
            
            tabs={[
              {
                tabName: "Chart Selection",
                tabIcon: ChartIcon,
                  tabContent: (
                    <div>
                      <br/><br/>

{/* Chart Category Selection */}
                      <p><strong>Unique Topic</strong></p>
                      <FormControlLabel 
                    
                        control={
                          <Switch
                            checked={uniqueTopic}
                            onChange={event => setUniqueTopic(event.target.checked)}
                            value="uniqueTopic"
                            
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={uniqueTopic === true ? "ON" : "OFF (Recommended)"}
                      />

                      <br/>  <br/> <br/>

{/* Chart Category Selection */}
                      <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="chartCategory-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Chart Category
                          </InputLabel>

                          <Select MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={chartCategorySelection}
                            onChange={handleChartCategorySelection}
                            inputProps={
                              {
                                name: "countryCategorySelection",
                                id: "chartCategory-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value="Basic Overview Monitoring"
                            >
                              Basic Overview Monitoring
                            </MenuItem>

                             </Select>
                        </FormControl>



{/* Select Charts */} <br/><br/><br/><br/>

                        <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="chart-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Charts
                        </InputLabel>

                        <Select MenuProps={
                            {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                          }
                          classes={
                            {select: classes.select}
                          }
                          multiple
                          autoWidth={false}
                          value={chartSelection}
                          onChange={handlechartSelection}
                          inputProps={
                            {
                              name: "chartSelection",
                              id: "chart-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Multiple Selection</b>
                          </MenuItem>

                          {
                          chartItems.map((data, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                                }
                              }
                              value={data}>
                              {data} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>   

                      <br/><br/><br/><br/><br/>
                                
                    </div>
                )

                
              }
            ]}
          /> 



        </GridItem>


{/* Auto Publishing Configuration */}
        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            title= 'Step 3:'
            centered={true}
            headerColor="rose"
            
            tabs={[
              {
                tabName: "Auto Publishing Configuration",
                tabIcon: SettingIcon,
                  tabContent: (
                    <div>

                      <form>
                        <CustomInput
                          labelText="Email Address"
                          id="email_address"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "email",
                            onBlur: event => {setCreateReportEmail(event.target.value)},
                            endAdornment: (<InputAdornment position="end"><IndividualEmailIcon/></InputAdornment>)
                          }}
                        />

                        <br/><br/><br/>

                        <CustomInput
                          labelText="Email List"
                          id="email_list"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "text",
                            onBlur: event => {setCreateReportEmailList(event.target.value)},
                            endAdornment: (<InputAdornment position="end"><EmailIcon/></InputAdornment>)
                          }}
                        />
 
                        
                    </form>

                    <GridContainer style={{height: "75px"}}>
                        <GridItem md={6}>
                          <br/><br/><br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select Start Date" }}
                              value={dateStartPublishingTiming}
                              defaultValue ={moment('YYYY-MM-DD').add(1,'days')}
                              onChange={handleChangeStartDatePublishTiming}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect
                              //isValidDate={ validStart6 } 
                              
                            />
                        </GridItem>

                        <GridItem md={6}>
                          <br/><br/><br/>
                          <Datetime 
                              timeFormat={false}
                              inputProps={{ placeholder: "Select End Date" }}
                              value={dateEndPublishingTiming}
                              defaultValue ={moment('YYYY-MM-DD').add(1,'days')}
                              onChange={handleChangeEndDatePublishTiming}
                              dateFormat='YYYY-MM-DD'
                              closeOnSelect 
                              //isValidDate={ validEnd6 } 
                            />
                        </GridItem>

                    </GridContainer >

                    <br/><br/><br/><br/><br/>
                    <p><strong>Publish Timing</strong></p>

                    <FormControlLabel
                      control={
                        <Radio
                          checked={publishTiming === "Weekly"}
                          onChange={() => setPublishTiming("Weekly")}
                          value="Weekly"
                          name="Publish Timing"
                          aria-label="Weekly"
                          icon={<FiberManualRecord className={classes.radioUnchecked} />}
                          checkedIcon={
                            <FiberManualRecord className={classes.radioChecked} />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Weekly"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          checked={publishTiming === "Bi-Weekly"}
                          onChange={() => setPublishTiming("Bi-Weekly")}
                          value="Bi-Weekly"
                          name="Publish Timing"
                          aria-label="Bi-Weekly"
                          icon={<FiberManualRecord className={classes.radioUnchecked} />}
                          checkedIcon={
                            <FiberManualRecord className={classes.radioChecked} />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Bi-Weekly"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          checked={publishTiming === "Monthly"}
                          onChange={() => setPublishTiming("Monthly")}
                          value="Monthly"
                          name="Publish Timing"
                          aria-label="Monthly"
                          icon={<FiberManualRecord className={classes.radioUnchecked} />}
                          checkedIcon={
                            <FiberManualRecord className={classes.radioChecked} />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label="Monthly"
                    />
                                
                                
                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>
          
      </GridContainer>



      <GridContainer>
        <GridItem 
          xs={12}
          container
          direction="row"
          justify="flex-end"
          alignItems="center">

          <Button color="behance" onClick={onClickClearData}>
            <ClearIcon className={classes.icons} />
              Clear Model Selection
          </Button> 
          
          {/* <Button color="warning" >
            <SaveIcon className={classes.icons} />
              Save Edit
          </Button>  */}

          <Button color="pinterest" onClick={onClickSubmitData}>
            <SendIcon className={classes.icons} />
              Submit Request
          </Button>


        
      

      </GridItem>
      </GridContainer>




      <GridContainer>

        <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Report</b> </Button>
        </GridItem>

      </GridContainer>
      


{/* Edit / Delete */}
      <GridContainer>
   
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle} style={{color: "white"}}><strong>Delete Report</strong></h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* onSubmit={e => { e.preventDefault()  */}
              <form  id='editDelete-form' > 
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Group #</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="groupID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Example: 22",
                        onBlur: event => {setGroupNum(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><GroupIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Code</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="codeID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        //placeholder: "Example: Add drill down for all charts within the competitor tab",
                        onBlur: event => {setReportCode(event.target.value)},
                        type: "password",
                        autoComplete: "off",
                        endAdornment: (<InputAdornment position="end"><LockIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>



              </form>
            </CardBody>

            <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                {/* <Button color="warning" form='editDelete-form' type="submit" onClick={onClickSubmitEdit}>
                  <EditIcon className={classes.icons} />
                    Edit
                </Button> */}
                <Button color="pinterest" form='editDelete-form' type="submit" onClick={onClickSubmitDelete} >
                  <DeleteIcon className={classes.icons} />
                    Delete
                </Button>
                
              

              </GridItem>

            <br/>
          </Card>
        </GridItem>

{/* Create Report */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle} style={{color: "white"}}><strong>Create Report</strong></h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* onSubmit={e => { e.preventDefault()  */}
              <form  id='createReportGroup-form' > 
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Group #</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="createReportGroupID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Example: 22",
                        onBlur: event => {setGenerateReportGroupNum(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><GroupIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>

                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Email</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="createReportGroupID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Example: John.Doe@sony.com",
                        onBlur: event => {setGenerateReportEmail(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><EmailIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>

                </GridContainer>


              </form>
            </CardBody>

            <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                <Button color="pinterest" form='createReportGroup-form' type="submit" onClick={onClickGenerateReport} disabled >
                  <DownloadIcon className={classes.icons} />
                    Generate Report
                </Button>
                
              

              </GridItem>

            <br/>
          </Card>
        </GridItem>

        {/* Sweet Alert  */}
        {alert}
        
      </GridContainer>



      
      <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Configuration Table</b> </Button>
      </GridItem>



      {/* Table */}

      <GridContainer>
   
      <GridItem xs={12} sm={12} md={12} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            
            tabs={[
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                                
                    <div style={{ maxWidth: '100%' }}>
                            <MaterialTable
                              columns={createReportTableColumn}
                              data={createReportTableDataSet}
                              title="Model List"
                              options={{
                                exportButton: true,
                                exportAllData: true,
                                filtering: true,
                                showTitle: false,
                                pageSize: 10
                              }}

                            />
                      </div>



                        <br/>
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                  
                          <CSVLink data={createReportData} filename={"Create Report - Report List.csv"}>
                              <Button color="warning"> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>

                          </CSVLink>

                          <Button color="pinterest" onClick={onClickRefreshTable}>
                            <RefreshIcon className={classes.icons} />
                              Refresh Table
                          </Button>



              

                        </GridItem>

                      </GridContainer>

                  </div>
                )

                
              }
            ]}
          /> 

        </GridItem>
        
      </GridContainer>





    </div>
  );
}
