/*eslint-disable*/

import React, { Fragment } from "react";

// CSS
import "../../assets/css/page-loading.css"
import customCSS from "../StarRatingDashboard/CustomStyle.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import NavPills from "components/NavPills/NavPills.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";



// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import CalculationIcon from "@material-ui/icons/Grade";
import BubbleIcon from "@material-ui/icons/BubbleChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/LocalOffer"; //"@material-ui/icons/Loyalty";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import RatingIcon from "@material-ui/icons/RateReview";
import PriceIcon from "@material-ui/icons/AttachMoney";
import BasicIcon from "@material-ui/icons/FilterList";
import AdvanceIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
//import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Bubble, Bar } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment";
import MaterialTable from 'material-table' // 3rd Party Table Component



const useStyles = makeStyles(styles);

export default function StarRatingDashboard() {


// SMAT Log
React.useEffect(() => {
  let restGETurl = `${"smatCategory="}${"Star Rating Dashboard"}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');
  axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  // Get Master Model Table 
  axios.get(SMATrestAPI.generalFilter[0].allModelsPrice).then(function (response) { 

    // handle success
    //localStorage.setItem("modelList", JSON.stringify(response.data.getFilter));
    setFullModelList(response.data.getFilter);

    let retrievedModelList = response.data.getFilter
    let uniqueBrand = [...new Set(retrievedModelList.map(x => x.Brand))];
    uniqueBrand.unshift("Sony");

    setBrandItemsTrend(uniqueBrand);


    // Filter Master Table for Amazon Models only
    let result2 = retrievedModelList.filter((data) => data.Website === "Amazon");
    setFullModelList2(result2);

    let uniqueBrand2 = [...new Set(result2.map(x => x.Brand))];
    uniqueBrand2.unshift("Sony");

    setBrandItemsTrend2(uniqueBrand2);



    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });

}, [])  


////////////////////////////////////////////////////////////////////////////////////////////////
//////  Bubble Start

  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");
  
  const [isLoading, setIsLoading] = React.useState(false);

  const [websiteItems, setWebsiteItems] = React.useState([]);
  const [websiteSelection, setWebsiteSelection] = React.useState([]);
  const websiteSelectionRef = React.useRef();

  const [categoryItems, setCategoryItems] = React.useState([]);
  const [categorySelection, setCategorySelection] = React.useState([]);

  const [countryItems, setCountryItems] = React.useState([]);
  const [countrySelection, setCountrySelection] = React.useState([]);

  const [calculationItems, setCalculationItems] = React.useState([]);
  const [calculationSelection, setCalculationSelection] = React.useState("");

  const [dateStartItemTrend2, setDateStartItemTrend2] = React.useState('');
  const [dateStartSelectionTrend2, setDateStartSelectionTrend2] = React.useState('');

  const [dateEndItemTrend2, setDateEndItemTrend2] = React.useState('');
  const [dateEndSelectionTrend2, setDateEndSelectionTrend2] = React.useState('');

  
  const handleWebsite = event => {
    setWebsiteSelection(event.target.value);
  };

  const handleCategory = event => {
    setCategorySelection(event.target.value);
  };

  const handleCountry = event => {
    setCountrySelection(event.target.value);
  };

  
  const handleCalculation = event => {
    setCalculationSelection(event.target.value);
  };



  const handleDateStartTrend2 = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
    
      setDateStartSelectionTrend2(aDate);
    }
  
  }
  
  const handleDateEndTrend2 = event => {
  
    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
      
      setDateEndSelectionTrend2(aDate);
    }
  
  
  }
  
  
  
  var yesterday2 = moment(dateStartItemTrend2, 'YYYY-MM-DD').subtract(1, "day");
  function validStart2(current) {
    return current.isAfter(yesterday2);
  }
  
  var tommorrow2 = moment(dateEndItemTrend2, 'YYYY-MM-DD').add(1, "day");
  function validEnd2(current) {
    return current.isBefore(tommorrow2);
  }
  

  
 
  // Get Filter Data from SMAT REST API
  React.useEffect(() => {
    const axios = require('axios');

    axios.get(SMATrestAPI.starRatingDashboard[0].filter1).then(function (response) { 
      // handle success
      setWebsiteItems(response.data.WebsiteData)
      setCategoryItems(response.data.CategoryData)
      setCountryItems(response.data.CountryData)
      setCalculationItems(["SMAT", "SMAT Unique", "Website"])

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


    axios.get(`${SMATrestAPI.starRatingDashboard[0].trendFilterDate}`).then(function (response) { 
      // handle success
      setDateStartItemTrend2(response.data.get_starRatingDashboard_trend_min_date_filter);
      setDateEndItemTrend2(response.data.get_starRatingDashboard_trend_max_date_filter);

      setDateStartSelectionTrend2(response.data.get_starRatingDashboard_trend_min_date_filter);
      setDateEndSelectionTrend2(response.data.get_starRatingDashboard_trend_max_date_filter);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }, [])


  // Bubble Chart Data using Chartjs2
  const [bubbleRESTData, setBubbleRESTData] = React.useState([]);

  const onClickBubbleChartData = () => {
    setIsLoading(true);

    var restGETurl = `${"Website="}${websiteSelection.join()}${"&Category="}${categorySelection.join()}${"&Country="}${countrySelection.join()}${"&Calculation="}${calculationSelection}${"&startDate="}${dateStartSelectionTrend2}${"&endDate="}${dateEndSelectionTrend2}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.starRatingDashboard[0].bubbleChart}${restGETurl}`).then(function (response) { 

      setBubbleRESTData(response.data.bubbleData)

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


    setTimeout(() => {  setIsLoading(false); }, 1000);

  }

  const bubbleData = {
    datasets: 
      bubbleRESTData.map((bubbleRESTData) => {
        return ({
          label: bubbleRESTData.brand,
          data: [{
            x: bubbleRESTData.reviewCount,
            y: bubbleRESTData.starRating,
            r: bubbleRESTData.size
          }],
          backgroundColor: bubbleRESTData.color
        })
    
      })

  }

  const bubbleOptions = {
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Rating'
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Review Count'
        }
      }],
    },
    maintainAspectRatio: false ,
    animation: {duration: 0 }
  }


  const BubbleChart = () => (
    <>
      <Bubble 
      data={bubbleData} 
      options={bubbleOptions} 
      height={430}
      />
    </>
  )

  // Bubble Table using react-data-table-component
  const bubbleTableData = 
    bubbleRESTData.map((bubbleRESTData) => {
      return ({
        id: bubbleRESTData.id,
        brand: bubbleRESTData.brand,
        starRating: bubbleRESTData.starRating,
        reviewCount: bubbleRESTData.reviewCount,
        modelCount: bubbleRESTData.modelCount
      })
    });
    
  const bubbleTableColumn = [
    {
      name: 'Brand',
      selector: 'brand',
      sortable: true,
      left: true
    },
    {
      name: 'Star Rating',
      selector: 'starRating',
      sortable: true,
      center: true
    },
    {
      name: 'Review Count',
      selector: 'reviewCount',
      sortable: true,
      center: true
    },
    {
      name: 'Model Count',
      selector: 'modelCount',
      sortable: true,
      center: true
    }
  ];


  const conditionalRowStyles = [
    {
      when: row => (row.starRating >= 0 && row.starRating <= 2.9),
      style: {
        backgroundColor: '#811818',
        color: 'white'
      }
    },
    {
      when: row => (row.starRating >= 3 && row.starRating <= 3.9),
      style: {
        backgroundColor: '#7a6305',
        color: 'white'
      }
    },
    {
      when: row => (row.starRating >= 4 && row.starRating <= 5),
      style: {
        backgroundColor: '#135328',
        color: 'white'
      }
    }
    
  ];


  const BubbleTable = () => (
    <>
      <DataTable
          title=""
          columns={bubbleTableColumn}
          data={bubbleTableData}
          //conditionalRowStyles={conditionalRowStyles}
          // pagination={true}
          // paginationPerPage={10}
          // paginationComponentOptions={{ noRowsPerPage: true }}
          fixedHeader={true}
          //fixedHeaderScrollHeight={"445px"}
          fixedHeaderScrollHeight={"360px"}
          noHeader={true}
        />
    </>
  )
  
////////////////////////////////////////////////////////////////////////////////////////////////
//////  Bubble End


////////////////////////////////////////////////////////////////////////////////////////////////
//////  Trend Start

const [fullModelList, setFullModelList] = React.useState(['']);

const [brandItemsTrend, setBrandItemsTrend] = React.useState([]);
const [brandSelectionTrend, setBrandSelectionTrend] = React.useState('');

const [categoryItemsTrend, setCategoryItemsTrend] = React.useState([]);
const [categorySelectionTrend, setCategorySelectionTrend] = React.useState('');

const [modelItemsTrend, setModelItemsTrend] = React.useState([]);
const [modelSelectionTrend, setModelSelectionTrend] = React.useState([]);

const [websiteItemsTrend, setWebsiteItemsTrend] = React.useState([]);
const [websiteSelectionTrend, setWebsiteSelectionTrend] = React.useState('');

const [countryItemsTrend, setCountryItemsTrend] = React.useState([]);
const [countrySelectionTrend, setCountrySelectionTrend] = React.useState('');

const [dateStartItemTrend, setDateStartItemTrend] = React.useState('');
const [dateStartSelectionTrend, setDateStartSelectionTrend] = React.useState('');

const [dateEndItemTrend, setDateEndItemTrend] = React.useState('');
const [dateEndSelectionTrend, setDateEndSelectionTrend] = React.useState('');


// Advanced Model Search
const [dateStartItemTrend3, setDateStartItemTrend3] = React.useState('');
const [dateStartSelectionTrend3, setDateStartSelectionTrend3] = React.useState('');

const [dateEndItemTrend3, setDateEndItemTrend3] = React.useState('');
const [dateEndSelectionTrend3, setDateEndSelectionTrend3] = React.useState('');



// Amazon Star Rating - Review + No Review Filter

const [fullModelList2, setFullModelList2] = React.useState(['']);

const [brandItemsTrend2, setBrandItemsTrend2] = React.useState([]);
const [brandSelectionTrend2, setBrandSelectionTrend2] = React.useState('');

const [categoryItemsTrend2, setCategoryItemsTrend2] = React.useState([]);
const [categorySelectionTrend2, setCategorySelectionTrend2] = React.useState('');

const [modelItemsTrend2, setModelItemsTrend2] = React.useState([]);
const [modelSelectionTrend2, setModelSelectionTrend2] = React.useState([]);

const [websiteItemsTrend2, setWebsiteItemsTrend2] = React.useState([]);
const [websiteSelectionTrend2, setWebsiteSelectionTrend2] = React.useState('');

const [countryItemsTrend2, setCountryItemsTrend2] = React.useState([]);
const [countrySelectionTrend2, setCountrySelectionTrend2] = React.useState('');

const [dateStartItemTrend2a, setDateStartItemTrend2a] = React.useState('');
const [dateStartSelectionTrend2a, setDateStartSelectionTrend2a] = React.useState('');

const [dateEndItemTrend2a, setDateEndItemTrend2a] = React.useState('');
const [dateEndSelectionTrend2a, setDateEndSelectionTrend2a] = React.useState('');

const [starRatingData, setStarRatingData] = React.useState([]);
const [starRatingDataRaw, setStarRatingDataRaw] = React.useState([]);

const handleBrandTrend2 = event => {
  setBrandSelectionTrend2(event.target.value);
  setCategorySelectionTrend2('');
  setModelSelectionTrend2([]);
  setWebsiteSelectionTrend2('');
  setCountrySelectionTrend2('');
  setDateStartSelectionTrend2('');
  setDateEndSelectionTrend2('');
};

const handleCategoryTrend2 = event => {
  setCategorySelectionTrend2(event.target.value);
  setModelSelectionTrend2([]);
  setWebsiteSelectionTrend2('');
  setCountrySelectionTrend2('');
  setDateStartSelectionTrend2a('');
  setDateEndSelectionTrend2a('');
};

const handleModelTrend2 = event => {
  setModelSelectionTrend2(event.target.value);
  setWebsiteSelectionTrend2('');
  setCountrySelectionTrend2('');
  setDateStartSelectionTrend2a('');
  setDateEndSelectionTrend2a('');
};


const handleWebsiteTrend2 = event => {
  setWebsiteSelectionTrend2(event.target.value);
  setCountrySelectionTrend2('');
  setDateStartSelectionTrend2('');
  setDateEndSelectionTrend2('');
};

const handleCountryTrend2 = event => {
  setCountrySelectionTrend2(event.target.value);
  setDateStartSelectionTrend2('');
  setDateEndSelectionTrend2('');
};

const handleDateStartTrend2a = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  
    setDateStartSelectionTrend2a(aDate);
  }

}

const handleDateEndTrend2a = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
    
    setDateEndSelectionTrend2a(aDate);
  }


}

var yesterday2a = moment(dateStartItemTrend2a, 'YYYY-MM-DD').subtract(1, "day");
function validStart2a(current) {
  return current.isAfter(yesterday2a);
}

var tommorrow2a = moment(dateEndItemTrend2a, 'YYYY-MM-DD').add(1, "day");
function validEnd2a(current) {
  return current.isBefore(tommorrow2a);
}



// Get Filter Data from SMAT REST API - Category
React.useEffect(() => {


  if(brandSelectionTrend2 !== ''){
    let result = fullModelList2.filter(x => x.Brand === brandSelectionTrend2);

    result = [...new Set(result.map(x => x['Product Category']))];

    setCategoryItemsTrend2(result);

  }
}, [brandSelectionTrend2])



// Get Filter Data from SMAT REST API - Model
React.useEffect(() => {

  console.log(brandSelectionTrend2)
  console.log(categorySelectionTrend2)

if(categorySelectionTrend2 !== ''){

  let result = fullModelList2.filter(x => x.Brand === brandSelectionTrend2);

  result = result.filter(x => x['Product Category'] === categorySelectionTrend2);

  result = [...new Set(result.map(x => x['Model']))];

  setModelItemsTrend2(result);

}

}, [categorySelectionTrend2])



// Get Filter Data from SMAT REST API - Website
React.useEffect(() => {


if(modelSelectionTrend2 !== ''){

  let result = fullModelList2.filter(x => x.Brand === brandSelectionTrend2);
  result = result.filter(x => x['Product Category'] === categorySelectionTrend2);
  result = result.filter(x => x['Model'] == modelSelectionTrend2[0] || x['Model'] == modelSelectionTrend2[1] || x['Model'] == modelSelectionTrend2[2] || x['Model'] == modelSelectionTrend2[3] || x['Model'] == modelSelectionTrend2[4] || x['Model'] == modelSelectionTrend2[5] || x['Model'] == modelSelectionTrend2[6] || x['Model'] == modelSelectionTrend2[7] || x['Model'] == modelSelectionTrend2[8] || x['Model'] == modelSelectionTrend2[9]);
  result = [...new Set(result.map(x => x['Website']))];

  setWebsiteItemsTrend2(result);

}


}, [modelSelectionTrend2])



// Get Filter Data from SMAT REST API - Country
React.useEffect(() => {


if(categorySelectionTrend2 !== ''){

  let result = fullModelList2.filter(x => x.Brand === brandSelectionTrend2);
  result = result.filter(x => x['Product Category'] === categorySelectionTrend2);
  result = result.filter(x => x['Model'] == modelSelectionTrend2[0] || x['Model'] == modelSelectionTrend2[1] || x['Model'] == modelSelectionTrend2[2] || x['Model'] == modelSelectionTrend2[3] || x['Model'] == modelSelectionTrend2[4] || x['Model'] == modelSelectionTrend2[5] || x['Model'] == modelSelectionTrend2[6] || x['Model'] == modelSelectionTrend2[7] || x['Model'] == modelSelectionTrend2[8] || x['Model'] == modelSelectionTrend2[9]);
  result = result.filter(x => x['Website'] == websiteSelectionTrend2);
  result = [...new Set(result.map(x => x['Country']))];
  setCountryItemsTrend2(result);

}
}, [websiteSelectionTrend2])


// Get Filter Data from SMAT REST API - Date
React.useEffect(() => {


if(categorySelectionTrend2 !== ''){

  var restGETurl = `${"brand="}${brandSelectionTrend2}${"&category="}${categorySelectionTrend2}${"&model="}${modelSelectionTrend2}${"&website="}${websiteSelectionTrend2}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.starRatingDashboard[0].trendFilterDate2}`).then(function (response) { 
    // handle success
    setDateStartItemTrend2a(response.data.get_starRatingDashboard_trend_min_date_filter);
    setDateEndItemTrend2a(response.data.get_starRatingDashboard_trend_max_date_filter);

    setDateStartSelectionTrend2a(response.data.get_starRatingDashboard_trend_min_date_filter);
    setDateEndSelectionTrend2a(response.data.get_starRatingDashboard_trend_max_date_filter);

    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

}
}, [countrySelectionTrend2])


//////////////////////////////////////


const handleBrandTrend= event => {
  setBrandSelectionTrend(event.target.value);
  setCategorySelectionTrend('');
  setModelSelectionTrend([]);
  setWebsiteSelectionTrend('');
  setCountrySelectionTrend('');
  setDateStartSelectionTrend('');
  setDateEndSelectionTrend('');
};

const handleCategoryTrend = event => {
  setCategorySelectionTrend(event.target.value);
  setModelSelectionTrend([]);
  setWebsiteSelectionTrend('');
  setCountrySelectionTrend('');
  setDateStartSelectionTrend('');
  setDateEndSelectionTrend('');
};

const handleModelTrend = event => {
  setModelSelectionTrend(event.target.value);
  setWebsiteSelectionTrend('');
  setCountrySelectionTrend('');
  setDateStartSelectionTrend('');
  setDateEndSelectionTrend('');
};


const handleWebsiteTrend = event => {
  setWebsiteSelectionTrend(event.target.value);
  setCountrySelectionTrend('');
  setDateStartSelectionTrend('');
  setDateEndSelectionTrend('');
};

const handleCountryTrend = event => {
  setCountrySelectionTrend(event.target.value);
  setDateStartSelectionTrend('');
  setDateEndSelectionTrend('');
};

// const handleDateStartTrend = event => {
//   let startDate = event._d;
//   startDate = new Date(event._d);
//   let year = startDate.getFullYear();
//   let month = startDate.getMonth()+1;
//   let dt = startDate.getDate();

//   if (dt < 10) {
//     dt = '0' + dt;
//   }
//   if (month < 10) {
//     month = '0' + month;
//   }

//   startDate = (year+'-' + month + '-'+dt);

//   //console.log(startDate)

//   if(isNaN(startDate) == false){
//     setDateStartSelectionTrend(startDate);
//   }


// };

// const handleDateEndTrend = event => {
//   let endDate = event._d;
//   endDate = new Date(event._d);
//   let year = endDate.getFullYear();
//   let month = endDate.getMonth()+1;
//   let dt = endDate.getDate();

//   if (dt < 10) {
//     dt = '0' + dt;
//   }
//   if (month < 10) {
//     month = '0' + month;
//   }

//   endDate = (year+'-' + month + '-'+dt);

//   setDateEndSelectionTrend(endDate);

//   if(isNaN(endDate) == false){
//     setDateEndSelectionTrend(endDate);
//   }

// };

const handleDateStartTrend = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  
    setDateStartSelectionTrend(aDate);
  }

}

const handleDateEndTrend = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
    
    setDateEndSelectionTrend(aDate);
  }


}



var yesterday = moment(dateStartItemTrend, 'YYYY-MM-DD').subtract(1, "day");
function validStart(current) {
  return current.isAfter(yesterday);
}

var tommorrow = moment(dateEndItemTrend, 'YYYY-MM-DD').add(1, "day");
function validEnd(current) {
  return current.isBefore(tommorrow);
}




// // Get Filter Data from SMAT REST API - Brand
// React.useEffect(() => {
//   const axios = require('axios');

//   axios.get(SMATrestAPI.starRatingDashboard[0].trendFilterBrand).then(function (response) { 
//     // handle success
//     //brandItemsTrend(response.data.WebsiteData)
//     //setBrandItemsTrend(response.data.get_starRatingDashboard_trend_brand_filter);


//     let brandList = response.data.get_starRatingDashboard_trend_brand_filter
//     brandList.unshift("Sony");
//     setBrandItemsTrend(brandList);
    
//   }).catch(function (error) { 
//     // handle error
//     console.log(error);
//   }).then(function () { 
//     // always executed
//   });

// }, [])


// Get Filter Data from SMAT REST API - Category
  React.useEffect(() => {


    if(brandSelectionTrend !== ''){
      let result = fullModelList.filter(x => x.Brand === brandSelectionTrend);
      result = [...new Set(result.map(x => x['Product Category']))];

      setCategoryItemsTrend(result);

    }
  }, [brandSelectionTrend])



// Get Filter Data from SMAT REST API - Model
React.useEffect(() => {


  if(categorySelectionTrend !== ''){

    let result = fullModelList.filter(x => x.Brand === brandSelectionTrend);
    result = result.filter(x => x['Product Category'] === categorySelectionTrend);
    result = [...new Set(result.map(x => x['Model']))];

    setModelItemsTrend(result);

  }

}, [categorySelectionTrend])



// Get Filter Data from SMAT REST API - Website
React.useEffect(() => {


  if(modelSelectionTrend !== ''){

    let result = fullModelList.filter(x => x.Brand === brandSelectionTrend);
    result = result.filter(x => x['Product Category'] === categorySelectionTrend);
    result = result.filter(x => x['Model'] == modelSelectionTrend[0] || x['Model'] == modelSelectionTrend[1] || x['Model'] == modelSelectionTrend[2] || x['Model'] == modelSelectionTrend[3] || x['Model'] == modelSelectionTrend[4] || x['Model'] == modelSelectionTrend[5] || x['Model'] == modelSelectionTrend[6] || x['Model'] == modelSelectionTrend[7] || x['Model'] == modelSelectionTrend[8] || x['Model'] == modelSelectionTrend[9]);
    result = [...new Set(result.map(x => x['Website']))];

    setWebsiteItemsTrend(result);

  }


}, [modelSelectionTrend])



// Get Filter Data from SMAT REST API - Country
React.useEffect(() => {


  if(categorySelectionTrend !== ''){

    let result = fullModelList.filter(x => x.Brand === brandSelectionTrend);
    result = result.filter(x => x['Product Category'] === categorySelectionTrend);
    result = result.filter(x => x['Model'] == modelSelectionTrend[0] || x['Model'] == modelSelectionTrend[1] || x['Model'] == modelSelectionTrend[2] || x['Model'] == modelSelectionTrend[3] || x['Model'] == modelSelectionTrend[4] || x['Model'] == modelSelectionTrend[5] || x['Model'] == modelSelectionTrend[6] || x['Model'] == modelSelectionTrend[7] || x['Model'] == modelSelectionTrend[8] || x['Model'] == modelSelectionTrend[9]);
    result = result.filter(x => x['Website'] == websiteSelectionTrend);
    result = [...new Set(result.map(x => x['Country']))];
    setCountryItemsTrend(result);

  }
}, [websiteSelectionTrend])


// Get Filter Data from SMAT REST API - Date
React.useEffect(() => {


  if(categorySelectionTrend !== ''){

    var restGETurl = `${"brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.starRatingDashboard[0].trendFilterDate}`).then(function (response) { 
      // handle success
      setDateStartItemTrend(response.data.get_starRatingDashboard_trend_min_date_filter);
      setDateEndItemTrend(response.data.get_starRatingDashboard_trend_max_date_filter);

      setDateStartSelectionTrend(response.data.get_starRatingDashboard_trend_min_date_filter);
      setDateEndSelectionTrend(response.data.get_starRatingDashboard_trend_max_date_filter);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }
}, [countrySelectionTrend])



// Get Trend Data via SMAT RESTAPI
const [trendIsLoading, setTrendIsLoading] = React.useState(false);

const [websiteRatingTrendData, setWebsiteRatingTrendData] = React.useState(['']);
const [websiteRatingTrendTableData, setWebsiteRatingTrendTableData] = React.useState(['']);

const [smatUniqueRatingTrendData, setSmatUniqueRatingTrendData] = React.useState(['']);
const [smatUniqueRatingTrendTableData, setSmatUniqueRatingTrendTableData] = React.useState(['']);

const [smatRatingTrendData, setSmatRatingTrendData] = React.useState(['']);
const [smatRatingTrendTableData, setSmatRatingTrendTableData] = React.useState(['']);

const [priceTrendData, setPriceTrendData] = React.useState(['']);
const [priceTrendTableData, setPriceTrendTableData] = React.useState(['']);

const [ratingCountTrendData, setRatingCountTrendData] = React.useState(['']);
const [ratingCountTrendTableData, setRatingCountTrendTableData] = React.useState(['']);

const [pricePointTrendTableData, setPricePointTrendTableData] = React.useState(['']);

const onClickTrendChartData = () => {
  setTrendIsLoading(true);

  const axios = require('axios');

  // Send Model Information to SMAT DB after user clicks submit
  var restGETurl0 = `${"page="}${"Star Rating Dashboard"}${"&brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}${"&country="}${countrySelectionTrend}`;
  restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
  restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl0}`).then(function (response) { 


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  // Website Rating Trend
  var restGETurl1 = `${"brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}${"&country="}${countrySelectionTrend}${"&startDate="}${dateStartSelectionTrend}${"&endDate="}${dateEndSelectionTrend}`;
  console.log(restGETurl1);
  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingDashboard[0].websiteRatingData}${restGETurl1}`).then(function (response) { 
    
    setWebsiteRatingTrendData(response.data.get_starRatingDashboard_websiteRatingChartData);
    setWebsiteRatingTrendTableData(response.data.get_starRatingDashboard_websiteRatingTableData);


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });


  // SMAT Unique Rating Trend
  var restGETurl2 = `${"brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}${"&country="}${countrySelectionTrend}${"&startDate="}${dateStartSelectionTrend}${"&endDate="}${dateEndSelectionTrend}`;
  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingDashboard[0].smatUniqueRatingData}${restGETurl2}`).then(function (response) { 

    setSmatUniqueRatingTrendData(response.data.chartData);
    setSmatUniqueRatingTrendTableData(response.data.tableData);


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });


  // SMAT Rating Trend
  var restGETurl3 = `${"brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}${"&country="}${countrySelectionTrend}${"&startDate="}${dateStartSelectionTrend}${"&endDate="}${dateEndSelectionTrend}`;
  restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
  restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingDashboard[0].smatRatingData}${restGETurl3}`).then(function (response) { 

    setSmatRatingTrendData(response.data.chartData);
    setSmatRatingTrendTableData(response.data.tableData);


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });


  // Price Trend
  var restGETurl4 = `${"brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}${"&country="}${countrySelectionTrend}${"&startDate="}${dateStartSelectionTrend}${"&endDate="}${dateEndSelectionTrend}`;
  restGETurl4 = restGETurl4.replaceAll(",", '%2C'); // replace comma for url
  restGETurl4 = restGETurl4.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingDashboard[0].priceData}${restGETurl4}`).then(function (response) { 

    setPriceTrendData(response.data.chartData);
    setPriceTrendTableData(response.data.tableData);


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });


  // Rating Count
  var restGETurl5 = `${"brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}${"&country="}${countrySelectionTrend}${"&startDate="}${dateStartSelectionTrend}${"&endDate="}${dateEndSelectionTrend}`;
  restGETurl5 = restGETurl5.replaceAll(",", '%2C'); // replace comma for url
  restGETurl5 = restGETurl5.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingDashboard[0].ratingCountData}${restGETurl5}`).then(function (response) { 

    setRatingCountTrendData(response.data.chartData);
    setRatingCountTrendTableData(response.data.tableData);


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });


  // Price Point
  var restGETurl6 = `${"brand="}${brandSelectionTrend}${"&category="}${categorySelectionTrend}${"&model="}${modelSelectionTrend}${"&website="}${websiteSelectionTrend}${"&country="}${countrySelectionTrend}${"&startDate="}${dateStartSelectionTrend}${"&endDate="}${dateEndSelectionTrend}`;
  restGETurl6 = restGETurl6.replaceAll(",", '%2C'); // replace comma for url
  restGETurl6 = restGETurl6.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingDashboard[0].pricePointData}${restGETurl6}`).then(function (response) { 
    setPricePointTrendTableData(response.data.tableData);


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });


  setTimeout(() => {  setTrendIsLoading(false); }, 1000);

}


const onClickTrendChartDataAdvance = () => {
  console.table(savedModelList);

  let brandListSelection = [...new Set(savedModelList.map(data => data.brand))];
  let categoryListSelection = [...new Set(savedModelList.map(data => data.category))];
  let modelListSelection = [...new Set(savedModelList.map(data => data.model))];
  let websiteListSelection = [...new Set(savedModelList.map(data => data.website))];
  let countryListSelection = [...new Set(savedModelList.map(data => data.country))];


  const axios = require('axios');

  // Send Model Information to SMAT DB after user clicks submit
  // var restGETurl0 = `${"page="}${"Star Rating Dashboard"}${"&brand="}${brandListSelection}${"&category="}${categoryListSelection}${"&model="}${modelListSelection}${"&website="}${websiteListSelection}${"&country="}${countryListSelection}`;
  // restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
  // restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

  // axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl0}`).then(function (response) { 


  // }).catch(function (error) { 
  //   // handle error
  //   console.log(error);
  // }).then(function () { 
  //   // always executed
  // });

  // Website Rating Trend
  var restGETurl1 = `${"brand="}${brandListSelection}${"&category="}${categoryListSelection}${"&model="}${modelListSelection}${"&website="}${websiteListSelection}${"&country="}${countryListSelection}${"&startDate="}${dateStartSelectionTrend}${"&endDate="}${dateEndSelectionTrend}`;
  console.log(restGETurl1);
  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  console.log(restGETurl1);

  axios.get(`${SMATrestAPI.starRatingDashboard[0].websiteRatingData}${restGETurl1}`).then(function (response) { 
    
    setWebsiteRatingTrendData(response.data.get_starRatingDashboard_websiteRatingChartData);
    setWebsiteRatingTrendTableData(response.data.get_starRatingDashboard_websiteRatingTableData);


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });





  
  setTrendIsLoading(true);





  setTimeout(() => {  setTrendIsLoading(false); }, 1000);

}

// Website Rating Trend
let getWebsiteRatingTrendLabel = websiteRatingTrendData.map(data => data.Date);
let getWebsiteRatingTrendData1 = websiteRatingTrendData.map(data => data[modelSelectionTrend[0]]);
let getWebsiteRatingTrendData2 = websiteRatingTrendData.map(data => data[modelSelectionTrend[1]]);
let getWebsiteRatingTrendData3 = websiteRatingTrendData.map(data => data[modelSelectionTrend[2]]);
let getWebsiteRatingTrendData4 = websiteRatingTrendData.map(data => data[modelSelectionTrend[3]]);
let getWebsiteRatingTrendData5 = websiteRatingTrendData.map(data => data[modelSelectionTrend[4]]);



let websiteRatingTrendDataSet = {};

switch(modelSelectionTrend.length) {
  case 1:
    websiteRatingTrendDataSet = {
      labels: getWebsiteRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getWebsiteRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },
    
      ]
    
    }
    break;
  case 2:
    websiteRatingTrendDataSet = {
      labels: getWebsiteRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getWebsiteRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getWebsiteRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        }
    
      ]
    
    }
    break;
    case 3:
    websiteRatingTrendDataSet = {
      labels: getWebsiteRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getWebsiteRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getWebsiteRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getWebsiteRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        }
    
      ]
    
    }
    break;
    case 4:
    websiteRatingTrendDataSet = {
      labels: getWebsiteRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getWebsiteRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getWebsiteRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getWebsiteRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getWebsiteRatingTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        }
    
      ]
    
    }
    break;
    case 5:
    websiteRatingTrendDataSet = {
      labels: getWebsiteRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getWebsiteRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getWebsiteRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getWebsiteRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getWebsiteRatingTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        },

        {
          label: modelSelectionTrend[4],
          data: getWebsiteRatingTrendData5,
          fill: false,
          backgroundColor: 'rgb(255, 99, 99)',
          borderColor: 'rgba(255, 99, 99, 0.6)'
        }
    
      ]
    
    }
    break;
  default:
    // code block
}

const options = {
  spanGaps: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}



// Website Rating Trend Table using react-data-table-component

// websiteRatingTrendTableData

const websiteRatingTrendTableDataSet = 
websiteRatingTrendTableData.map((websiteTrendData) => {
    return ({
      id: websiteTrendData.id,
      date: websiteTrendData.Date,
      model: websiteTrendData.Model,
      starRating: websiteTrendData['Star Rating']
    })
  });
  
const websiteRatingTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Model',
    selector: 'model',
    sortable: true,
    center: true
  },
  {
    name: 'Star Rating',
    selector: 'starRating',
    sortable: true,
    center: true
  }
];




// SMAT Unique Rating Trend
let getSmatUniqueRatingTrendLabel = smatUniqueRatingTrendData.map(data => data.Date);
let getSmatUniqueRatingTrendData1 = smatUniqueRatingTrendData.map(data => data[modelSelectionTrend[0]]);
let getSmatUniqueRatingTrendData2 = smatUniqueRatingTrendData.map(data => data[modelSelectionTrend[1]]);
let getSmatUniqueRatingTrendData3 = smatUniqueRatingTrendData.map(data => data[modelSelectionTrend[2]]);
let getSmatUniqueRatingTrendData4 = smatUniqueRatingTrendData.map(data => data[modelSelectionTrend[3]]);
let getSmatUniqueRatingTrendData5 = smatUniqueRatingTrendData.map(data => data[modelSelectionTrend[4]]);



let smatUniqueRatingTrendDataSet = {};

switch(modelSelectionTrend.length) {
  case 1:
    smatUniqueRatingTrendDataSet = {
      labels: getSmatUniqueRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatUniqueRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },
    
      ]
    
    }
    break;
  case 2:
    smatUniqueRatingTrendDataSet = {
      labels: getSmatUniqueRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatUniqueRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatUniqueRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        }
    
      ]
    
    }
    break;
    case 3:
    smatUniqueRatingTrendDataSet = {
      labels: getSmatUniqueRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatUniqueRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatUniqueRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getSmatUniqueRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        }
    
      ]
    
    }
    break;
    case 4:
    smatUniqueRatingTrendDataSet = {
      labels: getSmatUniqueRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatUniqueRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatUniqueRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getSmatUniqueRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getSmatUniqueRatingTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        }
    
      ]
    
    }
    break;
    case 5:
    smatUniqueRatingTrendDataSet = {
      labels: getSmatUniqueRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatUniqueRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatUniqueRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getSmatUniqueRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getSmatUniqueRatingTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        },

        {
          label: modelSelectionTrend[4],
          data: getSmatUniqueRatingTrendData5,
          fill: false,
          backgroundColor: 'rgb(255, 99, 99)',
          borderColor: 'rgba(255, 99, 99, 0.6)'
        }
    
      ]
    
    }
    break;
  default:
    // code block
}

const smatUniqueLineoptions = {
  spanGaps: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}



// SMAT Unique Rating Trend Table using react-data-table-component

const smatUniqueRatingTrendTableDataSet = 
smatUniqueRatingTrendTableData.map((smatUniqueTrendData) => {
    return ({
      id: smatUniqueTrendData.id,
      date: smatUniqueTrendData.Date,
      model: smatUniqueTrendData.Model,
      starRating: smatUniqueTrendData['SMAT_Unique']
    })
  });
  
const smatUniqueRatingTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Model',
    selector: 'model',
    sortable: true,
    center: true
  },
  {
    name: 'Star Rating',
    selector: 'starRating',
    sortable: true,
    center: true
  }
];



// SMAT Rating Trend
let getSmatRatingTrendLabel = smatRatingTrendData.map(data => data.Date);
let getSmatRatingTrendData1 = smatRatingTrendData.map(data => data[modelSelectionTrend[0]]);
let getSmatRatingTrendData2 = smatRatingTrendData.map(data => data[modelSelectionTrend[1]]);
let getSmatRatingTrendData3 = smatRatingTrendData.map(data => data[modelSelectionTrend[2]]);
let getSmatRatingTrendData4 = smatRatingTrendData.map(data => data[modelSelectionTrend[3]]);
let getSmatRatingTrendData5 = smatRatingTrendData.map(data => data[modelSelectionTrend[4]]);



let smatRatingTrendDataSet = {};

switch(modelSelectionTrend.length) {
  case 1:
    smatRatingTrendDataSet = {
      labels: getSmatRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },
    
      ]
    
    }
    break;
  case 2:
    smatRatingTrendDataSet = {
      labels: getSmatRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        }
    
      ]
    
    }
    break;
    case 3:
    smatRatingTrendDataSet = {
      labels: getSmatRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getSmatRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        }
    
      ]
    
    }
    break;
    case 4:
    smatRatingTrendDataSet = {
      labels: getSmatRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getSmatRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getSmatRatingTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        }
    
      ]
    
    }
    break;
    case 5:
    smatRatingTrendDataSet = {
      labels: getSmatRatingTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getSmatRatingTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getSmatRatingTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getSmatRatingTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getSmatRatingTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        },

        {
          label: modelSelectionTrend[4],
          data: getSmatRatingTrendData5,
          fill: false,
          backgroundColor: 'rgb(255, 99, 99)',
          borderColor: 'rgba(255, 99, 99, 0.6)'
        }
    
      ]
    
    }
    break;
  default:
    // code block
}

const smatLineoptions = {
  spanGaps: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// SMAT Rating Trend Table using react-data-table-component

const smatRatingTrendTableDataSet = 
smatRatingTrendTableData.map((smatTrendData) => {
    return ({
      id: smatTrendData.id,
      date: smatTrendData.Date,
      model: smatTrendData.Model,
      starRating: smatTrendData['SMAT']
    })
  });
  
const smatRatingTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Model',
    selector: 'model',
    sortable: true,
    center: true
  },
  {
    name: 'Star Rating',
    selector: 'starRating',
    sortable: true,
    center: true
  }
];


// Price Trend
let getPriceTrendLabel = priceTrendData.map(data => data.Date);
let getPriceTrendData1 = priceTrendData.map(data => data[modelSelectionTrend[0]]);
let getPriceTrendData2 = priceTrendData.map(data => data[modelSelectionTrend[1]]);
let getPriceTrendData3 = priceTrendData.map(data => data[modelSelectionTrend[2]]);
let getPriceTrendData4 = priceTrendData.map(data => data[modelSelectionTrend[3]]);
let getPriceTrendData5 = priceTrendData.map(data => data[modelSelectionTrend[4]]);



let priceTrendDataSet = {};

switch(modelSelectionTrend.length) {
  case 1:
    priceTrendDataSet = {
      labels: getPriceTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getPriceTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },
    
      ]
    
    }
    break;
  case 2:
    priceTrendDataSet = {
      labels: getPriceTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getPriceTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getPriceTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        }
    
      ]
    
    }
    break;
    case 3:
    priceTrendDataSet = {
      labels: getPriceTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getPriceTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getPriceTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getPriceTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        }
    
      ]
    
    }
    break;
    case 4:
    priceTrendDataSet = {
      labels: getPriceTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getPriceTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getPriceTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getPriceTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getPriceTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        }
    
      ]
    
    }
    break;
    case 5:
    priceTrendDataSet = {
      labels: getPriceTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getPriceTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getPriceTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getPriceTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getPriceTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        },

        {
          label: modelSelectionTrend[4],
          data: getPriceTrendData5,
          fill: false,
          backgroundColor: 'rgb(255, 99, 99)',
          borderColor: 'rgba(255, 99, 99, 0.6)'
        }
    
      ]
    
    }
    break;
  default:
    // code block
}

const priceLineoptions = {
  spanGaps: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}



// Price Trend Table using react-data-table-component

const priceTrendTableDataSet = 
priceTrendTableData.map((PriceTrendData) => {
    return ({
      id: PriceTrendData.id,
      date: PriceTrendData.Date,
      model: PriceTrendData.Model,
      price: PriceTrendData['Price']
    })
  });
  
const priceTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Model',
    selector: 'model',
    sortable: true,
    center: true
  },
  {
    name: 'Price',
    selector: 'price',
    sortable: true,
    center: true
  }
];



// Rating Count Trend
let getRatingCountTrendLabel = ratingCountTrendData.map(data => data.Date);
let getRatingCountTrendData1 = ratingCountTrendData.map(data => data[modelSelectionTrend[0]]);
let getRatingCountTrendData2 = ratingCountTrendData.map(data => data[modelSelectionTrend[1]]);
let getRatingCountTrendData3 = ratingCountTrendData.map(data => data[modelSelectionTrend[2]]);
let getRatingCountTrendData4 = ratingCountTrendData.map(data => data[modelSelectionTrend[3]]);
let getRatingCountTrendData5 = ratingCountTrendData.map(data => data[modelSelectionTrend[4]]);



let ratingCountTrendDataSet = {};

switch(modelSelectionTrend.length) {
  case 1:
    ratingCountTrendDataSet = {
      labels: getRatingCountTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getRatingCountTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },
    
      ]
    
    }
    break;
  case 2:
    ratingCountTrendDataSet = {
      labels: getRatingCountTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getRatingCountTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getRatingCountTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        }
    
      ]
    
    }
    break;
    case 3:
    ratingCountTrendDataSet = {
      labels: getRatingCountTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getRatingCountTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getRatingCountTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getRatingCountTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        }
    
      ]
    
    }
    break;
    case 4:
    ratingCountTrendDataSet = {
      labels: getRatingCountTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getRatingCountTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getRatingCountTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getRatingCountTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getRatingCountTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        }
    
      ]
    
    }
    break;
    case 5:
    ratingCountTrendDataSet = {
      labels: getRatingCountTrendLabel,
      datasets: [
        {
          label: modelSelectionTrend[0],
          data: getRatingCountTrendData1,
          fill: false,
          backgroundColor: 'rgb(255, 151, 99)',
          borderColor: 'rgba(255, 151, 99, 0.6)'
        },

        {
          label: modelSelectionTrend[1],
          data: getRatingCountTrendData2,
          fill: false,
          backgroundColor: 'rgb(172, 99, 255)',
          borderColor: 'rgba(172, 99, 255, 0.6)'
        },

        {
          label: modelSelectionTrend[2],
          data: getRatingCountTrendData3,
          fill: false,
          backgroundColor: 'rgb(255, 99, 245)',
          borderColor: 'rgba(255, 99, 245, 0.6)'
        },

        {
          label: modelSelectionTrend[3],
          data: getRatingCountTrendData4,
          fill: false,
          backgroundColor: 'rgb(99, 255, 161)',
          borderColor: 'rgba(99, 255, 161, 0.6)'
        },

        {
          label: modelSelectionTrend[4],
          data: getRatingCountTrendData5,
          fill: false,
          backgroundColor: 'rgb(255, 99, 99)',
          borderColor: 'rgba(255, 99, 99, 0.6)'
        }
    
      ]
    
    }
    break;
  default:
    // code block
}

const ratingCountLineoptions = {
  spanGaps: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}



// Rating Count Trend Table using react-data-table-component

const ratingCountTrendTableDataSet = 
ratingCountTrendTableData.map((RatingCountTrendData) => {
    return ({
      id: RatingCountTrendData.id,
      date: RatingCountTrendData.Date,
      model: RatingCountTrendData.Model,
      ratingCount: RatingCountTrendData['Rating Count']
    })
  });
  
const ratingCountTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Model',
    selector: 'model',
    sortable: true,
    center: true
  },
  {
    name: 'Rating Count',
    selector: 'ratingCount',
    sortable: true,
    center: true
  }
];


// Price Point Trend
// let getPricePointTrendLabel = pricePointTrendData.map(data => data.Date);
// let getPricePointTrendData1 = pricePointTrendData.map(data => data[modelSelectionTrend[0]]);
// let getPricePointTrendData2 = pricePointTrendData.map(data => data[modelSelectionTrend[1]]);
// let getPricePointTrendData3 = pricePointTrendData.map(data => data[modelSelectionTrend[2]]);
// let getPricePointTrendData4 = pricePointTrendData.map(data => data[modelSelectionTrend[3]]);
// let getPricePointTrendData5 = pricePointTrendData.map(data => data[modelSelectionTrend[4]]);


// Rating Count Trend Table using react-data-table-component

const pricePointTrendTableDataSet = 
pricePointTrendTableData.map((PricePointTrendData) => {
    return ({
      id: PricePointTrendData.id,
      model: PricePointTrendData.Model,
      lowestPrice: PricePointTrendData['Lowest Price'],
      highestPrice: PricePointTrendData['Highest Price']
    })
  });
  
const pricePointTrendTableColumn = [
  {
    name: 'Model',
    selector: 'model',
    sortable: true,
    center: true
  },
  {
    name: 'Low',
    selector: 'lowestPrice',
    sortable: true,
    center: true
  },
  {
    name: 'High',
    selector: 'highestPrice',
    sortable: true,
    center: true
  }
];


// Custom Model Search


const [modelList, setModelList] = React.useState([]);
const [savedModelList, setSavedModelList] = React.useState([]);
  
  
  
    // Get Data from SMAT REST API
    React.useEffect(() => {
    const axios = require('axios');

    axios.get(SMATrestAPI.modelConfiguration[0].modelList).then(function (response) { 
      // handle success
      setModelList(response.data.getModelList);

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });



  }, [])
  
  
 
  // Model table - Table using material-table

  const modelConfigurationTableColumn = [
    {
      title: 'Brand',
      field: 'brand'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Model',
      field: 'model'
    },
    {
      title: 'Website',
      field: 'website'
    },
    {
      title: 'Country',
      field: 'country'
    }
  ];



  const modelConfigurationTableDataSet = modelList.map((data) => {
    return ({
      //id: data.id,
      brand: data['Brand'],
      category: data['Product.Category'],
      model: data['Model'],
      website: data['Website'],
      country: data['Country']
    })
  });


    // Model table - Table using material-table

    var savedModelConfigurationTableColumn = [
      {
        title: 'Brand',
        field: 'brand'
      },
      {
        title: 'Category',
        field: 'category'
      },
      {
        title: 'Model',
        field: 'model'
      },
      {
        title: 'Website',
        field: 'website'
      },
      {
        title: 'Country',
        field: 'country'
      }
    ];
  
  
    var saveModelConfigurationTableDataSet = savedModelList.map((data) => {
      return ({
        //id: data.id,
        brand: data['brand'],
        category: data['category'],
        model: data['model'],
        website: data['website'],
        country: data['country']
      })
    });
	
	
	
	
  const onClickAddModel = (brand, category, model, website, country) => {

    const arr = [{"brand": brand, "category": category, "model": model, "website": website, "country": country}];

    setSavedModelList(current => [...current, ...arr]);

    

    if(savedModelList.model !== ''){

    
      const axios = require('axios');
    
      axios.get(`${SMATrestAPI.starRatingDashboard[0].trendFilterDate}`).then(function (response) { 
        // handle success
        setDateStartItemTrend(response.data.get_starRatingDashboard_trend_min_date_filter);
        setDateEndItemTrend(response.data.get_starRatingDashboard_trend_max_date_filter);
  
        setDateStartSelectionTrend(response.data.get_starRatingDashboard_trend_min_date_filter);
        setDateEndSelectionTrend(response.data.get_starRatingDashboard_trend_max_date_filter);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });
  
    }

  
  }

  
  const onClickRemoveModel = (brand, category, model, website, country) => {

    setSavedModelList(current =>
      current.filter(data => {
        return (
          data.model !== model || data.website !== website || data.country !== country
        );
      }),
    );


    if(savedModelList.model !== ''){

    
      const axios = require('axios');
    
      axios.get(`${SMATrestAPI.starRatingDashboard[0].trendFilterDate}`).then(function (response) { 
        // handle success
        setDateStartItemTrend(response.data.get_starRatingDashboard_trend_min_date_filter);
        setDateEndItemTrend(response.data.get_starRatingDashboard_trend_max_date_filter);
  
        setDateStartSelectionTrend(response.data.get_starRatingDashboard_trend_min_date_filter);
        setDateEndSelectionTrend(response.data.get_starRatingDashboard_trend_max_date_filter);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });
  
    }

  }



  const onClickTrendChartData2 = () => {
    setTrendIsLoading(true);
  
    const axios = require('axios');
  
    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl0 = `${"page="}${"Star Rating Dashboard"}${"&brand="}${brandSelectionTrend2}${"&category="}${categorySelectionTrend2}${"&model="}${modelSelectionTrend2}${"&website="}${websiteSelectionTrend2}${"&country="}${countrySelectionTrend2}`;
    restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
    restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url
  
    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl0}`).then(function (response) { 
  
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });
  
    // Website Rating Trend
    var restGETurl1 = `${"brand="}${brandSelectionTrend2}${"&category="}${categorySelectionTrend2}${"&model="}${modelSelectionTrend2}${"&website="}${websiteSelectionTrend2}${"&country="}${countrySelectionTrend2}${"&startDate="}${dateStartSelectionTrend2a}${"&endDate="}${dateEndSelectionTrend2a}`;
    //console.log(restGETurl1);
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
    axios.get(`${SMATrestAPI.starRatingDashboard[0].starRatingData}${restGETurl1}`).then(function (response) { 
      
      //console.table(response.data.tableData);

      setStarRatingData(response.data.tableData)
      setStarRatingDataRaw(response.data.detailData)

      //console.table(response.data.tableData)
      //console.table(response.data.detailData)
  
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });
  
  
    setTimeout(() => {  setTrendIsLoading(false); }, 1000);
  
  }


  //console.table(starRatingData.map(data => data['Date']))
  //console.table(starRatingData.map(data => data['fiveStar']))

  const starRatingDataSet = {
    type: 'bar',
    labels: starRatingData.map(data => data['Date']),
    datasets: [
  
      {
        label: "5-Star",
        data: starRatingData.map(data => data['fiveStar']),
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
      },
      {
        label: "4-Star",
        data: starRatingData.map(data => data['fourStar']),
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
      },
      {
        label: "3-Star",
        data: starRatingData.map(data => data['threeStar']),
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
      },
      {
        label: "2-Star",
        data: starRatingData.map(data => data['twoStar']),
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
      }
      ,
      {
        label: "1-Star",
        data: starRatingData.map(data => data['oneStar']),
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
      }
      
    ]
    } 


    const starRatingDetailBarOption = {
  
      // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },


      
      scales: {
        xAxes: [{
            offset: true,
            stacked: true,
        //     type: 'time',
        //     time: {
        //     unit: 'day'
        // }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
    
  
    }




  const starRatingTableDataSet = starRatingDataRaw.map((data) => {
    return ({
      date: data['Date'],
      brand: data['Brand'],
      model: data['Model'],
      productCategory: data['Product Category'],
      website: data['Website'],
      country: data['Country'],
      fiveStar: data['fiveStar'],
      fourStar: data['fourStar'],
      threeStar: data['threeStar'],
      twoStar: data['twoStar'],
      oneStar: data['oneStar']
    })
  });
  
  const starRatingTableColumn = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: 'Brand',
      selector: 'brand',
      sortable: true,
      center: true
    },
    {
      name: 'Model',
      selector: 'model',
      sortable: true,
      center: true
    },
    {
      name: 'Product Category',
      selector: 'productCategory',
      sortable: true,
      center: true
    },
    {
      name: 'Website',
      selector: 'website',
      sortable: true,
      center: true
    },
    {
      name: 'Country',
      selector: 'country',
      sortable: true,
      center: true
    },
    {
      name: '5 Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4 Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3 Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2 Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1 Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    }
  ];


  const classes = useStyles();
  

  return (
    <div>

      <div id="pageLoader" className={loadPage1}></div>

      <div id="mainPage" style={{display:loadPage2}}>


{/* Filters */}
      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

      </GridContainer>

      <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <WebsiteIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Website</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="website-select"
                  className={
                    classes.selectLabel
                }>
                  Select Website
                </InputLabel>

                <Select
                          multiple
                          value={websiteSelection}
                          onChange={handleWebsite}
                          MenuProps={{
                            className: classes.selectMenu,
                            classes: { paper: classes.selectPaper }
                          }}
                          classes={{ select: classes.select }}
                          inputProps={{
                            name: "websiteSelection",
                            id: "website-select"
                          }}
                        >

                      

                  <MenuItem disabled
                    classes={
                      {
                        root: classes.selectMenuItem
                      }
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  websiteItems.map((websiteItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={websiteItems}>
                      {websiteItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {
                      className: classes.selectMenu,
                      classes: { paper: classes.selectPaper }
                    }
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={categorySelection}
                  onChange={handleCategory}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>


                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  categoryItems.map((categoryItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={categoryItems}>
                      {categoryItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection}
                  onChange={handleCountry}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems.map((countryItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={countryItems}>
                      {countryItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CalculationIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Calculation</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Calculation
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }

                  autoWidth={false}
                  value={calculationSelection}
                  onChange={handleCalculation}
                  inputProps={
                    {
                      name: "calculationSelection",
                      id: "calculation-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  calculationItems.map((calculationItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={calculationItems}>
                      {calculationItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select Start Date" }}
                    // value={dateStartSelectionTrend}
                    // onChange={handleDateStartTrend}
                    // isValidDate={validStart}
                    // viewDate={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelectionTrend2, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItemTrend2, 'YYYY-MM-DD')}
                    onChange={handleDateStartTrend2}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart2 } 
                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select End Date" }}
                    // value={dateEndSelectionTrend}
                    // onChange={handleDateEndTrend}
                    // isValidDate={validEnd}
                    // viewDate={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelectionTrend2, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItemTrend2, 'YYYY-MM-DD')}
                    onChange={handleDateEndTrend2}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd2 } 

                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

      </GridContainer>


{/* Bubble Charts & Table */}

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Star Rating</b> </Button>
        </GridItem>

      </GridContainer>
      
      <GridContainer >
      <GridItem xs={12} sm={12} md={7}>

      <Card style={{height: '500px'}}>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
             <BubbleIcon />
          </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <b>Star Rating by Brand</b>
            </h4>
          </CardHeader>
          <CardBody>
          
          {!isLoading ? <BubbleChart /> : <CircularProgress style={{marginTop: "20%", marginLeft: '50%'}} /> }

            
          </CardBody>
        </Card>

        


        </GridItem>

        <GridItem xs={12} sm={12} md={5} style={{height:'100%'}}>
        <Card style={{height: '500px'}}>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <AssignmentIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <b>Star Rating Table</b>
            </h4>
          </CardHeader>
          <CardBody>

          {!isLoading ? <BubbleTable /> : <CircularProgress style={{marginTop: "30%", marginLeft: '50%'}} /> }
              
          </CardBody>
        </Card>
        </GridItem>
        
      </GridContainer>

      <GridContainer >

      <GridItem 
          xs={12}
          container
          direction="row"
          justify="flex-end"
          alignItems="center">
      
        <CSVLink data={bubbleTableData} filename={"Star Rating by Brand.csv"}>
          <Button color="warning"> 
            <DownloadIcon className={classes.icons} />
              Download CSV 
          </Button>
        </CSVLink>

        <Button color="pinterest" onClick={onClickBubbleChartData}>
          <RefreshIcon className={classes.icons} />
            Display Chart
        </Button>

      </GridItem>

      </GridContainer>


{/* Trend Charts & Table Filter */}

    <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Select A Product</b> </Button>
      </GridItem>

    </GridContainer>

    <GridContainer>


      <GridItem xs={12} sm={12} md={12}>

        <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="rose"

              tabs={[
                      {
                        tabName: "Basic Filter",
                        tabIcon: BasicIcon,
                          tabContent: 

                          <div>
                            <GridContainer>
                              <GridItem xs={12}
                                sm={12}
                                md={3}>
                      
                                <Card>
                                  <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                      <BrandIcon/>
                                    </CardIcon>
                                    <h4 className={
                                      classes.cardIconTitle
                                    }>
                                      <b>Brand</b>
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                                    <FormControl fullWidth
                                      className={
                                        classes.selectFormControl
                                    }>
                                      <InputLabel htmlFor="brandTrend-select"
                                        className={
                                          classes.selectLabel
                                      }>
                                        Select Brand
                                      </InputLabel>
                      
                                      <Select style={
                                          {minWidth: "100%"}
                                        }
                                        MenuProps={
                                          {className: classes.selectMenu}
                                        }
                                        classes={
                                          {select: classes.select}
                                        }
                                        //multiple
                                        autoWidth={false}
                                        value={brandSelectionTrend}
                                        onChange={handleBrandTrend}
                                        inputProps={
                                          {
                                            name: "brandTrendSelection",
                                            id: "brandTrend-select"
                                          }
                                      }>
                                        <MenuItem disabled
                                          classes={
                                            {root: classes.selectMenuItem}
                                        }>
                                          <b>Single Selection</b>
                                        </MenuItem>
                      
                                        {
                                        brandItemsTrend.map((brandItemsTrend, index) => (
                                          <MenuItem key={index}
                                            classes={
                                              {
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                              }
                                            }
                                            value={brandItemsTrend}>
                                            {brandItemsTrend} </MenuItem>
                                        ))
                                      } </Select>
                                    </FormControl>
                                  </CardBody>
                                </Card>
                      
                              </GridItem>
                      
                      
                              <GridItem xs={12}
                                sm={12}
                                md={3}>
                      
                                <Card>
                                  <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                      <CategoryIcon/>
                                    </CardIcon>
                                    <h4 className={
                                      classes.cardIconTitle
                                    }>
                                      <b>Category</b>
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                      
                                    <FormControl fullWidth
                                      className={
                                        classes.selectFormControl
                                    }>
                                      <InputLabel htmlFor="categoryTrend-select"
                                        className={
                                          classes.selectLabel
                                      }>
                                        Select Category
                                      </InputLabel>
                      
                                      <Select MenuProps={
                                          {className: classes.selectMenu}
                                        }
                                        classes={
                                          {select: classes.select}
                                        }
                                        //multiple
                                        autoWidth={false}
                                        value={categorySelectionTrend}
                                        onChange={handleCategoryTrend}
                                        inputProps={
                                          {
                                            name: "categoryTrendSelection",
                                            id: "categoryTrend-select"
                                          }
                                      }>
                                        <MenuItem disabled
                                          classes={
                                            {root: classes.selectMenuItem}
                                        }>
                                          <b>Single Selection</b>
                                        </MenuItem>
                      
                                        {
                                        categoryItemsTrend.map((categoryItemsTrend, index) => {
                                          return (
                                            <MenuItem key={index}
                                              classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                              }}
                                              value={categoryItemsTrend}>
                                              {categoryItemsTrend} </MenuItem>
                                          );
                                        })
                                      } </Select>
                                    </FormControl>
                                  </CardBody>
                                </Card>
                      
                              </GridItem>
                      
                              <GridItem xs={12}
                                sm={12}
                                md={3}>
                      
                                <Card>
                                  <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                      <ModelIcon/>
                                    </CardIcon>
                                    <h4 className={
                                      classes.cardIconTitle
                                    }>
                                      <b>Model</b>
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                      
                                    <FormControl fullWidth
                                      className={
                                        classes.selectFormControl
                                    }>
                                      <InputLabel htmlFor="modelTrend-select"
                                        className={
                                          classes.selectLabel
                                      }>
                                        Select Model
                                      </InputLabel>
                      
                                      <Select MenuProps={
                                          {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                                        }
                                        classes={
                                          {select: classes.select}
                                        }
                                        multiple
                                        autoWidth={false}
                                        value={modelSelectionTrend}
                                        onChange={handleModelTrend}
                                        inputProps={
                                          {
                                            name: "modelTrendSelection",
                                            id: "modelTrend-select"
                                          }
                                      }>
                                        <MenuItem disabled
                                          classes={
                                            {root: classes.selectMenuItem}
                                        }>
                                          <b>Multiple Selection</b>
                                        </MenuItem>
                      
                                        {
                                        modelItemsTrend.map((modelItemsTrend, index) => (
                                          <MenuItem key={index}
                                            classes={
                                              {
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelectedMultiple
                                              }
                                            }
                                            value={modelItemsTrend}>
                                            {modelItemsTrend} </MenuItem>
                                        ))
                                      } </Select>
                                    </FormControl>
                                  </CardBody>
                                </Card>
                      
                              </GridItem>
                      
                              <GridItem xs={12}
                                sm={12}
                                md={3}>
                      
                                <Card>
                                  <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                      <WebsiteIcon/>
                                    </CardIcon>
                                    <h4 className={
                                      classes.cardIconTitle
                                    }>
                                      <b>Website</b>
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                      
                                    <FormControl fullWidth
                                      className={
                                        classes.selectFormControl
                                    }>
                                      <InputLabel htmlFor="websiteTrend-select"
                                        className={
                                          classes.selectLabel
                                      }>
                                        Select Website
                                      </InputLabel>
                      
                                      <Select MenuProps={
                                          {className: classes.selectMenu}
                                        }
                                        classes={
                                          {select: classes.select}
                                        }
                                        //multiple
                                        autoWidth={false}
                                        value={websiteSelectionTrend}
                                        onChange={handleWebsiteTrend}
                                        inputProps={
                                          {
                                            name: "websiteSelectionTrend",
                                            id: "websiteTrend-select"
                                          }
                                      }>
                                        <MenuItem disabled
                                          classes={
                                            {root: classes.selectMenuItem}
                                        }>
                                          <b>Single Selection</b>
                                        </MenuItem>
                      
                                        {
                                        websiteItemsTrend.map((websiteItemsTrend, index) => (
                                          <MenuItem key={index}
                                            classes={
                                              {
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                              }
                                            }
                                            value={websiteItemsTrend}>
                                            {websiteItemsTrend} </MenuItem>
                                        ))
                                      } </Select>
                                    </FormControl>
                                  </CardBody>
                                </Card>
                      
                              </GridItem>
                      
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12}
                                sm={12}
                                md={3}>

                                <Card>
                                  <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                      <CountryIcon/>
                                    </CardIcon>
                                    <h4 className={
                                      classes.cardIconTitle
                                    }>
                                      <b>Country</b>
                                    </h4>
                                  </CardHeader>
                                  <CardBody>
                                    <FormControl fullWidth
                                      className={
                                        classes.selectFormControl
                                    }>
                                      <InputLabel htmlFor="countryTrend-select"
                                        className={
                                          classes.selectLabel
                                      }>
                                        Select Country
                                      </InputLabel>

                                      <Select style={
                                          {minWidth: "100%"}
                                        }
                                        MenuProps={
                                          {className: classes.selectMenu}
                                        }
                                        classes={
                                          {select: classes.select}
                                        }
                                        //multiple
                                        autoWidth={false}
                                        value={countrySelectionTrend}
                                        onChange={handleCountryTrend}
                                        inputProps={
                                          {
                                            name: "countryTrendSelection",
                                            id: "countryTrend-select"
                                          }
                                      }>
                                        <MenuItem disabled
                                          classes={
                                            {root: classes.selectMenuItem}
                                        }>
                                          <b>Single Selection</b>
                                        </MenuItem>

                                        {
                                        countryItemsTrend.map((countryItemsTrend, index) => (
                                          <MenuItem key={index}
                                            classes={
                                              {
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                              }
                                            }
                                            value={countryItemsTrend}>
                                            {countryItemsTrend} </MenuItem>
                                        ))
                                      } </Select>
                                    </FormControl>
                                  </CardBody>
                                </Card>

                              </GridItem>


                              <GridItem xs={12}
                                sm={12}
                                md={9}>

                                <Card>
                                  <CardHeader color="rose" icon>
                                    <CardIcon color="rose">
                                      <DateIcon/>
                                    </CardIcon>
                                    <h4 className={
                                      classes.cardIconTitle
                                    }>
                                      <b>Date</b>
                                    </h4>
                                  </CardHeader>
                                  <CardBody>

                                  <GridContainer style={{height: "75px"}}>
                                    <GridItem md={6}>
                                      <br/>
                                      <Datetime 
                                          // timeFormat={false}
                                          // inputProps={{ placeholder: "Select Start Date" }}
                                          // value={dateStartSelectionTrend}
                                          // onChange={handleDateStartTrend}
                                          // isValidDate={validStart}
                                          // viewDate={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}

                                          timeFormat={false}
                                          inputProps={{ placeholder: "Select Start Date" }}
                                          value={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}
                                          defaultValue ={moment(dateStartItemTrend, 'YYYY-MM-DD')}
                                          onChange={handleDateStartTrend}
                                          dateFormat='YYYY-MM-DD'
                                          closeOnSelect
                                          isValidDate={ validStart } 
                                        />
                                    </GridItem>

                                    <GridItem md={6}>
                                      <br/>
                                      <Datetime 
                                          // timeFormat={false}
                                          // inputProps={{ placeholder: "Select End Date" }}
                                          // value={dateEndSelectionTrend}
                                          // onChange={handleDateEndTrend}
                                          // isValidDate={validEnd}
                                          // viewDate={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}

                                          timeFormat={false}
                                          inputProps={{ placeholder: "Select End Date" }}
                                          value={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}
                                          defaultValue ={moment(dateEndItemTrend, 'YYYY-MM-DD')}
                                          onChange={handleDateEndTrend}
                                          dateFormat='YYYY-MM-DD'
                                          closeOnSelect 
                                          isValidDate={ validEnd } 

                                        />
                                    </GridItem>

                                  </GridContainer >
                                            
                                  </CardBody>
                                </Card>

                              </GridItem>

                            </GridContainer>


                            <GridContainer >

                              <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">

                                <Button color="pinterest" onClick={onClickTrendChartData}>
                                  <RefreshIcon className={classes.icons} />
                                    Display Chart
                                </Button>

                              </GridItem>

                            </GridContainer>




                          </div>




                        

                          

                      },
                      // {
                      //   tabName: "Advanced Filter",
                      //   tabIcon: AdvanceIcon,
                      //     tabContent: 
                      //       <div>


                      //         {/* Model List */}

                      //         <GridContainer>
                          
                      //             <GridItem xs={12} sm={12} md={6} >

                      //               <CustomTabs
                      //                   //title="Tasks:"
                      //                   centered={true}
                      //                   headerColor="primary"
                                        
                      //                   tabs={[
                      //                     {
                      //                       tabName: "Add Model",
                      //                       tabIcon: AssignmentIcon,
                      //                         tabContent: (
                      //                           <div>    
                      //                               <div style={{ maxWidth: '100%' }}>
                      //                                       <MaterialTable
                      //                                         columns={modelConfigurationTableColumn}
                      //                                         data={modelConfigurationTableDataSet}
                      //                                         title="Model List"
                      //                                         options={{
                      //                                           exportButton: false,
                      //                                           exportAllData: false,
                      //                                           filtering: true,
                      //                                           showTitle: false,
                      //                                           searchAutoFocus: true,
                      //                                           pageSize: 10
                      //                                         }}
                      //                                         actions={[
                      //                                           {
                      //                                             icon: AddIcon,
                      //                                             tooltip: 'Add Model',
                      //                                             onClick: (event, rowData) => {
                      //                                               // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                      //                                               onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                    

                      //                                             }

                      //                                           }
                      //                                         ]}
                      //                                         components={{
                      //                                           Action: props => (

                      //                                             <Tooltip
                      //                                               id="tooltip-bottom"
                      //                                               title="Add Model"
                      //                                               placement="top"
                      //                                               classes={{ tooltip: classes.tooltip }}
                      //                                             >
                      //                                                 <Button color="success" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                      //                                                   <AddIcon />
                      //                                                 </Button>
                      //                                             </Tooltip>
                                                                  
                      //                                           )
                      //                                         }}

                      //                                       />
                      //                                 </div>


                      //                             </div>
                      //                       )

                                            
                      //                     }
                      //                   ]}
                      //                 /> 

                      //               </GridItem>

                      //           {/* Saved Model List */}

                      //               <GridItem xs={12} sm={12} md={6} direction="column">

                      //                 <CustomTabs
                      //                     //title="Tasks:"
                      //                     centered={true}
                      //                     headerColor="primary"
                                          
                      //                     tabs={[
                      //                       {
                      //                         tabName: "Saved Model List",
                      //                         tabIcon: AssignmentIcon,
                      //                           tabContent: (
                      //                             <div>    
                      //                                 <div style={{ maxWidth: '100%' }}>
                      //                                         <MaterialTable
                      //                                           columns={savedModelConfigurationTableColumn}
                      //                                           data={saveModelConfigurationTableDataSet}
                      //                                           title="Model List"
                      //                                           options={{
                      //                                             exportButton: false,
                      //                                             exportAllData: false,
                      //                                             filtering: true,
                      //                                             showTitle: false,
                      //                                             searchAutoFocus: true,
                      //                                             pageSize: 5
                      //                                           }}
                      //                                           actions={[
                      //                                             {
                      //                                               icon: RemoveIcon,
                      //                                               tooltip: 'Remove Model',
                      //                                               onClick: (event, rowData) => {
                      //                                                 // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                      //                                                 onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                      

                      //                                               }

                      //                                             }
                      //                                           ]}
                      //                                           components={{
                      //                                             Action: props => (

                      //                                               <Tooltip
                      //                                                 id="tooltip-bottom"
                      //                                                 title="Remove Model"
                      //                                                 placement="top"
                      //                                                 classes={{ tooltip: classes.tooltip }}
                      //                                               >
                      //                                                   <Button color="danger" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                      //                                                     <RemoveIcon />
                      //                                                   </Button>
                      //                                               </Tooltip>

                                                                    
                      //                                             )
                      //                                           }}

                      //                                         />
                      //                                   </div>
                                                        


                      //                               </div>
                      //                           )
                                              
                      //                       }


                                            
                      //                     ]}
                      //                   /> 

                      //                   <CustomTabs
                      //                     //title="Tasks:"
                      //                     centered={true}
                      //                     headerColor="primary"
                                          
                      //                     tabs={[
                      //                       {
                      //                         tabName: "Date Selection",
                      //                         tabIcon: DateIcon,
                      //                           tabContent: (
                      //                             <div>    

                      //                               <GridContainer>

                      //                                 <GridItem xs={12}
                      //                                       sm={12}
                      //                                       md={12}>

                      //                                     <Card>
                      //                                       <CardHeader color="primary" icon>
                      //                                         <CardIcon color="primary">
                      //                                           <DateIcon/>
                      //                                         </CardIcon>
                      //                                         <h4 className={
                      //                                           classes.cardIconTitle
                      //                                         }>
                      //                                           <b>Date</b>
                      //                                         </h4>
                      //                                       </CardHeader>
                      //                                       <CardBody>

                      //                                       <GridContainer style={{height: "75px"}}>
                      //                                         <GridItem md={6}>
                      //                                           <br/>
                      //                                           <Datetime 
                      //                                               // timeFormat={false}
                      //                                               // inputProps={{ placeholder: "Select Start Date" }}
                      //                                               // value={dateStartSelectionTrend}
                      //                                               // onChange={handleDateStartTrend}
                      //                                               // isValidDate={validStart}
                      //                                               // viewDate={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}

                      //                                               timeFormat={false}
                      //                                               inputProps={{ placeholder: "Select Start Date" }}
                      //                                               value={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}
                      //                                               defaultValue ={moment(dateStartItemTrend, 'YYYY-MM-DD')}
                      //                                               onChange={handleDateStartTrend}
                      //                                               dateFormat='YYYY-MM-DD'
                      //                                               closeOnSelect
                      //                                               isValidDate={ validStart } 
                      //                                             />
                      //                                         </GridItem>

                      //                                         <GridItem md={6}>
                      //                                           <br/>
                      //                                           <Datetime 
                      //                                               // timeFormat={false}
                      //                                               // inputProps={{ placeholder: "Select End Date" }}
                      //                                               // value={dateEndSelectionTrend}
                      //                                               // onChange={handleDateEndTrend}
                      //                                               // isValidDate={validEnd}
                      //                                               // viewDate={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}

                      //                                               timeFormat={false}
                      //                                               inputProps={{ placeholder: "Select End Date" }}
                      //                                               value={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}
                      //                                               defaultValue ={moment(dateEndItemTrend, 'YYYY-MM-DD')}
                      //                                               onChange={handleDateEndTrend}
                      //                                               dateFormat='YYYY-MM-DD'
                      //                                               closeOnSelect 
                      //                                               isValidDate={ validEnd } 

                      //                                             />
                      //                                         </GridItem>

                      //                                       </GridContainer >
                                                                      
                      //                                       </CardBody>
                      //                                     </Card>

                      //                                   </GridItem>
                                                      
                      //                               </GridContainer>



                      //                             </div>
                      //                           )
                                              
                      //                       }


                                            
                      //                     ]}
                      //                   /> 

                      //                 </GridItem>

                      //                 {/* Advanced Model Submission */}
                      //                 <GridContainer >

                      //                   <GridItem 
                      //                       xs={12}
                      //                       container
                      //                       direction="row"
                      //                       justify="flex-end"
                      //                       alignItems="center">

                      //                     <Button color="pinterest" onClick={onClickTrendChartDataAdvance}>
                      //                       <RefreshIcon className={classes.icons} />
                      //                         Display Chart
                      //                     </Button>

                      //                   </GridItem>

                      //                 </GridContainer>


                                    
                      //             </GridContainer>



                      //       </div>
                      // }

                    ]}
                    

          />


      </GridItem>


    </GridContainer>


      {/* <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BrandIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Brand</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="brandTrend-select"
                  className={
                    classes.selectLabel
                }>
                  Select Brand
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={brandSelectionTrend}
                  onChange={handleBrandTrend}
                  inputProps={
                    {
                      name: "brandTrendSelection",
                      id: "brandTrend-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  brandItemsTrend.map((brandItemsTrend, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={brandItemsTrend}>
                      {brandItemsTrend} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="categoryTrend-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelectionTrend}
                  onChange={handleCategoryTrend}
                  inputProps={
                    {
                      name: "categoryTrendSelection",
                      id: "categoryTrend-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItemsTrend.map((categoryItemsTrend, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={categoryItemsTrend}>
                        {categoryItemsTrend} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="modelTrend-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelectionTrend}
                  onChange={handleModelTrend}
                  inputProps={
                    {
                      name: "modelTrendSelection",
                      id: "modelTrend-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItemsTrend.map((modelItemsTrend, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={modelItemsTrend}>
                      {modelItemsTrend} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <WebsiteIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Website</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="websiteTrend-select"
                  className={
                    classes.selectLabel
                }>
                  Select Website
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={websiteSelectionTrend}
                  onChange={handleWebsiteTrend}
                  inputProps={
                    {
                      name: "websiteSelectionTrend",
                      id: "websiteTrend-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  websiteItemsTrend.map((websiteItemsTrend, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={websiteItemsTrend}>
                      {websiteItemsTrend} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

      </GridContainer> */}


      {/* <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="countryTrend-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={countrySelectionTrend}
                  onChange={handleCountryTrend}
                  inputProps={
                    {
                      name: "countryTrendSelection",
                      id: "countryTrend-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  countryItemsTrend.map((countryItemsTrend, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={countryItemsTrend}>
                      {countryItemsTrend} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={9}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select Start Date" }}
                    // value={dateStartSelectionTrend}
                    // onChange={handleDateStartTrend}
                    // isValidDate={validStart}
                    // viewDate={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItemTrend, 'YYYY-MM-DD')}
                    onChange={handleDateStartTrend}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart } 
                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select End Date" }}
                    // value={dateEndSelectionTrend}
                    // onChange={handleDateEndTrend}
                    // isValidDate={validEnd}
                    // viewDate={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItemTrend, 'YYYY-MM-DD')}
                    onChange={handleDateEndTrend}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 

                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

      </GridContainer> */}


      {/* <GridContainer >

        <GridItem 
            xs={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center">

          <Button color="pinterest" onClick={onClickTrendChartData}>
            <RefreshIcon className={classes.icons} />
              Display Chart
          </Button>

        </GridItem>

      </GridContainer> */}



    {/* <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Trends</b> </Button>
      </GridItem>

    </GridContainer> */}



    <GridContainer>

        <GridItem xs={12} sm={12} md={4} >
 
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "Website Rating",
                    tabIcon: RatingIcon,
                    tabContent: (!trendIsLoading ? <Line height={240} data={websiteRatingTrendDataSet}  options={options} /> : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!trendIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={websiteRatingTrendTableColumn}
                            data={websiteRatingTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"315px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={websiteRatingTrendTableDataSet} filename={"Website Rating Trend.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "SMAT Unique Rating",
                  tabIcon: RatingIcon,
                  tabContent: (!trendIsLoading ? <Line height={240} data={smatUniqueRatingTrendDataSet}  options={smatUniqueLineoptions} /> : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!trendIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={smatUniqueRatingTrendTableColumn}
                          data={smatUniqueRatingTrendTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"315px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={smatUniqueRatingTrendTableDataSet} filename={"SMAT Unique Rating Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

      </GridItem>   

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="info"
            
            tabs={[
              {
                tabName: "SMAT Rating",
                tabIcon: RatingIcon,
                tabContent: (!trendIsLoading ? <Line height={240} data={smatRatingTrendDataSet}  options={smatLineoptions} /> : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!trendIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={smatRatingTrendTableColumn}
                        data={smatRatingTrendTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"315px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={smatRatingTrendTableDataSet} filename={"SMAT Rating Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

        </GridItem>   

    </GridContainer>



    <GridContainer>

        <GridItem xs={12} sm={12} md={4} >
 
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Price",
                    tabIcon: PriceIcon,
                    tabContent: (!trendIsLoading ? <Line height={240} data={priceTrendDataSet}  options={priceLineoptions} /> : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!trendIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={priceTrendTableColumn}
                            data={priceTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"315px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={priceTrendTableDataSet} filename={"Price Trend.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

        </GridItem>


        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="rose"
            
            tabs={[
              {
                tabName: "Price Point",
                tabIcon: PriceIcon,
                  tabContent: (!trendIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={pricePointTrendTableColumn}
                        data={pricePointTrendTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"400px"}
                        noHeader={true}
                      /> 

                      <br/> <br/> <br/> <br/> <br/> <br/>  <br/> <br/> <br/> <br/> <br/> 

                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={pricePointTrendTableDataSet} filename={"Price Point Data.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

        </GridItem>   

        <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="danger"
              
              tabs={[
                {
                  tabName: "Rating Count",
                  tabIcon: TrendIcon,
                  tabContent: (!trendIsLoading ? <Line height={240} data={ratingCountTrendDataSet}  options={ratingCountLineoptions} /> : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!trendIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={ratingCountTrendTableColumn}
                          data={ratingCountTrendTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"315px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={ratingCountTrendTableDataSet} filename={"Rating Count Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

      </GridItem>   

      

    </GridContainer>



    <GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>Amazon Star Rating Trend (Reviews + No Reviews) </b> </Button>
</GridItem>

</GridContainer>

<GridContainer>

{/* Amazon Star Rating : Reviews and No Review Count */}

  <GridItem xs={12} sm={12} md={12}>

    <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="rose"

          tabs={[
                  {
                    tabName: "Basic Filter",
                    tabIcon: BasicIcon,
                      tabContent: 

                      <div>
                        <GridContainer>
                          <GridItem xs={12}
                            sm={12}
                            md={3}>
                  
                            <Card>
                              <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                  <BrandIcon/>
                                </CardIcon>
                                <h4 className={
                                  classes.cardIconTitle
                                }>
                                  <b>Brand</b>
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <FormControl fullWidth
                                  className={
                                    classes.selectFormControl
                                }>
                                  <InputLabel htmlFor="brandTrend-select"
                                    className={
                                      classes.selectLabel
                                  }>
                                    Select Brand
                                  </InputLabel>
                  
                                  <Select style={
                                      {minWidth: "100%"}
                                    }
                                    MenuProps={
                                      {className: classes.selectMenu}
                                    }
                                    classes={
                                      {select: classes.select}
                                    }
                                    //multiple
                                    autoWidth={false}
                                    value={brandSelectionTrend2}
                                    onChange={handleBrandTrend2}
                                    inputProps={
                                      {
                                        name: "brandTrendSelection",
                                        id: "brandTrend-select"
                                      }
                                  }>
                                    <MenuItem disabled
                                      classes={
                                        {root: classes.selectMenuItem}
                                    }>
                                      <b>Single Selection</b>
                                    </MenuItem>
                  
                                    {
                                    brandItemsTrend2.map((brandItemsTrend2, index) => (
                                      <MenuItem key={index}
                                        classes={
                                          {
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                          }
                                        }
                                        value={brandItemsTrend2}>
                                        {brandItemsTrend2} </MenuItem>
                                    ))
                                  } </Select>
                                </FormControl>
                              </CardBody>
                            </Card>
                  
                          </GridItem>
                  
                  
                          <GridItem xs={12}
                            sm={12}
                            md={3}>
                  
                            <Card>
                              <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                  <CategoryIcon/>
                                </CardIcon>
                                <h4 className={
                                  classes.cardIconTitle
                                }>
                                  <b>Category</b>
                                </h4>
                              </CardHeader>
                              <CardBody>
                  
                                <FormControl fullWidth
                                  className={
                                    classes.selectFormControl
                                }>
                                  <InputLabel htmlFor="categoryTrend-select"
                                    className={
                                      classes.selectLabel
                                  }>
                                    Select Category
                                  </InputLabel>
                  
                                  <Select MenuProps={
                                      {className: classes.selectMenu}
                                    }
                                    classes={
                                      {select: classes.select}
                                    }
                                    //multiple
                                    autoWidth={false}
                                    value={categorySelectionTrend2}
                                    onChange={handleCategoryTrend2}
                                    inputProps={
                                      {
                                        name: "categoryTrendSelection",
                                        id: "categoryTrend-select"
                                      }
                                  }>
                                    <MenuItem disabled
                                      classes={
                                        {root: classes.selectMenuItem}
                                    }>
                                      <b>Single Selection</b>
                                    </MenuItem>
                  
                                    {
                                    categoryItemsTrend2.map((categoryItemsTrend2, index) => {
                                      return (
                                        <MenuItem key={index}
                                          classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                          }}
                                          value={categoryItemsTrend2}>
                                          {categoryItemsTrend2} </MenuItem>
                                      );
                                    })
                                  } </Select>
                                </FormControl>
                              </CardBody>
                            </Card>
                  
                          </GridItem>
                  
                          <GridItem xs={12}
                            sm={12}
                            md={3}>
                  
                            <Card>
                              <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                  <ModelIcon/>
                                </CardIcon>
                                <h4 className={
                                  classes.cardIconTitle
                                }>
                                  <b>Model</b>
                                </h4>
                              </CardHeader>
                              <CardBody>
                  
                                <FormControl fullWidth
                                  className={
                                    classes.selectFormControl
                                }>
                                  <InputLabel htmlFor="modelTrend-select"
                                    className={
                                      classes.selectLabel
                                  }>
                                    Select Model
                                  </InputLabel>
                  
                                  <Select MenuProps={
                                      {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                                    }
                                    classes={
                                      {select: classes.select}
                                    }
                                    multiple
                                    autoWidth={false}
                                    value={modelSelectionTrend2}
                                    onChange={handleModelTrend2}
                                    inputProps={
                                      {
                                        name: "modelTrendSelection",
                                        id: "modelTrend-select"
                                      }
                                  }>
                                    <MenuItem disabled
                                      classes={
                                        {root: classes.selectMenuItem}
                                    }>
                                      <b>Multiple Selection</b>
                                    </MenuItem>
                  
                                    {
                                    modelItemsTrend2.map((modelItemsTrend2, index) => (
                                      <MenuItem key={index}
                                        classes={
                                          {
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple
                                          }
                                        }
                                        value={modelItemsTrend2}>
                                        {modelItemsTrend2} </MenuItem>
                                    ))
                                  } </Select>
                                </FormControl>
                              </CardBody>
                            </Card>
                  
                          </GridItem>
                  
                          <GridItem xs={12}
                            sm={12}
                            md={3}>
                  
                            <Card>
                              <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                  <WebsiteIcon/>
                                </CardIcon>
                                <h4 className={
                                  classes.cardIconTitle
                                }>
                                  <b>Website</b>
                                </h4>
                              </CardHeader>
                              <CardBody>
                  
                                <FormControl fullWidth
                                  className={
                                    classes.selectFormControl
                                }>
                                  <InputLabel htmlFor="websiteTrend-select"
                                    className={
                                      classes.selectLabel
                                  }>
                                    Select Website
                                  </InputLabel>
                  
                                  <Select MenuProps={
                                      {className: classes.selectMenu}
                                    }
                                    classes={
                                      {select: classes.select}
                                    }
                                    //multiple
                                    autoWidth={false}
                                    value={websiteSelectionTrend2}
                                    onChange={handleWebsiteTrend2}
                                    inputProps={
                                      {
                                        name: "websiteSelectionTrend",
                                        id: "websiteTrend-select"
                                      }
                                  }>
                                    <MenuItem disabled
                                      classes={
                                        {root: classes.selectMenuItem}
                                    }>
                                      <b>Single Selection</b>
                                    </MenuItem>
                  
                                    {
                                    websiteItemsTrend2.map((websiteItemsTrend2, index) => (
                                      <MenuItem key={index}
                                        classes={
                                          {
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                          }
                                        }
                                        value={websiteItemsTrend2}>
                                        {websiteItemsTrend2} </MenuItem>
                                    ))
                                  } </Select>
                                </FormControl>
                              </CardBody>
                            </Card>
                  
                          </GridItem>
                  
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12}
                            sm={12}
                            md={3}>

                            <Card>
                              <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                  <CountryIcon/>
                                </CardIcon>
                                <h4 className={
                                  classes.cardIconTitle
                                }>
                                  <b>Country</b>
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <FormControl fullWidth
                                  className={
                                    classes.selectFormControl
                                }>
                                  <InputLabel htmlFor="countryTrend-select"
                                    className={
                                      classes.selectLabel
                                  }>
                                    Select Country
                                  </InputLabel>

                                  <Select style={
                                      {minWidth: "100%"}
                                    }
                                    MenuProps={
                                      {className: classes.selectMenu}
                                    }
                                    classes={
                                      {select: classes.select}
                                    }
                                    //multiple
                                    autoWidth={false}
                                    value={countrySelectionTrend2}
                                    onChange={handleCountryTrend2}
                                    inputProps={
                                      {
                                        name: "countryTrendSelection",
                                        id: "countryTrend-select"
                                      }
                                  }>
                                    <MenuItem disabled
                                      classes={
                                        {root: classes.selectMenuItem}
                                    }>
                                      <b>Single Selection</b>
                                    </MenuItem>

                                    {
                                    countryItemsTrend2.map((countryItemsTrend2, index) => (
                                      <MenuItem key={index}
                                        classes={
                                          {
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                          }
                                        }
                                        value={countryItemsTrend2}>
                                        {countryItemsTrend2} </MenuItem>
                                    ))
                                  } </Select>
                                </FormControl>
                              </CardBody>
                            </Card>

                          </GridItem>


                          <GridItem xs={12}
                            sm={12}
                            md={9}>

                            <Card>
                              <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                  <DateIcon/>
                                </CardIcon>
                                <h4 className={
                                  classes.cardIconTitle
                                }>
                                  <b>Date</b>
                                </h4>
                              </CardHeader>
                              <CardBody>

                              <GridContainer style={{height: "75px"}}>
                                <GridItem md={6}>
                                  <br/>
                                  <Datetime 
                                      // timeFormat={false}
                                      // inputProps={{ placeholder: "Select Start Date" }}
                                      // value={dateStartSelectionTrend}
                                      // onChange={handleDateStartTrend}
                                      // isValidDate={validStart}
                                      // viewDate={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}

                                      timeFormat={false}
                                      inputProps={{ placeholder: "Select Start Date" }}
                                      value={moment(dateStartSelectionTrend2a, 'YYYY-MM-DD')}
                                      defaultValue ={moment(dateStartItemTrend2a, 'YYYY-MM-DD')}
                                      onChange={handleDateStartTrend2a}
                                      dateFormat='YYYY-MM-DD'
                                      closeOnSelect
                                      isValidDate={ validStart2a } 
                                    />
                                </GridItem>

                                <GridItem md={6}>
                                  <br/>
                                  <Datetime 
                                      // timeFormat={false}
                                      // inputProps={{ placeholder: "Select End Date" }}
                                      // value={dateEndSelectionTrend}
                                      // onChange={handleDateEndTrend}
                                      // isValidDate={validEnd}
                                      // viewDate={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}

                                      timeFormat={false}
                                      inputProps={{ placeholder: "Select End Date" }}
                                      value={moment(dateEndSelectionTrend2a, 'YYYY-MM-DD')}
                                      defaultValue ={moment(dateEndItemTrend2a, 'YYYY-MM-DD')}
                                      onChange={handleDateEndTrend2a}
                                      dateFormat='YYYY-MM-DD'
                                      closeOnSelect 
                                      isValidDate={ validEnd2a } 

                                    />
                                </GridItem>

                              </GridContainer >
                                        
                              </CardBody>
                            </Card>

                          </GridItem>

                        </GridContainer>


                        <GridContainer >

                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">

                            <Button color="pinterest" onClick={onClickTrendChartData2}>
                              <RefreshIcon className={classes.icons} />
                                Display Chart
                            </Button>

                          </GridItem>

                        </GridContainer>




                      </div>




                    

                      

                  },
                  // {
                  //   tabName: "Advanced Filter",
                  //   tabIcon: AdvanceIcon,
                  //     tabContent: 
                  //       <div>


                  //         {/* Model List */}

                  //         <GridContainer>
                      
                  //             <GridItem xs={12} sm={12} md={6} >

                  //               <CustomTabs
                  //                   //title="Tasks:"
                  //                   centered={true}
                  //                   headerColor="primary"
                                    
                  //                   tabs={[
                  //                     {
                  //                       tabName: "Add Model",
                  //                       tabIcon: AssignmentIcon,
                  //                         tabContent: (
                  //                           <div>    
                  //                               <div style={{ maxWidth: '100%' }}>
                  //                                       <MaterialTable
                  //                                         columns={modelConfigurationTableColumn}
                  //                                         data={modelConfigurationTableDataSet}
                  //                                         title="Model List"
                  //                                         options={{
                  //                                           exportButton: false,
                  //                                           exportAllData: false,
                  //                                           filtering: true,
                  //                                           showTitle: false,
                  //                                           searchAutoFocus: true,
                  //                                           pageSize: 10
                  //                                         }}
                  //                                         actions={[
                  //                                           {
                  //                                             icon: AddIcon,
                  //                                             tooltip: 'Add Model',
                  //                                             onClick: (event, rowData) => {
                  //                                               // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                  //                                               onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                

                  //                                             }

                  //                                           }
                  //                                         ]}
                  //                                         components={{
                  //                                           Action: props => (

                  //                                             <Tooltip
                  //                                               id="tooltip-bottom"
                  //                                               title="Add Model"
                  //                                               placement="top"
                  //                                               classes={{ tooltip: classes.tooltip }}
                  //                                             >
                  //                                                 <Button color="success" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                  //                                                   <AddIcon />
                  //                                                 </Button>
                  //                                             </Tooltip>
                                                              
                  //                                           )
                  //                                         }}

                  //                                       />
                  //                                 </div>


                  //                             </div>
                  //                       )

                                        
                  //                     }
                  //                   ]}
                  //                 /> 

                  //               </GridItem>

                  //           {/* Saved Model List */}

                  //               <GridItem xs={12} sm={12} md={6} direction="column">

                  //                 <CustomTabs
                  //                     //title="Tasks:"
                  //                     centered={true}
                  //                     headerColor="primary"
                                      
                  //                     tabs={[
                  //                       {
                  //                         tabName: "Saved Model List",
                  //                         tabIcon: AssignmentIcon,
                  //                           tabContent: (
                  //                             <div>    
                  //                                 <div style={{ maxWidth: '100%' }}>
                  //                                         <MaterialTable
                  //                                           columns={savedModelConfigurationTableColumn}
                  //                                           data={saveModelConfigurationTableDataSet}
                  //                                           title="Model List"
                  //                                           options={{
                  //                                             exportButton: false,
                  //                                             exportAllData: false,
                  //                                             filtering: true,
                  //                                             showTitle: false,
                  //                                             searchAutoFocus: true,
                  //                                             pageSize: 5
                  //                                           }}
                  //                                           actions={[
                  //                                             {
                  //                                               icon: RemoveIcon,
                  //                                               tooltip: 'Remove Model',
                  //                                               onClick: (event, rowData) => {
                  //                                                 // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                  //                                                 onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                                                  

                  //                                               }

                  //                                             }
                  //                                           ]}
                  //                                           components={{
                  //                                             Action: props => (

                  //                                               <Tooltip
                  //                                                 id="tooltip-bottom"
                  //                                                 title="Remove Model"
                  //                                                 placement="top"
                  //                                                 classes={{ tooltip: classes.tooltip }}
                  //                                               >
                  //                                                   <Button color="danger" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                  //                                                     <RemoveIcon />
                  //                                                   </Button>
                  //                                               </Tooltip>

                                                                
                  //                                             )
                  //                                           }}

                  //                                         />
                  //                                   </div>
                                                    


                  //                               </div>
                  //                           )
                                          
                  //                       }


                                        
                  //                     ]}
                  //                   /> 

                  //                   <CustomTabs
                  //                     //title="Tasks:"
                  //                     centered={true}
                  //                     headerColor="primary"
                                      
                  //                     tabs={[
                  //                       {
                  //                         tabName: "Date Selection",
                  //                         tabIcon: DateIcon,
                  //                           tabContent: (
                  //                             <div>    

                  //                               <GridContainer>

                  //                                 <GridItem xs={12}
                  //                                       sm={12}
                  //                                       md={12}>

                  //                                     <Card>
                  //                                       <CardHeader color="primary" icon>
                  //                                         <CardIcon color="primary">
                  //                                           <DateIcon/>
                  //                                         </CardIcon>
                  //                                         <h4 className={
                  //                                           classes.cardIconTitle
                  //                                         }>
                  //                                           <b>Date</b>
                  //                                         </h4>
                  //                                       </CardHeader>
                  //                                       <CardBody>

                  //                                       <GridContainer style={{height: "75px"}}>
                  //                                         <GridItem md={6}>
                  //                                           <br/>
                  //                                           <Datetime 
                  //                                               // timeFormat={false}
                  //                                               // inputProps={{ placeholder: "Select Start Date" }}
                  //                                               // value={dateStartSelectionTrend}
                  //                                               // onChange={handleDateStartTrend}
                  //                                               // isValidDate={validStart}
                  //                                               // viewDate={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}

                  //                                               timeFormat={false}
                  //                                               inputProps={{ placeholder: "Select Start Date" }}
                  //                                               value={moment(dateStartSelectionTrend, 'YYYY-MM-DD')}
                  //                                               defaultValue ={moment(dateStartItemTrend, 'YYYY-MM-DD')}
                  //                                               onChange={handleDateStartTrend}
                  //                                               dateFormat='YYYY-MM-DD'
                  //                                               closeOnSelect
                  //                                               isValidDate={ validStart } 
                  //                                             />
                  //                                         </GridItem>

                  //                                         <GridItem md={6}>
                  //                                           <br/>
                  //                                           <Datetime 
                  //                                               // timeFormat={false}
                  //                                               // inputProps={{ placeholder: "Select End Date" }}
                  //                                               // value={dateEndSelectionTrend}
                  //                                               // onChange={handleDateEndTrend}
                  //                                               // isValidDate={validEnd}
                  //                                               // viewDate={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}

                  //                                               timeFormat={false}
                  //                                               inputProps={{ placeholder: "Select End Date" }}
                  //                                               value={moment(dateEndSelectionTrend, 'YYYY-MM-DD')}
                  //                                               defaultValue ={moment(dateEndItemTrend, 'YYYY-MM-DD')}
                  //                                               onChange={handleDateEndTrend}
                  //                                               dateFormat='YYYY-MM-DD'
                  //                                               closeOnSelect 
                  //                                               isValidDate={ validEnd } 

                  //                                             />
                  //                                         </GridItem>

                  //                                       </GridContainer >
                                                                  
                  //                                       </CardBody>
                  //                                     </Card>

                  //                                   </GridItem>
                                                  
                  //                               </GridContainer>



                  //                             </div>
                  //                           )
                                          
                  //                       }


                                        
                  //                     ]}
                  //                   /> 

                  //                 </GridItem>

                  //                 {/* Advanced Model Submission */}
                  //                 <GridContainer >

                  //                   <GridItem 
                  //                       xs={12}
                  //                       container
                  //                       direction="row"
                  //                       justify="flex-end"
                  //                       alignItems="center">

                  //                     <Button color="pinterest" onClick={onClickTrendChartDataAdvance}>
                  //                       <RefreshIcon className={classes.icons} />
                  //                         Display Chart
                  //                     </Button>

                  //                   </GridItem>

                  //                 </GridContainer>


                                
                  //             </GridContainer>



                  //       </div>
                  // }

                ]}
                

      />


  </GridItem>


  </GridContainer>




  <GridContainer>

    <GridItem xs={12} sm={12} md={12} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Star Rating",
                // tabIcon: TrendingUpIcon,
                tabContent: (!trendIsLoading ? 
                  <div>
                    <br/>
                    <Bar 
                      height={50} data={starRatingDataSet}  
                      options={starRatingDetailBarOption} /> 
                  </div>
                

                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!trendIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={starRatingTableColumn}
                        data={starRatingTableDataSet}

                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={starRatingDataRaw} filename={`${"Star_Rating_Data"}${".csv"}`}>
                    
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

    </GridItem>


    </GridContainer>
          
          
    




    </div>
    </div>
  );
}
