/*eslint-disable*/

import React from "react";

import PropTypes from "prop-types";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"

// 3rd Party Component
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from "@material-ui/core/InputAdornment";
import ModelIcon from "@material-ui/icons/Devices";
import EmailIcon from "@material-ui/icons/Email";
import WebsiteIcon from "@material-ui/icons/Language";
import BrandIcon from "@material-ui/icons/LocalOffer";
import SendIcon from "@material-ui/icons/Send";

// @material-ui/icons
import RefreshIcon from "@material-ui/icons/Refresh";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DownloadIcon from "@material-ui/icons/GetApp";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";




import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStyles = makeStyles(styles);

export default function NewModelRequest() {

  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"New Model Request"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])



  // New Model Submission
  const [brand, setBrand] = React.useState('');
  const [model, setModel] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [email, setEmail] = React.useState('');

  // Model Update Submission
  const [brand2, setBrand2] = React.useState('');
  const [model2, setModel2] = React.useState('');
  const [website2, setWebsite2] = React.useState('');
  const [email2, setEmail2] = React.useState('');

  // Alert/Modal
  const [alert, setAlert] = React.useState(null);

  // Loading State 
  const [viewTableIsLoading, setViewTableIsLoading] = React.useState(false);

  // Model Table Data
  const [modelTableData, setModelTableData] = React.useState([]);


  const onSubmitData = (event) => {
    event.preventDefault();

    if((brand === "") || (model === "") || (website === "") || (email === "")) {

      failAlert();

    }else{

      // Send Data to REST API
      var restGETurl1 = `${"brand="}${brand}${"&model="}${model}${"&website="}${website}${"&email="}${email}`;
      restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
      restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

      const axios = require('axios');
      axios.get(`${SMATrestAPI.newModelRequest[0].modelRequest}${restGETurl1}`).then(function (response) { 
        // handle success

        // Confirmation
        successAlert();

        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed

      });

    }

    


  };


  const onSubmitData2 = (event) => {
    event.preventDefault();

    if((brand2 === "") || (model2 === "") || (website2 === "") || (email2 === "")) {

      failAlert2();

    }else{

      // Send Data to REST API
      var restGETurl1 = `${"brand="}${brand2}${"&model="}${model2}${"&website="}${website2}${"&email="}${email2}`;
      restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
      restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

      const axios = require('axios');
      axios.get(`${SMATrestAPI.newModelRequest[0].modelUpdate}${restGETurl1}`).then(function (response) { 
        // handle success

        // Confirmation
        successAlert2();

        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed

      });

    }

    


  };




  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Request Submitted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
      >
        {`${'Brand = '}${brand}`}
        <br/>
        {`${'Model = '}${model}`}
        <br/>
        {`${'Website = '}${website}`}
        <br/>
        {`${'Email = '}${email}`}
      </SweetAlert>
    );
  };

  const failAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Missing Information"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
      >
        {`${'Brand = '}${brand}`}
        <br/>
        {`${'Model = '}${model}`}
        <br/>
        {`${'Website = '}${website}`}
        <br/>
        {`${'Email = '}${email}`}
      </SweetAlert>
    );
  };


  const successAlert2 = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Request Submitted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
      >
        {`${'Brand = '}${brand2}`}
        <br/>
        {`${'Model = '}${model2}`}
        <br/>
        {`${'Website = '}${website2}`}
        <br/>
        {`${'Email = '}${email2}`}
      </SweetAlert>
    );
  };

  const failAlert2 = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Missing Information"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
      >
        {`${'Brand = '}${brand2}`}
        <br/>
        {`${'Model = '}${model2}`}
        <br/>
        {`${'Website = '}${website2}`}
        <br/>
        {`${'Email = '}${email2}`}
      </SweetAlert>
    );
  };


  const hideAlert = () => {
    setAlert(null);
  };


  React.useEffect(() => {
    const axios = require('axios');

    axios.get(`${SMATrestAPI.newModelRequest[0].modelTable}`).then(function (response) { 
      // handle success

      setModelTableData(response.data.modelTable);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });

  }, [])



// Model table - Table using material-table


const modelTableColumn = [
  {
    title: 'Brand',
    field: 'brand'
  },
  {
    title: 'Product Category',
    field: 'category'
  },
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Website',
    field: 'website'
  },
  {
    title: 'Country',
    field: 'country'
  },
  {
    title: 'Earliest Review Date',
    field: 'startDate'
  },
  {
    title: 'Latest Review Date',
    field: 'endDate'
  }
];



const modelDataTableDataSet = modelTableData.map((data) => {
  return ({
    //id: data.id,
    brand: data.Brand,
    category: data['Product.Category'],
    country: data['Country'],
    model: data['Model'],
    website: data['Website'],
    startDate: data['Earliest Review Date'],
    endDate: data['Latest Review Date']
  })
});

 


  const classesSweetAlert = useStylesSweetAlert();
  const classes = useStyles();

  return (
    <div>

      <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model Update</b> </Button>
      </GridItem>

      <GridContainer>
   
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}><strong>New Model Request</strong></h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* onSubmit={e => { e.preventDefault()  */}
              <form  id='newModel-form' onSubmit={onSubmitData}> 
                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Brand</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="brandID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Sony",
                        onBlur: event => {setBrand(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><BrandIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Model</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="modelID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "WH1000XM4, WH1000XM3",
                        onBlur: event => {setModel(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><ModelIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Website</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="websiteID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Amazon, Best Buy, Flipkart, JD",
                        onBlur: event => {setWebsite(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><WebsiteIcon/></InputAdornment>)
                      }}
                      
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Email</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="emailID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "John.Doe@sony.com",
                        onBlur: event => {setEmail(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><EmailIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>

              </form>
            </CardBody>

            <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                <Button color="pinterest" form='newModel-form' type="submit">
                  <SendIcon className={classes.icons} />
                    Submit
                </Button>
                
              

              </GridItem>

            <br/>
          </Card>
        </GridItem>


        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}><strong>Model Update Request</strong></h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* onSubmit={e => { e.preventDefault()  */}
              <form  id='modelUpdate-form' onSubmit={onSubmitData2}> 
                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Brand</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="brandID2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Sony",
                        onBlur: event => {setBrand2(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><BrandIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Model</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="modelID2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "WH1000XM4, WH1000XM3",
                        onBlur: event => {setModel2(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><ModelIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Website</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="websiteID2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Amazon, Best Buy, Flipkart, JD",
                        onBlur: event => {setWebsite2(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><WebsiteIcon/></InputAdornment>)
                      }}
                      
                    />
                  </GridItem>
                </GridContainer>


                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Email</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="emailID2"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "John.Doe@sony.com",
                        onBlur: event => {setEmail2(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><EmailIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>

              </form>
            </CardBody>

            <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                <Button color="pinterest" form='modelUpdate-form' type="submit">
                  <SendIcon className={classes.icons} />
                    Submit
                </Button>
                
              

              </GridItem>

            <br/>
          </Card>
        </GridItem>

        {/* Sweet Alert  */}
        {alert}
        
      </GridContainer>


      <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model Status</b> </Button>
      </GridItem>

      <GridContainer>
   
      <GridItem xs={12} sm={12} md={12} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            
            tabs={[
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                                
                                <div style={{ maxWidth: '100%' }}>
                                        <MaterialTable
                                          columns={modelTableColumn}
                                          data={modelDataTableDataSet}
                                          title="Model List"
                                          options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            filtering: true,
                                            showTitle: false,
                                            searchAutoFocus: true,
                                            pageSize: 10
                                          }}
         
                                        />
                                  </div>



                            <br/>
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                       
                              <CSVLink data={modelTableData} filename={"New Model Request - Model List.csv"}>
                                  <Button color="warning"> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>

                              </CSVLink>


                            </GridItem>


                          </GridContainer>

                              </div>
                   )

                
              }
            ]}
          /> 

        </GridItem>
        
      </GridContainer>




    </div>



                    
  );



}
