/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";

import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

const hist = createBrowserHistory();


const RequireAuth = ({ children }) => {
  var cookie = ("; "+document.cookie).split("; auth=").pop().split(";").shift();

  if (cookie !== "true") {
    return (
        <div>
          <Route path="/auth" component={AuthLayout} />
          <Route path="/rtl" component={RtlLayout} />
          <Redirect from="/" to="/auth/login-page" />  
        </div>
    )
  }

  return children;
};


ReactDOM.render(


  <Router history={hist}>
    <Switch>

      <RequireAuth>
        <Route path="/admin" component={AdminLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/rtl" component={RtlLayout} />
        <Redirect from="/" to="/admin/home" />
      </RequireAuth>
      
    </Switch>
  </Router>,
  document.getElementById("root")
);





// ReactDOM.render(
//   <Router history={hist}>
//     <Switch>
//       {/* <Route path="/rtl" component={RtlLayout} /> */}
//       <Route path="/auth" component={AuthLayout} />
//       <Route path="/admin" component={AdminLayout} />
//       {/* <Redirect from="/" to="/admin/home" /> */}
//       <Redirect from="/" to="/auth/login-page" />
//     </Switch>
//   </Router>,
//   document.getElementById("root")
// );
