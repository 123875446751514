import React, { Fragment } from "react";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Instruction from "components/Instruction/Instruction.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import CalculationIcon from "@material-ui/icons/Grade";
import BubbleIcon from "@material-ui/icons/BubbleChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/Loyalty";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import RatingIcon from "@material-ui/icons/RateReview";
import FilterIcon from "@material-ui/icons/FilterList";
import Warning from "@material-ui/icons/Warning";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
import SoftwareIcon from "@material-ui/icons/Apps";
import PictureIcon from "@material-ui/icons/Image";
import PriceIcon from "@material-ui/icons/AttachMoney";
import DesignIcon from "@material-ui/icons/DataUsage";
import FeatureIcon from "@material-ui/icons/Settings";
import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar, Pie } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);


const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="h4" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};






export default function AppRatingAnalytic() {

  
  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"App Rating Analytic"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])

  

  // Loading Param
  const [progress, setProgress] = React.useState(10);


  // Loading by Section
  const [appDataDataIsLoading, setAppDataDataIsLoading] = React.useState(false);

  // filters
  const [allFilters, setAllFilters] = React.useState([]);
  
  const [appNameItems, setAppNameItems] = React.useState([]);
  const [appNameSelection, setAppNameSelection] = React.useState('');

  const [osItems, setOsItems] = React.useState([]);
  const [osSelection, setOsSelection] = React.useState([]);

  const [countryItems, setCountryItems] = React.useState([]);
  const [countrySelection, setCountrySelection] = React.useState([]);

  const [versionItems, setVersionItems] = React.useState([]);
  const [versionSelection, setVersionSelection] = React.useState([]);

  const [dateStartItem, setDateStartItem] = React.useState('');
  const [dateStartSelection, setDateStartSelection] = React.useState('');

  const [dateEndItem, setDateEndItem] = React.useState('');
  const [dateEndSelection, setDateEndSelection] = React.useState('');


  //Page Sections

  // App Website Rating Trend
  const [appWebsiteTrendData, setAppWebsiteTrendData] = React.useState([]);

  // App Summary
  const [appSummaryTrendData, setAppSummaryTrendData] = React.useState([]);
  const [appSummaryTrendPercentCumData, setAppSummaryTrendPercentCumData] = React.useState([]);
  const [appSummaryStarRating, setAppSummaryStarRating] = React.useState([]);
  const [appSummaryColor, setAppSummaryColor] = React.useState([]);
  const [appSummaryStarRatingPie, setAppSummaryStarRatingPie] = React.useState([]);
  
  // App Detail
  const [appDetailTopicComparison, setAppDetailTopicComparison] = React.useState([]);
  const [appDetailAppVersion, setAppDetailAppVersion] = React.useState([]);
  const [appDetailPositiveDescription, setAppDetailPositiveDescription] = React.useState([]);
  const [appDetailNegativeDescription, setAppDetailNegativeDescription] = React.useState([]);

  // App Table
  const [appTableRawData, setAppTableRawData] = React.useState([]);




  React.useEffect(() => {
  
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.appRatingAnalytic[0].Filters}`).then(function (response) { 
      // handle success
  
      setAllFilters(response.data.getFilter);

      let appNameFilter = response.data.getFilter.map(data => data['App_Name']);
      let uniqueAppName = [...new Set(appNameFilter)];

      setAppNameItems(uniqueAppName);



      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });
  
  
  
  
    }, [])



  const handleAppName= event => {
    setAppNameSelection(event.target.value);

    let OsNameFilter = allFilters.map(data => data['OS']);
    let uniqueOs = [...new Set(OsNameFilter)];

    setOsSelection([]);
    setOsItems(uniqueOs);
    setCountrySelection([]);
    setVersionSelection([]);
    setDateStartSelection('');
    setDateEndSelection('');

  };

  const handleOS = event => {
    setOsSelection(event.target.value);

    let countryFilter = allFilters.filter(data => data['App_Name'] === appNameSelection);
    countryFilter = countryFilter.filter(data => data['OS'] == event.target.value);

    let uniqueCountry = countryFilter.map(data => data['Country']);
    uniqueCountry = [...new Set(uniqueCountry)];
    uniqueCountry = uniqueCountry.sort();

    setCountrySelection([]);
    setCountryItems(uniqueCountry);
    setVersionSelection([]);
    setDateStartSelection('');
    setDateEndSelection('');

  };


  const handleCountry = event => {
    setCountrySelection(event.target.value);

    let versionFilter = allFilters.filter(data => data['App_Name'] === appNameSelection);
    versionFilter = versionFilter.filter(data => data['OS'] == osSelection);

    // If user only selects 1 country
    if(event.target.value.length === 1){
      versionFilter = versionFilter.filter(data => data['Country'] == event.target.value);
    }

    // If user only selects multiple countries. filter by array
    if(event.target.value.length < 1){
      let arrayList = [event.target.value];

      versionFilter = versionFilter.filter(function(data) {
        return [arrayList].includes(data['Country'])
      });

    }

  
    let uniqueVersion = versionFilter.map(data => data['App_Version']);
    uniqueVersion = [...new Set(uniqueVersion)];
    uniqueVersion = uniqueVersion.sort();
    
    setVersionSelection([]);
    setVersionItems(uniqueVersion);

    setDateStartSelection('');
    setDateEndSelection('');
  };


  const handleVersion = event => {
    setVersionSelection(event.target.value);


    let restGETurl = `${"appName="}${appNameSelection}${"&os="}${osSelection}${"&country="}${countrySelection}${"&version="}${event.target.value}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.appRatingAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
      // handle success
  
     setDateStartItem(response.data.minDate);
     setDateEndItem(response.data.maxDate);
  
     setDateStartSelection(response.data.minDate);
     setDateEndSelection(response.data.maxDate);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  };



  const handleDateStart = event => {

    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);

      setDateStartSelection(aDate);
    }

  };

  const handleDateEnd = event => {
    

    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
    
      setDateEndSelection(aDate);
    }

  };


  var yesterday = moment(dateStartItem, 'YYYY-MM-DD').subtract(1, "day");
  function validStart(current) {
    return current.isAfter(yesterday);
  };

  var tommorrow = moment(dateEndItem, 'YYYY-MM-DD').add(1, "day");
  function validEnd(current) {
    return current.isBefore(tommorrow);
  };





  const onClickChartData = event => {

    const axios = require('axios');

    setAppDataDataIsLoading(true);
    let restGETurl = `${"appName="}${appNameSelection}${"&os="}${osSelection}${"&country="}${countrySelection}${"&version="}${versionSelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.appRatingAnalytic[0].chartData}${restGETurl}`).then(function (response) { 
      // handle success

      console.log(response.data.trendData);
  
      setAppSummaryTrendData(response.data.trendData);
      setAppSummaryTrendPercentCumData(response.data.trendDataPercentCum);
      setAppSummaryStarRating(response.data.starRating);
      setAppSummaryColor(response.data.color);
      setAppSummaryStarRatingPie(response.data.starRatingPie);

      setAppDetailTopicComparison(response.data.topicList);
      setAppDetailAppVersion(response.data.appVersion);
      setAppDetailPositiveDescription(response.data.topicDescriptionPos);
      setAppDetailNegativeDescription(response.data.topicDescriptionNeg);

      setAppTableRawData(response.data.rawData);
 
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      //setProgress(100);

      //setAppDataDataIsLoading(false);

    });





    let restGETur2 = `${"appName="}${appNameSelection}${"&os="}${osSelection}${"&country="}${countrySelection}`;
    restGETur2 = restGETur2.replaceAll(",", '%2C'); // replace comma for url
    restGETur2 = restGETur2.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.appRatingAnalytic[0].websiteRatingTrend}${restGETur2}`).then(function (response) { 
      // handle success
  
      setAppWebsiteTrendData(response.data.trendData);
      console.table(response.data.trendData);

 
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      //setProgress(100);
      setAppDataDataIsLoading(false);

    });






  }



  // Obtain a list of unique countries from the selected list
  let uniqueCountries = [...new Set(appWebsiteTrendData.map(data => data.Country))];

  // Set the data in to individiual Country
  let uniqueCountryUS = appWebsiteTrendData.filter(data => data.Country === "United States");
  let uniqueCountryJP = appWebsiteTrendData.filter(data => data.Country === "Japan");
  let uniqueCountryCN = appWebsiteTrendData.filter(data => data.Country === "China");
  let uniqueCountrySG = appWebsiteTrendData.filter(data => data.Country === "Singapore");
  let uniqueCountryCA = appWebsiteTrendData.filter(data => data.Country === "Canada");
  let uniqueCountryMX = appWebsiteTrendData.filter(data => data.Country === "Mexico");
  let uniqueCountryIT = appWebsiteTrendData.filter(data => data.Country === "Italy");
  let uniqueCountryFR = appWebsiteTrendData.filter(data => data.Country === "France");
  let uniqueCountryDE = appWebsiteTrendData.filter(data => data.Country === "Germany");
  let uniqueCountryAU = appWebsiteTrendData.filter(data => data.Country === "Australia");
  let uniqueCountryMY = appWebsiteTrendData.filter(data => data.Country === "Malaysia");
  let uniqueCountryIN = appWebsiteTrendData.filter(data => data.Country === "India");
  let uniqueCountryVN = appWebsiteTrendData.filter(data => data.Country === "Vietnam");


  // Website Rating Trend - Line Chart

  let appWebsiteTrendDataLabel = [...new Set(appWebsiteTrendData.map(data => data.Date))];

  let appWebsiteTrendDataSet = {
    type: 'line',
    labels: appWebsiteTrendDataLabel,
    datasets: [

      // Use spread to map the data for each country if the Country name is present in the selection list
      ...(uniqueCountries.includes("United States") ? [{
        label: 'United States',
        data: uniqueCountryUS.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(145, 158, 7)',
        borderColor: 'rgba(145, 158, 7, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Japan") ? [{
        label: 'Japan',
        data: uniqueCountryJP.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(9, 142, 111)',
        borderColor: 'rgba(9, 142, 111, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("China") ? [{
        label: 'China',
        data: uniqueCountryCN.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(140, 47, 11)',
        borderColor: 'rgba(140, 47, 11, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Singapore") ? [{
        label: 'Singapore',
        data: uniqueCountrySG.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(5, 32, 130)',
        borderColor: 'rgba(5, 32, 130, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Canada") ? [{
        label: 'Canada',
        data: uniqueCountryCA.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(10, 66, 145)',
        borderColor: 'rgba(10, 66, 145, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Mexico") ? [{
        label: 'Mexico',
        data: uniqueCountryMX.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(201, 22, 229)',
        borderColor: 'rgba(201, 22, 229, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Italy") ? [{
        label: 'Italy',
        data: uniqueCountryIT.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(229, 6, 151)',
        borderColor: 'rgba(229, 6, 151, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("France") ? [{
        label: 'France',
        data: uniqueCountryFR.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(24, 147, 10)',
        borderColor: 'rgba(24, 147, 10, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Germany") ? [{
        label: 'Germany',
        data: uniqueCountryDE.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(3, 112, 52)',
        borderColor: 'rgba(3, 112, 52, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Australia") ? [{
        label: 'Australia',
        data: uniqueCountryAU.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(132, 1, 38)',
        borderColor: 'rgba(132, 1, 38, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Malaysia") ? [{
        label: 'Malaysia',
        data: uniqueCountryMY.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(1, 57, 135)',
        borderColor: 'rgba(1, 57, 135, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("India") ? [{
        label: 'India',
        data: uniqueCountryIN.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(43, 158, 4)',
        borderColor: 'rgba(43, 158, 4, 0.6)',

      }]: [{}]),

      ...(uniqueCountries.includes("Vietnam") ? [{
        label: 'Vietnam',
        data: uniqueCountryVN.map(data => data['Website_Rating']) ,
        fill: false,
        backgroundColor: 'rgb(142, 2, 21)',
        borderColor: 'rgba(142, 2, 21, 0.6)',

      }]: [{}])




    ]

  }


  const appWebsiteTrendDataOptions = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false ,
    spanGaps: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
  
      xAxes: [{
        type: 'time'//,
        // time: {
        //     unit: 'month'
        // }
    }]
    }

  }

  // App Summary Star Rating Trend Table using react-data-table-component

  const appWebsiteTrendDataTableDataSet = appWebsiteTrendData.map((data) => {
      return ({
        id: data.id,
        date: data.Date,
        brand: data['Brand'],
        appName: data['App_Name'],
        os: data['OS'],
        country: data['Country'],
        rating: data['Website_Rating']
      })
    });
    
  const appWebsiteTrendDataTableColumn = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: 'Brand',
      selector: 'brand',
      sortable: true,
      center: true
    },
    {
      name: 'App Name',
      selector: 'appName',
      sortable: true,
      center: true
    },
    {
      name: 'OS',
      selector: 'os',
      sortable: true,
      center: true
    },
    {
      name: 'Country',
      selector: 'country',
      sortable: true,
      center: true
    },
    {
      name: 'Website Rating',
      selector: 'rating',
      sortable: true,
      center: true
    }
  ];




  /////////////////////////////


  // App Summary Star Rating Trend - Line Chart

  let appSummaryTrendPercentCumDataLabel = appSummaryTrendPercentCumData.map(data => data.Date);

  let appSummaryTrendPercentCumDataSet = {
    type: 'line',
    labels: appSummaryTrendPercentCumDataLabel,
    datasets: [
      {
        label: '5-Star',
        data: appSummaryTrendPercentCumData.map(data => data['5-Star']) ,
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        yAxisID: 'A'
      },

      {
        label: '4-Star',
        data: appSummaryTrendPercentCumData.map(data => data['4-Star']),
        fill: false,
        backgroundColor: 'rgb(36, 222, 96)',
        borderColor: 'rgba(36, 222, 96, 0.6)',
        yAxisID: 'A'
      },

      {
        label: '3-Star',
        data: appSummaryTrendPercentCumData.map(data => data['3-Star']),
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        yAxisID: 'A'
      },

      {
        label: '2-Star',
        data: appSummaryTrendPercentCumData.map(data => data['2-Star']),
        fill: false,
        backgroundColor: 'rgb(236, 50, 50)',
        borderColor: 'rgba(236, 50, 50, 0.6)',
        yAxisID: 'A'
      },

      {
        label: '1-Star',
        data: appSummaryTrendPercentCumData.map(data => data['1-Star']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        yAxisID: 'A'
      },

      {
        label: 'Cumulative Total',
        data: appSummaryTrendPercentCumData.map(data => data['Cumulative Total']),
        fill: false,
        backgroundColor: 'rgb(129, 130, 133)',
        borderColor: 'rgba(129, 130, 133, 0.6)',
        type: 'bar',
        yAxisID: 'B'
      }


    ]

  }


  const appSummaryTrendPercentCumDataOptions = {


  // Drill Down
  onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


        // reset the data
        // setOpenModalStarRatingTrend(true); // Show Pop-up
        // setUniqueTrendIndividualSentenceDrillDownData([]);
        // setUniqueTrendPositiveDrillDownData([]);
        // setUniqueTrendNegativeDrillDownData([]);
        // setModalTitle('');
        // setDrillDownIsLoading(true); 

        // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}`;
        // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
        // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url

        // const axios = require('axios');
        // axios.get(`${SMATrestAPI.starRatingAnalytic[0].trendDrillDown}${drillDownUrl}`).then(function (response) { 
        //   // handle success
        //   console.log(response.data);
        //   setUniqueTrendIndividualSentenceDrillDownData(response.data.individualSentenceDrillDown);
        //   setUniqueTrendPositiveDrillDownData(response.data.topicPosList);
        //   setUniqueTrendNegativeDrillDownData(response.data.topicNegList);
        //   setModalTitle(xLabel);
          
        // }).catch(function (error) { 
        //   // handle error
        //   console.log(error);
        // }).then(function () { 
        //   // always executed
          
        //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
        // });

      }

    },


    spanGaps: true,
    scales: {
      yAxes: [
        {
          id: 'A',
          type: 'linear',
          position: 'left',
          ticks: {
            min: 0,
            max: 100,
            callback: function(value) {
              return value + "%"
            }
          }
        },
        {
          id: 'B',
          type: 'linear',
          position: 'right'
        }
      ],

      xAxes: [{
        type: 'time',
        time: {
            unit: 'month'
        }
    }]
    },
  }

  // App Summary Star Rating Trend Table using react-data-table-component

  const appSummaryTrendPercentCumDataTableDataSet = appSummaryTrendPercentCumData.map((data) => {
      return ({
        id: data.id,
        date: data.Date,
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total'],
        cumulative: data['Cumulative Total']
      })
    });
    
  const appSummaryTrendPercentCumDataTableColumn = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    },
    {
      name: 'Cumulative Total',
      selector: 'cumulative',
      sortable: true,
      center: true
    }
  ];



  // App Summary Star Rating - Doughnut Chart

  let appSummaryStarRatingPieTrendDataSet = {
    type: 'doughnut',
    labels: [
      '5-Star',
      '4-Star',
      '3-Star',
      '2-Star',
      '1-Star'
    ],
    datasets: [
      {
        data: [
          appSummaryStarRatingPie.map(data => data['5-Star']), 
          appSummaryStarRatingPie.map(data => data['4-Star']), 
          appSummaryStarRatingPie.map(data => data['3-Star']), 
          appSummaryStarRatingPie.map(data => data['2-Star']), 
          appSummaryStarRatingPie.map(data => data['1-Star'])
        ],
        backgroundColor: [
          'rgb(26, 115, 55)',
          'rgb(36, 222, 96)',
          'rgb(245, 200, 15)',
          'rgb(236, 50, 50)',
          'rgb(163, 30, 30)'
          ],
        //options: options
      }],
      text: appSummaryStarRating
    } 

    let appSummaryStarRatingPieOptions = {

      // Drill Down
      onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);


          // // reset the data
          // setOpenModalStarRatingPie(true); // Show Pop-up
          // setStarRatingPieIndividualSentenceDrillDownData([]);
          // setStarRatingPiePositiveDrillDownData([]);
          // setStarRatingPieNegativeDrillDownData([]);
          // setModalTitle('');
          // setDrillDownIsLoading(true); 

          // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownLabel="}${xLabel}`;
          // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
          // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
          // const axios = require('axios');
          // axios.get(`${SMATrestAPI.starRatingAnalytic[0].starLabelDrillDown}${drillDownUrl}`).then(function (response) { 
          //   // handle success

          //   setStarRatingPieIndividualSentenceDrillDownData(response.data.individualSentenceDrillDown);
          //   setStarRatingPiePositiveDrillDownData(response.data.topicPosList);
          //   setStarRatingPieNegativeDrillDownData(response.data.topicNegList);
          //   setModalTitle(xLabel);
            
          // }).catch(function (error) { 
          //   // handle error
          //   console.log(error);
          // }).then(function () { 
          //   // always executed
            
          //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
          // });

        }

      }

    }


  // App Summary Star Rating Table using react-data-table-component 

  const appSummaryStarRatingPieTableDataSet = [{
      fiveStar: appSummaryStarRatingPie.map(data => data['5-Star']), 
      fourStar: appSummaryStarRatingPie.map(data => data['4-Star']), 
      threeStar: appSummaryStarRatingPie.map(data => data['3-Star']), 
      twoStar: appSummaryStarRatingPie.map(data => data['2-Star']), 
      oneStar: appSummaryStarRatingPie.map(data => data['1-Star']), 
      rating: appSummaryStarRating
  }]
  ;
    

  const appSummaryStarRatingPieTableColumn = [
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Rating',
      selector: 'rating',
      sortable: true,
      center: true
    }
  ];




  // App Detail - Topic Comparison List - Stack Bar Chart

  let appDetailTopicComparisonDataSet = {
    type: 'bar',
    labels: appDetailTopicComparison.map(data => data['Topic']),
    datasets: [
      {
        label: 'Positive',
        data: appDetailTopicComparison.map(data => data['Positive']),
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        stack: 'stack1'
      },

      {
        label: 'Negative',
        data: appDetailTopicComparison.map(data => data['Negative']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        stack: 'stack1'
      }


    ]

  }

  let appDetailTopicComparisonOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


        // // reset the data
        // setOpenModalTopicOverviewTopicComparisonSentiment(true); // Show Pop-up
        // setCategoryOverviewTopicComparisonSentimentDrillDownData([]);
        // setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData([]);
        // setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData([]);
        // setModalTitle('');
        // setDrillDownIsLoading(true); 

        // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"all"}${"&drillDownTopic="}${xLabel}`;
        // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
        // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
        // const axios = require('axios');
        // axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
        //   // handle success

        //   setCategoryOverviewTopicComparisonSentimentDrillDownData(response.data.individualSentenceDrillDown);
        //   setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData(response.data.topicDescriptionPos);
        //   setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData(response.data.topicDescriptionNeg);
        //   setModalTitle(`${"Topic = "}${xLabel}`);
          
        // }).catch(function (error) { 
        //   // handle error
        //   console.log(error);
        // }).then(function () { 
        //   // always executed
          
        //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
        // });

      }

    },


    scales: {
      xAxes: [{
          stacked: true
      }],
      yAxes: [{
          stacked: true
      }]
  }


  }


  // App Detail - Topic Comparison List Table using react-data-table-component

  const appDetailTopicComparisonTableDataSet = appDetailTopicComparison.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });
    
  const appDetailTopicComparisonTableColumn = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Positive',
      selector: 'positive',
      sortable: true,
      center: true
    },
    {
      name: 'Negative',
      selector: 'negative',
      sortable: true,
      center: true
    },
    {
      name: 'Neutral',
      selector: 'neutral',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];



  function appDetailTopicComparisonReactTable() {
    return <DataTable
    title=""
    columns={appDetailTopicComparisonTableColumn}
    data={appDetailTopicComparisonTableDataSet}
    //conditionalRowStyles={conditionalRowStyles}
    // pagination={true}
    // paginationPerPage={10}
    // paginationComponentOptions={{ noRowsPerPage: true }}
    fixedHeader={true}
    //fixedHeaderScrollHeight={"445px"}
    fixedHeaderScrollHeight={"445px"}
    noHeader={true}
    />
  }



// App Detail - Version Comparison List - Stack Bar Chart

    let appDetailAppVersionDataSet = {
      type: 'bar',
      labels: appDetailAppVersion.map(data => data['Version']),
      datasets: [
        {
          label: '5-Star',
          data: appDetailAppVersion.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
  
        {
          label: '4-Star',
          data: appDetailAppVersion.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
        {
          label: '3-Star',
          data: appDetailAppVersion.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
  
        {
          label: '2-Star',
          data: appDetailAppVersion.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
  
        {
          label: '1-Star',
          data: appDetailAppVersion.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
  
  
      ]
  
    }
  
    let appDetailAppVersionOption = {
  
      // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);
  
  
          // // reset the data
          // setOpenModalTopicOverviewTopicComparisonSentiment(true); // Show Pop-up
          // setCategoryOverviewTopicComparisonSentimentDrillDownData([]);
          // setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData([]);
          // setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData([]);
          // setModalTitle('');
          // setDrillDownIsLoading(true); 
  
          // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"all"}${"&drillDownTopic="}${xLabel}`;
          // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
          // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
          // const axios = require('axios');
          // axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
          //   // handle success
  
          //   setCategoryOverviewTopicComparisonSentimentDrillDownData(response.data.individualSentenceDrillDown);
          //   setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData(response.data.topicDescriptionPos);
          //   setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData(response.data.topicDescriptionNeg);
          //   setModalTitle(`${"Topic = "}${xLabel}`);
            
          // }).catch(function (error) { 
          //   // handle error
          //   console.log(error);
          // }).then(function () { 
          //   // always executed
            
          //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
          // });
  
        }
  
      },
  
  
      scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    }
  
  
    }
  
  
    // App Detail - Version Comparison List Table using react-data-table-component
  
    const appDetailAppVersionTableDataSet = appDetailAppVersion.map((data) => {
        return ({
          id: data.id,
          version: data['Version'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    const appDetailAppVersionTableColumn = [
      {
        name: 'Version',
        selector: 'version',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];
  
  
  
    function appDetailAppVersionReactTable() {
      return <DataTable
      title=""
      columns={appDetailAppVersionTableColumn}
      data={appDetailAppVersionTableDataSet}
      //conditionalRowStyles={conditionalRowStyles}
      // pagination={true}
      // paginationPerPage={10}
      // paginationComponentOptions={{ noRowsPerPage: true }}
      fixedHeader={true}
      //fixedHeaderScrollHeight={"445px"}
      fixedHeaderScrollHeight={"445px"}
      noHeader={true}
      />
    }




  // Topic Overview - Positive Wordcloud

  const appDetailPositiveDescriptionWords = appDetailPositiveDescription.map((data) => {
    return({
      text: data['WORD'],
      value: data['FREQ']
    })
  })

  const appDetailPositiveDescriptionCallbacks = {
    onWordClick: (word) => {
      console.log(word.text);

      // reset the data
      // setOpenModalTopicOverviewTopicComparisonSentiment(true); // Show Pop-up
      // setCategoryOverviewTopicComparisonSentimentDrillDownData([]);
      // setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData([]);
      // setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData([]);
      // setModalTitle('');
      // setDrillDownIsLoading(true); 

      // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"Positive"}${"&drillDownTopic="}${word.text}`;
      // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      // const axios = require('axios');
      // axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
      //   // handle success

      //   setCategoryOverviewTopicComparisonSentimentDrillDownData(response.data.individualSentenceDrillDown);
      //   setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData(response.data.topicDescriptionPos);
      //   setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData(response.data.topicDescriptionNeg);
      //   setModalTitle(`${"Topic = "}${word.text}${" / Sentiment = Positive"}`);
        
      // }).catch(function (error) { 
      //   // handle error
      //   console.log(error);
      // }).then(function () { 
      //   // always executed
        
      //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      // });




    }, 



    getWordTooltip: (word) =>
      `The word "${word.text}" appears ${word.value} times.`,
  }

  const appDetailPositiveDescriptionOptions = {
    colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
    enableTooltip: true,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [20, 80],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 0,
    rotations: 1,
    rotationAngles: [0, 0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
  };

  function appDetailPositiveDescriptionWordCloud() {
    return <ReactWordcloud
      callbacks={appDetailPositiveDescriptionCallbacks}
      options={appDetailPositiveDescriptionOptions}
      words={appDetailPositiveDescriptionWords} 
    />
  }


  // Topic Overview - Positive Table using react-data-table-component

  const appDetailPositiveDescriptionTableDataSet = appDetailPositiveDescription.map((data) => {
      return ({
        id: data.id,
        topic: data['WORD'],
        freq: data['FREQ']
      })
    });
    
  const appDetailPositiveDescriptionTableColumn = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Frequency',
      selector: 'freq',
      sortable: true,
      center: true
    }
  ];


  // Topic Overview - Negative Wordcloud

  const appDetailNegativeDescriptionWords = appDetailNegativeDescription.map((data) => {
    return({
      text: data['WORD'],
      value: data['FREQ']
    })
  })

  const appDetailNegativeDescriptionCallbacks = {
    onWordClick: (word) => {
      console.log(word.text);

      // // reset the data
      // setOpenModalTopicOverviewTopicComparisonSentimentNeg(true); // Show Pop-up
      // setCategoryOverviewTopicComparisonSentimentNegDrillDownData([]);
      // setCategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData([]);
      // setCategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData([]);
      // setModalTitle('');
      // setDrillDownIsLoading(true); 

      // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"Negative"}${"&drillDownTopic="}${word.text}`;
      // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      // const axios = require('axios');
      // axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
      //   // handle success

      //   setCategoryOverviewTopicComparisonSentimentNegDrillDownData(response.data.individualSentenceDrillDown);
      //   setCategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData(response.data.topicDescriptionPos);
      //   setCategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData(response.data.topicDescriptionNeg);
      //   setModalTitle(`${"Topic = "}${word.text}${" / Sentiment = Positive"}`);
        
      // }).catch(function (error) { 
      //   // handle error
      //   console.log(error);
      // }).then(function () { 
      //   // always executed
        
      //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      // });




    }, 

    getWordTooltip: (word) =>
      `The word "${word.text}" appears ${word.value} times.`,
  }

  const appDetailNegativeDescriptionOptions = {
    //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
    colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
    enableTooltip: true,
    deterministic: true,
    fontFamily: "impact",
    fontSizes: [20, 80],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 0,
    rotations: 1,
    rotationAngles: [0, 0],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000
  };

  function appDetailNegativeDescriptionWordCloud() {
    return <ReactWordcloud
      callbacks={appDetailNegativeDescriptionCallbacks}
      options={appDetailNegativeDescriptionOptions}
      words={appDetailNegativeDescriptionWords} 
    />
  }


  // Topic Overview - Negative Table using react-data-table-component

  const appDetailNegativeDescriptionTableDataSet = appDetailNegativeDescription.map((data) => {
      return ({
        id: data.id,
        topic: data['WORD'],
        freq: data['FREQ']
      })
    });
    
  const appDetailNegativeDescriptionTableColumn = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Frequency',
      selector: 'freq',
      sortable: true,
      center: true
    }
  ];
  




  // Category Detail -  - View Individual Sentence - Table using material-table


  const appTableRawDataTableColumn = [
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'App Name',
      field: 'appName'
    },
    {
      title: 'App Version',
      field: 'appVersion'
    },
    {
      title: 'Country',
      field: 'country'
    },
    {
      title: 'Stars',
      field: 'stars'
    },
    {
      title: 'Review',
      field: 'review',
      cellStyle: {
        width: 300,
        minWidth: 300
      },
      headerStyle: {
        width: 300,
        minWidth: 300
      }
    },
    {
      title: 'Topic.1',
      field: 'topic1'
    },
    {
      title: 'Topic.2',
      field: 'topic2'
    },
    {
      title: 'Topic 3',
      field: 'topic3'
    },
    {
      title: 'Topic 4',
      field: 'topic4'
    },
    {
      title: 'Topic 5',
      field: 'topic5'
    },
    {
      title: 'Topic 6',
      field: 'topic6'
    },
    {
      title: 'Sentiment',
      field: 'sentimentResult'
    },
    {
      title: 'Positive list',
      field: 'posList'
    },
    {
      title: 'Negative list',
      field: 'negList'
    }
  ];



  const appTableRawDataTableDataSet = appTableRawData.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      appName: data['App_Name'],
      os: data['OS'],
      appVersion: data['App_Version'],
      country: data['Country'],
      stars: data['Stars'],
      review: data['Review'],
      topic1: data['Topic 1'],
      topic2: data['Topic 2'],
      topic3: data['Topic 3'],
      topic4: data['Topic 4'],
      topic5: data['Topic 5'],
      topic6: data['Topic 6'],
      sentimentResult: data['Sentiment.Result'],
      posList: data['Pos_List'],
      negList: data['Neg_List']
    })
  });





  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();


  return (
    <div>

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={6}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <SoftwareIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>App</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="app-select"
                  className={
                    classes.selectLabel
                }>
                  Select App
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={appNameSelection}
                  onChange={handleAppName}
                  inputProps={
                    {
                      name: "appselect",
                      id: "app-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  appNameItems.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon />
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>OS</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="os-select"
                  className={
                    classes.selectLabel
                }>
                  Select OS
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={osSelection}
                  onChange={handleOS}
                  inputProps={
                    {
                      name: "osSelection",
                      id: "os-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  osItems.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


       

        <GridItem xs={12}
          sm={6}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon />
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection}
                  onChange={handleCountry}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={6}
          md={2}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Version</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="version-select"
                  className={
                    classes.selectLabel
                }>
                  Select Version
                </InputLabel>

                <Select 
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper }
                  }}
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={versionSelection}
                  onChange={handleVersion}
                  inputProps={
                    {
                      name: "versionSelection",
                      id: "version-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  versionItems.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 
                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItem, 'YYYY-MM-DD')}
                    onChange={handleDateStart}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart } 
                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItem, 'YYYY-MM-DD')}
                    onChange={handleDateEnd}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 
                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>



        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

            <Button color="pinterest" onClick={onClickChartData}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>
            

          </GridItem>

        </GridContainer>

        <br/>


{/* Website Rating Trend */}

        <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Website Rating Trend</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>

        <GridItem xs={12} sm={12} md={12} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Website Rating Trend",
                  tabIcon: WebsiteIcon,
                  tabContent: (!appDataDataIsLoading ? 
                    <div>
                      <Line 
                        height={350} data={appWebsiteTrendDataSet}  
                        options={appWebsiteTrendDataOptions} /> 
                    </div>
                  

                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!appDataDataIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={appWebsiteTrendDataTableColumn}
                          data={appWebsiteTrendDataTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={appWebsiteTrendData} filename={"Website Rating - Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>



        </GridContainer>




{/* App Summary */}

        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>App Summary</b> </Button>
          </GridItem>

        </GridContainer>


{/* Star Rating Value Box */}

    <GridContainer>
          <GridItem xs={12} sm={6} md={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>5-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {appSummaryStarRatingPie.map(data => data['5-Star']) }
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>4-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {appSummaryStarRatingPie.map(data => data['4-Star']) }
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} md={2}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HandIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>3-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {appSummaryStarRatingPie.map(data => data['3-Star']) }
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} md={2}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>2-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {appSummaryStarRatingPie.map(data => data['2-Star']) }
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} md={2}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>1-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {appSummaryStarRatingPie.map(data => data['1-Star']) }
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6} md={2}>
            <Card>
              <CardHeader color={appSummaryColor} stats icon>
                <CardIcon color={appSummaryColor}>
                  <StarsIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Star Rating</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {appSummaryStarRating} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          </GridContainer>



{/* App Summary Star Trend Chart */}
        <GridContainer>

        <GridItem xs={12} sm={12} md={8} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Category Trend",
                  tabIcon: CategoryIcon,
                  tabContent: (!appDataDataIsLoading ? 
                    <div>
                      <Line 
                        height={100} data={appSummaryTrendPercentCumDataSet}  
                        options={appSummaryTrendPercentCumDataOptions} /> 
                    </div>
                  

                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!appDataDataIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={appSummaryTrendPercentCumDataTableColumn}
                          data={appSummaryTrendPercentCumDataTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={appSummaryTrendPercentCumDataTableDataSet} filename={"App Summary - Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>


        <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Star Rating",
                  tabIcon: TopicIcon,
                  tabContent: (!appDataDataIsLoading ? 
                    <div>
                      
                     <Doughnut 
                       height={215} data={appSummaryStarRatingPieTrendDataSet} options={appSummaryStarRatingPieOptions} /> 

                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!appDataDataIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={appSummaryStarRatingPieTableColumn}
                          data={appSummaryStarRatingPieTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={appSummaryStarRatingPieTableDataSet} filename={"App Summary - Rating Breakdown Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                        }
                      ]}
                    /> 

                </GridItem>


            </GridContainer>




{/* App Detail */}

      <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>App Detail</b> </Button>
      </GridItem>

      </GridContainer>



{/* Topic Comparison & Version Comparison - Stack Bar chart  */}

        <GridContainer>

        <GridItem xs={12} sm={12} md={6}>

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            tabs={[

              {
                tabName: "Topic Sentiment Comparison",
                tabIcon: CompareIcon,
                tabContent: (!appDataDataIsLoading ? 
                  <div> 
                    {/* style={{ height: 300}} */}
                    <HorizontalBar
                      height={200}
                      data={appDetailTopicComparisonDataSet}
                      options={appDetailTopicComparisonOption}
                    />
                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!appDataDataIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={appDetailTopicComparisonTableColumn}
                        data={appDetailTopicComparisonTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"445px"}
                        noHeader={true}
                        />
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={appDetailTopicComparisonTableDataSet} filename={"Topic Sentiment Comparison.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={6}>

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
          
            
            tabs={[
              {
                tabName: "Version Comparison",
                tabIcon: ModelIcon,
                tabContent: (!appDataDataIsLoading ? 
                  <div> 
                    {/* style={{ height: 300}} */}
                    <HorizontalBar
                      height={200}
                      data={appDetailAppVersionDataSet}
                      options={appDetailAppVersionOption}
                    />
                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },

              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!appDataDataIsLoading ? 
                    <div>
                      {appDetailAppVersionReactTable()}
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={appDetailAppVersionTableDataSet} filename={"Topic Comparison List.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

        </GridItem>



      </GridContainer>



{/* Topic Overview - Wordcloud  */}

      <GridContainer>

      <GridItem xs={12} sm={12} md={6} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="success"
          
          tabs={[
            {
              tabName: "Positive Sentiment List",
              tabIcon: PositiveIcon,
              tabContent: (!appDataDataIsLoading ? 
                <div style={{ height: 500}}>
                  {appDetailPositiveDescriptionWordCloud()}
                </div>
              

              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!appDataDataIsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={appDetailPositiveDescriptionTableColumn}
                      data={appDetailPositiveDescriptionTableDataSet}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={appDetailPositiveDescriptionTableDataSet} filename={"Sentiment Overview - Positive.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        /> 

      </GridItem>

      <GridItem xs={12} sm={12} md={6} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="danger"
          
          tabs={[
            {
              tabName: "Negative Sentiment List",
              tabIcon: NegativeIcon,
              tabContent: (!appDataDataIsLoading ? 
                <div style={{ height: 500}}>
                  {appDetailNegativeDescriptionWordCloud()}
                </div>

              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!appDataDataIsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={appDetailNegativeDescriptionTableColumn}
                      data={appDetailNegativeDescriptionTableDataSet}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={appDetailNegativeDescriptionTableDataSet} filename={"Sentiment Overview - Negative.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        /> 

      </GridItem>




      </GridContainer>








{/* App Review Table */}

      <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>App Review Table</b> </Button>
      </GridItem>

      </GridContainer>


      <GridContainer>

        <GridItem xs={12} sm={12} md={12} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
              
              tabs={[
                {
                  tabName: "Raw Data",
                  tabIcon: AssignmentIcon,
                    tabContent: (!appDataDataIsLoading ? 
                      <div>
                        
                        <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                  columns={appTableRawDataTableColumn}
                                  data={appTableRawDataTableDataSet}
                                  title="SMAT Review"
                                  options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    filtering: true,
                                    showTitle: false
                                  }}
                                  
                                 
                                />
                          </div>

                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                      <CSVLink data={appTableRawData} filename={"App Review - Raw Data.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>

        </GridContainer>





    </div>
  );
}
