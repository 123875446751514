import React, { Fragment } from "react";



// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Instruction from "components/Instruction/Instruction.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import CalculationIcon from "@material-ui/icons/Grade";
import BubbleIcon from "@material-ui/icons/BubbleChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/Loyalty";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import RatingIcon from "@material-ui/icons/RateReview";
import FilterIcon from "@material-ui/icons/FilterList";
import Warning from "@material-ui/icons/Warning";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
import SoftwareIcon from "@material-ui/icons/Apps";
import PictureIcon from "@material-ui/icons/Image";
import PriceIcon from "@material-ui/icons/AttachMoney";
import DesignIcon from "@material-ui/icons/DataUsage";
import FeatureIcon from "@material-ui/icons/Settings";
import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import SearchIcon from "@material-ui/icons/Search";



import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar, Pie } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);


export default function SocialAnalytic() {

  
  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Social Analytic"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])



  // Loading Param
  const [progress, setProgress] = React.useState(10);

  // Loading by Section
  const [socialChartUIsLoading, setSocialChartUIsLoading] = React.useState(false);
  const [topicInsightIsLoading, setTopicInsightIsLoading] = React.useState(false);

  // filters
  const [searchTerms, setSearchTerms] = React.useState([]);
  const [searchTermsSelection, setSearchTermsSelection] = React.useState('');
  
  const [dateStartItem, setDateStartItem] = React.useState('');
  const [dateStartSelection, setDateStartSelection] = React.useState('');

  const [dateEndItem, setDateEndItem] = React.useState('');
  const [dateEndSelection, setDateEndSelection] = React.useState('');

  // Trend Summary
  const [redditTrendTable, setRedditTrendTable] = React.useState([]);
  const [redditTrendTableCumPercent, setRedditTrendTableCumPercent] = React.useState([]);
  const [redditTrendCount, setRedditTrendCount] = React.useState([]);

  const [avsforumTrendTable, setAvsforumTrendTable] = React.useState([]);
  const [avsforumTrendTableCumPercent, setAvsforumTrendTableCumPercent] = React.useState([]);
  const [avsforumTrendCount, setAvsforumTrendCount] = React.useState([]);

  // Topic Summary
  const [redditTopicCount, setRedditTopicCount] = React.useState([]);
  const [redditTopicPie, setRedditTopicPie] = React.useState([]);
  const [redditTopicComparisonAll, setRedditTopicComparisonAll] = React.useState([]);
  const [redditTopicComparison, setRedditTopicComparison] = React.useState([]);

  const [avsforumTopicCount, setAvsforumTopicCount] = React.useState([]);
  const [avsforumTopicPie, setAvsforumTopicPie] = React.useState([]);
  const [avsforumTopicComparisonAll, setAvsforumTopicComparisonAll] = React.useState([]);
  const [avsforumTopicComparison, setAvsforumTopicComparison] = React.useState([]);

  // Topic Insight
  const [topicInsightItems, setTopicInsightItems] = React.useState(['bravia core']);
  const [topicInsightSelection, setTopicInsightSelection] = React.useState('bravia core');

  const [topicInsightPhrase, setTopicInsightPhrase] = React.useState([]);
  const [topicInsightPositiveSentiment, setTopicInsightPositiveSentiment] = React.useState([]);
  const [topicInsightNegativeSentiment, setTopicInsightNegativeSentiment] = React.useState([]);

  // Raw Data
  const [rawData, setRawData] = React.useState([]);

  const [redditPostData, setRedditPostData] = React.useState(0);
  const [avsforumPostData, setAvsforumPostData] = React.useState(0);




  React.useEffect(() => {

    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.socialAnalytic[0].searchTerm}`).then(function (response) { 
      // handle success
      setSearchTerms([response.data.getSearchTerm]);
    

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])



  const handleSearchTerms= event => {
    setSearchTermsSelection(event.target.value);


    let restGETurl = `${"searchTerm="}${event.target.value}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.socialAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
      // handle success
  
     setDateStartItem(response.data.minDate);
     setDateEndItem(response.data.maxDate);
  
     setDateStartSelection(response.data.minDate);
     setDateEndSelection(response.data.maxDate);


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  };



  const handleDateStart = event => {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);

    setDateStartSelection(aDate);

  }

  const handleDateEnd = event => {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
    
    setDateEndSelection(aDate);

  }


  var yesterday = moment(dateStartItem, 'YYYY-MM-DD').subtract(1, "day");
  function validStart(current) {
    return current.isAfter(yesterday);
  }

  var tommorrow = moment(dateEndItem, 'YYYY-MM-DD').add(1, "day");
  function validEnd(current) {
    return current.isBefore(tommorrow);
  }


  const onClickChartData = () => {

    // Reset
    setTopicInsightPhrase([]);
    setTopicInsightPositiveSentiment([]);
    setTopicInsightNegativeSentiment([]);

    // Trend Data
    setSocialChartUIsLoading(true);
    var restGETurl1 = `${"searchTerm="}${searchTermsSelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.socialAnalytic[0].trendData}${restGETurl1}`).then(function (response) { 
      // handle success

      setRedditTrendTable(response.data.redditTrendData);
      setRedditTrendTableCumPercent(response.data.redditTrendDataCumPercent);
      setRedditTrendCount(response.data.redditCount);

      setAvsforumTrendTable(response.data.avsforumTrendData);
      setAvsforumTrendTableCumPercent(response.data.avsforumTrendDataCumPercent);
      setAvsforumTrendCount(response.data.avsforumCount);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      
    });



    // Topic Data
    var restGETurl2 = `${"searchTerm="}${searchTermsSelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
    restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
    restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.socialAnalytic[0].topicData}${restGETurl2}`).then(function (response) { 
      // handle success

      setRedditTopicCount(response.data.topicTotal_reddit);
      setRedditTopicPie(response.data.topicSentimentComparison_reddit);
      setRedditTopicComparisonAll(response.data.dumbbell_df_all_reddit);
      setRedditTopicComparison(response.data.dumbbell_df_reddit);

      setAvsforumTopicCount(response.data.topicTotal_avsforum);
      setAvsforumTopicPie(response.data.topicSentimentComparison_avsforum);
      setAvsforumTopicComparisonAll(response.data.dumbbell_df_all_avsforum);
      setAvsforumTopicComparison(response.data.dumbbell_df_avsforum);

      setTopicInsightSelection('bravia core'); // Need to Reset the list or a crash will occur
      setTopicInsightItems(response.data.TopicList);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      
    });


    // Raw Data
    var restGETurl3 = `${"searchTerm="}${searchTermsSelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
    restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
    restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.socialAnalytic[0].rawData}${restGETurl3}`).then(function (response) { 
      // handle success

      setRawData(response.data.rawData);
      setRedditPostData(response.data.redditPostCount);
      setAvsforumPostData(response.data.avsforumPostCount);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      
    });







    setTimeout(() => {  setSocialChartUIsLoading(false); }, 1000);

  }







  // Reddit Trend - Line Chart

  let redditTrendTableCumPercentTrendLabel = redditTrendTableCumPercent.map(data => data.Date);

  let redditTrendTableCumPercentTrendDataSet = {
    type: 'line',
    labels: redditTrendTableCumPercentTrendLabel,
    datasets: [
      {
        label: 'Positive',
        data: redditTrendTableCumPercent.map(data => data.Positive) ,
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        yAxisID: 'A'
      },

      {
        label: 'Neutral',
        data: redditTrendTableCumPercent.map(data => data.Neutral) ,
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        yAxisID: 'A'
      },

      {
        label: 'Negative',
        data: redditTrendTableCumPercent.map(data => data.Negative) ,
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        yAxisID: 'A'
      },

      {
        label: 'Cumulative Total',
        data: redditTrendTableCumPercent.map(data => data['Cumulative Total']) ,
        fill: false,
        backgroundColor: 'rgb(129, 130, 133)',
        borderColor: 'rgba(129, 130, 133, 0.6)',
        type: 'bar',
        yAxisID: 'B'
      }


    ]

  }


  const redditTrendTableCumPercentTrendOptions = {


  // Drill Down
  onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    },


    spanGaps: true,
    scales: {
      yAxes: [
        {
          id: 'A',
          type: 'linear',
          position: 'left',
          ticks: {
            min: 0,
            max: 100,
            callback: function(value) {
              return value + "%"
            }
          }
        },
        {
          id: 'B',
          type: 'linear',
          position: 'right'
        }
      ],

      xAxes: [{
        type: 'time',
        time: {
            unit: 'month'
        }
    }]
    },
  }

  // Reddit Trend Table using react-data-table-component

  const redditTrendTableCumPercentTrendTableDataSet = 
  redditTrendTable.map((data) => {
      return ({
        //id: data.id,
        date: data.Date,
        positive: data['Positive'],
        neutral: data['Neutral'],
        negative: data['Negative'],
        total: data['Total'],
        cumulative: data['Cumulative Total']
      })
    });
    
  const redditTrendTableCumPercentTrendTableColumn = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: 'Positive',
      selector: 'positive',
      sortable: true,
      center: true
    },
    {
      name: 'Neutral',
      selector: 'neutral',
      sortable: true,
      center: true
    },
    {
      name: 'Negative',
      selector: 'negative',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    },
    {
      name: 'Cumulative Total',
      selector: 'cumulative',
      sortable: true,
      center: true
    }
  ];




  // AVS Forum Trend - Line Chart

  let avsforumTrendTableCumPercentTrendLabel = avsforumTrendTableCumPercent.map(data => data.Date);

  let avsforumTrendTableCumPercentTrendDataSet = {
    type: 'line',
    labels: avsforumTrendTableCumPercentTrendLabel,
    datasets: [
      {
        label: 'Positive',
        data: avsforumTrendTableCumPercent.map(data => data.Positive) ,
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        yAxisID: 'A'
      },

      {
        label: 'Neutral',
        data: avsforumTrendTableCumPercent.map(data => data.Neutral) ,
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        yAxisID: 'A'
      },

      {
        label: 'Negative',
        data: avsforumTrendTableCumPercent.map(data => data.Negative) ,
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        yAxisID: 'A'
      },

      {
        label: 'Cumulative Total',
        data: avsforumTrendTableCumPercent.map(data => data['Cumulative Total']) ,
        fill: false,
        backgroundColor: 'rgb(129, 130, 133)',
        borderColor: 'rgba(129, 130, 133, 0.6)',
        type: 'bar',
        yAxisID: 'B'
      }


    ]

  }


  const avsforumTrendTableCumPercentTrendOptions = {


  // Drill Down
  onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    },


    spanGaps: true,
    scales: {
      yAxes: [
        {
          id: 'A',
          type: 'linear',
          position: 'left',
          ticks: {
            min: 0,
            max: 100,
            callback: function(value) {
              return value + "%"
            }
          }
        },
        {
          id: 'B',
          type: 'linear',
          position: 'right'
        }
      ],

      xAxes: [{
        type: 'time',
        time: {
            unit: 'month'
        }
    }]
    },
  }

  // AVS Forum Table using react-data-table-component

  const avsforumTrendTableCumPercentTrendTableDataSet = 
  avsforumTrendTable.map((data) => {
      return ({
        //id: data.id,
        date: data.Date,
        positive: data['Positive'],
        neutral: data['Neutral'],
        negative: data['Negative'],
        total: data['Total'],
        cumulative: data['Cumulative Total']
      })
    });
    
  const avsforumTrendTableCumPercentTrendTableColumn = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: 'Positive',
      selector: 'positive',
      sortable: true,
      center: true
    },
    {
      name: 'Neutral',
      selector: 'neutral',
      sortable: true,
      center: true
    },
    {
      name: 'Negative',
      selector: 'negative',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    },
    {
      name: 'Cumulative Total',
      selector: 'cumulative',
      sortable: true,
      center: true
    }
  ];


 

  // Topic Breakdown - Reddit - Doughnut Chart

  let pieData = redditTopicPie.map(data => data.Count);

  let redditTopicPieDataSet = {
    type: 'doughnut',
    labels: [
      'Positive',
      'Negative',
      'Neutral'
    ],
    datasets: [
      {
        data: [
          pieData[0],
          pieData[1],
          pieData[2]
        ],
        backgroundColor: [
          'rgb(26, 115, 55)',
          'rgb(245, 200, 15)',
          'rgb(163, 30, 30)'
          ],
        //options: options
      }],
      text: ""
    } 

    let redditTopicPieOptions = {

      // Drill Down
      onClick: (event, elements) => {

          if(elements.length > 0){ // undefine cause app to crash
            const chart = elements[0]._chart;
            const element = chart.getElementAtEvent(event)[0];
            const dataset = chart.data.datasets[element._datasetIndex];
            const xLabel = chart.data.labels[element._index];
            const value = dataset.data[element._index];
            console.log(dataset.label + " at " + xLabel + ": " + value);


          }

        },

        maintainAspectRatio: false 

      

    }


  // Topic Pie Table Reddit using react-data-table-component 
  const redditTopicPieTableDataSet = redditTopicPie.map((data) => {
      return ({
        id: data.id,
        sentiment: data.Sentiment,
        count: data.Count
      })
    });

    

    const redditTopicPieTableColumn = [
      {
        name: 'Sentiment',
        selector: 'sentiment',
        sortable: true,
        center: true
      },
      {
        name: 'Count',
        selector: 'count',
        sortable: true,
        center: true
      }
    ];



  // Topic Breakdown - AVS Forum - Doughnut Chart

  let pieDataAvsForum = avsforumTopicPie.map(data => data.Count);

  let avsforumTopicPieDataSet = {
    type: 'doughnut',
    labels: [
      'Positive',
      'Negative',
      'Neutral'
    ],
    datasets: [
      {
        data: [
          pieDataAvsForum[0],
          pieDataAvsForum[1],
          pieDataAvsForum[2]
        ],
        backgroundColor: [
          'rgb(26, 115, 55)',
          'rgb(245, 200, 15)',
          'rgb(163, 30, 30)'
          ],
        //options: options
      }],
      text: ""
    } 

    let avsforumTopicPieOptions = {

      // Drill Down
      onClick: (event, elements) => {

          if(elements.length > 0){ // undefine cause app to crash
            const chart = elements[0]._chart;
            const element = chart.getElementAtEvent(event)[0];
            const dataset = chart.data.datasets[element._datasetIndex];
            const xLabel = chart.data.labels[element._index];
            const value = dataset.data[element._index];
            console.log(dataset.label + " at " + xLabel + ": " + value);


          }

        },

        maintainAspectRatio: false 

      

    }


  // Topic Pie Table Reddit using react-data-table-component 
  const avsforumTopicPieTableDataSet = avsforumTopicPie.map((data) => {
      return ({
        id: data.id,
        sentiment: data.Sentiment,
        count: data.Count
      })
    });

    

    const avsforumTopicPieTableColumn = [
      {
        name: 'Sentiment',
        selector: 'sentiment',
        sortable: true,
        center: true
      },
      {
        name: 'Count',
        selector: 'count',
        sortable: true,
        center: true
      }
    ];




    // Reddit Topic Overview - Topic Comparison List - Stack Bar Chart

    let redditTopicComparisonTopic = redditTopicComparison.map(data => data['Topic']);
    let redditTopicComparisonPositive = redditTopicComparison.map(data => data['Positive']);
    let redditTopicComparisonNegative = redditTopicComparison.map(data => data['Negative']);
    let redditTopicComparisonNeutral = redditTopicComparison.map(data => data['Neutral']);

    let redditTopicComparisonDataSet = {
      type: 'bar',
      labels: redditTopicComparisonTopic,
      datasets: [
        {
          label: 'Positive',
          data: redditTopicComparisonPositive ,
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },

        {
          label: 'Negative',
          data: redditTopicComparisonNegative,
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        },

        {
          label: 'Neutral',
          data: redditTopicComparisonNeutral,
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        }


      ]

    }

    let redditTopicComparisonOption = {

      // Drill Down
      onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);



        }

      },


      scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    },

    maintainAspectRatio: false 


    }

    // yAxes: [
    //   {
    //     stacked: true,
    //     ticks: {
    //       fontSize: 12,
    //       min: 0,
    //       max: 100,
    //       callback: function(value) {
    //         return value + "%"
    //        }
    //     }
    //   }
    // ],

    // Reddit Topic Overview - Topic Comparison List Table using react-data-table-component

    const redditTopicComparisonAllTableDataSet = redditTopicComparisonAll.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    const redditTopicComparisonAllTableColumn = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];




    // AVS Forum Topic Overview - Topic Comparison List - Stack Bar Chart

    let avsforumTopicComparisonTopic = avsforumTopicComparison.map(data => data['Topic']);
    let avsforumTopicComparisonPositive = avsforumTopicComparison.map(data => data['Positive']);
    let avsforumTopicComparisonNegative = avsforumTopicComparison.map(data => data['Negative']);
    let avsforumTopicComparisonNeutral = avsforumTopicComparison.map(data => data['Neutral']);

    let avsforumTopicComparisonDataSet = {
      type: 'bar',
      labels: avsforumTopicComparisonTopic,
      datasets: [
        {
          label: 'Positive',
          data: avsforumTopicComparisonPositive ,
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },

        {
          label: 'Negative',
          data: avsforumTopicComparisonNegative,
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        },

        {
          label: 'Neutral',
          data: avsforumTopicComparisonNeutral,
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        }


      ]

    }

    let avsforumTopicComparisonOption = {

      // Drill Down
      onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);



        }

      },


      scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    },

    maintainAspectRatio: false 


    }

    // yAxes: [
    //   {
    //     stacked: true,
    //     ticks: {
    //       fontSize: 12,
    //       min: 0,
    //       max: 100,
    //       callback: function(value) {
    //         return value + "%"
    //        }
    //     }
    //   }
    // ],

    // avsforum Topic Overview - Topic Comparison List Table using react-data-table-component

    const avsforumTopicComparisonAllTableDataSet = avsforumTopicComparisonAll.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    const avsforumTopicComparisonAllTableColumn = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];



    const handleTopicInsight = event => {
      setTopicInsightSelection(event.target.value);
    };



    const onClickTopicInsight = () => {
      setTopicInsightIsLoading(true);

      // Reset
      setTopicInsightPhrase([]);
      setTopicInsightPositiveSentiment([]);
      setTopicInsightNegativeSentiment([]);

      
      const axios = require('axios');
    
      // Topic Insight Data
      let restGETurl0 = `${"searchTerm="}${searchTermsSelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&topic="}${topicInsightSelection}`;
      restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
      restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url
    
      axios.get(`${SMATrestAPI.socialAnalytic[0].topicInsight}${restGETurl0}`).then(function (response) { 
        // handle success
    
        setTopicInsightPhrase(response.data.topicPhrase);
        setTopicInsightPositiveSentiment(response.data.topicDescriptionPositive);
        setTopicInsightNegativeSentiment(response.data.topicDescriptionNegative);
            
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        setTimeout(() => {  setTopicInsightIsLoading(false); }, 1000);
      });
    
    }



    // Topic Insight - Topic Comparison List - Stack Bar Chart

    let topicInsightPhraseTopic = topicInsightPhrase.map(data => data['Phrase']);
    let topicInsightPhrasePositive = topicInsightPhrase.map(data => data['Positive']);
    let topicInsightPhraseNegative = topicInsightPhrase.map(data => data['Negative']);
    let topicInsightPhraseNeutral = topicInsightPhrase.map(data => data['Neutral']);

    let topicInsightPhraseDataSet = {
      type: 'bar',
      labels: topicInsightPhraseTopic,
      datasets: [
        {
          label: 'Positive',
          data: topicInsightPhrasePositive ,
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },

        {
          label: 'Negative',
          data: topicInsightPhraseNegative,
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        },

        {
          label: 'Neutral',
          data: topicInsightPhraseNeutral,
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        }


      ]

    }

    let topicInsightPhraseOption = {

      // Drill Down
      onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);



        }

      },


      scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [{
            stacked: true
        }]
    },

    maintainAspectRatio: false 


    }

    // yAxes: [
    //   {
    //     stacked: true,
    //     ticks: {
    //       fontSize: 12,
    //       min: 0,
    //       max: 100,
    //       callback: function(value) {
    //         return value + "%"
    //        }
    //     }
    //   }
    // ],

    // avsforum Topic Overview - Topic Comparison List Table using react-data-table-component

    const topicInsightPhraseTableDataSet = topicInsightPhrase.map((data) => {
        return ({
          id: data.id,
          topic: data['Phrase'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    const topicInsightPhraseTableColumn = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];




// Topic Insight - Positive Wordcloud

const topicInsightPositiveSentimentTopicWords = topicInsightPositiveSentiment.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicInsightPositiveSentimentTopicCallbacks = {
  onWordClick: (word) => {
    //console.log(word.text);

  }, 



  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicInsightPositiveSentimentTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicOverViewPositiveWordCloud() {
  return <ReactWordcloud
    callbacks={topicInsightPositiveSentimentTopicCallbacks}
    options={topicInsightPositiveSentimentTopicOptions}
    words={topicInsightPositiveSentimentTopicWords} 
  />
}


// Topic Insight Positive Word Cloud - Positive Table using react-data-table-component

const topicInsightPositiveSentimentTopicTableDataSet = topicInsightPositiveSentiment.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicInsightPositiveSentimentTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Overview - Negative Wordcloud

const topicInsightNegativeSentimentTopicWords = topicInsightNegativeSentiment.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicInsightNegativeSentimentTopicCallbacks = {
  onWordClick: (word) => {
    //console.log(word.text);


  }, 

  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicInsightNegativeSentimentTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicOverViewNegativeWordCloud() {
  return <ReactWordcloud
    callbacks={topicInsightNegativeSentimentTopicCallbacks}
    options={topicInsightNegativeSentimentTopicOptions}
    words={topicInsightNegativeSentimentTopicWords} 
  />
}


// Topic Overview - Negative Table using react-data-table-component

const topicInsightNegativeSentimentTopicTableDataSet = topicInsightNegativeSentiment.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicInsightNegativeSentimentTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];




// Raw Data - Table using material-table


const rawDataTableColumn = [
  {
    title: 'Website',
    field: 'website'
  },
  {
    title: 'Date',
    field: 'date'
  },
  {
    title: 'Post',
    field: 'post',
    cellStyle: {
      width: 600,
      minWidth: 600
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Topic 1',
    field: 'topic1'
  },
  {
    title: 'Topic 2',
    field: 'topic2'
  },
  {
    title: 'Topic 3',
    field: 'topic3'
  },
  {
    title: 'Topic 4',
    field: 'topic4'
  },
  {
    title: 'Topic 5',
    field: 'topic5'
  },
  {
    title: 'Topic 6',
    field: 'topic6'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  },
  {
    title: 'Pos list',
    field: 'posList'
  },
  {
    title: 'Neg list',
    field: 'negList'
  }
];



const dataTableDataSet = rawData.map((data) => {
  return ({
    id: data.id,
    website: data.Website,
    date: data.Date,
    post: data['Post'],
    topic1: data['Topic 1'],
    topic2: data['Topic 2'],
    topic3: data['Topic 3'],
    topic4: data['Topic 4'],
    topic5: data['Topic 5'],
    topic6: data['Topic 6'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




const onClickUrl = (url, website) => {
  
  window.open(url, '_blank').focus();
}


  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();


  return (
    <div>

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

        </GridContainer>


{/* Search Term Selection */}
      <GridContainer>
      <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <SearchIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Search Term</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="search-term"
                  className={
                    classes.selectLabel
                }>
                  Select Search Term
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={searchTermsSelection}
                  onChange={handleSearchTerms}
                  inputProps={
                    {
                      name: "searchTerm",
                      id: "search-term"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  searchTerms.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } 
                </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


{/* Start Date Selection */}
        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Start Date</b>
              </h4>
            </CardHeader>
            <CardBody>

              <GridContainer style={{height: "75px"}}>
                <GridItem md={12}>
                  <br/>

                  <Datetime 
                          timeFormat={false}
                          inputProps={{ placeholder: "Select Start Date" }}
                          value={moment(dateStartSelection, 'YYYY-MM-DD')}
                          defaultValue ={moment(dateStartItem, 'YYYY-MM-DD')}
                          onChange={handleDateStart}
                          dateFormat='YYYY-MM-DD'
                          closeOnSelect
                          isValidDate={ validStart } 
                        />
                </GridItem>
              </GridContainer>
                      
            </CardBody>
          </Card>

        </GridItem>

{/* End Date Selection */}

      <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>End Date</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer style={{height: "75px"}}>
                <GridItem md={12}>
                <br/>
                  <Datetime 
                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItem, 'YYYY-MM-DD')}
                    onChange={handleDateEnd}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 

                  />
                </GridItem>
              </GridContainer>

            </CardBody>
          </Card>

        </GridItem>

      </GridContainer>



      <GridContainer >

        <GridItem 
            xs={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center">

          <Button color="pinterest" onClick={onClickChartData}>
            <RefreshIcon className={classes.icons} />
              Display Chart
          </Button>
          

        </GridItem>

      </GridContainer>

        <br/>


{/* Sentiment Trend */}
      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Sentiment Trend</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>

          <GridItem xs={12} sm={12} md={6}  direction="column">
            <Button color="reddit" size="sm" style={{width: "100%"}}> <b>Reddit</b> </Button>

{/* Reddit Cards */}
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Postive Post Count</p>
                  <h3 className={dashboardClasses.cardTitle}>
                  {redditTrendCount.map(data => data['Positive'])}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Negative Post Count</p>
                  <h3 className={dashboardClasses.cardTitle}>
                  {redditTrendCount.map(data => data['Negative'])}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HandIcon />
                </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Neutral Post Count</p>
                  <h3 className={dashboardClasses.cardTitle}>
                  {redditTrendCount.map(data => data['Neutral'])}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>


{/* Reddit Trend Chart */}
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
                
                tabs={[
                  {
                    tabName: "Cumulative Trend by Percent",
                    tabIcon: TrendingUpIcon,
                    tabContent: (!socialChartUIsLoading ? 
                      <div>
                        <Line 
                          height={100} data={redditTrendTableCumPercentTrendDataSet}  
                          options={redditTrendTableCumPercentTrendOptions} /> 
                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!socialChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={redditTrendTableCumPercentTrendTableColumn}
                            data={redditTrendTableCumPercentTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={redditTrendTableCumPercentTrendTableDataSet} filename={"Reddit Trend Data.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          <GridItem xs={12} sm={12} md={6} direction="column">
            <Button color="facebook" size="sm" style={{width: "100%"}}> <b>AVS Forum</b> </Button>

{/* AVS Forum Cards */}
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Postive Post Count</p>
                  <h3 className={dashboardClasses.cardTitle}>
                  {avsforumTrendCount.map(data => data['Positive'])}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Negative Post Count</p>
                  <h3 className={dashboardClasses.cardTitle}>
                  {avsforumTrendCount.map(data => data['Negative'])}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>

            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HandIcon />
                </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Neutral Post Count</p>
                  <h3 className={dashboardClasses.cardTitle}>
                  {avsforumTrendCount.map(data => data['Neutral'])}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>


{/* AVS Forum Trend Chart */}
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
                
                tabs={[
                  {
                    tabName: "Cumulative Trend by Percent",
                    tabIcon: TrendingUpIcon,
                    tabContent: (!socialChartUIsLoading ? 
                      <div>
                        <Line 
                          height={100} data={avsforumTrendTableCumPercentTrendDataSet}  
                          options={avsforumTrendTableCumPercentTrendOptions} /> 
                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!socialChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={avsforumTrendTableCumPercentTrendTableColumn}
                            data={avsforumTrendTableCumPercentTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={avsforumTrendTable} filename={"AVS Forum Trend Data.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

        </GridContainer>


{/* Topic Overview */}
        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Overview</b> </Button>
          </GridItem>

        </GridContainer>

{/* Reddit Topic Overview */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}  direction="column">
              <Button color="reddit" size="sm" style={{width: "100%"}}> <b>Reddit</b> </Button>

{/* Reddit Topic Overview - Card */}
              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <TopicIcon />
                  </CardIcon>
                    <p className={dashboardClasses.cardCategory}>Topic Count</p>
                    <h3 className={dashboardClasses.cardTitle}>
                    {redditTopicCount.map(data => data['Total'])}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>

                  </div>
                </CardFooter>
              </Card>

{/* Reddit Topic Overview - Chart */}
              <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Sentiment Breakdown",
                    tabIcon: PieChartIcon,
                    tabContent: (!socialChartUIsLoading ? 
                      <div>
                        <Doughnut 
                          height={300} 
                          data={redditTopicPieDataSet} 
                          options={redditTopicPieOptions} /> 

                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!socialChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={redditTopicPieTableColumn}
                            data={redditTopicPieTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={redditTopicPie} filename={"Reddit Sentiment Breakdown.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
  
                      </GridItem>
  
                    </GridContainer>
  
                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              />



              <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
              
                
                tabs={[
                  {
                    tabName: "Topic Comparison",
                    tabIcon: CompareIcon,
                    tabContent: (!socialChartUIsLoading ? 
                      <div> 
                        {/* style={{ height: 300}} */}
                        <HorizontalBar
                          height={350}
                          data={redditTopicComparisonDataSet}
                          options={redditTopicComparisonOption}
                        />
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },

                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!socialChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={redditTopicComparisonAllTableColumn}
                            data={redditTopicComparisonAllTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"445px"}
                            noHeader={true}
                            />

                            <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={redditTopicComparisonAllTableDataSet} filename={"Reddit Topic Comparison List.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              />
          
          </GridItem>

{/* AVS Forum Topic Overview */}
          <GridItem xs={12} sm={12} md={6}  direction="column">
              <Button color="facebook" size="sm" style={{width: "100%"}}> <b>AVS Forum</b> </Button>

              <Card>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <TopicIcon />
                  </CardIcon>
                    <p className={dashboardClasses.cardCategory}>Topic Count</p>
                    <h3 className={dashboardClasses.cardTitle}>
                    {avsforumTopicCount.map(data => data['Total'])}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>

                  </div>
                </CardFooter>
              </Card>


{/* AVS Forum Topic Overview - Chart */}
              <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Sentiment Breakdown",
                    tabIcon: PieChartIcon,
                    tabContent: (!socialChartUIsLoading ? 
                      <div>
                        <Doughnut 
                          height={300} 
                          data={avsforumTopicPieDataSet} 
                          options={avsforumTopicPieOptions} /> 

                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!socialChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={avsforumTopicPieTableColumn}
                            data={avsforumTopicPieTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={avsforumTopicPie} filename={"AVS Forum Sentiment Breakdown.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
  
                      </GridItem>
  
                    </GridContainer>
  
                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              />


              <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
              
                
                tabs={[
                  {
                    tabName: "Topic Comparison",
                    tabIcon: CompareIcon,
                    tabContent: (!socialChartUIsLoading ? 
                      <div> 
                        {/* style={{ height: 300}} */}
                        <HorizontalBar
                          height={350}
                          data={avsforumTopicComparisonDataSet}
                          options={avsforumTopicComparisonOption}
                        />
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },

                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!socialChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={avsforumTopicComparisonAllTableColumn}
                            data={avsforumTopicComparisonAllTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"445px"}
                            noHeader={true}
                            />

                            <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={avsforumTopicComparisonAllTableDataSet} filename={"AVS Forum Topic Comparison List.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              />
          
          </GridItem>



        </GridContainer>



{/* Topic Insight Filters */}
        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Insight</b> </Button>
          </GridItem>

        </GridContainer>


        <GridContainer>
          <GridItem xs={12}
            sm={12}
            md={12}>

            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <TopicIcon />
                  
                  
                </CardIcon>
                <h4 className={
                  classes.cardIconTitle
                }>
                  <b>Topic List</b>
                </h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="topicInsight-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Topic
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={topicInsightSelection}
                    onChange={handleTopicInsight}
                    inputProps={
                      {
                        name: "topicInsightSelect",
                        id: "topicInsight-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    topicInsightItems.map((TopicInsightItems, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={TopicInsightItems}>
                        {TopicInsightItems} </MenuItem>
                    ))
                  } </Select>
                </FormControl>
                
              </CardBody>

            </Card>


            

          </GridItem>
          

          </GridContainer>

          <GridContainer >

            <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

              <Button color="pinterest" onClick={onClickTopicInsight}>
                <RefreshIcon className={classes.icons} />
                  Display Chart
              </Button>
              

            </GridItem>

          </GridContainer>

          <br/>

{/* Topic Insight Topic Chart */}

        <GridContainer>

          <GridItem xs={12} sm={12} md={4}>

          <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Topic Phrase",
                    tabIcon: TopicIcon,
                    tabContent: (!topicInsightIsLoading ? 
                      <div>
                        <HorizontalBar 
                          height={350} 
                          data={topicInsightPhraseDataSet} 
                          options={topicInsightPhraseOption} /> 

                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicInsightIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicInsightPhraseTableColumn}
                            data={topicInsightPhraseTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicInsightPhraseTableDataSet} filename={"Topic Insight Phrase.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
  
                      </GridItem>
  
                    </GridContainer>
  
                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              />

          </GridItem>


          <GridItem xs={12} sm={12} md={4} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "Positive Topic List",
                    tabIcon: PositiveIcon,
                    tabContent: (!topicInsightIsLoading ? 
                      <div style={{ height: 350}}>
                        <ReactWordcloud
                          callbacks={topicInsightPositiveSentimentTopicCallbacks}
                          options={topicInsightPositiveSentimentTopicOptions}
                          words={topicInsightPositiveSentimentTopicWords} 
                        />
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicInsightIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicInsightPositiveSentimentTopicTableColumn}
                            data={topicInsightPositiveSentimentTopicTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicInsightPositiveSentimentTopicTableDataSet} filename={"Topic Overview - Positive.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          <GridItem xs={12} sm={12} md={4} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="danger"
                
                tabs={[
                  {
                    tabName: "Negative Sentiment",
                    tabIcon: NegativeIcon,
                    tabContent: (!topicInsightIsLoading ? 
                      <div style={{ height: 350}}>
                        <ReactWordcloud
                          callbacks={topicInsightNegativeSentimentTopicCallbacks}
                          options={topicInsightNegativeSentimentTopicOptions}
                          words={topicInsightNegativeSentimentTopicWords} 
                        />
                      </div>

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicInsightIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicInsightNegativeSentimentTopicTableColumn}
                            data={topicInsightNegativeSentimentTopicTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicInsightNegativeSentimentTopicTableDataSet} filename={"Topic Overview - Negative.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

        </GridContainer>


{/* Raw Data */}

        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Raw Data</b> </Button>
          </GridItem>

        </GridContainer>

{/* Reddit Raw Data - Card */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}  direction="column">
              <Button color="reddit" size="sm" style={{width: "100%"}}> <b>Reddit</b> </Button>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <TopicIcon />
                    </CardIcon>
                      <p className={dashboardClasses.cardCategory}>Post Count</p>
                      <h3 className={dashboardClasses.cardTitle}>
                      {redditPostData}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={dashboardClasses.stats}>

                    </div>
                  </CardFooter>
                </Card>
            </GridItem>

{/* AVS Forum Raw Data - Card */}
            <GridItem xs={12} sm={12} md={6}  direction="column">
              <Button color="facebook" size="sm" style={{width: "100%"}}> <b>AVS Forum</b> </Button>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <TopicIcon />
                    </CardIcon>
                      <p className={dashboardClasses.cardCategory}>Post Count</p>
                      <h3 className={dashboardClasses.cardTitle}>
                      {avsforumPostData}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={dashboardClasses.stats}>

                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

          </GridContainer>


{/* Raw Data Table */}


        <GridContainer>

          <GridItem xs={12} sm={12} md={12} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Post/Comments",
                    tabIcon: AssignmentIcon,
                      tabContent: (!socialChartUIsLoading ? 
                        <div>
                          
                          <div style={{ maxWidth: '100%' }}>
                                  <MaterialTable
                                    columns={rawDataTableColumn}
                                    data={dataTableDataSet}
                                    title="Data"
                                    options={{
                                      exportButton: true,
                                      exportAllData: true,
                                      filtering: true,
                                      showTitle: false
                                    }}
                                    // actions={[
                                    //   {
                                    //     icon: TopicIcon,
                                    //     tooltip: 'Show Full Review',
                                    //     onClick: (event, rowData) => {
                                    //       // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                    //       onClickUrl(rowData.url, rowData.website, ()=>{});

                                    //     }

                                    //   }
                                    // ]}
                                    // components={{
                                    //   Action: props => (

                                    //     <Tooltip
                                    //       id="tooltip-top"
                                    //       title="View Post"
                                    //       placement="top"
                                    //       classes={{ tooltip: classes.tooltip }}
                                    //     >
                                    //         <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                    //           <FullReviewIcon />
                                    //         </Button>
                                    //     </Tooltip>
                                        
                                    //   )
                                    // }}
                                  />
                            </div>

                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">

                        <CSVLink data={rawData} filename={"Bravia Core - Raw Data.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          </GridContainer>


    </div>
  );
}
