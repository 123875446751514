/*eslint-disable*/

import React from "react";

// CSS
import "../../assets/css/page-loading.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/LocalOffer";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import FilterIcon from "@material-ui/icons/FilterList";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
// import SoftwareIcon from "@material-ui/icons/Apps";
// import PictureIcon from "@material-ui/icons/Image";
// import PriceIcon from "@material-ui/icons/AttachMoney";
// import DesignIcon from "@material-ui/icons/DataUsage";
// import FeatureIcon from "@material-ui/icons/Settings";
// import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import DetailIcon from "@material-ui/icons/Details";
// import FullReviewIcon from "@material-ui/icons/Message";
// import Close from "@material-ui/icons/Close";
// import Check from "@material-ui/icons/Check";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import HighlightIcon from "@material-ui/icons/Highlight";
import CheckIcon from "@material-ui/icons/Check";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import checkStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar } from 'react-chartjs-2'; // 3rd Party Chart Component
// import { Line, Doughnut, Radar, Bar, Chart } from 'react-chartjs-2'; // 3rd Party Chart Component
//import 'chartjs-plugin-datalabels'; // 3rd party for chartjs to display labels
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component
//import ChartDataLabels from 'chartjs-plugin-datalabels';
import Highlighter from "react-highlight-words";
import { set } from "lodash";
import { MultiSelect } from "react-multi-select-component";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);
const useCheckStyles = makeStyles(checkStyles);

const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="h4" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};



export default function StarRatingAnalyticCN() {

  const axios = require('axios');

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"TV Rating Analytic - CN"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])


  // Test Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Test Modal 


  //TODO: Set all setStates to [] if the default is an array. Example: 
  // OLD => setCategoryDetailTopicInsightPictureItem(response.data.pictureTopicList);
  // New =>setCategoryDetailTopicInsightPictureItem([response.data.pictureTopicList]);
  // an error would occur if the return data is only 1 value which becomes not an array causing SMAT to error or show a blank page

  // Loading Param
  const [progress, setProgress] = React.useState(10);


  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");


  // Loading by Section
  const [starRatingTrendUniqueIsLoading, setStarRatingTrendUniqueIsLoading] = React.useState(false);
  const [categorySummaryIsLoading, setCategorySummaryIsLoading] = React.useState(false);
  const [topicOverviewIsLoading, setTopicOverviewIsLoading] = React.useState(false);
  const [topicDetailIsLoading, setTopicDetailIsLoading] = React.useState(false);
  const [viewIndividualSentenceIsLoading, setViewIndividualSentenceIsLoading] = React.useState(false);



  // Get Trend Data via SMAT RESTAPI
  // filters
  const [fullModelList, setFullModelList] = React.useState(['']);

  const [brandItems, setBrandItems] = React.useState(['']);
  const [brandSelection, setBrandSelection] = React.useState('');

  const [categoryItems, setCategoryItems] = React.useState([]);
  const [categorySelection, setCategorySelection] = React.useState('');

  const [modelItems, setModelItems] = React.useState([]);
  const [modelSelection, setModelSelection] = React.useState([]);

  // Join model list to a string
  const [modelSelectionCustom, setModelSelectionCustom] = React.useState([]);

  const [currentDate, setCurrentDate] = React.useState('');

  // for model search result to appear constant after selection
  const [inputValue, setInputValue] = React.useState("");

  const [websiteItems, setWebsiteItems] = React.useState(['JD']);
  const [websiteSelection, setWebsiteSelection] = React.useState(['JD']);

  const [countryItems, setCountryItems] = React.useState(['China']);
  const [countrySelection, setCountrySelection] = React.useState(['China']);

  const [dateStartItem, setDateStartItem] = React.useState('');
  const [dateStartSelection, setDateStartSelection] = React.useState('');

  const [dateEndItem, setDateEndItem] = React.useState('');
  const [dateEndSelection, setDateEndSelection] = React.useState('');

  const [uniqueTopic, setUniqueTopic] = React.useState(false);

  // Test

  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
  ];

  const [selected, setSelected] = React.useState([]);

  // SMAT Trend
  const [uniqueTrendData, setUniqueTrendData] = React.useState([]);
  const [uniqueTrendDataPercentCum, setUniqueTrendDataPercentCum] = React.useState([]);
  const [uniqueTrendStarRatingAvg, setUniqueTrendStarRatingAvg] = React.useState(0);
  const [uniqueTrendStarColor, setUniqueTrendStarColor] = React.useState('info');

  const [trendFilter, setTrendFilter] = React.useState("month");

   // Category Summary
  
   const [categorySummaryOriginalData, setCategorySummaryOriginalData] = React.useState([]);
   const [categorySummaryData, setCategorySummaryData] = React.useState([]);
   const [categoryLabel, setCategoryLabel] = React.useState([]);
   const [categoryRating, setCategoryRating] = React.useState([]); // Order: "After-Service", "Brand", "COI", "Design", "Hardware", "Hardware/UX", "Others", "Performance", "Picture", "Price", "QA", "Sound", "UX"
   const [categoryColor, setCategoryColor] = React.useState([]);
   const [sortOption, setSortOption] = React.useState('highLow');
   
   const [categoryFiveStarCount, setCategoryFiveStarCount] = React.useState([]);
   const [categoryFourStarCount, setCategoryFourStarCount] = React.useState([]);
   const [categoryThreeStarCount, setCategoryThreeStarCount] = React.useState([]);
   const [categoryTwoStarCount, setCategoryTwoStarCount] = React.useState([]);
   const [categoryOneStarCount, setCategoryOneStarCount] = React.useState([]);

   const [categoryFiveStarPercent, setCategoryFiveStarPercent] = React.useState([]);
   const [categoryFourStarPercent, setCategoryFourStarPercent] = React.useState([]);
   const [categoryThreeStarPercent, setCategoryThreeStarPercent] = React.useState([]);
   const [categoryTwoStarPercent, setCategoryTwoStarPercent] = React.useState([]);
   const [categoryOneStarPercent, setCategoryOneStarPercent] = React.useState([]);

   const [categoryPositiveCount, setCategoryPositiveCount] = React.useState([]);
   const [categoryNeutralCount, setCategoryNeutralCount] = React.useState([]);
   const [categoryNegativeCount, setCategoryNegativeCount] = React.useState([]);

   const [categoryPositivePercent, setCategoryPositivePercent] = React.useState([]);
   const [categoryNeutralPercent, setCategoryNeutralPercent] = React.useState([]);
   const [categoryNegativePercent, setCategoryNegativePercent] = React.useState([]);


  // Topic Overview
  const [analyticOption, setAnalyticOption] = React.useState('star');
  const [analyticOption2, setAnalyticOption2] = React.useState('star');
  const [topicOption, setTopicOption] = React.useState('top20');
  const [topicSortOption, setTopicSortOption] = React.useState('highLow');
  const [topicLanguageOption, setTopicLanguageOption] = React.useState('chinese');

  const [topicComparison, setTopicComparison] = React.useState([]);
  const [topicDetailComparison, setTopicDetailComparison] = React.useState([]);

  const [topicComparisonOriginal, setTopicComparisonOriginal] = React.useState([]);
  const [topicDetailComparisonOriginal, setTopicDetailComparisonOriginal] = React.useState([]);

  const [topicDetailPositive, setTopicDetailPositive] = React.useState([]);
  const [topicDetailNegative, setTopicDetailNegative] = React.useState([]);

  const [topicMenuList, setTopicMenuList] = React.useState([]);
  const [topicDetailMenuList, setTopicDetailMenuList] = React.useState([]);

  const [topicMenuSelection, setTopicMenuSelection] = React.useState([]);
  const [topicDetailMenuSelection, setTopicDetailMenuSelection] = React.useState([]);

  const [topicAndTopicDetailMenuList, setTopicAndTopicDetailMenuList] = React.useState([]);

  // Topic and Topic Detail custom search place holder
  const [inputTopicValue, setInputTopicValue] = React.useState("");
  const [inputTopicDetailValue, setInputTopicDetailValue] = React.useState("");

  // Topic Detail
  const [categoryData, setCategoryData] = React.useState([]);
  const [topicData, setTopicData] = React.useState([]);
  const [topicDetailData, setTopicDetailData] = React.useState([]);
  const [topicTrendData, setTopicTrendData] = React.useState([]);
  const [customIndividualSentence, setCustomIndividualSentence] = React.useState([]);

  const [highlightSentence, setHighlightSentence] = React.useState('');
  const [highlightTopicDetail, setHighlightTopicDetail] = React.useState([]);


  const [topicDetailTrendFilter, setTopicDetailTrendFilter] = React.useState("month");
  const [topicDetailTrendRatingFilter, setTopicDetailTrendRatingFilter] = React.useState('star');

  const [hotTopicDetailTrendFilter, setHotTopicDetailTrendFilter] = React.useState("month");

  // View Individual Sentence
  const [viewIndividualSentenceData, setViewIndividualSentenceData] = React.useState([]);

  // Raw and Analyzed Data
  const [rawData, setRawData] = React.useState([]);
  const [analyzedData, setAnalyzedData] = React.useState([]);

   // Sentiment Mapping
   const [sentimentMapping, setSentimentMapping] = React.useState([]);

   const [popUpHighlight, setPopUpHighlight] = React.useState(false);


    // Category Checkbox 
    const [state, setState] = React.useState({
      afterservice: true,
      brand: true,
      coi: true,
      design: true,
      hardware: true,
      hardwareux: true,
      others: true,
      performance: true,
      picture: true,
      price: true,
      qa: true,
      sound: true,
      ux: true
    });

    const [stateMonitor, setStateMonitor] = React.useState({
      externaldeviceMonitor: true,
      pqMonitor: true,
      designMonitor: true,
      sqMonitor: true,
      scenarioMonitor: true,
      qaMonitor: true,
      coiMonitor: true,
      uxMonitor: true,
      performanceMonitor: true
    });
 
    const handleChange = (event) => {
      if(categorySelection === "TV"){
        setState({ ...state, [event.target.name]: event.target.checked });
      }else if(categorySelection === "Monitor"){
        setStateMonitor({ ...stateMonitor, [event.target.name]: event.target.checked });
      }
      
    };

    const { afterservice, brand, coi, design, hardware, hardwareux, others, performance, picture, price, qa, sound, ux } = state;

    const { externaldeviceMonitor, pqMonitor, designMonitor, sqMonitor, scenarioMonitor, qaMonitor, coiMonitor, uxMonitor, performanceMonitor } = stateMonitor;


    // Category Radio Selection

    const [valueCategoryValue, setValueCategoryValue] = React.useState('abc');

    const handleChangeCategoryRadio = (event) => {
      setSortOption(event.target.value);
    };


    // Topic Overview Radio Selection - Analytic  = data => () => {
    const handleChangeTopicAnalyticRadio = data => () => {
      setAnalyticOption(data);
    };

    const handleChangeTopicAnalyticRadio2 = data => () => {
      setAnalyticOption2(data);
    };

    // Topic Overview Radio Selection - Topic Option
    const handleChangeTopicRadio = (event) => {
      setTopicOption(event.target.value);
    };

    // Topic Overview Radio Selection - Sort Option
    const handleChangeTopicSortRadio = (event) => {
      setTopicSortOption(event.target.value);
    };

    // Topic Overview Radio Selection - Language Option
    const handleChangeTopicLanguageRadio = (event) => {
      setTopicLanguageOption(event.target.value);
    };


  const handleBrand= event => {
    setBrandSelection(event.target.value);
    setCategorySelection('');
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleCategory = event => {
    setCategorySelection(event.target.value);
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleModel = (event, values) => {
    setModelSelection(values);
    setWebsiteSelection(['JD']);
    setCountrySelection(['China']);
    setDateStartSelection('');
    setDateEndSelection('');

  };


  const handleWebsite = event => {
    setWebsiteSelection(event.target.value);
    setCountrySelection(['China']);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleCountry = event => {
    setCountrySelection(event.target.value);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  // const handleDateStart = event => {
  //   let startDate = event._d;
  //   startDate = new Date(event._d);
  //   let year = startDate.getFullYear();
  //   let month = startDate.getMonth()+1;
  //   let dt = startDate.getDate();

  //   if (dt < 10) {
  //     dt = '0' + dt;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }

  //   startDate = (year+'-' + month + '-'+dt);

    

  //   if(isNaN(startDate) == false){
  //     setDateStartSelection(startDate);
  //   }


  // };

  // const handleDateEnd = event => {
  //   let endDate = event._d;
  //   endDate = new Date(event._d);
  //   let year = endDate.getFullYear();
  //   let month = endDate.getMonth()+1;
  //   let dt = endDate.getDate();

  //   if (dt < 10) {
  //     dt = '0' + dt;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }

  //   endDate = (year+'-' + month + '-'+dt);

  //   setDateEndSelection(endDate);

  //   if(isNaN(endDate) == false){
  //     setDateEndSelection(endDate);
  //   }

  // };



  const handleDateStart = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
  
      setDateStartSelection(aDate);
    }

  }

  const handleDateEnd = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
      
      setDateEndSelection(aDate);
    }

  }


  var yesterday = moment(dateStartItem, 'YYYY-MM-DD').subtract(1, "day");
  function validStart(current) {
    return current.isAfter(yesterday);
  }

  var tommorrow = moment(dateEndItem, 'YYYY-MM-DD').add(1, "day");
  function validEnd(current) {
    return current.isBefore(tommorrow);
  }




  const handleTopic = (event, values) => {
    setTopicMenuSelection(values);

    // Set Topic Detail Menu list base user selection for Topic Menu
    let topicList = [];
    topicList = values;

    let topicDetailList = topicAndTopicDetailMenuList.filter((data => topicList.includes(data.Topic)));
  
    setTopicDetailMenuList(topicDetailList.map(data => data["Topic.Detail"]));
    setTopicDetailMenuSelection(topicDetailList.map(data => data["Topic.Detail"]));


  };

  const handleTopicDetail = (event, values) => {
    setTopicDetailMenuSelection(values);
    //setTopicMenuSelection([]);
  };



  // Get Filter Data from SMAT REST API - Brand
  React.useEffect(() => {
    const axios = require('axios');

    // axios.get(SMATrestAPI.generalFilterCN[0].getBrand).then(function (response) { 
    //   // handle success
    //   setBrandItems(response.data.getBrand);

    // }).catch(function (error) { 
    //   // handle error
    //   console.log(error);
    // }).then(function () { 
    //   // always executed
    // });


    // Get Master Model Table 
    axios.get(SMATrestAPI.generalFilterCN[0].getAllModels).then(function (response) { 

    // handle success
    //localStorage.setItem("modelList", JSON.stringify(response.data.getFilter));
    setFullModelList(response.data.getFilter);

    let retrievedModelList = response.data.getFilter
    let uniqueBrand = [...new Set(retrievedModelList.map(x => x.Brand))];
    uniqueBrand.unshift("Sony");

    setBrandItems(uniqueBrand);


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });



  }, [])


  // Get Filter Data from SMAT REST API - Category
  React.useEffect(() => {


    if(brandSelection !== ''){

      let result = fullModelList.filter(x => x.Brand === brandSelection);
      result = [...new Set(result.map(x => x['Product.Category']))];

      setCategoryItems(result);

    }
  }, [brandSelection])



// Get Filter Data from SMAT REST API - Model
React.useEffect(() => {


  if(categorySelection !== ''){

    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = [...new Set(result.map(x => x['Model']))];

    setModelItems(result);

  }
}, [categorySelection])



// Get Filter Data from SMAT REST API - Website
React.useEffect(() => {


  if(modelSelection !== ''){
    
    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = result.filter(x => x['Model'] == modelSelection[0] || x['Model'] == modelSelection[1] || x['Model'] == modelSelection[2] || x['Model'] == modelSelection[3] || x['Model'] == modelSelection[4] || x['Model'] == modelSelection[5] || x['Model'] == modelSelection[6] || x['Model'] == modelSelection[7] || x['Model'] == modelSelection[8] || x['Model'] == modelSelection[9] || x['Model'] == modelSelection[10] || x['Model'] == modelSelection[11] || x['Model'] == modelSelection[12] || x['Model'] == modelSelection[13] || x['Model'] == modelSelection[14]);
    result = [...new Set(result.map(x => x['Website']))];

    setWebsiteItems(result);

  }
}, [modelSelection])



// Get Filter Data from SMAT REST API - Country
React.useEffect(() => {


  if(categorySelection !== ''){
    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = result.filter(x => x['Model'] == modelSelection[0] || x['Model'] == modelSelection[1] || x['Model'] == modelSelection[2] || x['Model'] == modelSelection[3] || x['Model'] == modelSelection[4] || x['Model'] == modelSelection[5] || x['Model'] == modelSelection[6] || x['Model'] == modelSelection[7] || x['Model'] == modelSelection[8] || x['Model'] == modelSelection[9] || x['Model'] == modelSelection[10] || x['Model'] == modelSelection[11] || x['Model'] == modelSelection[12] || x['Model'] == modelSelection[13] || x['Model'] == modelSelection[14]);
    result = result.filter(x => x['Website'] == websiteSelection[0] || x['Website'] == websiteSelection[1] || x['Website'] == websiteSelection[2] || x['Website'] == websiteSelection[3] || x['Website'] == websiteSelection[4] || x['Website'] == websiteSelection[5] || x['Website'] == websiteSelection[6] || x['Website'] == websiteSelection[7] || x['Website'] == websiteSelection[8] || x['Website'] == websiteSelection[9]);
    result = [...new Set(result.map(x => x['Country']))];

    setCountryItems(result);

  }
}, [websiteSelection])


// Get Filter Data from SMAT REST API - Date
React.useEffect(() => {


  if(countrySelection !== ''){

    var restGETurl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.generalFilterCN[0].getDate}${restGETurl}`).then(function (response) { 
      // handle success

      setDateStartItem(response.data.startDate);
      setDateEndItem(response.data.endDate);

      setDateStartSelection(response.data.startDate);
      setDateEndSelection(response.data.endDate);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }
}, [countrySelection])




const getPageLoggingData = async () => {

  // Send Model Information to SMAT DB after user clicks submit
  var restGETurl0 = `${"page="}${"TV Rating Analytic - CN"}${"&brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
  restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
  restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

  try {
  const resp = await axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl0}`);

  } catch (err) {
    // Handle Error Here
    console.error(err);

  }


};


const getUniqueTrendData = async () => {

  // Unique Trend Data
  setStarRatingTrendUniqueIsLoading(true);
  let restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&filterSelection="}${trendFilter}`;
  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  try {
  const resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl1}`);
    setUniqueTrendData(resp.data.uniqueTrendData);
    setUniqueTrendDataPercentCum(resp.data.uniqueTrendDataPercentCum);
    setUniqueTrendStarRatingAvg(resp.data.starRating);
    setUniqueTrendStarColor(resp.data.color);

    setStarRatingTrendUniqueIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setUniqueTrendData([]);
    setUniqueTrendDataPercentCum([]);
    setUniqueTrendStarRatingAvg(0);
    setUniqueTrendStarColor('info');

    setStarRatingTrendUniqueIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};


const getCategorySummaryData = async () => {

  // Category Summary Data
  setCategorySummaryIsLoading(true);
  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&sortOption="}${"highLow"}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&sortOption="}${"highLow"}`;

  }

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {
    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl2}`);
    }

    let ratingData = resp.data.categorySummary.map(data => data['Rating']);
    setCategoryRating(ratingData);

    let colorData = resp.data.categorySummary.map(data => data['Color']);
    setCategoryColor(colorData);

    // Labels and Info Box
    setCategorySummaryOriginalData(resp.data.categorySummary);

    // Use categorySummaryCustom (Has Filters)

    setCategorySummaryData(resp.data.categorySummaryCustom);

    let labelData = resp.data.categorySummaryCustom.map(data => data['Category']);
    setCategoryLabel(labelData);


    let fiveStarCountData = resp.data.categorySummaryCustom.map(data => data['5-Star Count']);
    setCategoryFiveStarCount(fiveStarCountData);

    let fourStarCountData = resp.data.categorySummaryCustom.map(data => data['4-Star Count']);
    setCategoryFourStarCount(fourStarCountData);

    let threeStarCountData = resp.data.categorySummaryCustom.map(data => data['3-Star Count']);
    setCategoryThreeStarCount(threeStarCountData);

    let twoStarCountData = resp.data.categorySummaryCustom.map(data => data['2-Star Count']);
    setCategoryTwoStarCount(twoStarCountData);

    let oneStarCountData = resp.data.categorySummaryCustom.map(data => data['1-Star Count']);
    setCategoryOneStarCount(oneStarCountData);

    let fiveStarPercentData = resp.data.categorySummaryCustom.map(data => data['5-Star Percent']);
    setCategoryFiveStarPercent(fiveStarPercentData);

    let fourStarPercentData = resp.data.categorySummaryCustom.map(data => data['4-Star Percent']);
    setCategoryFourStarPercent(fourStarPercentData);

    let threeStarPercentData = resp.data.categorySummaryCustom.map(data => data['3-Star Percent']);
    setCategoryThreeStarPercent(threeStarPercentData);

    let twoStarPercentData = resp.data.categorySummaryCustom.map(data => data['2-Star Percent']);
    setCategoryTwoStarPercent(twoStarPercentData);

    let oneStarPercentData = resp.data.categorySummaryCustom.map(data => data['1-Star Percent']);
    setCategoryOneStarPercent(oneStarPercentData);

    let positiveCountData = resp.data.categorySummaryCustom.map(data => data['Positive Count']);
    setCategoryPositiveCount(positiveCountData);

    let neutralCountData = resp.data.categorySummaryCustom.map(data => data['Neutral Count']);
    setCategoryNeutralCount(neutralCountData);

    let negativeCountData = resp.data.categorySummaryCustom.map(data => data['Negative Count']);
    setCategoryNegativeCount(negativeCountData);

    let positivePercentData = resp.data.categorySummaryCustom.map(data => data['Positive Percent']);
    setCategoryPositivePercent(positivePercentData);

    let negativePercentData = resp.data.categorySummaryCustom.map(data => data['Negative Percent']);
    setCategoryNegativePercent(negativePercentData);

    let neutralPercentData = resp.data.categorySummaryCustom.map(data => data['Neutral Percent']);
    setCategoryNeutralPercent(neutralPercentData);

    setCategorySummaryIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setCategorySummaryIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};



const getTopicSummaryData = async () => {

  // Topic Summary Data
  setTopicOverviewIsLoading(true);
  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&analyticFilter="}${"star"}${"&topicFilter="}${"top30"}${"&topicSortFilter="}${"highLow"}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&analyticFilter="}${"star"}${"&topicFilter="}${"top30"}${"&topicSortFilter="}${"highLow"}`;

  }

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl2}`);
    }

    setTopicComparison(resp.data.topicComparison);
    setTopicDetailComparison(resp.data.topicDetailComparison);

    setTopicComparisonOriginal(resp.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal(resp.data.topicDetailComparisonOriginal);

    setTopicDetailPositive(resp.data.positiveTopic);
    setTopicDetailNegative(resp.data.negativeTopic);

    setTopicMenuList(resp.data.topicMenu);
    setTopicDetailMenuList(resp.data.topicDetailMenu);

    setTopicAndTopicDetailMenuList(resp.data.topicAndTopicDetailMenu);

    setTopicMenuSelection(resp.data.topicMenu);
    setTopicDetailMenuSelection(resp.data.topicDetailMenu);

    setTopicOverviewIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};


const getTopicDetailData = async () => {

  // Topic Detail
  setTopicDetailIsLoading(true);

  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;

  }


  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl2}`);
    }
      setCategoryData(resp.data.categoryList);
      setTopicData(resp.data.topicList);
      setTopicDetailData(resp.data.topicDetailList);
      setTopicTrendData(resp.data.trendData);
      setCustomIndividualSentence(resp.data.individualSentenceCN);

      setTopicDetailIsLoading(false);
      setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicDetailIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};



const getIndividualSentenceData = async () => {

  // Individual Sentence
  setViewIndividualSentenceIsLoading(true);

  let restGETurl2;

  restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  try {

    let resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].rawAndAnalyzedData}${restGETurl2}`);
    
      setRawData(resp.data.rawData);
      setAnalyzedData(resp.data.analyzedData);

      setViewIndividualSentenceIsLoading(false);
      setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setViewIndividualSentenceIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};


const getSentimentMappingData = async () => {

    // Sentiment Mapping

    axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].sentimentMapping}`).then(function (response) { 
      // handle success
  
      let sentimentData = response.data.sentimentMapping;
  
      setSentimentMapping(sentimentData.map(data => data['Chinese']));
  
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


  try {

    let resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].sentimentMapping}`);
    
    let sentimentData = resp.data.sentimentMapping;
  
    setSentimentMapping(sentimentData.map(data => data['Chinese']));

  } catch (err) {
    // Handle Error Here
    console.error(err);


  }


};



const onClickChartData = () => {

  setProgress(0);

  getPageLoggingData();
  getUniqueTrendData();
  getCategorySummaryData(); // Update RESTAPI
  getTopicSummaryData(); // Update RESTAPI
  getTopicDetailData(); // Update RESTAPI
  getIndividualSentenceData(); // No need to change
  getSentimentMappingData(); // No need to change


  setModelSelectionCustom([])
  if(modelSelection != ""){
    setModelSelectionCustom(modelSelection.join("_"));
  }


  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + mm + dd;
  setCurrentDate(today);


}


// OnClick Refresh 1 Async Functions - Start


const getCategorySummaryDataRefresh1 = async () => {

  // Category Summary Data
  setCategorySummaryIsLoading(true);
  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&sortOption="}${sortOption}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&sortOption="}${sortOption}`;

  }

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {
    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl2}`);
    }

    let ratingData = resp.data.categorySummary.map(data => data['Rating']);
    setCategoryRating(ratingData);

    let colorData = resp.data.categorySummary.map(data => data['Color']);
    setCategoryColor(colorData);

    // Labels and Info Box
    setCategorySummaryOriginalData(resp.data.categorySummary);

    // Use categorySummaryCustom (Has Filters)

    setCategorySummaryData(resp.data.categorySummaryCustom);

    let labelData = resp.data.categorySummaryCustom.map(data => data['Category']);
    setCategoryLabel(labelData);


    let fiveStarCountData = resp.data.categorySummaryCustom.map(data => data['5-Star Count']);
    setCategoryFiveStarCount(fiveStarCountData);

    let fourStarCountData = resp.data.categorySummaryCustom.map(data => data['4-Star Count']);
    setCategoryFourStarCount(fourStarCountData);

    let threeStarCountData = resp.data.categorySummaryCustom.map(data => data['3-Star Count']);
    setCategoryThreeStarCount(threeStarCountData);

    let twoStarCountData = resp.data.categorySummaryCustom.map(data => data['2-Star Count']);
    setCategoryTwoStarCount(twoStarCountData);

    let oneStarCountData = resp.data.categorySummaryCustom.map(data => data['1-Star Count']);
    setCategoryOneStarCount(oneStarCountData);

    let fiveStarPercentData = resp.data.categorySummaryCustom.map(data => data['5-Star Percent']);
    setCategoryFiveStarPercent(fiveStarPercentData);

    let fourStarPercentData = resp.data.categorySummaryCustom.map(data => data['4-Star Percent']);
    setCategoryFourStarPercent(fourStarPercentData);

    let threeStarPercentData = resp.data.categorySummaryCustom.map(data => data['3-Star Percent']);
    setCategoryThreeStarPercent(threeStarPercentData);

    let twoStarPercentData = resp.data.categorySummaryCustom.map(data => data['2-Star Percent']);
    setCategoryTwoStarPercent(twoStarPercentData);

    let oneStarPercentData = resp.data.categorySummaryCustom.map(data => data['1-Star Percent']);
    setCategoryOneStarPercent(oneStarPercentData);

    let positiveCountData = resp.data.categorySummaryCustom.map(data => data['Positive Count']);
    setCategoryPositiveCount(positiveCountData);

    let neutralCountData = resp.data.categorySummaryCustom.map(data => data['Neutral Count']);
    setCategoryNeutralCount(neutralCountData);

    let negativeCountData = resp.data.categorySummaryCustom.map(data => data['Negative Count']);
    setCategoryNegativeCount(negativeCountData);

    let positivePercentData = resp.data.categorySummaryCustom.map(data => data['Positive Percent']);
    setCategoryPositivePercent(positivePercentData);

    let negativePercentData = resp.data.categorySummaryCustom.map(data => data['Negative Percent']);
    setCategoryNegativePercent(negativePercentData);

    let neutralPercentData = resp.data.categorySummaryCustom.map(data => data['Neutral Percent']);
    setCategoryNeutralPercent(neutralPercentData);

    setCategorySummaryIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setCategorySummaryIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};



const getTopicSummaryDataRefresh1 = async () => {

  // Topic Summary Data
  setTopicOverviewIsLoading(true);
  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl2}`);
    }

    setTopicComparison(resp.data.topicComparison);
    setTopicDetailComparison(resp.data.topicDetailComparison);

    setTopicComparisonOriginal(resp.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal(resp.data.topicDetailComparisonOriginal);

    setTopicDetailPositive(resp.data.positiveTopic);
    setTopicDetailNegative(resp.data.negativeTopic);

    //setTopicMenuList(resp.data.topicMenu); //error
    //setTopicDetailMenuList(resp.data.topicDetailMenu); //error

    setTopicAndTopicDetailMenuList(resp.data.topicAndTopicDetailMenu);

    //setTopicMenuSelection(resp.data.topicMenu);  //error
    //setTopicDetailMenuSelection(resp.data.topicDetailMenu); //error

    
    // Check if response is single or multiple
    // singe Topic list will need to be force into an array or an error would appear

    if(Array.isArray(resp.data.topicMenu)){
      setTopicMenuList(resp.data.topicMenu);
    }else{
      setTopicMenuList([resp.data.topicMenu]);
    }

    if(Array.isArray(resp.data.topicDetailMenu)){
      setTopicDetailMenuList(resp.data.topicDetailMenu);
    }else{
      setTopicDetailMenuList([resp.data.topicDetailMenu])
    }

    if(Array.isArray(resp.data.topicMenu)){
      setTopicMenuSelection(resp.data.topicMenu);
    }else{
      setTopicMenuSelection([resp.data.topicMenu])
    }

    if(Array.isArray(resp.data.topicDetailMenu)){
      setTopicDetailMenuSelection(resp.data.topicDetailMenu);
    }else{
      setTopicDetailMenuSelection([resp.data.topicDetailMenu]);
    }



    setTopicOverviewIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};


const getTopicDetailDataRefresh1 = async () => {

  // Topic Detail
  setTopicDetailIsLoading(true);

  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;

  }


  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl2}`);
    }
      setCategoryData(resp.data.categoryList);
      setTopicData(resp.data.topicList);
      setTopicDetailData(resp.data.topicDetailList);
      setTopicTrendData(resp.data.trendData);
      setCustomIndividualSentence(resp.data.individualSentenceCN);

      setTopicDetailIsLoading(false);
      setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicDetailIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};


// onClick Refresh 1 Async Function - End

const onClickChartDataRefresh = () => {

  getCategorySummaryDataRefresh1();
  getTopicSummaryDataRefresh1();
  getTopicDetailDataRefresh1();

} 




// onClick Refresh 2 Async Function - Start

const getTopicSummaryDataRefresh2 = async () => {

  // Topic Summary Data
  setTopicOverviewIsLoading(true);
  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl2}`);
    }

    setTopicComparison(resp.data.topicComparison);
    setTopicDetailComparison(resp.data.topicDetailComparison);

    setTopicComparisonOriginal(resp.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal(resp.data.topicDetailComparisonOriginal);

    setTopicDetailPositive(resp.data.positiveTopic);
    setTopicDetailNegative(resp.data.negativeTopic);

    setTopicMenuList(resp.data.topicMenu);
    setTopicDetailMenuList(resp.data.topicDetailMenu);

    setTopicAndTopicDetailMenuList(resp.data.topicAndTopicDetailMenu);

    setTopicMenuSelection(resp.data.topicMenu);
    setTopicDetailMenuSelection(resp.data.topicDetailMenu);

    setTopicOverviewIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


  if(analyticOption == "sentiment"){
    topicOverviewTopicComparisonDataSet = {
      type: 'bar',
      labels: topicComparison.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicComparison.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicComparison.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicComparison.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    topicOverviewTopicComparisonOption = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet = topicComparisonOriginal.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption == "star"){
    topicOverviewTopicComparisonDataSet = {
      type: 'bar',
      labels: topicComparison.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicComparison.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicComparison.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicComparison.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicComparison.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicComparison.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    topicOverviewTopicComparisonOption = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet = topicComparisonOriginal.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }




  // Topic Detail Overview - Topic Comparison List - Stack Bar Chart

  if(analyticOption2 == "sentiment"){
    var topicDetailOverviewTopicComparisonDataSet = {
      type: 'bar',
      labels: topicDetailComparison.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicDetailComparison.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicDetailComparison.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicDetailComparison.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    var topicDetailOverviewTopicComparisonOption = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet = topicDetailComparisonOriginal.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption2 == "star"){
    var topicDetailOverviewTopicComparisonDataSet = {
      type: 'bar',
      labels: topicDetailComparison.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicDetailComparison.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicDetailComparison.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicDetailComparison.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicDetailComparison.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicDetailComparison.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    var topicDetailOverviewTopicComparisonOption = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet = topicDetailComparisonOriginal.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


};


// onClick Refresh 2 Async Function - end



const onClickChartDataRefreshTopicOverview = () => {

  getTopicSummaryDataRefresh2();

}


// onClick Refresh 3 Async Function - start

const getTopicDetailDataRefresh3 = async () => {

  // Topic Detail
  setTopicDetailIsLoading(true);

  let restGETurl2;

  if(categorySelection === "TV"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

  }else if(categorySelection == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

  }


  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection === "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl2}`);
    }else if(categorySelection == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl2}`);
    }
      setCategoryData(resp.data.categoryList);
      setTopicData(resp.data.topicList);
      setTopicDetailData(resp.data.topicDetailList);
      setTopicTrendData(resp.data.trendData);
      setCustomIndividualSentence(resp.data.individualSentenceCN);

      setTopicDetailIsLoading(false);
      setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicDetailIsLoading(false);
    setProgress(prevCount => prevCount + 20);

  }


};

// onClick Refresh 3 Async Function - end
const onClickChartDataRefreshTopicDetail = () => {

  getTopicDetailDataRefresh3();

}


const onClickCheckAll = () => {
  // Select All Topic Selection
  setTopicMenuSelection(topicMenuList);

   // Set Topic Detail Menu list base user selection for Topic Menu
   let topicList = [];
   topicList = topicMenuList;

   let topicDetailList = topicAndTopicDetailMenuList.filter((data => topicList.includes(data.Topic)));
 
   setTopicDetailMenuList(topicDetailList.map(data => data["Topic.Detail"]));
   setTopicDetailMenuSelection(topicDetailList.map(data => data["Topic.Detail"]));

}



// Star Rating Trend Unique - Info Box
let fiveStarData = uniqueTrendData.map(data => data['5-Star']);
let fourStarData = uniqueTrendData.map(data => data['4-Star']);
let threeStarData = uniqueTrendData.map(data => data['3-Star']);
let twoStarData = uniqueTrendData.map(data => data['2-Star']);
let oneStarData = uniqueTrendData.map(data => data['1-Star']);

let fiveStarDataPercentCum = uniqueTrendDataPercentCum.map(data => data['5-Star']);
let fourStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['4-Star']);
let threeStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['3-Star']);
let twoStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['2-Star']);
let oneStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['1-Star']);
let cumulativeTotal  = uniqueTrendData.map(data => data['Cumulative Total']);


// Star Rating Trend Unique - Line Chart

let uniqueTrendViewTrendLabel = uniqueTrendDataPercentCum.map(data => data.Date);

let uniqueTrendViewTrendDataSet = {
  type: 'line',
  labels: uniqueTrendViewTrendLabel,
  datasets: [
    {
      label: '5-Star',
      data: fiveStarDataPercentCum ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '4-Star',
      data: fourStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(36, 222, 96)',
      borderColor: 'rgba(36, 222, 96, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '3-Star',
      data: threeStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '2-Star',
      data: twoStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(236, 50, 50)',
      borderColor: 'rgba(236, 50, 50, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '1-Star',
      data: oneStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: cumulativeTotal,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const uniqueTrendViewTrendOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },
  
  spanGaps: true,

  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      distribution: 'series',
      time: {
          unit: trendFilter
      }
  }]
  },
}

// Star Rating Trend Unique Table using react-data-table-component

const uniqueTrendTrendTableDataSet = 
uniqueTrendData.map((UniqueTrendData) => {
    return ({
      id: UniqueTrendData.id,
      date: UniqueTrendData.Date,
      fiveStar: UniqueTrendData['5-Star'],
      fourStar: UniqueTrendData['4-Star'],
      threeStar: UniqueTrendData['3-Star'],
      twoStar: UniqueTrendData['2-Star'],
      oneStar: UniqueTrendData['1-Star'],
      total: UniqueTrendData['Total'],
      cumulative: UniqueTrendData['Cumulative Total']
    })
  });
  
const uniqueTrendTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];


// Star Rating Trend Unique - Doughnut Chart

// Implement text inside Doughnut
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);
    
    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 114).toFixed(2); 
    ctx.font = fontSize + "em Verdana";
    ctx.textBaseline = "middle";

    var text = chart.config.data.text,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 1.8;

    ctx.fillText(text, textX, textY);
  }
});

let uniquePieViewTrendDataSet = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: [
        fiveStarData.reduce(function(a, b) { return a + b; }, 0), 
        fourStarData.reduce(function(a, b) { return a + b; }, 0), 
        threeStarData.reduce(function(a, b) { return a + b; }, 0), 
        twoStarData.reduce(function(a, b) { return a + b; }, 0), 
        oneStarData.reduce(function(a, b) { return a + b; }, 0)],
      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: uniqueTrendStarRatingAvg
  } 

  let uniquePieViewTrendOptions = {

    // Drill Down
    onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);


        }

      },
      
      options: {
        responsive: true,
        maintainAspectRatio: false
      },

     

  }


// Star Rating Trend Unique Table using react-data-table-component 

const uniqueTrendPieTableDataSet = [{
    fiveStar: fiveStarData.reduce(function(a, b) { return a + b; }, 0), 
    fourStar: fourStarData.reduce(function(a, b) { return a + b; }, 0), 
    threeStar: threeStarData.reduce(function(a, b) { return a + b; }, 0), 
    twoStar: twoStarData.reduce(function(a, b) { return a + b; }, 0), 
    oneStar: oneStarData.reduce(function(a, b) { return a + b; }, 0),
    rating: uniqueTrendStarRatingAvg
}]
;
  

const uniqueTrendPieTableColumn = [
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  }
];



const onClickUniqueTrendFilter = data => () => {

  if(typeof(dateEndSelection) != "undefined"){

    setTrendFilter(data);

    const axios = require('axios');

    setStarRatingTrendUniqueIsLoading(true);
  
   // Unique Trend Data
   setStarRatingTrendUniqueIsLoading(true);
   var restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&filterSelection="}${data}`;
   restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
   restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
   axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl1}`).then(function (response) { 
     // handle success
  
     setUniqueTrendData(response.data.uniqueTrendData);
     setUniqueTrendDataPercentCum(response.data.uniqueTrendDataPercentCum);
     setUniqueTrendStarRatingAvg(response.data.starRating);
     setUniqueTrendStarColor(response.data.color);
  
     
   }).catch(function (error) { 
     // handle error
     console.log(error);
  
     setUniqueTrendData([]);
     setUniqueTrendDataPercentCum([]);
     setUniqueTrendStarRatingAvg(0);
     setUniqueTrendStarColor('info');
  
   }).then(function () { 
     // always executed
     setStarRatingTrendUniqueIsLoading(false);
   });

  }

};

// Category Summary

// Dynamic Icon for Infobox
function IconSelectionCategorySummaryInfo1(){
  if(categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo2(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo3(){
  if(categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo4(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo5(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


function IconSelectionCategorySummaryInfo6(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


function IconSelectionCategorySummaryInfo7(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


function IconSelectionCategorySummaryInfo8(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo9(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


function IconSelectionCategorySummaryInfo10(){
  if(categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


function IconSelectionCategorySummaryInfo11(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Color)  == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Color)  == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


function IconSelectionCategorySummaryInfo12(){
  if(categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}



function IconSelectionCategorySummaryInfoMonitor1(){
  //console.table(categorySummaryOriginalData);
  if(categorySummaryOriginalData.filter(data => data.Category == "External Device").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "External Device").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "External Device").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


function IconSelectionCategorySummaryInfoMonitor2(){
  if(categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfoMonitor3(){
  if(categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfoMonitor4(){
  if(categorySummaryOriginalData.filter(data => data.Category == "SQ").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "SQ").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "SQ").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfoMonitor5(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Scenario").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Scenario").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Scenario").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfoMonitor6(){
  if(categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfoMonitor7(){
  if(categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfoMonitor8(){
  if(categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfoMonitor9(){
  if(categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color) == "success"){
    return <ThumpsUpIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color) == "warning"){
    return <HandIcon />
  }else if(categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color) == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}



// Category Summary Bar Chart

let categorySummaryBarDataSet = {
  type: 'bar',
  labels: categoryLabel,
  datasets: [
    {
      label: "5-Star",
      data: categoryFiveStarCount,
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
    },
    {
      label: "4-Star",
      data: categoryFourStarCount,
      borderColor: 'rgb(36, 222, 96)', // Line Color
      backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
    },
    {
      label: "3-Star",
      data: categoryThreeStarCount,
      borderColor: 'rgb(245, 200, 15)', // Line Color
      backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
    },
    {
      label: "2-Star",
      data: categoryTwoStarCount,
      borderColor: 'rgb(236, 50, 50)', // Line Color
      backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
    }
    ,
    {
      label: "1-Star",
      data: categoryOneStarCount,
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
    }
    
  ]
  } 


  let categorySummaryBarOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    },
    
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }

  

  }



// Category Summary Bar Chart Table using react-data-table-component 

const categorySummaryBarTableDataSet = 
  categorySummaryData.map((CategorySummaryData) => {
    return ({
      //id: CategorySummaryData.id,
      category: CategorySummaryData['Category'],
      fiveStar: CategorySummaryData['5-Star Count'], 
      fourStar: CategorySummaryData['4-Star Count'], 
      threeStar: CategorySummaryData['3-Star Count'], 
      twoStar: CategorySummaryData['2-Star Count'], 
      oneStar: CategorySummaryData['1-Star Count']
  
    })
  });



const categorySummaryBarTableColumn = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    center: true
  },
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  }
];




// Category Summary Bar Chart - Sentiment

let categorySummaryBarSentimentDataSet = {
  type: 'bar',
  labels: categoryLabel,
  datasets: [
    {
      label: "Positive",
      data: categoryPositiveCount,
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
    },
    {
      label: "Neutral",
      data: categoryNeutralCount,
      borderColor: 'rgb(245, 200, 15)', // Line Color
      backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
    },
    {
      label: "Negative",
      data: categoryNegativeCount,
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
    }
    
  ]
  } 


  let categorySummarySentimentBarOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    },
    
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }

  

  }



// Category Summary Sentiment Bar Chart Table using react-data-table-component 

const categorySummaryBarSentimentTableDataSet = 
  categorySummaryData.map((CategorySummaryData) => {
    return ({
      //id: CategorySummaryData.id,
      category: CategorySummaryData['Category'],
      pos: CategorySummaryData['Positive Count'], 
      neu: CategorySummaryData['Neutral Count'], 
      neg: CategorySummaryData['Negative Count']
  
    })
  });



const categorySummarySentimentBarTableColumn = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'pos',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neu',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'neg',
    sortable: true,
    center: true
  }
];



// Category Summary Radar Chart - Star

let categorySummaryStarRadarDataSet = {
  spanGaps: true,
  type: 'radar',
  labels: categoryLabel,
  datasets: [

    {
      label: "1-Star",
      data: categoryOneStarPercent,
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },
    {
      label: "2-Star",
      data: categoryTwoStarPercent,
      borderColor: 'rgb(236, 50, 50)', // Line Color
      backgroundColor: "rgba(236, 50, 50, .6)", // Fill Color
      pointBackgroundColor: "rgb(236, 50, 50)", // Dot Color
    },
    {
      label: "3-Star",
      data: categoryThreeStarPercent,
      borderColor: 'rgb(245, 200, 15)', // Line Color
      backgroundColor: "rgba(245, 200, 15, .6)", // Fill Color
      pointBackgroundColor: "rgb(245, 200, 15)", // Dot Color
    },
    {
      label: "4-Star",
      data: categoryFourStarPercent,
      borderColor: 'rgb(36, 222, 96)', // Line Color
      backgroundColor: "rgba(36, 222, 96, .6)", // Fill Color
      pointBackgroundColor: "rgb(36, 222, 96)", // Dot Color
    },
    {
      label: "5-Star",
      data:  categoryFiveStarPercent,
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    }
    
  ]
  } 


  let categorySummaryStarRadarOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component - Star

const categorySummaryStarRadarTableDataSet = 

categorySummaryData.map((CategorySummaryData) => {
  return ({
    id: CategorySummaryData.id,
    category: CategorySummaryData["Category"],
    fiveStarCount: CategorySummaryData["5-Star Count"], 
    fourStarCount: CategorySummaryData["4-Star Count"], 
    threeStarCount: CategorySummaryData["3-Star Count"], 
    twoStarCount: CategorySummaryData["2-Star Count"], 
    oneStarCount: CategorySummaryData["1-Star Count"], 
    fiveStarPercent: CategorySummaryData["5-Star Percent"], 
    fourStarPercent: CategorySummaryData["4-Star Percent"], 
    threeStarPercent: CategorySummaryData["3-Star Percent"], 
    twoStarPercent: CategorySummaryData["2-Star Percent"], 
    oneStarPercent: CategorySummaryData["1-Star Percent"], 

  })
});


const categorySummaryStarRadarTableColumn = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    center: true
  },
  {
    name: '5-Star Count',
    selector: 'fiveStarCount',
    sortable: true,
    center: true
  },
  {
    name: '4-Star Count',
    selector: 'fourStarCount',
    sortable: true,
    center: true
  },
  {
    name: '3-Star Count',
    selector: 'threeStarCount',
    sortable: true,
    center: true
  },
  {
    name: '2-Star Count',
    selector: 'twoStarCount',
    sortable: true,
    center: true
  },
  {
    name: '1-Star Count',
    selector: 'oneStarCount',
    sortable: true,
    center: true
  },

  {
    name: '5-Star Percent',
    selector: 'fiveStarPercent',
    sortable: true,
    center: true
  },
  {
    name: '4-Star Percent',
    selector: 'fourStarPercent',
    sortable: true,
    center: true
  },
  {
    name: '3-Star Percent',
    selector: 'threeStarPercent',
    sortable: true,
    center: true
  },
  {
    name: '2-Star Percent',
    selector: 'twoStarPercent',
    sortable: true,
    center: true
  },
  {
    name: '1-Star Percent',
    selector: 'oneStarPercent',
    sortable: true,
    center: true
  },

];  



// Category Summary Radar Chart - Sentiment

let categorySummaryRadarDataSet = {
  spanGaps: true,
  type: 'radar',
  labels: categoryLabel,
  datasets: [

    {
      label: "Negative",
      data: categoryNegativePercent,
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },

    {
      label: "Neutral",
      data: categoryNeutralPercent,
      borderColor: 'rgb(245, 200, 15)', // Line Color
      backgroundColor: "rgba(245, 200, 15, .6)", // Fill Color
      pointBackgroundColor: "rgb(245, 200, 15)", // Dot Color
    },
    {
      label: "Positive",
      data:  categoryPositivePercent,
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    }
    
  ]
  } 


  let categorySummaryRadarOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component 

const categorySummaryRadarTableDataSet = 

categorySummaryData.map((CategorySummaryData) => {
  return ({
    id: CategorySummaryData.id,
    category: CategorySummaryData["Category"],
    positiveCount: CategorySummaryData["Positive Count"], 
    neutralCount: CategorySummaryData["Neutral Count"], 
    negativeCount: CategorySummaryData["Negative Count"], 
    positivePercent: CategorySummaryData["Positive Percent"], 
    neutralPercent: CategorySummaryData["Neutral Percent"], 
    negativePercent: CategorySummaryData["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive Count',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Neutral Count',
  selector: 'neutralCount',
  sortable: true,
  center: true
},
{
  name: 'Negative Count',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Neutral Percent',
  selector: 'neutralPercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

];  




// Topic Overview - Topic Comparison List - Stack Bar Chart
if(analyticOption == "sentiment"){
  var topicOverviewTopicComparisonDataSet = {
    type: 'bar',
    labels: topicComparison.map(data => data['Topic']),
    datasets: [
      {
        label: 'Positive',
        data: topicComparison.map(data => data['Positive']),
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: 'Neutral',
        data: topicComparison.map(data => data['Neutral']),
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: 'Negative',
        data: topicComparison.map(data => data['Negative']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        stack: 'stack1'
      }
  
  
    ]
  
  }


  
  var topicOverviewTopicComparisonOption = {
  
    // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
  
  }
  
  // Topic Overview - Topic Comparison List Table using react-data-table-component
  
  var topicOverviewTopicComparisonTableDataSet = topicComparisonOriginal.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });
    
  var topicOverviewTopicComparisonTableColumn = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Positive',
      selector: 'positive',
      sortable: true,
      center: true
    },
    {
      name: 'Negative',
      selector: 'negative',
      sortable: true,
      center: true
    },
    {
      name: 'Neutral',
      selector: 'neutral',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

}


if(analyticOption == "star"){
  var topicOverviewTopicComparisonDataSet = {
    type: 'bar',
    labels: topicComparison.map(data => data['Topic']),
    datasets: [
      {
        label: '5-Star',
        data: topicComparison.map(data => data['5-Star']),
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '4-Star',
        data: topicComparison.map(data => data['4-Star']),
        fill: false,
        backgroundColor: 'rgb(36, 222, 96)',
        borderColor: 'rgba(36, 222, 96, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '3-Star',
        data: topicComparison.map(data => data['3-Star']),
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '2-Star',
        data: topicComparison.map(data => data['2-Star']),
        fill: false,
        backgroundColor: 'rgb(236, 50, 50)',
        borderColor: 'rgba(236, 50, 50, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '1-Star',
        data: topicComparison.map(data => data['1-Star']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        stack: 'stack1'
      }
  
  
    ]
  
  }
  
  var topicOverviewTopicComparisonOption = {
  
    // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
  
  }
  
  // Topic Overview - Topic Comparison List Table using react-data-table-component
  
  var topicOverviewTopicComparisonTableDataSet = topicComparisonOriginal.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });
    
  var topicOverviewTopicComparisonTableColumn = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

}


let languageLabel;
// Topic Detail Overview - Topic Comparison List - Stack Bar Chart
if(analyticOption2 == "sentiment"){

  if(topicLanguageOption == "chinese"){
    languageLabel = topicDetailComparison.map(data => data['Topic.Detail'])
  }else{
    languageLabel = topicDetailComparison.map(data => data['Translation_EN'])
  }

  var topicDetailOverviewTopicComparisonDataSet = {
    type: 'bar',
    //labels: topicDetailComparison.map(data => data['Topic.Detail']),
    labels: languageLabel,
    datasets: [
      {
        label: 'Positive',
        data: topicDetailComparison.map(data => data['Positive']),
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: 'Neutral',
        data: topicDetailComparison.map(data => data['Neutral']),
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: 'Negative',
        data: topicDetailComparison.map(data => data['Negative']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        stack: 'stack1'
      }
  
  
    ]
  
  }


  
  var topicDetailOverviewTopicComparisonOption = {
  
    // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
  
  }
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet = topicDetailComparisonOriginal.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });
    
  var topicDetailOverviewTopicComparisonTableColumn = [
    {
      name: 'Topic Detail',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Topic Detail - EN',
      selector: 'topicEN',
      sortable: true,
      center: true
    },
    {
      name: 'Positive',
      selector: 'positive',
      sortable: true,
      center: true
    },
    {
      name: 'Negative',
      selector: 'negative',
      sortable: true,
      center: true
    },
    {
      name: 'Neutral',
      selector: 'neutral',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

}

let topicDetailComparisonLabel;
if(topicLanguageOption == "chinese"){

  topicDetailComparisonLabel = topicDetailComparison.map(data => data['Topic.Detail']);

}else{
  topicDetailComparisonLabel = topicDetailComparison.map(data => data['Translation_EN']);
}

if(analyticOption2 == "star"){
  var topicDetailOverviewTopicComparisonDataSet = {
    type: 'bar',
    // labels: topicDetailComparison.map(data => data['Topic.Detail']),
    labels: topicDetailComparisonLabel,
    datasets: [
      {
        label: '5-Star',
        data: topicDetailComparison.map(data => data['5-Star']),
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '4-Star',
        data: topicDetailComparison.map(data => data['4-Star']),
        fill: false,
        backgroundColor: 'rgb(36, 222, 96)',
        borderColor: 'rgba(36, 222, 96, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '3-Star',
        data: topicDetailComparison.map(data => data['3-Star']),
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '2-Star',
        data: topicDetailComparison.map(data => data['2-Star']),
        fill: false,
        backgroundColor: 'rgb(236, 50, 50)',
        borderColor: 'rgba(236, 50, 50, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '1-Star',
        data: topicDetailComparison.map(data => data['1-Star']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        stack: 'stack1'
      }
  
  
    ]
  
  }
  
  var topicDetailOverviewTopicComparisonOption = {
  
    // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
  
  }
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet = topicDetailComparisonOriginal.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });
    
  var topicDetailOverviewTopicComparisonTableColumn = [
    {
      name: 'Topic Detail',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Topic Detail - EN',
      selector: 'topicEN',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

}



// Sentiment Overview - Positive Wordcloud

// const sentimentOverviewPositveWordsData = topicDetailPositive.map((data) => {
//   return({
//     text: data['Topic.Detail'],
//     value: data['Positive']
//   })
// })


const sentimentOverviewPositveWordsData = topicDetailPositive.map((data) => {

if(topicLanguageOption == "chinese"){

  return({
    text: data['Topic.Detail'],
    value: data['Positive']
  })

}else{

  return({
    text: data['Translation_EN'],
    value: data['Positive']
  })

}


})

const sentimentOverviewPositveCallbacks = {
  onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const sentimentOverviewPositveOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [10, 60],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function sentimentOverviewPositveWordCloud() {
  return <ReactWordcloud
    callbacks={sentimentOverviewPositveCallbacks}
    options={sentimentOverviewPositveOptions}
    words={sentimentOverviewPositveWordsData} 
  />
}


// Sentiment Overview - Positive Table using react-data-table-component

const sentimentOverviewPositveTableDataSet = topicDetailPositive.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic.Detail'],
      topic_en: data['Translation_EN'],
      freq: data['Positive']
    })
  });
  
const sentimentOverviewPositveTableColumn = [
  {
    name: 'Topic Detail',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Topic Detail - EN',
    selector: 'topic_en',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Sentiment Overview - Negative Wordcloud

const sentimentOverviewNegativeDataWords = topicDetailNegative.map((data) => {

  if(topicLanguageOption == "chinese"){

    return({
      text: data['Topic.Detail'],
      value: data['Negative']
    })

  }else{

    return({
      text: data['Translation_EN'],
      value: data['Negative']
    })

  }
  



})

const sentimentOverviewNegativeDataCallbacks = {
  onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const sentimentOverviewNegativeDataOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [10, 60],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function sentimentOverviewNegativeWordCloud() {
  return <ReactWordcloud
    callbacks={sentimentOverviewNegativeDataCallbacks}
    options={sentimentOverviewNegativeDataOptions}
    words={sentimentOverviewNegativeDataWords} 
  />
}


// Sentiment Overview - Negative Table using react-data-table-component

const sentimentOverviewNegativeDataTableDataSet = topicDetailNegative.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic.Detail'],
      topic_en: data['Translation_EN'],
      freq: data['Negative']
    })
  });
  
const sentimentOverviewNegativeDataTableColumn = [
  {
    name: 'Topic Detail',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Topic Detail - EN',
    selector: 'topic_en',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Detail - Category Horizontal Bar

var topicDetailDataSet = {
  type: 'bar',
  labels: categoryData.map(data => data['Category']),
  datasets: [
    {
      label: 'Count',
      data: categoryData.map(data => data['Total']),
      fill: false,
      backgroundColor: 'rgb(12, 27, 51)',
      borderColor: 'rgba(12, 27, 51, 0.6)'
    }

  ]

}



var topicDetailOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  scales: {
    xAxes: [{
      ticks: {
          beginAtZero:true
      }
    }]
  }

}

// Topic Overview - Topic Comparison List Table using react-data-table-component

var topicDetailTableDataSet = categoryData.map((data) => {
    return ({
      id: data.id,
      category: data['Category'],
      total: data['Total']
    })
  });
  
var topicDetailTableColumn = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Topic Detail - Topic Horizontal Bar

var topicDetailTopicDataSet = {
  type: 'bar',
  labels: topicData.map(data => data['Topic']),
  datasets: [
    {
      label: 'Count',
      data: topicData.map(data => data['Total']),
      fill: false,
      backgroundColor: 'rgb(122, 48, 108)',
      borderColor: 'rgba(122, 48, 108, 0.6)'
    }

  ]

}



var topicDetailTopicOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);
    }
  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  scales: {
    xAxes: [{
      ticks: {
          beginAtZero:true
      }
    }]
  }

}

// Topic Detail - Topic List Table using react-data-table-component

var topicDetailTopicTableDataSet = topicData.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      total: data['Total']
    })
  });
  
var topicDetailTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];


// Topic Detail - Topic Detail Horizontal Bar

var topicDetailTopicDetailDataSet = {
  type: 'bar',
  labels: topicDetailData.map(data => data['Topic.Detail']),
  datasets: [
    {
      label: 'Count',
      data: topicDetailData.map(data => data['Total']),
      fill: false,
      backgroundColor: 'rgb(3, 181, 170)',
      borderColor: 'rgba(3, 181, 170, 0.6)'
    }

  ]

}



var topicDetailTopicDetailOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);
    }
  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  scales: {
    xAxes: [{
      ticks: {
          beginAtZero:true
      }
    }]
  }

}

// Topic Detail - Topic Detail Table using react-data-table-component

var topicDetailTopicDetailTableDataSet = topicDetailData.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic.Detail'],
      total: data['Total']
    })
  });
  
var topicDetailTopicDetailTableColumn = [
  {
    name: 'Topic.Detail',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];




// Topic Detail - View Individual Sentence - Table using material-table

const topicDetailIndividualSentenceDataTableColumn = [
  {
    title: 'Date',
    field: 'date'
  },
  {
    title: 'Brand',
    field: 'brand'
  },
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Sentiment',
    field: 'sentiment'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Topic Detail',
    field: 'topicDetail'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 600,
      minWidth: 600
    },
    headerStyle: {
      width: 600,
      minWidth: 600
    }
  }
];



const topicDetailIndividualSentenceDataTableDataSet = customIndividualSentence.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    brand: data['Brand'],
    model: data['Model'],
    stars: data['Stars'],
    sentiment: data['Sentiment'],
    category: data['Category'],
    topic: data['Topic'],
    topicDetail: data['Topic.Detail'],
    individualSentence: data['Individual Sentence']
  })
});





// Trend by Category / Topic / Topic Detail - Stack Bar Chart

var trendByCategoryTopicTopicDetailBarDataSet;
var trendByCategoryTopicTopicDetailBarOption;
var trendByCategoryTopicTopicDetailTableDataSet;
var trendByCategoryTopicTopicDetailTableColumn;

if(topicDetailTrendRatingFilter == 'star'){
  trendByCategoryTopicTopicDetailBarDataSet = {
    type: 'bar',
    labels: topicTrendData.map(data => data['Date']),
    datasets: [
  
      {
        label: "5-Star",
        data: topicTrendData.map(data => data['5-Star']),
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
      },
      {
        label: "4-Star",
        data: topicTrendData.map(data => data['4-Star']),
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
      },
      {
        label: "3-Star",
        data: topicTrendData.map(data => data['3-Star']),
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
      },
      {
        label: "2-Star",
        data: topicTrendData.map(data => data['2-Star']),
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
      }
      ,
      {
        label: "1-Star",
        data: topicTrendData.map(data => data['1-Star']),
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
      }
      
    ]
    } 
  
  
    trendByCategoryTopicTopicDetailBarOption = {
  
      // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
            offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
    
  
    }
  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component 
  
  trendByCategoryTopicTopicDetailTableDataSet = topicTrendData.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        category: data['Date'],
        fiveStar: data['5-Star'], 
        fourStar: data['4-Star'], 
        threeStar: data['3-Star'], 
        twoStar: data['2-Star'], 
        oneStar: data['1-Star'],
        total: data['Total']
    
      })
    });
  
  
  
  trendByCategoryTopicTopicDetailTableColumn = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
  ];
  
}else{
  // Sentiment Selection

  trendByCategoryTopicTopicDetailBarDataSet = {
    type: 'bar',
    labels: topicTrendData.map(data => data['Date']),
    datasets: [
  
      {
        label: "Positive",
        data: topicTrendData.map(data => data['Positive']),
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
      },
      {
        label: "Neutral",
        data: topicTrendData.map(data => data['Neutral']),
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
      },
      {
        label: "Negative",
        data: topicTrendData.map(data => data['Negative']),
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
      }
      
    ]
    } 
  
  
    trendByCategoryTopicTopicDetailBarOption = {
  
      // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
          offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
    
  
    }
  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component 
  
  trendByCategoryTopicTopicDetailTableDataSet = topicTrendData.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        date: data['Date'],
        positive: data['Positive'], 
        neutral: data['Neutral'], 
        negative: data['Negative'], 
        total: data['Total']
    
      })
    });
  
  
  
  trendByCategoryTopicTopicDetailTableColumn = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
  ];



}


const onClickCategoryTopicTopicDetailTrendFilter = data => () => {

  setTopicDetailTrendFilter(data);

  const axios = require('axios');

  // Topic Detail
  setTopicDetailIsLoading(true);
  let restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${data}`;
  
  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  if(categorySelection === "TV"){

    axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl1}`).then(function (response) { 
      // handle success
  
      //setCategoryData(response.data.categoryList);
      //setTopicData(response.data.topicList);
      //setTopicDetailData(response.data.topicDetailList);
      setTopicTrendData(response.data.trendData);
      //setCustomIndividualSentence(response.data.individualSentenceCN);
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setTopicDetailIsLoading(false);
    });
    
  }else if(categorySelection == "Monitor"){

    axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl1}`).then(function (response) { 
      // handle success
  
      //setCategoryData(response.data.categoryList);
      //setTopicData(response.data.topicList);
      //setTopicDetailData(response.data.topicDetailList);
      setTopicTrendData(response.data.trendData);
      //setCustomIndividualSentence(response.data.individualSentenceCN);
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setTopicDetailIsLoading(false);
    });
    
  }




};


const onClickCategoryTopicTopicDetailTrendRatingFilter = data => () => {
  setTopicDetailTrendRatingFilter(data);
};




// Hot Topic Trend - Stack Bar Chart
let hotTopicTrendBarDataSet = {
  type: 'line',
  labels: topicTrendData.map(data => data['Date']),
  datasets: [
    {
      label: 'Ratio %',
      data: topicTrendData.map(data => data['Ratio']),
      fill: false,
      backgroundColor: 'rgb(255, 231, 76)', 
      borderColor: 'rgba(255, 231, 76, 0.6)',
      type: 'line',
      yAxisID: 'A'
    },
    {
      label: 'Other Topics',
      data: topicTrendData.map(data => data['OtherReviews']),
      fill: false,
      backgroundColor: 'rgb(56, 97, 140)',
      borderColor: 'rgba(56, 97, 140)',
      stack: 'bar',
      yAxisID: 'B'
    },
    {
      label: 'Selected Topic',
      data: topicTrendData.map(data => data['Total']),
      fill: false,
      backgroundColor: 'rgb(17, 145, 137)',
      borderColor: 'rgba(17, 145, 137, 0.6)',
      stack: 'bar',
      yAxisID: 'B'
    }



  ]

}


let hotTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          // max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      time: {
          unit: topicDetailTrendFilter
      }
  }]
  }


}

// Hot Topic Trend- Table using react-data-table-component

const hotTopicTrendTableDataSet = topicTrendData.map((data) => {
    return ({
      date: data['Date'],
      selectedTopic: data['Total'],
      otherReviews: data['OtherReviews'],
      ratio: data['Ratio']
    })
  });
  
const hotTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Selected Topic',
    selector: 'selectedTopic',
    sortable: true,
    center: true
  },
  {
    name: 'Other Reviews',
    selector: 'otherReviews',
    sortable: true,
    center: true
  },
  {
    name: 'Ratio',
    selector: 'ratio',
    sortable: true,
    center: true
  }
];


// Individual Sentence

const viewIndividualSentenceTableColumn = [
  {
    title: 'Date',
    field: 'date'
  },
  {
    title: 'Website',
    field: 'website'
  },
  {
    title: 'Country',
    field: 'country'
  },
  {
    title: 'ReviewerID',
    field: 'reviewerId'
  },
  {
    title: 'Brand',
    field: 'brand'
  },
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Topic Detail - CN',
    field: 'topicDetailCn'
  },
  {
    title: 'Topic Detail - EN',
    field: 'topicDetailEn'
  },
  {
    title: 'Full Review - CN',
    field: 'fullReviewCn',
    cellStyle: {
      width: 500,
      minWidth: 500
    },
    headerStyle: {
      width: 500,
      minWidth: 500
    }
  },
  {
    title: 'Individual Sentence - CN',
    field: 'individualSentenceCn',
    cellStyle: {
      width: 500,
      minWidth: 500
    },
    headerStyle: {
      width: 500,
      minWidth: 500
    }
  },
  {
    title: 'Sentiment Result',
    field: 'sentimentResult'
  },
  {
    title: 'PosList - CN',
    field: 'posListCn'
  },
  {
    title: 'NegList - CN',
    field: 'negListCn'
  },
  {
    title: 'Full Review - EN',
    field: 'fullReviewEn',
    cellStyle: {
      width: 500,
      minWidth: 500
    },
    headerStyle: {
      width: 500,
      minWidth: 500
    }
  },
  {
    title: 'Individual Sentence - En',
    field: 'individualSentenceEn',
    cellStyle: {
      width: 500,
      minWidth: 500
    },
    headerStyle: {
      width: 500,
      minWidth: 500
    }
  },
  {
    title: 'PosList - EN',
    field: 'posListEn'
  },
  {
    title: 'NegList - EN',
    field: 'negListEn'
  }

];



const viewIndividualSentenceDataTableDataSet = analyzedData.map((data) => {
  return ({
    id: data.id,
    date: data['Date'],
    website: data['Website'],
    country: data['Country'],
    reviewerId: data['Reviewer_ID'],
    brand: data['Brand'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    topicDetailCn: data['Topic.Detail_CN'],
    topicDetailEn: data['Topic.Detail_EN'],
    fullReviewCn: data['Full.Review_CN'],
    individualSentenceCn: data['Individual.Sentence_CN'],
    sentimentResult: data['Sentiment.Result'],
    posListCn: data['Pos_List_CN'],
    negListCn: data['Neg_List_CN'],
    fullReviewEn: data['Full.Review_EN'],
    individualSentenceEn: data['Individual.Sentence_EN'],
    posListEn: data['Pos_List_EN'],
    negListEn: data['Neg_List_EN']
  })
});




const onClickHighlight = (sentence, topicDetail) => {

  setHighlightSentence(sentence);
  setHighlightTopicDetail(topicDetail);

  sentimentMapping.push(topicDetail); // Combine Sentiment Words with specific topc for highlighting


}



// Conditional Render - Category Label

function CategoryLabel(){

  if(categorySelection === "TV"){

    return (
      <div>
             <GridContainer>
             <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo1()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>After-Service</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {/* {categoryRating[0]} */}
                    {categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo2()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Brand</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo3()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>COI</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo4()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Design</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo5()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Hardware</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo6()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Others</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo7()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Performance</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo8()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Picture</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo9()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Price</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo10()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>QA</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6}  md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo11()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Sound</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo12()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>UX</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
             </GridContainer>
      </div>
    )
    
  }else if(categorySelection === "Monitor"){
    return (
      <div>
             <GridContainer>
             <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "External Device").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "External Device").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor1()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>External Device</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {/* {categoryRating[0]} */}
                    {categorySummaryOriginalData.filter(data => data.Category == "External Device").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor2()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>PQ</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "PQ").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor3()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Design</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "SQ").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "SQ").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor4()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>SQ</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "SQ").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Scenario").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Scenario").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor5()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Scenario</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Scenario").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor6()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>QA</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor7()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>COI</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor8()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>UX</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfoMonitor9()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Performance</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
             </GridContainer>
      </div>
    )
  }else{ // Default
    return (
      <div>
             <GridContainer>
             <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo1()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>After-Service</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {/* {categoryRating[0]} */}
                    {categorySummaryOriginalData.filter(data => data.Category == "After-Service").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo2()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Brand</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Brand").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo3()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>COI</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "COI").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo4()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Design</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Design").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo5()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Hardware</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Hardware").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo6()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Others</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Others").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo7()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Performance</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Performance").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo8()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Picture</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Picture").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo9()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Price</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Price").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo10()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>QA</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "QA").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6}  md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo11()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>Sound</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "Sound").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
  
            <GridItem xs={12} sm={6} md={2}>
              <Card>
                <CardHeader color={categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color)} stats icon>
                  <CardIcon color={categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Color)} >
                   {IconSelectionCategorySummaryInfo12()}
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}>UX</p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categorySummaryOriginalData.filter(data => data.Category == "UX").map(data => data.Rating)}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>
  
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
             </GridContainer>
      </div>
    )
  }


}



// Conditional Render - Category Filter

function CategoryFilter(){

  if(categorySelection === "TV"){

    return (
      <div>
              <FormGroup>
                <FormLabel component="legend" style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Category Selection</strong></FormLabel>
                <FormControlLabel
                  control={<Checkbox checked={afterservice} onChange={handleChange} name="afterservice" size="small" style={{height: 3}} />}
                  label={<span style={{ fontSize: '14px', color: 'black' }}>After-Service</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={brand} onChange={handleChange} name="brand" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Brand</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={coi} onChange={handleChange} name="coi" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>COI</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={design} onChange={handleChange} name="design" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Design</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={hardware} onChange={handleChange} name="hardware" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Hardware</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={others} onChange={handleChange} name="others" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Others</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={performance} onChange={handleChange} name="performance" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Performance</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={picture} onChange={handleChange} name="picture" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Picture</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={price} onChange={handleChange} name="price" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Price</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={qa} onChange={handleChange} name="qa" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>QA</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={sound} onChange={handleChange} name="sound" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Sound</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={ux} onChange={handleChange} name="ux" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>UX</span>}
                />

                

              </FormGroup>
      </div>
    )
    
  }else if(categorySelection === "Monitor"){
    return (
      <div>
              <FormGroup>
                <FormLabel component="legend" style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Category Selection</strong></FormLabel>
                <FormControlLabel
                  control={<Checkbox checked={externaldeviceMonitor} onChange={handleChange} name="externaldeviceMonitor" size="small" style={{height: 3}} />}
                  label={<span style={{ fontSize: '14px', color: 'black' }}>External Device</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={pqMonitor} onChange={handleChange} name="pqMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>PQ</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={designMonitor} onChange={handleChange} name="designMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Design</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={sqMonitor} onChange={handleChange} name="sqMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>SQ</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={scenarioMonitor} onChange={handleChange} name="scenarioMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Scenario</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={qaMonitor} onChange={handleChange} name="qaMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>QA</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={coiMonitor} onChange={handleChange} name="coiMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>COI</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={uxMonitor} onChange={handleChange} name="uxMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>UX</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={performanceMonitor} onChange={handleChange} name="performanceMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Performance</span>}
                />

                

              </FormGroup>
      </div>
    )
  }else{ // Default
    return (
      <div>
              <FormGroup>
                <FormLabel component="legend" style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Category Selection</strong></FormLabel>
                <FormControlLabel
                  control={<Checkbox checked={afterservice} onChange={handleChange} name="afterservice" size="small" style={{height: 3}} />}
                  label={<span style={{ fontSize: '14px', color: 'black' }}>After-Service</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={brand} onChange={handleChange} name="brand" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Brand</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={coi} onChange={handleChange} name="coi" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>COI</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={design} onChange={handleChange} name="design" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Design</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={hardware} onChange={handleChange} name="hardware" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Hardware</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={others} onChange={handleChange} name="others" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Others</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={performance} onChange={handleChange} name="performance" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Performance</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={picture} onChange={handleChange} name="picture" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Picture</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={price} onChange={handleChange} name="price" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Price</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={qa} onChange={handleChange} name="qa" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>QA</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={sound} onChange={handleChange} name="sound" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Sound</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={ux} onChange={handleChange} name="ux" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>UX</span>}
                />

                

              </FormGroup>
      </div>
    )
  }


}








  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();
  const checkClasses = useCheckStyles();

  // (!viewIndividualSentenceIsLoading ? 
  return (
    (!viewIndividualSentenceIsLoading ?  
    <div>

      <div id="pageLoader" className={loadPage1}></div>

      <div id="mainPage" style={{display:loadPage2}}>

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BrandIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Brand</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="brand-select"
                  className={
                    classes.selectLabel
                }>
                  Select Brand
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={brandSelection}
                  onChange={handleBrand}
                  inputProps={
                    {
                      name: "brandSelection",
                      id: "brand-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  brandItems.map((brandItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={brandItems}>
                      {brandItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection}
                  onChange={handleCategory}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems.map((categoryItems, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={categoryItems}>
                        {categoryItems} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                {/* <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel> */}

                {/* <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection}
                  onChange={handleModel}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems.map((modelItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={modelItems}>
                      {modelItems} </MenuItem>
                  ))
                } </Select> */}

              <Autocomplete
                    multiple
                    limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="model-select"
                    disableCloseOnSelect
                    onChange={handleModel}
                    value={modelSelection}
                    inputValue={inputValue}
                    options={modelItems}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION (15 Max)'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                        {/* {console.log(option)} */}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params}
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onBlur={() => setInputValue('')}
                                        label="SELECT OR SEARCH MODEL" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />



              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <WebsiteIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Website</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="website-select"
                  className={
                    classes.selectLabel
                }>
                  Select Website
                </InputLabel>

                <Select 
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper }
                  }}
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={websiteSelection}
                  onChange={handleWebsite}
                  inputProps={
                    {
                      name: "websiteSelection",
                      id: "website-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  websiteItems.map((websiteItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={websiteItems}>
                      {websiteItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {
                      className: classes.selectMenu,
                      classes: { paper: classes.selectPaper }
                    }
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection}
                  onChange={handleCountry}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems.map((countryItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={countryItems}>
                      {countryItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FilterIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Unique Topic</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>
              <div className={classes.block} style={{height:"75px"}}>
                      <br/>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={uniqueTopic}
                            onChange={event => setUniqueTopic(event.target.checked)}
                            value="uniqueTopic"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={uniqueTopic === true ? "ON" : "OFF (Recommended)"}
                      />
                    </div>
            </CardBody>
          </Card>

          </GridItem>


        <GridItem xs={12}
          sm={12}
          md={6}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select Start Date" }}
                    // value={dateStartSelection}
                    // onChange={handleDateStart}
                    // isValidDate={validStart}
                    // viewDate={moment(dateStartSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItem, 'YYYY-MM-DD')}
                    onChange={handleDateStart}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart } 
                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select End Date" }}
                    // value={dateEndSelection}
                    // onChange={handleDateEnd}
                    // isValidDate={validEnd}
                    // viewDate={moment(dateEndSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItem, 'YYYY-MM-DD')}
                    onChange={handleDateEnd}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 

                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>


        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

            <Button color="pinterest" onClick={onClickChartData}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>
            

          </GridItem>

          </GridContainer>

          <br/>

{/* Unique Trend */}
        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Star Rating Trend - Unique User</b> </Button>
          </GridItem>

        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>5-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {fiveStarData.reduce(function(a, b) { return a + b; }, 0)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>4-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {fourStarData.reduce(function(a, b) { return a + b; }, 0)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HandIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>3-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {threeStarData.reduce(function(a, b) { return a + b; }, 0)} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>2-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {twoStarData.reduce(function(a, b) { return a + b; }, 0)} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>1-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {oneStarData.reduce(function(a, b) { return a + b; }, 0)}  
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>
 
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={uniqueTrendStarColor} stats icon>
                <CardIcon color={uniqueTrendStarColor}>
                  <StarsIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Star Rating</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {uniqueTrendStarRatingAvg} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>
          
      </GridContainer>

      
      <GridContainer>

        <GridItem xs={12} sm={12} md={8} >
 
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
                
                tabs={[
                  {
                    tabName: "Cumulative Trend by Percent",
                    tabIcon: TrendingUpIcon,
                    tabContent: (!starRatingTrendUniqueIsLoading ? 
                      <div>
                        <GridContainer >
                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton} onClick={onClickUniqueTrendFilter("month")}>
                            Month
                          </Button>
                          <Button color="white" size="sm" className={classes.middleButton} onClick={onClickUniqueTrendFilter("week")}>
                            Week
                          </Button>
                          <Button color="white" size="sm" className={classes.lastButton} onClick={onClickUniqueTrendFilter("day")}>
                            Day
                          </Button>
                        </div>
                        
                          </GridItem>

                          </GridContainer>
                        <br/>
                        <Line 
                          height={80} data={uniqueTrendViewTrendDataSet}  
                          options={uniqueTrendViewTrendOptions} /> 
                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!starRatingTrendUniqueIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={uniqueTrendTrendTableColumn}
                            data={uniqueTrendTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={uniqueTrendTrendTableDataSet} filename={`${currentDate}${"_"}${"Unique Trend_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
  
                      </GridItem>
  
                    </GridContainer>
  
                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={4} >
 
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="info"
                
                tabs={[
                  {
                    tabName: "Star Rating",
                    tabIcon: PieChartIcon,
                    tabContent: (!starRatingTrendUniqueIsLoading ? 
                      <div>
                        {/* <GridContainer >
                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton}>
                            Week
                          </Button>
                          <Button color="white" size="sm" className={classes.middleButton}>
                            Month
                          </Button>
                          <Button color="white" size="sm" className={classes.lastButton}>
                            Year
                          </Button>
                        </div>
                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton}>
                            Percent
                          </Button>
                          <Button color="white" size="sm" className={classes.middleButton}>
                            Value
                          </Button>
                        </div>

                          </GridItem>

                          </GridContainer>
                        <br/> */}
                        <Doughnut 
                          height={205} data={uniquePieViewTrendDataSet} options={uniquePieViewTrendOptions} /> 

                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!starRatingTrendUniqueIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={uniqueTrendPieTableColumn}
                            data={uniqueTrendPieTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={uniqueTrendPieTableDataSet} filename={`${currentDate}${"_"}${"Unique Pie_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
  
                      </GridItem>
  
                    </GridContainer>
  
                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

        </GridItem>


    </GridContainer>


{/* Category Overview */}
    <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Category Overview</b> </Button>
      </GridItem>

    </GridContainer>

    {/* <GridContainer> */}

{/* Conditional Render - Category Label */}


      <CategoryLabel />

    {/* </GridContainer> */}


{/* Conditional Render - Category Filter */}
    <GridContainer>

      <GridItem xs={12}
            sm={12}
            md={2}
            direction="row">

        <Card>
            <CardHeader color="rose">
              <p style={{ color: 'white'  }}><center><strong>Category Filter</strong></center></p>
            </CardHeader>
            <CardBody>
                
              <CategoryFilter />

              <hr/>

              <FormControl component="fieldset">
                <FormLabel component="legend" style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Sorting Option</strong></FormLabel>
                <RadioGroup aria-label="categorySortFilter" name="sortFilter" value={sortOption} onChange={handleChangeCategoryRadio}>
                  <FormControlLabel value="abc" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'}}>Alphabet</span>}  />
                  <FormControlLabel value="priority" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>Priority</span>} />
                  {/* <FormControlLabel value="fiveStar" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>5-Star</span>}/>
                  <FormControlLabel value="oneStar"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>1-Star</span>}/> */}
                  <FormControlLabel value="highLow"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>High to Low</span>}/>
                </RadioGroup>
              </FormControl>

              <hr/>
              <Button color="pinterest" size="sm" onClick={onClickChartDataRefresh}>
                <RefreshIcon className={classes.icons} />
                  Display/Refresh
              </Button>
              
            </CardBody>
        </Card>

      
   
      </GridItem>


      <GridItem xs={12}
            sm={12}
            md={5}>

        <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="primary"

        tabs={[
          {
            tabName: "Star Rating by Category",
            tabIcon: BarChartIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          {/* <GridContainer >
                              <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                              
                              <div className={classes.buttonGroup}>
                              <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryBarFilter("percent")}>
                              Percent
                            </Button>
                              <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryBarFilter("value")}>
                              Value
                            </Button>
                              </div>
                              
                              </GridItem>
                              
                              </GridContainer>
                              <br/> */}
                        <HorizontalBar 
                        height={215} data={categorySummaryBarDataSet} options={categorySummaryBarOption} /> 
                          
                          </div>
                          
                          
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          <DataTable
                        title=""
                        columns={categorySummaryBarTableColumn}
                        data={categorySummaryBarTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                        /> 
                          <GridContainer>
                          <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                          
                          <CSVLink data={categorySummaryBarTableDataSet} filename={`${currentDate}${"_"}${"Star Rating by Category_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                      
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                          Download CSV 
                        </Button>
                          </CSVLink>
                          
                          </GridItem>
                          
                          
                          
                          
                          
                          </GridContainer>
                          
                          </div>
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            
            
          }
        ]}
        /> 



        <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="info"

        tabs={[
          {
            tabName: "Star Rating by Category",
            tabIcon: RadarChartIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          {/* <GridContainer >
                              <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                              
                              <div className={classes.buttonGroup}>
                              <Button color="white" size="sm" className={classes.firstButton}>
                              Percent
                            </Button>
                              <Button color="white" size="sm" className={classes.middleButton}>
                              Value
                            </Button>
                              </div>
                              
                              </GridItem>
                              
                              </GridContainer>
                              <br/> */}
                        <Radar 
                        height={215} data={categorySummaryStarRadarDataSet}  
                        options={categorySummaryStarRadarOption} /> 
                          </div>
                          
                          
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          <DataTable
                        title=""
                        columns={categorySummaryStarRadarTableColumn}
                        data={categorySummaryStarRadarTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                        /> 
                          <GridContainer>
                          <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                          
                          <CSVLink data={categorySummaryStarRadarTableDataSet} filename={`${currentDate}${"_"}${"Star by Category_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                          
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                          Download CSV 
                        </Button>
                          </CSVLink>
                          
                          </GridItem>
                          
                          </GridContainer>
                          
                          </div>
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            
            
          }
        ]}
        /> 
      
   
      </GridItem>


{/* Category by Sentiment */}

      <GridItem xs={12} sm={12} md={5} >
        
        <CustomTabs
      //title="Tasks:"
      centered={true}
      headerColor="primary"

      tabs={[
        {
          tabName: "Sentiment Rating by Category",
          tabIcon: BarChartIcon,
          tabContent: (!categorySummaryIsLoading ? 
                        <div>
                        {/* <GridContainer >
                            <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">
                            
                            <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryBarFilter("percent")}>
                            Percent
                          </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryBarFilter("value")}>
                            Value
                          </Button>
                            </div>
                            
                            </GridItem>
                            
                            </GridContainer>
                            <br/> */}
                      <HorizontalBar 
                      height={215} data={categorySummaryBarSentimentDataSet} options={categorySummarySentimentBarOption} /> 
                        
                        </div>
                        
                        
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
        },
        {
          tabName: "Table",
          tabIcon: AssignmentIcon,
          tabContent: (!categorySummaryIsLoading ? 
                        <div>
                        <DataTable
                      title=""
                      columns={categorySummarySentimentBarTableColumn}
                      data={categorySummaryBarSentimentTableDataSet}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                      /> 
                        <GridContainer>
                        <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                        
                        <CSVLink data={categorySummaryBarSentimentTableDataSet} filename={`${currentDate}${"_"}${"Sentiment Rating by Category_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                        
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                        Download CSV 
                      </Button>
                        </CSVLink>
                        
                        </GridItem>
                        
                        
                        
                        
                        
                        </GridContainer>
                        
                        </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          
          
        }
      ]}
      /> 

      <CustomTabs
      //title="Tasks:"
      centered={true}
      headerColor="info"

      tabs={[
        {
          tabName: "Sentiment by Category",
          tabIcon: RadarChartIcon,
          tabContent: (!categorySummaryIsLoading ? 
                        <div>
                        {/* <GridContainer >
                            <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">
                            
                            <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton}>
                            Percent
                          </Button>
                            <Button color="white" size="sm" className={classes.middleButton}>
                            Value
                          </Button>
                            </div>
                            
                            </GridItem>
                            
                            </GridContainer>
                            <br/> */}
                      <Radar 
                      height={215} data={categorySummaryRadarDataSet}  
                      options={categorySummaryRadarOption} /> 
                        </div>
                        
                        
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
        },
        {
          tabName: "Table",
          tabIcon: AssignmentIcon,
          tabContent: (!categorySummaryIsLoading ? 
                        <div>
                        <DataTable
                      title=""
                      columns={categorySummaryRadarTableColumn}
                      data={categorySummaryRadarTableDataSet}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                      /> 
                        <GridContainer>
                        <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                        
                        <CSVLink data={categorySummaryRadarTableDataSet} filename={`${currentDate}${"_"}${"Sentiment by Category_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                        
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                        Download CSV 
                      </Button>
                        </CSVLink>
                        
                        </GridItem>
                        
                        </GridContainer>
                        
                        </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          
          
        }
      ]}
      />

        </GridItem>



    </GridContainer>

{/* Topic Overview */}
  <GridContainer>

    <GridItem xs={12}>
      <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Overview</b> </Button>
    </GridItem>

  </GridContainer>

  <GridContainer>

      <GridItem xs={12}
            sm={12}
            md={2}>

        <Card>
            <CardHeader color="rose">
              <p style={{ color: 'white'  }}><center><strong>Topic Filter</strong></center></p>
            </CardHeader>
            <CardBody>

              <FormControl component="fieldset">
                  <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Topic Count Option</strong></FormLabel>
                  <RadioGroup aria-label="TopicCountFilter" name="topicCountFilter" value={topicOption} onChange={handleChangeTopicRadio}>
                    <FormControlLabel value="top30" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Top 30</span>}  />
                    <FormControlLabel value="top20" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Top 20</span>}  />
                    <FormControlLabel value="top10" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Top 10</span>}  />
                  </RadioGroup>
                </FormControl>

              <hr/>

              <FormControl component="fieldset">
                <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Sorting Option</strong></FormLabel>
                <RadioGroup aria-label="TopicSortFilter" name="topicSortFilter" value={topicSortOption} onChange={handleChangeTopicSortRadio}>
                  <FormControlLabel value="abc" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'}}>Alphabet</span>}  />
                  <FormControlLabel value="fiveStar" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>5-Star/Positive</span>}/>
                  <FormControlLabel value="oneStar"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>1-Star/Negative</span>}/>
                  <FormControlLabel value="highLow"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>High to Low</span>}/>
                </RadioGroup>
              </FormControl>

              <hr/>

              <FormControl component="fieldset">
                <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Language</strong></FormLabel>
                <RadioGroup aria-label="TopicLanguageFilter" name="topicLanguageFilter" value={topicLanguageOption} onChange={handleChangeTopicLanguageRadio}>
                  <FormControlLabel value="chinese" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'}}>Chinese</span>}  />
                  <FormControlLabel value="english" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>English</span>}/>
                </RadioGroup>
              </FormControl>

              <hr/>
              <Button color="pinterest" size="sm" onClick={onClickChartDataRefreshTopicOverview}>
                <RefreshIcon className={classes.icons} />
                  Display/Refresh
              </Button>
              
            </CardBody>
        </Card>

      
   
      </GridItem>

{/* Topic Overview - Horizontal Bar  */}

      <GridItem xs={12} sm={12} md={5}>

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio('sentiment')}>
                              Sentiment
                            </Button>
                          </div>

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={200}
                        data={topicOverviewTopicComparisonDataSet}
                        options={topicOverviewTopicComparisonOption}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicOverviewTopicComparisonTableColumn}
                          data={topicOverviewTopicComparisonTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicOverviewTopicComparisonTableDataSet} filename={`${currentDate}${"_"}${"Topic Comparison List_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 



<CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "Positive Topic Detail List",
                    tabIcon: PositiveIcon,
                    tabContent: (!topicDetailIsLoading ? 
                      <div style={{ height: 400}}>

                        <div style={{ height: 400}}>
                          {sentimentOverviewPositveWordCloud()}
                        </div>

                        <br />
                        <br />
                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },

                

                  
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicDetailIsLoading ?
                        <div>
                          <DataTable
                            title=""
                            columns={sentimentOverviewPositveTableColumn}
                            data={sentimentOverviewPositveTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={sentimentOverviewPositveTableDataSet} filename={`${currentDate}${"_"}${"Topic Detail - Positive_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                        
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress disableShrink  style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>




          <GridItem xs={12} sm={12} md={5}>

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Detail Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio2('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio2('sentiment')}>
                              Sentiment
                            </Button>
                          </div>

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={200}
                        data={topicDetailOverviewTopicComparisonDataSet}
                        options={topicDetailOverviewTopicComparisonOption}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicDetailOverviewTopicComparisonTableColumn}
                          data={topicDetailOverviewTopicComparisonTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicDetailOverviewTopicComparisonTableDataSet} filename={`${currentDate}${"_"}${"Topic Detail Comparison List_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 



<CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="danger"
                
                tabs={[
                  {
                    tabName: "Negative Topic Detail List",
                    tabIcon: NegativeIcon,
                    tabContent: (!topicDetailIsLoading ? 
                      <div style={{ height: 400}}>
                        
                        <div style={{ height: 400}}>
                          {sentimentOverviewNegativeWordCloud()}
                        </div>

                        <br />
                        <br />
                      </div>

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicDetailIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={sentimentOverviewNegativeDataTableColumn}
                            data={sentimentOverviewNegativeDataTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={sentimentOverviewNegativeDataTableDataSet} filename={`${currentDate}${"_"}${"Topic Detail - Negative_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                        
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              />

          </GridItem>
          


        </GridContainer>


{/* Topic Detail Overview */}
        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Detail</b> </Button>
          </GridItem>

      </GridContainer>

      <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={6}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <TopicIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Topic Selection</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>

              <Autocomplete
                    multiple
                    //limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="topic-select"
                    disableCloseOnSelect
                    onChange={handleTopic}
                    inputValue={inputTopicValue}
                    value={topicMenuSelection}
                    options={topicMenuList}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params} 
                                        onChange={(e) => setInputTopicValue(e.target.value)}
                                        onBlur={() => setInputTopicValue('')}
                                        label="SELECT TOPIC" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
                  

              </FormControl>
                        
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={6}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DetailIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Topic Detail Selection</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>

              <Autocomplete
                    multiple
                    //limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="topicDetail-select"
                    disableCloseOnSelect
                    onChange={handleTopicDetail}
                    value={topicDetailMenuSelection}
                    inputValue={inputTopicDetailValue}
                    options={topicDetailMenuList}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params}
                                        onChange={(e) => setInputTopicDetailValue(e.target.value)}
                                        onBlur={() => setInputTopicDetailValue('')} 
                                        label="SELECT DETAIL TOPIC" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
                  

              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

      </GridContainer>


      <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">


            <Button color="warning" onClick={onClickCheckAll}> 
              <CheckIcon className={classes.icons} />
                Check All 
              </Button>

            <Button color="pinterest" onClick={onClickChartDataRefreshTopicDetail}>
              <RefreshIcon className={classes.icons} />
                Display/Refresh
            </Button>
            

          </GridItem>

        </GridContainer>

          <br/>



{/* Topic Detail - Horizontal Bar  */}
        <GridContainer>

          <GridItem xs={12} sm={12} md={4}>

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
              
                
                tabs={[
                  {
                    tabName: "Category",
                    tabIcon: CategoryIcon,
                    tabContent: (!topicDetailIsLoading ? 

                      
                      <div> 
                        {/* style={{ height: 300}} */}


                        <HorizontalBar
                          height={250}
                          data={topicDetailDataSet}
                          options={topicDetailOption}
                        />
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },

                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicDetailIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicDetailTableColumn}
                            data={topicDetailTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"445px"}
                            noHeader={true}
                            />

                            <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={topicDetailTableDataSet} filename={`${currentDate}${"_"}${"Category Count_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                                
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

            </GridItem>

            <GridItem xs={12} sm={12} md={4}>

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="primary"
                
                  
                  tabs={[
                    {
                      tabName: "Topic",
                      tabIcon: TopicIcon,
                      tabContent: (!topicDetailIsLoading ? 

                        
                        <div> 
                          {/* style={{ height: 300}} */}

 
                          <HorizontalBar
                            height={250}
                            data={topicDetailTopicDataSet}
                            options={topicDetailTopicOption}
                          />
                        </div>
                      

                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },

                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!topicDetailIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={topicDetailTopicTableColumn}
                              data={topicDetailTopicTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"445px"}
                              noHeader={true}
                              />

                              <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={topicDetailTopicTableDataSet} filename={`${currentDate}${"_"}${"Topic Count_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                          </div>
                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

              </GridItem>



              <GridItem xs={12} sm={12} md={4}>

                <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="primary"
                
                  
                  tabs={[
                    {
                      tabName: "Topic Detail",
                      tabIcon: DetailIcon,
                      tabContent: (!topicDetailIsLoading ? 

                        
                        <div> 
                          {/* style={{ height: 300}} */}

                          <HorizontalBar
                            height={250}
                            data={topicDetailTopicDetailDataSet}
                            options={topicDetailTopicDetailOption}
                          />
                        </div>
                      

                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },

                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!topicDetailIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={topicDetailTopicDetailTableColumn}
                              data={topicDetailTopicDetailTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"445px"}
                              noHeader={true}
                              />

                              <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={topicDetailTopicDetailTableDataSet} filename={`${currentDate}${"_"}${"Topic Detail Count_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                          </div>
                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

              </GridItem>



        </GridContainer>



{/*View Individual Sentence */}

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Custom Individual Sentence",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicDetailIsLoading ? 
                        <div>

                          
                          <div style={{ maxWidth: '100%' }}>
                                  <MaterialTable
                                    columns={topicDetailIndividualSentenceDataTableColumn}
                                    data={topicDetailIndividualSentenceDataTableDataSet}
                                    title="SMAT Review"
                                    options={{
                                      exportButton: true,
                                      exportAllData: true,
                                      filtering: true,
                                      showTitle: false
                                    }}
                                    actions={[
                                      {
                                        icon: TopicIcon,
                                        tooltip: 'Highlight Topic Detail and Sentiment',
                                        onClick: (event, rowData) => {
                                          // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                          //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                          //setFullReviewModal(true); // Show Pop-up
                                          //console.log(rowData.individualSentence); 
                                          onClickHighlight(rowData.individualSentence, rowData.topicDetail, ()=>{});
                                          setPopUpHighlight(true);

                                        }

                                      }
                                    ]}
                                    components={{
                                      Action: props => (

                                        <Tooltip
                                          id="tooltip-top"
                                          title="Highlight Topic Detail and Sentiment"
                                          placement="top"
                                          classes={{ tooltip: classes.tooltip }}
                                        >
                                            <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                              <HighlightIcon />
                                            </Button>
                                        </Tooltip>
                                        
                                      )
                                    }}
                                  />
                            </div>

                            <div>
                                    <Dialog
                                      fullWidth={true}
                                      maxWidth={'lg'}
                                      open={popUpHighlight}
                                      onClose={() => setPopUpHighlight(false)}
                                      //TransitionComponent={Transition}
                                      aria-labelledby="full-review-dialog-title1"
                                    >
                                      <DialogTitle id="full-review-dialog-title1"><strong> Sentence Review </strong></DialogTitle>
                                      <DialogContent>
                                        {/* <DialogContentText> */}
                                        {/* {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )} */}
                                        {/* </DialogContentText> */}
                                        <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={sentimentMapping} 
                                            autoEscape={true}
                                            textToHighlight={highlightSentence}
                                          />
                                      
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={() => setPopUpHighlight(false)} color="primary">
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>
 
                      <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                          <CSVLink data={customIndividualSentence} filename={`${currentDate}${"_"}${"Custom Individual Sentence_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                          
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                        </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          </GridContainer>


          <GridContainer>

            <GridItem xs={12}>
              <Button color="github" size="sm" style={{width: "100%"}}> <b>Trend by Category / Topic / Topic Detail</b> </Button>
            </GridItem>

        </GridContainer>



        <GridContainer>

          <GridItem xs={12} sm={12} md={12} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="warning"
                  
                  tabs={[
                    {
                      tabName: "Category / Topic / Topic Detail / Trend",
                      tabIcon: TrendingUpIcon,
                      tabContent: (!topicDetailIsLoading ? 
                        <div>
                          <GridContainer >
                            <GridItem 
                                xs={6}
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center">

                          <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                              Month
                            </Button>
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                              Week
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                              Day
                            </Button>
                          </div>
                          
                            </GridItem>

                            </GridContainer>


                            <GridContainer>
                              <GridItem 
                                  xs={6}
                                  container
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center">

                            <div className={classes.buttonGroup}>
                              <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("star")}>
                                Star
                              </Button>
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("sentiment")}>
                                Sentiment
                              </Button>
                            </div>
                            
                              </GridItem>
                            </GridContainer>


                          <br/>
                          <Bar 
                            height={50} data={trendByCategoryTopicTopicDetailBarDataSet}  
                            options={trendByCategoryTopicTopicDetailBarOption} /> 
                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!topicDetailIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={trendByCategoryTopicTopicDetailTableColumn}
                              data={trendByCategoryTopicTopicDetailTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={trendByCategoryTopicTopicDetailTableDataSet} filename={`${currentDate}${"_"}${"Trend By Category Topic Topic Detail Trend_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                          
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

          </GridItem>


          </GridContainer>


          <GridContainer>

            <GridItem xs={12}>
              <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Ratio Trend</b> </Button>
            </GridItem>

            </GridContainer>



            <GridContainer>

            <GridItem xs={12} sm={12} md={12} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="warning"
                  
                  tabs={[
                    {
                      tabName: "Topic Ratio Trend",
                      tabIcon: TrendingUpIcon,
                      tabContent: (!topicDetailIsLoading ? 
                        <div>
                          <GridContainer >
                            <GridItem 
                                xs={6}
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center">

                          <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                              Month
                            </Button>
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                              Week
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                              Day
                            </Button>
                          </div>
                          
                            </GridItem>

                            </GridContainer>

                          <Bar 
                            height={50} data={hotTopicTrendBarDataSet}  
                            options={hotTopicTrendOption} /> 
                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!topicDetailIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={hotTopicTrendTableColumn}
                              data={hotTopicTrendTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={hotTopicTrendTableDataSet} filename={`${currentDate}${"_"}${"Topic Ratio Trend_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                          
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>


            </GridContainer>


          <GridContainer>

            <GridItem xs={12}>
              <Button color="github" size="sm" style={{width: "100%"}}> <b>Individual Sentence</b> </Button>
            </GridItem>

          </GridContainer>




{/* Individual Sentence */}

        <GridContainer>

          <GridItem xs={12} sm={12} md={12} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Individual Sentence",
                    tabIcon: AssignmentIcon,
                      tabContent: (!viewIndividualSentenceIsLoading ? 
                        <div>
                          
                          <div style={{ maxWidth: '100%' }}>
                                  <MaterialTable
                                    columns={viewIndividualSentenceTableColumn}
                                    data={viewIndividualSentenceDataTableDataSet}
                                    title="SMAT Review"
                                    options={{
                                      exportButton: true,
                                      exportAllData: true,
                                      filtering: true,
                                      showTitle: false
                                    }}
                                  />
                            </div>

                      <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                          <CSVLink data={analyzedData} filename={`${currentDate}${"_"}${"Analyzed Data_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                          
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Analyzed Data 
                            </Button>
                          </CSVLink>

                          <CSVLink data={rawData} filename={`${currentDate}${"_"}${"Raw Data_"}${brandSelection}${"_"}${modelSelectionCustom}${".csv"}`}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Raw Data 
                            </Button>
                          </CSVLink>

                        </GridItem>

                        </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          </GridContainer>
        

    
    </div> </div> : <LinearProgressWithLabel value={progress} style={{textAlign: 'center'}}/>
    )
    // <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
    
  );
}
