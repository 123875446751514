/*eslint-disable*/

import React, { Fragment, useMemo, useCallback } from "react";

// CSS
import "../../assets/css/page-loading.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Instruction from "components/Instruction/Instruction.js";
import Checkbox from "@material-ui/core/Checkbox";
import NavPills from "components/NavPills/NavPills.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from '@material-ui/core/Tooltip';



// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import CalculationIcon from "@material-ui/icons/Grade";
import BubbleIcon from "@material-ui/icons/BubbleChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/Loyalty";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import RatingIcon from "@material-ui/icons/RateReview";
import FilterIcon from "@material-ui/icons/FilterList";
import Warning from "@material-ui/icons/Warning";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
import SoftwareIcon from "@material-ui/icons/Apps";
import PictureIcon from "@material-ui/icons/Image";
import PriceIcon from "@material-ui/icons/AttachMoney";
import DesignIcon from "@material-ui/icons/DataUsage";
import FeatureIcon from "@material-ui/icons/Settings";
import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import DetailIcon from "@material-ui/icons/Details";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import HighlightIcon from "@material-ui/icons/Highlight";
import CheckIcon from "@material-ui/icons/Check";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import checkStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Bubble, Line, Doughnut, Radar, Bar, Chart, HorizontalBar, Pie } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component
import Highlighter from "react-highlight-words";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);


const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



function LinearProgressWithLabel(props) {
  return (
    <div>
      
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" color="secondary" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="h4" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};





export default function CompetitorAnalyticCN() {

  const axios = require('axios');

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Competitor Analytics - CN"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])


  
  // Loading Param
  const [progressBarIsLoading, setProgressBarIsLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(10);


  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");

  // Loading by Section
  const [starRatingTrendUniqueIsLoading1, setStarRatingTrendUniqueIsLoading1] = React.useState(false);
  const [starRatingTrendUniqueIsLoading2, setStarRatingTrendUniqueIsLoading2] = React.useState(false);
  const [starRatingTrendUniqueIsLoading3, setStarRatingTrendUniqueIsLoading3] = React.useState(false);

  const [categorySummaryIsLoading, setCategorySummaryIsLoading] = React.useState(false);
  
  const [topicOverviewIsLoading, setTopicOverviewIsLoading] = React.useState(false);

  const [viewIndividualSentenceIsLoading1, setViewIndividualSentenceIsLoading1] = React.useState(false);
  const [viewIndividualSentenceIsLoading2, setViewIndividualSentenceIsLoading2] = React.useState(false);
  const [viewIndividualSentenceIsLoading3, setViewIndividualSentenceIsLoading3] = React.useState(false);

  const [topicDetail2IsLoading, setTopicDetail2IsLoading] = React.useState(false);

  // filters
  const [allFilters, setAllFilters] = React.useState([]);


  // Join model list to a string
  const [modelSelectionCustom1, setModelSelectionCustom1] = React.useState([]);
  const [modelSelectionCustom2, setModelSelectionCustom2] = React.useState([]);
  const [modelSelectionCustom3, setModelSelectionCustom3] = React.useState([]);

  const [currentDate1, setCurrentDate1] = React.useState('');
  const [currentDate2, setCurrentDate2] = React.useState('');
  const [currentDate3, setCurrentDate3] = React.useState('');


  // for model search result to appear constant after selection
  const [inputValue1, setInputValue1] = React.useState("");

  const [brandItems1, setBrandItems1] = React.useState(['']);
  const [brandSelection1, setBrandSelection1] = React.useState('');

  const [categoryItems1, setCategoryItems1] = React.useState([]);
  const [categorySelection1, setCategorySelection1] = React.useState('');

  const [modelItems1, setModelItems1] = React.useState([]);
  const [modelSelection1, setModelSelection1] = React.useState([]);

  const [dateStartItem1, setDateStartItem1] = React.useState('');
  const [dateStartSelection1, setDateStartSelection1] = React.useState('');

  const [dateEndItem1, setDateEndItem1] = React.useState('');
  const [dateEndSelection1, setDateEndSelection1] = React.useState('');


  // for model search result to appear constant after selection
  const [inputValue2, setInputValue2] = React.useState("");

  const [brandItems2, setBrandItems2] = React.useState(['']);
  const [brandSelection2, setBrandSelection2] = React.useState('');

  const [categoryItems2, setCategoryItems2] = React.useState([]);
  const [categorySelection2, setCategorySelection2] = React.useState('');

  const [modelItems2, setModelItems2] = React.useState([]);
  const [modelSelection2, setModelSelection2] = React.useState([]);

  const [dateStartItem2, setDateStartItem2] = React.useState('');
  const [dateStartSelection2, setDateStartSelection2] = React.useState('');

  const [dateEndItem2, setDateEndItem2] = React.useState('');
  const [dateEndSelection2, setDateEndSelection2] = React.useState('');


  // for model search result to appear constant after selection
  const [inputValue3, setInputValue3] = React.useState("");

  const [brandItems3, setBrandItems3] = React.useState(['']);
  const [brandSelection3, setBrandSelection3] = React.useState('');

  const [categoryItems3, setCategoryItems3] = React.useState([]);
  const [categorySelection3, setCategorySelection3] = React.useState('');

  const [modelItems3, setModelItems3] = React.useState([]);
  const [modelSelection3, setModelSelection3] = React.useState([]);

  const [dateStartItem3, setDateStartItem3] = React.useState('');
  const [dateStartSelection3, setDateStartSelection3] = React.useState('');

  const [dateEndItem3, setDateEndItem3] = React.useState('');
  const [dateEndSelection3, setDateEndSelection3] = React.useState('');



  const [uniqueTopic, setUniqueTopic] = React.useState(false);
  const [uniqueTopicSelection, setUniqueTopicSelection] = React.useState("No (Recommended)");



  // SMAT Trend / Pie
  const [uniqueTrendData1, setUniqueTrendData1] = React.useState([]);
  const [uniqueTrendDataPercentCum1, setUniqueTrendDataPercentCum1] = React.useState([]);
  const [uniqueTrendStarRatingAvg1, setUniqueTrendStarRatingAvg1] = React.useState(0);
  const [uniqueTrendStarColor1, setUniqueTrendStarColor1] = React.useState('info');

  const [uniqueTrendData2, setUniqueTrendData2] = React.useState([]);
  const [uniqueTrendDataPercentCum2, setUniqueTrendDataPercentCum2] = React.useState([]);
  const [uniqueTrendStarRatingAvg2, setUniqueTrendStarRatingAvg2] = React.useState(0);
  const [uniqueTrendStarColor2, setUniqueTrendStarColor2] = React.useState('info');

  const [uniqueTrendData3, setUniqueTrendData3] = React.useState([]);
  const [uniqueTrendDataPercentCum3, setUniqueTrendDataPercentCum3] = React.useState([]);
  const [uniqueTrendStarRatingAvg3, setUniqueTrendStarRatingAvg3] = React.useState(0);
  const [uniqueTrendStarColor3, setUniqueTrendStarColor3] = React.useState('info');

  const [trendFilter1, setTrendFilter1] = React.useState("month");
  const [trendFilter2, setTrendFilter2] = React.useState("month");
  const [trendFilter3, setTrendFilter3] = React.useState("month");


     // Category Summary
  
     const [categorySummaryOriginalData1, setCategorySummaryOriginalData1] = React.useState([]);
     const [categorySummaryData1, setCategorySummaryData1] = React.useState([]);
     const [categoryLabel1, setCategoryLabel1] = React.useState([]);
     const [categoryRating1, setCategoryRating1] = React.useState([]); // Order: "After-Service", "Brand", "COI", "Design", "Hardware", "Hardware/UX", "Others", "Performance", "Picture", "Price", "QA", "Sound", "UX"
     const [categoryColor1, setCategoryColor1] = React.useState([]);
     const [sortOption, setSortOption] = React.useState('highLow');
     
     const [categoryFiveStarCount1, setCategoryFiveStarCount1] = React.useState([]);
     const [categoryFourStarCount1, setCategoryFourStarCount1] = React.useState([]);
     const [categoryThreeStarCount1, setCategoryThreeStarCount1] = React.useState([]);
     const [categoryTwoStarCount1, setCategoryTwoStarCount1] = React.useState([]);
     const [categoryOneStarCount1, setCategoryOneStarCount1] = React.useState([]);
  
     const [categoryFiveStarPercent1, setCategoryFiveStarPercent1] = React.useState([]);
     const [categoryFourStarPercent1, setCategoryFourStarPercent1] = React.useState([]);
     const [categoryThreeStarPercent1, setCategoryThreeStarPercent1] = React.useState([]);
     const [categoryTwoStarPercent1, setCategoryTwoStarPercent1] = React.useState([]);
     const [categoryOneStarPercent1, setCategoryOneStarPercent1] = React.useState([]);
  
     const [categoryPositiveCount1, setCategoryPositiveCount1] = React.useState([]);
     const [categoryNeutralCount1, setCategoryNeutralCount1] = React.useState([]);
     const [categoryNegativeCount1, setCategoryNegativeCount1] = React.useState([]);
  
     const [categoryPositivePercent1, setCategoryPositivePercent1] = React.useState([]);
     const [categoryNeutralPercent1, setCategoryNeutralPercent1] = React.useState([]);
     const [categoryNegativePercent1, setCategoryNegativePercent1] = React.useState([]);

     const [categorySummaryOriginalData2, setCategorySummaryOriginalData2] = React.useState([]);
     const [categorySummaryData2, setCategorySummaryData2] = React.useState([]);
     const [categoryLabel2, setCategoryLabel2] = React.useState([]);
     const [categoryRating2, setCategoryRating2] = React.useState([]); // Order: "After-Service", "Brand", "COI", "Design", "Hardware", "Hardware/UX", "Others", "Performance", "Picture", "Price", "QA", "Sound", "UX"
     const [categoryColor2, setCategoryColor2] = React.useState([]);
     
     const [categoryFiveStarCount2, setCategoryFiveStarCount2] = React.useState([]);
     const [categoryFourStarCount2, setCategoryFourStarCount2] = React.useState([]);
     const [categoryThreeStarCount2, setCategoryThreeStarCount2] = React.useState([]);
     const [categoryTwoStarCount2, setCategoryTwoStarCount2] = React.useState([]);
     const [categoryOneStarCount2, setCategoryOneStarCount2] = React.useState([]);
  
     const [categoryFiveStarPercent2, setCategoryFiveStarPercent2] = React.useState([]);
     const [categoryFourStarPercent2, setCategoryFourStarPercent2] = React.useState([]);
     const [categoryThreeStarPercent2, setCategoryThreeStarPercent2] = React.useState([]);
     const [categoryTwoStarPercent2, setCategoryTwoStarPercent2] = React.useState([]);
     const [categoryOneStarPercent2, setCategoryOneStarPercent2] = React.useState([]);
  
     const [categoryPositiveCount2, setCategoryPositiveCount2] = React.useState([]);
     const [categoryNeutralCount2, setCategoryNeutralCount2] = React.useState([]);
     const [categoryNegativeCount2, setCategoryNegativeCount2] = React.useState([]);
  
     const [categoryPositivePercent2, setCategoryPositivePercent2] = React.useState([]);
     const [categoryNeutralPercent2, setCategoryNeutralPercent2] = React.useState([]);
     const [categoryNegativePercent2, setCategoryNegativePercent2] = React.useState([]);

     const [categorySummaryOriginalData3, setCategorySummaryOriginalData3] = React.useState([]);
     const [categorySummaryData3, setCategorySummaryData3] = React.useState([]);
     const [categoryLabel3, setCategoryLabel3] = React.useState([]);
     const [categoryRating3, setCategoryRating3] = React.useState([]); // Order: "After-Service", "Brand", "COI", "Design", "Hardware", "Hardware/UX", "Others", "Performance", "Picture", "Price", "QA", "Sound", "UX"
     const [categoryColor3, setCategoryColor3] = React.useState([]);
     
     const [categoryFiveStarCount3, setCategoryFiveStarCount3] = React.useState([]);
     const [categoryFourStarCount3, setCategoryFourStarCount3] = React.useState([]);
     const [categoryThreeStarCount3, setCategoryThreeStarCount3] = React.useState([]);
     const [categoryTwoStarCount3, setCategoryTwoStarCount3] = React.useState([]);
     const [categoryOneStarCount3, setCategoryOneStarCount3] = React.useState([]);
  
     const [categoryFiveStarPercent3, setCategoryFiveStarPercent3] = React.useState([]);
     const [categoryFourStarPercent3, setCategoryFourStarPercent3] = React.useState([]);
     const [categoryThreeStarPercent3, setCategoryThreeStarPercent3] = React.useState([]);
     const [categoryTwoStarPercent3, setCategoryTwoStarPercent3] = React.useState([]);
     const [categoryOneStarPercent3, setCategoryOneStarPercent3] = React.useState([]);
  
     const [categoryPositiveCount3, setCategoryPositiveCount3] = React.useState([]);
     const [categoryNeutralCount3, setCategoryNeutralCount3] = React.useState([]);
     const [categoryNegativeCount3, setCategoryNegativeCount3] = React.useState([]);
  
     const [categoryPositivePercent3, setCategoryPositivePercent3] = React.useState([]);
     const [categoryNeutralPercent3, setCategoryNeutralPercent3] = React.useState([]);
     const [categoryNegativePercent3, setCategoryNegativePercent3] = React.useState([]);



  // Topic Overview
  const [analyticOption, setAnalyticOption] = React.useState('star');

  const [analyticOptionChart1Model1, setAnalyticOptionChart1Model1] = React.useState('star');
  const [analyticOptionChart2Model1, setAnalyticOptionChart2Model1] = React.useState('star');

  const [analyticOptionChart1Model2, setAnalyticOptionChart1Model2] = React.useState('star');
  const [analyticOptionChart2Model2, setAnalyticOptionChart2Model2] = React.useState('star');

  const [analyticOptionChart1Model3, setAnalyticOptionChart1Model3] = React.useState('star');
  const [analyticOptionChart2Model3, setAnalyticOptionChart2Model3] = React.useState('star');


  const [topicOption, setTopicOption] = React.useState('top10'); // top 20
  const [topicSortOption, setTopicSortOption] = React.useState('highLow');
  const [topicLanguageOption, setTopicLanguageOption] = React.useState('chinese'); // chinese

  const [topicComparison1, setTopicComparison1] = React.useState([]);
  const [topicDetailComparison1, setTopicDetailComparison1] = React.useState([]);

  const [topicComparisonOriginal1, setTopicComparisonOriginal1] = React.useState([]);
  const [topicDetailComparisonOriginal1, setTopicDetailComparisonOriginal1] = React.useState([]);

  const [topicDetailPositive1, setTopicDetailPositive1] = React.useState([]);
  const [topicDetailNegative1, setTopicDetailNegative1] = React.useState([]);

  const [topicComparison2, setTopicComparison2] = React.useState([]);
  const [topicDetailComparison2, setTopicDetailComparison2] = React.useState([]);

  const [topicComparisonOriginal2, setTopicComparisonOriginal2] = React.useState([]);
  const [topicDetailComparisonOriginal2, setTopicDetailComparisonOriginal2] = React.useState([]);

  const [topicDetailPositive2, setTopicDetailPositive2] = React.useState([]);
  const [topicDetailNegative2, setTopicDetailNegative2] = React.useState([]);

  const [topicComparison3, setTopicComparison3] = React.useState([]);
  const [topicDetailComparison3, setTopicDetailComparison3] = React.useState([]);

  const [topicComparisonOriginal3, setTopicComparisonOriginal3] = React.useState([]);
  const [topicDetailComparisonOriginal3, setTopicDetailComparisonOriginal3] = React.useState([]);

  const [topicDetailPositive3, setTopicDetailPositive3] = React.useState([]);
  const [topicDetailNegative3, setTopicDetailNegative3] = React.useState([]);


  // Topic Detail
  const [topicMenuList, setTopicMenuList] = React.useState([]);
  const [topicDetailMenuList, setTopicDetailMenuList] = React.useState([]);

  const [topicMenuSelection, setTopicMenuSelection] = React.useState([]);
  const [topicDetailMenuSelection, setTopicDetailMenuSelection] = React.useState([]);

  const [topicAndTopicDetailMenuList, setTopicAndTopicDetailMenuList] = React.useState([]);

  // Topic and Topic Detail custom search place holder
  const [inputTopicValue, setInputTopicValue] = React.useState("");
  const [inputTopicDetailValue, setInputTopicDetailValue] = React.useState("");


  // Topic Detail

  const [topicDetailTrendFilter, setTopicDetailTrendFilter] = React.useState("month");
  const [topicDetailTrendRatingFilter, setTopicDetailTrendRatingFilter] = React.useState('star');


  const [categoryData1, setCategoryData1] = React.useState([]);
  const [topicData1, setTopicData1] = React.useState([]);
  const [topicDetailData1, setTopicDetailData1] = React.useState([]);
  const [topicTrendData1, setTopicTrendData1] = React.useState([]);
  const [customIndividualSentence1, setCustomIndividualSentence1] = React.useState([]);

  const [highlightSentence1, setHighlightSentence1] = React.useState('');
  const [highlightTopicDetail1, setHighlightTopicDetail1] = React.useState([]);

  const [topicDetailTrendFilter1, setTopicDetailTrendFilter1] = React.useState("month");
  const [topicDetailTrendRatingFilter1, setTopicDetailTrendRatingFilter1] = React.useState('star');

  const [hotTopicDetailTrendFilter1, setHotTopicDetailTrendFilter1] = React.useState("month");

  const [categoryData2, setCategoryData2] = React.useState([]);
  const [topicData2, setTopicData2] = React.useState([]);
  const [topicDetailData2, setTopicDetailData2] = React.useState([]);
  const [topicTrendData2, setTopicTrendData2] = React.useState([]);
  const [customIndividualSentence2, setCustomIndividualSentence2] = React.useState([]);

  const [highlightSentence2, setHighlightSentence2] = React.useState('');
  const [highlightTopicDetail2, setHighlightTopicDetail2] = React.useState([]);

  const [topicDetailTrendFilter2, setTopicDetailTrendFilter2] = React.useState("month");
  const [topicDetailTrendRatingFilter2, setTopicDetailTrendRatingFilter2] = React.useState('star');

  const [hotTopicDetailTrendFilter2, setHotTopicDetailTrendFilter2] = React.useState("month");


  const [categoryData3, setCategoryData3] = React.useState([]);
  const [topicData3, setTopicData3] = React.useState([]);
  const [topicDetailData3, setTopicDetailData3] = React.useState([]);
  const [topicTrendData3, setTopicTrendData3] = React.useState([]);
  const [customIndividualSentence3, setCustomIndividualSentence3] = React.useState([]);

  const [highlightSentence3, setHighlightSentence3] = React.useState('');
  const [highlightTopicDetail3, setHighlightTopicDetail3] = React.useState([]);

  const [topicDetailTrendFilter3, setTopicDetailTrendFilter3] = React.useState("month");
  const [topicDetailTrendRatingFilter3, setTopicDetailTrendRatingFilter3] = React.useState('star');

  const [hotTopicDetailTrendFilter3, setHotTopicDetailTrendFilter3] = React.useState("month");


  const [highlightSentence, setHighlightSentence] = React.useState('');
  const [highlightTopicDetail, setHighlightTopicDetail] = React.useState([]);

  // Sentiment Mapping
  const [sentimentMapping, setSentimentMapping] = React.useState([]);

  const [popUpHighlight, setPopUpHighlight] = React.useState(false);


  // View Individual Sentence
    const [viewIndividualSentenceData, setViewIndividualSentenceData] = React.useState([]);

  // Raw and Analyzed Data
  const [rawData1, setRawData1] = React.useState([]);
  const [analyzedData1, setAnalyzedData1] = React.useState([]);

  const [rawData2, setRawData2] = React.useState([]);
  const [analyzedData2, setAnalyzedData2] = React.useState([]);

  const [rawData3, setRawData3] = React.useState([]);
  const [analyzedData3, setAnalyzedData3] = React.useState([]);


   // Category Checkbox 
   const [state, setState] = React.useState({
     afterservice: true,
     brand: true,
     coi: true,
     design: true,
     hardware: true,
     hardwareux: true,
     others: true,
     performance: true,
     picture: true,
     price: true,
     qa: true,
     sound: true,
     ux: true
   });

   const [stateMonitor, setStateMonitor] = React.useState({
     externaldeviceMonitor: true,
     pqMonitor: true,
     designMonitor: true,
     sqMonitor: true,
     scenarioMonitor: true,
     qaMonitor: true,
     coiMonitor: true,
     uxMonitor: true,
     performanceMonitor: true
   });

   const handleChange = (event) => {
     if(categorySelection1 == "TV"){
       setState({ ...state, [event.target.name]: event.target.checked });
     }else if(categorySelection1 == "Monitor"){
       setStateMonitor({ ...stateMonitor, [event.target.name]: event.target.checked });
      //  console.table(stateMonitor);
     }
     
   };


   const { afterservice, brand, coi, design, hardware, hardwareux, others, performance, picture, price, qa, sound, ux } = state;

   const { externaldeviceMonitor, pqMonitor, designMonitor, sqMonitor, scenarioMonitor, qaMonitor, coiMonitor, uxMonitor, performanceMonitor } = stateMonitor;


   // Category Radio Selection

   const [valueCategoryValue, setValueCategoryValue] = React.useState('abc');

   const handleChangeCategoryRadio = (event) => {
     setSortOption(event.target.value);
   };


  // Topic Overview Radio Selection - Analytic  = data => () => {
    // const handleChangeTopicAnalyticRadio = data => () => {
    //   setAnalyticOption(data);
    // };

  const handleChangeTopicAnalyticRadio = (event) => {
    setAnalyticOption(event.target.value);
  };

   const handleChangeTopicAnalyticRadioChart1Model1 = data => () => {
     setAnalyticOptionChart1Model1(data);
   };

   const handleChangeTopicAnalyticRadioChart2Model1 = data => () => {
     setAnalyticOptionChart2Model2(data);
   };


    const handleChangeTopicAnalyticRadioChart1Model2 = data => () => {
      setAnalyticOptionChart1Model2(data);
    };
  
    const handleChangeTopicAnalyticRadioChart2Model2 = data => () => {
      setAnalyticOptionChart2Model2(data);
    };
     
    const handleChangeTopicAnalyticRadioChart1Model3 = data => () => {
      setAnalyticOptionChart1Model3(data);
    };
  
    const handleChangeTopicAnalyticRadioChart2Model3 = data => () => {
      setAnalyticOptionChart2Model3(data);
    };



   // Topic Overview Radio Selection - Topic Option
   const handleChangeTopicRadio = (event) => {
     setTopicOption(event.target.value);
   };

   // Topic Overview Radio Selection - Sort Option
   const handleChangeTopicSortRadio = (event) => {
     setTopicSortOption(event.target.value);
   };

   // Topic Overview Radio Selection - Language Option
   const handleChangeTopicLanguageRadio = (event) => {
     setTopicLanguageOption(event.target.value);
   };


   const handleTopicDetailRadioRating = (event) => {
    setTopicDetailTrendRatingFilter(event.target.value);
  };


  const handleTopicDetailRadioDate = (event) => {
    setTopicDetailTrendFilter(event.target.value);
  };

  

  React.useEffect(() => {
  
  
    const axios = require('axios');
    axios.get(SMATrestAPI.generalFilterCN[0].getAllModels).then(function (response) { 
      // handle success

      setAllFilters(response.data.getFilter);

      let brandFilter = response.data.getFilter.map(data => data['Brand']);
      let uniqueBrand = [...new Set(brandFilter)];
      uniqueBrand.unshift("Sony");

      setBrandItems1(uniqueBrand);
      setBrandItems2(uniqueBrand);
      setBrandItems3(uniqueBrand);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });
  
  
    }, [])


    const getUniqueTrendData = async () => {

      if (modelSelection1.length != 0){
      setProgress(prevCount => prevCount + 5);

        // Unique Trend Data
        setStarRatingTrendUniqueIsLoading1(true);
        let restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&filterSelection="}${trendFilter1}`;
        restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
        restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
      
        try {
        const resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl1}`);
          setUniqueTrendData1(resp.data.uniqueTrendData);
          setUniqueTrendDataPercentCum1(resp.data.uniqueTrendDataPercentCum);
          setUniqueTrendStarRatingAvg1(resp.data.starRating);
          setUniqueTrendStarColor1(resp.data.color);
      
          setStarRatingTrendUniqueIsLoading1(false);
          // setProgress(prevCount => prevCount + 20);
      
        } catch (err) {
          // Handle Error Here
          console.error(err);
      
          setUniqueTrendData1([]);
          setUniqueTrendDataPercentCum1([]);
          setUniqueTrendStarRatingAvg1(0);
          setUniqueTrendStarColor1('info');
      
          setStarRatingTrendUniqueIsLoading1(false);
          // setProgress(prevCount => prevCount + 20);
      
        }



      }


     if (modelSelection2.length != 0){
      setProgress(prevCount => prevCount + 5);

        // Unique Trend Data
        setStarRatingTrendUniqueIsLoading2(true);
        let restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&filterSelection="}${trendFilter2}`;
        restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
        restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url
      
        try {
        const resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl2}`);
          setUniqueTrendData2(resp.data.uniqueTrendData);
          setUniqueTrendDataPercentCum2(resp.data.uniqueTrendDataPercentCum);
          setUniqueTrendStarRatingAvg2(resp.data.starRating);
          setUniqueTrendStarColor2(resp.data.color);
      
          setStarRatingTrendUniqueIsLoading2(false);
          // setProgress(prevCount => prevCount + 20);
      
        } catch (err) {
          // Handle Error Here
          console.error(err);
      
          setUniqueTrendData2([]);
          setUniqueTrendDataPercentCum2([]);
          setUniqueTrendStarRatingAvg2(0);
          setUniqueTrendStarColor2('info');
      
          setStarRatingTrendUniqueIsLoading2(false);
          // setProgress(prevCount => prevCount + 20);
      
        }


      }




      
      if (modelSelection3.length != 0){
        setProgress(prevCount => prevCount + 5);


        // Unique Trend Data
        setStarRatingTrendUniqueIsLoading3(true);
        let restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&filterSelection="}${trendFilter3}`;
        restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
        restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url
      
        try {
        const resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl3}`);
          setUniqueTrendData3(resp.data.uniqueTrendData);
          setUniqueTrendDataPercentCum3(resp.data.uniqueTrendDataPercentCum);
          setUniqueTrendStarRatingAvg3(resp.data.starRating);
          setUniqueTrendStarColor3(resp.data.color);
      
          setStarRatingTrendUniqueIsLoading3(false);
          // setProgress(prevCount => prevCount + 20);

      
        } catch (err) {
          // Handle Error Here
          console.error(err);
      
          setUniqueTrendData3([]);
          setUniqueTrendDataPercentCum3([]);
          setUniqueTrendStarRatingAvg3(0);
          setUniqueTrendStarColor3('info');
      
          setStarRatingTrendUniqueIsLoading3(false);
          // setProgress(prevCount => prevCount + 20);
      
        }



      }


    

      setProgressBarIsLoading(false);

    
    };



// Star Rating Trend Unique - Doughnut Chart - Model 1

// Star Rating Trend Unique - Info Box
let fiveStarData1 = uniqueTrendData1.map(data => data['5-Star']);
let fourStarData1 = uniqueTrendData1.map(data => data['4-Star']);
let threeStarData1 = uniqueTrendData1.map(data => data['3-Star']);
let twoStarData1 = uniqueTrendData1.map(data => data['2-Star']);
let oneStarData1 = uniqueTrendData1.map(data => data['1-Star']);

let fiveStarDataPercentCum1 = uniqueTrendDataPercentCum1.map(data => data['5-Star']);
let fourStarDataPercentCum1  = uniqueTrendDataPercentCum1.map(data => data['4-Star']);
let threeStarDataPercentCum1  = uniqueTrendDataPercentCum1.map(data => data['3-Star']);
let twoStarDataPercentCum1  = uniqueTrendDataPercentCum1.map(data => data['2-Star']);
let oneStarDataPercentCum1  = uniqueTrendDataPercentCum1.map(data => data['1-Star']);
let cumulativeTotal1  = uniqueTrendData1.map(data => data['Cumulative Total']);




// Implement text inside Doughnut

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);
    
    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 114).toFixed(2); 
    ctx.font = fontSize + "em Verdana";
    ctx.textBaseline = "middle";

    var text = chart.config.data.text,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 1.8;

    ctx.fillText(text, textX, textY);
  }
});

var uniquePieViewTrendDataSet1 = useMemo(() => {

  let data = {
    type: 'doughnut',
    labels: [
      '5-Star',
      '4-Star',
      '3-Star',
      '2-Star',
      '1-Star'
    ],
    datasets: [
      {
        data: [
          fiveStarData1.reduce(function(a, b) { return a + b; }, 0), 
          fourStarData1.reduce(function(a, b) { return a + b; }, 0), 
          threeStarData1.reduce(function(a, b) { return a + b; }, 0), 
          twoStarData1.reduce(function(a, b) { return a + b; }, 0), 
          oneStarData1.reduce(function(a, b) { return a + b; }, 0)],
        backgroundColor: [
          'rgb(26, 115, 55)',
          'rgb(36, 222, 96)',
          'rgb(245, 200, 15)',
          'rgb(236, 50, 50)',
          'rgb(163, 30, 30)'
          ],
        //options: options
      }],
      text: uniqueTrendStarRatingAvg1
    }


    return data
    
  }, [uniqueTrendData1, uniqueTrendStarRatingAvg1]); //uniqueTrendData1



  var uniquePieViewTrendOptions1 = useMemo(() => {


    let data = {

          // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);


      }

      },
      
      options: {
        responsive: true,
        maintainAspectRatio: false
      },

   

    }
     

  }, [uniqueTrendData1]); //uniqueTrendData1


// Star Rating Trend Unique Table using react-data-table-component 

var uniqueTrendPieTableDataSet1 = useMemo(() => {

  let data = 

  [{
    fiveStar: fiveStarData1.reduce(function(a, b) { return a + b; }, 0), 
    fourStar: fourStarData1.reduce(function(a, b) { return a + b; }, 0), 
    threeStar: threeStarData1.reduce(function(a, b) { return a + b; }, 0), 
    twoStar: twoStarData1.reduce(function(a, b) { return a + b; }, 0), 
    oneStar: oneStarData1.reduce(function(a, b) { return a + b; }, 0),
    rating: uniqueTrendStarRatingAvg1
  }]

  return data

}, [uniqueTrendData1]); //uniqueTrendData1;
  

var uniqueTrendPieTableColumn1 = useMemo(() => {

  let data = [
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Rating',
      selector: 'rating',
      sortable: true,
      center: true
    }
  ];


  return data


}, [uniqueTrendData1]);





// Star Rating Trend Unique - Doughnut Chart - Model 2

// Star Rating Trend Unique - Info Box
let fiveStarData2 = uniqueTrendData2.map(data => data['5-Star']);
let fourStarData2 = uniqueTrendData2.map(data => data['4-Star']);
let threeStarData2 = uniqueTrendData2.map(data => data['3-Star']);
let twoStarData2 = uniqueTrendData2.map(data => data['2-Star']);
let oneStarData2 = uniqueTrendData2.map(data => data['1-Star']);

let fiveStarDataPercentCum2 = uniqueTrendDataPercentCum2.map(data => data['5-Star']);
let fourStarDataPercentCum2  = uniqueTrendDataPercentCum2.map(data => data['4-Star']);
let threeStarDataPercentCum2  = uniqueTrendDataPercentCum2.map(data => data['3-Star']);
let twoStarDataPercentCum2  = uniqueTrendDataPercentCum2.map(data => data['2-Star']);
let oneStarDataPercentCum2  = uniqueTrendDataPercentCum2.map(data => data['1-Star']);
let cumulativeTotal2  = uniqueTrendData2.map(data => data['Cumulative Total']);

var uniquePieViewTrendDataSet2 = useMemo(() => {

  let data = {
    type: 'doughnut',
    labels: [
      '5-Star',
      '4-Star',
      '3-Star',
      '2-Star',
      '1-Star'
    ],
    datasets: [
      {
        data: [
          fiveStarData2.reduce(function(a, b) { return a + b; }, 0), 
          fourStarData2.reduce(function(a, b) { return a + b; }, 0), 
          threeStarData2.reduce(function(a, b) { return a + b; }, 0), 
          twoStarData2.reduce(function(a, b) { return a + b; }, 0), 
          oneStarData2.reduce(function(a, b) { return a + b; }, 0)],
        backgroundColor: [
          'rgb(26, 115, 55)',
          'rgb(36, 222, 96)',
          'rgb(245, 200, 15)',
          'rgb(236, 50, 50)',
          'rgb(163, 30, 30)'
          ],
        //options: options
      }],
      text: uniqueTrendStarRatingAvg2
  }

  return data


  }, [uniqueTrendData2, uniqueTrendStarRatingAvg2]);


  var uniquePieViewTrendOptions2 = useMemo(() => {

    let data = {
      // Drill Down
      onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);


        }

      },
      
      options: {
        responsive: true,
        maintainAspectRatio: false
      },
    }


     return data

  }, [uniqueTrendData2]);


// Star Rating Trend Unique Table using react-data-table-component 

var uniqueTrendPieTableDataSet2 = useMemo(() => {

  let data = [{
    fiveStar: fiveStarData2.reduce(function(a, b) { return a + b; }, 0), 
    fourStar: fourStarData2.reduce(function(a, b) { return a + b; }, 0), 
    threeStar: threeStarData2.reduce(function(a, b) { return a + b; }, 0), 
    twoStar: twoStarData2.reduce(function(a, b) { return a + b; }, 0), 
    oneStar: oneStarData2.reduce(function(a, b) { return a + b; }, 0),
    rating: uniqueTrendStarRatingAvg2
  }];

  return data


}, [uniqueTrendData2]);
  
var uniqueTrendPieTableColumn2 = useMemo(() => {

  let data = [
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Rating',
      selector: 'rating',
      sortable: true,
      center: true
    }
  ];


  return data

}, [uniqueTrendData2]);




// Star Rating Trend Unique - Doughnut Chart - Model 3

// Star Rating Trend Unique - Info Box
let fiveStarData3 = uniqueTrendData3.map(data => data['5-Star']);
let fourStarData3 = uniqueTrendData3.map(data => data['4-Star']);
let threeStarData3 = uniqueTrendData3.map(data => data['3-Star']);
let twoStarData3 = uniqueTrendData3.map(data => data['2-Star']);
let oneStarData3 = uniqueTrendData3.map(data => data['1-Star']);

let fiveStarDataPercentCum3 = uniqueTrendDataPercentCum3.map(data => data['5-Star']);
let fourStarDataPercentCum3  = uniqueTrendDataPercentCum3.map(data => data['4-Star']);
let threeStarDataPercentCum3  = uniqueTrendDataPercentCum3.map(data => data['3-Star']);
let twoStarDataPercentCum3  = uniqueTrendDataPercentCum3.map(data => data['2-Star']);
let oneStarDataPercentCum3  = uniqueTrendDataPercentCum3.map(data => data['1-Star']);
let cumulativeTotal3  = uniqueTrendData3.map(data => data['Cumulative Total']);

var uniquePieViewTrendDataSet3 = useMemo(() => {
  let data = {
    type: 'doughnut',
    labels: [
      '5-Star',
      '4-Star',
      '3-Star',
      '2-Star',
      '1-Star'
    ],
    datasets: [
      {
        data: [
          fiveStarData3.reduce(function(a, b) { return a + b; }, 0), 
          fourStarData3.reduce(function(a, b) { return a + b; }, 0), 
          threeStarData3.reduce(function(a, b) { return a + b; }, 0), 
          twoStarData3.reduce(function(a, b) { return a + b; }, 0), 
          oneStarData3.reduce(function(a, b) { return a + b; }, 0)],
        backgroundColor: [
          'rgb(26, 115, 55)',
          'rgb(36, 222, 96)',
          'rgb(245, 200, 15)',
          'rgb(236, 50, 50)',
          'rgb(163, 30, 30)'
          ],
        //options: options
      }],
    text: uniqueTrendStarRatingAvg3
    }

    return data
  }, [uniqueTrendData3, uniqueTrendStarRatingAvg3]); 

  var uniquePieViewTrendOptions3 = useMemo(() => {

    let data = {
      // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);


      }

    },
    
    options: {
      responsive: true,
      maintainAspectRatio: false
    },

    }

    return data


  }, [uniqueTrendData3]);


// Star Rating Trend Unique Table using react-data-table-component 

var uniqueTrendPieTableDataSet3 = useMemo(() => {

  let data = [{
    fiveStar: fiveStarData3.reduce(function(a, b) { return a + b; }, 0), 
    fourStar: fourStarData3.reduce(function(a, b) { return a + b; }, 0), 
    threeStar: threeStarData3.reduce(function(a, b) { return a + b; }, 0), 
    twoStar: twoStarData3.reduce(function(a, b) { return a + b; }, 0), 
    oneStar: oneStarData3.reduce(function(a, b) { return a + b; }, 0),
    rating: uniqueTrendStarRatingAvg3
  }];

  return data


}, [uniqueTrendData3]);


var uniqueTrendPieTableColumn3 = useMemo(() => {
  
  let data = [
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Rating',
      selector: 'rating',
      sortable: true,
      center: true
    }
  ];

  return data


}, [uniqueTrendData3]);




// Star Rating Trend Unique - Line Chart - Model 1

let uniqueTrendViewTrendLabel1 = uniqueTrendDataPercentCum1.map(data => data.Date);

var uniqueTrendViewTrendDataSet1 = useMemo(() => {

  let data = {
    type: 'line',
    labels: uniqueTrendViewTrendLabel1,
    datasets: [
      {
        label: '5-Star',
        data: fiveStarDataPercentCum1 ,
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '4-Star',
        data: fourStarDataPercentCum1,
        fill: false,
        backgroundColor: 'rgb(36, 222, 96)',
        borderColor: 'rgba(36, 222, 96, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '3-Star',
        data: threeStarDataPercentCum1,
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '2-Star',
        data: twoStarDataPercentCum1,
        fill: false,
        backgroundColor: 'rgb(236, 50, 50)',
        borderColor: 'rgba(236, 50, 50, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '1-Star',
        data: oneStarDataPercentCum1,
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: 'Cumulative Total',
        data: cumulativeTotal1,
        fill: false,
        backgroundColor: 'rgb(129, 130, 133)',
        borderColor: 'rgba(129, 130, 133, 0.6)',
        type: 'bar',
        yAxisID: 'B'
      }
  
  
    ]
  };

  return data

}, [uniqueTrendDataPercentCum1]);


var uniqueTrendViewTrendOptions1 = useMemo(() => {


  let data = {
      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },
  
  spanGaps: true,

  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      distribution: 'series',
      time: {
          unit: trendFilter1
      }
  }]
  },

  }

  return data

}, [uniqueTrendDataPercentCum1]);

// Star Rating Trend Unique Table using react-data-table-component


var uniqueTrendTrendTableDataSet1 = useMemo(() => {

  let data = uniqueTrendData1.map((UniqueTrendData) => {
    return ({
      id: UniqueTrendData.id,
      date: UniqueTrendData.Date,
      fiveStar: UniqueTrendData['5-Star'],
      fourStar: UniqueTrendData['4-Star'],
      threeStar: UniqueTrendData['3-Star'],
      twoStar: UniqueTrendData['2-Star'],
      oneStar: UniqueTrendData['1-Star'],
      total: UniqueTrendData['Total'],
      cumulative: UniqueTrendData['Cumulative Total']
    })
  });

  return data

}, [uniqueTrendDataPercentCum1]);

const uniqueTrendTrendTableColumn1 = useMemo(() => {

  let data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    },
    {
      name: 'Cumulative Total',
      selector: 'cumulative',
      sortable: true,
      center: true
    }
  ];


  return data


}, [uniqueTrendDataPercentCum1]);



const onClickUniqueTrendFilter1 = data => () => {

  if(typeof(dateEndSelection1) != "undefined"){

    setTrendFilter1(data);

   // Unique Trend Data
   setStarRatingTrendUniqueIsLoading1(true);
   var restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&filterSelection="}${data}`;
   restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
   restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
   axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl1}`).then(function (response) { 
     // handle success
  
     setUniqueTrendData1(response.data.uniqueTrendData);
     setUniqueTrendDataPercentCum1(response.data.uniqueTrendDataPercentCum);
     setUniqueTrendStarRatingAvg1(response.data.starRating);
     setUniqueTrendStarColor1(response.data.color);
  
     
   }).catch(function (error) { 
     // handle error
     console.log(error);
  
     setUniqueTrendData1([]);
     setUniqueTrendDataPercentCum1([]);
     setUniqueTrendStarRatingAvg1(0);
     setUniqueTrendStarColor1('info');
  
   }).then(function () { 
     // always executed
     setStarRatingTrendUniqueIsLoading1(false);
   });

  }

};


// Star Rating Trend Unique - Line Chart - Model 2

let uniqueTrendViewTrendLabel2 = uniqueTrendDataPercentCum2.map(data => data.Date);



var uniqueTrendViewTrendDataSet2 = useMemo(() => {

  let data = {
    type: 'line',
    labels: uniqueTrendViewTrendLabel2,
    datasets: [
      {
        label: '5-Star',
        data: fiveStarDataPercentCum2 ,
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '4-Star',
        data: fourStarDataPercentCum2,
        fill: false,
        backgroundColor: 'rgb(36, 222, 96)',
        borderColor: 'rgba(36, 222, 96, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '3-Star',
        data: threeStarDataPercentCum2,
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '2-Star',
        data: twoStarDataPercentCum2,
        fill: false,
        backgroundColor: 'rgb(236, 50, 50)',
        borderColor: 'rgba(236, 50, 50, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '1-Star',
        data: oneStarDataPercentCum2,
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: 'Cumulative Total',
        data: cumulativeTotal2,
        fill: false,
        backgroundColor: 'rgb(129, 130, 133)',
        borderColor: 'rgba(129, 130, 133, 0.6)',
        type: 'bar',
        yAxisID: 'B'
      }
  
  
    ]
  }

  return data

}, [uniqueTrendDataPercentCum2]);


var uniqueTrendViewTrendOptions2 = useMemo(() => {


  let data = {

      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },
  
  spanGaps: true,

  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      distribution: 'series',
      time: {
          unit: trendFilter2
      }
  }]
  },

  }

  return data

}, [uniqueTrendDataPercentCum2]);

// Star Rating Trend Unique Table using react-data-table-component


var uniqueTrendTrendTableDataSet2 = useMemo(() => {

  let data = 
uniqueTrendData2.map((UniqueTrendData) => {
    return ({
      id: UniqueTrendData.id,
      date: UniqueTrendData.Date,
      fiveStar: UniqueTrendData['5-Star'],
      fourStar: UniqueTrendData['4-Star'],
      threeStar: UniqueTrendData['3-Star'],
      twoStar: UniqueTrendData['2-Star'],
      oneStar: UniqueTrendData['1-Star'],
      total: UniqueTrendData['Total'],
      cumulative: UniqueTrendData['Cumulative Total']
    })
  });

  return data


}, [uniqueTrendDataPercentCum2]);

var uniqueTrendTrendTableColumn2 = useMemo(() => {

  let data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    },
    {
      name: 'Cumulative Total',
      selector: 'cumulative',
      sortable: true,
      center: true
    }
  ];

  return data

}, [uniqueTrendDataPercentCum2]);



const onClickUniqueTrendFilter2 = data => () => {

  if(typeof(dateEndSelection2) != "undefined"){

    setTrendFilter2(data);

   // Unique Trend Data
   setStarRatingTrendUniqueIsLoading2(true);
   var restGETurl1 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&filterSelection="}${data}`;
   restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
   restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
   axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl1}`).then(function (response) { 
     // handle success
  
     setUniqueTrendData2(response.data.uniqueTrendData);
     setUniqueTrendDataPercentCum2(response.data.uniqueTrendDataPercentCum);
     setUniqueTrendStarRatingAvg2(response.data.starRating);
     setUniqueTrendStarColor2(response.data.color);
  
     
   }).catch(function (error) { 
     // handle error
     console.log(error);
  
     setUniqueTrendData2([]);
     setUniqueTrendDataPercentCum2([]);
     setUniqueTrendStarRatingAvg2(0);
     setUniqueTrendStarColor1('info');
  
   }).then(function () { 
     // always executed
     setStarRatingTrendUniqueIsLoading2(false);
   });

  }

};



// Star Rating Trend Unique - Line Chart - Model 3

let uniqueTrendViewTrendLabel3 = uniqueTrendDataPercentCum3.map(data => data.Date);


var uniqueTrendViewTrendDataSet3 = useMemo(() => {

  let data = {

    type: 'line',
    labels: uniqueTrendViewTrendLabel3,
    datasets: [
      {
        label: '5-Star',
        data: fiveStarDataPercentCum3 ,
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '4-Star',
        data: fourStarDataPercentCum3,
        fill: false,
        backgroundColor: 'rgb(36, 222, 96)',
        borderColor: 'rgba(36, 222, 96, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '3-Star',
        data: threeStarDataPercentCum3,
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '2-Star',
        data: twoStarDataPercentCum3,
        fill: false,
        backgroundColor: 'rgb(236, 50, 50)',
        borderColor: 'rgba(236, 50, 50, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: '1-Star',
        data: oneStarDataPercentCum3,
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        yAxisID: 'A'
      },
  
      {
        label: 'Cumulative Total',
        data: cumulativeTotal3,
        fill: false,
        backgroundColor: 'rgb(129, 130, 133)',
        borderColor: 'rgba(129, 130, 133, 0.6)',
        type: 'bar',
        yAxisID: 'B'
      }
  
  
    ]

  }


  return data

}, [uniqueTrendDataPercentCum3]);


var uniqueTrendViewTrendOptions3 = useMemo(() => {

  let data = {

      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },
  
  spanGaps: true,

  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      distribution: 'series',
      time: {
          unit: trendFilter3
      }
  }]
  },
  }

  return data


}, [uniqueTrendDataPercentCum3]);

// Star Rating Trend Unique Table using react-data-table-component


var uniqueTrendTrendTableDataSet3 = useMemo(() => {


  let data = uniqueTrendData3.map((UniqueTrendData) => {
    return ({
      id: UniqueTrendData.id,
      date: UniqueTrendData.Date,
      fiveStar: UniqueTrendData['5-Star'],
      fourStar: UniqueTrendData['4-Star'],
      threeStar: UniqueTrendData['3-Star'],
      twoStar: UniqueTrendData['2-Star'],
      oneStar: UniqueTrendData['1-Star'],
      total: UniqueTrendData['Total'],
      cumulative: UniqueTrendData['Cumulative Total']
    })
  });

  return data


}, [uniqueTrendDataPercentCum3]);



const uniqueTrendTrendTableColumn3 = useMemo(() => {

  let data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    },
    {
      name: 'Cumulative Total',
      selector: 'cumulative',
      sortable: true,
      center: true
    }
  ];

  return data


}, [uniqueTrendDataPercentCum3]);




const onClickUniqueTrendFilter3 = data => () => {

  if(typeof(dateEndSelection3) != "undefined"){

    setTrendFilter3(data);

   // Unique Trend Data
   setStarRatingTrendUniqueIsLoading3(true);
   var restGETurl1 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&filterSelection="}${data}`;
   restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
   restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
   axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].uniqueTrend}${restGETurl1}`).then(function (response) { 
     // handle success
  
     setUniqueTrendData3(response.data.uniqueTrendData);
     setUniqueTrendDataPercentCum3(response.data.uniqueTrendDataPercentCum);
     setUniqueTrendStarRatingAvg3(response.data.starRating);
     setUniqueTrendStarColor3(response.data.color);
  
     
   }).catch(function (error) { 
     // handle error
     console.log(error);
  
     setUniqueTrendData3([]);
     setUniqueTrendDataPercentCum3([]);
     setUniqueTrendStarRatingAvg3(0);
     setUniqueTrendStarColor1('info');
  
   }).then(function () { 
     // always executed
     setStarRatingTrendUniqueIsLoading3(false);
   });

  }

};



const getCategorySummaryData = async () => {




  if (modelSelection1.length != 0){
    setProgress(prevCount => prevCount + 5);

      // Category Summary Data - Model 1
  setCategorySummaryIsLoading(true);
  let restGETurl1;

  if(categorySelection1 == "TV"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&sortOption="}${"highLow"}`;

  }else if(categorySelection1 == "Monitor"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&sortOption="}${"highLow"}`;

  }

  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {
    if(categorySelection1 == "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl1}`);
    }else if(categorySelection1 == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl1}`);
    }

    let ratingData = resp.data.categorySummary.map(data => data['Rating']);
    setCategoryRating1(ratingData);

    let colorData = resp.data.categorySummary.map(data => data['Color']);
    setCategoryColor1(colorData);

    // Labels and Info Box
    setCategorySummaryOriginalData1(resp.data.categorySummary);

    // Use categorySummaryCustom (Has Filters)

    setCategorySummaryData1(resp.data.categorySummaryCustom);

    let labelData = resp.data.categorySummaryCustom.map(data => data['Category']);
    setCategoryLabel1(labelData);


    let fiveStarCountData = resp.data.categorySummaryCustom.map(data => data['5-Star Count']);
    setCategoryFiveStarCount1(fiveStarCountData);

    let fourStarCountData = resp.data.categorySummaryCustom.map(data => data['4-Star Count']);
    setCategoryFourStarCount1(fourStarCountData);

    let threeStarCountData = resp.data.categorySummaryCustom.map(data => data['3-Star Count']);
    setCategoryThreeStarCount1(threeStarCountData);

    let twoStarCountData = resp.data.categorySummaryCustom.map(data => data['2-Star Count']);
    setCategoryTwoStarCount1(twoStarCountData);

    let oneStarCountData = resp.data.categorySummaryCustom.map(data => data['1-Star Count']);
    setCategoryOneStarCount1(oneStarCountData);

    let fiveStarPercentData = resp.data.categorySummaryCustom.map(data => data['5-Star Percent']);
    setCategoryFiveStarPercent1(fiveStarPercentData);

    let fourStarPercentData = resp.data.categorySummaryCustom.map(data => data['4-Star Percent']);
    setCategoryFourStarPercent1(fourStarPercentData);

    let threeStarPercentData = resp.data.categorySummaryCustom.map(data => data['3-Star Percent']);
    setCategoryThreeStarPercent1(threeStarPercentData);

    let twoStarPercentData = resp.data.categorySummaryCustom.map(data => data['2-Star Percent']);
    setCategoryTwoStarPercent1(twoStarPercentData);

    let oneStarPercentData = resp.data.categorySummaryCustom.map(data => data['1-Star Percent']);
    setCategoryOneStarPercent1(oneStarPercentData);

    let positiveCountData = resp.data.categorySummaryCustom.map(data => data['Positive Count']);
    setCategoryPositiveCount1(positiveCountData);

    let neutralCountData = resp.data.categorySummaryCustom.map(data => data['Neutral Count']);
    setCategoryNeutralCount1(neutralCountData);

    let negativeCountData = resp.data.categorySummaryCustom.map(data => data['Negative Count']);
    setCategoryNegativeCount1(negativeCountData);

    let positivePercentData = resp.data.categorySummaryCustom.map(data => data['Positive Percent']);
    setCategoryPositivePercent1(positivePercentData);

    let negativePercentData = resp.data.categorySummaryCustom.map(data => data['Negative Percent']);
    setCategoryNegativePercent1(negativePercentData);

    let neutralPercentData = resp.data.categorySummaryCustom.map(data => data['Neutral Percent']);
    setCategoryNeutralPercent1(neutralPercentData);

    setCategorySummaryIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setCategorySummaryIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  }



  }




 if (modelSelection2.length != 0){
  setProgress(prevCount => prevCount + 5);

      // Category Summary Data - Model 2
      setCategorySummaryIsLoading(true);
      let restGETurl2;
    
      if(categorySelection2 == "TV"){
    
        restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&sortOption="}${"highLow"}`;
    
      }else if(categorySelection2 == "Monitor"){
    
        restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&sortOption="}${"highLow"}`;
    
      }
    
      restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
      restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url
    
      let resp2;
      try {
        if(categorySelection2 == "TV"){
          resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl2}`);
        }else if(categorySelection2 == "Monitor"){
          resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl2}`);
        }
    
        let ratingData = resp2.data.categorySummary.map(data => data['Rating']);
        setCategoryRating2(ratingData);
    
        let colorData = resp2.data.categorySummary.map(data => data['Color']);
        setCategoryColor2(colorData);
    
        // Labels and Info Box
        setCategorySummaryOriginalData2(resp2.data.categorySummary);
    
        // Use categorySummaryCustom (Has Filters)
    
        setCategorySummaryData2(resp2.data.categorySummaryCustom);
    
        let labelData = resp2.data.categorySummaryCustom.map(data => data['Category']);
        setCategoryLabel2(labelData);
    
    
        let fiveStarCountData = resp2.data.categorySummaryCustom.map(data => data['5-Star Count']);
        setCategoryFiveStarCount2(fiveStarCountData);
    
        let fourStarCountData = resp2.data.categorySummaryCustom.map(data => data['4-Star Count']);
        setCategoryFourStarCount2(fourStarCountData);
    
        let threeStarCountData = resp2.data.categorySummaryCustom.map(data => data['3-Star Count']);
        setCategoryThreeStarCount2(threeStarCountData);
    
        let twoStarCountData = resp2.data.categorySummaryCustom.map(data => data['2-Star Count']);
        setCategoryTwoStarCount2(twoStarCountData);
    
        let oneStarCountData = resp2.data.categorySummaryCustom.map(data => data['1-Star Count']);
        setCategoryOneStarCount2(oneStarCountData);
    
        let fiveStarPercentData = resp2.data.categorySummaryCustom.map(data => data['5-Star Percent']);
        setCategoryFiveStarPercent2(fiveStarPercentData);
    
        let fourStarPercentData = resp2.data.categorySummaryCustom.map(data => data['4-Star Percent']);
        setCategoryFourStarPercent2(fourStarPercentData);
    
        let threeStarPercentData = resp2.data.categorySummaryCustom.map(data => data['3-Star Percent']);
        setCategoryThreeStarPercent2(threeStarPercentData);
    
        let twoStarPercentData = resp2.data.categorySummaryCustom.map(data => data['2-Star Percent']);
        setCategoryTwoStarPercent2(twoStarPercentData);
    
        let oneStarPercentData = resp2.data.categorySummaryCustom.map(data => data['1-Star Percent']);
        setCategoryOneStarPercent2(oneStarPercentData);
    
        let positiveCountData = resp2.data.categorySummaryCustom.map(data => data['Positive Count']);
        setCategoryPositiveCount2(positiveCountData);
    
        let neutralCountData = resp2.data.categorySummaryCustom.map(data => data['Neutral Count']);
        setCategoryNeutralCount2(neutralCountData);
    
        let negativeCountData = resp2.data.categorySummaryCustom.map(data => data['Negative Count']);
        setCategoryNegativeCount2(negativeCountData);
    
        let positivePercentData = resp2.data.categorySummaryCustom.map(data => data['Positive Percent']);
        setCategoryPositivePercent2(positivePercentData);
    
        let negativePercentData = resp2.data.categorySummaryCustom.map(data => data['Negative Percent']);
        setCategoryNegativePercent2(negativePercentData);
    
        let neutralPercentData = resp2.data.categorySummaryCustom.map(data => data['Neutral Percent']);
        setCategoryNeutralPercent2(neutralPercentData);
    
        setCategorySummaryIsLoading(false);
        // setProgress(prevCount => prevCount + 20);
    
      } catch (err) {
        // Handle Error Here
        console.error(err);
    
        setCategorySummaryIsLoading(false);
        // setProgress(prevCount => prevCount + 20);
    
      }
  


  }



  if (modelSelection3.length != 0){

    setProgress(prevCount => prevCount + 5);


    
// Category Summary Data - Model 3
setCategorySummaryIsLoading(true);
let restGETurl3;

if(categorySelection3 == "TV"){

  restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&sortOption="}${"highLow"}`;

}else if(categorySelection3 == "Monitor"){

  restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&sortOption="}${"highLow"}`;

}

restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

let resp3;
try {
  if(categorySelection3 == "TV"){
    resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl3}`);
  }else if(categorySelection3 == "Monitor"){
    resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl3}`);
  }

  let ratingData = resp3.data.categorySummary.map(data => data['Rating']);
  setCategoryRating3(ratingData);

  let colorData = resp3.data.categorySummary.map(data => data['Color']);
  setCategoryColor3(colorData);

  // Labels and Info Box
  setCategorySummaryOriginalData3(resp3.data.categorySummary);

  // Use categorySummaryCustom (Has Filters)

  setCategorySummaryData3(resp3.data.categorySummaryCustom);

  let labelData = resp3.data.categorySummaryCustom.map(data => data['Category']);
  setCategoryLabel3(labelData);


  let fiveStarCountData = resp3.data.categorySummaryCustom.map(data => data['5-Star Count']);
  setCategoryFiveStarCount3(fiveStarCountData);

  let fourStarCountData = resp3.data.categorySummaryCustom.map(data => data['4-Star Count']);
  setCategoryFourStarCount3(fourStarCountData);

  let threeStarCountData = resp3.data.categorySummaryCustom.map(data => data['3-Star Count']);
  setCategoryThreeStarCount3(threeStarCountData);

  let twoStarCountData = resp3.data.categorySummaryCustom.map(data => data['2-Star Count']);
  setCategoryTwoStarCount3(twoStarCountData);

  let oneStarCountData = resp3.data.categorySummaryCustom.map(data => data['1-Star Count']);
  setCategoryOneStarCount3(oneStarCountData);

  let fiveStarPercentData = resp3.data.categorySummaryCustom.map(data => data['5-Star Percent']);
  setCategoryFiveStarPercent3(fiveStarPercentData);

  let fourStarPercentData = resp3.data.categorySummaryCustom.map(data => data['4-Star Percent']);
  setCategoryFourStarPercent3(fourStarPercentData);

  let threeStarPercentData = resp3.data.categorySummaryCustom.map(data => data['3-Star Percent']);
  setCategoryThreeStarPercent3(threeStarPercentData);

  let twoStarPercentData = resp3.data.categorySummaryCustom.map(data => data['2-Star Percent']);
  setCategoryTwoStarPercent3(twoStarPercentData);

  let oneStarPercentData = resp3.data.categorySummaryCustom.map(data => data['1-Star Percent']);
  setCategoryOneStarPercent3(oneStarPercentData);

  let positiveCountData = resp3.data.categorySummaryCustom.map(data => data['Positive Count']);
  setCategoryPositiveCount3(positiveCountData);

  let neutralCountData = resp3.data.categorySummaryCustom.map(data => data['Neutral Count']);
  setCategoryNeutralCount3(neutralCountData);

  let negativeCountData = resp3.data.categorySummaryCustom.map(data => data['Negative Count']);
  setCategoryNegativeCount3(negativeCountData);

  let positivePercentData = resp3.data.categorySummaryCustom.map(data => data['Positive Percent']);
  setCategoryPositivePercent3(positivePercentData);

  let negativePercentData = resp3.data.categorySummaryCustom.map(data => data['Negative Percent']);
  setCategoryNegativePercent3(negativePercentData);

  let neutralPercentData = resp3.data.categorySummaryCustom.map(data => data['Neutral Percent']);
  setCategoryNeutralPercent3(neutralPercentData);

  setCategorySummaryIsLoading(false);
  // setProgress(prevCount => prevCount + 20);

} catch (err) {
  // Handle Error Here
  console.error(err);

  setCategorySummaryIsLoading(false);
  // setProgress(prevCount => prevCount + 20);

}



  }




};





  
    const handleBrand1= event => {
      setBrandSelection1(event.target.value);


      let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

      let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
      uniqueCategory = [...new Set(uniqueCategory)];
      uniqueCategory = uniqueCategory.sort();

      setCategoryItems1(uniqueCategory);

      setCategorySelection1('');
      setModelSelection1([]);
      setDateStartSelection1('');
      setDateEndSelection1('');


    };
  
    const handleCategory1 = event => {
      setCategorySelection1(event.target.value);



      let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection1);
      modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

      let uniqueModel = modelFilter.map(data => data['Model']);
      uniqueModel = [...new Set(uniqueModel)];
      uniqueModel = uniqueModel.sort();

      setModelItems1(uniqueModel);


      setModelSelection1([]);
      setDateStartSelection1('');
      setDateEndSelection1('');
    };
  


    ////////////////////////////

    const handleModel1 = (event, values) => {
      setModelSelection1(values);
      setDateStartSelection1('');
      setDateEndSelection1('');


      let restGETurl = `${"brand="}${brandSelection1}${"&model="}${modelSelection1}${"&country="}${"China"}`;
      restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
      restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
    
      const axios = require('axios');
    
      axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
        // handle success
    
      setDateStartItem1(response.data.startDate);
      setDateEndItem1(response.data.endDate);
    
      setDateStartSelection1(response.data.startDate);
      setDateEndSelection1(response.data.endDate);

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });
    };


    // Get Filter Data from SMAT REST API - Date
    React.useEffect(() => {


      if(modelSelection1 !== ''){

        var restGETurl = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}`;
        restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
        restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

        const axios = require('axios');
      
        axios.get(`${SMATrestAPI.generalFilterCN[0].getDate}${restGETurl}`).then(function (response) { 
          // handle success

          setDateStartItem1(response.data.startDate);
          setDateEndItem1(response.data.endDate);

          setDateStartSelection1(response.data.startDate);
          setDateEndSelection1(response.data.endDate);

          
        }).catch(function (error) { 
          // handle error
          console.log(error);
        }).then(function () { 
          // always executed
        });

      }
    }, [modelSelection1])

    
    const handleChangeStartDate1 = event => {

      // Prevent function error if user manually inputs
      if (typeof event === 'object') {
        let aDate = event.format();
        aDate = aDate.substring(0, 10);
  
        setDateStartSelection1(aDate);
      }


    }

    const handleChangeEndDate1 = event => {

      // Prevent function error if user manually inputs
      if (typeof event === 'object') {
        let aDate = event.format();
        aDate = aDate.substring(0, 10);
        
        setDateEndSelection1(aDate);
      }
      

      

    }


  let yesterday1 = moment(dateStartItem1, 'YYYY-MM-DD').subtract(1, "day");
  function validStart1(current) {
    return current.isAfter(yesterday1);
  }

    
  let tommorrow1 = moment(dateEndItem1, 'YYYY-MM-DD').add(1, "day");
  function validEnd1(current) {
    return current.isBefore(tommorrow1);
  }  




  
const handleBrand2= event => {
  setBrandSelection2(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems2(uniqueCategory);

  setCategorySelection2('');
  setModelSelection2([]); 
  setDateStartSelection2('');
  setDateEndSelection2('');


};

const handleCategory2 = event => {
  setCategorySelection2(event.target.value);

  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection2);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems2(uniqueModel);

  setModelSelection2([]);
  setDateStartSelection2('');
  setDateEndSelection2('');
};

const handleModel2 = (event, values) => {
  setModelSelection2(values);
  setDateStartSelection2('');
  setDateEndSelection2('');


  let restGETurl = `${"brand="}${brandSelection2}${"&model="}${modelSelection2}${"&country="}${"China"}`;
      restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
      restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
    
      const axios = require('axios');
    
      axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
        // handle success
    
      setDateStartItem2(response.data.startDate);
      setDateEndItem2(response.data.endDate);
    
      setDateStartSelection2(response.data.startDate);
      setDateEndSelection2(response.data.endDate);

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });

};

// Get Filter Data from SMAT REST API - Date
React.useEffect(() => {


  if(modelSelection2 !== ''){

    var restGETurl = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.generalFilterCN[0].getDate}${restGETurl}`).then(function (response) { 
      // handle success

      setDateStartItem2(response.data.startDate);
      setDateEndItem2(response.data.endDate);

      setDateStartSelection2(response.data.startDate);
      setDateEndSelection2(response.data.endDate);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }
}, [modelSelection2])



const handleChangeStartDate2 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  
    setDateStartSelection2(aDate);
  }
  


}

const handleChangeEndDate2 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
    
    //setDateEndItem2(aDate);
    setDateEndSelection2(aDate);
  }

}


let yesterday2 = moment(dateStartItem2, 'YYYY-MM-DD').subtract(1, "day");
function validStart2(current) {
return current.isAfter(yesterday2);
}


let tommorrow2 = moment(dateEndItem2, 'YYYY-MM-DD').add(1, "day");
function validEnd2(current) {
return current.isBefore(tommorrow2);
}  





const handleBrand3= event => {
  setBrandSelection3(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems3(uniqueCategory);

  setCategorySelection3('');
  setModelSelection3([]);
  setDateStartSelection3('');
  setDateEndSelection3('');


};

const handleCategory3 = event => {
  setCategorySelection3(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection3);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems3(uniqueModel);


  setModelSelection3([]);
  setDateStartSelection3('');
  setDateEndSelection3('');
};

const handleModel3 = (event, values) => {
  setModelSelection3(values);
  setDateStartSelection3('');
  setDateEndSelection3('');

  let restGETurl = `${"brand="}${brandSelection3}${"&model="}${modelSelection3}${"&country="}${"China"}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
    // handle success

  setDateStartItem3(response.data.startDate);
  setDateEndItem3(response.data.endDate);

  setDateStartSelection3(response.data.startDate);
  setDateEndSelection3(response.data.endDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });



};



// Get Filter Data from SMAT REST API - Date
React.useEffect(() => {


  if(modelSelection3 !== ''){

    var restGETurl = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.generalFilterCN[0].getDate}${restGETurl}`).then(function (response) { 
      // handle success

      setDateStartItem3(response.data.startDate);
      setDateEndItem3(response.data.endDate);

      setDateStartSelection3(response.data.startDate);
      setDateEndSelection3(response.data.endDate);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }
}, [modelSelection3])


const handleChangeStartDate3 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  
  
    setDateStartSelection3(aDate);
  }

}

const handleChangeEndDate3 = event => {
  

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  

    setDateEndSelection3(aDate);
  }

}


let yesterday3 = moment(dateStartItem3, 'YYYY-MM-DD').subtract(1, "day");
function validStart3(current) {
return current.isAfter(yesterday3);
}


let tommorrow3 = moment(dateEndItem3, 'YYYY-MM-DD').add(1, "day");
function validEnd3(current) {
return current.isBefore(tommorrow3);
}  





// const handleUniqueTopic = event => {
//   setUniqueTopicSelection(event.target.value);
// };




  const onClickChartData = event => {
    setProgressBarIsLoading(true);

    setProgress(0);
    setProgress(prevCount => prevCount + 10);

    const axios = require('axios');

    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic - CN"}${"&brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}`; // countrySelection1
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic - CN"}${"&brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}`; // countrySelection2
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic - CN"}${"&brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}`; // countrySelection3
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



    getUniqueTrendData();
    getCategorySummaryData(); // Update RESTAPI
    getTopicSummaryData(); // Update RESTAPI
    getTopicDetailData(); // Update RESTAPI
    getIndividualSentenceData(); // No need to change
    getSentimentMappingData(); // No need to change
    // setProgress(10);



    setModelSelectionCustom1([])
    if (modelSelection1.length != 0){
      setModelSelectionCustom1(modelSelection1.join("_"));
    }

    setModelSelectionCustom2([])
    if (modelSelection1.length != 0){
      setModelSelectionCustom2(modelSelection2.join("_"));
    }

    setModelSelectionCustom3([])
    if (modelSelection1.length != 0){
      setModelSelectionCustom3(modelSelection3.join("_"));
    }


    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
  
    today = yyyy + mm + dd;
    setCurrentDate1(today);
    setCurrentDate2(today);
    setCurrentDate3(today);


  }


// onClick Refresh 1 Async Function - End




const getTopicDetailDataRefresh1 = async () => {



  if (modelSelection1.length != 0){

    // Topic Detail - Model 1
    setTopicOverviewIsLoading(true);

    let restGETurl1;

    if(categorySelection1 === "TV"){
  
      restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }else if(categorySelection1 == "Monitor"){
  
      restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }
  
  
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
    let resp;
    try {
  
      if(categorySelection1 === "TV"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl1}`);
      }else if(categorySelection1 == "Monitor"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl1}`);
      }
        setCategoryData1(resp.data.categoryList);
        setTopicData1(resp.data.topicList);
        setTopicDetailData1(resp.data.topicDetailList);
        setTopicTrendData1(resp.data.trendData);
        setCustomIndividualSentence1(resp.data.individualSentenceCN);
  
        setTopicOverviewIsLoading(false);
        //setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setTopicOverviewIsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    }


  }



  if (modelSelection2.length != 0){

    // Topic Detail - Model 2
    setTopicOverviewIsLoading(true);

    let restGETurl2;

    if(categorySelection2 === "TV"){
  
      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }else if(categorySelection2 == "Monitor"){
  
      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }
  
  
    restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
    restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url
  
    let resp;
    try {
  
      if(categorySelection2 === "TV"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl2}`);
      }else if(categorySelection2 == "Monitor"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl2}`);
      }
        setCategoryData2(resp.data.categoryList);
        setTopicData2(resp.data.topicList);
        setTopicDetailData2(resp.data.topicDetailList);
        setTopicTrendData2(resp.data.trendData);
        setCustomIndividualSentence2(resp.data.individualSentenceCN);
  
        setTopicOverviewIsLoading(false);
        //setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setTopicOverviewIsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    }


  }



  if (modelSelection3.length != 0){

    // Topic Detail - Model 3
    setTopicOverviewIsLoading(true);

    let restGETurl3;

    if(categorySelection3 === "TV"){
  
      restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }else if(categorySelection3 == "Monitor"){
  
      restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }
  
  
    restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
    restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url
  
    let resp;
    try {
  
      if(categorySelection3 == "TV"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl3}`);
      }else if(categorySelection3 == "Monitor"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl3}`);
      }
        setCategoryData3(resp.data.categoryList);
        setTopicData3(resp.data.topicList);
        setTopicDetailData3(resp.data.topicDetailList);
        setTopicTrendData3(resp.data.trendData);
        setCustomIndividualSentence3(resp.data.individualSentenceCN);
  
        setTopicOverviewIsLoading(false);
        //setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setTopicOverviewIsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    }


  }




};

const onClickChartDataRefresh = () => {

  getCategorySummaryDataRefresh1();
  getTopicSummaryDataRefresh1();
  getTopicDetailDataRefresh1(); 

} 


// OnClick Refresh 1 Async Functions - Start

const getCategorySummaryDataRefresh1 = async () => {


  if (modelSelection1.length != 0){

    
    // Category Summary Data - Model 1
    setCategorySummaryIsLoading(true);
    let restGETurl1;
  
    if(categorySelection1 == "TV"){
  
      restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&sortOption="}${sortOption}`;
  
    }else if(categorySelection1 == "Monitor"){
  
      restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&sortOption="}${sortOption}`;
  
    }
  
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

    console.table(restGETurl1);
  
    let resp;
    try {
      if(categorySelection1 == "TV"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl1}`);
      }else if(categorySelection1 == "Monitor"){
        resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl1}`);
      }
  
      let ratingData = resp.data.categorySummary.map(data => data['Rating']);
      setCategoryRating1(ratingData);
  
      let colorData = resp.data.categorySummary.map(data => data['Color']);
      setCategoryColor1(colorData);
  
      // Labels and Info Box
      setCategorySummaryOriginalData1(resp.data.categorySummary);
  
      // Use categorySummaryCustom (Has Filters)
  
      setCategorySummaryData1(resp.data.categorySummaryCustom);

      console.table(resp.data.categorySummaryCustom);
      console.table(resp.data.categorySummary);
  
      let labelData = resp.data.categorySummaryCustom.map(data => data['Category']);
      setCategoryLabel1(labelData);
  
  
      let fiveStarCountData = resp.data.categorySummaryCustom.map(data => data['5-Star Count']);
      setCategoryFiveStarCount1(fiveStarCountData);
  
      let fourStarCountData = resp.data.categorySummaryCustom.map(data => data['4-Star Count']);
      setCategoryFourStarCount1(fourStarCountData);
  
      let threeStarCountData = resp.data.categorySummaryCustom.map(data => data['3-Star Count']);
      setCategoryThreeStarCount1(threeStarCountData);
  
      let twoStarCountData = resp.data.categorySummaryCustom.map(data => data['2-Star Count']);
      setCategoryTwoStarCount1(twoStarCountData);
  
      let oneStarCountData = resp.data.categorySummaryCustom.map(data => data['1-Star Count']);
      setCategoryOneStarCount1(oneStarCountData);
  
      let fiveStarPercentData = resp.data.categorySummaryCustom.map(data => data['5-Star Percent']);
      setCategoryFiveStarPercent1(fiveStarPercentData);
  
      let fourStarPercentData = resp.data.categorySummaryCustom.map(data => data['4-Star Percent']);
      setCategoryFourStarPercent1(fourStarPercentData);
  
      let threeStarPercentData = resp.data.categorySummaryCustom.map(data => data['3-Star Percent']);
      setCategoryThreeStarPercent1(threeStarPercentData);
  
      let twoStarPercentData = resp.data.categorySummaryCustom.map(data => data['2-Star Percent']);
      setCategoryTwoStarPercent1(twoStarPercentData);
  
      let oneStarPercentData = resp.data.categorySummaryCustom.map(data => data['1-Star Percent']);
      setCategoryOneStarPercent1(oneStarPercentData);
  
      let positiveCountData = resp.data.categorySummaryCustom.map(data => data['Positive Count']);
      setCategoryPositiveCount1(positiveCountData);
  
      let neutralCountData = resp.data.categorySummaryCustom.map(data => data['Neutral Count']);
      setCategoryNeutralCount1(neutralCountData);
  
      let negativeCountData = resp.data.categorySummaryCustom.map(data => data['Negative Count']);
      setCategoryNegativeCount1(negativeCountData);
  
      let positivePercentData = resp.data.categorySummaryCustom.map(data => data['Positive Percent']);
      setCategoryPositivePercent1(positivePercentData);
  
      let negativePercentData = resp.data.categorySummaryCustom.map(data => data['Negative Percent']);
      setCategoryNegativePercent1(negativePercentData);
  
      let neutralPercentData = resp.data.categorySummaryCustom.map(data => data['Neutral Percent']);
      setCategoryNeutralPercent1(neutralPercentData);
  
      setCategorySummaryIsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setCategorySummaryIsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    }


  }
  


  if (modelSelection2.length != 0){


      
    // Category Summary Data - Model 2
    setCategorySummaryIsLoading(true);
    let restGETurl2;
  
    if(categorySelection2 == "TV"){
  
      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&sortOption="}${sortOption}`;
  
    }else if(categorySelection2 == "Monitor"){
  
      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&sortOption="}${sortOption}`;
  
    }
  
    restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
    restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url
  
    let resp2;
    try {
      if(categorySelection2 == "TV"){
        resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl2}`);
      }else if(categorySelection2 == "Monitor"){
        resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl2}`);
      }
  
      let ratingData = resp2.data.categorySummary.map(data => data['Rating']);
      setCategoryRating2(ratingData);
  
      let colorData = resp2.data.categorySummary.map(data => data['Color']);
      setCategoryColor2(colorData);
  
      // Labels and Info Box
      setCategorySummaryOriginalData2(resp2.data.categorySummary);
  
      // Use categorySummaryCustom (Has Filters)
  
      setCategorySummaryData2(resp2.data.categorySummaryCustom);
  
      let labelData = resp2.data.categorySummaryCustom.map(data => data['Category']);
      setCategoryLabel2(labelData);
  
  
      let fiveStarCountData = resp2.data.categorySummaryCustom.map(data => data['5-Star Count']);
      setCategoryFiveStarCount2(fiveStarCountData);
  
      let fourStarCountData = resp2.data.categorySummaryCustom.map(data => data['4-Star Count']);
      setCategoryFourStarCount2(fourStarCountData);
  
      let threeStarCountData = resp2.data.categorySummaryCustom.map(data => data['3-Star Count']);
      setCategoryThreeStarCount2(threeStarCountData);
  
      let twoStarCountData = resp2.data.categorySummaryCustom.map(data => data['2-Star Count']);
      setCategoryTwoStarCount2(twoStarCountData);
  
      let oneStarCountData = resp2.data.categorySummaryCustom.map(data => data['1-Star Count']);
      setCategoryOneStarCount2(oneStarCountData);
  
      let fiveStarPercentData = resp2.data.categorySummaryCustom.map(data => data['5-Star Percent']);
      setCategoryFiveStarPercent2(fiveStarPercentData);
  
      let fourStarPercentData = resp2.data.categorySummaryCustom.map(data => data['4-Star Percent']);
      setCategoryFourStarPercent2(fourStarPercentData);
  
      let threeStarPercentData = resp2.data.categorySummaryCustom.map(data => data['3-Star Percent']);
      setCategoryThreeStarPercent2(threeStarPercentData);
  
      let twoStarPercentData = resp2.data.categorySummaryCustom.map(data => data['2-Star Percent']);
      setCategoryTwoStarPercent2(twoStarPercentData);
  
      let oneStarPercentData = resp2.data.categorySummaryCustom.map(data => data['1-Star Percent']);
      setCategoryOneStarPercent2(oneStarPercentData);
  
      let positiveCountData = resp2.data.categorySummaryCustom.map(data => data['Positive Count']);
      setCategoryPositiveCount2(positiveCountData);
  
      let neutralCountData = resp2.data.categorySummaryCustom.map(data => data['Neutral Count']);
      setCategoryNeutralCount2(neutralCountData);
  
      let negativeCountData = resp2.data.categorySummaryCustom.map(data => data['Negative Count']);
      setCategoryNegativeCount2(negativeCountData);
  
      let positivePercentData = resp2.data.categorySummaryCustom.map(data => data['Positive Percent']);
      setCategoryPositivePercent2(positivePercentData);
  
      let negativePercentData = resp2.data.categorySummaryCustom.map(data => data['Negative Percent']);
      setCategoryNegativePercent2(negativePercentData);
  
      let neutralPercentData = resp2.data.categorySummaryCustom.map(data => data['Neutral Percent']);
      setCategoryNeutralPercent2(neutralPercentData);
  
      setCategorySummaryIsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setCategorySummaryIsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    }


  }



  if (modelSelection3.length != 0){

      
  // Category Summary Data - Model 3
  setCategorySummaryIsLoading(true);
  let restGETurl3;

  if(categorySelection3 == "TV"){

    restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&sortOption="}${sortOption}`;

  }else if(categorySelection3 == "Monitor"){

    restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&sortOption="}${sortOption}`;

  }

  restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
  restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

  let resp3;
  try {
    if(categorySelection3 == "TV"){
      resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryTV}${restGETurl3}`);
    }else if(categorySelection3 == "Monitor"){
      resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].categorySummaryMonitor}${restGETurl3}`);
    }

    let ratingData = resp3.data.categorySummary.map(data => data['Rating']);
    setCategoryRating3(ratingData);

    let colorData = resp3.data.categorySummary.map(data => data['Color']);
    setCategoryColor3(colorData);

    // Labels and Info Box
    setCategorySummaryOriginalData3(resp3.data.categorySummary);

    // Use categorySummaryCustom (Has Filters)

    setCategorySummaryData3(resp3.data.categorySummaryCustom);

    let labelData = resp3.data.categorySummaryCustom.map(data => data['Category']);
    setCategoryLabel3(labelData);


    let fiveStarCountData = resp3.data.categorySummaryCustom.map(data => data['5-Star Count']);
    setCategoryFiveStarCount3(fiveStarCountData);

    let fourStarCountData = resp3.data.categorySummaryCustom.map(data => data['4-Star Count']);
    setCategoryFourStarCount3(fourStarCountData);

    let threeStarCountData = resp3.data.categorySummaryCustom.map(data => data['3-Star Count']);
    setCategoryThreeStarCount3(threeStarCountData);

    let twoStarCountData = resp3.data.categorySummaryCustom.map(data => data['2-Star Count']);
    setCategoryTwoStarCount3(twoStarCountData);

    let oneStarCountData = resp3.data.categorySummaryCustom.map(data => data['1-Star Count']);
    setCategoryOneStarCount3(oneStarCountData);

    let fiveStarPercentData = resp3.data.categorySummaryCustom.map(data => data['5-Star Percent']);
    setCategoryFiveStarPercent3(fiveStarPercentData);

    let fourStarPercentData = resp3.data.categorySummaryCustom.map(data => data['4-Star Percent']);
    setCategoryFourStarPercent3(fourStarPercentData);

    let threeStarPercentData = resp3.data.categorySummaryCustom.map(data => data['3-Star Percent']);
    setCategoryThreeStarPercent3(threeStarPercentData);

    let twoStarPercentData = resp3.data.categorySummaryCustom.map(data => data['2-Star Percent']);
    setCategoryTwoStarPercent3(twoStarPercentData);

    let oneStarPercentData = resp3.data.categorySummaryCustom.map(data => data['1-Star Percent']);
    setCategoryOneStarPercent3(oneStarPercentData);

    let positiveCountData = resp3.data.categorySummaryCustom.map(data => data['Positive Count']);
    setCategoryPositiveCount3(positiveCountData);

    let neutralCountData = resp3.data.categorySummaryCustom.map(data => data['Neutral Count']);
    setCategoryNeutralCount3(neutralCountData);

    let negativeCountData = resp3.data.categorySummaryCustom.map(data => data['Negative Count']);
    setCategoryNegativeCount3(negativeCountData);

    let positivePercentData = resp3.data.categorySummaryCustom.map(data => data['Positive Percent']);
    setCategoryPositivePercent3(positivePercentData);

    let negativePercentData = resp3.data.categorySummaryCustom.map(data => data['Negative Percent']);
    setCategoryNegativePercent3(negativePercentData);

    let neutralPercentData = resp3.data.categorySummaryCustom.map(data => data['Neutral Percent']);
    setCategoryNeutralPercent3(neutralPercentData);

    setCategorySummaryIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setCategorySummaryIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  }





  }
  



}


const getTopicSummaryDataRefresh1 = async () => {

  // Reset
  setTopicMenuList([]);
  setTopicMenuSelection([]);
  
  setTopicDetailMenuList([]);
  setTopicDetailMenuSelection([]);

  setTopicAndTopicDetailMenuList([]);


  if (modelSelection1.length != 0){

    
  // Topic Summary Data - Model 1
  setTopicOverviewIsLoading(true);
  let restGETurl1;

  if(categorySelection1 == "TV"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection1 == "Monitor"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection1 == "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl1}`);
    }else if(categorySelection1 == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl1}`);
    }

    setTopicComparison1(resp.data.topicComparison);
    setTopicDetailComparison1(resp.data.topicDetailComparison);

    setTopicComparisonOriginal1(resp.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal1(resp.data.topicDetailComparisonOriginal);

    setTopicDetailPositive1(resp.data.positiveTopic);
    setTopicDetailNegative1(resp.data.negativeTopic);

    //setTopicMenuList(resp.data.topicMenu); //error
    //setTopicDetailMenuList(resp.data.topicDetailMenu); //error

    setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp.data.topicAndTopicDetailMenu));

    //setTopicMenuSelection(resp.data.topicMenu);  //error
    //setTopicDetailMenuSelection(resp.data.topicDetailMenu); //error

    
    // Check if response is single or multiple
    // singe Topic list will need to be force into an array or an error would appear

    if(Array.isArray(resp.data.topicMenu)){
      //setTopicMenuList(resp.data.topicMenu);
      setTopicMenuList(prevState => prevState.concat(resp.data.topicMenu));
    }else{
      //setTopicMenuList([resp.data.topicMenu]);
      setTopicMenuList(prevState => prevState.concat([resp.data.topicMenu]));
    }

    if(Array.isArray(resp.data.topicDetailMenu)){
      //setTopicDetailMenuList(resp.data.topicDetailMenu);
      setTopicDetailMenuList(prevState => prevState.concat(resp.data.topicDetailMenu));
    }else{
      //setTopicDetailMenuList([resp.data.topicDetailMenu])
      setTopicDetailMenuList(prevState => prevState.concat([resp.data.topicDetailMenu]));
    }


    if(Array.isArray(resp.data.topicMenu)){
      //setTopicMenuSelection(resp.data.topicMenu);
      setTopicMenuSelection(prevState => prevState.concat(resp.data.topicMenu));
    }else{
      //setTopicMenuSelection([resp.data.topicMenu]);
      setTopicMenuSelection(prevState => prevState.concat([resp.data.topicMenu]));
    }

    if(Array.isArray(resp.data.topicDetailMenu)){
      //setTopicDetailMenuSelection(resp.data.topicDetailMenu);
      setTopicDetailMenuSelection(prevState => prevState.concat(resp.data.topicDetailMenu));
    }else{
      //setTopicDetailMenuSelection([resp.data.topicDetailMenu]);
      setTopicDetailMenuSelection(prevState => prevState.concat([resp.data.topicDetailMenu]));
    }




    setTopicOverviewIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  }


  }
  


  if (modelSelection2.length != 0){


        // Topic Summary Data - Model 2
        setTopicOverviewIsLoading(true);
        let restGETurl2;
      
        if(categorySelection2 == "TV"){
      
          restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOptionChart1Model2}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;
      
        }else if(categorySelection2 == "Monitor"){
      
          restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOptionChart1Model2}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;
      
        }
      
        restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
        restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url
      
        let resp2;
        try {
      
          if(categorySelection2 == "TV"){
            resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl2}`);
          }else if(categorySelection2 == "Monitor"){
            resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl2}`);
          }
      
          setTopicComparison2(resp2.data.topicComparison);
          setTopicDetailComparison2(resp2.data.topicDetailComparison);
      
          setTopicComparisonOriginal2(resp2.data.topicComparisonOriginal);
          setTopicDetailComparisonOriginal2(resp2.data.topicDetailComparisonOriginal);
      
          setTopicDetailPositive2(resp2.data.positiveTopic);
          setTopicDetailNegative2(resp2.data.negativeTopic);
      
          //setTopicMenuList(resp.data.topicMenu); //error
          //setTopicDetailMenuList(resp.data.topicDetailMenu); //error
      
          setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp2.data.topicAndTopicDetailMenu));
      
          //setTopicMenuSelection(resp.data.topicMenu);  //error
          //setTopicDetailMenuSelection(resp.data.topicDetailMenu); //error
      
          
          // Check if response is single or multiple
          // singe Topic list will need to be force into an array or an error would appear
      
          if(Array.isArray(resp2.data.topicMenu)){
            //setTopicMenuList(resp.data.topicMenu);
            setTopicMenuList(prevState => prevState.concat(resp2.data.topicMenu));
          }else{
            //setTopicMenuList([resp.data.topicMenu]);
            setTopicMenuList(prevState => prevState.concat([resp2.data.topicMenu]));
          }
      
          if(Array.isArray(resp2.data.topicDetailMenu)){
            //setTopicDetailMenuList(resp.data.topicDetailMenu);
            setTopicDetailMenuList(prevState => prevState.concat(resp2.data.topicDetailMenu));
          }else{
            //setTopicDetailMenuList([resp.data.topicDetailMenu])
            setTopicDetailMenuList(prevState => prevState.concat([resp2.data.topicDetailMenu]));
          }
    
    
          if(Array.isArray(resp2.data.topicMenu)){
            setTopicMenuSelection(prevState => prevState.concat(resp2.data.topicMenu));
          }else{
            setTopicMenuSelection(prevState => prevState.concat([resp2.data.topicMenu]));
          }
      
          if(Array.isArray(resp2.data.topicDetailMenu)){
            setTopicDetailMenuSelection(prevState => prevState.concat(resp2.data.topicDetailMenu));
          }else{
            setTopicDetailMenuSelection(prevState => prevState.concat([resp2.data.topicDetailMenu]));
          }
      
      
      
          setTopicOverviewIsLoading(false);
          // setProgress(prevCount => prevCount + 20);
      
        } catch (err) {
          // Handle Error Here
          console.error(err);
      
          setTopicOverviewIsLoading(false);
          // setProgress(prevCount => prevCount + 20);
      
        }


  }




  if (modelSelection3.length != 0){


       // Topic Summary Data - Model 3
       setTopicOverviewIsLoading(true);
       let restGETurl3;
     
       if(categorySelection3 == "TV"){
     
         restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOptionChart1Model3}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;
     
       }else if(categorySelection3 == "Monitor"){
     
         restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOptionChart1Model3}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;
     
       }
     
       restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
       restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url
     
       let resp3;
       try {
     
         if(categorySelection3 == "TV"){
           resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl3}`);
         }else if(categorySelection3 == "Monitor"){
           resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl3}`);
         }
     
         setTopicComparison3(resp3.data.topicComparison);
         setTopicDetailComparison3(resp3.data.topicDetailComparison);
     
         setTopicComparisonOriginal3(resp3.data.topicComparisonOriginal);
         setTopicDetailComparisonOriginal3(resp3.data.topicDetailComparisonOriginal);
     
         setTopicDetailPositive3(resp3.data.positiveTopic);
         setTopicDetailNegative3(resp3.data.negativeTopic);
     
         //setTopicMenuList(resp.data.topicMenu); //error
         //setTopicDetailMenuList(resp.data.topicDetailMenu); //error
     
         setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp3.data.topicAndTopicDetailMenu));
     
         //setTopicMenuSelection(resp.data.topicMenu);  //error
         //setTopicDetailMenuSelection(resp.data.topicDetailMenu); //error
     
         
         // Check if response is single or multiple
         // singe Topic list will need to be force into an array or an error would appear
   
         if(Array.isArray(resp3.data.topicMenu)){
           //setTopicMenuList(resp.data.topicMenu);
           setTopicMenuList(prevState => prevState.concat(resp3.data.topicMenu));
         }else{
           //setTopicMenuList([resp.data.topicMenu]);
           setTopicMenuList(prevState => prevState.concat([resp3.data.topicMenu]));
         }
     
         if(Array.isArray(resp3.data.topicDetailMenu)){
           //setTopicDetailMenuList(resp.data.topicDetailMenu);
           setTopicDetailMenuList(prevState => prevState.concat(resp3.data.topicDetailMenu));
         }else{
           //setTopicDetailMenuList([resp.data.topicDetailMenu])
           setTopicDetailMenuList(prevState => prevState.concat([resp3.data.topicDetailMenu]));
         }
   
   
         if(Array.isArray(resp3.data.topicMenu)){
           setTopicMenuSelection(prevState => prevState.concat(resp3.data.topicMenu));
         }else{
           setTopicMenuSelection(prevState => prevState.concat([resp3.data.topicMenu]));
         }
     
         if(Array.isArray(resp3.data.topicDetailMenu)){
           setTopicDetailMenuSelection(prevState => prevState.concat(resp3.data.topicDetailMenu));
         }else{
           setTopicDetailMenuSelection(prevState => prevState.concat([resp3.data.topicDetailMenu]));
         }
     
     
     
         setTopicOverviewIsLoading(false);
         // setProgress(prevCount => prevCount + 20);
     
       } catch (err) {
         // Handle Error Here
         console.error(err);
     
         setTopicOverviewIsLoading(false);
         // setProgress(prevCount => prevCount + 20);
     
       }


  }
  





};


// onClick Refresh 2 Async Function - end



const onClickChartDataRefreshTopicOverview = () => {

  getTopicSummaryDataRefresh2();

}

// onClick Refresh 2 Async Function - Start



const getTopicSummaryDataRefresh2 = async () => {

  // Reset
  setTopicMenuList([]);
  setTopicMenuSelection([]);
  
  setTopicDetailMenuList([]);
  setTopicDetailMenuSelection([]);

  setTopicAndTopicDetailMenuList([]);


  // Model 1


  if (modelSelection1.length != 0){


      // Topic Summary Data
  setTopicOverviewIsLoading(true);
  let restGETurl1;

  if(categorySelection1 == "TV"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection1 == "Monitor"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection1 == "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl1}`);
    }else if(categorySelection1 == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl1}`);
    }

    setTopicComparison1(resp.data.topicComparison);
    setTopicDetailComparison1(resp.data.topicDetailComparison);

    setTopicComparisonOriginal1(resp.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal1(resp.data.topicDetailComparisonOriginal);

    setTopicDetailPositive1(resp.data.positiveTopic);
    setTopicDetailNegative1(resp.data.negativeTopic);

    setTopicMenuList(prevState => prevState.concat(resp.data.topicMenu));
    setTopicDetailMenuList(prevState => prevState.concat(resp.data.topicDetailMenu));
    setTopicMenuSelection(prevState => prevState.concat(resp.data.topicMenu));
    setTopicDetailMenuSelection(prevState => prevState.concat(resp.data.topicDetailMenu));
    setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp.data.topicAndTopicDetailMenu));

    setTopicOverviewIsLoading(false);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);


  }


  if(analyticOption == "sentiment"){

    topicOverviewTopicComparisonDataSet1 = {
      type: 'bar',
      labels: topicDetailComparisonOriginal1.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicComparison1.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicComparison1.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicComparison1.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    topicOverviewTopicComparisonOption1 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet1 = topicComparisonOriginal1.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn1 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption == "star"){
    topicOverviewTopicComparisonDataSet1 = {
      type: 'bar',
      labels: topicComparison1.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicComparison1.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicComparison1.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicComparison1.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicComparison1.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicComparison1.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    topicOverviewTopicComparisonOption1 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet1 = topicComparisonOriginal1.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn1 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }




  // Topic Detail Overview - Topic Comparison List - Stack Bar Chart

  if(analyticOption == "sentiment"){
    var topicDetailOverviewTopicComparisonDataSet1 = {
      type: 'bar',
      labels: topicDetailComparison1.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicDetailComparison1.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicDetailComparison1.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicDetailComparison1.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    var topicDetailOverviewTopicComparisonOption1 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet1 = topicDetailComparisonOriginal1.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn1 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption == "star"){
    var topicDetailOverviewTopicComparisonDataSet1 = {
      type: 'bar',
      labels: topicDetailComparison1.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicDetailComparison1.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicDetailComparison1.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicDetailComparison1.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicDetailComparison1.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicDetailComparison1.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    var topicDetailOverviewTopicComparisonOption1 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet1 = topicDetailComparisonOriginal1.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn1 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  }



  if (modelSelection2.length != 0){


      // Model 2

  // Topic Summary Data
  setTopicOverviewIsLoading(true);
  let restGETurl2;

  if(categorySelection2 == "TV"){

    restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection2 == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp2;
  try {

    if(categorySelection2 == "TV"){
      resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl2}`);
    }else if(categorySelection2 == "Monitor"){
      resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl2}`);
    }

    setTopicComparison2(resp2.data.topicComparison);
    setTopicDetailComparison2(resp2.data.topicDetailComparison);

    setTopicComparisonOriginal2(resp2.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal2(resp2.data.topicDetailComparisonOriginal);

    setTopicDetailPositive2(resp2.data.positiveTopic);
    setTopicDetailNegative2(resp2.data.negativeTopic);

    setTopicMenuList(prevState => prevState.concat(resp2.data.topicMenu));
    setTopicDetailMenuList(prevState => prevState.concat(resp2.data.topicDetailMenu));
    setTopicMenuSelection(prevState => prevState.concat(resp2.data.topicMenu));
    setTopicDetailMenuSelection(prevState => prevState.concat(resp2.data.topicDetailMenu));
    setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp2.data.topicAndTopicDetailMenu));

    setTopicOverviewIsLoading(false);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);


  }


  if(analyticOption == "sentiment"){
    topicOverviewTopicComparisonDataSet2 = {
      type: 'bar',
      labels: topicDetailComparisonOriginal2.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicComparison2.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicComparison2.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicComparison2.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    topicOverviewTopicComparisonOption2 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet2 = topicComparisonOriginal2.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn2 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption == "star"){
    topicOverviewTopicComparisonDataSet2 = {
      type: 'bar',
      labels: topicComparison2.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicComparison2.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicComparison2.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicComparison2.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicComparison2.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicComparison2.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    topicOverviewTopicComparisonOption2 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet2 = topicComparisonOriginal2.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn2 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }




  // Topic Detail Overview - Topic Comparison List - Stack Bar Chart

  if(analyticOption == "sentiment"){
    var topicDetailOverviewTopicComparisonDataSet2 = {
      type: 'bar',
      labels: topicDetailComparison2.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicDetailComparison2.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicDetailComparison2.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicDetailComparison2.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    var topicDetailOverviewTopicComparisonOption2 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet2 = topicDetailComparisonOriginal2.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn2 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption == "star"){
    var topicDetailOverviewTopicComparisonDataSet2 = {
      type: 'bar',
      labels: topicDetailComparison2.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicDetailComparison2.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicDetailComparison2.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicDetailComparison2.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicDetailComparison2.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicDetailComparison2.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    var topicDetailOverviewTopicComparisonOption2 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet2 = topicDetailComparisonOriginal2.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn2 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  }


  if (modelSelection3.length != 0){


      // Model 3

  // Topic Summary Data
  setTopicOverviewIsLoading(true);
  let restGETurl3;

  if(categorySelection3 == "TV"){

    restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection3 == "Monitor"){

    restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
  restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

  let resp3;
  try {

    if(categorySelection3 == "TV"){
      resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl3}`);
    }else if(categorySelection3 == "Monitor"){
      resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl3}`);
    }

    setTopicComparison3(resp3.data.topicComparison);
    setTopicDetailComparison3(resp3.data.topicDetailComparison);

    setTopicComparisonOriginal3(resp3.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal3(resp3.data.topicDetailComparisonOriginal);

    setTopicDetailPositive3(resp3.data.positiveTopic);
    setTopicDetailNegative3(resp3.data.negativeTopic);

    setTopicMenuList(prevState => prevState.concat(resp3.data.topicMenu));
    setTopicDetailMenuList(prevState => prevState.concat(resp3.data.topicDetailMenu));
    setTopicMenuSelection(prevState => prevState.concat(resp3.data.topicMenu));
    setTopicDetailMenuSelection(prevState => prevState.concat(resp3.data.topicDetailMenu));
    setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp3.data.topicAndTopicDetailMenu));

    setTopicOverviewIsLoading(false);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);


  }


  if(analyticOption == "sentiment"){
    topicOverviewTopicComparisonDataSet3 = {
      type: 'bar',
      labels: topicDetailComparisonOriginal3.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicComparison3.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicComparison3.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicComparison3.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    topicOverviewTopicComparisonOption3 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet3 = topicComparisonOriginal3.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn3 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption == "star"){
    topicOverviewTopicComparisonDataSet3 = {
      type: 'bar',
      labels: topicComparison3.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicComparison3.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicComparison3.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicComparison3.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicComparison3.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicComparison3.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    topicOverviewTopicComparisonOption3 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Overview - Topic Comparison List Table using react-data-table-component
    
    topicOverviewTopicComparisonTableDataSet3 = topicComparisonOriginal3.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    topicOverviewTopicComparisonTableColumn3 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }




  // Topic Detail Overview - Topic Comparison List - Stack Bar Chart

  if(analyticOption == "sentiment"){
    var topicDetailOverviewTopicComparisonDataSet3 = {
      type: 'bar',
      labels: topicDetailComparison3.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicDetailComparison3.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicDetailComparison3.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicDetailComparison3.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }


    
    var topicDetailOverviewTopicComparisonOption3 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet3 = topicDetailComparisonOriginal3.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          positive: data['Positive'],
          negative: data['Negative'],
          neutral: data['Neutral'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn3 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  if(analyticOption == "star"){
    var topicDetailOverviewTopicComparisonDataSet3 = {
      type: 'bar',
      labels: topicDetailComparison3.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicDetailComparison3.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicDetailComparison3.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicDetailComparison3.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicDetailComparison3.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicDetailComparison3.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    
    }
    
    var topicDetailOverviewTopicComparisonOption3 = {
    
      // Drill Down
      onClick: (event, elements) => {
    
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
    
        }
    
      },
    
    
      scales: {
        xAxes: [{
            stacked: true,
            ticks: {
              beginAtZero:true
          }
        }],
        yAxes: [{
            stacked: true
        }]
    }
    
    
    }
    
    // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
    
    var topicDetailOverviewTopicComparisonTableDataSet3 = topicDetailComparisonOriginal3.map((data) => {
        return ({
          id: data.id,
          topic: data['Topic'],
          fiveStar: data['5-Star'],
          fourStar: data['4-Star'],
          threeStar: data['3-Star'],
          twoStar: data['2-Star'],
          oneStar: data['1-Star'],
          total: data['Total']
        })
      });
      
    var topicDetailOverviewTopicComparisonTableColumn3 = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

  }


  }




};


// onClick Refresh 2 Async Function - end


// onClick Refresh 3 Async Function - start

const getTopicDetailDataRefresh3 = async () => {


  if (modelSelection1.length != 0){

    // Topic Detail
    setTopicDetail2IsLoading(true);

    let restGETurl1;

    if(categorySelection1 === "TV"){

      restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

    }else if(categorySelection1 == "Monitor"){

      restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

    }


    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

    let resp1;
    try {

      if(categorySelection1 == "TV"){
        resp1 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl1}`);
      }else if(categorySelection1 == "Monitor"){
        resp1 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl1}`);
      }
        setCategoryData1(resp1.data.categoryList);
        setTopicData1(resp1.data.topicList);
        setTopicDetailData1(resp1.data.topicDetailList);
        setTopicTrendData1(resp1.data.trendData);
        setCustomIndividualSentence1(resp1.data.individualSentenceCN);

        setTopicDetail2IsLoading(false);
        // setProgress(prevCount => prevCount + 20);

    } catch (err) {
      // Handle Error Here
      console.error(err);

      setTopicDetail2IsLoading(false);
      // setProgress(prevCount => prevCount + 20);

    }



  }



  if (modelSelection2.length != 0){

    // Topic Detail
    setTopicDetail2IsLoading(true);

    let restGETurl2;

    if(categorySelection2 === "TV"){

      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

    }else if(categorySelection2 == "Monitor"){

      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

    }


    restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
    restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

    let resp2;
    try {

      if(categorySelection2 == "TV"){
        resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl2}`);
      }else if(categorySelection2 == "Monitor"){
        resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl2}`);
      }
        setCategoryData2(resp2.data.categoryList);
        setTopicData2(resp2.data.topicList);
        setTopicDetailData2(resp2.data.topicDetailList);
        setTopicTrendData2(resp2.data.trendData);
        setCustomIndividualSentence2(resp2.data.individualSentenceCN);

        setTopicDetail2IsLoading(false);
        // setProgress(prevCount => prevCount + 20);

    } catch (err) {
      // Handle Error Here
      console.error(err);

      setTopicDetail2IsLoading(false);
      // setProgress(prevCount => prevCount + 20);

    }



  }



  if (modelSelection3.length != 0){

    // Topic Detail
    setTopicDetail2IsLoading(true);

    let restGETurl3;

    if(categorySelection3 === "TV"){

      restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

    }else if(categorySelection3 == "Monitor"){

      restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${stateMonitor.externaldeviceMonitor}${"&categoryPQ="}${stateMonitor.pqMonitor}${"&categoryDesign="}${stateMonitor.designMonitor}${"&categorySQ="}${stateMonitor.sqMonitor}${"&categoryScenario="}${stateMonitor.scenarioMonitor}${"&categoryQA="}${stateMonitor.qaMonitor}${"&categoryCOI="}${stateMonitor.coiMonitor}${"&categoryUX="}${stateMonitor.uxMonitor}${"&categoryPerformance="}${stateMonitor.performanceMonitor}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${topicDetailTrendFilter}`;

    }


    restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
    restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

    let resp3;
    try {

      if(categorySelection3 == "TV"){
        resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl3}`);
      }else if(categorySelection3 == "Monitor"){
        resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl3}`);
      }
        setCategoryData3(resp3.data.categoryList);
        setTopicData3(resp3.data.topicList);
        setTopicDetailData3(resp3.data.topicDetailList);
        setTopicTrendData3(resp3.data.trendData);
        setCustomIndividualSentence3(resp3.data.individualSentenceCN);

        setTopicDetail2IsLoading(false);
        // setProgress(prevCount => prevCount + 20);

    } catch (err) {
      // Handle Error Here
      console.error(err);

      setTopicDetail2IsLoading(false);
      // setProgress(prevCount => prevCount + 20);

    }



  }




  
}

// onClick Refresh 3 Async Function - end


const onClickChartDataRefreshTopicDetail = () => {

  getTopicDetailDataRefresh3();

}


const onClickCheckAll = () => {
  // Select All Topic Selection
  setTopicMenuSelection(topicMenuList);

   // Set Topic Detail Menu list base user selection for Topic Menu
   let topicList = [];
   topicList = topicMenuList;

   let topicDetailList = topicAndTopicDetailMenuList.filter((data => topicList.includes(data.Topic)));
 
   setTopicDetailMenuList(topicDetailList.map(data => data["Topic.Detail"]));
   setTopicDetailMenuSelection(topicDetailList.map(data => data["Topic.Detail"]));

}


// Conditional Render - Category Filter

function CategoryFilter(){

  if(categorySelection1 == "TV"){

    return (
      <div>
              <p style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Category Selection</strong></p>
              <br></br>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={afterservice} onChange={handleChange} name="afterservice" size="small" style={{height: 3}} />}
                  label={<span style={{ fontSize: '14px', color: 'black' }}>After-Service</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={brand} onChange={handleChange} name="brand" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Brand</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={coi} onChange={handleChange} name="coi" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>COI</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={design} onChange={handleChange} name="design" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Design</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={hardware} onChange={handleChange} name="hardware" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Hardware</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={others} onChange={handleChange} name="others" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Others</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={performance} onChange={handleChange} name="performance" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Performance</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={picture} onChange={handleChange} name="picture" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Picture</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={price} onChange={handleChange} name="price" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Price</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={qa} onChange={handleChange} name="qa" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>QA</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={sound} onChange={handleChange} name="sound" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Sound</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={ux} onChange={handleChange} name="ux" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>UX</span>}
                />

                

              </FormGroup>
      </div>
    )
    
  }else if(categorySelection1 === "Monitor"){
    return (
      <div>

              <p style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Category Selection</strong></p>
              <br/>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={externaldeviceMonitor} onChange={handleChange} name="externaldeviceMonitor" size="small" style={{height: 3}} />}
                  label={<span style={{ fontSize: '14px', color: 'black' }}>External Device</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={pqMonitor} onChange={handleChange} name="pqMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>PQ</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={designMonitor} onChange={handleChange} name="designMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Design</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={sqMonitor} onChange={handleChange} name="sqMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>SQ</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={scenarioMonitor} onChange={handleChange} name="scenarioMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Scenario</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={qaMonitor} onChange={handleChange} name="qaMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>QA</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={coiMonitor} onChange={handleChange} name="coiMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>COI</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={uxMonitor} onChange={handleChange} name="uxMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>UX</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={performanceMonitor} onChange={handleChange} name="performanceMonitor" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Performance</span>}
                />

                

              </FormGroup>
      </div>
    )
  }else{ // Default
    return (
      <div>
              <p style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Category Selection</strong></p>
              <br/>
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={afterservice} onChange={handleChange} name="afterservice" size="small" style={{height: 3}} />}
                  label={<span style={{ fontSize: '14px', color: 'black' }}>After-Service</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={brand} onChange={handleChange} name="brand" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Brand</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={coi} onChange={handleChange} name="coi" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>COI</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={design} onChange={handleChange} name="design" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Design</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={hardware} onChange={handleChange} name="hardware" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Hardware</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={others} onChange={handleChange} name="others" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Others</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={performance} onChange={handleChange} name="performance" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Performance</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={picture} onChange={handleChange} name="picture" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Picture</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={price} onChange={handleChange} name="price" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Price</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={qa} onChange={handleChange} name="qa" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>QA</span>}
                />
                <FormControlLabel
                  control={<Checkbox checked={sound} onChange={handleChange} name="sound" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>Sound</span>}
                />

                <FormControlLabel
                  control={<Checkbox checked={ux} onChange={handleChange} name="ux" size="small" style={{height: 3}}/>}
                  label={<span style={{ fontSize: '14px', color: 'black'  }}>UX</span>}
                />

                

              </FormGroup>
      </div>
    )
  }


}



// Category Summary Bar Chart - Model 1

var categorySummaryBarDataSet1 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: categoryLabel1,
    datasets: [
      {
        label: "5-Star",
        data: categoryFiveStarCount1,
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
      },
      {
        label: "4-Star",
        data: categoryFourStarCount1,
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
      },
      {
        label: "3-Star",
        data: categoryThreeStarCount1,
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
      },
      {
        label: "2-Star",
        data: categoryTwoStarCount1,
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
      }
      ,
      {
        label: "1-Star",
        data: categoryOneStarCount1,
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
      }
      
    ]
  }

  return data

  // }, [uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption, state, stateMonitor]);
}, [categorySummaryIsLoading]);


  var categorySummaryBarOption1 = useMemo(() => {

    let data = {
          // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    },
    
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

  return data

}, [categorySummaryIsLoading]);



// Category Summary Bar Chart Table using react-data-table-component 

var categorySummaryBarTableDataSet1 = useMemo(() => {
  
  let data = categorySummaryData1.map((CategorySummaryData) => {
    return ({
      //id: CategorySummaryData.id,
      category: CategorySummaryData['Category'],
      fiveStar: CategorySummaryData['5-Star Count'], 
      fourStar: CategorySummaryData['4-Star Count'], 
      threeStar: CategorySummaryData['3-Star Count'], 
      twoStar: CategorySummaryData['2-Star Count'], 
      oneStar: CategorySummaryData['1-Star Count']
  
    })
  });

  return data

}, [categorySummaryIsLoading]);


var categorySummaryBarTableColumn1 = useMemo(() => {

  let data = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    }
  ];

  return data


}, [categorySummaryIsLoading]);



// Category Summary Bar Chart - Model 2

var categorySummaryBarDataSet2 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: categoryLabel2,
    datasets: [
      {
        label: "5-Star",
        data: categoryFiveStarCount2,
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
      },
      {
        label: "4-Star",
        data: categoryFourStarCount2,
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
      },
      {
        label: "3-Star",
        data: categoryThreeStarCount2,
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
      },
      {
        label: "2-Star",
        data: categoryTwoStarCount2,
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
      }
      ,
      {
        label: "1-Star",
        data: categoryOneStarCount2,
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
      }
      
    ]
  }

  return data

}, [categorySummaryIsLoading]);


  var categorySummaryBarOption2 = useMemo(() => {


    let data = {
          // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    },
    
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }


    return data

  

  }, [categorySummaryIsLoading]);



// Category Summary Bar Chart Table using react-data-table-component 

var categorySummaryBarTableDataSet2 = useMemo(() => {

  let data = categorySummaryData2.map((CategorySummaryData) => {
    return ({
      //id: CategorySummaryData.id,
      category: CategorySummaryData['Category'],
      fiveStar: CategorySummaryData['5-Star Count'], 
      fourStar: CategorySummaryData['4-Star Count'], 
      threeStar: CategorySummaryData['3-Star Count'], 
      twoStar: CategorySummaryData['2-Star Count'], 
      oneStar: CategorySummaryData['1-Star Count']
  
    })
  });

  return data


}, [categorySummaryIsLoading]);


var categorySummaryBarTableColumn2 = useMemo(() => {


  let data = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    }
  ];

  return data


}, [categorySummaryIsLoading]);



// Category Summary Bar Chart - Model 3

let categorySummaryBarDataSet3 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: categoryLabel3,
    datasets: [
      {
        label: "5-Star",
        data: categoryFiveStarCount3,
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
      },
      {
        label: "4-Star",
        data: categoryFourStarCount3,
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
      },
      {
        label: "3-Star",
        data: categoryThreeStarCount3,
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
      },
      {
        label: "2-Star",
        data: categoryTwoStarCount3,
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
      }
      ,
      {
        label: "1-Star",
        data: categoryOneStarCount3,
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
      }
      
    ]
  }

  return data

}, [categorySummaryIsLoading]);


  let categorySummaryBarOption3 = useMemo(() => {


    let data = {
          // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    },
    
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }


    return data

  

  }, [categorySummaryIsLoading]);



// Category Summary Bar Chart Table using react-data-table-component 
var categorySummaryBarTableDataSet3 = useMemo(() => {

  let data = 
  categorySummaryData3.map((CategorySummaryData) => {
    return ({
      //id: CategorySummaryData.id,
      category: CategorySummaryData['Category'],
      fiveStar: CategorySummaryData['5-Star Count'], 
      fourStar: CategorySummaryData['4-Star Count'], 
      threeStar: CategorySummaryData['3-Star Count'], 
      twoStar: CategorySummaryData['2-Star Count'], 
      oneStar: CategorySummaryData['1-Star Count']
  
    })
  });

  return data


}, [categorySummaryIsLoading]);


var categorySummaryBarTableColumn3 = useMemo(() => {

  let data = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    }
  ];


  return data
}, [categorySummaryIsLoading]);





// Category Summary Radar Chart - Star - Model 1

var categorySummaryStarRadarDataSet1 = useMemo(() => {

  let data = {
    spanGaps: true,
    type: 'radar',
    labels: categoryLabel1,
    datasets: [
  
      {
        label: "1-Star",
        data: categoryOneStarPercent1,
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
        pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
      },
      {
        label: "2-Star",
        data: categoryTwoStarPercent1,
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .6)", // Fill Color
        pointBackgroundColor: "rgb(236, 50, 50)", // Dot Color
      },
      {
        label: "3-Star",
        data: categoryThreeStarPercent1,
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .6)", // Fill Color
        pointBackgroundColor: "rgb(245, 200, 15)", // Dot Color
      },
      {
        label: "4-Star",
        data: categoryFourStarPercent1,
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .6)", // Fill Color
        pointBackgroundColor: "rgb(36, 222, 96)", // Dot Color
      },
      {
        label: "5-Star",
        data:  categoryFiveStarPercent1,
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
        pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
      }
      
    ]
  }

  return data

}, [categorySummaryIsLoading]);


  var categorySummaryStarRadarOption1 = useMemo(() => {


    let data = {
          // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }
    }

    return data

  }, [categorySummaryIsLoading]);


// Category Summary Radar Table using react-data-table-component - Star

var categorySummaryStarRadarTableDataSet1 = useMemo(() => {

  let data = categorySummaryData1.map((CategorySummaryData) => {
    return ({
      id: CategorySummaryData.id,
      category: CategorySummaryData["Category"],
      fiveStarCount: CategorySummaryData["5-Star Count"], 
      fourStarCount: CategorySummaryData["4-Star Count"], 
      threeStarCount: CategorySummaryData["3-Star Count"], 
      twoStarCount: CategorySummaryData["2-Star Count"], 
      oneStarCount: CategorySummaryData["1-Star Count"], 
      fiveStarPercent: CategorySummaryData["5-Star Percent"], 
      fourStarPercent: CategorySummaryData["4-Star Percent"], 
      threeStarPercent: CategorySummaryData["3-Star Percent"], 
      twoStarPercent: CategorySummaryData["2-Star Percent"], 
      oneStarPercent: CategorySummaryData["1-Star Percent"], 
  
    })
  });

  return data


}, [categorySummaryIsLoading]);


var categorySummaryStarRadarTableColumn1 = useMemo(() => {

  let data = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
    {
      name: '5-Star Count',
      selector: 'fiveStarCount',
      sortable: true,
      center: true
    },
    {
      name: '4-Star Count',
      selector: 'fourStarCount',
      sortable: true,
      center: true
    },
    {
      name: '3-Star Count',
      selector: 'threeStarCount',
      sortable: true,
      center: true
    },
    {
      name: '2-Star Count',
      selector: 'twoStarCount',
      sortable: true,
      center: true
    },
    {
      name: '1-Star Count',
      selector: 'oneStarCount',
      sortable: true,
      center: true
    },
  
    {
      name: '5-Star Percent',
      selector: 'fiveStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '4-Star Percent',
      selector: 'fourStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '3-Star Percent',
      selector: 'threeStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '2-Star Percent',
      selector: 'twoStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '1-Star Percent',
      selector: 'oneStarPercent',
      sortable: true,
      center: true
    },
  
  ];

  return data


}, [categorySummaryIsLoading]);




  // Category Summary Radar Chart - Star - Model 2

var categorySummaryStarRadarDataSet2 = useMemo(() => {

  let data = {
    spanGaps: true,
    type: 'radar',
    labels: categoryLabel2,
    datasets: [
  
      {
        label: "1-Star",
        data: categoryOneStarPercent2,
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
        pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
      },
      {
        label: "2-Star",
        data: categoryTwoStarPercent2,
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .6)", // Fill Color
        pointBackgroundColor: "rgb(236, 50, 50)", // Dot Color
      },
      {
        label: "3-Star",
        data: categoryThreeStarPercent2,
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .6)", // Fill Color
        pointBackgroundColor: "rgb(245, 200, 15)", // Dot Color
      },
      {
        label: "4-Star",
        data: categoryFourStarPercent2,
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .6)", // Fill Color
        pointBackgroundColor: "rgb(36, 222, 96)", // Dot Color
      },
      {
        label: "5-Star",
        data:  categoryFiveStarPercent2,
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
        pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
      }
      
    ]
  }

  return data
}, [categorySummaryIsLoading]);


  var categorySummaryStarRadarOption2 = useMemo(() => {


    let data = {
          // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }
    }

    return data

  }, [categorySummaryIsLoading]);


// Category Summary Radar Table using react-data-table-component - Star

var categorySummaryStarRadarTableDataSet2 = useMemo(() => {

  let data = categorySummaryData2.map((CategorySummaryData) => {
    return ({
      id: CategorySummaryData.id,
      category: CategorySummaryData["Category"],
      fiveStarCount: CategorySummaryData["5-Star Count"], 
      fourStarCount: CategorySummaryData["4-Star Count"], 
      threeStarCount: CategorySummaryData["3-Star Count"], 
      twoStarCount: CategorySummaryData["2-Star Count"], 
      oneStarCount: CategorySummaryData["1-Star Count"], 
      fiveStarPercent: CategorySummaryData["5-Star Percent"], 
      fourStarPercent: CategorySummaryData["4-Star Percent"], 
      threeStarPercent: CategorySummaryData["3-Star Percent"], 
      twoStarPercent: CategorySummaryData["2-Star Percent"], 
      oneStarPercent: CategorySummaryData["1-Star Percent"], 
  
    })
  });

  return data

}, [categorySummaryIsLoading]);


var categorySummaryStarRadarTableColumn2 = useMemo(() => {

  let data = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
    {
      name: '5-Star Count',
      selector: 'fiveStarCount',
      sortable: true,
      center: true
    },
    {
      name: '4-Star Count',
      selector: 'fourStarCount',
      sortable: true,
      center: true
    },
    {
      name: '3-Star Count',
      selector: 'threeStarCount',
      sortable: true,
      center: true
    },
    {
      name: '2-Star Count',
      selector: 'twoStarCount',
      sortable: true,
      center: true
    },
    {
      name: '1-Star Count',
      selector: 'oneStarCount',
      sortable: true,
      center: true
    },
  
    {
      name: '5-Star Percent',
      selector: 'fiveStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '4-Star Percent',
      selector: 'fourStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '3-Star Percent',
      selector: 'threeStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '2-Star Percent',
      selector: 'twoStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '1-Star Percent',
      selector: 'oneStarPercent',
      sortable: true,
      center: true
    },
  
  ];

  return data

}, [categorySummaryIsLoading]);




// Category Summary Radar Chart - Star - Model 3

var categorySummaryStarRadarDataSet3 = useMemo(() => {

  let data = {
    spanGaps: true,
    type: 'radar',
    labels: categoryLabel3,
    datasets: [
  
      {
        label: "1-Star",
        data: categoryOneStarPercent3,
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
        pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
      },
      {
        label: "2-Star",
        data: categoryTwoStarPercent3,
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .6)", // Fill Color
        pointBackgroundColor: "rgb(236, 50, 50)", // Dot Color
      },
      {
        label: "3-Star",
        data: categoryThreeStarPercent3,
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .6)", // Fill Color
        pointBackgroundColor: "rgb(245, 200, 15)", // Dot Color
      },
      {
        label: "4-Star",
        data: categoryFourStarPercent3,
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .6)", // Fill Color
        pointBackgroundColor: "rgb(36, 222, 96)", // Dot Color
      },
      {
        label: "5-Star",
        data:  categoryFiveStarPercent3,
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
        pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
      }
      
    ]
  }

  return data

}, [categorySummaryIsLoading]);


  var categorySummaryStarRadarOption3 = useMemo(() => {


    let data = {
          // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }
    }

    return data

  }, [categorySummaryIsLoading]);


// Category Summary Radar Table using react-data-table-component - Star

var categorySummaryStarRadarTableDataSet3 = useMemo(() => {

  let data = categorySummaryData3.map((CategorySummaryData) => {
    return ({
      id: CategorySummaryData.id,
      category: CategorySummaryData["Category"],
      fiveStarCount: CategorySummaryData["5-Star Count"], 
      fourStarCount: CategorySummaryData["4-Star Count"], 
      threeStarCount: CategorySummaryData["3-Star Count"], 
      twoStarCount: CategorySummaryData["2-Star Count"], 
      oneStarCount: CategorySummaryData["1-Star Count"], 
      fiveStarPercent: CategorySummaryData["5-Star Percent"], 
      fourStarPercent: CategorySummaryData["4-Star Percent"], 
      threeStarPercent: CategorySummaryData["3-Star Percent"], 
      twoStarPercent: CategorySummaryData["2-Star Percent"], 
      oneStarPercent: CategorySummaryData["1-Star Percent"], 
  
    })
  });

  return data

}, [categorySummaryIsLoading]);

var categorySummaryStarRadarTableColumn3 = useMemo(() => {

  let data = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
    {
      name: '5-Star Count',
      selector: 'fiveStarCount',
      sortable: true,
      center: true
    },
    {
      name: '4-Star Count',
      selector: 'fourStarCount',
      sortable: true,
      center: true
    },
    {
      name: '3-Star Count',
      selector: 'threeStarCount',
      sortable: true,
      center: true
    },
    {
      name: '2-Star Count',
      selector: 'twoStarCount',
      sortable: true,
      center: true
    },
    {
      name: '1-Star Count',
      selector: 'oneStarCount',
      sortable: true,
      center: true
    },
  
    {
      name: '5-Star Percent',
      selector: 'fiveStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '4-Star Percent',
      selector: 'fourStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '3-Star Percent',
      selector: 'threeStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '2-Star Percent',
      selector: 'twoStarPercent',
      sortable: true,
      center: true
    },
    {
      name: '1-Star Percent',
      selector: 'oneStarPercent',
      sortable: true,
      center: true
    },
  
  ];

  return data


}, [categorySummaryIsLoading]);




const getTopicSummaryData = async () => {



  if (modelSelection1.length != 0){

    setProgress(prevCount => prevCount + 5);


    
  // Topic Summary Data - Model 1
  setTopicOverviewIsLoading(true);
  let restGETurl1;

  if(categorySelection1 == "TV"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection1 == "Monitor"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection1 == "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl1}`);
    }else if(categorySelection1 == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl1}`);
    }

    setTopicComparison1(resp.data.topicComparison);
    setTopicDetailComparison1(resp.data.topicDetailComparison);

    setTopicComparisonOriginal1(resp.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal1(resp.data.topicDetailComparisonOriginal);

    setTopicDetailPositive1(resp.data.positiveTopic);
    setTopicDetailNegative1(resp.data.negativeTopic);

    // setTopicMenuList1(resp.data.topicMenu);
    // setTopicMenuList(resp.data.topicMenu);
    // console.table(resp.data.topicMenu);
    setTopicMenuList(prevState => prevState.concat(resp.data.topicMenu));
    setTopicDetailMenuList(prevState => prevState.concat(resp.data.topicDetailMenu));
    setTopicMenuSelection(prevState => prevState.concat(resp.data.topicMenu));
    setTopicDetailMenuSelection(prevState => prevState.concat(resp.data.topicDetailMenu));
    setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp.data.topicAndTopicDetailMenu));

    // setTopicDetailMenuList1(resp.data.topicDetailMenu);
    // setTopicAndTopicDetailMenuList1(resp.data.topicAndTopicDetailMenu);

    // setTopicMenuSelection1(resp.data.topicMenu);
    // setTopicDetailMenuSelection1(resp.data.topicDetailMenu);

    setTopicOverviewIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  }



  }




  
 if (modelSelection2.length != 0){

  setProgress(prevCount => prevCount + 5);


    
  // Topic Summary Data - Model 2
  setTopicOverviewIsLoading(true);
  let restGETurl2;

  if(categorySelection2 == "TV"){

    restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }else if(categorySelection2 == "Monitor"){

    restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;

  }

  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  let resp2;
  try {

    if(categorySelection2 == "TV"){
      resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl2}`);
    }else if(categorySelection2 == "Monitor"){
      resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl2}`);
    }

    setTopicComparison2(resp2.data.topicComparison);
    setTopicDetailComparison2(resp2.data.topicDetailComparison);

    setTopicComparisonOriginal2(resp2.data.topicComparisonOriginal);
    setTopicDetailComparisonOriginal2(resp2.data.topicDetailComparisonOriginal);

    setTopicDetailPositive2(resp2.data.positiveTopic);
    setTopicDetailNegative2(resp2.data.negativeTopic);

    // setTopicMenuList2(resp2.data.topicMenu);
    // setTopicDetailMenuList2(resp2.data.topicDetailMenu);

    // setTopicAndTopicDetailMenuList2(resp2.data.topicAndTopicDetailMenu);

    // setTopicMenuSelection2(resp2.data.topicMenu);
    // setTopicDetailMenuSelection2(resp2.data.topicDetailMenu);

    setTopicMenuList(prevState => prevState.concat(resp2.data.topicMenu));
    setTopicDetailMenuList(prevState => prevState.concat(resp2.data.topicDetailMenu));
    setTopicMenuSelection(prevState => prevState.concat(resp2.data.topicMenu));
    setTopicDetailMenuSelection(prevState => prevState.concat(resp2.data.topicDetailMenu));
    setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp2.data.topicAndTopicDetailMenu));

    setTopicOverviewIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicOverviewIsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  }




  }



  if (modelSelection3.length != 0){

    setProgress(prevCount => prevCount + 5);


    
   // Topic Summary Data - Model 3
   setTopicOverviewIsLoading(true);
   let restGETurl3;
 
   if(categorySelection3 == "TV"){
 
    restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;
 
   }else if(categorySelection3 == "Monitor"){
 
    restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&analyticFilter="}${analyticOption}${"&topicFilter="}${topicOption}${"&topicSortFilter="}${topicSortOption}`;
 
   }
 
   restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
   restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url
 
   let resp3;
   try {
 
     if(categorySelection3 == "TV"){
      resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewTV}${restGETurl3}`);
     }else if(categorySelection3 == "Monitor"){
      resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicOverviewMonitor}${restGETurl3}`);
     }
 
     setTopicComparison3(resp3.data.topicComparison);
     setTopicDetailComparison3(resp3.data.topicDetailComparison);
 
     setTopicComparisonOriginal3(resp3.data.topicComparisonOriginal);
     setTopicDetailComparisonOriginal3(resp3.data.topicDetailComparisonOriginal);
 
     setTopicDetailPositive3(resp3.data.positiveTopic);
     setTopicDetailNegative3(resp3.data.negativeTopic);
 
    //  setTopicMenuList3(resp3.data.topicMenu);
    //  setTopicDetailMenuList3(resp3.data.topicDetailMenu);

    //  setTopicMenuList(prevState  => [...prevState , resp3.data.topicMenu.value]);
    //  setTopicDetailMenuList(prevState  => [...prevState , resp3.data.topicDetailMenu.value]);
 
    //  setTopicAndTopicDetailMenuList3(resp3.data.topicAndTopicDetailMenu);
 
    //  setTopicMenuSelection3(resp3.data.topicMenu);
    //  setTopicDetailMenuSelection3(resp3.data.topicDetailMenu);


      setTopicMenuList(prevState => prevState.concat(resp3.data.topicMenu));
      setTopicDetailMenuList(prevState => prevState.concat(resp3.data.topicDetailMenu));
      setTopicMenuSelection(prevState => prevState.concat(resp3.data.topicMenu));
      setTopicDetailMenuSelection(prevState => prevState.concat(resp3.data.topicDetailMenu));
      setTopicAndTopicDetailMenuList(prevState => prevState.concat(resp3.data.topicAndTopicDetailMenu));

     setTopicOverviewIsLoading(false);
     // setProgress(prevCount => prevCount + 20);
 
   } catch (err) {
     // Handle Error Here
     console.error(err);
 
     setTopicOverviewIsLoading(false);
     // setProgress(prevCount => prevCount + 20);
 
   }



  }




};



const getTopicDetailData = async () => {


  if (modelSelection1.length != 0){

    setProgress(prevCount => prevCount + 5);

    
  // Topic Detail - Model 1
  setTopicOverviewIsLoading(true);

  let restGETurl1;

  if(categorySelection1 == "TV"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;

  }else if(categorySelection1 == "Monitor"){

    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;

  }


  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  let resp;
  try {

    if(categorySelection1 == "TV"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl1}`);
    }else if(categorySelection1 == "Monitor"){
      resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl1}`);
    }
      setCategoryData1(resp.data.categoryList);
      setTopicData1(resp.data.topicList);
      setTopicDetailData1(resp.data.topicDetailList);
      setTopicTrendData1(resp.data.trendData);
      setCustomIndividualSentence1(resp.data.individualSentenceCN);

      setTopicOverviewIsLoading(false);


  } catch (err) {
    // Handle Error Here
    console.error(err);

    setTopicDetail2IsLoading(false);
    // setProgress(prevCount => prevCount + 20);

  }




  }


 if (modelSelection2.length != 0){

  setProgress(prevCount => prevCount + 5);
    
    // Topic Detail - Model 2
    setTopicOverviewIsLoading(true);

    let restGETurl2;
  
    if(categorySelection2 == "TV"){
  
      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }else if(categorySelection2 == "Monitor"){
  
      restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }
  
  
    restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
    restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url
  
    let res2;
    try {
  
      if(categorySelection2 == "TV"){
        res2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl2}`);
      }else if(categorySelection2 == "Monitor"){
        res2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl2}`);
      }
        setCategoryData2(res2.data.categoryList);
        setTopicData2(res2.data.topicList);
        setTopicDetailData2(res2.data.topicDetailList);
        setTopicTrendData2(res2.data.trendData);
        setCustomIndividualSentence2(res2.data.individualSentenceCN);
  
        setTopicOverviewIsLoading(false);
  
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setTopicDetail2IsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    }


  }




  if (modelSelection3.length != 0){

    setProgress(prevCount => prevCount + 5);
    
    // Topic Detail - Model 3
    setTopicOverviewIsLoading(true);

    let restGETurl3;
  
    if(categorySelection3 == "TV"){
  
      restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${"true"}${"&categoryBrand="}${"true"}${"&categoryCOI="}${"true"}${"&categoryDesign="}${"true"}${"&categoryHardware="}${"true"}${"&categoryOthers="}${"true"}${"&categoryPerformance="}${"true"}${"&categoryPicture="}${"true"}${"&categoryPrice="}${"true"}${"&categoryQA="}${"true"}${"&categorySound="}${"true"}${"&categoryUX="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }else if(categorySelection3 == "Monitor"){
  
      restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopic}${"&categoryExternalDevice="}${"true"}${"&categoryPQ="}${"true"}${"&categoryDesign="}${"true"}${"&categorySQ="}${"true"}${"&categoryScenario="}${"true"}${"&categoryQA="}${"true"}${"&categoryCOI="}${"true"}${"&categoryUX="}${"true"}${"&categoryPerformance="}${"true"}${"&topicSelection="}${"all"}${"&topicDetailSection="}${"all"}${"&filterSelection="}${"month"}`;
  
    }
  
  
    restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
    restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url
  
    let resp3;
    try {
  
      if(categorySelection3 == "TV"){
        resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl3}`);
      }else if(categorySelection3 == "Monitor"){
        resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl3}`);
      }
        setCategoryData3(resp3.data.categoryList);
        setTopicData3(resp3.data.topicList);
        setTopicDetailData3(resp3.data.topicDetailList);
        setTopicTrendData3(resp3.data.trendData);
        setCustomIndividualSentence3(resp3.data.individualSentenceCN);
  
        setTopicOverviewIsLoading(false);
  
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setTopicDetail2IsLoading(false);
      // setProgress(prevCount => prevCount + 20);
  
    }



  }





};





// Topic Overview - Topic Comparison List - Stack Bar Chart - Model 1
if(analyticOption == "sentiment"){
  var topicOverviewTopicComparisonDataSet1 = useMemo(() => {


    let data = {
      type: 'bar',
      labels: topicComparison1.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicComparison1.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicComparison1.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicComparison1.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  // }, [topicComparison1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);
}, [topicOverviewIsLoading]);


  
  var topicOverviewTopicComparisonOption1 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

    return data
  
  
  }, [topicOverviewIsLoading]);
  
  // Topic Overview - Topic Comparison List Table using react-data-table-component

  var topicOverviewTopicComparisonTableDataSet1 = useMemo(() => {

    let data = topicComparisonOriginal1.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);
    
    var topicOverviewTopicComparisonTableColumn1 = useMemo(() => {

      let data = [
        {
          name: 'Topic',
          selector: 'topic',
          sortable: true,
          center: true
        },
        {
          name: 'Positive',
          selector: 'positive',
          sortable: true,
          center: true
        },
        {
          name: 'Negative',
          selector: 'negative',
          sortable: true,
          center: true
        },
        {
          name: 'Neutral',
          selector: 'neutral',
          sortable: true,
          center: true
        },
        {
          name: 'Total',
          selector: 'total',
          sortable: true,
          center: true
        }
      ];
  
      return data

    }, [topicOverviewIsLoading]);
  

}


if(analyticOption == "star"){

  var topicOverviewTopicComparisonDataSet1 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicComparison1.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicComparison1.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicComparison1.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicComparison1.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicComparison1.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicComparison1.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);
  
  var topicOverviewTopicComparisonOption1 = useMemo(() => {
  
    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

    return data
  
  
  }, [topicOverviewIsLoading]);
  
  // Topic Overview - Topic Comparison List Table using react-data-table-component

  var topicOverviewTopicComparisonTableDataSet1 = useMemo(() => {

    let data = topicComparisonOriginal1.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);
  
  var topicOverviewTopicComparisonTableColumn1 = useMemo(() => {

    let data = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

    return data


  }, [topicOverviewIsLoading]);
  
  

}



// Topic Overview - Topic Comparison List - Stack Bar Chart - Model 2
if(analyticOption == "sentiment"){

  var topicOverviewTopicComparisonDataSet2 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicComparison2.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicComparison2.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicComparison2.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicComparison2.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);


  
  var topicOverviewTopicComparisonOption2 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

    return data
  
  
  }, [topicOverviewIsLoading]);
  
  // Topic Overview - Topic Comparison List Table using react-data-table-component


  var topicOverviewTopicComparisonTableDataSet2 = useMemo(() => {
    
    let data = topicComparisonOriginal2.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });


    return data


  }, [topicOverviewIsLoading]);
  
  
  var topicOverviewTopicComparisonTableColumn2 = useMemo(() => {

    let data = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

    return data

  }, [topicOverviewIsLoading]);


}


if(analyticOption == "star"){

  var topicOverviewTopicComparisonDataSet2 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicComparison2.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicComparison2.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicComparison2.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicComparison2.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicComparison2.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicComparison2.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);
  

  var topicOverviewTopicComparisonOption2 = useMemo(() => {
  
    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
    }

    return data
  
  }, [topicOverviewIsLoading]);

  
  // Topic Overview - Topic Comparison List Table using react-data-table-component

  var topicOverviewTopicComparisonTableDataSet2 = useMemo(() => {

    let data = topicComparisonOriginal2.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);

  var topicOverviewTopicComparisonTableColumn2 = useMemo(() => {


    let data = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];


    return data


  }, [topicOverviewIsLoading]);





}




// Topic Overview - Topic Comparison List - Stack Bar Chart - Model 3
if(analyticOption == "sentiment"){

  var topicOverviewTopicComparisonDataSet3 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicComparison3.map(data => data['Topic']),
      datasets: [
        {
          label: 'Positive',
          data: topicComparison3.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicComparison3.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicComparison3.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }


    return data
  
  }, [topicOverviewIsLoading]);


  
  var topicOverviewTopicComparisonOption3 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }


    return data
  
  
  }, [topicOverviewIsLoading]);
  
  // Topic Overview - Topic Comparison List Table using react-data-table-component
  
  var topicOverviewTopicComparisonTableDataSet3 = useMemo(() => {

    let data = topicComparisonOriginal3.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);


  var topicOverviewTopicComparisonTableColumn3 = useMemo(() => {

    let data = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

    return data
  }, [topicOverviewIsLoading]);


  

}


if(analyticOption == "star"){

  var topicOverviewTopicComparisonDataSet3 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicComparison3.map(data => data['Topic']),
      datasets: [
        {
          label: '5-Star',
          data: topicComparison3.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicComparison3.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicComparison3.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicComparison3.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicComparison3.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);
  
  var topicOverviewTopicComparisonOption3 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
    }

    return data
  
  }, [topicOverviewIsLoading]);
  
  // Topic Overview - Topic Comparison List Table using react-data-table-component
  
  var topicOverviewTopicComparisonTableDataSet3 = useMemo(() => {
    let data = topicComparisonOriginal3.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);

  var topicOverviewTopicComparisonTableColumn3 = useMemo(() => {

    let data = [
      {
        name: 'Topic',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

    return data


  }, [topicOverviewIsLoading]);

  

}


// Topic Detail Overview - Topic Comparison List - Stack Bar Chart - Model 1

let languageLabel1;

if(analyticOption == "sentiment"){

  if(topicLanguageOption == "chinese"){
    languageLabel1 = topicDetailComparison1.map(data => data['Topic.Detail'])
  }else{
    languageLabel1 = topicDetailComparison1.map(data => data['Translation_EN'])
  }

  var topicDetailOverviewTopicComparisonDataSet1 = useMemo(() => {

    let data = {
      type: 'bar',
      //labels: topicDetailComparison.map(data => data['Topic.Detail']),
      labels: languageLabel1,
      datasets: [
        {
          label: 'Positive',
          data: topicDetailComparison1.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicDetailComparison1.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicDetailComparison1.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);


  
  var topicDetailOverviewTopicComparisonOption1 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

    return data
  
  
  }, [topicOverviewIsLoading]);
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet1 = useMemo(() => {

    let data = topicDetailComparisonOriginal1.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);

  var topicDetailOverviewTopicComparisonTableColumn1 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topicEN',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];


    return data
  }, [topicOverviewIsLoading]);




}

let topicDetailComparisonLabel1;
if(topicLanguageOption == "chinese"){

  topicDetailComparisonLabel1 = topicDetailComparison1.map(data => data['Topic.Detail']);

}else{
  topicDetailComparisonLabel1 = topicDetailComparison1.map(data => data['Translation_EN']);
}

if(analyticOption == "star"){

  var topicDetailOverviewTopicComparisonDataSet1 = useMemo(() => {


    let data = {

          // labels: topicDetailComparison.map(data => data['Topic.Detail']),
    labels: topicDetailComparisonLabel1,
    datasets: [
      {
        label: '5-Star',
        data: topicDetailComparison1.map(data => data['5-Star']),
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '4-Star',
        data: topicDetailComparison1.map(data => data['4-Star']),
        fill: false,
        backgroundColor: 'rgb(36, 222, 96)',
        borderColor: 'rgba(36, 222, 96, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '3-Star',
        data: topicDetailComparison1.map(data => data['3-Star']),
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '2-Star',
        data: topicDetailComparison1.map(data => data['2-Star']),
        fill: false,
        backgroundColor: 'rgb(236, 50, 50)',
        borderColor: 'rgba(236, 50, 50, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: '1-Star',
        data: topicDetailComparison1.map(data => data['1-Star']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        stack: 'stack1'
      }
  
  
    ]
    }

    return data
  
  }, [topicOverviewIsLoading]);
  
  var topicDetailOverviewTopicComparisonOption1 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
    }

    return data
  
  }, [topicDetailComparison1]);
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet1 = useMemo(() => {

    let data = topicDetailComparisonOriginal1.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });


    return data

  }, [topicOverviewIsLoading]);

  
  var topicDetailOverviewTopicComparisonTableColumn1 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topicEN',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

    return data

  }, [topicOverviewIsLoading]);

 

}



// Topic Detail Overview - Topic Comparison List - Stack Bar Chart - Model 2

let languageLabel2;

if(analyticOption == "sentiment"){

  if(topicLanguageOption == "chinese"){
    languageLabel2 = topicDetailComparison2.map(data => data['Topic.Detail'])
  }else{
    languageLabel2 = topicDetailComparison2.map(data => data['Translation_EN'])
  }

  var topicDetailOverviewTopicComparisonDataSet2 = useMemo(() => {


    let data = {
          //labels: topicDetailComparison.map(data => data['Topic.Detail']),
    labels: languageLabel2,
    datasets: [
      {
        label: 'Positive',
        data: topicDetailComparison2.map(data => data['Positive']),
        fill: false,
        backgroundColor: 'rgb(26, 115, 55)',
        borderColor: 'rgba(26, 115, 55, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: 'Neutral',
        data: topicDetailComparison2.map(data => data['Neutral']),
        fill: false,
        backgroundColor: 'rgb(245, 200, 15)',
        borderColor: 'rgba(245, 200, 15, 0.6)',
        stack: 'stack1'
      },
  
      {
        label: 'Negative',
        data: topicDetailComparison2.map(data => data['Negative']),
        fill: false,
        backgroundColor: 'rgb(163, 30, 30)',
        borderColor: 'rgba(163, 30, 30, 0.6)',
        stack: 'stack1'
      }
  
  
    ]
    }

    return data
  
  }, [topicOverviewIsLoading]);


  
  var topicDetailOverviewTopicComparisonOption2 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

    return data
  
  
  }, [topicOverviewIsLoading]);
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet2 = useMemo(() => {

    let data = topicDetailComparisonOriginal2.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });

    return data


  }, [topicOverviewIsLoading]);


  var topicDetailOverviewTopicComparisonTableColumn2 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topicEN',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

    return data


  }, [topicOverviewIsLoading]);
    


}

let topicDetailComparisonLabel2;
if(topicLanguageOption == "chinese"){

  topicDetailComparisonLabel2 = topicDetailComparison2.map(data => data['Topic.Detail']);

}else{
  topicDetailComparisonLabel2 = topicDetailComparison2.map(data => data['Translation_EN']);
}

if(analyticOption == "star"){

  var topicDetailOverviewTopicComparisonDataSet2 = useMemo(() => {

    let data = {
      type: 'bar',
      // labels: topicDetailComparison.map(data => data['Topic.Detail']),
      labels: topicDetailComparisonLabel2,
      datasets: [
        {
          label: '5-Star',
          data: topicDetailComparison2.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicDetailComparison2.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicDetailComparison2.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicDetailComparison2.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicDetailComparison2.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);
  
  var topicDetailOverviewTopicComparisonOption2 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

    return data
  
  
  }, [topicDetailComparison2]);
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet2 = useMemo(() => {

    let data = topicDetailComparisonOriginal2.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);


  var topicDetailOverviewTopicComparisonTableColumn2 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topicEN',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];


    return data


  }, [topicOverviewIsLoading]);

  

}



// Topic Detail Overview - Topic Comparison List - Stack Bar Chart - Model 3

let languageLabel3;

if(analyticOption == "sentiment"){

  if(topicLanguageOption == "chinese"){
    languageLabel3 = topicDetailComparison3.map(data => data['Topic.Detail'])
  }else{
    languageLabel3 = topicDetailComparison3.map(data => data['Translation_EN'])
  }

  var topicDetailOverviewTopicComparisonDataSet3 = useMemo(() => {

    let data = {
      type: 'bar',
      //labels: topicDetailComparison.map(data => data['Topic.Detail']),
      labels: languageLabel3,
      datasets: [
        {
          label: 'Positive',
          data: topicDetailComparison3.map(data => data['Positive']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Neutral',
          data: topicDetailComparison3.map(data => data['Neutral']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: 'Negative',
          data: topicDetailComparison3.map(data => data['Negative']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);


  
  var topicDetailOverviewTopicComparisonOption3 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
  
    }

    return data
  
  }, [topicOverviewIsLoading]);
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet3 = useMemo(() => {

    let data = topicDetailComparisonOriginal3.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        positive: data['Positive'],
        negative: data['Negative'],
        neutral: data['Neutral'],
        total: data['Total']
      })
    });


    return data


  }, [topicOverviewIsLoading]);

  var topicDetailOverviewTopicComparisonTableColumn3 = useMemo(() => {



    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topicEN',
        sortable: true,
        center: true
      },
      {
        name: 'Positive',
        selector: 'positive',
        sortable: true,
        center: true
      },
      {
        name: 'Negative',
        selector: 'negative',
        sortable: true,
        center: true
      },
      {
        name: 'Neutral',
        selector: 'neutral',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];

    return data



  }, [topicOverviewIsLoading]);





}

let topicDetailComparisonLabel3;
if(topicLanguageOption == "chinese"){

  topicDetailComparisonLabel3 = topicDetailComparison3.map(data => data['Topic.Detail']);

}else{
  topicDetailComparisonLabel3 = topicDetailComparison3.map(data => data['Translation_EN']);
}

if(analyticOption == "star"){
  var topicDetailOverviewTopicComparisonDataSet3 = useMemo(() => {

    let data = {
      type: 'bar',
      // labels: topicDetailComparison.map(data => data['Topic.Detail']),
      labels: topicDetailComparisonLabel3,
      datasets: [
        {
          label: '5-Star',
          data: topicDetailComparison3.map(data => data['5-Star']),
          fill: false,
          backgroundColor: 'rgb(26, 115, 55)',
          borderColor: 'rgba(26, 115, 55, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '4-Star',
          data: topicDetailComparison3.map(data => data['4-Star']),
          fill: false,
          backgroundColor: 'rgb(36, 222, 96)',
          borderColor: 'rgba(36, 222, 96, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '3-Star',
          data: topicDetailComparison3.map(data => data['3-Star']),
          fill: false,
          backgroundColor: 'rgb(245, 200, 15)',
          borderColor: 'rgba(245, 200, 15, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '2-Star',
          data: topicDetailComparison3.map(data => data['2-Star']),
          fill: false,
          backgroundColor: 'rgb(236, 50, 50)',
          borderColor: 'rgba(236, 50, 50, 0.6)',
          stack: 'stack1'
        },
    
        {
          label: '1-Star',
          data: topicDetailComparison3.map(data => data['1-Star']),
          fill: false,
          backgroundColor: 'rgb(163, 30, 30)',
          borderColor: 'rgba(163, 30, 30, 0.6)',
          stack: 'stack1'
        }
    
    
      ]
    }

    return data
  
  }, [topicOverviewIsLoading]);
  
  var topicDetailOverviewTopicComparisonOption3 = useMemo(() => {
  

    let data = {
          // Drill Down
    onClick: (event, elements) => {
  
      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
  
      }
  
    },
  
  
    scales: {
      xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero:true
        }
      }],
      yAxes: [{
          stacked: true
      }]
  }
    }

    return data
  
  
  }, [topicOverviewIsLoading]);
  
  // Topic Detail Overview - Topic Comparison List Table using react-data-table-component
  
  var topicDetailOverviewTopicComparisonTableDataSet3 = useMemo(() => {

    let data = topicDetailComparisonOriginal3.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topicEN: data['Translation_EN'],
        fiveStar: data['5-Star'],
        fourStar: data['4-Star'],
        threeStar: data['3-Star'],
        twoStar: data['2-Star'],
        oneStar: data['1-Star'],
        total: data['Total']
      })
    });

    return data

  }, [topicOverviewIsLoading]);



  var topicDetailOverviewTopicComparisonTableColumn3 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topicEN',
        sortable: true,
        center: true
      },
      {
        name: '5-Star',
        selector: 'fiveStar',
        sortable: true,
        center: true
      },
      {
        name: '4-Star',
        selector: 'fourStar',
        sortable: true,
        center: true
      },
      {
        name: '3-Star',
        selector: 'threeStar',
        sortable: true,
        center: true
      },
      {
        name: '2-Star',
        selector: 'twoStar',
        sortable: true,
        center: true
      },
      {
        name: '1-Star',
        selector: 'oneStar',
        sortable: true,
        center: true
      },
      {
        name: 'Total',
        selector: 'total',
        sortable: true,
        center: true
      }
    ];


    return data


  }, [topicOverviewIsLoading]);
    


}


// Sentiment Overview - Positive Wordcloud - Model 1

const sentimentOverviewPositveWordsData1 = useMemo(() => {


  let data = topicDetailPositive1.map((data) => {

    if(topicLanguageOption == "chinese"){
    
      return({
        text: data['Topic.Detail'],
        value: data['Positive']
      })
    
    }else{
    
      return({
        text: data['Translation_EN'],
        value: data['Positive']
      })
    
    }
    
    
    })

    return data

  }, [topicOverviewIsLoading]);


  
  const sentimentOverviewPositveCallbacks1 = useMemo(() => {

    let data = {
      //onWordClick: console.log, // TODO: Add function for drill down
      getWordTooltip: (word) => `The word "${word.text}" appears ${word.value} times.`
    }

    return data
  }, [topicDetailPositive1, topicLanguageOption]);
  
  const sentimentOverviewPositveOptions1 = useMemo(() => {

    let data = {
      colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
      enableTooltip: true,
      deterministic: true,
      fontFamily: "impact",
      fontSizes: [10, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 0,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000
    }

    return data


}, [topicOverviewIsLoading]);
  
  // function sentimentOverviewPositveWordCloud1() {

  //   return <ReactWordcloud
  //     callbacks={sentimentOverviewPositveCallbacks1}
  //     options={sentimentOverviewPositveOptions1}
  //     words={sentimentOverviewPositveWordsData1} 
  //   />
  // }
  
  
  // Sentiment Overview - Positive Table using react-data-table-component
  
  var sentimentOverviewPositveTableDataSet1 = useMemo(() => {

    let data = topicDetailPositive1.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topic_en: data['Translation_EN'],
        freq: data['Positive']
      })
    });

  return data

}, [topicOverviewIsLoading]);
   
  
  var sentimentOverviewPositveTableColumn1 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topic_en',
        sortable: true,
        center: true
      },
      {
        name: 'Frequency',
        selector: 'freq',
        sortable: true,
        center: true
      }
    ];

    return data

  }, [topicOverviewIsLoading]);

  
  
  // Sentiment Overview - Negative Wordcloud - Model 1

  const sentimentOverviewNegativeDataWords1 = useMemo(() => {


    let data = topicDetailNegative1.map((data) => {
  
      if(topicLanguageOption == "chinese"){
    
        return({
          text: data['Topic.Detail'],
          value: data['Negative']
        })
    
      }else{
    
        return({
          text: data['Translation_EN'],
          value: data['Negative']
        })
    
      }
      
    
    })

    return data



  }, [topicOverviewIsLoading]);
  
  
  const sentimentOverviewNegativeDataCallbacks1 = useMemo(() => {

    let data = {
      //onWordClick: console.log, // TODO: Add function for drill down
      getWordTooltip: (word) => `The word "${word.text}" appears ${word.value} times.`,
    }

    return data


  }, [topicOverviewIsLoading]);

  
  var sentimentOverviewNegativeDataOptions1 = useMemo(() => {

    let data = {
      //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
      colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
      enableTooltip: true,
      deterministic: true,
      fontFamily: "impact",
      fontSizes: [10, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 0,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000
      }

    return data


  }, [topicOverviewIsLoading]);
  
  function sentimentOverviewNegativeWordCloud1() {
    return <ReactWordcloud
      callbacks={sentimentOverviewNegativeDataCallbacks1}
      options={sentimentOverviewNegativeDataOptions1}
      words={sentimentOverviewNegativeDataWords1} 
    />
  }
  
  
  // Sentiment Overview - Negative Table using react-data-table-component

  var sentimentOverviewNegativeDataTableDataSet1 = useMemo(() => {

    let data = topicDetailNegative1.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topic_en: data['Translation_EN'],
        freq: data['Negative']
      })
    });

    return data


  }, [topicOverviewIsLoading]);
  

  var sentimentOverviewNegativeDataTableColumn1 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topic_en',
        sortable: true,
        center: true
      },
      {
        name: 'Frequency',
        selector: 'freq',
        sortable: true,
        center: true
      }
    ];

    return data


  }, [topicOverviewIsLoading]);



  // Sentiment Overview - Positive Wordcloud - Model 2

var sentimentOverviewPositveWordsData2 = useMemo(() => {

  let data = topicDetailPositive2.map((data) => {

    if(topicLanguageOption == "chinese"){
    
      return({
        text: data['Topic.Detail'],
        value: data['Positive']
      })
    
    }else{
    
      return({
        text: data['Translation_EN'],
        value: data['Positive']
      })
    
    }
    
    
    })

    return data

  }, [topicOverviewIsLoading]);

  
  var sentimentOverviewPositveCallbacks2 = useMemo(() => {

    let data = {
      //onWordClick: console.log, // TODO: Add function for drill down
      getWordTooltip: (word) => `The word "${word.text}" appears ${word.value} times.`,
    }

    return data

  }, [topicOverviewIsLoading]);
  
  var sentimentOverviewPositveOptions2 = useMemo(() => {

    let data = {
      colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
      enableTooltip: true,
      deterministic: true,
      fontFamily: "impact",
      fontSizes: [10, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 0,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000
    }
    return data
  }, [topicOverviewIsLoading]);
  
  // function sentimentOverviewPositveWordCloud2() {
  //   return <ReactWordcloud
  //     callbacks={sentimentOverviewPositveCallbacks2}
  //     options={sentimentOverviewPositveOptions2}
  //     words={sentimentOverviewPositveWordsData2} 
  //   />
  // }
  
  
  // Sentiment Overview - Positive Table using react-data-table-component
  
  var sentimentOverviewPositveTableDataSet2 = useMemo(() => {

    let data = topicDetailPositive2.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topic_en: data['Translation_EN'],
        freq: data['Positive']
      })
    });

    return data


  }, [topicOverviewIsLoading]);


  var sentimentOverviewPositveTableColumn2 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topic_en',
        sortable: true,
        center: true
      },
      {
        name: 'Frequency',
        selector: 'freq',
        sortable: true,
        center: true
      }
    ];

    return data


  }, [topicOverviewIsLoading]);


  
  
  // Sentiment Overview - Negative Wordcloud - Model 2
  
  var sentimentOverviewNegativeDataWords2 = useMemo(() => {

    let data = topicDetailNegative2.map((data) => {
  
      if(topicLanguageOption == "chinese"){
    
        return({
          text: data['Topic.Detail'],
          value: data['Negative']
        })
    
      }else{
    
        return({
          text: data['Translation_EN'],
          value: data['Negative']
        })
    
      }
      
    
    })

    return data

  }, [topicOverviewIsLoading]);

  
  var sentimentOverviewNegativeDataCallbacks2 = useMemo(() => {

    let data = {
      //onWordClick: console.log, // TODO: Add function for drill down
      getWordTooltip: (word) => `The word "${word.text}" appears ${word.value} times.`,
    }

    return data


  }, [topicOverviewIsLoading]);
  
  const sentimentOverviewNegativeDataOptions2 = useMemo(() => {

    let data = {
      //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
      colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
      enableTooltip: true,
      deterministic: true,
      fontFamily: "impact",
      fontSizes: [10, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 0,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000
      }

    return data

  }, [topicOverviewIsLoading]);
  
  function sentimentOverviewNegativeWordCloud2() {
    return <ReactWordcloud
      callbacks={sentimentOverviewNegativeDataCallbacks2}
      options={sentimentOverviewNegativeDataOptions2}
      words={sentimentOverviewNegativeDataWords2} 
    />
  }
  
  
  // Sentiment Overview - Negative Table using react-data-table-component

  var sentimentOverviewNegativeDataTableDataSet2 = useMemo(() => {

    let data = topicDetailNegative2.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topic_en: data['Translation_EN'],
        freq: data['Negative']
      })
    });

    return data

  }, [topicOverviewIsLoading]);
  
  
  var sentimentOverviewNegativeDataTableColumn2 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topic_en',
        sortable: true,
        center: true
      },
      {
        name: 'Frequency',
        selector: 'freq',
        sortable: true,
        center: true
      }
    ];

    return data


  }, [topicOverviewIsLoading]);




 // Sentiment Overview - Positive Wordcloud - Model 3

var sentimentOverviewPositveWordsData3 = useMemo(() => {

  let data = topicDetailPositive3.map((data) => {

    if(topicLanguageOption == "chinese"){
    
      return({
        text: data['Topic.Detail'],
        value: data['Positive']
      })
    
    }else{
    
      return({
        text: data['Translation_EN'],
        value: data['Positive']
      })
    
    }
    
    
    })

    return data


  }, [topicOverviewIsLoading]);


  
  const sentimentOverviewPositveCallbacks3 = useMemo(() => {

    let data = {
      //onWordClick: console.log, // TODO: Add function for drill down
      getWordTooltip: (word) => `The word "${word.text}" appears ${word.value} times.`,
    }
    return data
  }, [topicDetailPositive3, topicLanguageOption, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

  
  const sentimentOverviewPositveOptions3 = useMemo(() => {

    let data = {
      colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
      enableTooltip: true,
      deterministic: true,
      fontFamily: "impact",
      fontSizes: [10, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 0,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000
    }

    return data


  }, [topicOverviewIsLoading]);
  
  // function sentimentOverviewPositveWordCloud3() {
  //   return <ReactWordcloud
  //     callbacks={sentimentOverviewPositveCallbacks3}
  //     options={sentimentOverviewPositveOptions3}
  //     words={sentimentOverviewPositveWordsData3} 
  //   />
  // }
  
  
  // Sentiment Overview - Positive Table using react-data-table-component
  
  var sentimentOverviewPositveTableDataSet3 = useMemo(() => {

    let data = topicDetailPositive3.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topic_en: data['Translation_EN'],
        freq: data['Positive']
      })
    });

    return data


  }, [topicOverviewIsLoading]);


  var sentimentOverviewPositveTableColumn3 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topic_en',
        sortable: true,
        center: true
      },
      {
        name: 'Frequency',
        selector: 'freq',
        sortable: true,
        center: true
      }
    ];

    return data

  }, [topicOverviewIsLoading]);

  
  
  // Sentiment Overview - Negative Wordcloud - Model 3
  
  var sentimentOverviewNegativeDataWords3 = useMemo(() => {

    let data = topicDetailNegative3.map((data) => {
  
      if(topicLanguageOption == "chinese"){
    
        return({
          text: data['Topic.Detail'],
          value: data['Negative']
        })
    
      }else{
    
        return({
          text: data['Translation_EN'],
          value: data['Negative']
        })
    
      }
    
    })

    return data


  }, [topicOverviewIsLoading]);


  
  var sentimentOverviewNegativeDataCallbacks3 = useMemo(() => {

    let data = {
      //onWordClick: console.log, // TODO: Add function for drill down
      getWordTooltip: (word) => `The word "${word.text}" appears ${word.value} times.`,
    }

    return data

  }, [topicDetailNegative3, topicLanguageOption]);
  
  var sentimentOverviewNegativeDataOptions3 = useMemo(() => {

    let data = {
      //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
      colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
      enableTooltip: true,
      deterministic: true,
      fontFamily: "impact",
      fontSizes: [10, 60],
      fontStyle: "normal",
      fontWeight: "normal",
      padding: 0,
      rotations: 1,
      rotationAngles: [0, 0],
      scale: "sqrt",
      spiral: "archimedean",
      transitionDuration: 1000
    }

    return data


  }, [topicOverviewIsLoading]);
  
  function sentimentOverviewNegativeWordCloud3() {
    return <ReactWordcloud
      callbacks={sentimentOverviewNegativeDataCallbacks3}
      options={sentimentOverviewNegativeDataOptions3}
      words={sentimentOverviewNegativeDataWords3} 
    />
  }
  
  
  // Sentiment Overview - Negative Table using react-data-table-component
  
  var sentimentOverviewNegativeDataTableDataSet3 = useMemo(() => {

    let data = topicDetailNegative3.map((data) => {
      return ({
        id: data.id,
        topic: data['Topic.Detail'],
        topic_en: data['Translation_EN'],
        freq: data['Negative']
      })
    });

    return data


  }, [topicOverviewIsLoading]);

  
  var sentimentOverviewNegativeDataTableColumn3 = useMemo(() => {

    let data = [
      {
        name: 'Topic Detail',
        selector: 'topic',
        sortable: true,
        center: true
      },
      {
        name: 'Topic Detail - EN',
        selector: 'topic_en',
        sortable: true,
        center: true
      },
      {
        name: 'Frequency',
        selector: 'freq',
        sortable: true,
        center: true
      }
    ];

    return data

  }, [topicOverviewIsLoading]);




  const handleTopic = (event, values) => {
    setTopicMenuSelection(values);

    // Set Topic Detail Menu list base user selection for Topic Menu
    let topicList = [];
    topicList = values;

    let topicDetailList = topicAndTopicDetailMenuList.filter((data => topicList.includes(data.Topic)));
  
    setTopicDetailMenuList(topicDetailList.map(data => data["Topic.Detail"]));
    setTopicDetailMenuSelection(topicDetailList.map(data => data["Topic.Detail"]));


  };

  const handleTopicDetail = (event, values) => {
    setTopicDetailMenuSelection(values);
    //setTopicMenuSelection([]);
  };


  // React.useEffect(() => {

  //   let topicDetailList = topicAndTopicDetailMenuList.filter((data => topicMenuSelection.includes(data.Topic)));
  //   let distinctTopicDetailList = [...new Set(topicDetailList)]
  //   console.log(distinctTopicDetailList);

    

  //   //setTopicDetailMenuList(topicDetailList.map(data => data["Topic.Detail"]));
  //   //setTopicDetailMenuSelection(topicDetailList.map(data => data["Topic.Detail"]));


  // }, [topicMenuSelection])



  const getIndividualSentenceData = async () => {

    // Individual Sentence - Model 1
    setViewIndividualSentenceIsLoading1(true);
  
    let restGETurl1;
  
    restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}`;
  
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
    try {
  
      let resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].rawAndAnalyzedData}${restGETurl1}`);
      
        setRawData1(resp.data.rawData);
        setAnalyzedData1(resp.data.analyzedData);
  
        setViewIndividualSentenceIsLoading1(false);
        setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setViewIndividualSentenceIsLoading1(false);
      // setProgress(prevCount => prevCount + 20);
  
    }



    // Individual Sentence - Model 2
    setViewIndividualSentenceIsLoading2(true);

    let restGETurl2;
  
    restGETurl2 = `${"brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}`;
  
    restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
    restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url
  
    try {
  
      let resp2 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].rawAndAnalyzedData}${restGETurl2}`);
      
        setRawData2(resp2.data.rawData);
        setAnalyzedData2(resp2.data.analyzedData);
  
        setViewIndividualSentenceIsLoading2(false);
        // setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setViewIndividualSentenceIsLoading2(false);
      // setProgress(prevCount => prevCount + 20);
  
    }



    // Individual Sentence - Model 3
    setViewIndividualSentenceIsLoading3(true);

    let restGETurl3;
  
    restGETurl3 = `${"brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}`;
  
    restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
    restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url
  
    try {
  
      let resp3 = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].rawAndAnalyzedData}${restGETurl3}`);
      
        setRawData3(resp3.data.rawData);
        setAnalyzedData3(resp3.data.analyzedData);
  
        setViewIndividualSentenceIsLoading3(false);
        // setProgress(prevCount => prevCount + 20);
  
    } catch (err) {
      // Handle Error Here
      console.error(err);
  
      setViewIndividualSentenceIsLoading3(false);
      // setProgress(prevCount => prevCount + 20);
  
    }
  
  
  };



  // Individual Sentence - Model 1

var viewIndividualSentenceTableColumn1 = useMemo(() => {

  let data = [
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Website',
      field: 'website'
    },
    {
      title: 'Country',
      field: 'country'
    },
    {
      title: 'ReviewerID',
      field: 'reviewerId'
    },
    {
      title: 'Brand',
      field: 'brand'
    },
    {
      title: 'Model',
      field: 'model'
    },
    {
      title: 'Stars',
      field: 'stars'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Topic',
      field: 'topic'
    },
    {
      title: 'Topic Detail - CN',
      field: 'topicDetailCn'
    },
    {
      title: 'Topic Detail - EN',
      field: 'topicDetailEn'
    },
    {
      title: 'Full Review - CN',
      field: 'fullReviewCn',
      cellStyle: {
        width: 500,
        minWidth: 500
      },
      headerStyle: {
        width: 500,
        minWidth: 500
      }
    },
    {
      title: 'Individual Sentence - CN',
      field: 'individualSentenceCn',
      cellStyle: {
        width: 500,
        minWidth: 500
      },
      headerStyle: {
        width: 500,
        minWidth: 500
      }
    },
    {
      title: 'Sentiment Result',
      field: 'sentimentResult'
    },
    {
      title: 'PosList - CN',
      field: 'posListCn'
    },
    {
      title: 'NegList - CN',
      field: 'negListCn'
    },
    {
      title: 'Full Review - EN',
      field: 'fullReviewEn',
      cellStyle: {
        width: 500,
        minWidth: 500
      },
      headerStyle: {
        width: 500,
        minWidth: 500
      }
    },
    {
      title: 'Individual Sentence - En',
      field: 'individualSentenceEn',
      cellStyle: {
        width: 500,
        minWidth: 500
      },
      headerStyle: {
        width: 500,
        minWidth: 500
      }
    },
    {
      title: 'PosList - EN',
      field: 'posListEn'
    },
    {
      title: 'NegList - EN',
      field: 'negListEn'
    }
  
  ];

  return data

  
}, [analyzedData1]);

var viewIndividualSentenceDataTableDataSet1 = useMemo(() => {

  
let data = analyzedData1.map((data) => {
  return ({
    id: data.id,
    date: data['Date'],
    website: data['Website'],
    country: data['Country'],
    reviewerId: data['Reviewer_ID'],
    brand: data['Brand'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    topicDetailCn: data['Topic.Detail_CN'],
    topicDetailEn: data['Topic.Detail_EN'],
    fullReviewCn: data['Full.Review_CN'],
    individualSentenceCn: data['Individual.Sentence_CN'],
    sentimentResult: data['Sentiment.Result'],
    posListCn: data['Pos_List_CN'],
    negListCn: data['Neg_List_CN'],
    fullReviewEn: data['Full.Review_EN'],
    individualSentenceEn: data['Individual.Sentence_EN'],
    posListEn: data['Pos_List_EN'],
    negListEn: data['Neg_List_EN']
  })
});

return data


}, [analyzedData1]);



  // Individual Sentence - Model 2

  var viewIndividualSentenceTableColumn2 = useMemo(() => {

    let data = [
      {
        title: 'Date',
        field: 'date'
      },
      {
        title: 'Website',
        field: 'website'
      },
      {
        title: 'Country',
        field: 'country'
      },
      {
        title: 'ReviewerID',
        field: 'reviewerId'
      },
      {
        title: 'Brand',
        field: 'brand'
      },
      {
        title: 'Model',
        field: 'model'
      },
      {
        title: 'Stars',
        field: 'stars'
      },
      {
        title: 'Category',
        field: 'category'
      },
      {
        title: 'Topic',
        field: 'topic'
      },
      {
        title: 'Topic Detail - CN',
        field: 'topicDetailCn'
      },
      {
        title: 'Topic Detail - EN',
        field: 'topicDetailEn'
      },
      {
        title: 'Full Review - CN',
        field: 'fullReviewCn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'Individual Sentence - CN',
        field: 'individualSentenceCn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'Sentiment Result',
        field: 'sentimentResult'
      },
      {
        title: 'PosList - CN',
        field: 'posListCn'
      },
      {
        title: 'NegList - CN',
        field: 'negListCn'
      },
      {
        title: 'Full Review - EN',
        field: 'fullReviewEn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'Individual Sentence - En',
        field: 'individualSentenceEn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'PosList - EN',
        field: 'posListEn'
      },
      {
        title: 'NegList - EN',
        field: 'negListEn'
      }
    
    ];

    return data


  }, [analyzedData2]);

  
  var viewIndividualSentenceDataTableDataSet2 = useMemo(() => {

    let data = analyzedData2.map((data) => {
      return ({
        id: data.id,
        date: data['Date'],
        website: data['Website'],
        country: data['Country'],
        reviewerId: data['Reviewer_ID'],
        brand: data['Brand'],
        model: data['Model'],
        stars: data['Stars'],
        category: data['Category'],
        topic: data['Topic'],
        topicDetailCn: data['Topic.Detail_CN'],
        topicDetailEn: data['Topic.Detail_EN'],
        fullReviewCn: data['Full.Review_CN'],
        individualSentenceCn: data['Individual.Sentence_CN'],
        sentimentResult: data['Sentiment.Result'],
        posListCn: data['Pos_List_CN'],
        negListCn: data['Neg_List_CN'],
        fullReviewEn: data['Full.Review_EN'],
        individualSentenceEn: data['Individual.Sentence_EN'],
        posListEn: data['Pos_List_EN'],
        negListEn: data['Neg_List_EN']
      })
    });

    return data


  }, [analyzedData2]);




  // Individual Sentence - Model 3

  var viewIndividualSentenceTableColumn3 = useMemo(() => {

    let data = [
      {
        title: 'Date',
        field: 'date'
      },
      {
        title: 'Website',
        field: 'website'
      },
      {
        title: 'Country',
        field: 'country'
      },
      {
        title: 'ReviewerID',
        field: 'reviewerId'
      },
      {
        title: 'Brand',
        field: 'brand'
      },
      {
        title: 'Model',
        field: 'model'
      },
      {
        title: 'Stars',
        field: 'stars'
      },
      {
        title: 'Category',
        field: 'category'
      },
      {
        title: 'Topic',
        field: 'topic'
      },
      {
        title: 'Topic Detail - CN',
        field: 'topicDetailCn'
      },
      {
        title: 'Topic Detail - EN',
        field: 'topicDetailEn'
      },
      {
        title: 'Full Review - CN',
        field: 'fullReviewCn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'Individual Sentence - CN',
        field: 'individualSentenceCn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'Sentiment Result',
        field: 'sentimentResult'
      },
      {
        title: 'PosList - CN',
        field: 'posListCn'
      },
      {
        title: 'NegList - CN',
        field: 'negListCn'
      },
      {
        title: 'Full Review - EN',
        field: 'fullReviewEn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'Individual Sentence - En',
        field: 'individualSentenceEn',
        cellStyle: {
          width: 500,
          minWidth: 500
        },
        headerStyle: {
          width: 500,
          minWidth: 500
        }
      },
      {
        title: 'PosList - EN',
        field: 'posListEn'
      },
      {
        title: 'NegList - EN',
        field: 'negListEn'
      }
    
    ];

    return data


  }, [analyzedData3]);



var viewIndividualSentenceDataTableDataSet3 = useMemo(() => {
  
  let data = analyzedData3.map((data) => {
    return ({
      id: data.id,
      date: data['Date'],
      website: data['Website'],
      country: data['Country'],
      reviewerId: data['Reviewer_ID'],
      brand: data['Brand'],
      model: data['Model'],
      stars: data['Stars'],
      category: data['Category'],
      topic: data['Topic'],
      topicDetailCn: data['Topic.Detail_CN'],
      topicDetailEn: data['Topic.Detail_EN'],
      fullReviewCn: data['Full.Review_CN'],
      individualSentenceCn: data['Individual.Sentence_CN'],
      sentimentResult: data['Sentiment.Result'],
      posListCn: data['Pos_List_CN'],
      negListCn: data['Neg_List_CN'],
      fullReviewEn: data['Full.Review_EN'],
      individualSentenceEn: data['Individual.Sentence_EN'],
      posListEn: data['Pos_List_EN'],
      negListEn: data['Neg_List_EN']
    })
  });


  return data


}, [analyzedData3]);






// Topic Detail - Topic Horizontal Bar - Model 1

var topicDetailTopicDataSet1 = useMemo(() => {

  let data = {

    type: 'bar',
    labels: topicData1.map(data => data['Topic']),
    datasets: [
      {
        label: 'Count',
        data: topicData1.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(122, 48, 108)',
        borderColor: 'rgba(122, 48, 108, 0.6)'
      }
  
    ]

  }

  return data

}, [topicData1, topicDetailData1, topicTrendData1]);



var topicDetailTopicOption1 = useMemo(() => {


  let data = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
      }
    },

    options: {
      responsive: true,
      maintainAspectRatio: false
    },

    scales: {
      xAxes: [{
        ticks: {
            beginAtZero:true
        }
      }]
    }

    }

  return data

}, [topicData1]);

// Topic Detail - Topic List Table using react-data-table-component - Model 1

var topicDetailTopicTableDataSet1 = useMemo(() => {

  let data = topicData1.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      total: data['Total']
    })
  });

  return data


}, [topicData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);

var topicDetailTopicTableColumn1 = useMemo(() => {

  let data = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

  return data


}, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);
  



// Topic Detail - Topic Detail Horizontal Bar - Model 1

var topicDetailTopicDetailDataSet1 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: topicDetailData1.map(data => data['Topic.Detail']),
    datasets: [
      {
        label: 'Count',
        data: topicDetailData1.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(3, 181, 170)',
        borderColor: 'rgba(3, 181, 170, 0.6)'
      }
  
    ]
  }

  return data

}, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);



var topicDetailTopicDetailOption1 = useMemo(() => {


  let data = {
    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);
      }
    },

    options: {
      responsive: true,
      maintainAspectRatio: false
    },

    scales: {
      xAxes: [{
        ticks: {
            beginAtZero:true
        }
      }]
    }

    }

    return data

}, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);

// Topic Detail - Topic Detail Table using react-data-table-component - Model 1

var topicDetailTopicDetailTableDataSet1 = useMemo(() => {

  let data = topicDetailData1.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic.Detail'],
      total: data['Total']
    })
  });

  return data


}, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);


var topicDetailTopicDetailTableColumn1 = useMemo(() => {

  let data = [
    {
      name: 'Topic.Detail',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

  return data
  


}, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);




// Topic Detail - View Individual Sentence - Table using material-table - Model 1

var topicDetailIndividualSentenceDataTableColumn1 = useMemo(() => {

  let data = [
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Brand',
      field: 'brand'
    },
    {
      title: 'Model',
      field: 'model'
    },
    {
      title: 'Stars',
      field: 'stars'
    },
    {
      title: 'Sentiment',
      field: 'sentiment'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Topic',
      field: 'topic'
    },
    {
      title: 'Topic Detail',
      field: 'topicDetail'
    },
    {
      title: 'Individual Sentence',
      field: 'individualSentence',
      cellStyle: {
        width: 600,
        minWidth: 600
      },
      headerStyle: {
        width: 600,
        minWidth: 600
      }
    }
  ];

  return data

}, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);


var topicDetailIndividualSentenceDataTableDataSet1 = useMemo(() => {

  let data = customIndividualSentence1.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      brand: data['Brand'],
      model: data['Model'],
      stars: data['Stars'],
      sentiment: data['Sentiment'],
      category: data['Category'],
      topic: data['Topic'],
      topicDetail: data['Topic.Detail'],
      individualSentence: data['Individual Sentence']
    })
  });

  return data


}, [topicData1, topicDetailData1, topicTrendData1, customIndividualSentence1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);



// Trend by Category / Topic / Topic Detail - Stack Bar Chart - Model 1

var trendByCategoryTopicTopicDetailBarDataSet1;
var trendByCategoryTopicTopicDetailBarOption1;
var trendByCategoryTopicTopicDetailTableDataSet1;
var trendByCategoryTopicTopicDetailTableColumn1;

if(topicDetailTrendRatingFilter == 'star'){

  trendByCategoryTopicTopicDetailBarDataSet1 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicTrendData1.map(data => data['Date']),
      datasets: [
    
        {
          label: "5-Star",
          data: topicTrendData1.map(data => data['5-Star']),
          borderColor: 'rgb(26, 115, 55)', // Line Color
          backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
        },
        {
          label: "4-Star",
          data: topicTrendData1.map(data => data['4-Star']),
          borderColor: 'rgb(36, 222, 96)', // Line Color
          backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
        },
        {
          label: "3-Star",
          data: topicTrendData1.map(data => data['3-Star']),
          borderColor: 'rgb(245, 200, 15)', // Line Color
          backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
        },
        {
          label: "2-Star",
          data: topicTrendData1.map(data => data['2-Star']),
          borderColor: 'rgb(236, 50, 50)', // Line Color
          backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
        }
        ,
        {
          label: "1-Star",
          data: topicTrendData1.map(data => data['1-Star']),
          borderColor: 'rgb(163, 30, 30)', // Line Color
          backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
        }
        
      ]
    }

    return data

    }, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);
  
  
    trendByCategoryTopicTopicDetailBarOption1 = useMemo(() => {
  

      let data = {
              // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
            offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
      }

      return data
    
  
    }, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);
  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component  - Model 1
  
  trendByCategoryTopicTopicDetailTableDataSet1 = useMemo(() => {

    let data = topicTrendData1.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        category: data['Date'],
        fiveStar: data['5-Star'], 
        fourStar: data['4-Star'], 
        threeStar: data['3-Star'], 
        twoStar: data['2-Star'], 
        oneStar: data['1-Star'],
        total: data['Total']
    
      })
    });

    return data

  }, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);

  
  
  trendByCategoryTopicTopicDetailTableColumn1 = useMemo(() => {

    let data = [
      {
        name: 'Category',
        selector: 'category',
        sortable: true,
        center: true
      },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
    ];

    return data

  }, [topicData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);


  
}else{
  // Sentiment Selection

  trendByCategoryTopicTopicDetailBarDataSet1 = useMemo(() => {


    let data = {
      type: 'bar',
      labels: topicTrendData1.map(data => data['Date']),
      datasets: [
    
        {
          label: "Positive",
          data: topicTrendData1.map(data => data['Positive']),
          borderColor: 'rgb(26, 115, 55)', // Line Color
          backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
        },
        {
          label: "Neutral",
          data: topicTrendData1.map(data => data['Neutral']),
          borderColor: 'rgb(245, 200, 15)', // Line Color
          backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
        },
        {
          label: "Negative",
          data: topicTrendData1.map(data => data['Negative']),
          borderColor: 'rgb(163, 30, 30)', // Line Color
          backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
        }
        
      ]
    }

    return data

    }, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);
  
  
    trendByCategoryTopicTopicDetailBarOption1 = useMemo(() => {
  

      let data = {
              // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
          offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
      }

      return data
    
  
    }, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);
  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component - Model 1
  
  trendByCategoryTopicTopicDetailTableDataSet1 = useMemo(() => {

    let data = topicTrendData1.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        date: data['Date'],
        positive: data['Positive'], 
        neutral: data['Neutral'], 
        negative: data['Negative'], 
        total: data['Total']
    
      })
    });

    return data

  }, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);
  
  
  trendByCategoryTopicTopicDetailTableColumn1 = useMemo(() => {

    let data = [
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
        center: true
      },
    {
      name: 'Positive',
      selector: 'positive',
      sortable: true,
      center: true
    },
    {
      name: 'Neutral',
      selector: 'neutral',
      sortable: true,
      center: true
    },
    {
      name: 'Negative',
      selector: 'negative',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
    ];

    return data


  }, [topicData1, topicDetailData1, topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);




}

  

// Topic Detail - Topic Horizontal Bar - Model 2

var topicDetailTopicDataSet2 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: topicData2.map(data => data['Topic']),
    datasets: [
      {
        label: 'Count',
        data: topicData2.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(122, 48, 108)',
        borderColor: 'rgba(122, 48, 108, 0.6)'
      }
  
    ]
  }

  return data

}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);



var topicDetailTopicOption2 = useMemo(() => {


  let data = {
      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);
    }
  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  scales: {
    xAxes: [{
      ticks: {
          beginAtZero:true
      }
    }]
  }

  }
  return data
}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);

// Topic Detail - Topic List Table using react-data-table-component - Model 2
var topicDetailTopicTableDataSet2 = useMemo(() => {

  let data = topicData2.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      total: data['Total']
    })
  });

  return data

}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);


var topicDetailTopicTableColumn2 = useMemo(() => {

  let data = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

  return data

}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);  



// Topic Detail - Topic Detail Horizontal Bar - Model 2

var topicDetailTopicDetailDataSet2 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: topicDetailData2.map(data => data['Topic.Detail']),
    datasets: [
      {
        label: 'Count',
        data: topicDetailData2.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(3, 181, 170)',
        borderColor: 'rgba(3, 181, 170, 0.6)'
      }
  
    ]
  }

  return data

}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);



var topicDetailTopicDetailOption2 = useMemo(() => {


  let data = {
      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);
    }
  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  scales: {
    xAxes: [{
      ticks: {
          beginAtZero:true
      }
    }]
  }
  }

  return data

}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);

// Topic Detail - Topic Detail Table using react-data-table-component - Model 2

var topicDetailTopicDetailTableDataSet2 = useMemo(() => {

  let data = topicDetailData2.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic.Detail'],
      total: data['Total']
    })
  });

  return data

}, [topicData2]);

var topicDetailTopicDetailTableColumn2 = useMemo(() => {

  let data = [
    {
      name: 'Topic.Detail',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

  return data

}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);



// Topic Detail - View Individual Sentence - Table using material-table - Model 2

const topicDetailIndividualSentenceDataTableColumn2 = useMemo(() => {

  let data = [
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Brand',
      field: 'brand'
    },
    {
      title: 'Model',
      field: 'model'
    },
    {
      title: 'Stars',
      field: 'stars'
    },
    {
      title: 'Sentiment',
      field: 'sentiment'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Topic',
      field: 'topic'
    },
    {
      title: 'Topic Detail',
      field: 'topicDetail'
    },
    {
      title: 'Individual Sentence',
      field: 'individualSentence',
      cellStyle: {
        width: 600,
        minWidth: 600
      },
      headerStyle: {
        width: 600,
        minWidth: 600
      }
    }
  ];

  return data

}, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);


var topicDetailIndividualSentenceDataTableDataSet2 = useMemo(() => {

  let data = customIndividualSentence2.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      brand: data['Brand'],
      model: data['Model'],
      stars: data['Stars'],
      sentiment: data['Sentiment'],
      category: data['Category'],
      topic: data['Topic'],
      topicDetail: data['Topic.Detail'],
      individualSentence: data['Individual Sentence']
    })
  });

  return data

}, [topicData2, topicDetailData2, topicTrendData2, customIndividualSentence2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);




// Trend by Category / Topic / Topic Detail - Stack Bar Chart - Model 2

var trendByCategoryTopicTopicDetailBarDataSet2;
var trendByCategoryTopicTopicDetailBarOption2;
var trendByCategoryTopicTopicDetailTableDataSet2;
var trendByCategoryTopicTopicDetailTableColumn2;

if(topicDetailTrendRatingFilter == 'star'){

  trendByCategoryTopicTopicDetailBarDataSet2 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicTrendData2.map(data => data['Date']),
      datasets: [
    
        {
          label: "5-Star",
          data: topicTrendData2.map(data => data['5-Star']),
          borderColor: 'rgb(26, 115, 55)', // Line Color
          backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
        },
        {
          label: "4-Star",
          data: topicTrendData2.map(data => data['4-Star']),
          borderColor: 'rgb(36, 222, 96)', // Line Color
          backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
        },
        {
          label: "3-Star",
          data: topicTrendData2.map(data => data['3-Star']),
          borderColor: 'rgb(245, 200, 15)', // Line Color
          backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
        },
        {
          label: "2-Star",
          data: topicTrendData2.map(data => data['2-Star']),
          borderColor: 'rgb(236, 50, 50)', // Line Color
          backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
        }
        ,
        {
          label: "1-Star",
          data: topicTrendData2.map(data => data['1-Star']),
          borderColor: 'rgb(163, 30, 30)', // Line Color
          backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
        }
        
      ]
    }

    return data
    }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);
  
  
    trendByCategoryTopicTopicDetailBarOption2 = useMemo(() => {
  

      let data = {

              // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
            offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
      }

      return data
    
  
    }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);
  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component  - Model 1
  
  trendByCategoryTopicTopicDetailTableDataSet2 = useMemo(() => {

    let data = topicTrendData2.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        category: data['Date'],
        fiveStar: data['5-Star'], 
        fourStar: data['4-Star'], 
        threeStar: data['3-Star'], 
        twoStar: data['2-Star'], 
        oneStar: data['1-Star'],
        total: data['Total']
    
      })
    });

    return data

  }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);


  
  trendByCategoryTopicTopicDetailTableColumn2 = useMemo(() => {

    let data = [
      {
        name: 'Category',
        selector: 'category',
        sortable: true,
        center: true
      },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
    ];

    return data

  }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);


  
}else{
  // Sentiment Selection

  trendByCategoryTopicTopicDetailBarDataSet2 = useMemo(() => {


    let data = {
      type: 'bar',
      labels: topicTrendData2.map(data => data['Date']),
      datasets: [
    
        {
          label: "Positive",
          data: topicTrendData2.map(data => data['Positive']),
          borderColor: 'rgb(26, 115, 55)', // Line Color
          backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
        },
        {
          label: "Neutral",
          data: topicTrendData2.map(data => data['Neutral']),
          borderColor: 'rgb(245, 200, 15)', // Line Color
          backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
        },
        {
          label: "Negative",
          data: topicTrendData2.map(data => data['Negative']),
          borderColor: 'rgb(163, 30, 30)', // Line Color
          backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
        }
        
      ]
    }

    return data

    }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);
  
  
    trendByCategoryTopicTopicDetailBarOption2 = useMemo(() => {
  

      let data = {
              // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
          offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
      }

      return data
  
    
  
    }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);

  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component - Model 1
  
  trendByCategoryTopicTopicDetailTableDataSet2 = useMemo(() => {

    let data = topicTrendData2.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        date: data['Date'],
        positive: data['Positive'], 
        neutral: data['Neutral'], 
        negative: data['Negative'], 
        total: data['Total']
    
      })
    });

    return data

  }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);

  
  
  trendByCategoryTopicTopicDetailTableColumn2 = useMemo(() => {

    let data = [
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
        center: true
      },
    {
      name: 'Positive',
      selector: 'positive',
      sortable: true,
      center: true
    },
    {
      name: 'Neutral',
      selector: 'neutral',
      sortable: true,
      center: true
    },
    {
      name: 'Negative',
      selector: 'negative',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
    ];

    return data

  }, [topicData2, topicDetailData2, topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);



}



// Topic Detail - Topic Horizontal Bar - Model 3

var topicDetailTopicDataSet3 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: topicData3.map(data => data['Topic']),
    datasets: [
      {
        label: 'Count',
        data: topicData3.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(122, 48, 108)',
        borderColor: 'rgba(122, 48, 108, 0.6)'
      }
  
    ]
  }

  return data

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);



var topicDetailTopicOption3 = useMemo(() => {


  let data = {
      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);
    }
  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  scales: {
    xAxes: [{
      ticks: {
          beginAtZero:true
      }
    }]
  }
  }
  return data

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

// Topic Detail - Topic List Table using react-data-table-component - Model 3
var topicDetailTopicTableDataSet3 = useMemo(() => {

  let data = topicData3.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      total: data['Total']
    })
  });

  return data

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

var topicDetailTopicTableColumn3 = useMemo(() => {

  let data = [
    {
      name: 'Topic',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

  return data
  

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

// Topic Detail - Topic Detail Horizontal Bar - Model 3

var topicDetailTopicDetailDataSet3 = useMemo(() => {

  let data = {
    type: 'bar',
    labels: topicDetailData3.map(data => data['Topic.Detail']),
    datasets: [
      {
        label: 'Count',
        data: topicDetailData3.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(3, 181, 170)',
        borderColor: 'rgba(3, 181, 170, 0.6)'
      }
  
    ]
  }

  return data

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);



var topicDetailTopicDetailOption3 = useMemo(() => {

  let data = {

      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);
    }
  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  scales: {
    xAxes: [{
      ticks: {
          beginAtZero:true
      }
    }]
  }

  }

  return data

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

// Topic Detail - Topic Detail Table using react-data-table-component - Model 3

var topicDetailTopicDetailTableDataSet3 = useMemo(() => {

  let data = topicDetailData3.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic.Detail'],
      total: data['Total']
    })
  });

  return data

}, [topicData3]);

var topicDetailTopicDetailTableColumn3 = useMemo(() => {

  let data = [
    {
      name: 'Topic.Detail',
      selector: 'topic',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
  ];

  return data
  

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);



// Topic Detail - View Individual Sentence - Table using material-table - Model 3
var topicDetailIndividualSentenceDataTableColumn3 = useMemo(() => {

  let data = [
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Brand',
      field: 'brand'
    },
    {
      title: 'Model',
      field: 'model'
    },
    {
      title: 'Stars',
      field: 'stars'
    },
    {
      title: 'Sentiment',
      field: 'sentiment'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Topic',
      field: 'topic'
    },
    {
      title: 'Topic Detail',
      field: 'topicDetail'
    },
    {
      title: 'Individual Sentence',
      field: 'individualSentence',
      cellStyle: {
        width: 600,
        minWidth: 600
      },
      headerStyle: {
        width: 600,
        minWidth: 600
      }
    }
  ];

  return data

}, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);



var topicDetailIndividualSentenceDataTableDataSet3 = useMemo(() => {

  let data = customIndividualSentence3.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      brand: data['Brand'],
      model: data['Model'],
      stars: data['Stars'],
      sentiment: data['Sentiment'],
      category: data['Category'],
      topic: data['Topic'],
      topicDetail: data['Topic.Detail'],
      individualSentence: data['Individual Sentence']
    })
  });

  return data

}, [topicData3, topicDetailData3, topicTrendData3, customIndividualSentence3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);



// Trend by Category / Topic / Topic Detail - Stack Bar Chart - Model 3

var trendByCategoryTopicTopicDetailBarDataSet3;
var trendByCategoryTopicTopicDetailBarOption3;
var trendByCategoryTopicTopicDetailTableDataSet3;
var trendByCategoryTopicTopicDetailTableColumn3;

if(topicDetailTrendRatingFilter == 'star'){

  trendByCategoryTopicTopicDetailBarDataSet3 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicTrendData3.map(data => data['Date']),
      datasets: [
    
        {
          label: "5-Star",
          data: topicTrendData3.map(data => data['5-Star']),
          borderColor: 'rgb(26, 115, 55)', // Line Color
          backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
        },
        {
          label: "4-Star",
          data: topicTrendData3.map(data => data['4-Star']),
          borderColor: 'rgb(36, 222, 96)', // Line Color
          backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
        },
        {
          label: "3-Star",
          data: topicTrendData3.map(data => data['3-Star']),
          borderColor: 'rgb(245, 200, 15)', // Line Color
          backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
        },
        {
          label: "2-Star",
          data: topicTrendData3.map(data => data['2-Star']),
          borderColor: 'rgb(236, 50, 50)', // Line Color
          backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
        }
        ,
        {
          label: "1-Star",
          data: topicTrendData3.map(data => data['1-Star']),
          borderColor: 'rgb(163, 30, 30)', // Line Color
          backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
        }
        
      ]
    }
    return data
    }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);
  
  
    trendByCategoryTopicTopicDetailBarOption3 = useMemo(() => {
  

      let data = {

              // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
            offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
      }

      return data
    
  
    }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);
  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component  - Model 3
  
  trendByCategoryTopicTopicDetailTableDataSet3 = useMemo(() => {

    let data = topicTrendData3.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        category: data['Date'],
        fiveStar: data['5-Star'], 
        fourStar: data['4-Star'], 
        threeStar: data['3-Star'], 
        twoStar: data['2-Star'], 
        oneStar: data['1-Star'],
        total: data['Total']
    
      })
    });

    return data

  }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

  
  trendByCategoryTopicTopicDetailTableColumn3 = useMemo(() => {

    let data = [
      {
        name: 'Category',
        selector: 'category',
        sortable: true,
        center: true
      },
    {
      name: '5-Star',
      selector: 'fiveStar',
      sortable: true,
      center: true
    },
    {
      name: '4-Star',
      selector: 'fourStar',
      sortable: true,
      center: true
    },
    {
      name: '3-Star',
      selector: 'threeStar',
      sortable: true,
      center: true
    },
    {
      name: '2-Star',
      selector: 'twoStar',
      sortable: true,
      center: true
    },
    {
      name: '1-Star',
      selector: 'oneStar',
      sortable: true,
      center: true
    },
    {
      name: 'Total',
      selector: 'total',
      sortable: true,
      center: true
    }
    ];

    return data


  }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);


  
}else{
  // Sentiment Selection

  trendByCategoryTopicTopicDetailBarDataSet3 = useMemo(() => {

    let data = {
      type: 'bar',
      labels: topicTrendData3.map(data => data['Date']),
      datasets: [
    
        {
          label: "Positive",
          data: topicTrendData3.map(data => data['Positive']),
          borderColor: 'rgb(26, 115, 55)', // Line Color
          backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
        },
        {
          label: "Neutral",
          data: topicTrendData3.map(data => data['Neutral']),
          borderColor: 'rgb(245, 200, 15)', // Line Color
          backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
        },
        {
          label: "Negative",
          data: topicTrendData3.map(data => data['Negative']),
          borderColor: 'rgb(163, 30, 30)', // Line Color
          backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
        }
        
      ]
    }

    return data
    }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);
  
  
    trendByCategoryTopicTopicDetailBarOption3 = useMemo(() => {
  

      let data = {
              // Drill Down
      onClick: (event, elements) => {
  
        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          //console.log(dataset.label + " at " + xLabel + ": " + value);
  
        }
  
      },
      
      scales: {
        xAxes: [{
          offset: true,
            stacked: true,
            type: 'time',
            time: {
            unit: topicDetailTrendFilter
        }
        }],
  
        yAxes: [{
          stacked: true
      }],
  
      responsive: true,
      maintainAspectRatio: false
  
    }
  
      }

      return data
    
  
    }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);
  
  
  
  //  Trend by Category / Topic / Topic Detail -  Chart Table using react-data-table-component - Model 3
  
  trendByCategoryTopicTopicDetailTableDataSet3 = useMemo(() => {

    let data = topicTrendData3.map((data) => {
      return ({
        //id: CategorySummaryData.id,
        date: data['Date'],
        positive: data['Positive'], 
        neutral: data['Neutral'], 
        negative: data['Negative'], 
        total: data['Total']
    
      })
    });

    return data


  }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);


  trendByCategoryTopicTopicDetailTableColumn3 = useMemo(() => {

      
  let data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
  ];


  return data


  }, [topicData3, topicDetailData3, topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);


}



const onClickHighlight = (sentence, topicDetail) => {

  setHighlightSentence(sentence);
  setHighlightTopicDetail(topicDetail);

  sentimentMapping.push(topicDetail); // Combine Sentiment Words with specific topc for highlighting


}



const getSentimentMappingData = async () => {

  // Sentiment Mapping

  axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].sentimentMapping}`).then(function (response) { 
    // handle success

    let sentimentData = response.data.sentimentMapping;

    setSentimentMapping(sentimentData.map(data => data['Chinese']));


  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });


try {

  let resp = await axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].sentimentMapping}`);
  
  let sentimentData = resp.data.sentimentMapping;

  setSentimentMapping(sentimentData.map(data => data['Chinese']));

} catch (err) {
  // Handle Error Here
  console.error(err);


}


};



const onClickCategoryTopicTopicDetailTrendFilter = data => () => {

  setTopicDetailTrendFilter(data);

  const axios = require('axios');

  // Topic Detail
  setTopicDetail2IsLoading(true);
  let restGETurl1 = `${"brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"JD"}${"&country="}${"China"}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopic}${"&categoryAfterService="}${state.afterservice}${"&categoryBrand="}${state.brand}${"&categoryCOI="}${state.coi}${"&categoryDesign="}${state.design}${"&categoryHardware="}${state.hardware}${"&categoryOthers="}${state.others}${"&categoryPerformance="}${state.performance}${"&categoryPicture="}${state.picture}${"&categoryPrice="}${state.price}${"&categoryQA="}${state.qa}${"&categorySound="}${state.sound}${"&categoryUX="}${state.ux}${"&topicSelection="}${topicMenuSelection}${"&topicDetailSection="}${topicDetailMenuSelection}${"&filterSelection="}${data}`;
  
  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

  if(categorySelection1 == "TV"){

    axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailTV}${restGETurl1}`).then(function (response) { 
      // handle success
  
      //setCategoryData(response.data.categoryList);
      //setTopicData(response.data.topicList);
      //setTopicDetailData(response.data.topicDetailList);
      setTopicTrendData1(response.data.trendData);
      //setCustomIndividualSentence(response.data.individualSentenceCN);
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setTopicDetail2IsLoading(false);
    });
    
  }else if(categorySelection1 == "Monitor"){

    axios.get(`${SMATrestAPI.starRatingAnalyticCN[0].topicDetailMonitor}${restGETurl1}`).then(function (response) { 
      // handle success
  
      //setCategoryData(response.data.categoryList);
      //setTopicData(response.data.topicList);
      //setTopicDetailData(response.data.topicDetailList);
      setTopicTrendData1(response.data.trendData);
      //setCustomIndividualSentence(response.data.individualSentenceCN);
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setTopicDetail2IsLoading(false);
    });
    
  }




};


const onClickCategoryTopicTopicDetailTrendRatingFilter = data => () => {
  setTopicDetailTrendRatingFilter(data);
};



// Hot Topic Trend - Stack Bar Chart - Model 1
var hotTopicTrendBarDataSet1 = useMemo(() => {

  let data = {

    type: 'line',
    labels: topicTrendData1.map(data => data['Date']),
    datasets: [
      {
        label: 'Ratio %',
        data: topicTrendData1.map(data => data['Ratio']),
        fill: false,
        backgroundColor: 'rgb(255, 231, 76)', 
        borderColor: 'rgba(255, 231, 76, 0.6)',
        type: 'line',
        yAxisID: 'A'
      },
      {
        label: 'Other Topics',
        data: topicTrendData1.map(data => data['OtherReviews']),
        fill: false,
        backgroundColor: 'rgb(56, 97, 140)',
        borderColor: 'rgba(56, 97, 140)',
        stack: 'bar',
        yAxisID: 'B'
      },
      {
        label: 'Selected Topic',
        data: topicTrendData1.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(17, 145, 137)',
        borderColor: 'rgba(17, 145, 137, 0.6)',
        stack: 'bar',
        yAxisID: 'B'
      }
  
  
  
    ]

  }

  return data

}, [topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);


var hotTopicTrendOption1 = useMemo(() => {


  let data = {
      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          // max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      time: {
          unit: topicDetailTrendFilter
      }
  }]
  }
  }

  return data


}, [topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);

// Hot Topic Trend- Table using react-data-table-component
var hotTopicTrendTableDataSet1 = useMemo(() => {

  let data = topicTrendData1.map((data) => {
    return ({
      date: data['Date'],
      selectedTopic: data['Total'],
      otherReviews: data['OtherReviews'],
      ratio: data['Ratio']
    })
  });

  return data


}, [topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);


const hotTopicTrendTableColumn1 = useMemo(() => {

  let data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: 'Selected Topic',
      selector: 'selectedTopic',
      sortable: true,
      center: true
    },
    {
      name: 'Other Reviews',
      selector: 'otherReviews',
      sortable: true,
      center: true
    },
    {
      name: 'Ratio',
      selector: 'ratio',
      sortable: true,
      center: true
    }
  ];


  return data


}, [topicTrendData1, uniqueTrendData1, categorySummaryData1, categoryLabel1, sortOption]);





// Hot Topic Trend - Stack Bar Chart - Model 2
var hotTopicTrendBarDataSet2 = useMemo(() => {

  let data = {

    type: 'line',
    labels: topicTrendData2.map(data => data['Date']),
    datasets: [
      {
        label: 'Ratio %',
        data: topicTrendData2.map(data => data['Ratio']),
        fill: false,
        backgroundColor: 'rgb(255, 231, 76)', 
        borderColor: 'rgba(255, 231, 76, 0.6)',
        type: 'line',
        yAxisID: 'A'
      },
      {
        label: 'Other Topics',
        data: topicTrendData2.map(data => data['OtherReviews']),
        fill: false,
        backgroundColor: 'rgb(56, 97, 140)',
        borderColor: 'rgba(56, 97, 140)',
        stack: 'bar',
        yAxisID: 'B'
      },
      {
        label: 'Selected Topic',
        data: topicTrendData2.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(17, 145, 137)',
        borderColor: 'rgba(17, 145, 137, 0.6)',
        stack: 'bar',
        yAxisID: 'B'
      }
  
  
  
    ]
  }

  return data

}, [topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);


let hotTopicTrendOption2 = useMemo(() => {

  let data = {

      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          // max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      time: {
          unit: topicDetailTrendFilter
      }
  }]
  }

  }

  return data

}, [topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);

// Hot Topic Trend- Table using react-data-table-component
var hotTopicTrendTableDataSet2 = useMemo(() => {


  let data = topicTrendData2.map((data) => {
    return ({
      date: data['Date'],
      selectedTopic: data['Total'],
      otherReviews: data['OtherReviews'],
      ratio: data['Ratio']
    })
  });

return data


}, [topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);

var hotTopicTrendTableColumn2 = useMemo(() => {

  let data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: 'Selected Topic',
      selector: 'selectedTopic',
      sortable: true,
      center: true
    },
    {
      name: 'Other Reviews',
      selector: 'otherReviews',
      sortable: true,
      center: true
    },
    {
      name: 'Ratio',
      selector: 'ratio',
      sortable: true,
      center: true
    }
  ];

  return data


}, [topicTrendData2, uniqueTrendData2, categorySummaryData2, categoryLabel2, sortOption]);



// Hot Topic Trend - Stack Bar Chart - Model 3
var hotTopicTrendBarDataSet3 = useMemo(() => {

  let data = {

    type: 'line',
    labels: topicTrendData3.map(data => data['Date']),
    datasets: [
      {
        label: 'Ratio %',
        data: topicTrendData3.map(data => data['Ratio']),
        fill: false,
        backgroundColor: 'rgb(255, 231, 76)', 
        borderColor: 'rgba(255, 231, 76, 0.6)',
        type: 'line',
        yAxisID: 'A'
      },
      {
        label: 'Other Topics',
        data: topicTrendData3.map(data => data['OtherReviews']),
        fill: false,
        backgroundColor: 'rgb(56, 97, 140)',
        borderColor: 'rgba(56, 97, 140)',
        stack: 'bar',
        yAxisID: 'B'
      },
      {
        label: 'Selected Topic',
        data: topicTrendData3.map(data => data['Total']),
        fill: false,
        backgroundColor: 'rgb(17, 145, 137)',
        borderColor: 'rgba(17, 145, 137, 0.6)',
        stack: 'bar',
        yAxisID: 'B'
      }
  
  
  
    ]
  }

  return data

}, [topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);


var hotTopicTrendOption3 = useMemo(() => {

  let data = {
      // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },

  options: {
    responsive: true,
    maintainAspectRatio: false
  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          // max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      offset: true,
      type: 'time',
      time: {
          unit: topicDetailTrendFilter
      }
  }]
  }
  }

  return data


}, [topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

// Hot Topic Trend- Table using react-data-table-component
var hotTopicTrendTableDataSet3 = useMemo(() => {

  let data = topicTrendData3.map((data) => {
    return ({
      date: data['Date'],
      selectedTopic: data['Total'],
      otherReviews: data['OtherReviews'],
      ratio: data['Ratio']
    })
  });
  

return data


}, [topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);

var hotTopicTrendTableColumn3 = useMemo(() => {

  let data = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      center: true
    },
    {
      name: 'Selected Topic',
      selector: 'selectedTopic',
      sortable: true,
      center: true
    },
    {
      name: 'Other Reviews',
      selector: 'otherReviews',
      sortable: true,
      center: true
    },
    {
      name: 'Ratio',
      selector: 'ratio',
      sortable: true,
      center: true
    }
  ];

  return data

}, [topicTrendData3, uniqueTrendData3, categorySummaryData3, categoryLabel3, sortOption]);






  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();



  return (
    (!progressBarIsLoading ? 
    <div>

      <div id="pageLoader" className={loadPage1}></div>

      <div id="mainPage" style={{display:loadPage2}}>

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

      </GridContainer>


      <GridContainer>
        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #1</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #1 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection1}
                    onChange={handleBrand1}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems1.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #1 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection1}
                  onChange={handleCategory1}
                  inputProps={
                    {
                      name: "categorySelection1",
                      id: "category-select1"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems1.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #1 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                {/* <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel> */}

                {/* <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection1}
                  onChange={handleModel1}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems1.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select> */}

                  <Autocomplete
                    multiple
                    limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="model-select"
                    disableCloseOnSelect
                    onChange={handleModel1}
                    value={modelSelection1}
                    inputValue={inputValue1}
                    options={modelItems1}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION (15 Max)'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                        {/* {console.log(option)} */}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params}
                                        onChange={(e) => setInputValue1(e.target.value)}
                                        onBlur={() => setInputValue1('')}
                                        label="SELECT OR SEARCH MODEL" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
              </FormControl>


{/*  Date Selection #1 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection1, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem1, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate1}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart1 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection1, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem1, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate1}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd1 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #2</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #2 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection2}
                    onChange={handleBrand2}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems2.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #2 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection2}
                  onChange={handleCategory2}
                  inputProps={
                    {
                      name: "categorySelection2",
                      id: "category-select2"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems2.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #2 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                {/* <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection2}
                  onChange={handleModel2}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems2.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select> */}

                <Autocomplete
                    multiple
                    limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="model-select"
                    disableCloseOnSelect
                    onChange={handleModel2}
                    value={modelSelection2}
                    inputValue={inputValue2}
                    options={modelItems2}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION (15 Max)'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}     
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                        {/* {console.log(option)} */}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params}
                                        onChange={(e) => setInputValue2(e.target.value)}
                                        onBlur={() => setInputValue2('')}
                                        label="SELECT OR SEARCH MODEL" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
              </FormControl>


{/*  Date Selection #2 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection2, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem2, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate2}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart2 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection2, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem2, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate2}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd2 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #3</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #3 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection3}
                    onChange={handleBrand3}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems3.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #3 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection3}
                  onChange={handleCategory3}
                  inputProps={
                    {
                      name: "categorySelection3",
                      id: "category-select3"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems3.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #3 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                {/* <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection3}
                  onChange={handleModel3}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems3.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select> */}

                <Autocomplete
                    multiple
                    limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="model-select"
                    disableCloseOnSelect
                    onChange={handleModel3}
                    value={modelSelection3}
                    inputValue={inputValue3}
                    options={modelItems3}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION (15 Max)'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}     
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                        {/* {console.log(option)} */}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params}
                                        onChange={(e) => setInputValue3(e.target.value)}
                                        onBlur={() => setInputValue3('')}
                                        label="SELECT OR SEARCH MODEL" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
              </FormControl>


{/*  Date Selection #3 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection3, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem3, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate3}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart3 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection3, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem3, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate3}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd3 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


      </GridContainer>


        <GridContainer >

        <GridItem 
            xs={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center">

          <Button color="pinterest" onClick={onClickChartData}>
            <RefreshIcon className={classes.icons} />
              Display Chart
          </Button>
          

        </GridItem>

        </GridContainer>

      <br/>


        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Product Comparison</b> </Button>
          </GridItem>

        </GridContainer>


{/* Model 1 - 3 Model Title */}

        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #1</b> </Button>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #2</b> </Button>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #3</b> </Button>
          </GridItem>
      
        </GridContainer>


{/* Model 1 Pie */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={uniquePieViewTrendDataSet1} 
                      options={uniquePieViewTrendOptions1} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={uniqueTrendPieTableColumn1}
                        data={uniqueTrendPieTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={uniqueTrendPieTableDataSet1} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Model 2 Pie */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={uniquePieViewTrendDataSet2} 
                      options={uniquePieViewTrendOptions2} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={uniqueTrendPieTableColumn2}
                        data={uniqueTrendPieTableDataSet2}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={uniqueTrendPieTableDataSet2} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Model 3 Pie */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={uniquePieViewTrendDataSet3} 
                      options={uniquePieViewTrendOptions3} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={uniqueTrendPieTableColumn3}
                        data={uniqueTrendPieTableDataSet3}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={uniqueTrendPieTableDataSet3} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>

      </GridContainer>


{/* Model 1 Star Rating Trend */}

    <GridContainer>

      <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Cumulative Trend by Percent",
                  tabIcon: TrendingUpIcon,
                  tabContent: (!starRatingTrendUniqueIsLoading1 ? 
                    <div>
                      <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton} onClick={onClickUniqueTrendFilter1("month")}>
                          Month
                        </Button>
                        <Button color="white" size="sm" className={classes.middleButton} onClick={onClickUniqueTrendFilter1("week")}>
                          Week
                        </Button>
                        <Button color="white" size="sm" className={classes.lastButton} onClick={onClickUniqueTrendFilter1("day")}>
                          Day
                        </Button>
                      </div>
                      
                        </GridItem>

                        </GridContainer>
                      <br/>
                      <Line 
                        height={215} data={uniqueTrendViewTrendDataSet1}  
                        options={uniqueTrendViewTrendOptions1} /> 
                    </div>
                  

                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!starRatingTrendUniqueIsLoading1 ? 
                      <div>
                        <DataTable
                          title=""
                          columns={uniqueTrendTrendTableColumn1}
                          data={uniqueTrendTrendTableDataSet1}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={uniqueTrendTrendTableDataSet1} filename={`${currentDate1}${"_"}${"Unique Trend_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

      </GridItem>




{/* Model 2 Star Rating Trend */}

      <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Cumulative Trend by Percent",
                  tabIcon: TrendingUpIcon,
                  tabContent: (!starRatingTrendUniqueIsLoading2 ? 
                    <div>
                      <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton} onClick={onClickUniqueTrendFilter2("month")}>
                          Month
                        </Button>
                        <Button color="white" size="sm" className={classes.middleButton} onClick={onClickUniqueTrendFilter2("week")}>
                          Week
                        </Button>
                        <Button color="white" size="sm" className={classes.lastButton} onClick={onClickUniqueTrendFilter2("day")}>
                          Day
                        </Button>
                      </div>
                      
                        </GridItem>

                        </GridContainer>
                      <br/>
                      <Line 
                        height={215} data={uniqueTrendViewTrendDataSet2}  
                        options={uniqueTrendViewTrendOptions2} /> 
                    </div>
                  

                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!starRatingTrendUniqueIsLoading2 ? 
                      <div>
                        <DataTable
                          title=""
                          columns={uniqueTrendTrendTableColumn2}
                          data={uniqueTrendTrendTableDataSet2}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={uniqueTrendTrendTableDataSet2} filename={`${currentDate2}${"_"}${"Unique Trend_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

      </GridItem>




 {/* Model 3 Star Rating Trend */}

      <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Cumulative Trend by Percent",
                  tabIcon: TrendingUpIcon,
                  tabContent: (!starRatingTrendUniqueIsLoading3 ? 
                    <div>
                      <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton} onClick={onClickUniqueTrendFilter3("month")}>
                          Month
                        </Button>
                        <Button color="white" size="sm" className={classes.middleButton} onClick={onClickUniqueTrendFilter3("week")}>
                          Week
                        </Button>
                        <Button color="white" size="sm" className={classes.lastButton} onClick={onClickUniqueTrendFilter3("day")}>
                          Day
                        </Button>
                      </div>
                      
                        </GridItem>

                        </GridContainer>
                      <br/>
                      <Line 
                        height={215} data={uniqueTrendViewTrendDataSet3}  
                        options={uniqueTrendViewTrendOptions3} /> 
                    </div>
                  

                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!starRatingTrendUniqueIsLoading3 ? 
                      <div>
                        <DataTable
                          title=""
                          columns={uniqueTrendTrendTableColumn3}
                          data={uniqueTrendTrendTableDataSet3}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={uniqueTrendTrendTableDataSet3} filename={`${currentDate3}${"_"}${"Unique Trend_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

      </GridItem>


    </GridContainer>


{/* Conditional Render - Category Filter */}
    <GridContainer direction="row">

      <GridItem xs={12}
            sm={12}
            md={12}
            >

        <Card>
            <CardHeader color="rose">
              <p style={{ color: 'white'  }}><center><strong>Category Filter</strong></center></p>
            </CardHeader>
            <CardBody>
                
              <CategoryFilter />

              <hr/>
              {/* <strong>Category Selection</strong> */}
              <p style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Sorting Option</strong></p>
              <br></br>
              <FormControl component="fieldset">
                {/* <FormLabel component="legend" style={{height: 2, fontSize: '16px', color: 'black'}}><strong>Sorting Option</strong></FormLabel> */}
                <RadioGroup aria-label="categorySortFilter" name="sortFilter" value={sortOption} onChange={handleChangeCategoryRadio} row>
                  <FormControlLabel value="abc" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'}}>Alphabet</span>}  />
                  <FormControlLabel value="priority" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>Priority</span>} />
                  {/* <FormControlLabel value="fiveStar" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>5-Star</span>}/>
                  <FormControlLabel value="oneStar"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>1-Star</span>}/> */}
                  <FormControlLabel value="highLow"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>High to Low</span>}/>
                </RadioGroup>
              </FormControl>

              <hr/>

              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

                <Button color="pinterest" size="sm" onClick={onClickChartDataRefresh}>
                  <RefreshIcon className={classes.icons} />
                    Display Chart
                </Button>
            

              </GridItem>
              
            </CardBody>
        </Card>



      </GridItem>



    </GridContainer>


{/* Model 1 - Star Rating by Category */}
    <GridContainer>

    <GridItem xs={12}
            sm={12}
            md={4}>

        <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="primary"

        tabs={[
          {
            tabName: "Star Rating by Category",
            tabIcon: BarChartIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          {/* <GridContainer >
                              <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                              
                              <div className={classes.buttonGroup}>
                              <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryBarFilter("percent")}>
                              Percent
                            </Button>
                              <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryBarFilter("value")}>
                              Value
                            </Button>
                              </div>
                              
                              </GridItem>
                              
                              </GridContainer>
                              <br/> */}
                        <HorizontalBar 
                        height={215} data={categorySummaryBarDataSet1} options={categorySummaryBarOption1} /> 
                          
                          </div>
                          
                          
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          <DataTable
                        title=""
                        columns={categorySummaryBarTableColumn1}
                        data={categorySummaryBarTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                        /> 
                          <GridContainer>
                          <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                          
                          <CSVLink data={categorySummaryBarTableDataSet1} filename={`${currentDate1}${"_"}${"Star Rating by Category_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                      
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                          Download CSV 
                        </Button>
                          </CSVLink>
                          
                          </GridItem>
                          
                          
                          
                          
                          
                          </GridContainer>
                          
                          </div>
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            
            
          }
        ]}
        /> 

        </GridItem>

{/* Model 2 - Star Rating by Category */}
        <GridItem xs={12}
            sm={12}
            md={4}>

        <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="primary"

        tabs={[
          {
            tabName: "Star Rating by Category",
            tabIcon: BarChartIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          {/* <GridContainer >
                              <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                              
                              <div className={classes.buttonGroup}>
                              <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryBarFilter("percent")}>
                              Percent
                            </Button>
                              <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryBarFilter("value")}>
                              Value
                            </Button>
                              </div>
                              
                              </GridItem>
                              
                              </GridContainer>
                              <br/> */}
                        <HorizontalBar 
                        height={215} data={categorySummaryBarDataSet2} options={categorySummaryBarOption2} /> 
                          
                          </div>
                          
                          
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          <DataTable
                        title=""
                        columns={categorySummaryBarTableColumn2}
                        data={categorySummaryBarTableDataSet2}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                        /> 
                          <GridContainer>
                          <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                          
                          <CSVLink data={categorySummaryBarTableDataSet2} filename={`${currentDate2}${"_"}${"Star Rating by Category_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                      
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                          Download CSV 
                        </Button>
                          </CSVLink>
                          
                          </GridItem>
                          
                          
                          
                          
                          
                          </GridContainer>
                          
                          </div>
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            
            
          }
        ]}
        /> 

        </GridItem>



  {/* Model 3 - Star Rating by Category */}
        <GridItem xs={12}
            sm={12}
            md={4}>

        <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="primary"

        tabs={[
          {
            tabName: "Star Rating by Category",
            tabIcon: BarChartIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          {/* <GridContainer >
                              <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">
                              
                              <div className={classes.buttonGroup}>
                              <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryBarFilter("percent")}>
                              Percent
                            </Button>
                              <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryBarFilter("value")}>
                              Value
                            </Button>
                              </div>
                              
                              </GridItem>
                              
                              </GridContainer>
                              <br/> */}
                        <HorizontalBar 
                        height={215} data={categorySummaryBarDataSet3} options={categorySummaryBarOption3} /> 
                          
                          </div>
                          
                          
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
            tabContent: (!categorySummaryIsLoading ? 
                          <div>
                          <DataTable
                        title=""
                        columns={categorySummaryBarTableColumn3}
                        data={categorySummaryBarTableDataSet3}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                        /> 
                          <GridContainer>
                          <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                          
                          <CSVLink data={categorySummaryBarTableDataSet3} filename={`${currentDate3}${"_"}${"Star Rating by Category_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                      
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                          Download CSV 
                        </Button>
                          </CSVLink>
                          
                          </GridItem>
                          
                          
                          
                          
                          
                          </GridContainer>
                          
                          </div>
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            
            
          }
        ]}
        /> 

        </GridItem>



    </GridContainer>

  <GridContainer>

  {/* Model 1 - Star Rating by Category */}

    <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="info"

          tabs={[
            {
              tabName: "Star Rating by Category",
              tabIcon: RadarChartIcon,
              tabContent: (!categorySummaryIsLoading ? 
                            <div>
                            {/* <GridContainer >
                                <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center">
                                
                                <div className={classes.buttonGroup}>
                                <Button color="white" size="sm" className={classes.firstButton}>
                                Percent
                              </Button>
                                <Button color="white" size="sm" className={classes.middleButton}>
                                Value
                              </Button>
                                </div>
                                
                                </GridItem>
                                
                                </GridContainer>
                                <br/> */}
                          <Radar 
                          height={300} data={categorySummaryStarRadarDataSet1}  
                          options={categorySummaryStarRadarOption1} /> 
                            </div>
                            
                            
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
              tabContent: (!categorySummaryIsLoading ? 
                            <div>
                            <DataTable
                          title=""
                          columns={categorySummaryStarRadarTableColumn1}
                          data={categorySummaryStarRadarTableDataSet1}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                          /> 
                            <GridContainer>
                            <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                            
                            <CSVLink data={categorySummaryStarRadarTableDataSet1} filename={`${currentDate1}${"_"}${"Star by Category_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                            
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                            Download CSV 
                          </Button>
                            </CSVLink>
                            
                            </GridItem>
                            
                            </GridContainer>
                            
                            </div>
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              
              
            }
          ]}
          />
    </GridItem>

    <GridItem xs={12} sm={12} md={4} >
        <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="info"

          tabs={[
            {
              tabName: "Star Rating by Category",
              tabIcon: RadarChartIcon,
              tabContent: (!categorySummaryIsLoading ? 
                            <div>
                            {/* <GridContainer >
                                <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center">
                                
                                <div className={classes.buttonGroup}>
                                <Button color="white" size="sm" className={classes.firstButton}>
                                Percent
                              </Button>
                                <Button color="white" size="sm" className={classes.middleButton}>
                                Value
                              </Button>
                                </div>
                                
                                </GridItem>
                                
                                </GridContainer>
                                <br/> */}
                          <Radar 
                          height={300} data={categorySummaryStarRadarDataSet2}  
                          options={categorySummaryStarRadarOption2} /> 
                            </div>
                            
                            
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
              tabContent: (!categorySummaryIsLoading ? 
                            <div>
                            <DataTable
                          title=""
                          columns={categorySummaryStarRadarTableColumn2}
                          data={categorySummaryStarRadarTableDataSet2}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                          /> 
                            <GridContainer>
                            <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                            
                            <CSVLink data={categorySummaryStarRadarTableDataSet2} filename={`${currentDate2}${"_"}${"Star by Category_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                            
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                            Download CSV 
                          </Button>
                            </CSVLink>
                            
                            </GridItem>
                            
                            </GridContainer>
                            
                            </div>
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              
              
            }
          ]}
          />
    </GridItem>

    <GridItem xs={12} sm={12} md={4} >
        <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="info"

          tabs={[
            {
              tabName: "Star Rating by Category",
              tabIcon: RadarChartIcon,
              tabContent: (!categorySummaryIsLoading ? 
                            <div>
                            {/* <GridContainer >
                                <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center">
                                
                                <div className={classes.buttonGroup}>
                                <Button color="white" size="sm" className={classes.firstButton}>
                                Percent
                              </Button>
                                <Button color="white" size="sm" className={classes.middleButton}>
                                Value
                              </Button>
                                </div>
                                
                                </GridItem>
                                
                                </GridContainer>
                                <br/> */}
                          <Radar 
                          height={300} data={categorySummaryStarRadarDataSet3}  
                          options={categorySummaryStarRadarOption3} /> 
                            </div>
                            
                            
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
              tabContent: (!categorySummaryIsLoading ? 
                            <div>
                            <DataTable
                          title=""
                          columns={categorySummaryStarRadarTableColumn3}
                          data={categorySummaryStarRadarTableDataSet3}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                          /> 
                            <GridContainer>
                            <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                            
                            <CSVLink data={categorySummaryStarRadarTableDataSet3} filename={`${currentDate3}${"_"}${"Star by Category_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                            
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                            Download CSV 
                          </Button>
                            </CSVLink>
                            
                            </GridItem>
                            
                            </GridContainer>
                            
                            </div>
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              
              
            }
          ]}
          />


    </GridItem>


  </GridContainer>


{/* Topic Overview */}
  <GridContainer>

    <GridItem xs={12}>
      <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Overview</b> </Button>
    </GridItem>

  </GridContainer>


{/* Sentiment by Category  */}
  <GridContainer>

    <GridItem xs={12}
              sm={12}
              md={12}>


          <Card>
            <CardHeader color="rose">
              <p style={{ color: 'white'  }}><center><strong>Topic Filter</strong></center></p>
            </CardHeader>
            <CardBody>


            <FormControl component="fieldset">
                  <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Rating Option</strong></FormLabel>
                  <RadioGroup aria-label="RatingFilter" name="ratingFilter" value={analyticOption} onChange={handleChangeTopicAnalyticRadio} row>
                    <FormControlLabel value="star" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Star</span>}  />
                    <FormControlLabel value="sentiment" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Sentiment</span>}  />
                  </RadioGroup>
                </FormControl>

              <hr/>

              <FormControl component="fieldset">
                  <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Topic Count Option</strong></FormLabel>
                  <RadioGroup aria-label="TopicCountFilter" name="topicCountFilter" value={topicOption} onChange={handleChangeTopicRadio} row>
                    <FormControlLabel value="top30" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Top 30</span>}  />
                    <FormControlLabel value="top20" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Top 20</span>}  />
                    <FormControlLabel value="top10" control={<Radio style={{height: 3}} />} 
                      label={<span style={{fontSize: '14px', color: 'black'}}>Top 10</span>}  />
                  </RadioGroup>
                </FormControl>

              <hr/>

              <FormControl component="fieldset">
                <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Sorting Option</strong></FormLabel>
                <RadioGroup aria-label="TopicSortFilter" name="topicSortFilter" value={topicSortOption} onChange={handleChangeTopicSortRadio} row>
                  <FormControlLabel value="abc" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'}}>Alphabet</span>}  />
                  {/* <FormControlLabel value="fiveStar" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>5-Star/Positive</span>}/>
                  <FormControlLabel value="oneStar"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>1-Star/Negative</span>}/> */}
                  <FormControlLabel value="highLow"  control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>High to Low</span>}/>
                </RadioGroup>
              </FormControl>

              <hr/>

              <FormControl component="fieldset">
                <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Language</strong></FormLabel>
                <RadioGroup aria-label="TopicLanguageFilter" name="topicLanguageFilter" value={topicLanguageOption} onChange={handleChangeTopicLanguageRadio} row>
                  <FormControlLabel value="chinese" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'}}>Chinese</span>}  />
                  <FormControlLabel value="english" control={<Radio style={{height: 3}} />} 
                    label={<span style={{fontSize: '14px', color: 'black'  }}>English</span>}/>
                </RadioGroup>
              </FormControl>

              <hr/>

              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

                <Button color="pinterest" size="sm" onClick={onClickChartDataRefreshTopicOverview}>
                  <RefreshIcon className={classes.icons} />
                  Display/Refresh
                </Button>
            

              </GridItem>
              
            </CardBody>
        </Card>



    </GridItem>


  </GridContainer>


  <GridContainer>

{/* Topic Overview - Horizontal Bar - Model 1  */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          {/* <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio('sentiment')}>
                              Sentiment
                            </Button>
                          </div> */}

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={400}
                        data={topicOverviewTopicComparisonDataSet1}
                        options={topicOverviewTopicComparisonOption1}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicOverviewTopicComparisonTableColumn1}
                          data={topicOverviewTopicComparisonTableDataSet1}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicOverviewTopicComparisonTableDataSet1} filename={`${currentDate1}${"_"}${"Topic Comparison List_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 



    </GridItem>


{/* Topic Overview - Horizontal Bar - Model 2  */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          {/* <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio('sentiment')}>
                              Sentiment
                            </Button>
                          </div> */}

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={400}
                        data={topicOverviewTopicComparisonDataSet2}
                        options={topicOverviewTopicComparisonOption2}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicOverviewTopicComparisonTableColumn2}
                          data={topicOverviewTopicComparisonTableDataSet2}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicOverviewTopicComparisonTableDataSet2} filename={`${currentDate2}${"_"}${"Topic Comparison List_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 



    </GridItem>


    {/* Topic Overview - Horizontal Bar - Model 3  */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          {/* <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio('sentiment')}>
                              Sentiment
                            </Button>
                          </div> */}

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={400}
                        data={topicOverviewTopicComparisonDataSet3}
                        options={topicOverviewTopicComparisonOption3}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicOverviewTopicComparisonTableColumn3}
                          data={topicOverviewTopicComparisonTableDataSet3}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicOverviewTopicComparisonTableDataSet3} filename={`${currentDate3}${"_"}${"Topic Comparison List_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 



    </GridItem>

  </GridContainer>


  <GridContainer>

{/* Topic Overview - Topic Comparison Detail - Model 1 */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Detail Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          {/* <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio('sentiment')}>
                              Sentiment
                            </Button>
                          </div> */}

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={400}
                        data={topicDetailOverviewTopicComparisonDataSet1}
                        options={topicDetailOverviewTopicComparisonOption1}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicDetailOverviewTopicComparisonTableColumn1}
                          data={topicDetailOverviewTopicComparisonTableDataSet1}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicDetailOverviewTopicComparisonTableDataSet1} filename={`${currentDate1}${"_"}${"Topic Detail Comparison List_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            />


    </GridItem>

  {/* Topic Overview - Topic Comparison Detail - Model 2 */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Detail Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          {/* <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio('sentiment')}>
                              Sentiment
                            </Button>
                          </div> */}

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={400}
                        data={topicDetailOverviewTopicComparisonDataSet2}
                        options={topicDetailOverviewTopicComparisonOption2}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicDetailOverviewTopicComparisonTableColumn2}
                          data={topicDetailOverviewTopicComparisonTableDataSet2}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicDetailOverviewTopicComparisonTableDataSet2} filename={`${currentDate2}${"_"}${"Topic Detail Comparison List_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            />
      

    </GridItem>

  {/* Topic Overview - Topic Comparison Detail - Model 3 */}
    <GridItem xs={12} sm={12} md={4}>


    <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
            
              
              tabs={[
                {
                  tabName: "Topic Detail Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!topicOverviewIsLoading ? 

                    
                    <div> 
                      {/* style={{ height: 300}} */}

                      <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                          {/* <div className={classes.buttonGroup}>
                            <Button color="white" size="sm" className={classes.firstButton} onClick={handleChangeTopicAnalyticRadio('star')}>
                              Star
                            </Button>
                            <Button color="white" size="sm" className={classes.lastButton} onClick={handleChangeTopicAnalyticRadio('sentiment')}>
                              Sentiment
                            </Button>
                          </div> */}

                      </GridItem>

                      </GridContainer>




                      <HorizontalBar
                        height={400}
                        data={topicDetailOverviewTopicComparisonDataSet3}
                        options={topicDetailOverviewTopicComparisonOption3}
                      />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },

                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicDetailOverviewTopicComparisonTableColumn3}
                          data={topicDetailOverviewTopicComparisonTableDataSet3}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />

                          <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicDetailOverviewTopicComparisonTableDataSet3} filename={`${currentDate3}${"_"}${"Topic Detail Comparison List_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                              
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            />
      

    </GridItem>

  </GridContainer>


  <GridContainer>

{/* Topic Overview - Positive Topic Detail List - Model 1 */}
    <GridItem xs={12} sm={12} md={4}>
      
      <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="success"
        
        tabs={[
          {
            tabName: "Positive Topic Detail List",
            tabIcon: PositiveIcon,
            tabContent: (!topicOverviewIsLoading ? 
              <div style={{ height: 400}}>

                <div style={{ height: 400}}>
                  {/* {sentimentOverviewPositveWordCloud1()} */}
                  <ReactWordcloud
                    callbacks={sentimentOverviewPositveCallbacks1}
                    options={sentimentOverviewPositveOptions1}
                    words={sentimentOverviewPositveWordsData1} 
                  />
                </div>

                <br />
                <br />
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },

        

          
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!topicOverviewIsLoading ?
                <div>
                  <DataTable
                    title=""
                    columns={sentimentOverviewPositveTableColumn1}
                    data={sentimentOverviewPositveTableDataSet1}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink data={sentimentOverviewPositveTableDataSet1} filename={`${currentDate1}${"_"}${"Topic Detail - Positive_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink  style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      />


    </GridItem>

  {/* Topic Overview - Positive Topic Detail List - Model 2 */}
    <GridItem xs={12} sm={12} md={4}>


      <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="success"
        
        tabs={[
          {
            tabName: "Positive Topic Detail List",
            tabIcon: PositiveIcon,
            tabContent: (!topicOverviewIsLoading ? 
              <div style={{ height: 400}}>

                <div style={{ height: 400}}>
                  {/* {sentimentOverviewPositveWordCloud2()} */}
                  <ReactWordcloud
                    callbacks={sentimentOverviewPositveCallbacks2}
                    options={sentimentOverviewPositveOptions2}
                    words={sentimentOverviewPositveWordsData2} 
                  />
                </div>

                <br />
                <br />
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },

        

          
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!topicOverviewIsLoading ?
                <div>
                  <DataTable
                    title=""
                    columns={sentimentOverviewPositveTableColumn2}
                    data={sentimentOverviewPositveTableDataSet2}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink data={sentimentOverviewPositveTableDataSet2} filename={`${currentDate2}${"_"}${"Topic Detail - Positive_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink  style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      />
      

    </GridItem>

  {/* Topic Overview - Positive Topic Detail List - Model 3 */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="success"
        
        tabs={[
          {
            tabName: "Positive Topic Detail List",
            tabIcon: PositiveIcon,
            tabContent: (!topicOverviewIsLoading ? 
              <div style={{ height: 400}}>

                <div style={{ height: 400}}>
                  {/* {sentimentOverviewPositveWordCloud3()} */}
                  <ReactWordcloud
                    callbacks={sentimentOverviewPositveCallbacks3}
                    options={sentimentOverviewPositveOptions3}
                    words={sentimentOverviewPositveWordsData3} 
                  />
                </div>

                <br />
                <br />
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },

        

          
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!topicOverviewIsLoading ?
                <div>
                  <DataTable
                    title=""
                    columns={sentimentOverviewPositveTableColumn3}
                    data={sentimentOverviewPositveTableDataSet3}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink data={sentimentOverviewPositveTableDataSet3} filename={`${currentDate3}${"_"}${"Topic Detail - Positive_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink  style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      />
      
      
    </GridItem>

  </GridContainer>


  <GridContainer>

{/* Topic Overview - Negative Topic Detail List - Model 1 */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="danger"
        
        tabs={[
          {
            tabName: "Negative Topic Detail List",
            tabIcon: NegativeIcon,
            tabContent: (!topicOverviewIsLoading ? 
              <div style={{ height: 400}}>
                
                <div style={{ height: 400}}>
                  {sentimentOverviewNegativeWordCloud1()}
                </div>

                <br />
                <br />
              </div>

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!topicOverviewIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={sentimentOverviewNegativeDataTableColumn1}
                    data={sentimentOverviewNegativeDataTableDataSet1}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink data={sentimentOverviewNegativeDataTableDataSet1} filename={`${currentDate1}${"_"}${"Topic Detail - Negative_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      />


    </GridItem>

  {/* Topic Overview - Negative Topic Detail List - Model 2 */}
    <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="danger"
        
        tabs={[
          {
            tabName: "Negative Topic Detail List",
            tabIcon: NegativeIcon,
            tabContent: (!topicOverviewIsLoading ? 
              <div style={{ height: 400}}>
                
                <div style={{ height: 400}}>
                  {sentimentOverviewNegativeWordCloud2()}
                </div>

                <br />
                <br />
              </div>

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!topicOverviewIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={sentimentOverviewNegativeDataTableColumn2}
                    data={sentimentOverviewNegativeDataTableDataSet2}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink data={sentimentOverviewNegativeDataTableDataSet2} filename={`${currentDate2}${"_"}${"Topic Detail - Negative_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      />
      

    </GridItem>

  {/* Topic Overview - Negative Topic Detail List - Model 3 */}
    <GridItem xs={12} sm={12} md={4}>
      
      <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="danger"
        
        tabs={[
          {
            tabName: "Negative Topic Detail List",
            tabIcon: NegativeIcon,
            tabContent: (!topicOverviewIsLoading ? 
              <div style={{ height: 400}}>
                
                <div style={{ height: 400}}>
                  {sentimentOverviewNegativeWordCloud3()}
                </div>

                <br />
                <br />
              </div>

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!topicOverviewIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={sentimentOverviewNegativeDataTableColumn3}
                    data={sentimentOverviewNegativeDataTableDataSet3}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink data={sentimentOverviewNegativeDataTableDataSet3} filename={`${currentDate3}${"_"}${"Topic Detail - Negative_"}${brandSelection3}${"_"}${modelSelectionCustom2}${".csv"}`}>
                
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      />
      
    </GridItem>

  </GridContainer>   



{/* Topic Detail Overview */}
  <GridContainer>

    <GridItem xs={12}>
      <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Detail</b> </Button>
    </GridItem>

  </GridContainer>



  <GridContainer>

  <GridItem xs={12}
            sm={12}
            md={12}>


      <Card>
        <CardHeader color="rose">
          <p style={{ color: 'white'  }}><center><strong>Topic Filter</strong></center></p>
        </CardHeader>
        <CardBody>


        <FormControl component="fieldset">
              <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Rating Option</strong></FormLabel>
              <RadioGroup aria-label="RatingFilter2" name="ratingFilter2" value={topicDetailTrendRatingFilter} onChange={handleTopicDetailRadioRating} row>
                <FormControlLabel value="star" control={<Radio style={{height: 3}} />} 
                  label={<span style={{fontSize: '14px', color: 'black'}}>Star</span>}  />
                <FormControlLabel value="sentiment" control={<Radio style={{height: 3}} />} 
                  label={<span style={{fontSize: '14px', color: 'black'}}>Sentiment</span>}  />
              </RadioGroup>
            </FormControl>

          <hr/>

          <FormControl component="fieldset">
              <FormLabel component="legend" style={{height: 3, fontSize: '16px', color: 'black'}}><strong>Date Trend</strong></FormLabel>
              <RadioGroup aria-label="DateTrendFilter" name="dateTrendFilter" value={topicDetailTrendFilter} onChange={handleTopicDetailRadioDate} row>
                <FormControlLabel value="month" control={<Radio style={{height: 3}} />} 
                  label={<span style={{fontSize: '14px', color: 'black'}}>Month</span>}  />
                <FormControlLabel value="week" control={<Radio style={{height: 3}} />} 
                  label={<span style={{fontSize: '14px', color: 'black'}}>Week</span>}  />
                <FormControlLabel value="day" control={<Radio style={{height: 3}} />} 
                  label={<span style={{fontSize: '14px', color: 'black'}}>Day</span>}  />
              </RadioGroup>
            </FormControl>

          
        </CardBody>
    </Card>



</GridItem>


</GridContainer>


  <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={6}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <TopicIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Topic Selection</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>

              <Autocomplete
                    multiple
                    //limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="topic-select"
                    disableCloseOnSelect
                    onChange={handleTopic}
                    inputValue={inputTopicValue}
                    // value={topicMenuSelection}
                    value={[...new Set(topicMenuSelection)]}
                    options={[...new Set(topicMenuList)]}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params} 
                                        onChange={(e) => setInputTopicValue(e.target.value)}
                                        onBlur={() => setInputTopicValue('')}
                                        label="SELECT TOPIC" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
                  

              </FormControl>
                        
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={6}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DetailIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Topic Detail Selection</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>

              <Autocomplete
                    multiple
                    //limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="topicDetail-select"
                    disableCloseOnSelect
                    onChange={handleTopicDetail}
                    // value={topicDetailMenuSelection}
                    value={[...new Set(topicDetailMenuSelection)]}
                    inputValue={inputTopicDetailValue}
                    // options={topicDetailMenuList}
                    options={[...new Set(topicDetailMenuList)]}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION'}
                    style={{ width: "100%"}}
                    // ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params}
                                        onChange={(e) => setInputTopicDetailValue(e.target.value)}
                                        onBlur={() => setInputTopicDetailValue('')} 
                                        label="SELECT DETAIL TOPIC" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
                  

              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

      </GridContainer>


{/* Topic Detail Overview */}


      <GridContainer >

        <GridItem 
            xs={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center">


          <Button color="warning" onClick={onClickCheckAll}> 
            <CheckIcon className={classes.icons} />
              Check All 
            </Button>

          <Button color="pinterest" onClick={onClickChartDataRefreshTopicDetail}>
            <RefreshIcon className={classes.icons} />
              Display/Refresh
          </Button>
          

        </GridItem>

      </GridContainer>

      <br/>



    <GridContainer>

        {/* Topic - Horizontal Bar - Model 1 */}
        <GridItem xs={12} sm={12} md={4}>

          <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
          
            
            tabs={[
              {
                tabName: "Topic",
                tabIcon: TopicIcon,
                tabContent: (!topicDetail2IsLoading ? 

                  
                  <div> 
                    {/* style={{ height: 300}} */}


                    <HorizontalBar
                      height={350}
                      data={topicDetailTopicDataSet1}
                      options={topicDetailTopicOption1}
                    />
                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },

              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!topicDetail2IsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={topicDetailTopicTableColumn1}
                        data={topicDetailTopicTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"445px"}
                        noHeader={true}
                        />

                        <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicDetailTopicTableDataSet1} filename={`${currentDate1}${"_"}${"Topic Count_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                            
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          />

        </GridItem>

        {/* Topic - Horizontal Bar - Model 2 */}
        <GridItem xs={12} sm={12} md={4}>

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
          
            
            tabs={[
              {
                tabName: "Topic",
                tabIcon: TopicIcon,
                tabContent: (!topicDetail2IsLoading ? 

                  
                  <div> 
                    {/* style={{ height: 300}} */}


                    <HorizontalBar
                      height={350}
                      data={topicDetailTopicDataSet2}
                      options={topicDetailTopicOption2}
                    />
                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },

              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!topicDetail2IsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={topicDetailTopicTableColumn2}
                        data={topicDetailTopicTableDataSet2}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"445px"}
                        noHeader={true}
                        />

                        <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicDetailTopicTableDataSet2} filename={`${currentDate2}${"_"}${"Topic Count_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                            
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          />
          
        </GridItem>

        {/* Topic - Horizontal Bar - Model 3 */}
        <GridItem xs={12} sm={12} md={4}>

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
          
            
            tabs={[
              {
                tabName: "Topic",
                tabIcon: TopicIcon,
                tabContent: (!topicDetail2IsLoading ? 

                  
                  <div> 
                    {/* style={{ height: 300}} */}


                    <HorizontalBar
                      height={350}
                      data={topicDetailTopicDataSet3}
                      options={topicDetailTopicOption3}
                    />
                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },

              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!topicDetail2IsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={topicDetailTopicTableColumn3}
                        data={topicDetailTopicTableDataSet3}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"445px"}
                        noHeader={true}
                        />

                        <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicDetailTopicTableDataSet3} filename={`${currentDate3}${"_"}${"Topic Count_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                            
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          />
      
        </GridItem>


    </GridContainer>


    <GridContainer>

      {/* Topic Detail - Horizontal Bar - Model 1 */}
      <GridItem xs={12} sm={12} md={4}>

        <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="primary"
        
          
          tabs={[
            {
              tabName: "Topic Detail",
              tabIcon: DetailIcon,
              tabContent: (!topicDetail2IsLoading ? 

                
                <div> 
                  {/* style={{ height: 300}} */}

                  <HorizontalBar
                    height={350}
                    data={topicDetailTopicDetailDataSet1}
                    options={topicDetailTopicDetailOption1}
                  />
                </div>
              

              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },

            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!topicDetail2IsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={topicDetailTopicDetailTableColumn1}
                      data={topicDetailTopicDetailTableDataSet1}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"445px"}
                      noHeader={true}
                      />

                      <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={topicDetailTopicDetailTableDataSet1} filename={`${currentDate1}${"_"}${"Topic Detail Count_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                          
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                  </div>
                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        />

      </GridItem>

      {/* Topic Detail - Horizontal Bar - Model 2 */}
      <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="primary"
        
          
          tabs={[
            {
              tabName: "Topic Detail",
              tabIcon: DetailIcon,
              tabContent: (!topicDetail2IsLoading ? 

                
                <div> 
                  {/* style={{ height: 300}} */}

                  <HorizontalBar
                    height={350}
                    data={topicDetailTopicDetailDataSet2}
                    options={topicDetailTopicDetailOption2}
                  />
                </div>
              

              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },

            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!topicDetail2IsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={topicDetailTopicDetailTableColumn2}
                      data={topicDetailTopicDetailTableDataSet2}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"445px"}
                      noHeader={true}
                      />

                      <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={topicDetailTopicDetailTableDataSet2} filename={`${currentDate2}${"_"}${"Topic Detail Count_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                          
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                  </div>
                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        />

      </GridItem>

      {/* Topic Detail - Horizontal Bar - Model 3 */}
      <GridItem xs={12} sm={12} md={4}>

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="primary"
        
          
          tabs={[
            {
              tabName: "Topic Detail",
              tabIcon: DetailIcon,
              tabContent: (!topicDetail2IsLoading ? 

                
                <div> 
                  {/* style={{ height: 300}} */}

                  <HorizontalBar
                    height={350}
                    data={topicDetailTopicDetailDataSet3}
                    options={topicDetailTopicDetailOption3}
                  />
                </div>
              

              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },

            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!topicDetail2IsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={topicDetailTopicDetailTableColumn3}
                      data={topicDetailTopicDetailTableDataSet3}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"445px"}
                      noHeader={true}
                      />

                      <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={topicDetailTopicDetailTableDataSet3} filename={`${currentDate3}${"_"}${"Topic Detail Count_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                          
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                  </div>
                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        />

      </GridItem>

    </GridContainer>



    <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          {/* <h3 className={classes.pageSubcategoriesTitle}>Page Subcategories</h3> */}
          <br />
          <NavPills
            color="warning"
            alignCenter
            tabs={[
              {
                tabButton: "Model 1",
                tabIcon: ModelIcon,
                tabContent: (
                  <GridContainer>

                  <GridItem xs={12} sm={12} md={12} >
        
                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="primary"
                        
                        tabs={[
                          {
                            tabName: "Custom Individual Sentence",
                            tabIcon: AssignmentIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
        
                                  
                                  <div style={{ maxWidth: '100%' }}>
                                          <MaterialTable
                                            columns={topicDetailIndividualSentenceDataTableColumn1}
                                            data={topicDetailIndividualSentenceDataTableDataSet1}
                                            title="SMAT Review"
                                            options={{
                                              exportButton: true,
                                              exportAllData: true,
                                              filtering: true,
                                              showTitle: false
                                            }}
                                            actions={[
                                              {
                                                icon: TopicIcon,
                                                tooltip: 'Highlight Topic Detail and Sentiment',
                                                onClick: (event, rowData) => {
                                                  // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                  //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                  //setFullReviewModal(true); // Show Pop-up
                                                  //console.log(rowData.individualSentence); 
                                                  onClickHighlight(rowData.individualSentence, rowData.topicDetail, ()=>{});
                                                  setPopUpHighlight(true);
        
                                                }
        
                                              }
                                            ]}
                                            components={{
                                              Action: props => (
        
                                                <Tooltip
                                                  id="tooltip-top"
                                                  title="Highlight Topic Detail and Sentiment"
                                                  placement="top"
                                                  classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                      <HighlightIcon />
                                                    </Button>
                                                </Tooltip>
                                                
                                              )
                                            }}
                                          />
                                    </div>
        
                                    <div>
                                            <Dialog
                                              fullWidth={true}
                                              maxWidth={'lg'}
                                              open={popUpHighlight}
                                              onClose={() => setPopUpHighlight(false)}
                                              //TransitionComponent={Transition}
                                              aria-labelledby="full-review-dialog-title1"
                                            >
                                              <DialogTitle id="full-review-dialog-title1"><strong> Sentence Review </strong></DialogTitle>
                                              <DialogContent>
                                                {/* <DialogContentText> */}
                                                {/* {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )} */}
                                                {/* </DialogContentText> */}
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={sentimentMapping} 
                                                    autoEscape={true}
                                                    textToHighlight={highlightSentence}
                                                  />
                                              
                                              </DialogContent>
                                              <DialogActions>
                                                <Button onClick={() => setPopUpHighlight(false)} color="primary">
                                                  Close
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </div>
         
                              <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={customIndividualSentence1} filename={`${currentDate1}${"_"}${"Custom Individual Sentence_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>
        
                                </GridItem>
        
                                </GridContainer>
        
                                </div>
                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
        
                            
                          }
                        ]}
                      /> 
        
                  </GridItem>


                  <GridItem xs={12}>
                    <Button color="github" size="sm" style={{width: "100%"}}> <b>Trend by Category / Topic / Topic Detail</b> </Button>
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="warning"
                        
                        tabs={[
                          {
                            tabName: "Category / Topic / Topic Detail / Trend",
                            tabIcon: TrendingUpIcon,
                            tabContent: (!topicDetail2IsLoading ? 
                              <div>
                                <GridContainer >
                                  <GridItem 
                                      xs={6}
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="center">

                                {/* <div className={classes.buttonGroup}>
                                  <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                                    Month
                                  </Button>
                                  <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                                    Week
                                  </Button>
                                  <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                                    Day
                                  </Button>
                                </div> */}
                                
                                  </GridItem>

                                  </GridContainer>


                                  <GridContainer>
                                    <GridItem 
                                        xs={6}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">

                                  {/* <div className={classes.buttonGroup}>
                                    <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("star")}>
                                      Star
                                    </Button>
                                    <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("sentiment")}>
                                      Sentiment
                                    </Button>
                                  </div> */}
                                  
                                    </GridItem>
                                  </GridContainer>


                                <br/>
                                <Bar 
                                  height={50} data={trendByCategoryTopicTopicDetailBarDataSet1}  
                                  options={trendByCategoryTopicTopicDetailBarOption1} /> 
                              </div>
                            

                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={trendByCategoryTopicTopicDetailTableColumn1}
                                    data={trendByCategoryTopicTopicDetailTableDataSet1}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={trendByCategoryTopicTopicDetailTableDataSet1} filename={`${currentDate1}${"_"}${"Trend By Category Topic Topic Detail Trend_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                                
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                    </GridItem>


                    <GridItem xs={12}>
                      <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Ratio Trend</b> </Button>
                    </GridItem>



                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="warning"
                          
                          tabs={[
                            {
                              tabName: "Topic Ratio Trend",
                              tabIcon: TrendingUpIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
                                  <GridContainer >
                                    <GridItem 
                                        xs={6}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">

                                  {/* <div className={classes.buttonGroup}>
                                    <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                                      Month
                                    </Button>
                                    <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                                      Week
                                    </Button>
                                    <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                                      Day
                                    </Button>
                                  </div> */}
                                  
                                    </GridItem>

                                    </GridContainer>

                                  <Bar 
                                    height={50} data={hotTopicTrendBarDataSet1}  
                                    options={hotTopicTrendOption1} /> 
                                </div>
                              

                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!topicDetail2IsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={hotTopicTrendTableColumn1}
                                      data={hotTopicTrendTableDataSet1}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={hotTopicTrendTableDataSet1} filename={`${currentDate1}${"_"}${"Topic Ratio Trend_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                      </GridItem>


                      {/* <GridContainer>

                        <GridItem xs={12}>
                          <Button color="github" size="sm" style={{width: "100%"}}> <b>Individual Sentence</b> </Button>
                        </GridItem>

                      </GridContainer>



                        <GridItem xs={12} sm={12} md={12}>

                          <CustomTabs
                            //title="Tasks:"
                            centered={true}
                            headerColor="primary"

                            tabs={[

                              {
                                tabName: "Table",
                                tabIcon: ModelIcon,
                                  tabContent: (!viewIndividualSentenceIsLoading1 ? 
                                    <div>
                                      <DataTable
                                        title=""
                                        columns={viewIndividualSentenceTableColumn1}
                                        data={viewIndividualSentenceDataTableDataSet1}
                                        //conditionalRowStyles={conditionalRowStyles}
                                        // pagination={true}
                                        // paginationPerPage={10}
                                        // paginationComponentOptions={{ noRowsPerPage: true }}
                                        fixedHeader={true}
                                        //fixedHeaderScrollHeight={"445px"}
                                        fixedHeaderScrollHeight={"445px"}
                                        noHeader={true}
                                        />
                                        <GridContainer>
                                        <GridItem 
                                          xs={12}
                                          container
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="center">
                                          <CSVLink data={analyzedData2} filename={`${currentDate1}${"_"}${"Analyzed Data_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                              <DownloadIcon className={classes.icons} />
                                                Download CSV 
                                            </Button>
                                          </CSVLink>

                                        </GridItem>

                                      </GridContainer>

                                    </div>
                                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                
                              }
                            ]}
                          /> 

                          </GridItem> */}




        
                  </GridContainer>

                  

                  
                )
              },
              {
                tabButton: "Model 2",
                tabIcon: ModelIcon,
                tabContent: (
                  <GridContainer>

                  <GridItem xs={12} sm={12} md={12} >
        
                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="primary"
                        
                        tabs={[
                          {
                            tabName: "Custom Individual Sentence",
                            tabIcon: AssignmentIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
        
                                  
                                  <div style={{ maxWidth: '100%' }}>
                                          <MaterialTable
                                            columns={topicDetailIndividualSentenceDataTableColumn2}
                                            data={topicDetailIndividualSentenceDataTableDataSet2}
                                            title="SMAT Review"
                                            options={{
                                              exportButton: true,
                                              exportAllData: true,
                                              filtering: true,
                                              showTitle: false
                                            }}
                                            actions={[
                                              {
                                                icon: TopicIcon,
                                                tooltip: 'Highlight Topic Detail and Sentiment',
                                                onClick: (event, rowData) => {
                                                  // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                  //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                  //setFullReviewModal(true); // Show Pop-up
                                                  //console.log(rowData.individualSentence); 
                                                  onClickHighlight(rowData.individualSentence, rowData.topicDetail, ()=>{});
                                                  setPopUpHighlight(true);
        
                                                }
        
                                              }
                                            ]}
                                            components={{
                                              Action: props => (
        
                                                <Tooltip
                                                  id="tooltip-top"
                                                  title="Highlight Topic Detail and Sentiment"
                                                  placement="top"
                                                  classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                      <HighlightIcon />
                                                    </Button>
                                                </Tooltip>
                                                
                                              )
                                            }}
                                          />
                                    </div>
        
                                    <div>
                                            <Dialog
                                              fullWidth={true}
                                              maxWidth={'lg'}
                                              open={popUpHighlight}
                                              onClose={() => setPopUpHighlight(false)}
                                              //TransitionComponent={Transition}
                                              aria-labelledby="full-review-dialog-title1"
                                            >
                                              <DialogTitle id="full-review-dialog-title1"><strong> Sentence Review </strong></DialogTitle>
                                              <DialogContent>
                                                {/* <DialogContentText> */}
                                                {/* {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )} */}
                                                {/* </DialogContentText> */}
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={sentimentMapping} 
                                                    autoEscape={true}
                                                    textToHighlight={highlightSentence}
                                                  />
                                              
                                              </DialogContent>
                                              <DialogActions>
                                                <Button onClick={() => setPopUpHighlight(false)} color="primary">
                                                  Close
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </div>
         
                              <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={customIndividualSentence2} filename={`${currentDate2}${"_"}${"Custom Individual Sentence_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>
        
                                </GridItem>
        
                                </GridContainer>
        
                                </div>
                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
        
                            
                          }
                        ]}
                      /> 
        
                  </GridItem>


                  <GridItem xs={12}>
                    <Button color="github" size="sm" style={{width: "100%"}}> <b>Trend by Category / Topic / Topic Detail</b> </Button>
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="warning"
                        
                        tabs={[
                          {
                            tabName: "Category / Topic / Topic Detail / Trend",
                            tabIcon: TrendingUpIcon,
                            tabContent: (!topicDetail2IsLoading ? 
                              <div>
                                <GridContainer >
                                  <GridItem 
                                      xs={6}
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="center">

                                {/* <div className={classes.buttonGroup}>
                                  <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                                    Month
                                  </Button>
                                  <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                                    Week
                                  </Button>
                                  <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                                    Day
                                  </Button>
                                </div> */}
                                
                                  </GridItem>

                                  </GridContainer>


                                  <GridContainer>
                                    <GridItem 
                                        xs={6}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">

                                  <div className={classes.buttonGroup}>
                                    <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("star")}>
                                      Star
                                    </Button>
                                    <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("sentiment")}>
                                      Sentiment
                                    </Button>
                                  </div>
                                  
                                    </GridItem>
                                  </GridContainer>


                                <br/>
                                <Bar 
                                  height={50} data={trendByCategoryTopicTopicDetailBarDataSet2}  
                                  options={trendByCategoryTopicTopicDetailBarOption2} /> 
                              </div>
                            

                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={trendByCategoryTopicTopicDetailTableColumn2}
                                    data={trendByCategoryTopicTopicDetailTableDataSet2}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={trendByCategoryTopicTopicDetailTableDataSet2} filename={`${currentDate2}${"_"}${"Trend By Category Topic Topic Detail Trend_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                                
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                    </GridItem>


                    <GridItem xs={12}>
                      <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Ratio Trend</b> </Button>
                    </GridItem>



                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="warning"
                          
                          tabs={[
                            {
                              tabName: "Topic Ratio Trend",
                              tabIcon: TrendingUpIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
                                  <GridContainer >
                                    <GridItem 
                                        xs={6}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">

                                  {/* <div className={classes.buttonGroup}>
                                    <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                                      Month
                                    </Button>
                                    <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                                      Week
                                    </Button>
                                    <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                                      Day
                                    </Button>
                                  </div> */}
                                  
                                    </GridItem>

                                    </GridContainer>

                                  <Bar 
                                    height={50} data={hotTopicTrendBarDataSet2}  
                                    options={hotTopicTrendOption2} /> 
                                </div>
                              

                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!topicDetail2IsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={hotTopicTrendTableColumn2}
                                      data={hotTopicTrendTableDataSet2}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={hotTopicTrendTableDataSet2} filename={`${currentDate2}${"_"}${"Topic Ratio Trend_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                      </GridItem>

{/* Individual Sentence */}
                    {/* <GridContainer>

                    <GridItem xs={12}>
                      <Button color="github" size="sm" style={{width: "100%"}}> <b>Individual Sentence</b> </Button>
                    </GridItem>

                    </GridContainer>


                      <GridItem xs={12} sm={12} md={12}>

                        <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"

                          tabs={[

                            {
                              tabName: "Table",
                              tabIcon: ModelIcon,
                                tabContent: (!viewIndividualSentenceIsLoading2 ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={viewIndividualSentenceTableColumn2}
                                      data={viewIndividualSentenceDataTableDataSet2}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"445px"}
                                      noHeader={true}
                                      />
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                        <CSVLink data={analyzedData2} filename={`${currentDate2}${"_"}${"Analyzed Data_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                  </div>
                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                      </GridItem> */}




        
                  </GridContainer>

                  

                  
                )
              },
              {
                tabButton: "Model 3",
                tabIcon: ModelIcon,
                tabContent: (
                  <GridContainer>

                  <GridItem xs={12} sm={12} md={12} >
        
                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="primary"
                        
                        tabs={[
                          {
                            tabName: "Custom Individual Sentence",
                            tabIcon: AssignmentIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
        
                                  
                                  <div style={{ maxWidth: '100%' }}>
                                          <MaterialTable
                                            columns={topicDetailIndividualSentenceDataTableColumn3}
                                            data={topicDetailIndividualSentenceDataTableDataSet3}
                                            title="SMAT Review"
                                            options={{
                                              exportButton: true,
                                              exportAllData: true,
                                              filtering: true,
                                              showTitle: false
                                            }}
                                            actions={[
                                              {
                                                icon: TopicIcon,
                                                tooltip: 'Highlight Topic Detail and Sentiment',
                                                onClick: (event, rowData) => {
                                                  // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                  //onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                  //setFullReviewModal(true); // Show Pop-up
                                                  //console.log(rowData.individualSentence); 
                                                  onClickHighlight(rowData.individualSentence, rowData.topicDetail, ()=>{});
                                                  setPopUpHighlight(true);
        
                                                }
        
                                              }
                                            ]}
                                            components={{
                                              Action: props => (
        
                                                <Tooltip
                                                  id="tooltip-top"
                                                  title="Highlight Topic Detail and Sentiment"
                                                  placement="top"
                                                  classes={{ tooltip: classes.tooltip }}
                                                >
                                                    <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                      <HighlightIcon />
                                                    </Button>
                                                </Tooltip>
                                                
                                              )
                                            }}
                                          />
                                    </div>
        
                                    <div>
                                            <Dialog
                                              fullWidth={true}
                                              maxWidth={'lg'}
                                              open={popUpHighlight}
                                              onClose={() => setPopUpHighlight(false)}
                                              //TransitionComponent={Transition}
                                              aria-labelledby="full-review-dialog-title1"
                                            >
                                              <DialogTitle id="full-review-dialog-title1"><strong> Sentence Review </strong></DialogTitle>
                                              <DialogContent>
                                                {/* <DialogContentText> */}
                                                {/* {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )} */}
                                                {/* </DialogContentText> */}
                                                <Highlighter
                                                    highlightClassName="YourHighlightClass"
                                                    searchWords={sentimentMapping} 
                                                    autoEscape={true}
                                                    textToHighlight={highlightSentence}
                                                  />
                                              
                                              </DialogContent>
                                              <DialogActions>
                                                <Button onClick={() => setPopUpHighlight(false)} color="primary">
                                                  Close
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </div>
         
                              <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={customIndividualSentence3} filename={`${currentDate3}${"_"}${"Custom Individual Sentence_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>
        
                                </GridItem>
        
                                </GridContainer>
        
                                </div>
                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
        
                            
                          }
                        ]}
                      /> 
        
                  </GridItem>


                  <GridItem xs={12}>
                    <Button color="github" size="sm" style={{width: "100%"}}> <b>Trend by Category / Topic / Topic Detail</b> </Button>
                  </GridItem>


                  <GridItem xs={12} sm={12} md={12} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="warning"
                        
                        tabs={[
                          {
                            tabName: "Category / Topic / Topic Detail / Trend",
                            tabIcon: TrendingUpIcon,
                            tabContent: (!topicDetail2IsLoading ? 
                              <div>
                                <GridContainer >
                                  <GridItem 
                                      xs={6}
                                      container
                                      direction="row"
                                      justify="flex-start"
                                      alignItems="center">

                                {/* <div className={classes.buttonGroup}>
                                  <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                                    Month
                                  </Button>
                                  <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                                    Week
                                  </Button>
                                  <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                                    Day
                                  </Button>
                                </div> */}
                                
                                  </GridItem>

                                  </GridContainer>


                                  <GridContainer>
                                    <GridItem 
                                        xs={6}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">

                                  {/* <div className={classes.buttonGroup}>
                                    <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("star")}>
                                      Star
                                    </Button>
                                    <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendRatingFilter("sentiment")}>
                                      Sentiment
                                    </Button>
                                  </div> */}
                                  
                                    </GridItem>
                                  </GridContainer>


                                <br/>
                                <Bar 
                                  height={50} data={trendByCategoryTopicTopicDetailBarDataSet3}  
                                  options={trendByCategoryTopicTopicDetailBarOption3} /> 
                              </div>
                            

                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={trendByCategoryTopicTopicDetailTableColumn3}
                                    data={trendByCategoryTopicTopicDetailTableDataSet3}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={trendByCategoryTopicTopicDetailTableDataSet3} filename={`${currentDate3}${"_"}${"Trend By Category Topic Topic Detail Trend_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                                
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                    </GridItem>


                    <GridItem xs={12}>
                      <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Ratio Trend</b> </Button>
                    </GridItem>



                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="warning"
                          
                          tabs={[
                            {
                              tabName: "Topic Ratio Trend",
                              tabIcon: TrendingUpIcon,
                              tabContent: (!topicDetail2IsLoading ? 
                                <div>
                                  <GridContainer >
                                    <GridItem 
                                        xs={6}
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">

                                  {/* <div className={classes.buttonGroup}>
                                    <Button color="white" size="sm" className={classes.firstButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("month")}>
                                      Month
                                    </Button>
                                    <Button color="white" size="sm" className={classes.middleButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("week")}>
                                      Week
                                    </Button>
                                    <Button color="white" size="sm" className={classes.lastButton} onClick={onClickCategoryTopicTopicDetailTrendFilter("day")}>
                                      Day
                                    </Button>
                                  </div> */}
                                  
                                    </GridItem>

                                    </GridContainer>

                                  <Bar 
                                    height={50} data={hotTopicTrendBarDataSet3}  
                                    options={hotTopicTrendOption3} /> 
                                </div>
                              

                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!topicDetail2IsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={hotTopicTrendTableColumn3}
                                      data={hotTopicTrendTableDataSet3}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={hotTopicTrendTableDataSet3} filename={`${currentDate3}${"_"}${"Topic Ratio Trend_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                                  
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                      </GridItem>

{/* Individual Sentence */}
                    {/* <GridContainer>

                    <GridItem xs={12}>
                      <Button color="github" size="sm" style={{width: "100%"}}> <b>Individual Sentence</b> </Button>
                    </GridItem>

                    </GridContainer>


                      <GridItem xs={12} sm={12} md={12}>

                        <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"

                          tabs={[

                            {
                              tabName: "Table",
                              tabIcon: ModelIcon,
                                tabContent: (!viewIndividualSentenceIsLoading3 ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={viewIndividualSentenceTableColumn3}
                                      data={viewIndividualSentenceDataTableDataSet3}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"445px"}
                                      noHeader={true}
                                      />
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                        <CSVLink data={analyzedData2} filename={`${currentDate3}${"_"}${"Analyzed Data_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                  </div>
                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                      </GridItem> */}




        
                  </GridContainer>

                  

                  
                )
              }

            ]}
          />
        </GridItem>
      </GridContainer>


{/*  Raw Data Download - Model 1*/}
      <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Data Download</b> </Button>
      </GridItem>

      </GridContainer>



      <GridContainer>

        <GridItem xs={12} sm={12} md={4}>
          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
              
              tabs={[
                {
                  tabName: "Download",
                  tabIcon: AssignmentIcon,
                    tabContent: (!viewIndividualSentenceIsLoading1 ? 
                      <div>
                        
                        {/* <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                  columns={viewIndividualSentenceTableColumn}
                                  data={viewIndividualSentenceDataTableDataSet}
                                  title="SMAT Review"
                                  options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    filtering: true,
                                    showTitle: false
                                  }}
                                />
                          </div> */}

                    <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        <CSVLink data={analyzedData1} filename={`${currentDate1}${"_"}${"Analyzed Data_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                        
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Analyzed Data 
                          </Button>
                        </CSVLink>

                        <CSVLink data={rawData1} filename={`${currentDate1}${"_"}${"Raw Data_"}${brandSelection1}${"_"}${modelSelectionCustom1}${".csv"}`}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Raw Data 
                          </Button>
                        </CSVLink>

                      </GridItem>

                      </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>

{/*  Raw Data Download - Model 2 */}
        <GridItem xs={12} sm={12} md={4}>
          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
              
              tabs={[
                {
                  tabName: "Download",
                  tabIcon: AssignmentIcon,
                    tabContent: (!viewIndividualSentenceIsLoading2 ? 
                      <div>
                        
                        {/* <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                  columns={viewIndividualSentenceTableColumn}
                                  data={viewIndividualSentenceDataTableDataSet}
                                  title="SMAT Review"
                                  options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    filtering: true,
                                    showTitle: false
                                  }}
                                />
                          </div> */}

                    <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        <CSVLink data={analyzedData2} filename={`${currentDate2}${"_"}${"Analyzed Data_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                        
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Analyzed Data 
                          </Button>
                        </CSVLink>

                        <CSVLink data={rawData2} filename={`${currentDate2}${"_"}${"Raw Data_"}${brandSelection2}${"_"}${modelSelectionCustom2}${".csv"}`}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Raw Data 
                          </Button>
                        </CSVLink>

                      </GridItem>

                      </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>



{/*  Raw Data Download - Model 3 */}
        <GridItem xs={12} sm={12} md={4}>
          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
              
              tabs={[
                {
                  tabName: "Download",
                  tabIcon: AssignmentIcon,
                    tabContent: (!viewIndividualSentenceIsLoading3 ? 
                      <div>
                        
                        {/* <div style={{ maxWidth: '100%' }}>
                                <MaterialTable
                                  columns={viewIndividualSentenceTableColumn}
                                  data={viewIndividualSentenceDataTableDataSet}
                                  title="SMAT Review"
                                  options={{
                                    exportButton: true,
                                    exportAllData: true,
                                    filtering: true,
                                    showTitle: false
                                  }}
                                />
                          </div> */}

                    <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        <CSVLink data={analyzedData3} filename={`${currentDate3}${"_"}${"Analyzed Data_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                        
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Analyzed Data 
                          </Button>
                        </CSVLink>

                        <CSVLink data={rawData3} filename={`${currentDate3}${"_"}${"Raw Data_"}${brandSelection3}${"_"}${modelSelectionCustom3}${".csv"}`}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Raw Data 
                          </Button>
                        </CSVLink>

                      </GridItem>

                      </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>

        </GridContainer>





    </div></div>: <LinearProgressWithLabel value={progress} style={{textAlign: 'center'}}/>)
  );
}
