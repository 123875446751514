/*eslint-disable */

// import Buttons from "views/Components/Buttons.js";
// import Calendar from "views/Calendar/Calendar.js";
// import Charts from "views/Charts/Charts.js";
// import Dashboard from "views/Dashboard/Dashboard.js";
// import ErrorPage from "views/Pages/ErrorPage.js";
// import ExtendedForms from "views/Forms/ExtendedForms.js";
// import ExtendedTables from "views/Tables/ExtendedTables.js";
// import FullScreenMap from "views/Maps/FullScreenMap.js";
// import GoogleMaps from "views/Maps/GoogleMaps.js";
// import GridSystem from "views/Components/GridSystem.js";
// import Icons from "views/Components/Icons.js";
// import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
// import Notifications from "views/Components/Notifications.js";
// import Panels from "views/Components/Panels.js";
// import PricingPage from "views/Pages/PricingPage.js";
// import RTLSupport from "views/Pages/RTLSupport.js";
// import ReactTables from "views/Tables/ReactTables.js";
// import RegisterPage from "views/Pages/RegisterPage.js";
// import RegularForms from "views/Forms/RegularForms.js";
// import RegularTables from "views/Tables/RegularTables.js";
// import SweetAlert from "views/Components/SweetAlert.js";
// import TimelinePage from "views/Pages/Timeline.js";
// import Typography from "views/Components/Typography.js";
// import UserProfile from "views/Pages/UserProfile.js";
// import ValidationForms from "views/Forms/ValidationForms.js";
// import VectorMap from "views/Maps/VectorMap.js";
// import Widgets from "views/Widgets/Widgets.js";
// import Wizard from "views/Forms/Wizard.js";

import Home from "views/Home/Home.js";
import StarRatingDashboard from "views/StarRatingDashboard/StarRatingDashboard.js";
import StarRatingAnalytic from "views/StarRatingAnalytic/StarRatingAnalytic.js";
import StarRatingAnalyticCN from "views/StarRatingAnalyticCN/StarRatingAnalyticCN.js";
import CompetitorAnalyticCN from "views/CompetitorAnalyticCN/CompetitorAnalyticCN.js"
import CustomCategories from "views/CustomCategories/CustomCategories.js";
import AudioRatingAnalytic from "views/AudioRatingAnalytic/AudioRatingAnalytic.js";
import CompetitorAnalytic from "views/CompetitorAnalytic/CompetitorAnalytic.js";
import AppRatingAnalytic from "views/AppRatingAnalytic/AppRatingAnalytic.js";
import CreateReport from "views/CreateReport/CreateReport.js";
import NewModelRequest from "views/NewModelRequest/NewModelRequest.js";
import ReleaseNotes from "views/ReleaseNotes/ReleaseNotes.js";
//import EciaDataAnalytic from "views/EciaDataAnalytic/EciaDataAnalytic.js";
import SocialAnalytic from "views/SocialAnalytic/SocialAnalytic.js";
import ModelConfiguration from "views/ModelConfiguration/ModelConfiguration.js";
//import ModelConfiguration from "views/ModelConfiguration/ModelConfiguration.js";
import TwitterAnalytic from "views/TwitterAnalytic/TwitterAnalytic.js";



// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import DateRange from "@material-ui/icons/DateRange";
// import GridOn from "@material-ui/icons/GridOn";
// import Image from "@material-ui/icons/Image";
// import Place from "@material-ui/icons/Place";
// import Timeline from "@material-ui/icons/Timeline";
// import WidgetsIcon from "@material-ui/icons/Widgets";

import HomeIcon from "@material-ui/icons/Home";
import StarRatingDashboardIcon from "@material-ui/icons/TrendingUp";
import StarRatingAnalyticIcon from "@material-ui/icons/StarRate";
import StarRatingAnalyticCnIcon from "@material-ui/icons/Stars";
import CustomCategoriesIcon from "@material-ui/icons/Category";
import AudioRatingAnalyticIcon from "@material-ui/icons/VolumeUp";
import CompetitorAnalyticIcon from "@material-ui/icons/Compare";
import AppRatingAnalyticIcon from "@material-ui/icons/PhoneIphone";
import CreateReportIcon from "@material-ui/icons/Mail";
import NewModelRequestIcon from "@material-ui/icons/AddBox";
import ReleaseNotesIcon from "@material-ui/icons/Note";
import LoginIcon from "@material-ui/icons/AccountCircle";
import DataIcon from "@material-ui/icons/DataUsage";
import ForumIcon from "@material-ui/icons/Forum";
import TvIcon from "@material-ui/icons/Tv";
import ModelConfigurationIcon from "@material-ui/icons/Update";
import TwitterIcon from "@material-ui/icons/Twitter";
import ChinaIcon from "@material-ui/icons/FolderSpecial";

var dashRoutes = [
  {
    path: "/login-page",
    name: "Login Page",
    rtlName: "Login Page",
    icon: LoginIcon,
    rtlMini: "Login Page",
    component: LoginPage,
    layout: "/auth"
  },
  {
    path: "/home",
    name: "Home",
    rtlName: "Home",
    icon: HomeIcon,
    component: Home,
    layout: "/admin"
  },

  {
    path: "/star-rating-dashboard",
    name: "Star Rating Dashboard",
    rtlName: "",
    icon: StarRatingDashboardIcon,
    component: StarRatingDashboard,
    layout: "/admin"
  },

  {
    path: "/star-rating-analytic",
    name: "Star Rating Analytic",
    rtlName: "",
    icon: StarRatingAnalyticIcon,
    component: StarRatingAnalytic,
    layout: "/admin"
  },

  {
    collapse: true,
    name: "China",
    rtlName: "China",
    icon: ChinaIcon,
    state: "tablesCollapse",
    views: [
      {
        path: "/tv-rating-cn",
        name: "TV Rating Analytic",
        rtlName: "",
        // icon: TvIcon,
        mini: "CN",
        rtlMini: "CN",
        component: StarRatingAnalyticCN,
        layout: "/admin"
      },
      {
        path: "/competitors-analytic-cn",
        name: "Competitor Analytic",
        rtlName: "Competitor Analytic",
        // icon: TvIcon,
        mini: "CN",
        rtlMini: "CN",
        component: CompetitorAnalyticCN,
        layout: "/admin"
      },
    ]
  },

  // {
  //   path: "/tv-rating-cn",
  //   name: "TV Rating Analytic - CN",
  //   rtlName: "",
  //   icon: TvIcon,
  //   component: StarRatingAnalyticCN,
  //   layout: "/admin"
  // },

  {
    path: "/custom-categories",
    name: "Custom Categories",
    rtlName: "",
    icon: CustomCategoriesIcon,
    component: CustomCategories,
    layout: "/admin"
  },

  {
    path: "/audio-rating-analytic",
    name: "Audio Rating Analytic",
    rtlName: "",
    icon: AudioRatingAnalyticIcon,
    component: AudioRatingAnalytic,
    layout: "/admin"
  },

  {
    path: "/competitor-analytic",
    name: "Competitor Analytic",
    rtlName: "",
    icon: CompetitorAnalyticIcon,
    component: CompetitorAnalytic,
    layout: "/admin"
  },

  {
    path: "/app-rating-analytic",
    name: "App Rating Analytic",
    rtlName: "",
    icon: AppRatingAnalyticIcon,
    component: AppRatingAnalytic,
    layout: "/admin"
  },

  // {
  //   path: "/social-analytic",
  //   name: "Social Analytic",
  //   rtlName: "",
  //   icon: ForumIcon,
  //   component: SocialAnalytic,
  //   layout: "/admin"
  // },

  // {
  //   path: "/ecia-data-analytic",
  //   name: "ECIA Data Analytic",
  //   rtlName: "",
  //   icon: DataIcon,
  //   component: EciaDataAnalytic,
  //   layout: "/admin"
  // },

  {
    path: "/create-report",
    name: "Create Report",
    rtlName: "Create Report",
    icon: CreateReportIcon,
    component: CreateReport,
    layout: "/admin"
  },

  {
    path: "/new-model-request",
    name: "New Model Request",
    rtlName: "New Model Request",
    icon: NewModelRequestIcon,
    component: NewModelRequest,
    layout: "/admin"
  },

  {
    path: "/model-configuration",
    name: "Model Configuration",
    rtlName: "Model Configuration",
    icon: ModelConfigurationIcon,
    component: ModelConfiguration,
    layout: "/admin"
  },

  {
    path: "/release-notes",
    name: "Release Notes",
    rtlName: "Release Notes",
    icon: ReleaseNotesIcon,
    component: ReleaseNotes,
    layout: "/admin"
  },

  // {
  //   path: "/twitter-analytic",
  //   name: "Twitter Analytic",
  //   rtlName: "Twitter Analytic",
  //   icon: TwitterIcon,
  //   component: TwitterAnalytic,
  //   layout: "/admin"
  // },


];
export default dashRoutes;


