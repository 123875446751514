/*eslint-disable*/

import React from "react";

// CSS
import "../../assets/css/page-loading.css"

import PropTypes from "prop-types";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"

// 3rd Party Component
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from "@material-ui/core/InputAdornment";
import ModelIcon from "@material-ui/icons/Devices";
import EmailIcon from "@material-ui/icons/Email";
import WebsiteIcon from "@material-ui/icons/Language";
import BrandIcon from "@material-ui/icons/LocalOffer";
import SendIcon from "@material-ui/icons/Send";
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import RefreshIcon from "@material-ui/icons/Refresh";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DownloadIcon from "@material-ui/icons/GetApp";
import TopicIcon from "@material-ui/icons/ListAlt";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import LockIcon from "@material-ui/icons/Lock";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";




import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStyles = makeStyles(styles);

export default function ModelConfiguration() {

  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Model Configuration"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])



    // Get Data from SMAT REST API
    React.useEffect(() => {
    const axios = require('axios');

    axios.get(SMATrestAPI.modelConfiguration[0].modelList).then(function (response) { 
      // handle success
      setModelList(response.data.getModelList);

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });


    axios.get(SMATrestAPI.modelConfiguration[0].savedModelList).then(function (response) { 
      // handle success
      setSavedModelList(response.data.SavedModelList);

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }, [])


  const [modelList, setModelList] = React.useState([]);
  const [savedModelList, setSavedModelList] = React.useState([]);
  const [reportCode, setReportCode] = React.useState('none');

  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");
 
  // Model table - Table using material-table

  const modelConfigurationTableColumn = [
    {
      title: 'Brand',
      field: 'brand'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Model',
      field: 'model'
    },
    {
      title: 'Website',
      field: 'website'
    },
    {
      title: 'Country',
      field: 'country'
    }
  ];



  const modelConfigurationTableDataSet = modelList.map((data) => {
    return ({
      //id: data.id,
      brand: data['Brand'],
      category: data['Product.Category'],
      model: data['Model'],
      website: data['Website'],
      country: data['Country']
    })
  });


    // Model table - Table using material-table

    var savedModelConfigurationTableColumn = [
      {
        title: 'Brand',
        field: 'brand'
      },
      {
        title: 'Category',
        field: 'category'
      },
      {
        title: 'Model',
        field: 'model'
      },
      {
        title: 'Website',
        field: 'website'
      },
      {
        title: 'Country',
        field: 'country'
      }
    ];
  
  
    var saveModelConfigurationTableDataSet = savedModelList.map((data) => {
      return ({
        //id: data.id,
        brand: data['Brand'],
        category: data['Product.Category'],
        model: data['Model'],
        website: data['Website'],
        country: data['Country']
      })
    });
  


  const onClickAddModel = (brand, category, model, website, country) => {
    
    const axios = require('axios');

    // Add / Remove Model
    //setCategoryDetailSoundtopicInsightIsLoading(true);
    let sendModelInfo = `${"&brand="}${brand}${"&category="}${category}${"&model="}${model}${"&website="}${website}${"&country="}${country}${"&key="}${reportCode}`;
    sendModelInfo = sendModelInfo.replaceAll(",", '%2C'); // replace comma for url
    sendModelInfo = sendModelInfo.replaceAll(" ", '%20'); // empty space for url
  
    axios.get(`${SMATrestAPI.modelConfiguration[0].addModel}${sendModelInfo}`).then(function (response) { 
      // handle success
  
  
      setSavedModelList(response.data.SavedModelList);
    
      
    
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      //setFullReviewModal(true); // Show Pop-up
      //setTimeout(() => {setFullReviewDrillDownIsLoading(false)}, 500); // Turn Spinner Off after .5 second
    });
    
  
  }


  const onClickRemoveModel = (brand, category, model, website, country) => {

    const axios = require('axios');

    // Add / Remove Model
    //setCategoryDetailSoundtopicInsightIsLoading(true);
    let sendModelInfo = `${"&brand="}${brand}${"&category="}${category}${"&model="}${model}${"&website="}${website}${"&country="}${country}${"&key="}${reportCode}`;
    sendModelInfo = sendModelInfo.replaceAll(",", '%2C'); // replace comma for url
    sendModelInfo = sendModelInfo.replaceAll(" ", '%20'); // empty space for url
  
    axios.get(`${SMATrestAPI.modelConfiguration[0].removeModel}${sendModelInfo}`).then(function (response) { 
      // handle success
  
  
      setSavedModelList(response.data.SavedModelList);
    
      
    
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      //setFullReviewModal(true); // Show Pop-up
      //setTimeout(() => {setFullReviewDrillDownIsLoading(false)}, 500); // Turn Spinner Off after .5 second
    });
    
  
  }

  const classesSweetAlert = useStylesSweetAlert();
  const classes = useStyles();

  return (
    <div>

      <div id="pageLoader" className={loadPage1}></div>

      <div id="mainPage" style={{display:loadPage2}}>

        <GridContainer>
            <GridItem xs={12}>
                <Button color="github" size="sm" style={{width: "100%"}}> <b>Model Configuration</b> </Button>
            </GridItem>

        </GridContainer>

{/* Password */}

    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle} style={{color: "white"}}><strong>Setting</strong></h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* onSubmit={e => { e.preventDefault()  */}
              <form  id='editDelete-form' > 
                <GridContainer>
                  <GridItem xs={12} sm={1}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Password</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={11}>
                    <CustomInput
                      id="codeID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        //placeholder: "Example: Add drill down for all charts within the competitor tab",
                        onBlur: event => {setReportCode(event.target.value)},
                        type: "password",
                        autoComplete: "off",
                        endAdornment: (<InputAdornment position="end"><LockIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>



              </form>
            </CardBody>

            <br/>
          </Card>
        </GridItem>
    </GridContainer>

{/* Model List */}

      <GridContainer>
   
        <GridItem xs={12} sm={12} md={6} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
              
              tabs={[
                {
                  tabName: "Add Model",
                  tabIcon: AssignmentIcon,
                    tabContent: (
                      <div>    
                          <div style={{ maxWidth: '100%' }}>
                                  <MaterialTable
                                    columns={modelConfigurationTableColumn}
                                    data={modelConfigurationTableDataSet}
                                    title="Model List"
                                    options={{
                                      exportButton: false,
                                      exportAllData: false,
                                      filtering: true,
                                      showTitle: false,
                                      searchAutoFocus: true,
                                      pageSize: 5
                                    }}
                                    actions={[
                                      {
                                        icon: AddIcon,
                                        tooltip: 'Add Model',
                                        onClick: (event, rowData) => {
                                          // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                          onClickAddModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                          

                                        }

                                      }
                                    ]}
                                    components={{
                                      Action: props => (

                                        <Tooltip
                                          id="tooltip-bottom"
                                          title="Add Model"
                                          placement="top"
                                          classes={{ tooltip: classes.tooltip }}
                                        >
                                            <Button color="success" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                              <AddIcon />
                                            </Button>
                                        </Tooltip>
                                        
                                      )
                                    }}
    
                                  />
                            </div>


                        </div>
                  )

                  
                }
              ]}
            /> 

          </GridItem>

{/* Saved Model List */}

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
                
                tabs={[
                  {
                    tabName: "Saved Model List",
                    tabIcon: AssignmentIcon,
                      tabContent: (
                        <div>    
                            <div style={{ maxWidth: '100%' }}>
                                    <MaterialTable
                                      columns={savedModelConfigurationTableColumn}
                                      data={saveModelConfigurationTableDataSet}
                                      title="Model List"
                                      options={{
                                        exportButton: false,
                                        exportAllData: false,
                                        filtering: true,
                                        showTitle: false,
                                        searchAutoFocus: true,
                                        pageSize: 5
                                      }}
                                      actions={[
                                        {
                                          icon: RemoveIcon,
                                          tooltip: 'Remove Model',
                                          onClick: (event, rowData) => {
                                            // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                            onClickRemoveModel(rowData.brand, rowData.category, rowData.model, rowData.website, rowData.country, ()=>{});
                                            

                                          }

                                        }
                                      ]}
                                      components={{
                                        Action: props => (

                                          <Tooltip
                                            id="tooltip-bottom"
                                            title="Remove Model"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                          >
                                              <Button color="danger" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                <RemoveIcon />
                                              </Button>
                                          </Tooltip>

                                          
                                        )
                                      }}

                                    />
                              </div>


                          </div>
                    )

                    
                  }
                ]}
              /> 

            </GridItem>
          
        </GridContainer>



    </div></div>



                    
  );



}
