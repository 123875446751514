// /*eslint-disable*/

import React, { Fragment } from "react";

// CSS
import "../../assets/css/page-loading.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Instruction from "components/Instruction/Instruction.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import BrandIcon from "@material-ui/icons/LocalOffer";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import FilterIcon from "@material-ui/icons/FilterList";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar, Pie } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);


const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="h4" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};


export default function TwitterAnalytic() {


    const axios = require('axios');




  // SMAT Log
  React.useEffect(() => {
    const axios = require('axios');

    // let restGETurl = `${"smatCategory="}${"Twitter"}`;
    // restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    // restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    // axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    // }).catch(function (error) { 
    //   // handle error
    //   console.log(error);
    // }).then(function () { 
    //   // always executed
    // });


    // Get Master Model Table 
    axios.get(SMATrestAPI.twiiterAnalytic[0].getAllModels).then(function (response) { 

    // handle success
    //localStorage.setItem("modelList", JSON.stringify(response.data.getFilter));
    setFullModelList(response.data.getFilter);

    let retrievedModelList = response.data.getFilter
    let uniqueBrand = [...new Set(retrievedModelList.map(x => x.Brand))];
    // uniqueBrand.unshift("Sony");

    setBrandItems(uniqueBrand);


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });


    return () => {
      // Do some cleanup  
      setBrandItems([""]);
     }

  }, [])





  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader"); // "loader"
  const [loadPage2, setLoadPage2] = React.useState("none"); // "none"

  // Get Trend Data via SMAT RESTAPI
  // filters
  const [fullModelList, setFullModelList] = React.useState(['']);

  const [brandItems, setBrandItems] = React.useState(['']);
  const [brandSelection, setBrandSelection] = React.useState('');

  const [categoryItems, setCategoryItems] = React.useState([]);
  const [categorySelection, setCategorySelection] = React.useState('');

  const [modelItems, setModelItems] = React.useState([]);
  const [modelSelection, setModelSelection] = React.useState([]);

  const [websiteItems, setWebsiteItems] = React.useState([]);
  const [websiteSelection, setWebsiteSelection] = React.useState([]);

  const [countryItems, setCountryItems] = React.useState([]);
  const [countrySelection, setCountrySelection] = React.useState([]);

  const [dateStartItem, setDateStartItem] = React.useState('');
  const [dateStartSelection, setDateStartSelection] = React.useState('');

  const [dateEndItem, setDateEndItem] = React.useState('');
  const [dateEndSelection, setDateEndSelection] = React.useState('');

  const [uniqueTweet, setUniqueTweet] = React.useState(true);


  // Loading by Section
  const [sentimentTrendIsLoading, setSentimentTrendIsLoading] = React.useState(false);


  // Sentiment Trend
  const [sentimentTrendData, setSentimentTrendData] = React.useState([]);
  const [sentimentTrendDataPercentCum, setSentimentTrendDataPercentCum] = React.useState([]);

  // Topic Overview
  const [topicListData, setTopicListData] = React.useState([]);
  const [topicBySentimentData, setTopicBySentimentData] = React.useState([]);
  const [topicOverviewRawData, setTopicOverviewRawData] = React.useState([]);

  // Sentiment Overview
  const [sentimentOverviewPosData, setSentimentOverviewPosData] = React.useState([]);
  const [sentimentOverviewNegData, setSentimentOverviewNegData] = React.useState([]);

  // Raw Data
  const [rawData, setRawData] = React.useState([]);



  const handleBrand= event => {
    setBrandSelection(event.target.value);
    setCategorySelection('');
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleCategory = event => {
    setCategorySelection(event.target.value);
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleModel = event => {
    setModelSelection(event.target.value);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };


  const handleCountry = event => {
    setCountrySelection(event.target.value);
    setWebsiteSelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };


  const handleWebsite = event => {
    setWebsiteSelection(event.target.value);
    setDateStartSelection('');
    setDateEndSelection('');
  };


  const handleDateStart = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
  
      setDateStartSelection(aDate);
    }


  }

  const handleDateEnd = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
      
      setDateEndSelection(aDate);
    }


  }


  var yesterday = moment(dateStartItem, 'YYYY-MM-DD').subtract(1, "day");
  function validStart(current) {
    return current.isAfter(yesterday);
  }

  var tommorrow = moment(dateEndItem, 'YYYY-MM-DD').add(1, "day");
  function validEnd(current) {
    return current.isBefore(tommorrow);
  }


// Get Filter Data from SMAT REST API - Category
  React.useEffect(() => {
    
    const controller = new AbortController();
  
    if(brandSelection !== ''){
  
      let result = fullModelList.filter(x => x.Brand === brandSelection);
      result = [...new Set(result.map(x => x['Product Category']))];

      setCategoryItems(result);
  
    }

    return () => controller.abort();

  }, [brandSelection])


  // Get Filter Data from SMAT REST API - Model
  React.useEffect(() => {
  
    const controller = new AbortController();
    
    if(categorySelection !== ''){
    
      let result = fullModelList.filter(x => x.Brand === brandSelection);
      result = result.filter(x => x['Product Category'] === categorySelection);
      result = [...new Set(result.map(x => x['Model']))];
  
      setModelItems(result);
  
  
    }
  
      return () => controller.abort();
  
    }, [categorySelection])





  // Get Filter Data from SMAT REST API - Country
  React.useEffect(() => {
  
    const controller = new AbortController();
    
    if(categorySelection !== ''){
    
      let result = fullModelList.filter(x => x.Brand === brandSelection);
      result = result.filter(x => x['Product Category'] === categorySelection);
      result = result.filter(x => x['Model'] == modelSelection[0] || x['Model'] == modelSelection[1] || x['Model'] == modelSelection[2] || x['Model'] == modelSelection[3] || x['Model'] == modelSelection[4] || x['Model'] == modelSelection[5] || x['Model'] == modelSelection[6] || x['Model'] == modelSelection[7] || x['Model'] == modelSelection[8] || x['Model'] == modelSelection[9]);
      //result = result.filter(x => x['Website'] == websiteSelection[0] || x['Website'] == websiteSelection[1] || x['Website'] == websiteSelection[2] || x['Website'] == websiteSelection[3] || x['Website'] == websiteSelection[4] || x['Website'] == websiteSelection[5] || x['Website'] == websiteSelection[6] || x['Website'] == websiteSelection[7] || x['Website'] == websiteSelection[8] || x['Website'] == websiteSelection[9]);
      result = [...new Set(result.map(x => x['Country']))];
  
      setCountryItems(result);
  
  
    
    }
  
    return () => controller.abort();
  
    }, [modelSelection])



    // Get Filter Data from SMAT REST API - Date
  React.useEffect(() => {
  
    const controller = new AbortController();
  
  if(countrySelection !== ''){
  
    let restGETurl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&country="}${countrySelection}${"&unique="}${uniqueTweet}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.twiiterAnalytic[0].getDate}${restGETurl}`).then(function (response) { 
      // handle success
  
      setDateStartItem(response.data.startDate);
      setDateEndItem(response.data.endDate);
  
      setDateStartSelection(response.data.startDate);
      setDateEndSelection(response.data.endDate);
  
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

    return () => controller.abort();
  
  }
  }, [countrySelection])







  const getTwitterData = async () => {
    // Topic Insight Data Filter
    let restGETurl5 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&unique="}${uniqueTweet}`;
    restGETurl5 = restGETurl5.replaceAll(",", '%2C'); // replace comma for url
    restGETurl5 = restGETurl5.replaceAll(" ", '%20'); // empty space for url
  
    try {
      const resp = await axios.get(`${SMATrestAPI.twiiterAnalytic[0].getData}${restGETurl5}`);
      setSentimentTrendData(resp.data.uniqueTrendData);
      setSentimentTrendDataPercentCum(resp.data.uniqueTrendDataPercentCum);

      setTopicListData(resp.data.topicList);
      setTopicBySentimentData(resp.data.topicBySentiment);
      setTopicOverviewRawData(resp.data.topicTable);
      setSentimentOverviewPosData(resp.data.sentimentListPos);
      setSentimentOverviewNegData(resp.data.sentimentListNeg);
      setRawData(resp.data.rawData);

  
    } catch (err) {
        // Handle Error Here
        console.error(err);
  
    }
  
    // setProgress(prevCount => prevCount + 10);
  
  };


  const onClickChartData = event => {


    getTwitterData();

    // const axios = require('axios');


      // Send Model Information to SMAT DB after user clicks submit
      // var restGETurl0 = `${"page="}${"Custom Categories"}${"&brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
      // restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
      // restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

      // axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl0}`).then(function (response) { 

      // }).catch(function (error) { 
      //   // handle error
      //   console.log(error);
      // }).then(function () { 
      //   // always executed
      // });


  }





  // Star Rating Trend Unique - Info Box
let positiveTrendData = sentimentTrendData.map(data => data['Positive']);
let neutralTrendrData = sentimentTrendData.map(data => data['Neutral']);
let negativeTrendData = sentimentTrendData.map(data => data['Negative']);
let totalTrendData = sentimentTrendData.map(data => data['Total']);

let positiveDataPercentCum = sentimentTrendDataPercentCum.map(data => data['Positive']);
let neutralDataPercentCum  = sentimentTrendDataPercentCum.map(data => data['Neutral']);
let negativeDataPercentCum  = sentimentTrendDataPercentCum.map(data => data['Negative']);
let cumulativeTotal  = sentimentTrendData.map(data => data['Cumulative Total']);


// Star Rating Trend Unique - Line Chart

let sentimentTrendViewTrendLabel = sentimentTrendDataPercentCum.map(data => data.Date);

let sentimentTrendViewTrendDataSet = {
  type: 'line',
  labels: sentimentTrendViewTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: positiveDataPercentCum ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Neutral',
      data: neutralDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: negativeDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: cumulativeTotal,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const sentimentTrendViewTrendOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },
  
  spanGaps: true,

  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      distribution: 'series',
      time: {
          unit: 'day' //'month'
      }
  }]
  },
}

// Star Rating Trend Unique Table using react-data-table-component

const sentimentTrendTableDataSet = 
sentimentTrendData.map((SentimentTrendData) => {
    return ({
      id: SentimentTrendData.id,
      date: SentimentTrendData.Date,
      pos: SentimentTrendData['Positive'],
      neu: SentimentTrendData['Neutral'],
      neg: SentimentTrendData['Negative'],
      total: SentimentTrendData['Total'],
      cumulative: SentimentTrendData['Cumulative Total']
    })
  });
  
const uniqueTrendTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'pos',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neu',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'neg',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];




// Topic Overview - Wordcloud

const topicOverviewDataWords = topicListData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicOverviewDataWordsDataCallbacks = {
  onWordClick: (word) => {
    //console.log(word.text);


  }, 



  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicOverviewDataOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicOverviewDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicOverviewDataWordsDataCallbacks}
    options={topicOverviewDataOptions}
    words={topicOverviewDataWords} 
  />
}



// Topic List -  Table using react-data-table-component

const topicOverviewDataTableDataSet = topicListData.map((data) => {
  return ({
    id: data.id,
    topic: data['WORD'],
    freq: data['FREQ']
  })
});

const topicOverviewDataTableColumn = [
{
  name: 'Topic',
  selector: 'topic',
  sortable: true,
  center: true
},
{
  name: 'Frequency',
  selector: 'freq',
  sortable: true,
  center: true
}
];



// Topic Overview - Topic by Sentiment - Stack Bar Chart

const slicedTopicBySentimentData = topicBySentimentData.slice(0, 20);

let topicBySentimentDataLabel = slicedTopicBySentimentData.map(data => data['Topic List']);
let topicBySentimentDataPositive = slicedTopicBySentimentData.map(data => data['Positive']);
let topicBySentimentDataNeutral = slicedTopicBySentimentData.map(data => data['Neutral']);
let topicBySentimentDataNegative = slicedTopicBySentimentData.map(data => data['Negative']);

let topicBySentimentDataSet = {
  type: 'bar',
  labels: topicBySentimentDataLabel,
  datasets: [
    {
      label: 'Positive',
      data: topicBySentimentDataPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicBySentimentDataNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicBySentimentDataNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicBySentimentDataOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // // reset the data
      // setOpenModalTopicInsightTopicPhrase(true); // Show Pop-up
      // setTopicInsightTopicPhraseDrillDownData([]);
      // setTopicInsightTopicPhrasedPositiveDrillDownData([]);
      // setTopicInsightTopicPhraseNegativeDrillDownData([]);
      // setModalTitle('');
      // setDrillDownIsLoading(true); 

      // let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownTopicPhrase="}${xLabel}${"&drillDownTopic="}${topicInsightSelection}`;
      // drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      // drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      // const axios = require('axios');
      // axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsightTopicPhraseDrillDown}${drillDownUrl}`).then(function (response) { 
      //   // handle success

      //   setTopicInsightTopicPhraseDrillDownData(response.data.individualSentenceDrillDown);
      //   setTopicInsightTopicPhrasedPositiveDrillDownData(response.data.topicDescriptionPos);
      //   setTopicInsightTopicPhraseNegativeDrillDownData(response.data.topicDescriptionNeg);
      //   setModalTitle(`${"Topic Phrase = "}${xLabel}`);
        
      // }).catch(function (error) { 
      //   // handle error
      //   console.log(error);
      // }).then(function () { 
      //   // always executed
        
      //   setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      // });

    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Detail - Topic Breakdown Table using react-data-table-component

const topicBySentimentDataTableDataSet = topicBySentimentData.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic List'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
const topicBySentimentDataTableColumn = [
  {
    name: 'Topic List',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];




// Topic Overview -  - Raw Data - Table using material-table

const topicOverviewRawDataTableColumn = [
  {
    title: 'Date',
    field: 'date'
  },
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'English Text',
    field: 'englishText',
    cellStyle: {
      width: 400,
      minWidth: 400
    },
    headerStyle: {
      width: 400,
      minWidth: 400
    }
  },
  {
    title: 'Native Text',
    field: 'nativeText',
    cellStyle: {
      width: 400,
      minWidth: 400
    },
    headerStyle: {
      width: 400,
      minWidth: 400
    }
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  },
  {
    title: 'Positive list',
    field: 'posList'
  },
  {
    title: 'Negative list',
    field: 'negList'
  }
];


const topicOverviewRawDataTableDataSet = topicOverviewRawData.map((data) => {
  return ({
    // id: data.id,
    date: data.Date,
    model: data['Model'],
    topic: data['Topic'],
    englishText: data['English_Text'],
    nativeText: data['Native_Text'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});


// Positive Sentiment - Wordcloud

const positiveSentimentDataWords = sentimentOverviewPosData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const positiveSentimentDataWordsDataCallbacks = {
  onWordClick: (word) => {
    console.log(word.text);


  }, 



  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const positiveSentimentDataOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function positiveSentimentDataWordCloud() {
  return <ReactWordcloud
    callbacks={positiveSentimentDataWordsDataCallbacks}
    options={positiveSentimentDataOptions}
    words={positiveSentimentDataWords} 
  />
}



// Topic List -  Table using react-data-table-component

const positiveSentimentDataTableDataSet = sentimentOverviewPosData.map((data) => {
  return ({
    id: data.id,
    topic: data['WORD'],
    freq: data['FREQ']
  })
});

const positiveSentimentDataTableColumn = [
{
  name: 'Topic',
  selector: 'topic',
  sortable: true,
  center: true
},
{
  name: 'Frequency',
  selector: 'freq',
  sortable: true,
  center: true
}
];



// Negative Sentiment - Wordcloud

const negativeSentimentDataWords = sentimentOverviewNegData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const negativeSentimentDataWordsDataCallbacks = {
  onWordClick: (word) => {
    console.log(word.text);


  }, 



  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const negativeSentimentDataOptions = {
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function negativeSentimentDataWordCloud() {
  return <ReactWordcloud
    callbacks={negativeSentimentDataWordsDataCallbacks}
    options={negativeSentimentDataOptions}
    words={negativeSentimentDataWords} 
  />
}



// Topic List -  Table using react-data-table-component

const negativeSentimentDataTableDataSet = sentimentOverviewNegData.map((data) => {
  return ({
    id: data.id,
    topic: data['WORD'],
    freq: data['FREQ']
  })
});

const negativeSentimentDataTableColumn = [
{
  name: 'Topic',
  selector: 'topic',
  sortable: true,
  center: true
},
{
  name: 'Frequency',
  selector: 'freq',
  sortable: true,
  center: true
}
];





// Raw Data - Table using material-table

const rawDataTableColumn = [
  {
    title: 'Date',
    field: 'date'
  },
  {
    title: 'Platform',
    field: 'platform'
  },
  {
    title: 'Product Category',
    field: 'productCategory'
  },
  {
    title: 'Brand',
    field: 'brand'
  },
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Source',
    field: 'source'
  },
  {
    title: 'Username',
    field: 'username'
  },
  {
    title: 'URL',
    field: 'url'
  },
  {
    title: 'English Text',
    field: 'englishText',
    cellStyle: {
      width: 400,
      minWidth: 400
    },
    headerStyle: {
      width: 400,
      minWidth: 400
    }
  },
  {
    title: 'Native Text',
    field: 'nativeText',
    cellStyle: {
      width: 400,
      minWidth: 400
    },
    headerStyle: {
      width: 400,
      minWidth: 400
    }
  },
  {
    title: 'Topic 1',
    field: 'topic1'
  },
  {
    title: 'Topic 2',
    field: 'topic2'
  },
  {
    title: 'Topic 3',
    field: 'topic3'
  },
  {
    title: 'Topic 4',
    field: 'topic4'
  },
  {
    title: 'Topic 5',
    field: 'topic5'
  },
  {
    title: 'Topic 6',
    field: 'topic6'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  },
  {
    title: 'Positive list',
    field: 'posList'
  },
  {
    title: 'Negative list',
    field: 'negList'
  }
];


const rawDataTableDataSet = rawData.map((data) => {
  return ({
    // id: data.id,
    date: data.Date,
    platform: data['Platform'],
    productCategory: data['Product Category'],
    brand: data['Brand'],
    model: data['Model'],
    source: data['Source'],
    username: data['Username'],
    url: data['URL'],
    englishText: data['English_Text'],
    nativeText: data['Native_Text'],
    topic1: data['Topic 1'],
    topic2: data['Topic 2'],
    topic3: data['Topic 3'],
    topic4: data['Topic 4'],
    topic5: data['Topic 5'],
    topic6: data['Topic 6'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




  
  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();

  return (
    <div>

        <div id="pageLoader" className={loadPage1}></div>



    <div id="mainPage" style={{display:loadPage2}}>


    <GridContainer>

        <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

    </GridContainer>


    <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BrandIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Brand</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="brand-select"
                  className={
                    classes.selectLabel
                }>
                  Select Brand
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={brandSelection}
                  onChange={handleBrand}
                  inputProps={
                    {
                      name: "brandSelection",
                      id: "brand-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  brandItems.map((BrandItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={BrandItems}>
                      {BrandItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection}
                  onChange={handleCategory}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems.map((categoryItems, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={categoryItems}>
                        {categoryItems} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection}
                  onChange={handleModel}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems.map((modelItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={modelItems}>
                      {modelItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


          </GridContainer>



          <GridContainer>


          <GridItem xs={12}
          sm={12}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {
                      className: classes.selectMenu,
                      classes: { paper: classes.selectPaper }
                    }
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection}
                  onChange={handleCountry}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems.map((countryItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={countryItems}>
                      {countryItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


          <GridItem xs={12}
          sm={12}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FilterIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Unique Tweet</b>
              </h4>
            </CardHeader>
            <CardBody>
              <div className={classes.block} style={{height:"75px"}}>
                      <br/>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={uniqueTweet}
                            onChange={event => setUniqueTweet(event.target.checked)}
                            value= {uniqueTweet}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={uniqueTweet === true ? "ON (Recommended)" : "OFF"}
                      />
                    </div>
            </CardBody>
          </Card>

          </GridItem>

          <GridItem xs={12}
          sm={12}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select Start Date" }}
                    // value={dateStartSelection}
                    // onChange={handleDateStart}
                    // isValidDate={validStart}
                    // viewDate={moment(dateStartSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItem, 'YYYY-MM-DD')}
                    onChange={handleDateStart}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart } 

                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select End Date" }}
                    // value={dateEndSelection}
                    // onChange={handleDateEnd}
                    // isValidDate={validEnd}
                    // viewDate={moment(dateEndSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItem, 'YYYY-MM-DD')}
                    onChange={handleDateEnd}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 

                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>



        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

            <Button color="pinterest" onClick={onClickChartData}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>
    

          </GridItem>

        </GridContainer>


          <br/>


{/* Sentiment Trend */}

    <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Sentiment Trend</b> </Button>
      </GridItem>
    </GridContainer>


    <GridContainer>
      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <ThumpsUpIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>Positive</p>
            <h3 className={dashboardClasses.cardTitle}>
              {positiveTrendData.reduce(function(a, b) { return a + b; }, 0)}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              <HandIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>Neutral</p>
            <h3 className={dashboardClasses.cardTitle}>
              {neutralTrendrData.reduce(function(a, b) { return a + b; }, 0)}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <ThumpsDownIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>Negative</p>
            <h3 className={dashboardClasses.cardTitle}>
              {negativeTrendData.reduce(function(a, b) { return a + b; }, 0)}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={3}>
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              <RemoveIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>Total</p>
            <h3 className={dashboardClasses.cardTitle}>
              {totalTrendData.reduce(function(a, b) { return a + b; }, 0)}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>


    <GridContainer>
      <GridItem xs={12} sm={12} md={12} >
 
        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Cumulative Trend by Percent",
                tabIcon: TrendingUpIcon,
                tabContent: (!sentimentTrendIsLoading ? 
                  <div>
                    <Line 
                      height={100} data={sentimentTrendViewTrendDataSet}  
                      options={sentimentTrendViewTrendOptions} 
                      /> 
                  </div>
                

                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!sentimentTrendIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={uniqueTrendTrendTableColumn}
                        data={sentimentTrendTableDataSet}
                        
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentTrendTableDataSet} filename={"Sentiment Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

        </GridItem>
    </GridContainer>




{/* Topic Overview */}
    <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Overview</b> </Button>
      </GridItem>

    </GridContainer>


{/* Topic List - Wordcloud  */}

      <GridContainer>

        <GridItem xs={12} sm={12} md={6} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="info"
              
              tabs={[
                {
                  tabName: "Topic List",
                  tabIcon: TopicIcon,
                  tabContent: (!sentimentTrendIsLoading ? 
                    <div style={{ height: 500}}>
                      {topicOverviewDataWordCloud()}
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!sentimentTrendIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicOverviewDataTableColumn}
                          data={topicOverviewDataTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={topicOverviewDataTableDataSet} filename={"Topic Overview - Topic List.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={6} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Topic by Sentiment",
                  tabIcon: TopicIcon,
                  tabContent: (!sentimentTrendIsLoading ? 
                    <div>
                      
                      <HorizontalBar 
                         data={topicBySentimentDataSet} options={topicBySentimentDataOption} /> 
  
                    </div>
                  
  
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!sentimentTrendIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicBySentimentDataTableColumn}
                          data={topicBySentimentDataTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={topicBySentimentDataTableDataSet} filename={"Topic by Sentiment.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>




    </GridContainer>

{/*View Individual Sentence */}

    <GridContainer>

      <GridItem xs={12} sm={12} md={12} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            
            tabs={[
              {
                tabName: "Topic Overview Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!sentimentTrendIsLoading ? 
                    <div>
                      
                      <div style={{ maxWidth: '100%' }}>
                              <MaterialTable
                                columns={topicOverviewRawDataTableColumn}
                                data={topicOverviewRawDataTableDataSet}
                                title="SMAT Review"
                                options={{
                                  exportButton: true,
                                  exportAllData: true,
                                  filtering: true,
                                  showTitle: false
                                }}
  
                              />
                        </div>

                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                    <CSVLink data={topicOverviewRawDataTableDataSet} filename={"Topic Overview Table.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

              </GridContainer>

                  </div>
                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        /> 

    </GridItem>

    </GridContainer>  


{/* Sentiment Overview */}
    <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Sentiment Overview</b> </Button>
      </GridItem>

    </GridContainer>



    {/* Topic List - Wordcloud  */}

    <GridContainer>

      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="success"
            
            tabs={[
              {
                tabName: "Positive Sentiment List",
                tabIcon: PositiveIcon,
                tabContent: (!sentimentTrendIsLoading ? 
                  <div style={{ height: 500}}>
                    {positiveSentimentDataWordCloud()}
                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!sentimentTrendIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={positiveSentimentDataTableColumn}
                        data={positiveSentimentDataTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={positiveSentimentDataTableDataSet} filename={"Positive Sentiment List.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

      </GridItem>

      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="danger"
            
            tabs={[
              {
                tabName: "Negative Sentiment List",
                tabIcon: PositiveIcon,
                tabContent: (!sentimentTrendIsLoading ? 
                  <div style={{ height: 500}}>
                    {negativeSentimentDataWordCloud()}
                  </div>
                

                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!sentimentTrendIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={negativeSentimentDataTableColumn}
                        data={negativeSentimentDataTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={negativeSentimentDataTableDataSet} filename={"Negative Sentiment List.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

        </GridItem>




  </GridContainer>


{/* Raw Data */}
  <GridContainer>

    <GridItem xs={12}>
      <Button color="github" size="sm" style={{width: "100%"}}> <b>Raw Data</b> </Button>
    </GridItem>

  </GridContainer>



  {/*View Individual Sentence */}

  <GridContainer>

    <GridItem xs={12} sm={12} md={12} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="primary"
          
          tabs={[
            {
              tabName: "Raw Data",
              tabIcon: AssignmentIcon,
                tabContent: (!sentimentTrendIsLoading ? 
                  <div>
                    
                    <div style={{ maxWidth: '100%' }}>
                            <MaterialTable
                              columns={rawDataTableColumn}
                              data={rawDataTableDataSet}
                              title="SMAT Review"
                              options={{
                                exportButton: true,
                                exportAllData: true,
                                filtering: true,
                                showTitle: false
                              }}

                            />
                      </div>

                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                  <CSVLink data={rawDataTableDataSet} filename={"Raw Data.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

            </GridContainer>

                </div>
              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

    </GridItem>

    </GridContainer>  




    </div></div>



                    
  );



}
