// /*eslint-disable*/

//import React from "react";
import React, { memo } from 'react';

// CSS
import "../../assets/css/page-loading.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/LocalOffer";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import FilterIcon from "@material-ui/icons/FilterList";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
import SoftwareIcon from "@material-ui/icons/Apps";
import PictureIcon from "@material-ui/icons/Image";
import PriceIcon from "@material-ui/icons/AttachMoney";
import DesignIcon from "@material-ui/icons/DataUsage";
import FeatureIcon from "@material-ui/icons/Settings";
import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowUpIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownIcon from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";



import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar } from 'react-chartjs-2'; // 3rd Party Chart Component
//import 'chartjs-plugin-datalabels'; // 3rd party for chartjs to display labels
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);

const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="h4" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};



export default function StarRatingAnalytic() {

  const axios = require('axios');

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  


  const getMasterModelData = async () => {
    // Get Master Model Table
    try {
      const resp = await axios.get(SMATrestAPI.generalFilter[0].allModels);
      setFullModelList(resp.data.getFilter);
    
      let retrievedModelList = resp.data.getFilter
      let uniqueBrand = [...new Set(retrievedModelList.map(x => x.Brand))];
      uniqueBrand.unshift("Sony");

      setBrandItems(uniqueBrand);

      setLoadPage1("");
      setLoadPage2("");
    } catch (err) {
        // Handle Error Here
        console.error(err);
        setLoadPage1("");
        setLoadPage2("");
    }

  
  };
  

  const getLoggingData = async () => {

       // Logging
       let restGETurl = `${"smatCategory="}${"Star Rating Analytic"}`;
       restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
       restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    try {
      const resp = await axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`);

    } catch (err) {
        // Handle Error Here
        console.error(err);

    }

  
  };

// SMAT Log

  // SMAT Log
  React.useEffect(() => {

    const controller = new AbortController();

    getMasterModelData();
    getLoggingData();


    return () => {
      controller.abort();
      // setBrandItems(['']);
      // setFullModelList(['']);
    }

  }, [])


  // Test Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Test Modal 


  //TODO: Set all setStates to [] if the default is an array. Example: 
  // OLD => setCategoryDetailTopicInsightPictureItem(response.data.pictureTopicList);
  // New =>setCategoryDetailTopicInsightPictureItem([response.data.pictureTopicList]);
  // an error would occur if the return data is only 1 value which becomes not an array causing SMAT to error or show a blank page

  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");

  // Loading Param
  const [progress, setProgress] = React.useState(10);
  const [progressAll, setProgressAll] = React.useState(false);

  // Loading by Section
  const [starRatingTrendUniqueIsLoading, setStarRatingTrendUniqueIsLoading] = React.useState(false);
  const [starRatingTrendUIsLoading, setStarRatingTrendUIsLoading] = React.useState(false);
  const [categorySummaryIsLoading, setCategorySummaryIsLoading] = React.useState(false);
  const [topicOverviewIsLoading, setTopicOverviewIsLoading] = React.useState(false);
  const [topicInsightIsLoading, setTopicInsightIsLoading] = React.useState(false);
  const [topicSearchIsLoading, setTopicSearchIsLoading] = React.useState(false);

  const [sentimentOverviewPositiveIsLoading, setSentimentOverviewPositiveIsLoading] = React.useState(false);
  const [sentimentOverviewNegativeIsLoading, setSentimentOverviewNegativeIsLoading] = React.useState(false);

  const [softwareSummaryIsLoading, setSoftwareSummaryIsLoading] = React.useState(false);
  const [softwareTopicInsightIsLoading, setSoftwareTopicInsightIsLoading] = React.useState(false);

  const [pictureSummaryIsLoading, setPictureSummaryIsLoading] = React.useState(false);
  const [pictureTopicInsightIsLoading, setPictureTopicInsightIsLoading] = React.useState(false);

  const [priceSummaryIsLoading, setPriceSummaryIsLoading] = React.useState(false);
  const [priceTopicInsightIsLoading, setPriceTopicInsightIsLoading] = React.useState(false);

  const [designSummaryIsLoading, setDesignSummaryIsLoading] = React.useState(false);
  const [designTopicInsightIsLoading, setDesignTopicInsightIsLoading] = React.useState(false);

  const [featureSummaryIsLoading, setFeatureSummaryIsLoading] = React.useState(false);
  const [featureTopicInsightIsLoading, setFeatureTopicInsightIsLoading] = React.useState(false);

  const [soundSummaryIsLoading, setSoundSummaryIsLoading] = React.useState(false);
  const [soundTopicInsightIsLoading, setSoundTopicInsightIsLoading] = React.useState(false);

  const [categoryDetailSoftwaretopicInsightIsLoading, setCategoryDetailSoftwaretopicInsightIsLoading] = React.useState(false);
  const [categoryDetailPicturetopicInsightIsLoading, setCategoryDetailPicturetopicInsightIsLoading] = React.useState(false);
  const [categoryDetailPricetopicInsightIsLoading, setCategoryDetailPricetopicInsightIsLoading] = React.useState(false);
  const [categoryDetailDesigntopicInsightIsLoading, setCategoryDetailDesigntopicInsightIsLoading] = React.useState(false);
  const [categoryDetailFeaturetopicInsightIsLoading, setCategoryDetailFeaturetopicInsightIsLoading] = React.useState(false);
  const [categoryDetailSoundtopicInsightIsLoading, setCategoryDetailSoundtopicInsightIsLoading] = React.useState(false);

  const [viewIndividualSentenceIsLoading, setViewIndividualSentenceIsLoading] = React.useState(false);

  const [drillDownIsLoading, setDrillDownIsLoading] = React.useState(false); 


  // Get Trend Data via SMAT RESTAPI
  // filters

  const [fullModelList, setFullModelList] = React.useState(['']);

  const [brandItems, setBrandItems] = React.useState(['']);
  const [brandSelection, setBrandSelection] = React.useState('');

  const [categoryItems, setCategoryItems] = React.useState([]);
  const [categorySelection, setCategorySelection] = React.useState('');

  const [modelItems, setModelItems] = React.useState([]);
  const [modelSelection, setModelSelection] = React.useState([]);

  // for model search result to appear constant after selection
  const [inputValue, setInputValue] = React.useState("");

  const [websiteItems, setWebsiteItems] = React.useState([]);
  const [websiteSelection, setWebsiteSelection] = React.useState([]);

  const [countryItems, setCountryItems] = React.useState([]);
  const [countrySelection, setCountrySelection] = React.useState([]);
  const [lastWebsiteSelection, setLastWebsiteSelection] = React.useState(['']);

  const [dateStartItem, setDateStartItem] = React.useState('');
  const [dateStartSelection, setDateStartSelection] = React.useState('');

  const [dateEndItem, setDateEndItem] = React.useState('');
  const [dateEndSelection, setDateEndSelection] = React.useState('');

  const [uniqueTopic, setUniqueTopic] = React.useState(false);


  // SMAT Trend
  const [smatWeeklyTrendData, setSmatWeeklyTrendData] = React.useState([]);
  const [highWeeklyTrendData, setHighWeeklyTrendData] = React.useState('');
  const [lowWeeklyTrendData, setLowWeeklyTrendData] = React.useState('');
  const [smatTrendData, setSmatTrendData] = React.useState([]);
  const [smatTrendDataPercentCum, setSmatTrendDataPercentCum] = React.useState([]);
  const [smatTrendStarRatingAvg, setSmatTrendStarRatingAvg] = React.useState(0);
  const [smatTrendStarColor, setSmatTrendStarColor] = React.useState('info');
  const [smatTrendFilter, setSmatTrendFilter] = React.useState('day');

  // Unique Trend
  const [uniqueTrendData, setUniqueTrendData] = React.useState([]);
  const [uniqueTrendDataPercentCum, setUniqueTrendDataPercentCum] = React.useState([]);
  const [uniqueTrendStarRatingAvg, setUniqueTrendStarRatingAvg] = React.useState(0);
  const [uniqueTrendStarColor, setUniqueTrendStarColor] = React.useState('info');
  const [uniqueTrendFilter, setUniqueTrendFilter] = React.useState('day');

   // Category Summary
   const [categorySummaryData, setCategorySummaryData] = React.useState([]);
   const [categoryLabel, setCategoryLabel] = React.useState([]);
   const [categoryRating, setCategoryRating] = React.useState([]); // Order: Software, Picture, Price, Design, Feature, Sound
   const [categoryColor, setCategoryColor] = React.useState([]);
   
   const [categoryFiveStarCount, setCategoryFiveStarCount] = React.useState([]);
   const [categoryFourStarCount, setCategoryFourStarCount] = React.useState([]);
   const [categoryThreeStarCount, setCategoryThreeStarCount] = React.useState([]);
   const [categoryTwoStarCount, setCategoryTwoStarCount] = React.useState([]);
   const [categoryOneStarCount, setCategoryOneStarCount] = React.useState([]);

   const [categoryFiveStarPercent, setCategoryFiveStarPercent] = React.useState([]);
   const [categoryFourStarPercent, setCategoryFourStarPercent] = React.useState([]);
   const [categoryThreeStarPercent, setCategoryThreeStarPercent] = React.useState([]);
   const [categoryTwoStarPercent, setCategoryTwoStarPercent] = React.useState([]);
   const [categoryOneStarPercent, setCategoryOneStarPercent] = React.useState([]);

   const [categoryPositiveCount, setCategoryPositiveCount] = React.useState([]);
   const [categoryNegativeCount, setCategoryNegativeCount] = React.useState([]);

   const [categoryPositivePercent, setCategoryPositivePercent] = React.useState([]);
   const [categoryNegativePercent, setCategoryNegativePercent] = React.useState([]);

   const [categorySoftware, setCategorySoftware] = React.useState([]);
   const [categoryPicture, setCategoryPicture] = React.useState([]);
   const [categoryPrice, setCategoryPrice] = React.useState([]);
   const [categoryDesign, setCategoryDesign] = React.useState([]);
   const [categoryFeature, setCategoryFeature] = React.useState([]);
   const [categorySound, setCategorySound] = React.useState([]);

   const [categoryRadarOption, setCategoryRadarOption] = React.useState('Percent');


  // Topic Overview

  // const [topicOverviewcustomStarItem, setTopicOverviewcustomStarItem] = React.useState(["5-Star", "4-Star", "3-Star", "2-Star", "1-Star"]);
  // const [topicOverviewcustomStarSelection, setTopicOverviewcustomStarSelection] = React.useState(["5-Star", "4-Star", "3-Star", "2-Star", "1-Star"]);


  const [topicOverviewcustomStarSelection, setTopicOverviewcustomStarSelection] = React.useState({
    fiveStar: true,
    fourStar: true,
    threeStar: true,
    twoStar: true,
    oneStar: true
  });

  const [topicOverviewPositiveTopic, setTopicOverviewPositiveTopic] = React.useState([]);
  const [topicOverviewNegativeTopic, setTopicOverviewNegativeTopic] = React.useState([]);

  const [buzzWordsPositive, setBuzzWordsPositive] = React.useState([]);
  const [buzzWordsNegative, setBuzzWordsNegative] = React.useState([]);

  const [topicComparisonList, setTopicComparisonList] = React.useState([]);
  const [topicSentimentComparisonList, setTopicSentimentComparisonList] = React.useState([]);

  const [topicComparisonListAll, setTopicComparisonListAll] = React.useState([]);

  // Topic Insight

  const [topicInsightcustomStarSelection1, setTopicInsightcustomStarSelection1] = React.useState({
    fiveStar: true,
    fourStar: true,
    threeStar: true,
    twoStar: true,
    oneStar: true
  });


  const [topicInsightItems, setTopicInsightItems] = React.useState(['']);
  const [topicInsightSelection, setTopicInsightSelection] = React.useState('');

  const [topicInsightTopicPhraseData, setTopicInsightTopicPhraseData] = React.useState([]);
  const [topicInsightPositiveSentimentData, setTopicInsightPositiveSentimentData] = React.useState([]);
  const [topicInsightNegativeSentimentData, setTopicInsightNegativeSentimentData] = React.useState([]);
  const [topicInsightTopicTrendData, setTopicInsightTopicTrendData] = React.useState([]);

  // Topic Search

  const [topicSearchcustomStarSelection1, setTopicSearchcustomStarSelection1] = React.useState({
    fiveStar: true,
    fourStar: true,
    threeStar: true,
    twoStar: true,
    oneStar: true
  });



  const [topicSearchItems, setTopicSearchItems] = React.useState('');

  const [topicSearchTopicBreakdown, setTopicSearchTopicBreakdown] = React.useState([]);
  const [topicSearchPositiveSentimentData, setTopicSearchPositiveSentimentData] = React.useState([]);
  const [topicSearchNegativeSentimentData, setTopicSearchNegativeSentimentData] = React.useState([]);
  const [topicSearchTrendData, setTopicSearchTrendData] = React.useState([]);
  const [topicSearchTopicSentimentData, setTopicSearchTopicSentimentData] = React.useState([]);

  // Sentiment Overview
  const [sentimentOverviewPositveData, setSentimentOverviewPositiveData] = React.useState([]);
  const [sentimentOverviewNegativeData, setSentimentOverviewNegativeData] = React.useState([]);
  const [sentimentOverviewFilter, setSentimentOverviewFilter] = React.useState('All');

  // Category Detail - Software, Picture, Price, Design, Feature, Sound Topic List
  const [categoryDetailValueBoxData, setCategoryDetailValueBoxData] = React.useState([]);
  const [softwareTopic, setSoftwareTopic] = React.useState([]);
  const [pictureTopic, setPictureTopic] = React.useState([]);
  const [priceTopic, setPriceTopic] = React.useState([]);
  const [designTopic, setDesignTopic] = React.useState([]);
  const [featureTopic, setFeatureTopic] = React.useState([]);
  const [soundTopic, setToundTopic] = React.useState([]);

  // Category Detail - Topic Insight - Software, Picture, Price, Design, Feature, Sound Topic Insight
  const [categoryDetailTopicInsightSoftwareItem, setCategoryDetailTopicInsightSoftwareItem] = React.useState([]);
  const [categoryDetailTopicInsightSoftwareSelection, setCategoryDetailTopicInsightSoftwareSelection] = React.useState('');

  const [categoryDetailTopicInsightPictureItem, setCategoryDetailTopicInsightPictureItem] = React.useState([]);
  const [categoryDetailTopicInsightPictureSelection, setCategoryDetailTopicInsightPictureSelection] = React.useState('');

  const [categoryDetailTopicInsightPriceItem, setCategoryDetailTopicInsightPriceItem] = React.useState([]);
  const [categoryDetailTopicInsightPriceSelection, setCategoryDetailTopicInsightPriceSelection] = React.useState('');

  const [categoryDetailTopicInsightDesignItem, setCategoryDetailTopicInsightDesignItem] = React.useState([]);
  const [categoryDetailTopicInsightDesignSelection, setCategoryDetailTopicInsightDesignSelection] = React.useState('');

  const [categoryDetailTopicInsightFeatureItem, setCategoryDetailTopicInsightFeatureItem] = React.useState([]);
  const [categoryDetailTopicInsightFeatureSelection, setCategoryDetailTopicInsightFeatureSelection] = React.useState('');

  const [categoryDetailTopicInsightSoundItem, setCategoryDetailTopicInsightSoundItem] = React.useState([]);
  const [categoryDetailTopicInsightSoundSelection, setCategoryDetailTopicInsightSoundSelection] = React.useState('');


  const [categoryDetailTopicInsightTopicPhraseSoftwareData, setCategoryDetailTopicInsightTopicPhraseSoftwareData] = React.useState([]);
  const [categoryDetailTopicInsightPositiveSentimentSoftwareData, setCategoryDetailTopicInsightPositiveSentimentSoftwareData] = React.useState([]);
  const [categoryDetailTopicInsightNegativeSentimentSoftwareData, setCategoryDetailTopicInsightNegativeSentimentSoftwareData] = React.useState([]);
  const [categoryDetailTopicInsightTopicTrendSoftwareData, setCategoryDetailTopicInsightTopicTrendSoftwareData] = React.useState([]);

  const [categoryDetailTopicInsightTopicPhrasePictureData, setCategoryDetailTopicInsightTopicPhrasePictureData] = React.useState([]);
  const [categoryDetailTopicInsightPositiveSentimentPictureData, setCategoryDetailTopicInsightPositiveSentimentPictureData] = React.useState([]);
  const [categoryDetailTopicInsightNegativeSentimentPictureData, setCategoryDetailTopicInsightNegativeSentimentPictureData] = React.useState([]);
  const [categoryDetailTopicInsightTopicTrendPictureData, setCategoryDetailTopicInsightTopicTrendPictureData] = React.useState([]);

  const [categoryDetailTopicInsightTopicPhrasePriceData, setCategoryDetailTopicInsightTopicPhrasePriceData] = React.useState([]);
  const [categoryDetailTopicInsightPositiveSentimentPriceData, setCategoryDetailTopicInsightPositiveSentimentPriceData] = React.useState([]);
  const [categoryDetailTopicInsightNegativeSentimentPriceData, setCategoryDetailTopicInsightNegativeSentimentPriceData] = React.useState([]);
  const [categoryDetailTopicInsightTopicTrendPriceData, setCategoryDetailTopicInsightTopicTrendPriceData] = React.useState([]);

  const [categoryDetailTopicInsightTopicPhraseDesignData, setCategoryDetailTopicInsightTopicPhraseDesignData] = React.useState([]);
  const [categoryDetailTopicInsightPositiveSentimentDesignData, setCategoryDetailTopicInsightPositiveSentimentDesignData] = React.useState([]);
  const [categoryDetailTopicInsightNegativeSentimentDesignData, setCategoryDetailTopicInsightNegativeSentimentDesignData] = React.useState([]);
  const [categoryDetailTopicInsightTopicTrendDesignData, setCategoryDetailTopicInsightTopicTrendDesignData] = React.useState([]);

  const [categoryDetailTopicInsightTopicPhraseFeatureData, setCategoryDetailTopicInsightTopicPhraseFeatureData] = React.useState([]);
  const [categoryDetailTopicInsightPositiveSentimentFeatureData, setCategoryDetailTopicInsightPositiveSentimentFeatureData] = React.useState([]);
  const [categoryDetailTopicInsightNegativeSentimentFeatureData, setCategoryDetailTopicInsightNegativeSentimentFeatureData] = React.useState([]);
  const [categoryDetailTopicInsightTopicTrendFeatureData, setCategoryDetailTopicInsightTopicTrendFeatureData] = React.useState([]);

  const [categoryDetailTopicInsightTopicPhraseSoundData, setCategoryDetailTopicInsightTopicPhraseSoundData] = React.useState([]);
  const [categoryDetailTopicInsightPositiveSentimentSoundData, setCategoryDetailTopicInsightPositiveSentimentSoundData] = React.useState([]);
  const [categoryDetailTopicInsightNegativeSentimentSoundData, setCategoryDetailTopicInsightNegativeSentimentSoundData] = React.useState([]);
  const [categoryDetailTopicInsightTopicTrendSoundData, setCategoryDetailTopicInsightTopicTrendSoundData] = React.useState([]);

  // View Individual Sentence
  const [viewIndividualSentenceData, setViewIndividualSentenceData] = React.useState([]);

  // Raw Data
  const [rawData, setRawData] = React.useState([]);

  // Full Review 
  const [fullReviewData, setFullReviewData] = React.useState('');
  const [fullReviewModal, setFullReviewModal] = React.useState(false);
  const [fullReviewDrillDownIsLoading, setFullReviewDrillDownIsLoading] = React.useState(false);



  // Open Modal (Each modal open must be unique)
  const [openModalUniqueStarRatingTrend, setOpenModalUniqueStarRatingTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalUniqueStarRatingTrend, setFullReviewModalUniqueStarRatingTrend] = React.useState(false); // Full Review Pop up

  const [openModalUniqueStarRatingPie, setOpenModalUniqueStarRatingPie] = React.useState(false); // Full Review Pop up
  const [fullReviewModalUniqueStarRatingPie, setFullReviewModalUniqueStarRatingPie] = React.useState(false); // Full Review Pop up

  const [openModalStarRatingTrend, setOpenModalStarRatingTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalStarRatingTrend, setFullReviewModalStarRatingTrend] = React.useState(false); // Full Review Pop up

  const [openModalStarRatingPie, setOpenModalStarRatingPie] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalStarRatingPie, setFullReviewModalStarRatingPie] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummarySentimentRadar, setOpenModalCategorySummarySentimentRadar] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummarySentimentRadar, setFullReviewModalCategorySummarySentimentRadar] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummaryStarRating, setOpenModalCategorySummaryStarRating] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummaryStarRating, setFullReviewModalCategorySummaryStarRating] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummarySoftwareTrend, setOpenModalCategorySummarySoftwareTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummarySoftwareTrend, setFullReviewModalCategorySummarySoftwareTrend] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummaryPictureTrend, setOpenModalCategorySummaryPictureTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummaryPictureTrend, setFullReviewModalCategorySummaryPictureTrend] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummaryPriceTrend, setOpenModalCategorySummaryPriceTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummaryPriceTrend, setFullReviewModalCategorySummaryPriceTrend] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummaryDesignTrend, setOpenModalCategorySummaryDesignTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummaryDesignTrend, setFullReviewModalCategorySummaryDesignTrend] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummaryFeatureTrend, setOpenModalCategorySummaryFeatureTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummaryFeatureTrend, setFullReviewModalCategorySummaryFeatureTrend] = React.useState(false); // Full Review Pop up

  const [openModalCategorySummarySoundTrend, setOpenModalCategorySummarySoundTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalCategorySummarySoundTrend, setFullReviewModalCategorySummarySoundTrend] = React.useState(false); // Full Review Pop up

  const [openModalTopicOverviewTopicSentiment, setOpenModalTopicOverviewTopicSentiment] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicOverviewTopicSentimen, setFullReviewModalTopicOverviewTopicSentimen] = React.useState(false); // Full Review Pop up

  const [openModalTopicOverviewTopicComparisonSentiment, setOpenModalTopicOverviewTopicComparisonSentiment] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicOverviewTopicComparisonSentimen, setFullReviewModalTopicOverviewTopicComparisonSentimen] = React.useState(false); // Full Review Pop up

  const [openModalTopicOverviewTopicComparisonSentimentNeg, setOpenModalTopicOverviewTopicComparisonSentimentNeg] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicOverviewTopicComparisonSentimentNeg, setFullReviewModalTopicOverviewTopicComparisonSentimentNeg] = React.useState(false); // Full Review Pop up

  const [openModalTopicInsightTrend, setOpenModalTopicInsightTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicInsightTrend, setFullReviewModalTopicInsightTrend] = React.useState(false); // Full Review Pop up

  const [openModalTopicInsightTopicPhrase, setOpenModalTopicInsightTopicPhrase] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicInsightTopicPhrase, setFullReviewModalTopicInsightTopicPhrase] = React.useState(false); // Full Review Pop up

  const [openModalTopicInsightTopicSentimentWord, setOpenModalTopicInsightTopicSentimentWord] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicInsightTopicSentimentWord, setFullReviewModalTopicInsightTopicSentimentWord] = React.useState(false); // Full Review Pop up

  const [openModalTopicInsightTopicSentimentWordNeg, setOpenModalTopicInsightTopicSentimentWordNeg] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicInsightTopicSentimentWordNeg, setFullReviewModalTopicInsightTopicSentimentWordNeg] = React.useState(false); // Full Review Pop up

  const [openModalTopicSearchTrend, setOpenModalTopicSearchTrend] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicSearch, setFullReviewModalTopicSearch] = React.useState(false); // Full Review Pop up

  const [openModalTopicSearchTopicBreakdown, setOpenModalTopicSearchTopicBreakdown] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicSearchTopickBreakdown, setFullReviewModalTopicSearchTopickBreakdown] = React.useState(false); // Full Review Pop up

  const [openModalTopicSearchTopicCombine, setOpenModalTopicSearchTopicCombine] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicSearchTopicCombine, setFullReviewModalTopicSearchTopicCombine] = React.useState(false); // Full Review Pop up

  const [openModalTopicSearchSentimentPositive, setOpenModalTopicSearchSentimentPositive] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicSearchSentimentPositive, setFullReviewModalTopicSearchSentimentPositive] = React.useState(false); // Full Review Pop up

  const [openModalTopicSearchSentimentNegative, setOpenModalTopicSearchSentimentNegative] = React.useState(false); // Drill Down Pop up
  const [fullReviewModalTopicSearchSentimentNegative, setFullReviewModalTopicSearchSentimentNegative] = React.useState(false); // Full Review Pop up


  const [modalTitle, setModalTitle] = React.useState('');


  // Unique and Non Unique Trend - Drill Down 
  const [uniqueTrendPositiveDrillDownData, setUniqueTrendPositiveDrillDownData] = React.useState([]);
  const [uniqueTrendNegativeDrillDownData, setUniqueTrendNegativeDrillDownData] = React.useState([]);
  const [uniqueTrendIndividualSentenceDrillDownData, setUniqueTrendIndividualSentenceDrillDownData] = React.useState([]);

  // Unique and Non Unique Pie - Drill Down 
  const [starRatingPieIndividualSentenceDrillDownData, setStarRatingPieIndividualSentenceDrillDownData] = React.useState([]);
  const [starRatingPiePositiveDrillDownData, setStarRatingPiePositiveDrillDownData] = React.useState([]);
  const [starRatingPieNegativeDrillDownData, setStarRatingPieNegativeDrillDownData] = React.useState([]);

  // Category Summary Sentiment Radar - Drill Down 
  const [categorySummarySentimentRadarDrillDownData, setCategorySummarySentimentRadarDrillDownData] = React.useState([]);
  const [categorySummarySentimentRadarPositiveDrillDownData, setCategorySummarySentimentRadarPositiveDrillDownData] = React.useState([]);
  const [categorySummarySentimentRadarNegativeDrillDownData, setCategorySummarySentimentRadarNegativeDrillDownData] = React.useState([]);

  // Category Summary Star Rating - Drill Down 
  const [categorySummaryStarRatingDrillDownData, setCategorySummaryStarRatingDrillDownData] = React.useState([]);
  const [categorySummaryStarRatingPositiveDrillDownData, setCategorySummaryStarRatingPositiveDrillDownData] = React.useState([]);
  const [categorySummaryStarRatingNegativeDrillDownData, setCategorySummaryStarRatingNegativeDrillDownData] = React.useState([]);

  // Category Summary Software Trend - Drill Down 
  const [categorySummarySoftwareTrendDrillDownData, setCategorySummarySoftwareTrendDrillDownData] = React.useState([]);
  const [categorySummarySoftwareTrendPositiveDrillDownData, setCategorySummarySoftwareTrendPositiveDrillDownData] = React.useState([]);
  const [categorySummarySoftwareTrendNegativeDrillDownData, setCategorySummarySoftwareTrendNegativeDrillDownData] = React.useState([]);

  // Category Summary Picture Trend - Drill Down 
  const [categorySummaryPictureTrendDrillDownData, setCategorySummaryPictureTrendDrillDownData] = React.useState([]);
  const [categorySummaryPictureTrendPositiveDrillDownData, setCategorySummaryPictureTrendPositiveDrillDownData] = React.useState([]);
  const [categorySummaryPictureTrendNegativeDrillDownData, setCategorySummaryPictureTrendNegativeDrillDownData] = React.useState([]);

  // Category Summary Price Trend - Drill Down 
  const [categorySummaryPriceTrendDrillDownData, setCategorySummaryPriceTrendDrillDownData] = React.useState([]);
  const [categorySummaryPriceTrendPositiveDrillDownData, setCategorySummaryPriceTrendPositiveDrillDownData] = React.useState([]);
  const [categorySummaryPriceTrendNegativeDrillDownData, setCategorySummaryPriceTrendNegativeDrillDownData] = React.useState([]);

  // Category Summary Design Trend - Drill Down 
  const [categorySummaryDesignTrendDrillDownData, setCategorySummaryDesignTrendDrillDownData] = React.useState([]);
  const [categorySummaryDesignTrendPositiveDrillDownData, setCategorySummaryDesignTrendPositiveDrillDownData] = React.useState([]);
  const [categorySummaryDesignTrendNegativeDrillDownData, setCategorySummaryDesignTrendNegativeDrillDownData] = React.useState([]);

  // Category Summary Feature Trend - Drill Down 
  const [categorySummaryFeatureTrendDrillDownData, setCategorySummaryFeatureTrendDrillDownData] = React.useState([]);
  const [categorySummaryFeatureTrendPositiveDrillDownData, setCategorySummaryFeatureTrendPositiveDrillDownData] = React.useState([]);
  const [categorySummaryFeatureTrendNegativeDrillDownData, setCategorySummaryFeatureTrendNegativeDrillDownData] = React.useState([]);

  // Category Summary Sound Trend - Drill Down 
  const [categorySummarySoundTrendDrillDownData, setCategorySummarySoundTrendDrillDownData] = React.useState([]);
  const [categorySummarySoundTrendPositiveDrillDownData, setCategorySummarySoundTrendPositiveDrillDownData] = React.useState([]);
  const [categorySummarySoundTrendNegativeDrillDownData, setCategorySummarySoundTrendNegativeDrillDownData] = React.useState([]);

  // Topic Overview - Topic Sentiment - Drill Down 
  const [categoryOverviewTopicSentimentDrillDownData, setCategoryOverviewTopicSentimentDrillDownData] = React.useState([]);
  const [categoryOverviewTopicSentimentPositiveDrillDownData, setCategoryOverviewTopicSentimentPositiveDrillDownData] = React.useState([]);
  const [categoryOverviewTopicSentimentNegativeDrillDownData, setCategoryOverviewTopicSentimentNegativeDrillDownData] = React.useState([]);

  // Topic Overview - Topic Comparison Positive Sentiment - Drill Down 
  const [categoryOverviewTopicComparisonSentimentDrillDownData, setCategoryOverviewTopicComparisonSentimentDrillDownData] = React.useState([]);
  const [categoryOverviewTopicComparisonSentimentPositiveDrillDownData, setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData] = React.useState([]);
  const [categoryOverviewTopicComparisonSentimentNegativeDrillDownData, setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData] = React.useState([]);

  // Topic Overview - Topic Comparison Negative Sentiment - Drill Down 
  const [categoryOverviewTopicComparisonSentimentNegDrillDownData, setCategoryOverviewTopicComparisonSentimentNegDrillDownData] = React.useState([]);
  const [categoryOverviewTopicComparisonSentimentPositiveNegDrillDownData, setCategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData] = React.useState([]);
  const [categoryOverviewTopicComparisonSentimentNegativeNegDrillDownData, setCategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData] = React.useState([]);

  // Topic Insight - Trend - Drill Down 
  const [topicInsightTrendDrillDownData, setTopicInsightTrendDrillDownData] = React.useState([]);
  const [topicInsightTrendPositiveDrillDownData, setTopicInsightTrendPositiveDrillDownData] = React.useState([]);
  const [topicInsightTrendNegativeDrillDownData, setTopicInsightTrendNegativeDrillDownData] = React.useState([]);

   // Topic Insight - Topic Phrase - Drill Down 
   const [topicInsightTopicPhraseDrillDownData, setTopicInsightTopicPhraseDrillDownData] = React.useState([]);
   const [topicInsightTopicPhrasedPositiveDrillDownData, setTopicInsightTopicPhrasedPositiveDrillDownData] = React.useState([]);
   const [topicInsightTopicPhraseNegativeDrillDownData, setTopicInsightTopicPhraseNegativeDrillDownData] = React.useState([]);

   // Topic Insight - Positive Sentiment Word - Drill Down 
   const [topicInsightSentimentWordDrillDownData, setTopicInsightSentimentWordDrillDownData] = React.useState([]);

   // Topic Insight - Negative Sentiment Word - Drill Down 
   const [topicInsightSentimentWordDrillDownDataNeg, setTopicInsightSentimentWordDrillDownDataNeg] = React.useState([]);

   // Topic Search - Trend - Drill Down 
   const [topicSearchTrendDrillDownData, setTopicSearchTrendDrillDownData] = React.useState([]);
   const [topicSearchTrendPositiveDrillDownData, setTopicSearchTrendPositiveDrillDownData] = React.useState([]);
   const [topicSearchTrendNegativeDrillDownData, setTopicSearchTrendNegativeDrillDownData] = React.useState([]);

    // Topic Search - Topic Breakdown - Drill Down 
    const [topicSearchTopicBreakdowndDrillDownData, setTopicSearchTopicBreakdowndDrillDownData] = React.useState([]);
    const [topicSearchTopicBreakdownPositiveDrillDownData, setTopicSearchTopicBreakdownPositiveDrillDownData] = React.useState([]);
    const [topicSearchTopicBreakdownNegativeDrillDownData, setTopicSearchTopicBreakdownNegativeDrillDownData] = React.useState([]);

    // Topic Search - Topic Combine- Drill Down 
    const [topicSearchTopicCombineDrillDownData, setTopicSearchTopicCombineDrillDownData] = React.useState([]);
    const [topicSearchTopicCombinePositiveDrillDownData, setTopicSearchTopicCombinePositiveDrillDownData] = React.useState([]);
    const [topicSearchTopicCombineNegativeDrillDownData, setTopicSearchTopicCombineNegativeDrillDownData] = React.useState([]);

    // Topic Search - Positive Sentiment - Drill Down 
    const [topicSearchTopicPositiveSentimentDrillDownData, setTopicSearchTopicPositiveSentimentDrillDownData] = React.useState([]);

    // Topic Search - Negative Sentiment - Drill Down 
    const [topicSearchTopicNegativeSentimentDrillDownData, setTopicSearchTopicNegativeSentimentDrillDownData] = React.useState([]);

    // JD Download Button
    const [showJDbutton, setShowJDbutton] = React.useState(false);
    const [jdRawData, setJdRawData] = React.useState([]);

 

  const handleBrand= event => {
    setBrandSelection(event.target.value);
    setCategorySelection('');
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleCategory = event => {
    setCategorySelection(event.target.value);
    setModelSelection([]);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  const handleModel = (event, values) => {
    setModelSelection(values);
    setWebsiteSelection([]);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');

  };


  const handleWebsite = event => {
    setWebsiteSelection(event.target.value);
    setCountrySelection([]);
    setDateStartSelection('');
    setDateEndSelection('');

  };

  const handleCountry = event => {
    setCountrySelection(event.target.value);
    setDateStartSelection('');
    setDateEndSelection('');
  };

  // const handleDateStart = event => {
  //   let startDate = event._d;
  //   startDate = new Date(event._d);
  //   let year = startDate.getFullYear();
  //   let month = startDate.getMonth()+1;
  //   let dt = startDate.getDate();

  //   if (dt < 10) {
  //     dt = '0' + dt;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }

  //   startDate = (year+'-' + month + '-'+dt);

    

  //   if(isNaN(startDate) == false){
  //     setDateStartSelection(startDate);
  //   }


  // };

  // const handleDateEnd = event => {
  //   let endDate = event._d;
  //   endDate = new Date(event._d);
  //   let year = endDate.getFullYear();
  //   let month = endDate.getMonth()+1;
  //   let dt = endDate.getDate();

  //   if (dt < 10) {
  //     dt = '0' + dt;
  //   }
  //   if (month < 10) {
  //     month = '0' + month;
  //   }

  //   endDate = (year+'-' + month + '-'+dt);

  //   setDateEndSelection(endDate);

  //   if(isNaN(endDate) == false){
  //     setDateEndSelection(endDate);
  //   }

  // };



  const handleDateStart = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
  
      setDateStartSelection(aDate);
    }

  }

  const handleDateEnd = event => {

    // Prevent function error if user manually inputs
    if (typeof event === 'object') {
      let aDate = event.format();
      aDate = aDate.substring(0, 10);
      
      setDateEndSelection(aDate);
    }

  }


  var yesterday = moment(dateStartItem, 'YYYY-MM-DD').subtract(1, "day");
  function validStart(current) {
    return current.isAfter(yesterday);
  }

  var tommorrow = moment(dateEndItem, 'YYYY-MM-DD').add(1, "day");
  function validEnd(current) {
    return current.isBefore(tommorrow);
  }


  const handleTopicInsight = event => {
    setTopicInsightSelection(event.target.value);
  };


  const handleCategoryDetailSoftwareTopicInsight = event => {
    setCategoryDetailTopicInsightSoftwareSelection(event.target.value);
  };

  const handleCategoryDetailPictureTopicInsight = event => {
    setCategoryDetailTopicInsightPictureSelection(event.target.value);
  };

  const handleCategoryDetailPriceTopicInsight = event => {
    setCategoryDetailTopicInsightPriceSelection(event.target.value);
  };

  const handleCategoryDetailDesignTopicInsight = event => {
    setCategoryDetailTopicInsightDesignSelection(event.target.value);
  };

  const handleCategoryDetailFeatureTopicInsight = event => {
    setCategoryDetailTopicInsightFeatureSelection(event.target.value);
  };

  const handleCategoryDetailSoundTopicInsight = event => {
    setCategoryDetailTopicInsightSoundSelection(event.target.value);
  };

  


  // check the progress status after the user clicks on display charts
  React.useEffect(() => {

    const controller = new AbortController();

    if(progress > 10 & progress < 100){
      setProgressAll(true);
      //console.log("progress bar running");
    }

    if(progress >= 100){
      setProgressAll(false);
      //console.log("progress all done");
    }
    
    return () => controller.abort();

    

  }, [progress])



  // Get Filter Data from SMAT REST API - Category
  React.useEffect(() => {

    const controller = new AbortController();

    if(brandSelection !== ''){
      let result = fullModelList.filter(x => x.Brand === brandSelection);
      result = [...new Set(result.map(x => x['Product.Category']))];

      setCategoryItems(result);

    }

    return () => {
      controller.abort();
      // setCategoryItems([]);
    }

  }, [brandSelection])



// Get Filter Data from SMAT REST API - Model
React.useEffect(() => {

  const controller = new AbortController();

  if(categorySelection !== ''){

    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = [...new Set(result.map(x => x['Model']))];

    setModelItems(result);

  }

  return () => {
    controller.abort();
    // setModelItems([]);
  }


}, [categorySelection])



// Get Filter Data from SMAT REST API - Website
React.useEffect(() => {

  const controller = new AbortController();


  if(modelSelection !== ''){

    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = result.filter(x => x['Model'] == modelSelection[0] || x['Model'] == modelSelection[1] || x['Model'] == modelSelection[2] || x['Model'] == modelSelection[3] || x['Model'] == modelSelection[4] || x['Model'] == modelSelection[5] || x['Model'] == modelSelection[6] || x['Model'] == modelSelection[7] || x['Model'] == modelSelection[8] || x['Model'] == modelSelection[9]);
    result = [...new Set(result.map(x => x['Website']))];

    setWebsiteItems(result);


    // console.log(modelSelection);
    // console.log(result);
    // console.log(lastWebsiteSelection);
    // console.log(lastWebsiteSelection.length);
  
    // console.log(result.some(el => websiteSelection.includes(el)));
  
    // if(result.some(el => lastWebsiteSelection.includes(el))){
    //   setWebsiteSelection(lastWebsiteSelection);
    // }else{
    //   setWebsiteSelection([]);
    // }

  }

  return () => {
    controller.abort();
    // setWebsiteItems([]);
  }

}, [modelSelection])



// Get Filter Data from SMAT REST API - Country
React.useEffect(() => {

  const controller = new AbortController();


  if(websiteSelection !== ''){

    let result = fullModelList.filter(x => x.Brand === brandSelection);
    result = result.filter(x => x['Product.Category'] === categorySelection);
    result = result.filter(x => x['Model'] == modelSelection[0] || x['Model'] == modelSelection[1] || x['Model'] == modelSelection[2] || x['Model'] == modelSelection[3] || x['Model'] == modelSelection[4] || x['Model'] == modelSelection[5] || x['Model'] == modelSelection[6] || x['Model'] == modelSelection[7] || x['Model'] == modelSelection[8] || x['Model'] == modelSelection[9]);
    result = result.filter(x => x['Website'] == websiteSelection[0] || x['Website'] == websiteSelection[1] || x['Website'] == websiteSelection[2] || x['Website'] == websiteSelection[3] || x['Website'] == websiteSelection[4] || x['Website'] == websiteSelection[5] || x['Website'] == websiteSelection[6] || x['Website'] == websiteSelection[7] || x['Website'] == websiteSelection[8] || x['Website'] == websiteSelection[9]);
    result = [...new Set(result.map(x => x['Country']))];

    setCountryItems(result);

  }

  return () => {
    controller.abort();
    // setCountryItems([]);
  }


}, [websiteSelection])


// Get Filter Data from SMAT REST API - Date
React.useEffect(() => {
  const controller = new AbortController();

  if(countrySelection !== ''){

    var restGETurl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.generalFilter[0].getDate}${restGETurl}`).then(function (response) { 
      // handle success

      setDateStartItem(response.data.startDate);
      setDateEndItem(response.data.endDate);

      setDateStartSelection(response.data.startDate);
      setDateEndSelection(response.data.endDate);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }

  return () => {
    controller.abort();
    // setDateStartItem('');
    // setDateEndItem('');

    // setDateStartSelection('');
    // setDateEndSelection('');
  }


}, [countrySelection])


const sendModelData = async () => {

  var restGETurl0 = `${"page="}${"Star Rating Analytic"}${"&brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
  restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
  restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl0}`);
  } catch (err) {
      // Handle Error Here
      console.error(err);
  }


};





const getWeeklyStarTrendData = async () => {
  // Weekly Star Trend Data
  var restGETurl000 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}`;
  restGETurl000 = restGETurl000.replaceAll(",", '%2C'); // replace comma for url
  restGETurl000 = restGETurl000.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].weeklyTrend}${restGETurl000}`);

    let starTrendModified = resp.data.starTrend;

    starTrendModified.pop()

    setSmatWeeklyTrendData(starTrendModified);
    setHighWeeklyTrendData(resp.data.highRating);
    setLowWeeklyTrendData(resp.data.lowRating);

  } catch (err) {
      // Handle Error Here
      console.error(err);
  }


};


const getUniqueTrendData = async (trendFilterSelection) => {
    // Unique Trend Data
    setStarRatingTrendUniqueIsLoading(true);
    var restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&trendFilter="}${trendFilterSelection}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
  
  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].uniqueTrend}${restGETurl1}`);

    setUniqueTrendData(resp.data.uniqueTrendData);
    setUniqueTrendDataPercentCum(resp.data.uniqueTrendDataPercentCum);
    setUniqueTrendStarRatingAvg(resp.data.starRating);
    setUniqueTrendStarColor(resp.data.color);

    setStarRatingTrendUniqueIsLoading(false);
    setProgress(prevCount => prevCount + 10);

  } catch (err) {
      // Handle Error Here
      console.error(err);
      setUniqueTrendData([]);
      setUniqueTrendDataPercentCum([]);
      setUniqueTrendStarRatingAvg(0);
      setUniqueTrendStarColor('info');

      setStarRatingTrendUniqueIsLoading(false);
      setProgress(prevCount => prevCount + 10);
  }


};


const getTrendData = async () => {
  // Trend Data
  setStarRatingTrendUIsLoading(true);
  var restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&trendFilter="}${smatTrendFilter}`;
  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].smatTrend}${restGETurl2}`);

    setSmatTrendData(resp.data.smatTrendData);
    setSmatTrendDataPercentCum(resp.data.smatTrendDataPercentCum);
    setSmatTrendStarRatingAvg(resp.data.starRating);
    setSmatTrendStarColor(resp.data.color);

    setStarRatingTrendUIsLoading(false);
    setProgress(prevCount => prevCount + 10);

  } catch (err) {
      // Handle Error Here
      console.error(err);
      setStarRatingTrendUIsLoading(false);
      setProgress(prevCount => prevCount + 10);
  }


};


const getCategoryData = async () => {
  // Category Summary Data
  setCategorySummaryIsLoading(true);
  var restGETurl3 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}`;
  restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
  restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummary}${restGETurl3}`);

    setCategorySummaryData(resp.data.categorySummary);

    let labelData = resp.data.categorySummary.map(data => data['Category']);
    setCategoryLabel(labelData);

    let ratingData = resp.data.categorySummary.map(data => data['Rating']);
    setCategoryRating(ratingData);

    let colorData = resp.data.categorySummary.map(data => data['Color']);
    setCategoryColor(colorData);

    let fiveStarCountData = resp.data.categorySummary.map(data => data['5-Star Count']);
    setCategoryFiveStarCount(fiveStarCountData);

    let fourStarCountData = resp.data.categorySummary.map(data => data['4-Star Count']);
    setCategoryFourStarCount(fourStarCountData);

    let threeStarCountData = resp.data.categorySummary.map(data => data['3-Star Count']);
    setCategoryThreeStarCount(threeStarCountData);

    let twoStarCountData = resp.data.categorySummary.map(data => data['2-Star Count']);
    setCategoryTwoStarCount(twoStarCountData);

    let oneStarCountData = resp.data.categorySummary.map(data => data['1-Star Count']);
    setCategoryOneStarCount(oneStarCountData);

    let fiveStarPercentData = resp.data.categorySummary.map(data => data['5-Star Percent']);
    setCategoryFiveStarPercent(fiveStarPercentData);

    let fourStarPercentData = resp.data.categorySummary.map(data => data['4-Star Percent']);
    setCategoryFourStarPercent(fourStarPercentData);

    let threeStarPercentData = resp.data.categorySummary.map(data => data['3-Star Percent']);
    setCategoryThreeStarPercent(threeStarPercentData);

    let twoStarPercentData = resp.data.categorySummary.map(data => data['2-Star Percent']);
    setCategoryTwoStarPercent(twoStarPercentData);

    let oneStarPercentData = resp.data.categorySummary.map(data => data['1-Star Percent']);
    setCategoryOneStarPercent(oneStarPercentData);

    let positiveCountData = resp.data.categorySummary.map(data => data['Positive Count']);
    setCategoryPositiveCount(positiveCountData);

    let negativeCountData = resp.data.categorySummary.map(data => data['Negative Count']);
    setCategoryNegativeCount(negativeCountData);

    let positivePercentData = resp.data.categorySummary.map(data => data['Positive Percent']);
    setCategoryPositivePercent(positivePercentData);

    let negativePercentData = resp.data.categorySummary.map(data => data['Negative Percent']);
    setCategoryNegativePercent(negativePercentData);

    setCategorySoftware(resp.data.softwareSummary);
    setCategoryPicture(resp.data.pictureSummary);
    setCategoryPrice(resp.data.priceSummary);
    setCategoryDesign(resp.data.designSummary);
    setCategoryFeature(resp.data.featureSummary);
    setCategorySound(resp.data.soundSummary);

    setCategorySummaryIsLoading(false);
    setProgress(prevCount => prevCount + 10);

  } catch (err) {
      // Handle Error Here
      console.error(err);
      setStarRatingTrendUIsLoading(false);
      setProgress(prevCount => prevCount + 10);

      setCategorySummaryIsLoading(false);
      setProgress(prevCount => prevCount + 10);
  }


};


const getTopicOverviewData = async () => {

  // Topic Overview Data
  setTopicOverviewIsLoading(true);
  var restGETurl4 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}`;
  restGETurl4 = restGETurl4.replaceAll(",", '%2C'); // replace comma for url
  restGETurl4 = restGETurl4.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicOverview}${restGETurl4}`);
    setTopicOverviewPositiveTopic(resp.data.positiveTopics);
    setTopicOverviewNegativeTopic(resp.data.negativeTopics);
    setTopicComparisonList(resp.data.TopicComparisonList);
    setTopicComparisonListAll(resp.data.TopicComparisonListAll);
    setTopicSentimentComparisonList(resp.data.topicSentimentComparison);


    let buzzWord_pos = resp.data.positiveTopics;
    let buzzWord_neg = resp.data.negativeTopics;

    let buzzWord_pos_filter = buzzWord_pos.filter(data => data['Percent Change'] >= 100);
    let buzzWord_neg_filter = buzzWord_neg.filter(data => data['Percent Change'] >= 100);

    buzzWord_pos_filter = buzzWord_pos_filter.map(data => data['WORD']);
    buzzWord_neg_filter = buzzWord_neg_filter.map(data => data['WORD']);

    setBuzzWordsPositive(buzzWord_pos_filter);
    setBuzzWordsNegative(buzzWord_neg_filter);


  } catch (err) {
      // Handle Error Here
      console.error(err);


  }

  setTopicOverviewIsLoading(false);
  setProgress(prevCount => prevCount + 10);


};


// Star Filter Selection
const getTopicOverviewDataFilter = async () => {


    // Topic Overview Data
    let filterResult = topicOverviewcustomStarSelection.fiveStar.toString() + "," + topicOverviewcustomStarSelection.fourStar.toString() + "," + topicOverviewcustomStarSelection.threeStar.toString() + "," + topicOverviewcustomStarSelection.twoStar.toString() + "," + topicOverviewcustomStarSelection.oneStar.toString();

    // Topic Overview Data
    setTopicOverviewIsLoading(true);
    var restGETurl4 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&starFilter="}${filterResult}`;
    restGETurl4 = restGETurl4.replaceAll(",", '%2C'); // replace comma for url
    restGETurl4 = restGETurl4.replaceAll(" ", '%20'); // empty space for url
  
    try {
      const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicOverview}${restGETurl4}`);
      // handle success

      setTopicOverviewPositiveTopic(resp.data.positiveTopics);
      setTopicOverviewNegativeTopic(resp.data.negativeTopics);
      setTopicComparisonList(resp.data.TopicComparisonList);
      setTopicComparisonListAll(resp.data.TopicComparisonListAll);
      setTopicSentimentComparisonList(resp.data.topicSentimentComparison);

      let buzzWord_pos = resp.data.positiveTopics;
      let buzzWord_neg = resp.data.negativeTopics;

      let buzzWord_pos_filter = buzzWord_pos.filter(data => data['Percent Change'] >= 100);
      let buzzWord_neg_filter = buzzWord_neg.filter(data => data['Percent Change'] >= 100);

      buzzWord_pos_filter = buzzWord_pos_filter.map(data => data['WORD']);
      buzzWord_neg_filter = buzzWord_neg_filter.map(data => data['WORD']);

      setBuzzWordsPositive(buzzWord_pos_filter);
      setBuzzWordsNegative(buzzWord_neg_filter);

      setTopicOverviewIsLoading(false);
  
  
    } catch (err) {
        // Handle Error Here
        console.error(err);
  
        setTopicOverviewIsLoading(false);
  
    }



};

const getTopicInsightData = async () => {
  // Topic Insight Data Filter
  var restGETurl5 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}`;
  restGETurl5 = restGETurl5.replaceAll(",", '%2C'); // replace comma for url
  restGETurl5 = restGETurl5.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicList}${restGETurl5}`);
    setTopicInsightSelection(''); // Need to Reset the list or a crash will occur
    setTopicInsightItems(resp.data.topicList);

  } catch (err) {
      // Handle Error Here
      console.error(err);


  }

  setProgress(prevCount => prevCount + 10);

};


const getSentimentOverviewData = async () => {
  // Sentiment Overview Data
  setSentimentOverviewPositiveIsLoading(true);
  setSentimentOverviewNegativeIsLoading(true);
  var restGETurl6 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categorySelection="}${sentimentOverviewFilter}`;
  restGETurl6 = restGETurl6.replaceAll(",", '%2C'); // replace comma for url
  restGETurl6 = restGETurl6.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].sentimentOverview}${restGETurl6}`);
    setSentimentOverviewPositiveData(resp.data.sentimentPos);
    setSentimentOverviewNegativeData(resp.data.sentimentNeg);

  } catch (err) {
      // Handle Error Here
      console.error(err);


  }

  setSentimentOverviewPositiveIsLoading(false);
  setSentimentOverviewNegativeIsLoading(false);
  setProgress(prevCount => prevCount + 10);

};


const getCategorySummaryData = async () => {
  // Category Detail Data
  setSoftwareSummaryIsLoading(true);
  setPictureSummaryIsLoading(true);
  setPictureSummaryIsLoading(true);
  setPriceSummaryIsLoading(true);
  setDesignSummaryIsLoading(true);
  setFeatureSummaryIsLoading(true);
  setSoundSummaryIsLoading(true);

  var restGETurl7 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}`;
  restGETurl7 = restGETurl7.replaceAll(",", '%2C'); // replace comma for url
  restGETurl7 = restGETurl7.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicList}${restGETurl7}`);
    setCategoryDetailValueBoxData(resp.data.ValueBoxData);
    setSoftwareTopic(resp.data.softwareTopic);
    setPictureTopic(resp.data.pictureTopic);
    setPriceTopic(resp.data.priceTopic);
    setDesignTopic(resp.data.designTopic);
    setFeatureTopic(resp.data.featureTopic);
    setToundTopic(resp.data.soundTopic);

  } catch (err) {
      // Handle Error Here
      console.error(err);


  }

  setSoftwareSummaryIsLoading(false);
  setPictureSummaryIsLoading(false);
  setPictureSummaryIsLoading(false);
  setPriceSummaryIsLoading(false);
  setDesignSummaryIsLoading(false);
  setFeatureSummaryIsLoading(false);
  setSoundSummaryIsLoading(false);

  setProgress(prevCount => prevCount + 10);

};


const getCategoryDetailData = async () => {
  // Category Detail - Topic Insight Data Filter
  var restGETurl8 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}`;
  restGETurl8 = restGETurl8.replaceAll(",", '%2C'); // replace comma for url
  restGETurl8 = restGETurl8.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicInsightFilter}${restGETurl8}`);
    setCategoryDetailTopicInsightSoftwareSelection(''); // Need to Reset the list or a crash will occur
    setCategoryDetailTopicInsightPictureSelection(''); // Need to Reset the list or a crash will occur
    setCategoryDetailTopicInsightPriceSelection(''); // Need to Reset the list or a crash will occur
    setCategoryDetailTopicInsightDesignSelection(''); // Need to Reset the list or a crash will occur
    setCategoryDetailTopicInsightFeatureSelection(''); // Need to Reset the list or a crash will occur
    setCategoryDetailTopicInsightSoundSelection(''); // Need to Reset the list or a crash will occur

    // Check if response is single or multiple
    // singe Topic list will need to be force into an array or an error would appear

    if(Array.isArray(resp.data.softwareTopicList)){
      setCategoryDetailTopicInsightSoftwareItem(resp.data.softwareTopicList);
    }else{
      setCategoryDetailTopicInsightSoftwareItem([resp.data.softwareTopicList]);
    }

    if(Array.isArray(resp.data.pictureTopicList)){
      setCategoryDetailTopicInsightPictureItem(resp.data.pictureTopicList);
    }else{
      setCategoryDetailTopicInsightPictureItem([resp.data.pictureTopicList]);
    }

    if(Array.isArray(resp.data.priceTopicList)){
      setCategoryDetailTopicInsightPriceItem(resp.data.priceTopicList);
    }else{
      setCategoryDetailTopicInsightPriceItem([resp.data.priceTopicList]);
    }

    if(Array.isArray(resp.data.designTopicList)){
      setCategoryDetailTopicInsightDesignItem(resp.data.designTopicList);
    }else{
      setCategoryDetailTopicInsightDesignItem([resp.data.designTopicList]);
    }

    if(Array.isArray(resp.data.featureTopicList)){
      setCategoryDetailTopicInsightFeatureItem(resp.data.featureTopicList);
    }else{
      setCategoryDetailTopicInsightFeatureItem([resp.data.featureTopicList]);
    }

    if(Array.isArray(resp.data.soundTopicList)){
      setCategoryDetailTopicInsightSoundItem(resp.data.soundTopicList);
    }else{
      setCategoryDetailTopicInsightSoundItem([resp.data.soundTopicList]);
    }

  } catch (err) {
      // Handle Error Here
      console.error(err);


  }

  setProgress(prevCount => prevCount + 10);

};


const getRawData = async () => {
  // Raw Data
  let restGETurl15a = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
  restGETurl15a = restGETurl15a.replaceAll(",", '%2C'); // replace comma for url
  restGETurl15a = restGETurl15a.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].rawData}${restGETurl15a}`);
    setRawData(resp.data.rawData);

  } catch (err) {
      // Handle Error Here
      console.error(err);


  }


};


const getJdRawData = async () => {
  // To Show JD Raw Download Button
  if(websiteSelection.includes("JD")){
    setShowJDbutton(true);

    // JD Raw Data
    let restGETurl16a = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
    restGETurl16a = restGETurl16a.replaceAll(",", '%2C'); // replace comma for url
    restGETurl16a = restGETurl16a.replaceAll(" ", '%20'); // empty space for url

    try {
      const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].jdRawData}${restGETurl16a}`);
      setJdRawData(resp.data.rawData);
  
    } catch (err) {
        // Handle Error Here
        console.error(err);
  
  
    }



  }else{
    setShowJDbutton(false);
  }




};



const getViewIndividualSentenceData = async () => {
  // View Individual Sentence  (Last Item)
  setViewIndividualSentenceIsLoading(true);
  let restGETurl15 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categoryFilter="}${"all"}`;
  restGETurl15 = restGETurl15.replaceAll(",", '%2C'); // replace comma for url
  restGETurl15 = restGETurl15.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].viewIndividualSentence}${restGETurl15}`);
    setViewIndividualSentenceData(resp.data.viewIndividualSentence);

  } catch (err) {
      // Handle Error Here
      console.error(err);


  }

  setProgress(prevCount => prevCount + 10);
  setViewIndividualSentenceIsLoading(false);


};






const clearPage = () => {
  setSmatWeeklyTrendData([]);
  setHighWeeklyTrendData('');
  setLowWeeklyTrendData('');

  setUniqueTrendData([]);
  setUniqueTrendDataPercentCum([]);
  setUniqueTrendStarRatingAvg([]);
  setUniqueTrendStarColor('info');

  setSmatTrendData([]);
  setSmatTrendDataPercentCum([]);
  setSmatTrendStarRatingAvg([]);
  setSmatTrendStarColor('');

  setCategorySummaryData([]);

  setCategoryLabel([]);
  setCategoryRating([]);
  setCategoryColor('');
  setCategoryFiveStarCount([]);
  setCategoryFourStarCount([]);
  setCategoryThreeStarCount([]);
  setCategoryTwoStarCount([]);
  setCategoryOneStarCount([]);
  setCategoryFiveStarPercent([]);
  setCategoryFourStarPercent([]);
  setCategoryThreeStarPercent([]);
  setCategoryTwoStarPercent([]);
  setCategoryOneStarPercent([]);
  setCategoryPositiveCount([]);
  setCategoryNegativeCount([]);
  setCategoryPositivePercent([]);
  setCategoryNegativePercent([]);
  setCategorySoftware([]);
  setCategoryPicture([]);
  setCategoryPrice([]);
  setCategoryDesign([]);
  setCategoryFeature([]);
  setCategorySound([]);

  setTopicOverviewPositiveTopic([]);
  setTopicOverviewNegativeTopic([]);
  setTopicComparisonList([]);
  setTopicComparisonListAll([]);
  setTopicSentimentComparisonList([]);

  setTopicInsightSelection('');
  setTopicInsightItems([]);

  setSentimentOverviewPositiveData([]);
  setSentimentOverviewNegativeData([]);

  setCategoryDetailValueBoxData([]);
  setSoftwareTopic([]);
  setPictureTopic([]);
  setPriceTopic([]);
  setDesignTopic([]);
  setFeatureTopic([]);
  setToundTopic([]);
  setCategoryDetailTopicInsightSoftwareItem([]);
  setCategoryDetailTopicInsightPictureItem([]);
  setCategoryDetailTopicInsightPriceItem([]);
  setCategoryDetailTopicInsightDesignItem([]);
  setCategoryDetailTopicInsightFeatureItem([]);
  setCategoryDetailTopicInsightSoundItem([]);
  setRawData([]);
  setJdRawData([]);
  setViewIndividualSentenceData([]);

  setCategoryDetailTopicInsightSoftwareSelection('');
  setCategoryDetailTopicInsightPictureSelection('');
  setCategoryDetailTopicInsightPriceSelection('');
  setCategoryDetailTopicInsightDesignSelection('');
  setCategoryDetailTopicInsightFeatureSelection('');
  setCategoryDetailTopicInsightSoundSelection('');

}

const onClickChartData = () => {

  function getData()
    {
        // defer the execution of anonymous function for 
        // 3 seconds and go to next line of code.
        setTimeout(function(){ 
          setProgress(0);
          setProgress(prevCount => prevCount + 10);

        }, 1000);  
        sendModelData();
        getWeeklyStarTrendData();
        getUniqueTrendData("day");
        getTrendData();
        getCategoryData();
        getTopicOverviewData();
        getTopicInsightData();
        getSentimentOverviewData();
        getCategorySummaryData();
        getCategoryDetailData();
        getRawData();
        getJdRawData();
        getViewIndividualSentenceData();
    };


    getData();



    // Reset Star Filter Selections

    setTopicOverviewcustomStarSelection((prevState) => {
      return({
        ...prevState,
          fiveStar: true,
          fourStar: true,
          threeStar: true,
          twoStar: true,
          oneStar: true
      });
    });

    setTopicInsightcustomStarSelection1((prevState) => {
      return({
        ...prevState,
          fiveStar: true,
          fourStar: true,
          threeStar: true,
          twoStar: true,
          oneStar: true
      });
    });

    setTopicSearchcustomStarSelection1((prevState) => {
      return({
        ...prevState,
          fiveStar: true,
          fourStar: true,
          threeStar: true,
          twoStar: true,
          oneStar: true
      });
    });




}


const getTopicInsightData2 = async () => {
  // Topic Insight Data
  setTopicInsightIsLoading(true);
  var restGETurl0 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${topicInsightSelection}`;
  restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
  restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsight}${restGETurl0}`);

    setTopicInsightTopicPhraseData(resp.data.topicPhrase);
    setTopicInsightPositiveSentimentData(resp.data.topicSentimentPos);
    setTopicInsightNegativeSentimentData(resp.data.topicSentimentNeg);
    setTopicInsightTopicTrendData(resp.data.topicTrend);

    setTopicInsightIsLoading(false);

  } catch (err) {
      // Handle Error Here
      console.error(err);
      setTopicInsightIsLoading(false);
  };


  setTopicInsightcustomStarSelection1((prevState) => {
    return({
      ...prevState,
        fiveStar: true,
        fourStar: true,
        threeStar: true,
        twoStar: true,
        oneStar: true
    });
  });




};



const onClickTopicInsight = () => {
  
  getTopicInsightData2();



}


// Star Filter for Topic Insight
const getTopicInsightData2Filter = async () => {

    const axios = require('axios');

    // Topic Insight Data
    setTopicInsightIsLoading(true);


    let filterResult = topicInsightcustomStarSelection1.fiveStar.toString() + "," + topicInsightcustomStarSelection1.fourStar.toString() + "," + topicInsightcustomStarSelection1.threeStar.toString() + "," + topicInsightcustomStarSelection1.twoStar.toString() + "," + topicInsightcustomStarSelection1.oneStar.toString();


    let restGETurl0 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${topicInsightSelection}${"&starFilter="}${filterResult}`;
    restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
    restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url
  

    
    try {
      const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsight}${restGETurl0}`);
  
      // handle success

      setTopicInsightTopicPhraseData(resp.data.topicPhrase);
      setTopicInsightPositiveSentimentData(resp.data.topicSentimentPos);
      setTopicInsightNegativeSentimentData(resp.data.topicSentimentNeg);
      setTopicInsightTopicTrendData(resp.data.topicTrend);

      setTopicInsightIsLoading(false);
  
    } catch (err) {
        // Handle Error Here
        console.error(err);
        setTopicInsightTopicPhraseData([]);
        setTopicInsightPositiveSentimentData([]);
        setTopicInsightNegativeSentimentData([]);
        setTopicInsightTopicTrendData([]);

        setTopicInsightIsLoading(false);
    };



};






const getTopicSearchData = async () => {
  // Topic Search Data
  setTopicSearchIsLoading(true);
  var restGETurlTopicSearch = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicSearch="}${topicSearchItems}`;
  restGETurlTopicSearch = restGETurlTopicSearch.replaceAll(",", '%2C'); // replace comma for url
  restGETurlTopicSearch = restGETurlTopicSearch.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicSearch}${restGETurlTopicSearch}`);

    setTopicSearchTopicBreakdown(resp.data.topicBreakdown);
    setTopicSearchPositiveSentimentData(resp.data.topicSentimentPos);
    setTopicSearchNegativeSentimentData(resp.data.topicSentimentNeg);
    setTopicSearchTrendData(resp.data.topicTrend);
    setTopicSearchTopicSentimentData(resp.data.topicSentiment)

    setTopicSearchIsLoading(false);

  } catch (err) {
      // Handle Error Here
      console.error(err);
      setTopicSearchIsLoading(false);
  };


  setTopicSearchcustomStarSelection1((prevState) => {
    return({
      ...prevState,
        fiveStar: true,
        fourStar: true,
        threeStar: true,
        twoStar: true,
        oneStar: true
    });
  });




};


const getTopicSearchDataFilter = async () => {


    // Topic Search Data
    const axios = require('axios');

    setTopicSearchIsLoading(true);

    let filterResult = topicSearchcustomStarSelection1.fiveStar.toString() + "," + topicSearchcustomStarSelection1.fourStar.toString() + "," + topicSearchcustomStarSelection1.threeStar.toString() + "," + topicSearchcustomStarSelection1.twoStar.toString() + "," + topicSearchcustomStarSelection1.oneStar.toString();

    let restGETurlTopicSearch = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicSearch="}${topicSearchItems}${"&starFilter="}${filterResult}`;
    restGETurlTopicSearch = restGETurlTopicSearch.replaceAll(",", '%2C'); // replace comma for url
    restGETurlTopicSearch = restGETurlTopicSearch.replaceAll(" ", '%20'); // empty space for url


  
    try {
      const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicSearch}${restGETurlTopicSearch}`);
  
      if( resp.data.topicBreakdown ) {
        setTopicSearchTopicBreakdown(resp.data.topicBreakdown);
      }else{
        setTopicSearchTopicBreakdown([]);
      }
  
      if( resp.data.topicSentimentPos) {
        setTopicSearchPositiveSentimentData(resp.data.topicSentimentPos);
      }else{
        setTopicSearchPositiveSentimentData([]);
      }
  
      if( resp.data.topicSentimentNeg) {
        setTopicSearchNegativeSentimentData(resp.data.topicSentimentNeg);
      }else{
        setTopicSearchNegativeSentimentData([]);
      }
  
      if( resp.data.topicTrend) {
        setTopicSearchTrendData(resp.data.topicTrend);
      }else{
        setTopicSearchTrendData([]);
      }
  
      if( resp.data.topicSentiment) {
        setTopicSearchTopicSentimentData(resp.data.topicSentiment);
      }else{
        setTopicSearchTopicSentimentData([]);
      }
  
      setTopicSearchIsLoading(false);
  
    } catch (err) {
        // Handle Error Here
        console.error(err);

        setTopicSearchTopicBreakdown([]);
        setTopicSearchPositiveSentimentData([]);
        setTopicSearchNegativeSentimentData([]);
        setTopicSearchTrendData([]);
        setTopicSearchTopicSentimentData([]);

        setTopicSearchIsLoading(false);
    };





};


const onClickTopicSearch = () => {

  getTopicSearchData();



  
};



const onClickSentimentOverviewPositiveFilter = data => () => {


  const axios = require('axios');

  setSentimentOverviewPositiveIsLoading(true);
  //setSentimentOverviewNegativeIsLoading(true);
  let restGETurl6 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categorySelection="}${data}`;
  restGETurl6 = restGETurl6.replaceAll(",", '%2C'); // replace comma for url
  restGETurl6 = restGETurl6.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].sentimentOverview}${restGETurl6}`).then(function (response) { 
    // handle success

    setSentimentOverviewPositiveData(response.data.sentimentPos);
    //setSentimentOverviewNegativeData(response.data.sentimentNeg);
    

  }).catch(function (error) { 
    // handle error
    console.log(error);
    setSentimentOverviewPositiveData(['']);
  }).then(function () { 
    // always executed

    setSentimentOverviewPositiveIsLoading(false);
    //setSentimentOverviewNegativeIsLoading(false);
  });

  
};



const onClickSentimentOverviewNegativeFilter = data => () => {


  const axios = require('axios');

  //setSentimentOverviewPositiveIsLoading(true);
  setSentimentOverviewNegativeIsLoading(true);
  let restGETurl6 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&categorySelection="}${data}`;
  restGETurl6 = restGETurl6.replaceAll(",", '%2C'); // replace comma for url
  restGETurl6 = restGETurl6.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].sentimentOverview}${restGETurl6}`).then(function (response) { 
    // handle success

    //setSentimentOverviewPositiveData(response.data.sentimentPos);
    setSentimentOverviewNegativeData(response.data.sentimentNeg);
    

  }).catch(function (error) { 
    // handle error
    console.log(error);
    setSentimentOverviewNegativeData(['']);
  }).then(function () { 
    // always executed

    //setSentimentOverviewPositiveIsLoading(false);
    setSentimentOverviewNegativeIsLoading(false);
  });

  
};



const onClickCategoryDetailSoftwareTopicInsight = () => {
  const axios = require('axios');

  // Topic Insight Data
  setCategoryDetailSoftwaretopicInsightIsLoading(true);
  let restGETurlSoftware = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${categoryDetailTopicInsightSoftwareSelection}`;
  restGETurlSoftware = restGETurlSoftware.replaceAll(",", '%2C'); // replace comma for url
  restGETurlSoftware = restGETurlSoftware.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicInsight}${restGETurlSoftware}`).then(function (response) { 
    // handle success

    setCategoryDetailTopicInsightTopicPhraseSoftwareData(response.data.topicPhrase);
    setCategoryDetailTopicInsightPositiveSentimentSoftwareData(response.data.topicSentimentPos);
    setCategoryDetailTopicInsightNegativeSentimentSoftwareData(response.data.topicSentimentNeg);
    setCategoryDetailTopicInsightTopicTrendSoftwareData(response.data.topicTrend);
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    setCategoryDetailSoftwaretopicInsightIsLoading(false);
  });

}


const onClickCategoryDetailPictureTopicInsight = () => {
  const axios = require('axios');

  // Topic Insight Data
  setCategoryDetailPicturetopicInsightIsLoading(true);
  let restGETurlPicture = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${categoryDetailTopicInsightPictureSelection}`;
  restGETurlPicture = restGETurlPicture.replaceAll(",", '%2C'); // replace comma for url
  restGETurlPicture = restGETurlPicture.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicInsight}${restGETurlPicture}`).then(function (response) { 
    // handle success

    setCategoryDetailTopicInsightTopicPhrasePictureData(response.data.topicPhrase);
    setCategoryDetailTopicInsightPositiveSentimentPictureData(response.data.topicSentimentPos);
    setCategoryDetailTopicInsightNegativeSentimentPictureData(response.data.topicSentimentNeg);
    setCategoryDetailTopicInsightTopicTrendPictureData(response.data.topicTrend);
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    setCategoryDetailPicturetopicInsightIsLoading(false);
  });

}


const onClickCategoryDetailPriceTopicInsight = () => {
  const axios = require('axios');

  // Topic Insight Data
  setCategoryDetailPricetopicInsightIsLoading(true);
  let restGETurlPrice = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${categoryDetailTopicInsightPriceSelection}`;
  restGETurlPrice = restGETurlPrice.replaceAll(",", '%2C'); // replace comma for url
  restGETurlPrice = restGETurlPrice.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicInsight}${restGETurlPrice}`).then(function (response) { 
    // handle success

    setCategoryDetailTopicInsightTopicPhrasePriceData(response.data.topicPhrase);
    setCategoryDetailTopicInsightPositiveSentimentPriceData(response.data.topicSentimentPos);
    setCategoryDetailTopicInsightNegativeSentimentPriceData(response.data.topicSentimentNeg);
    setCategoryDetailTopicInsightTopicTrendPriceData(response.data.topicTrend);
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    setCategoryDetailPricetopicInsightIsLoading(false);
  });

}



const onClickCategoryDetailDesignTopicInsight = () => {
  const axios = require('axios');

  // Topic Insight Data
  setCategoryDetailDesigntopicInsightIsLoading(true);
  let restGETurlDesign = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${categoryDetailTopicInsightDesignSelection}`;
  restGETurlDesign = restGETurlDesign.replaceAll(",", '%2C'); // replace comma for url
  restGETurlDesign = restGETurlDesign.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicInsight}${restGETurlDesign}`).then(function (response) { 
    // handle success

    setCategoryDetailTopicInsightTopicPhraseDesignData(response.data.topicPhrase);
    setCategoryDetailTopicInsightPositiveSentimentDesignData(response.data.topicSentimentPos);
    setCategoryDetailTopicInsightNegativeSentimentDesignData(response.data.topicSentimentNeg);
    setCategoryDetailTopicInsightTopicTrendDesignData(response.data.topicTrend);
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    setCategoryDetailDesigntopicInsightIsLoading(false);
  });

}

const onClickCategoryDetailFeatureTopicInsight = () => {
  const axios = require('axios');

  // Topic Insight Data
  setCategoryDetailFeaturetopicInsightIsLoading(true);
  let restGETurlFeature = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${categoryDetailTopicInsightFeatureSelection}`;
  restGETurlFeature = restGETurlFeature.replaceAll(",", '%2C'); // replace comma for url
  restGETurlFeature = restGETurlFeature.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicInsight}${restGETurlFeature}`).then(function (response) { 
    // handle success

    setCategoryDetailTopicInsightTopicPhraseFeatureData(response.data.topicPhrase);
    setCategoryDetailTopicInsightPositiveSentimentFeatureData(response.data.topicSentimentPos);
    setCategoryDetailTopicInsightNegativeSentimentFeatureData(response.data.topicSentimentNeg);
    setCategoryDetailTopicInsightTopicTrendFeatureData(response.data.topicTrend);
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    setCategoryDetailFeaturetopicInsightIsLoading(false);
  });

}


const onClickCategoryDetailSoundTopicInsight = () => {
  const axios = require('axios');

  // Topic Insight Data
  setCategoryDetailSoundtopicInsightIsLoading(true);
  let restGETurlSound = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${categoryDetailTopicInsightSoundSelection}`;
  restGETurlSound = restGETurlSound.replaceAll(",", '%2C'); // replace comma for url
  restGETurlSound = restGETurlSound.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].categoryDetailTopicInsight}${restGETurlSound}`).then(function (response) { 
    // handle success

    setCategoryDetailTopicInsightTopicPhraseSoundData(response.data.topicPhrase);
    setCategoryDetailTopicInsightPositiveSentimentSoundData(response.data.topicSentimentPos);
    setCategoryDetailTopicInsightNegativeSentimentSoundData(response.data.topicSentimentNeg);
    setCategoryDetailTopicInsightTopicTrendSoundData(response.data.topicTrend);
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    setCategoryDetailSoundtopicInsightIsLoading(false);
  });

}


const onClickFullReview = (model, country, website, reviewID) => {
  setFullReviewData(''); // reset the data
  setFullReviewDrillDownIsLoading(true);  // Load Spinner
  const axios = require('axios');

  // Full Review
  //setCategoryDetailSoundtopicInsightIsLoading(true);
  let restGETurlFullReview = `${"&model="}${model}${"&website="}${website}${"&country="}${country}${"&reviewerID="}${reviewID}`;
  restGETurlFullReview = restGETurlFullReview.replaceAll(",", '%2C'); // replace comma for url
  restGETurlFullReview = restGETurlFullReview.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.starRatingAnalytic[0].fullReview}${restGETurlFullReview}`).then(function (response) { 
    // handle success


    setFullReviewData(response.data.fullReview);
  
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    //setFullReviewModal(true); // Show Pop-up
    setTimeout(() => {setFullReviewDrillDownIsLoading(false)}, 500); // Turn Spinner Off after .5 second
  });

}

////////////////////////////////

// Weekly Star Trend

let weeklyFiveStarData = smatWeeklyTrendData.map(data => data['5-Star']);
let weeklyFourStarData = smatWeeklyTrendData.map(data => data['4-Star']);
let weeklyThreeStarData = smatWeeklyTrendData.map(data => data['3-Star']);
let weeklyTwoStarData = smatWeeklyTrendData.map(data => data['2-Star']);
let weeklyOneStarData = smatWeeklyTrendData.map(data => data['1-Star']);

let weeklyTotalData = smatWeeklyTrendData.map(data => data['Review-Count']);
let weeklyAvgData = smatWeeklyTrendData.map(data => data['AVG-Rating']);

// Weekly Star Trend - Line Chart

let weeklyTrendViewTrendLabel = smatWeeklyTrendData.map(data => data.Date);

let weeklyTrendViewTrendDataSet = {
  type: 'line',
  labels: weeklyTrendViewTrendLabel,
  datasets: [
    {
      label: '5-Star',
      data: weeklyFiveStarData ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'B',
      type: 'bar',
      stack: 'stack1',
      order: 2
    },

    {
      label: '4-Star',
      data: weeklyFourStarData,
      fill: false,
      backgroundColor: 'rgb(36, 222, 96)',
      borderColor: 'rgba(36, 222, 96, 0.6)',
      yAxisID: 'B',
      type: 'bar',
      stack: 'stack1',
      order: 3
    },

    {
      label: '3-Star',
      data: weeklyThreeStarData,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      yAxisID: 'B',
      type: 'bar',
      stack: 'stack1',
      order: 4
    },

    {
      label: '2-Star',
      data: weeklyTwoStarData,
      fill: false,
      backgroundColor: 'rgb(236, 50, 50)',
      borderColor: 'rgba(236, 50, 50, 0.6)',
      yAxisID: 'B',
      type: 'bar',
      stack: 'stack1',
      order: 5
    },

    {
      label: '1-Star',
      data: weeklyOneStarData,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'B',
      type: 'bar',
      stack: 'stack1',
      order: 6
    },

    {
      label: 'Average Score',
      data: weeklyAvgData,
      fill: false,
      backgroundColor: 'rgb(0, 0, 255)',
      borderColor: 'rgba(0, 0, 255, 0.6)',
      yAxisID: 'A',
      order: 1
    }


  ]

}


const weeklyTrendViewTrendOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },
  
  spanGaps: true,

  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 5,
          callback: function(value) {
            return value 
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'week'
      }
  }]
  },
}

// Weekly Trend Unique Table using react-data-table-component

const weeklyTrendTableDataSet = 
smatWeeklyTrendData.map((SmatWeeklyTrendData) => {
    return ({
      id: SmatWeeklyTrendData.id,
      date: SmatWeeklyTrendData.Date,
      fiveStar: SmatWeeklyTrendData['5-Star'],
      fourStar: SmatWeeklyTrendData['4-Star'],
      threeStar: SmatWeeklyTrendData['3-Star'],
      twoStar: SmatWeeklyTrendData['2-Star'],
      oneStar: SmatWeeklyTrendData['1-Star'],
      total: SmatWeeklyTrendData['Review-Count'],
      avg: SmatWeeklyTrendData['AVG-Rating']
    })
  });
  
const weeklyTrendTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Weekly Average',
    selector: 'avg',
    sortable: true,
    center: true
  },
  {
    name: 'Weekly Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



///////////////////////////////



// Star Rating Trend Unique - Info Box
let fiveStarData = uniqueTrendData.map(data => data['5-Star']);
let fourStarData = uniqueTrendData.map(data => data['4-Star']);
let threeStarData = uniqueTrendData.map(data => data['3-Star']);
let twoStarData = uniqueTrendData.map(data => data['2-Star']);
let oneStarData = uniqueTrendData.map(data => data['1-Star']);

let fiveStarDataPercentCum = uniqueTrendDataPercentCum.map(data => data['5-Star']);
let fourStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['4-Star']);
let threeStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['3-Star']);
let twoStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['2-Star']);
let oneStarDataPercentCum  = uniqueTrendDataPercentCum.map(data => data['1-Star']);
let cumulativeTotal  = uniqueTrendData.map(data => data['Cumulative Total']);


// Star Rating Trend Unique - Line Chart

let uniqueTrendViewTrendLabel = uniqueTrendDataPercentCum.map(data => data.Date);

let uniqueTrendViewTrendDataSet = {
  type: 'line',
  labels: uniqueTrendViewTrendLabel,
  datasets: [
    {
      label: '5-Star',
      data: fiveStarDataPercentCum ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '4-Star',
      data: fourStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(36, 222, 96)',
      borderColor: 'rgba(36, 222, 96, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '3-Star',
      data: threeStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '2-Star',
      data: twoStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(236, 50, 50)',
      borderColor: 'rgba(236, 50, 50, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '1-Star',
      data: oneStarDataPercentCum,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: cumulativeTotal,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const uniqueTrendViewTrendOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setUniqueTrendIndividualSentenceDrillDownData([]);
      setUniqueTrendPositiveDrillDownData([]);
      setUniqueTrendNegativeDrillDownData([]);
      setModalTitle('');

      setOpenModalUniqueStarRatingTrend(true); // Show Pop-up
      setDrillDownIsLoading(true); 
      

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url

      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].trendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success
        setUniqueTrendIndividualSentenceDrillDownData(response.data.individualSentenceDrillDown);
        setUniqueTrendPositiveDrillDownData(response.data.topicPosList);
        setUniqueTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(xLabel);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        //setOpenModalUniqueStarRatingTrend(true); // Show Pop-up

        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);

      });

    }

  },
  
  spanGaps: true,

  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      distribution: 'series',
      time: {
          unit: uniqueTrendFilter //'month'
      }
  }]
  },
}

// Star Rating Trend Unique Table using react-data-table-component

const uniqueTrendTrendTableDataSet = 
uniqueTrendData.map((UniqueTrendData) => {
    return ({
      id: UniqueTrendData.id,
      date: UniqueTrendData.Date,
      fiveStar: UniqueTrendData['5-Star'],
      fourStar: UniqueTrendData['4-Star'],
      threeStar: UniqueTrendData['3-Star'],
      twoStar: UniqueTrendData['2-Star'],
      oneStar: UniqueTrendData['1-Star'],
      total: UniqueTrendData['Total'],
      cumulative: UniqueTrendData['Cumulative Total']
    })
  });
  
const uniqueTrendTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];


// Star Rating Trend Unique - Doughnut Chart

// Implement text inside Doughnut
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function() {
    originalDoughnutDraw.apply(this, arguments);
    
    var chart = this.chart.chart;
    var ctx = chart.ctx;
    var width = chart.width;
    var height = chart.height;

    var fontSize = (height / 114).toFixed(2); 
    ctx.font = fontSize + "em Verdana";
    ctx.textBaseline = "middle";

    var text = chart.config.data.text,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 1.8;

    ctx.fillText(text, textX, textY);
  }
});

let uniquePieViewTrendDataSet = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: [
        fiveStarData.reduce(function(a, b) { return a + b; }, 0), 
        fourStarData.reduce(function(a, b) { return a + b; }, 0), 
        threeStarData.reduce(function(a, b) { return a + b; }, 0), 
        twoStarData.reduce(function(a, b) { return a + b; }, 0), 
        oneStarData.reduce(function(a, b) { return a + b; }, 0)],
      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: uniqueTrendStarRatingAvg
  } 

  let uniquePieViewTrendOptions = {

    plugins: {

      datalabels: {
        display: true,
        color: "white",
        font:{
          size: 14,
          weight: "bold"
        },
        formatter: (value, ctx) => { // formatter: (val) => `${val}`,
          let datasets = ctx.chart.data.datasets;
          let percentage;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            percentage = Math.round((value / sum) * 100) + '%';
            return percentage;
          } else {
            return percentage;
          }
        }
      }
    },
  

    // Drill Down
    onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);


          // reset the data
          setOpenModalUniqueStarRatingPie(true); // Show Pop-up
          setStarRatingPieIndividualSentenceDrillDownData([]);
          setStarRatingPiePositiveDrillDownData([]);
          setStarRatingPieNegativeDrillDownData([]);
          setModalTitle('');
          setDrillDownIsLoading(true); 

          let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownLabel="}${xLabel}`;
          drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
          drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
          const axios = require('axios');
          axios.get(`${SMATrestAPI.starRatingAnalytic[0].starLabelDrillDown}${drillDownUrl}`).then(function (response) { 
            // handle success

            setStarRatingPieIndividualSentenceDrillDownData(response.data.individualSentenceDrillDown);
            setStarRatingPiePositiveDrillDownData(response.data.topicPosList);
            setStarRatingPieNegativeDrillDownData(response.data.topicNegList);
            setModalTitle(xLabel);
            
          }).catch(function (error) { 
            // handle error
            console.log(error);
          }).then(function () { 
            // always executed
            
            setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
          });

        }

      }

     

  }


// Star Rating Trend Unique Table using react-data-table-component 

const uniqueTrendPieTableDataSet = [{
    fiveStar: fiveStarData.reduce(function(a, b) { return a + b; }, 0), 
    fourStar: fourStarData.reduce(function(a, b) { return a + b; }, 0), 
    threeStar: threeStarData.reduce(function(a, b) { return a + b; }, 0), 
    twoStar: twoStarData.reduce(function(a, b) { return a + b; }, 0), 
    oneStar: oneStarData.reduce(function(a, b) { return a + b; }, 0),
    rating: uniqueTrendStarRatingAvg
}]
;
  

const uniqueTrendPieTableColumn = [
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  }
];


// Star Rating Trend SMAT - Info Box
let fiveStarDataSmat = smatTrendData.map(data => data['5-Star']);
let fourStarDataSmat  = smatTrendData.map(data => data['4-Star']);
let threeStarDataSmat  = smatTrendData.map(data => data['3-Star']);
let twoStarDataSmat  = smatTrendData.map(data => data['2-Star']);
let oneStarDataSmat  = smatTrendData.map(data => data['1-Star']);

let fiveStarDataPercentCumSmat  = smatTrendDataPercentCum.map(data => data['5-Star']);
let fourStarDataPercentCumSmat   = smatTrendDataPercentCum.map(data => data['4-Star']);
let threeStarDataPercentCumSmat   = smatTrendDataPercentCum.map(data => data['3-Star']);
let twoStarDataPercentCumSmat   = smatTrendDataPercentCum.map(data => data['2-Star']);
let oneStarDataPercentCumSmat   = smatTrendDataPercentCum.map(data => data['1-Star']);
let cumulativeTotalSmat   = smatTrendData.map(data => data['Cumulative Total']);


// Star Rating Trend - Line Chart

let smatTrendViewTrendLabel = smatTrendDataPercentCum.map(data => data.Date);

let smatTrendViewTrendDataSet = {
  type: 'line',
  labels: smatTrendViewTrendLabel,
  datasets: [
    {
      label: '5-Star',
      data: fiveStarDataPercentCumSmat ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '4-Star',
      data: fourStarDataPercentCumSmat,
      fill: false,
      backgroundColor: 'rgb(36, 222, 96)',
      borderColor: 'rgba(36, 222, 96, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '3-Star',
      data: threeStarDataPercentCumSmat,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '2-Star',
      data: twoStarDataPercentCumSmat,
      fill: false,
      backgroundColor: 'rgb(236, 50, 50)',
      borderColor: 'rgba(236, 50, 50, 0.6)',
      yAxisID: 'A'
    },

    {
      label: '1-Star',
      data: oneStarDataPercentCumSmat,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: cumulativeTotalSmat,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const smatTrendViewTrendOptions = {


// Drill Down
onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalStarRatingTrend(true); // Show Pop-up
      setUniqueTrendIndividualSentenceDrillDownData([]);
      setUniqueTrendPositiveDrillDownData([]);
      setUniqueTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url

      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].trendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setUniqueTrendIndividualSentenceDrillDownData(response.data.individualSentenceDrillDown);
        setUniqueTrendPositiveDrillDownData(response.data.topicPosList);
        setUniqueTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(xLabel);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      distribution: 'series',
      time: {
          unit: smatTrendFilter //'month'
      }
  }]
  },
}

// Star Rating Trend Table using react-data-table-component

const smatTrendTrendTableDataSet = 
smatTrendData.map((SmatTrendData) => {
    return ({
      id: SmatTrendData.id,
      date: SmatTrendData.Date,
      fiveStar: SmatTrendData['5-Star'],
      fourStar: SmatTrendData['4-Star'],
      threeStar: SmatTrendData['3-Star'],
      twoStar: SmatTrendData['2-Star'],
      oneStar: SmatTrendData['1-Star'],
      total: SmatTrendData['Total'],
      cumulative: SmatTrendData['Cumulative Total']
    })
  });
  
const smatTrendTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];


// Star Rating Trend - Doughnut Chart


let smatTrendDataPieViewTrendDataSet = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: [
        fiveStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
        fourStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
        threeStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
        twoStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
        oneStarDataSmat.reduce(function(a, b) { return a + b; }, 0)
      ],
      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: smatTrendStarRatingAvg
  } 

  let smatPieViewTrendOptions = {

    plugins: {

      datalabels: {
        display: true,
        color: "white",
        font:{
          size: 14,
          weight: "bold"
        },
        //formatter: (val) =>
        //`${val}`,
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          let percentage;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            percentage = Math.round((value / sum) * 100) + '%';
            return percentage;
          } else {
            return percentage;
          }
        }
      }
    },

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


        // reset the data
        setOpenModalStarRatingPie(true); // Show Pop-up
        setStarRatingPieIndividualSentenceDrillDownData([]);
        setStarRatingPiePositiveDrillDownData([]);
        setStarRatingPieNegativeDrillDownData([]);
        setModalTitle('');
        setDrillDownIsLoading(true); 

        let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownLabel="}${xLabel}`;
        drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
        drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
        const axios = require('axios');
        axios.get(`${SMATrestAPI.starRatingAnalytic[0].starLabelDrillDown}${drillDownUrl}`).then(function (response) { 
          // handle success

          setStarRatingPieIndividualSentenceDrillDownData(response.data.individualSentenceDrillDown);
          setStarRatingPiePositiveDrillDownData(response.data.topicPosList);
          setStarRatingPieNegativeDrillDownData(response.data.topicNegList);
          setModalTitle(xLabel);
          
        }).catch(function (error) { 
          // handle error
          console.log(error);
        }).then(function () { 
          // always executed
          
          setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
        });

      }

    }

  }


// Star Rating Trend Table using react-data-table-component 

const smatTrendPieTableDataSet = [{
    fiveStar: fiveStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
    fourStar: fourStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
    threeStar: threeStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
    twoStar: twoStarDataSmat.reduce(function(a, b) { return a + b; }, 0), 
    oneStar: oneStarDataSmat.reduce(function(a, b) { return a + b; }, 0),
    rating: smatTrendStarRatingAvg
}]
;
  

const smatTrendPieTableColumn = [
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  },
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  }
];



// Category Summary

// Dynamic Icon for Infobox
function IconSelectionCategorySummaryInfo1(){
  if(categoryColor[0] == "success"){
    return <ThumpsUpIcon />
  }else if(categoryColor[0] == "warning"){
    return <HandIcon />
  }else if(categoryColor[0] == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo2(){
  if(categoryColor[1] == "success"){
    return <ThumpsUpIcon />
  }else if(categoryColor[1] == "warning"){
    return <HandIcon />
  }else if(categoryColor[1] == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo3(){
  if(categoryColor[2] == "success"){
    return <ThumpsUpIcon />
  }else if(categoryColor[2] == "warning"){
    return <HandIcon />
  }else if(categoryColor[2] == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo4(){
  if(categoryColor[3] == "success"){
    return <ThumpsUpIcon />
  }else if(categoryColor[3] == "warning"){
    return <HandIcon />
  }else if(categoryColor[3] == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo5(){
  if(categoryColor[4] == "success"){
    return <ThumpsUpIcon />
  }else if(categoryColor[4] == "warning"){
    return <HandIcon />
  }else if(categoryColor[4] == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}

function IconSelectionCategorySummaryInfo6(){
  if(categoryColor[5] == "success"){
    return <ThumpsUpIcon />
  }else if(categoryColor[5] == "warning"){
    return <HandIcon />
  }else if(categoryColor[5] == "danger"){
    return <ThumpsDownIcon />
  }else{
    return <RemoveIcon />
  }
}


// Category Summary Radar Chart

let categorySummaryRadarDataSet = {
  spanGaps: true,
  type: 'radar',
  labels: categoryLabel,
  datasets: [

    {
      label: "Negative",
      data:categoryNegativePercent,
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },
    {
      label: "Positive",
      data:categoryPositivePercent,
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    }
    
  ]
  } 


  let categorySummaryRadarOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);


        // reset the data
        setOpenModalCategorySummarySentimentRadar(true); // Show Pop-up
        setCategorySummarySentimentRadarDrillDownData([]);
        setCategorySummarySentimentRadarPositiveDrillDownData([]);
        setCategorySummarySentimentRadarNegativeDrillDownData([]);
        setModalTitle('');
        setDrillDownIsLoading(true); 

        let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownCategory="}${xLabel}${"&drillDownSentiment="}${dataset.label}`;
        drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
        drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
        const axios = require('axios');
        axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummarySentimentDrillDown}${drillDownUrl}`).then(function (response) { 
          // handle success
          

          setCategorySummarySentimentRadarDrillDownData(response.data.individualSentenceDrillDown);
          setCategorySummarySentimentRadarPositiveDrillDownData(response.data.topicPosList);
          setCategorySummarySentimentRadarNegativeDrillDownData(response.data.topicNegList);
          setModalTitle(`${"Category - "}${xLabel}${" & Sentiment - "}${dataset.label}`);
          
        }).catch(function (error) { 
          // handle error
          console.log(error);
        }).then(function () { 
          // always executed
          
          setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
        });

      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component 

const categorySummaryRadarTableDataSet = 

categorySummaryData.map((CategorySummaryData) => {
  return ({
    id: CategorySummaryData.id,
    category: CategorySummaryData["Category"],
    positiveCount: CategorySummaryData["Positive Count"], 
    negativeCount: CategorySummaryData["Negative Count"], 
    positivePercent: CategorySummaryData["Positive Percent"], 
    negativePercent: CategorySummaryData["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive Count',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Negative Count',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

];  

// Category Summary Bar Chart

let categorySummaryBarDataSet = {
  type: 'bar',
  labels: categoryLabel,
  datasets: [

    {
      label: "5-Star",
      data:categoryFiveStarPercent,
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
    },
    {
      label: "4-Star",
      data:categoryFourStarPercent,
      borderColor: 'rgb(36, 222, 96)', // Line Color
      backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
    },
    {
      label: "3-Star",
      data:categoryThreeStarPercent,
      borderColor: 'rgb(245, 200, 15)', // Line Color
      backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
    },
    {
      label: "2-Star",
      data:categoryTwoStarPercent,
      borderColor: 'rgb(236, 50, 50)', // Line Color
      backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
    }
    ,
    {
      label: "1-Star",
      data:categoryOneStarPercent,
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
    }
    
  ]
  } 


  let categorySummaryBarOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


        // reset the data
        setOpenModalCategorySummaryStarRating(true); // Show Pop-up
        setCategorySummaryStarRatingDrillDownData([]);
        setCategorySummaryStarRatingPositiveDrillDownData([]);
        setCategorySummaryStarRatingNegativeDrillDownData([]);
        setModalTitle('');
        setDrillDownIsLoading(true); 

        let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownCategory="}${xLabel}${"&drillDownStar="}${dataset.label}`;
        drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
        drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
        const axios = require('axios');
        axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryStarRatingCategoryDrillDown}${drillDownUrl}`).then(function (response) { 
          // handle success

          setCategorySummaryStarRatingDrillDownData(response.data.individualSentenceDrillDown);
          setCategorySummaryStarRatingPositiveDrillDownData(response.data.topicPosList);
          setCategorySummaryStarRatingNegativeDrillDownData(response.data.topicNegList);
          setModalTitle(`${"Category - "}${xLabel}${" & Sentiment - "}${dataset.label}`);
          
        }).catch(function (error) { 
          // handle error
          console.log(error);
        }).then(function () { 
          // always executed
          
          setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
        });

      }

    },
    
    scales: {
      xAxes: [{
          stacked: true
      }],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontSize: 12,
            min: 0,
            max: 100,
            callback: function(value) {
              return value + "%"
             }
          }
        }
      ],

      
      scaleLabel: {
        display: true,
        labelString: 'Percentage',
      }
  }

  

  }



// Category Summary Bar Chart Table using react-data-table-component 

const categorySummaryBarTableDataSet = 
  categorySummaryData.map((CategorySummaryData) => {
    return ({
      //id: CategorySummaryData.id,
      category: CategorySummaryData['Category'],
      fiveStar: CategorySummaryData['5-Star Count'], 
      fourStar: CategorySummaryData['4-Star Count'], 
      threeStar: CategorySummaryData['3-Star Count'], 
      twoStar: CategorySummaryData['2-Star Count'], 
      oneStar: CategorySummaryData['1-Star Count']
  
    })
  });



const categorySummaryBarTableColumn = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    center: true
  },
{
  name: '5-Star',
  selector: 'fiveStar',
  sortable: true,
  center: true
},
{
  name: '4-Star',
  selector: 'fourStar',
  sortable: true,
  center: true
},
{
  name: '3-Star',
  selector: 'threeStar',
  sortable: true,
  center: true
},
{
  name: '2-Star',
  selector: 'twoStar',
  sortable: true,
  center: true
},
{
  name: '1-Star',
  selector: 'oneStar',
  sortable: true,
  center: true
}
];



// Category Summary - Software Trend - Combo Chart

let categorySummarySoftwareDate = categorySoftware.map(data => data.Date);
let categorySummarySoftwarePositiveCumPercent = categorySoftware.map(data => data['Positive Cumulative Percent']);
let categorySummarySoftwareNegativeCumPercent = categorySoftware.map(data => data['Negative Cumulative Percent']);
let categorySummarySoftwareTotalCumPercent = categorySoftware.map(data => data['Cumulative Total']);

let categorySummarySoftwareDataSet = {
  type: 'line',
  labels: categorySummarySoftwareDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummarySoftwarePositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummarySoftwareNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummarySoftwareTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



const categorySummarySoftwareOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalCategorySummarySoftwareTrend(true); // Show Pop-up
      setCategorySummarySoftwareTrendDrillDownData([]);
      setCategorySummarySoftwareTrendPositiveDrillDownData([]);
      setCategorySummarySoftwareTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownCategory="}${"software"}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setCategorySummarySoftwareTrendDrillDownData(response.data.individualSentenceDrillDown);
        setCategorySummarySoftwareTrendPositiveDrillDownData(response.data.topicPosList);
        setCategorySummarySoftwareTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Category = Software / "}${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Summary - Software Table using react-data-table-component

const categorySummarySoftwareTableDataSet = 
categorySoftware.map((CategorySoftware) => {
    return ({
      id: categorySoftware.id,
      date: CategorySoftware.Date,
      positiveCumulative: CategorySoftware['Positive Cumulative'],
      negativeCumulative: CategorySoftware['Negative Cumulative'],
      neutralCumulative: CategorySoftware['Neutral Cumulative'],
      cumulativeTotal: CategorySoftware['Cumulative Total']
    })
  });
  
const categorySummarySoftwareTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Category Summary - Picture Trend - Combo Chart

let categorySummaryPictureDate = categoryPicture.map(data => data.Date);
let categorySummaryPicturePositiveCumPercent = categoryPicture.map(data => data['Positive Cumulative Percent']);
let categorySummaryPictureNegativeCumPercent = categoryPicture.map(data => data['Negative Cumulative Percent']);
let categorySummaryPictureTotalCumPercent = categoryPicture.map(data => data['Cumulative Total']);

let categorySummaryPictureDataSet = {
  type: 'line',
  labels: categorySummaryPictureDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryPicturePositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryPictureNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryPictureTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



const categorySummaryPictureOptions = {
  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalCategorySummaryPictureTrend(true); // Show Pop-up
      setCategorySummaryPictureTrendDrillDownData([]);
      setCategorySummaryPictureTrendPositiveDrillDownData([]);
      setCategorySummaryPictureTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownCategory="}${"picture"}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setCategorySummaryPictureTrendDrillDownData(response.data.individualSentenceDrillDown);
        setCategorySummaryPictureTrendPositiveDrillDownData(response.data.topicPosList);
        setCategorySummaryPictureTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Category = Picture / "}${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Summary - Picture Table using react-data-table-component

const categorySummaryPictureTableDataSet = 
categoryPicture.map((CategoryPicture) => {
    return ({
      id: categoryPicture.id,
      date: CategoryPicture.Date,
      positiveCumulative: CategoryPicture['Positive Cumulative'],
      negativeCumulative: CategoryPicture['Negative Cumulative'],
      neutralCumulative: CategoryPicture['Neutral Cumulative'],
      cumulativeTotal: CategoryPicture['Cumulative Total']
    })
  });
  
const categorySummaryPictureTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulativer',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];


// Category Summary - Price Trend - Combo Chart

let categorySummaryPriceDate = categoryPrice.map(data => data.Date);
let categorySummaryPricePositiveCumPercent = categoryPrice.map(data => data['Positive Cumulative Percent']);
let categorySummaryPriceNegativeCumPercent = categoryPrice.map(data => data['Negative Cumulative Percent']);
let categorySummaryPriceTotalCumPercent = categoryPrice.map(data => data['Cumulative Total']);

let categorySummaryPriceDataSet = {
  type: 'line',
  labels: categorySummaryPriceDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryPricePositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryPriceNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryPriceTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



const categorySummaryPriceOptions = {
  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalCategorySummaryPriceTrend(true); // Show Pop-up
      setCategorySummaryPriceTrendDrillDownData([]);
      setCategorySummaryPriceTrendPositiveDrillDownData([]);
      setCategorySummaryPriceTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownCategory="}${"price"}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setCategorySummaryPriceTrendDrillDownData(response.data.individualSentenceDrillDown);
        setCategorySummaryPriceTrendPositiveDrillDownData(response.data.topicPosList);
        setCategorySummaryPriceTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Category = Price / "}${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Summary - Price Table using react-data-table-component

const categorySummaryPriceTableDataSet = 
categoryPrice.map((CategoryPrice) => {
    return ({
      id: categoryPicture.id,
      date: CategoryPrice.Date,
      positiveCumulative: CategoryPrice['Positive Cumulative'],
      negativeCumulative: CategoryPrice['Negative Cumulative'],
      neutralCumulative: CategoryPrice['Neutral Cumulative'],
      cumulativeTotal: CategoryPrice['Cumulative Total']
    })
  });
  
const categorySummaryPriceTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulativer',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Category Summary - Design Trend - Combo Chart

let categorySummaryDesignDate = categoryDesign.map(data => data.Date);
let categorySummaryDesignPositiveCumPercent = categoryDesign.map(data => data['Positive Cumulative Percent']);
let categorySummaryDesignNegativeCumPercent = categoryDesign.map(data => data['Negative Cumulative Percent']);
let categorySummaryDesignTotalCumPercent = categoryDesign.map(data => data['Cumulative Total']);

let categorySummaryDesignDataSet = {
  type: 'line',
  labels: categorySummaryDesignDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryDesignPositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryDesignNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryDesignTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



const categorySummaryDesignOptions = {
  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalCategorySummaryDesignTrend(true); // Show Pop-up
      setCategorySummaryDesignTrendDrillDownData([]);
      setCategorySummaryDesignTrendPositiveDrillDownData([]);
      setCategorySummaryDesignTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownCategory="}${"design"}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setCategorySummaryDesignTrendDrillDownData(response.data.individualSentenceDrillDown);
        setCategorySummaryDesignTrendPositiveDrillDownData(response.data.topicPosList);
        setCategorySummaryDesignTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Category = Design / "}${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Summary - Design Table using react-data-table-component

const categorySummaryDesignTableDataSet = 
categoryDesign.map((CategoryDesign) => {
    return ({
      id: categoryPicture.id,
      date: CategoryDesign.Date,
      positiveCumulative: CategoryDesign['Positive Cumulative'],
      negativeCumulative: CategoryDesign['Negative Cumulative'],
      neutralCumulative: CategoryDesign['Neutral Cumulative'],
      cumulativeTotal: CategoryDesign['Cumulative Total']
    })
  });
  
const categorySummaryDesignTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulativer',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];


// Category Summary - Feature Trend - Combo Chart

let categorySummaryFeatureDate = categoryFeature.map(data => data.Date);
let categorySummaryFeaturePositiveCumPercent = categoryFeature.map(data => data['Positive Cumulative Percent']);
let categorySummaryFeatureNegativeCumPercent = categoryFeature.map(data => data['Negative Cumulative Percent']);
let categorySummaryFeatureTotalCumPercent = categoryFeature.map(data => data['Cumulative Total']);

let categorySummaryFeatureDataSet = {
  type: 'line',
  labels: categorySummaryFeatureDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryFeaturePositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryFeatureNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryFeatureTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



const categorySummaryFeatureOptions = {
  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalCategorySummaryFeatureTrend(true); // Show Pop-up
      setCategorySummaryFeatureTrendDrillDownData([]);
      setCategorySummaryFeatureTrendPositiveDrillDownData([]);
      setCategorySummaryFeatureTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownCategory="}${"feature"}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setCategorySummaryFeatureTrendDrillDownData(response.data.individualSentenceDrillDown);
        setCategorySummaryFeatureTrendPositiveDrillDownData(response.data.topicPosList);
        setCategorySummaryFeatureTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Category = Feature / "}${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Summary - Feature Table using react-data-table-component

const categorySummaryFeatureTableDataSet = 
categoryFeature.map((CategoryFeature) => {
    return ({
      id: categoryPicture.id,
      date: CategoryFeature.Date,
      positiveCumulative: CategoryFeature['Positive Cumulative'],
      negativeCumulative: CategoryFeature['Negative Cumulative'],
      neutralCumulative: CategoryFeature['Neutral Cumulative'],
      cumulativeTotal: CategoryFeature['Cumulative Total']
    })
  });
  
const categorySummaryFeatureTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulativer',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Category Summary - Sound Trend - Combo Chart

let categorySummarySoundDate = categorySound.map(data => data.Date);
let categorySummarySoundPositiveCumPercent = categorySound.map(data => data['Positive Cumulative Percent']);
let categorySummarySoundNegativeCumPercent = categorySound.map(data => data['Negative Cumulative Percent']);
let categorySummarySoundTotalCumPercent = categorySound.map(data => data['Cumulative Total']);

let categorySummarySoundDataSet = {
  type: 'line',
  labels: categorySummarySoundDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummarySoundPositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummarySoundNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummarySoundTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



const categorySummarySoundOptions = {
  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalCategorySummarySoundTrend(true); // Show Pop-up
      setCategorySummarySoundTrendDrillDownData([]);
      setCategorySummarySoundTrendPositiveDrillDownData([]);
      setCategorySummarySoundTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownCategory="}${"sound"}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setCategorySummarySoundTrendDrillDownData(response.data.individualSentenceDrillDown);
        setCategorySummarySoundTrendPositiveDrillDownData(response.data.topicPosList);
        setCategorySummarySoundTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Category = Sound / "}${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Summary - Sound Table using react-data-table-component

const categorySummarySoundTableDataSet = 
categorySound.map((CategorySound) => {
    return ({
      id: categoryPicture.id,
      date: CategorySound.Date,
      positiveCumulative: CategorySound['Positive Cumulative'],
      negativeCumulative: CategorySound['Negative Cumulative'],
      neutralCumulative: CategorySound['Neutral Cumulative'],
      cumulativeTotal: CategorySound['Cumulative Total']
    })
  });
  
const categorySummarySoundTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulativer',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];




// Topic Overview - Positive Wordcloud

const topicOverviewPositiveTopicWords = topicOverviewPositiveTopic.map((TopicOverviewPositiveTopic) => {
  return({
    text: TopicOverviewPositiveTopic['WORD'],
    value: TopicOverviewPositiveTopic['FREQ']
  })
})

const topicOverviewPositiveTopicCallbacks = {
  onWordClick: (word) => {
    //console.log(word.text);

    // reset the data
    setOpenModalTopicOverviewTopicComparisonSentiment(true); // Show Pop-up
    setCategoryOverviewTopicComparisonSentimentDrillDownData([]);
    setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData([]);
    setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData([]);
    setModalTitle('');
    setDrillDownIsLoading(true); 

    let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"Positive"}${"&drillDownTopic="}${word.text}`;
    drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
    drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
    const axios = require('axios');
    axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
      // handle success

      setCategoryOverviewTopicComparisonSentimentDrillDownData(response.data.individualSentenceDrillDown);
      setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData(response.data.topicDescriptionPos);
      setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData(response.data.topicDescriptionNeg);
      setModalTitle(`${"Topic = "}${word.text}${" / Sentiment = Positive"}`);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      
      setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
    });




  }, 



  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,

  getWordColor: (word) => {
    if(word.text === buzzWordsPositive[0] || word.text === buzzWordsPositive[1] || word.text === buzzWordsPositive[2] || word.text === buzzWordsPositive[3] || word.text === buzzWordsPositive[4] || word.text === buzzWordsPositive[5] || word.text === buzzWordsPositive[6] || word.text === buzzWordsPositive[7] || word.text === buzzWordsPositive[8] || word.text === buzzWordsPositive[9] || word.text === buzzWordsPositive[10] || word.text === buzzWordsPositive[11] || word.text === buzzWordsPositive[12] || word.text === buzzWordsPositive[13] || word.text === buzzWordsPositive[14] || word.text === buzzWordsPositive[15] || word.text === buzzWordsPositive[16] || word.text === buzzWordsPositive[17] || word.text === buzzWordsPositive[18] || word.text === buzzWordsPositive[19] || word.text === buzzWordsPositive[20] || word.text === buzzWordsPositive[21] || word.text === buzzWordsPositive[22] || word.text === buzzWordsPositive[23] || word.text === buzzWordsPositive[24] || word.text === buzzWordsPositive[25] || word.text === buzzWordsPositive[26] || word.text === buzzWordsPositive[27] || word.text === buzzWordsPositive[28] || word.text === buzzWordsPositive[29] || word.text === buzzWordsPositive[30] || word.text === buzzWordsPositive[31] || word.text === buzzWordsPositive[32] || word.text === buzzWordsPositive[33] || word.text === buzzWordsPositive[34] || word.text === buzzWordsPositive[35] || word.text === buzzWordsPositive[36] || word.text === buzzWordsPositive[37] || word.text === buzzWordsPositive[38] || word.text === buzzWordsPositive[39] || word.text === buzzWordsPositive[40] || word.text === buzzWordsPositive[41] || word.text === buzzWordsPositive[42] || word.text === buzzWordsPositive[43] || word.text === buzzWordsPositive[44] || word.text === buzzWordsPositive[45] || word.text === buzzWordsPositive[46] || word.text === buzzWordsPositive[47] || word.text === buzzWordsPositive[48] || word.text === buzzWordsPositive[49]){
      return "#d49f0d"
    }else{
      return "#228B22"
    }
  }
      
    
}

const topicOverviewPositiveTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicOverViewPositiveWordCloud() {
  return <ReactWordcloud
    callbacks={topicOverviewPositiveTopicCallbacks}
    options={topicOverviewPositiveTopicOptions}
    words={topicOverviewPositiveTopicWords} 
  />
}


// Topic Overview - Positive Table using react-data-table-component

const topicOverviewPositiveTopicTableDataSet = 
topicOverviewPositiveTopic.map((TopicOverviewPositiveTopic) => {
    return ({
      id: TopicOverviewPositiveTopic.id,
      topic: TopicOverviewPositiveTopic['WORD'],
      freq: TopicOverviewPositiveTopic['FREQ'],
      currentMonth: TopicOverviewPositiveTopic['Current Month'],
      previousMonth: TopicOverviewPositiveTopic['Previous Month'],
      percentChange: TopicOverviewPositiveTopic['Percent Change']
    })
  });
  
const topicOverviewPositiveTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  },
  {
    name: 'Current Month',
    selector: 'currentMonth',
    sortable: true,
    center: true
  },
  {
    name: 'Previous Month',
    selector: 'previousMonth',
    sortable: true,
    center: true
  },
  {
    name: 'Percent Change',
    selector: 'percentChange',
    sortable: true,
    center: true
  }
];


// Topic Overview - Negative Wordcloud

const topicOverviewNegativeTopicWords = topicOverviewNegativeTopic.map((TopicOverviewNegativeTopic) => {
  return({
    text: TopicOverviewNegativeTopic['WORD'],
    value: TopicOverviewNegativeTopic['FREQ']
  })
})

const topicOverviewNegativeTopicCallbacks = {
  onWordClick: (word) => {
    //console.log(word.text);

    // reset the data
    setOpenModalTopicOverviewTopicComparisonSentimentNeg(true); // Show Pop-up
    setCategoryOverviewTopicComparisonSentimentNegDrillDownData([]);
    setCategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData([]);
    setCategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData([]);
    setModalTitle('');
    setDrillDownIsLoading(true); 

    let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"Negative"}${"&drillDownTopic="}${word.text}`;
    drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
    drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
    const axios = require('axios');
    axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
      // handle success

      setCategoryOverviewTopicComparisonSentimentNegDrillDownData(response.data.individualSentenceDrillDown);
      setCategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData(response.data.topicDescriptionPos);
      setCategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData(response.data.topicDescriptionNeg);
      setModalTitle(`${"Topic = "}${word.text}${" / Sentiment = Positive"}`);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      
      setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
    });




  }, 

  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,


  getWordColor: (word) => {
    if(word.text === buzzWordsNegative[0] || word.text === buzzWordsNegative[1] || word.text === buzzWordsNegative[2] || word.text === buzzWordsNegative[3] || word.text === buzzWordsNegative[4] || word.text === buzzWordsNegative[5] || word.text === buzzWordsNegative[6] || word.text === buzzWordsNegative[7] || word.text === buzzWordsNegative[8] || word.text === buzzWordsNegative[9] || word.text === buzzWordsNegative[10] || word.text === buzzWordsNegative[11] || word.text === buzzWordsNegative[12] || word.text === buzzWordsNegative[13] || word.text === buzzWordsNegative[14] || word.text === buzzWordsNegative[15] || word.text === buzzWordsNegative[16] || word.text === buzzWordsNegative[17] || word.text === buzzWordsNegative[18] || word.text === buzzWordsNegative[19] || word.text === buzzWordsNegative[20] || word.text === buzzWordsNegative[21] || word.text === buzzWordsNegative[22] || word.text === buzzWordsNegative[23] || word.text === buzzWordsNegative[24] || word.text === buzzWordsNegative[25] || word.text === buzzWordsNegative[26] || word.text === buzzWordsNegative[27] || word.text === buzzWordsNegative[28] || word.text === buzzWordsNegative[29] || word.text === buzzWordsNegative[30] || word.text === buzzWordsNegative[31] || word.text === buzzWordsNegative[32] || word.text === buzzWordsNegative[33] || word.text === buzzWordsNegative[34] || word.text === buzzWordsNegative[35] || word.text === buzzWordsNegative[36] || word.text === buzzWordsNegative[37] || word.text === buzzWordsNegative[38] || word.text === buzzWordsNegative[39] || word.text === buzzWordsNegative[40] || word.text === buzzWordsNegative[41] || word.text === buzzWordsNegative[42] || word.text === buzzWordsNegative[43] || word.text === buzzWordsNegative[44] || word.text === buzzWordsNegative[45] || word.text === buzzWordsNegative[46] || word.text === buzzWordsNegative[47] || word.text === buzzWordsNegative[48] || word.text === buzzWordsNegative[49]){
      return "#d49f0d"
    }else{
      return "#8B0000"
    }
  }

}

const topicOverviewNegativeTopicOptions = {
  //colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicOverViewNegativeWordCloud() {
  return <ReactWordcloud
    callbacks={topicOverviewNegativeTopicCallbacks}
    options={topicOverviewNegativeTopicOptions}
    words={topicOverviewNegativeTopicWords} 
  />
}


// Topic Overview - Negative Table using react-data-table-component

const topicOverviewNegativeTopicTableDataSet = 
topicOverviewNegativeTopic.map((TopicOverviewNegativeTopic) => {
    return ({
      id: TopicOverviewNegativeTopic.id,
      topic: TopicOverviewNegativeTopic['WORD'],
      freq: TopicOverviewNegativeTopic['FREQ'],
      currentMonth: TopicOverviewNegativeTopic['Current Month'],
      previousMonth: TopicOverviewNegativeTopic['Previous Month'],
      percentChange: TopicOverviewNegativeTopic['Percent Change'],
    })
  });
  
const topicOverviewNegativeTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  },
  {
    name: 'Current Month',
    selector: 'currentMonth',
    sortable: true,
    center: true
  },
  {
    name: 'Previous Month',
    selector: 'previousMonth',
    sortable: true,
    center: true
  },
  {
    name: 'Percent Change',
    selector: 'percentChange',
    sortable: true,
    center: true
  }
];



// Topic Overview - Topic Comparison List - Stack Bar Chart

let topicOverviewTopicComparisonTopic = topicComparisonList.map(data => data['Topic']);
let topicOverviewTopicComparisonPositive = topicComparisonList.map(data => data['Positive']);
let topicOverviewTopicComparisonNegative = topicComparisonList.map(data => data['Negative']);

let topicOverviewTopicComparisonDataSet = {
  type: 'bar',
  labels: topicOverviewTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: topicOverviewTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicOverviewTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicOverviewTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicOverviewTopicComparisonSentiment(true); // Show Pop-up
      setCategoryOverviewTopicComparisonSentimentDrillDownData([]);
      setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData([]);
      setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${"all"}${"&drillDownTopic="}${xLabel}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummaryTopicSentimentDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setCategoryOverviewTopicComparisonSentimentDrillDownData(response.data.individualSentenceDrillDown);
        setCategoryOverviewTopicComparisonSentimentPositiveDrillDownData(response.data.topicDescriptionPos);
        setCategoryOverviewTopicComparisonSentimentNegativeDrillDownData(response.data.topicDescriptionNeg);
        setModalTitle(`${"Topic = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Overview - Topic Comparison List Table using react-data-table-component

const topicOverviewTopicComparisonTableDataSet = topicComparisonListAll.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
const topicOverviewTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



function topicOverviewTopicComparisonReactTable() {
  return <DataTable
  title=""
  columns={topicOverviewTopicComparisonTableColumn}
  data={topicOverviewTopicComparisonTableDataSet}
  //conditionalRowStyles={conditionalRowStyles}
  // pagination={true}
  // paginationPerPage={10}
  // paginationComponentOptions={{ noRowsPerPage: true }}
  fixedHeader={true}
  //fixedHeaderScrollHeight={"445px"}
  fixedHeaderScrollHeight={"445px"}
  noHeader={true}
  />
}



// Topic Overview - Topic Sentiment Comparison List - Pie Chart

//let topicOverviewTopicSentimentComparisonCount = topicSentimentComparisonList.map(data => data['Count']);
let topicOverviewTopicSentimentComparisonCount = topicSentimentComparisonList.map(data => data['Count']);
let topicOverviewTopicSentimentComparisonColor = topicSentimentComparisonList.map(data => data['Color']);
let topicOverviewTopicSentimentComparisonLabel = topicSentimentComparisonList.map(data => data['Sentiment']);  

//let topicOverviewTopicSentimentComparisonLabelCustom = topicOverviewTopicSentimentComparisonLabel.map((data, i) => data + " " + topicOverviewTopicSentimentComparisonCount[i] + "%");

let topicOverviewTopicSentimentComparisonDataSet = {
  type: 'doughnut',
  labels: topicOverviewTopicSentimentComparisonLabel,
  datasets: [
    {
      data: topicOverviewTopicSentimentComparisonCount,
      backgroundColor: topicOverviewTopicSentimentComparisonColor
    }
  ],
  text: ''

}


let topicOverviewTopicSentimentComparisonOption = {

  plugins: {

    datalabels: {
      display: true,
      color: "white",
      font:{
        size: 18,
        weight: "bold"
      },
      //formatter: (val) =>
      //`${val}`, // To add % : ${val}%`
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        let percentage;
        if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
          let sum = datasets[0].data.reduce((a, b) => a + b, 0);
          percentage = Math.round((value / sum) * 100) + '%';
          return percentage;
        } else {
          return percentage;
        }
      }
    }
  },

  // Drill Down
  onClick: (event, elements) => {


    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicOverviewTopicSentiment(true); // Show Pop-up
      setCategoryOverviewTopicSentimentDrillDownData([]);
      setCategoryOverviewTopicSentimentPositiveDrillDownData([]);
      setCategoryOverviewTopicSentimentNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentiment="}${xLabel}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].categorySummarySentimentPieDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success
        
        setCategoryOverviewTopicSentimentDrillDownData(response.data.individualSentenceDrillDown);
        setCategoryOverviewTopicSentimentPositiveDrillDownData(response.data.topicPosList);
        setCategoryOverviewTopicSentimentNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(xLabel);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  }

}




const topicOverviewTopicSentimentComparisonTableDataSet = topicSentimentComparisonList.map((TopicSentimentComparisonList) => {
  return ({
    id: TopicSentimentComparisonList.id,
    sentiment: TopicSentimentComparisonList['Sentiment'],
    count: TopicSentimentComparisonList['Count']
  })
});

const topicOverviewTopicSentimentComparisonTableColumn = [
{
  name: 'Sentiment',
  selector: 'sentiment',
  sortable: true,
  center: true
},
{
  name: 'Count',
  selector: 'count',
  sortable: true,
  center: true
}
];


// Topic Insight - Topic Phrase - Stack Bar Chart

let topicInsightTopicPhraseLabel = topicInsightTopicPhraseData.map(data => data['Phrase']);
let topicInsightTopicPhrasePositive = topicInsightTopicPhraseData.map(data => data['Positive']);
let topicInsightTopicPhraseNeutral = topicInsightTopicPhraseData.map(data => data['Neutral']);
let topicInsightTopicPhraseNegative = topicInsightTopicPhraseData.map(data => data['Negative']);

let topicInsightPhraseDataSet = {
  type: 'bar',
  labels: topicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: topicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicInsightTopicPhrase(true); // Show Pop-up
      setTopicInsightTopicPhraseDrillDownData([]);
      setTopicInsightTopicPhrasedPositiveDrillDownData([]);
      setTopicInsightTopicPhraseNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownTopicPhrase="}${xLabel}${"&drillDownTopic="}${topicInsightSelection}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsightTopicPhraseDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicInsightTopicPhraseDrillDownData(response.data.individualSentenceDrillDown);
        setTopicInsightTopicPhrasedPositiveDrillDownData(response.data.topicDescriptionPos);
        setTopicInsightTopicPhraseNegativeDrillDownData(response.data.topicDescriptionNeg);
        setModalTitle(`${"Topic Phrase = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Insight Phrase Table using react-data-table-component

const topicInsightPhraseTableDataSet = topicInsightTopicPhraseData.map((TopicInsightTopicPhraseData) => {
    return ({
      id: TopicInsightTopicPhraseData.id,
      topic: TopicInsightTopicPhraseData['Phrase'],
      positive: TopicInsightTopicPhraseData['Positive'],
      negative: TopicInsightTopicPhraseData['Negative'],
      neutral: TopicInsightTopicPhraseData['Neutral']
    })
  });
  
const topicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Topic Insight - Topic Sentiment Positive - Bar Chart

let topicInsightPositiveLabel = topicInsightPositiveSentimentData.map(data => data['WORD']);
let topicInsightPositiveFreq = topicInsightPositiveSentimentData.map(data => data['FREQ']);

let topicInsightPositiveDataSet = {
  type: 'bar',
  labels: topicInsightPositiveLabel,
  datasets: [
    {
      label: "Positive Words",
      data: topicInsightPositiveFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let topicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicInsightTopicSentimentWord(true); // Show Pop-up
      setTopicInsightSentimentWordDrillDownData([]);
      //setCategorySummarySoftwareTrendPositiveDrillDownData([]);
      //setCategorySummarySoftwareTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentimentType="}${dataset.label}${"&drillDownSentimentWord="}${xLabel}${"&drillDownTopic="}${topicInsightSelection}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsightTopicSentimentWordDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicInsightSentimentWordDrillDownData(response.data.individualSentenceDrillDown);
        //setCategorySummarySoftwareTrendPositiveDrillDownData(response.data.topicPosList);
        //setCategorySummarySoftwareTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Sentiment Word = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Sentiment Positive Table using react-data-table-component

const topicInsightPositiveTableDataSet = topicInsightPositiveSentimentData.map((TopicInsightPositiveSentimentData) => {
    return ({
      id: TopicInsightPositiveSentimentData.id,
      word: TopicInsightPositiveSentimentData['WORD'],
      freq: TopicInsightPositiveSentimentData['FREQ']
    })
  });
  
const topicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Insight - Topic Negative Positive - Bar Chart

let topicInsightNegativeLabel = topicInsightNegativeSentimentData.map(data => data['WORD']);
let topicInsightNegativeFreq = topicInsightNegativeSentimentData.map(data => data['FREQ']);

let topicInsightNegativeDataSet = {
  type: 'bar',
  labels: topicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: topicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let topicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicInsightTopicSentimentWordNeg(true); // Show Pop-up
      setTopicInsightSentimentWordDrillDownDataNeg([]);
      //setCategorySummarySoftwareTrendPositiveDrillDownData([]);
      //setCategorySummarySoftwareTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentimentType="}${dataset.label}${"&drillDownSentimentWord="}${xLabel}${"&drillDownTopic="}${topicInsightSelection}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsightTopicSentimentWordDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicInsightSentimentWordDrillDownDataNeg(response.data.individualSentenceDrillDown);
        //setCategorySummarySoftwareTrendPositiveDrillDownData(response.data.topicPosList);
        //setCategorySummarySoftwareTrendNegativeDrillDownData(response.data.topicNegList);
        setModalTitle(`${"Sentiment Word = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Sentiment Negative Table using react-data-table-component

const topicInsightNegativeTableDataSet = topicInsightNegativeSentimentData.map((TopicInsightNegativeSentimentData) => {
    return ({
      id: TopicInsightNegativeSentimentData.id,
      word: TopicInsightNegativeSentimentData['WORD'],
      freq: TopicInsightNegativeSentimentData['FREQ']
    })
  });
  
const topicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Insight - Topic Trend - Line Chart

let topicInsightTopicTrendLabel = topicInsightTopicTrendData.map(data => data.Date);

let topicInsightTopicTrendDataSet = {
  type: 'line',
  labels: topicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: topicInsightTopicTrendData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: topicInsightTopicTrendData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: topicInsightTopicTrendData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let topicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicInsightTrend(true); // Show Pop-up
      setTopicInsightTrendDrillDownData([]);
      setTopicInsightTrendPositiveDrillDownData([]);
      setTopicInsightTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownTopic="}${topicInsightSelection}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicInsightTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicInsightTrendDrillDownData(response.data.individualSentenceDrillDown);
        setTopicInsightTrendPositiveDrillDownData(response.data.topicDescriptionPos);
        setTopicInsightTrendNegativeDrillDownData(response.data.topicDescriptionNeg);
        setModalTitle(`${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Topic Insight - Topic Trend - Table using react-data-table-component

const topicInsightTopicTrendTableDataSet = topicInsightTopicTrendData.map((TopicInsightTopicTrendData) => {
    return ({
      id: TopicInsightTopicTrendData.id,
      date: TopicInsightTopicTrendData.Date,
      topic: TopicInsightTopicTrendData.Topic,
      positiveTrend: TopicInsightTopicTrendData['Positive'],
      neutralTrend: TopicInsightTopicTrendData['Neutral'],
      negativeTrend: TopicInsightTopicTrendData['Negative'],
      total: TopicInsightTopicTrendData['Total'],
      posCumulative: TopicInsightTopicTrendData['Positive Cumulative'],
      negCumulative: TopicInsightTopicTrendData['Negative Cumulative'],
      totalCumulative: TopicInsightTopicTrendData['Cumulative Total'],
      posCumulativePercent: TopicInsightTopicTrendData['Positive Cumulative Percent'],
      negCumulativePercent: TopicInsightTopicTrendData['Negative Cumulative Percent']
    })
  });
  
const topicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];


// Topic Search - Topic Trend - Line Chart

let topicSearchTrendLabel = topicSearchTrendData.map(data => data.Date);

let topicSearchTrendDataSet = {
  type: 'line',
  labels: topicSearchTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: topicSearchTrendData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: topicSearchTrendData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: topicSearchTrendData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let topicSearchTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicSearchTrend(true); // Show Pop-up
      setTopicSearchTrendDrillDownData([]);
      setTopicSearchTrendPositiveDrillDownData([]);
      setTopicSearchTrendNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownDate="}${xLabel}${"&drillDownTopic="}${topicSearchItems}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicSearchTrendDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicSearchTrendDrillDownData(response.data.individualSentenceDrillDown);
        setTopicSearchTrendPositiveDrillDownData(response.data.topicDescriptionPos);
        setTopicSearchTrendNegativeDrillDownData(response.data.topicDescriptionNeg);
        setModalTitle(`${"Date = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Topic Search - Topic Trend - Table using react-data-table-component

const topicSearchTrendTableDataSet = topicSearchTrendData.map((TopicSearchTrendData) => {
    return ({
      id: TopicSearchTrendData.id,
      date: TopicSearchTrendData.Date,
      topic: TopicSearchTrendData.Topic,
      positiveTrend: TopicSearchTrendData['Positive'],
      neutralTrend: TopicSearchTrendData['Neutral'],
      negativeTrend: TopicSearchTrendData['Negative'],
      total: TopicSearchTrendData['Total'],
      posCumulative: TopicSearchTrendData['Positive Cumulative'],
      negCumulative: TopicSearchTrendData['Negative Cumulative'],
      totalCumulative: TopicSearchTrendData['Cumulative Total'],
      posCumulativePercent: TopicSearchTrendData['Positive Cumulative Percent'],
      negCumulativePercent: TopicSearchTrendData['Negative Cumulative Percent']
    })
  });
  
const TopicSearchTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];


// Topic Search - Pie Chart
let topicSearchBreakdownDataSet = {
  type: 'doughnut',
  labels: topicSearchTopicBreakdown.map(data => data['Topic']),
  datasets: [
    {
      data: topicSearchTopicBreakdown.map(data => data['Count']),
      backgroundColor: topicSearchTopicBreakdown.map(data => data['color']),
      //options: options
    }],
    text: ''
  } 

  let topicSearchBreakdownOptions = {

    plugins: {

      datalabels: {
        display: true,
        color: "white",
        font:{
          size: 18,
          weight: "bold"
        },
        //formatter: (val) =>
        //`${val}`,
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          let percentage;
          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            percentage = Math.round((value / sum) * 100) + '%';
            return percentage;
          } else {
            return percentage;
          }
        }
      }
    },
  

    // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicSearchTopicBreakdown(true); // Show Pop-up
      setTopicSearchTopicBreakdowndDrillDownData([]);
      setTopicSearchTopicBreakdownPositiveDrillDownData([]);
      setTopicSearchTopicBreakdownNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillTopicSearch="}${topicSearchItems}${"&drillDownTopic="}${xLabel}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicSearchTopicBreakdownDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicSearchTopicBreakdowndDrillDownData(response.data.individualSentenceDrillDown);
        setTopicSearchTopicBreakdownPositiveDrillDownData(response.data.topicDescriptionPos);
        setTopicSearchTopicBreakdownNegativeDrillDownData(response.data.topicDescriptionNeg);
        setModalTitle(`${"Topic = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },

  }


// Topic Search - Pie - Table using react-data-table-component 

const topicSearchBreakdownTableDataSet = topicSearchTopicBreakdown.map((TopicSearchTopicBreakdown) => {
  return ({
    id: TopicSearchTopicBreakdown.id,
    topic: TopicSearchTopicBreakdown.Topic,
    count: TopicSearchTopicBreakdown['Count']
  })
});
  

const topicSearchBreakdownTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  }
];


// Topic Search - Topic Sentiment - Stack Bar Chart

let topicSearchTopicSentimentDataSet = {
  type: 'bar',
  labels: topicSearchTopicSentimentData.map(data => data['Topic']),
  datasets: [
    {
      label: 'Positive',
      data: topicSearchTopicSentimentData.map(data => data['Positive']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicSearchTopicSentimentData.map(data => data['Neutral']),
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicSearchTopicSentimentData.map(data => data['Negative']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicSearchTopicSentimentOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicSearchTopicCombine(true); // Show Pop-up
      setTopicSearchTopicCombineDrillDownData([]);
      setTopicSearchTopicCombinePositiveDrillDownData([]);
      setTopicSearchTopicCombineNegativeDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillTopicSearch="}${topicSearchItems}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicSearchTopicCombineDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicSearchTopicCombineDrillDownData(response.data.individualSentenceDrillDown);
        setTopicSearchTopicCombinePositiveDrillDownData(response.data.topicDescriptionPos);
        setTopicSearchTopicCombineNegativeDrillDownData(response.data.topicDescriptionNeg);
        setModalTitle(`${"Combine Topics = "}${topicSearchItems}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Overview - Topic Comparison List Table using react-data-table-component

const topicSearchTopicSentimentTableDataSet = topicSearchTopicSentimentData.map((TopicSearchTopicSentimentData) => {
    return ({
      id: TopicSearchTopicSentimentData.id,
      topic: TopicSearchTopicSentimentData['Topic'],
      positive: TopicSearchTopicSentimentData['Positive'],
      negative: TopicSearchTopicSentimentData['Negative'],
      neutral: TopicSearchTopicSentimentData['Neutral']
    })
  });
  
const topicSearchTopicSentimentTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Topic Search - Topic Sentiment Positive - Bar Chart

let topicSearchPositiveSentimentDataSet = {
  type: 'bar',
  labels: topicSearchPositiveSentimentData.map(data => data['WORD']),
  datasets: [
    {
      label: "Positive Words",
      data: topicSearchPositiveSentimentData.map(data => data['FREQ']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let topicSearchPositiveSentimentOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicSearchSentimentPositive(true); // Show Pop-up
      setTopicSearchTopicPositiveSentimentDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentimentType="}${"Positive"}${"&drillDownSentimentWord="}${xLabel}${"&drillTopicSearch="}${topicSearchItems}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicSearchTopicSentimentWordDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicSearchTopicPositiveSentimentDrillDownData(response.data.individualSentenceDrillDown);
        setModalTitle(`${"Sentiment Word = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Sentiment Positive Table using react-data-table-component

const topicSearchPositiveSentimentTableDataSet = topicSearchPositiveSentimentData.map((TopicSearchPositiveSentimentData) => {
    return ({
      id: TopicSearchPositiveSentimentData.id,
      word: TopicSearchPositiveSentimentData['WORD'],
      freq: TopicSearchPositiveSentimentData['FREQ']
    })
  });
  
const topicSearchPositiveSentimentTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Insight - Topic Negative Positive - Bar Chart

let topicSearchNegativeSentimentDataDataSet = {
  type: 'bar',
  labels: topicSearchNegativeSentimentData.map(data => data['WORD']),
  datasets: [
    {
      label: "Negative Words",
      data: topicSearchNegativeSentimentData.map(data => data['FREQ']) ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let topicSearchNegativeSentimentOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      // reset the data
      setOpenModalTopicSearchSentimentNegative(true); // Show Pop-up
      setTopicSearchTopicNegativeSentimentDrillDownData([]);
      setModalTitle('');
      setDrillDownIsLoading(true); 

      let drillDownUrl = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&drillDownSentimentType="}${"Negative"}${"&drillDownSentimentWord="}${xLabel}${"&drillTopicSearch="}${topicSearchItems}`;
      drillDownUrl = drillDownUrl.replaceAll(",", '%2C'); // replace comma for url
      drillDownUrl = drillDownUrl.replaceAll(" ", '%20'); // empty space for url
      const axios = require('axios');
      axios.get(`${SMATrestAPI.starRatingAnalytic[0].topicSearchTopicSentimentWordDrillDown}${drillDownUrl}`).then(function (response) { 
        // handle success

        setTopicSearchTopicNegativeSentimentDrillDownData(response.data.individualSentenceDrillDown);
        setModalTitle(`${"Sentiment Word = "}${xLabel}`);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        
        setTimeout(() => {setDrillDownIsLoading(false)}, 1000);
      });

    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Sentiment Negative Table using react-data-table-component

const topicSearchNegativeSentimentTableDataSet = topicSearchNegativeSentimentData.map((TopicSearchNegativeSentimentData) => {
    return ({
      id: TopicSearchNegativeSentimentData.id,
      word: TopicSearchNegativeSentimentData['WORD'],
      freq: TopicSearchNegativeSentimentData['FREQ']
    })
  });
  
const topicSearchNegativeSentimentTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Sentiment Overview - Positive Wordcloud

const sentimentOverviewPositveWordsData = sentimentOverviewPositveData.map((SentimentOverviewPositveData) => {
  return({
    text: SentimentOverviewPositveData['WORD'],
    value: SentimentOverviewPositveData['FREQ']
  })
})

const sentimentOverviewPositveCallbacks = {
  onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const sentimentOverviewPositveOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function sentimentOverviewPositveWordCloud() {
  return <ReactWordcloud
    callbacks={sentimentOverviewPositveCallbacks}
    options={sentimentOverviewPositveOptions}
    words={sentimentOverviewPositveWordsData} 
  />
}


// Sentiment Overview - Positive Table using react-data-table-component

const sentimentOverviewPositveTableDataSet = sentimentOverviewPositveData.map((SentimentOverviewPositveData) => {
    return ({
      id: SentimentOverviewPositveData.id,
      topic: SentimentOverviewPositveData['WORD'],
      freq: SentimentOverviewPositveData['FREQ']
    })
  });
  
const sentimentOverviewPositveTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Sentiment Overview - Negative Wordcloud

const sentimentOverviewNegativeDataWords = sentimentOverviewNegativeData.map((SentimentOverviewNegativeData) => {
  return({
    text: SentimentOverviewNegativeData['WORD'],
    value: SentimentOverviewNegativeData['FREQ']
  })
})

const sentimentOverviewNegativeDataCallbacks = {
  onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const sentimentOverviewNegativeDataOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function sentimentOverviewNegativeWordCloud() {
  return <ReactWordcloud
    callbacks={sentimentOverviewNegativeDataCallbacks}
    options={sentimentOverviewNegativeDataOptions}
    words={sentimentOverviewNegativeDataWords} 
  />
}


// Sentiment Overview - Negative Table using react-data-table-component

const sentimentOverviewNegativeDataTableDataSet = sentimentOverviewNegativeData.map((SentimentOverviewNegativeData) => {
    return ({
      id: SentimentOverviewNegativeData.id,
      topic: SentimentOverviewNegativeData['WORD'],
      freq: SentimentOverviewNegativeData['FREQ']
    })
  });
  
const sentimentOverviewNegativeDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Value Box

const categoryDetailPositiveCount = categoryDetailValueBoxData.map(CategoryDetailValueBoxData => CategoryDetailValueBoxData['Positive Count']) // order : software, picture, price, design, feature, sound
const categoryDetailNeutralCount = categoryDetailValueBoxData.map(CategoryDetailValueBoxData => CategoryDetailValueBoxData['Neutral Count']) // order : software, picture, price, design, feature, sound
const categoryDetailNegativeCount = categoryDetailValueBoxData.map(CategoryDetailValueBoxData => CategoryDetailValueBoxData['Negative Count']) // order : software, picture, price, design, feature, sound



// Category Detail - Software Topic Comparison List - Stack Bar Chart

let categoryDetailSoftwareTopicComparisonTopic = softwareTopic.map(data => data['Topic']);
let categoryDetailSoftwareTopicComparisonPositive = softwareTopic.map(data => data['Positive']);
let categoryDetailSoftwareTopicComparisonNeutral = softwareTopic.map(data => data['Neutral']);
let categoryDetailSoftwareTopicComparisonNegative = softwareTopic.map(data => data['Negative']);

let categoryDetailSoftwareTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailSoftwareTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailSoftwareTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailSoftwareTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailSoftwareTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailSoftwareTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail - Software Topic Comparison List Table using react-data-table-component

const categoryDetailSoftwareTopicComparisonTableDataSet = softwareTopic.map((SoftwareTopic) => {
    return ({
      id: SoftwareTopic.id,
      topic: SoftwareTopic['Topic'],
      positive: SoftwareTopic['Positive'],
      negative: SoftwareTopic['Negative'],
      neutral: SoftwareTopic['Neutral'],
      total: SoftwareTopic['Total']
    })
  });
  
const categoryDetailSoftwareTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Category Detail - Software - Topic Insight - Topic Phrase - Stack Bar Chart

let categoryDetailSoftwaretopicInsightTopicPhraseLabel = categoryDetailTopicInsightTopicPhraseSoftwareData.map(data => data['Phrase']);
let categoryDetailSoftwaretopicInsightTopicPhrasePositive = categoryDetailTopicInsightTopicPhraseSoftwareData.map(data => data['Positive']);
let categoryDetailSoftwaretopicInsightTopicPhraseNeutral = categoryDetailTopicInsightTopicPhraseSoftwareData.map(data => data['Neutral']);
let categoryDetailSoftwaretopicInsightTopicPhraseNegative = categoryDetailTopicInsightTopicPhraseSoftwareData.map(data => data['Negative']);

let categoryDetailSoftwaretopicInsightPhraseDataSet = {
  type: 'bar',
  labels: categoryDetailSoftwaretopicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailSoftwaretopicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: categoryDetailSoftwaretopicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: categoryDetailSoftwaretopicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailSoftwaretopicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


const categoryDetailSoftwaretopicInsightPhraseTableDataSet = categoryDetailTopicInsightTopicPhraseSoftwareData.map((CategoryDetailTopicInsightTopicPhraseSoftwareData) => {
    return ({
      id: CategoryDetailTopicInsightTopicPhraseSoftwareData.id,
      topic: CategoryDetailTopicInsightTopicPhraseSoftwareData['Phrase'],
      positive: CategoryDetailTopicInsightTopicPhraseSoftwareData['Positive'],
      negative: CategoryDetailTopicInsightTopicPhraseSoftwareData['Negative'],
      neutral: CategoryDetailTopicInsightTopicPhraseSoftwareData['Neutral']
    })
  });
  
const categoryDetailSoftwaretopicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Software - Topic Positive - Bar Chart

let categoryDetailTopicInsightPositiveSentimentSoftwareLabel = categoryDetailTopicInsightPositiveSentimentSoftwareData.map(data => data['WORD']);
let categoryDetailTopicInsightPositiveSentimentSoftwareFreq = categoryDetailTopicInsightPositiveSentimentSoftwareData.map(data => data['FREQ']);

let categoryDetailSoftwaretopicInsightPositiveDataSet = {
  type: 'bar',
  labels: categoryDetailTopicInsightPositiveSentimentSoftwareLabel,
  datasets: [
    {
      label: "Positive Words",
      data: categoryDetailTopicInsightPositiveSentimentSoftwareFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let categoryDetailSoftwaretopicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailSoftwaretopicInsightPositiveTableDataSet = categoryDetailTopicInsightPositiveSentimentSoftwareData.map((CategoryDetailTopicInsightPositiveSentimentSoftwareData) => {
    return ({
      id: CategoryDetailTopicInsightPositiveSentimentSoftwareData.id,
      word: CategoryDetailTopicInsightPositiveSentimentSoftwareData['WORD'],
      freq: CategoryDetailTopicInsightPositiveSentimentSoftwareData['FREQ']
    })
  });
  
const categoryDetailSoftwaretopicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Software - Topic Negative - Bar Chart

let categoryDetailSoftwaretopicInsightNegativeLabel = categoryDetailTopicInsightNegativeSentimentSoftwareData.map(data => data['WORD']);
let categoryDetailSoftwaretopicInsightNegativeFreq = categoryDetailTopicInsightNegativeSentimentSoftwareData.map(data => data['FREQ']);

let categoryDetailSoftwaretopicInsightNegativeDataSet = {
  type: 'bar',
  labels: categoryDetailSoftwaretopicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: categoryDetailSoftwaretopicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let categoryDetailSoftwaretopicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailSoftwaretopicInsightNegativeTableDataSet = categoryDetailTopicInsightNegativeSentimentSoftwareData.map((CategoryDetailTopicInsightNegativeSentimentSoftwareData) => {
    return ({
      id: CategoryDetailTopicInsightNegativeSentimentSoftwareData.id,
      word: CategoryDetailTopicInsightNegativeSentimentSoftwareData['WORD'],
      freq: CategoryDetailTopicInsightNegativeSentimentSoftwareData['FREQ']
    })
  });
  
const categoryDetailSoftwaretopicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Topic Insight Trend - Software - Line Chart

let categoryDetailSoftwaretopicInsightTopicTrendLabel = categoryDetailTopicInsightTopicTrendSoftwareData.map(data => data.Date);

let categoryDetailSoftwaretopicInsightTopicTrendDataSet = {
  type: 'line',
  labels: categoryDetailSoftwaretopicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTopicInsightTopicTrendSoftwareData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categoryDetailTopicInsightTopicTrendSoftwareData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: categoryDetailTopicInsightTopicTrendSoftwareData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let categoryDetailSoftwaretopicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Category Detail - Topic Insight - Software - Table using react-data-table-component

const categoryDetailSoftwaretopicInsightTopicTrendTableDataSet = categoryDetailTopicInsightTopicTrendSoftwareData.map((CategoryDetailTopicInsightTopicTrendSoftwareData) => {
    return ({
      id: CategoryDetailTopicInsightTopicTrendSoftwareData.id,
      date: CategoryDetailTopicInsightTopicTrendSoftwareData.Date,
      topic: CategoryDetailTopicInsightTopicTrendSoftwareData.Topic,
      positiveTrend: CategoryDetailTopicInsightTopicTrendSoftwareData['Positive'],
      neutralTrend: CategoryDetailTopicInsightTopicTrendSoftwareData['Neutral'],
      negativeTrend: CategoryDetailTopicInsightTopicTrendSoftwareData['Negative'],
      total: CategoryDetailTopicInsightTopicTrendSoftwareData['Total'],
      posCumulative: CategoryDetailTopicInsightTopicTrendSoftwareData['Positive Cumulative'],
      negCumulative: CategoryDetailTopicInsightTopicTrendSoftwareData['Negative Cumulative'],
      totalCumulative: CategoryDetailTopicInsightTopicTrendSoftwareData['Cumulative Total'],
      posCumulativePercent: CategoryDetailTopicInsightTopicTrendSoftwareData['Positive Cumulative Percent'],
      negCumulativePercent: CategoryDetailTopicInsightTopicTrendSoftwareData['Negative Cumulative Percent']
    })
  });
  
const categoryDetailSoftwaretopicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];




// Category Detail - Picture Topic Comparison List - Stack Bar Chart

let categoryDetailPictureTopicComparisonTopic = pictureTopic.map(data => data['Topic']);
let categoryDetailPictureTopicComparisonPositive = pictureTopic.map(data => data['Positive']);
let categoryDetailPictureTopicComparisonNeutral = pictureTopic.map(data => data['Neutral']);
let categoryDetailPictureTopicComparisonNegative = pictureTopic.map(data => data['Negative']);

let categoryDetailPictureTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailPictureTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailPictureTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailPictureTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailPictureTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailPictureTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail - Picture Topic Comparison List Table using react-data-table-component

const categoryDetailPictureTopicComparisonTableDataSet = pictureTopic.map((PictureTopic) => {
    return ({
      id: PictureTopic.id,
      topic: PictureTopic['Topic'],
      positive: PictureTopic['Positive'],
      negative: PictureTopic['Negative'],
      neutral: PictureTopic['Neutral'],
      total: PictureTopic['Total']
    })
  });
  
const categoryDetailPictureTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Category Detail - Picture - Topic Insight - Topic Phrase - Stack Bar Chart

let categoryDetailPicturetopicInsightTopicPhraseLabel = categoryDetailTopicInsightTopicPhrasePictureData.map(data => data['Phrase']);
let categoryDetailPicturetopicInsightTopicPhrasePositive = categoryDetailTopicInsightTopicPhrasePictureData.map(data => data['Positive']);
let categoryDetailPicturetopicInsightTopicPhraseNeutral = categoryDetailTopicInsightTopicPhrasePictureData.map(data => data['Neutral']);
let categoryDetailPicturetopicInsightTopicPhraseNegative = categoryDetailTopicInsightTopicPhrasePictureData.map(data => data['Negative']);

let categoryDetailPicturetopicInsightPhraseDataSet = {
  type: 'bar',
  labels: categoryDetailPicturetopicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailPicturetopicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: categoryDetailPicturetopicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: categoryDetailPicturetopicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailPicturetopicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


const categoryDetailPicturetopicInsightPhraseTableDataSet = categoryDetailTopicInsightTopicPhrasePictureData.map((CategoryDetailTopicInsightTopicPhrasePictureData) => {
    return ({
      id: CategoryDetailTopicInsightTopicPhrasePictureData.id,
      topic: CategoryDetailTopicInsightTopicPhrasePictureData['Phrase'],
      positive: CategoryDetailTopicInsightTopicPhrasePictureData['Positive'],
      negative: CategoryDetailTopicInsightTopicPhrasePictureData['Negative'],
      neutral: CategoryDetailTopicInsightTopicPhrasePictureData['Neutral']
    })
  });
  
const categoryDetailPicturetopicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Picture - Topic Positive - Bar Chart

let categoryDetailTopicInsightPositiveSentimentPictureLabel = categoryDetailTopicInsightPositiveSentimentPictureData.map(data => data['WORD']);
let categoryDetailTopicInsightPositiveSentimentPictureFreq = categoryDetailTopicInsightPositiveSentimentPictureData.map(data => data['FREQ']);

let categoryDetailPicturetopicInsightPositiveDataSet = {
  type: 'bar',
  labels: categoryDetailTopicInsightPositiveSentimentPictureLabel,
  datasets: [
    {
      label: "Positive Words",
      data: categoryDetailTopicInsightPositiveSentimentPictureFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let categoryDetailPicturetopicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailPicturetopicInsightPositiveTableDataSet = categoryDetailTopicInsightPositiveSentimentPictureData.map((CategoryDetailTopicInsightPositiveSentimentPictureData) => {
    return ({
      id: CategoryDetailTopicInsightPositiveSentimentPictureData.id,
      word: CategoryDetailTopicInsightPositiveSentimentPictureData['WORD'],
      freq: CategoryDetailTopicInsightPositiveSentimentPictureData['FREQ']
    })
  });
  
const categoryDetailPicturetopicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Picture - Topic Negative - Bar Chart

let categoryDetailPicturetopicInsightNegativeLabel = categoryDetailTopicInsightNegativeSentimentPictureData.map(data => data['WORD']);
let categoryDetailPicturetopicInsightNegativeFreq = categoryDetailTopicInsightNegativeSentimentPictureData.map(data => data['FREQ']);

let categoryDetailPicturetopicInsightNegativeDataSet = {
  type: 'bar',
  labels: categoryDetailPicturetopicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: categoryDetailPicturetopicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let categoryDetailPicturetopicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailPicturetopicInsightNegativeTableDataSet = categoryDetailTopicInsightNegativeSentimentPictureData.map((CategoryDetailTopicInsightNegativeSentimentPictureData) => {
    return ({
      id: CategoryDetailTopicInsightNegativeSentimentPictureData.id,
      word: CategoryDetailTopicInsightNegativeSentimentPictureData['WORD'],
      freq: CategoryDetailTopicInsightNegativeSentimentPictureData['FREQ']
    })
  });
  
const categoryDetailPicturetopicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Topic Insight Trend - Picture - Line Chart

let categoryDetailPicturetopicInsightTopicTrendLabel = categoryDetailTopicInsightTopicTrendPictureData.map(data => data.Date);

let categoryDetailPicturetopicInsightTopicTrendDataSet = {
  type: 'line',
  labels: categoryDetailPicturetopicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTopicInsightTopicTrendPictureData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categoryDetailTopicInsightTopicTrendPictureData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: categoryDetailTopicInsightTopicTrendPictureData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let categoryDetailPicturetopicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Category Detail - Topic Insight - Picture - Table using react-data-table-component

const categoryDetailPicturetopicInsightTopicTrendTableDataSet = categoryDetailTopicInsightTopicTrendPictureData.map((CategoryDetailTopicInsightTopicTrendPictureData) => {
    return ({
      id: CategoryDetailTopicInsightTopicTrendPictureData.id,
      date: CategoryDetailTopicInsightTopicTrendPictureData.Date,
      topic: CategoryDetailTopicInsightTopicTrendPictureData.Topic,
      positiveTrend: CategoryDetailTopicInsightTopicTrendPictureData['Positive'],
      neutralTrend: CategoryDetailTopicInsightTopicTrendPictureData['Neutral'],
      negativeTrend: CategoryDetailTopicInsightTopicTrendPictureData['Negative'],
      total: CategoryDetailTopicInsightTopicTrendPictureData['Total'],
      posCumulative: CategoryDetailTopicInsightTopicTrendPictureData['Positive Cumulative'],
      negCumulative: CategoryDetailTopicInsightTopicTrendPictureData['Negative Cumulative'],
      totalCumulative: CategoryDetailTopicInsightTopicTrendPictureData['Cumulative Total'],
      posCumulativePercent: CategoryDetailTopicInsightTopicTrendPictureData['Positive Cumulative Percent'],
      negCumulativePercent: CategoryDetailTopicInsightTopicTrendPictureData['Negative Cumulative Percent']
    })
  });
  
const categoryDetailPicturetopicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];




// Category Detail - Price Topic Comparison List - Stack Bar Chart

let categoryDetailPriceTopicComparisonTopic = priceTopic.map(data => data['Topic']);
let categoryDetailPriceTopicComparisonPositive = priceTopic.map(data => data['Positive']);
let categoryDetailPriceTopicComparisonNeutral = priceTopic.map(data => data['Neutral']);
let categoryDetailPriceTopicComparisonNegative = priceTopic.map(data => data['Negative']);

let categoryDetailPriceTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailPriceTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailPriceTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailPriceTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailPriceTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailPriceTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail - Price Topic Comparison List Table using react-data-table-component

const categoryDetailPriceTopicComparisonTableDataSet = priceTopic.map((PriceTopic) => {
    return ({
      id: PriceTopic.id,
      topic: PriceTopic['Topic'],
      positive: PriceTopic['Positive'],
      negative: PriceTopic['Negative'],
      neutral: PriceTopic['Neutral'],
      total: PriceTopic['Total']
    })
  });
  
const categoryDetailPriceTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Category Detail - Price - Topic Insight - Topic Phrase - Stack Bar Chart

let categoryDetailPricetopicInsightTopicPhraseLabel = categoryDetailTopicInsightTopicPhrasePriceData.map(data => data['Phrase']);
let categoryDetailPricetopicInsightTopicPhrasePositive = categoryDetailTopicInsightTopicPhrasePriceData.map(data => data['Positive']);
let categoryDetailPricetopicInsightTopicPhraseNeutral = categoryDetailTopicInsightTopicPhrasePriceData.map(data => data['Neutral']);
let categoryDetailPricetopicInsightTopicPhraseNegative = categoryDetailTopicInsightTopicPhrasePriceData.map(data => data['Negative']);

let categoryDetailPricetopicInsightPhraseDataSet = {
  type: 'bar',
  labels: categoryDetailPricetopicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailPricetopicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: categoryDetailPricetopicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: categoryDetailPricetopicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailPricetopicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


const categoryDetailPricetopicInsightPhraseTableDataSet = categoryDetailTopicInsightTopicPhrasePriceData.map((CategoryDetailTopicInsightTopicPhrasePriceData) => {
    return ({
      id: CategoryDetailTopicInsightTopicPhrasePriceData.id,
      topic: CategoryDetailTopicInsightTopicPhrasePriceData['Phrase'],
      positive: CategoryDetailTopicInsightTopicPhrasePriceData['Positive'],
      negative: CategoryDetailTopicInsightTopicPhrasePriceData['Negative'],
      neutral: CategoryDetailTopicInsightTopicPhrasePriceData['Neutral']
    })
  });
  
const categoryDetailPricetopicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Price - Topic Positive - Bar Chart

let categoryDetailTopicInsightPositiveSentimentPriceLabel = categoryDetailTopicInsightPositiveSentimentPriceData.map(data => data['WORD']);
let categoryDetailTopicInsightPositiveSentimentPriceFreq = categoryDetailTopicInsightPositiveSentimentPriceData.map(data => data['FREQ']);

let categoryDetailPricetopicInsightPositiveDataSet = {
  type: 'bar',
  labels: categoryDetailTopicInsightPositiveSentimentPriceLabel,
  datasets: [
    {
      label: "Positive Words",
      data: categoryDetailTopicInsightPositiveSentimentPriceFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let categoryDetailPricetopicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailPricetopicInsightPositiveTableDataSet = categoryDetailTopicInsightPositiveSentimentPriceData.map((CategoryDetailTopicInsightPositiveSentimentPriceData) => {
    return ({
      id: CategoryDetailTopicInsightPositiveSentimentPriceData.id,
      word: CategoryDetailTopicInsightPositiveSentimentPriceData['WORD'],
      freq: CategoryDetailTopicInsightPositiveSentimentPriceData['FREQ']
    })
  });
  
const categoryDetailPricetopicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Price - Topic Negative - Bar Chart

let categoryDetailPricetopicInsightNegativeLabel = categoryDetailTopicInsightNegativeSentimentPriceData.map(data => data['WORD']);
let categoryDetailPricetopicInsightNegativeFreq = categoryDetailTopicInsightNegativeSentimentPriceData.map(data => data['FREQ']);

let categoryDetailPricetopicInsightNegativeDataSet = {
  type: 'bar',
  labels: categoryDetailPricetopicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: categoryDetailPricetopicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let categoryDetailPricetopicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailPricetopicInsightNegativeTableDataSet = categoryDetailTopicInsightNegativeSentimentPriceData.map((CategoryDetailTopicInsightNegativeSentimentPriceData) => {
    return ({
      id: CategoryDetailTopicInsightNegativeSentimentPriceData.id,
      word: CategoryDetailTopicInsightNegativeSentimentPriceData['WORD'],
      freq: CategoryDetailTopicInsightNegativeSentimentPriceData['FREQ']
    })
  });
  
const categoryDetailPricetopicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Topic Insight Trend - Price - Line Chart

let categoryDetailPricetopicInsightTopicTrendLabel = categoryDetailTopicInsightTopicTrendPriceData.map(data => data.Date);

let categoryDetailPricetopicInsightTopicTrendDataSet = {
  type: 'line',
  labels: categoryDetailPricetopicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTopicInsightTopicTrendPriceData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categoryDetailTopicInsightTopicTrendPriceData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: categoryDetailTopicInsightTopicTrendPriceData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let categoryDetailPricetopicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Category Detail - Topic Insight - Price - Table using react-data-table-component

const categoryDetailPricetopicInsightTopicTrendTableDataSet = categoryDetailTopicInsightTopicTrendPriceData.map((CategoryDetailTopicInsightTopicTrendPriceData) => {
    return ({
      id: CategoryDetailTopicInsightTopicTrendPriceData.id,
      date: CategoryDetailTopicInsightTopicTrendPriceData.Date,
      topic: CategoryDetailTopicInsightTopicTrendPriceData.Topic,
      positiveTrend: CategoryDetailTopicInsightTopicTrendPriceData['Positive'],
      neutralTrend: CategoryDetailTopicInsightTopicTrendPriceData['Neutral'],
      negativeTrend: CategoryDetailTopicInsightTopicTrendPriceData['Negative'],
      total: CategoryDetailTopicInsightTopicTrendPriceData['Total'],
      posCumulative: CategoryDetailTopicInsightTopicTrendPriceData['Positive Cumulative'],
      negCumulative: CategoryDetailTopicInsightTopicTrendPriceData['Negative Cumulative'],
      totalCumulative: CategoryDetailTopicInsightTopicTrendPriceData['Cumulative Total'],
      posCumulativePercent: CategoryDetailTopicInsightTopicTrendPriceData['Positive Cumulative Percent'],
      negCumulativePercent: CategoryDetailTopicInsightTopicTrendPriceData['Negative Cumulative Percent']
    })
  });
  
const categoryDetailPricetopicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];


// Category Detail - Design Topic Comparison List - Stack Bar Chart

let categoryDetailDesignTopicComparisonTopic = designTopic.map(data => data['Topic']);
let categoryDetailDesignTopicComparisonPositive = designTopic.map(data => data['Positive']);
let categoryDetailDesignTopicComparisonNeutral = designTopic.map(data => data['Neutral']);
let categoryDetailDesignTopicComparisonNegative = designTopic.map(data => data['Negative']);

let categoryDetailDesignTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailDesignTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailDesignTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailDesignTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailDesignTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailDesignTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail - Design Topic Comparison List Table using react-data-table-component

const categoryDetailDesignTopicComparisonTableDataSet = designTopic.map((DesignTopic) => {
    return ({
      id: DesignTopic.id,
      topic: DesignTopic['Topic'],
      positive: DesignTopic['Positive'],
      negative: DesignTopic['Negative'],
      neutral: DesignTopic['Neutral'],
      total: DesignTopic['Total']
    })
  });
  
const categoryDetailDesignTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Category Detail - Design - Topic Insight - Topic Phrase - Stack Bar Chart

let categoryDetailDesigntopicInsightTopicPhraseLabel = categoryDetailTopicInsightTopicPhraseDesignData.map(data => data['Phrase']);
let categoryDetailDesigntopicInsightTopicPhrasePositive = categoryDetailTopicInsightTopicPhraseDesignData.map(data => data['Positive']);
let categoryDetailDesigntopicInsightTopicPhraseNeutral = categoryDetailTopicInsightTopicPhraseDesignData.map(data => data['Neutral']);
let categoryDetailDesigntopicInsightTopicPhraseNegative = categoryDetailTopicInsightTopicPhraseDesignData.map(data => data['Negative']);

let categoryDetailDesigntopicInsightPhraseDataSet = {
  type: 'bar',
  labels: categoryDetailDesigntopicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailDesigntopicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: categoryDetailDesigntopicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: categoryDetailDesigntopicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailDesigntopicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


const categoryDetailDesigntopicInsightPhraseTableDataSet = categoryDetailTopicInsightTopicPhraseDesignData.map((CategoryDetailTopicInsightTopicPhraseDesignData) => {
    return ({
      id: CategoryDetailTopicInsightTopicPhraseDesignData.id,
      topic: CategoryDetailTopicInsightTopicPhraseDesignData['Phrase'],
      positive: CategoryDetailTopicInsightTopicPhraseDesignData['Positive'],
      negative: CategoryDetailTopicInsightTopicPhraseDesignData['Negative'],
      neutral: CategoryDetailTopicInsightTopicPhraseDesignData['Neutral']
    })
  });
  
const categoryDetailDesigntopicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Design - Topic Positive - Bar Chart

let categoryDetailTopicInsightPositiveSentimentDesignLabel = categoryDetailTopicInsightPositiveSentimentDesignData.map(data => data['WORD']);
let categoryDetailTopicInsightPositiveSentimentDesignFreq = categoryDetailTopicInsightPositiveSentimentDesignData.map(data => data['FREQ']);

let categoryDetailDesigntopicInsightPositiveDataSet = {
  type: 'bar',
  labels: categoryDetailTopicInsightPositiveSentimentDesignLabel,
  datasets: [
    {
      label: "Positive Words",
      data: categoryDetailTopicInsightPositiveSentimentDesignFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let categoryDetailDesigntopicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailDesigntopicInsightPositiveTableDataSet = categoryDetailTopicInsightPositiveSentimentDesignData.map((CategoryDetailTopicInsightPositiveSentimentDesignData) => {
    return ({
      id: CategoryDetailTopicInsightPositiveSentimentDesignData.id,
      word: CategoryDetailTopicInsightPositiveSentimentDesignData['WORD'],
      freq: CategoryDetailTopicInsightPositiveSentimentDesignData['FREQ']
    })
  });
  
const categoryDetailDesigntopicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Design - Topic Negative - Bar Chart

let categoryDetailDesigntopicInsightNegativeLabel = categoryDetailTopicInsightNegativeSentimentDesignData.map(data => data['WORD']);
let categoryDetailDesigntopicInsightNegativeFreq = categoryDetailTopicInsightNegativeSentimentDesignData.map(data => data['FREQ']);

let categoryDetailDesigntopicInsightNegativeDataSet = {
  type: 'bar',
  labels: categoryDetailDesigntopicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: categoryDetailDesigntopicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let categoryDetailDesigntopicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailDesigntopicInsightNegativeTableDataSet = categoryDetailTopicInsightNegativeSentimentDesignData.map((CategoryDetailTopicInsightNegativeSentimentDesignData) => {
    return ({
      id: CategoryDetailTopicInsightNegativeSentimentDesignData.id,
      word: CategoryDetailTopicInsightNegativeSentimentDesignData['WORD'],
      freq: CategoryDetailTopicInsightNegativeSentimentDesignData['FREQ']
    })
  });
  
const categoryDetailDesigntopicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Topic Insight Trend - Design - Line Chart

let categoryDetailDesigntopicInsightTopicTrendLabel = categoryDetailTopicInsightTopicTrendDesignData.map(data => data.Date);

let categoryDetailDesigntopicInsightTopicTrendDataSet = {
  type: 'line',
  labels: categoryDetailDesigntopicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTopicInsightTopicTrendDesignData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categoryDetailTopicInsightTopicTrendDesignData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: categoryDetailTopicInsightTopicTrendDesignData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let categoryDetailDesigntopicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Category Detail - Topic Insight - Design - Table using react-data-table-component

const categoryDetailDesigntopicInsightTopicTrendTableDataSet = categoryDetailTopicInsightTopicTrendDesignData.map((CategoryDetailTopicInsightTopicTrendDesignData) => {
    return ({
      id: CategoryDetailTopicInsightTopicTrendDesignData.id,
      date: CategoryDetailTopicInsightTopicTrendDesignData.Date,
      topic: CategoryDetailTopicInsightTopicTrendDesignData.Topic,
      positiveTrend: CategoryDetailTopicInsightTopicTrendDesignData['Positive'],
      neutralTrend: CategoryDetailTopicInsightTopicTrendDesignData['Neutral'],
      negativeTrend: CategoryDetailTopicInsightTopicTrendDesignData['Negative'],
      total: CategoryDetailTopicInsightTopicTrendDesignData['Total'],
      posCumulative: CategoryDetailTopicInsightTopicTrendDesignData['Positive Cumulative'],
      negCumulative: CategoryDetailTopicInsightTopicTrendDesignData['Negative Cumulative'],
      totalCumulative: CategoryDetailTopicInsightTopicTrendDesignData['Cumulative Total'],
      posCumulativePercent: CategoryDetailTopicInsightTopicTrendDesignData['Positive Cumulative Percent'],
      negCumulativePercent: CategoryDetailTopicInsightTopicTrendDesignData['Negative Cumulative Percent']
    })
  });
  
const categoryDetailDesigntopicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];




// Category Detail - Feature Topic Comparison List - Stack Bar Chart

let categoryDetailFeatureTopicComparisonTopic = featureTopic.map(data => data['Topic']);
let categoryDetailFeatureTopicComparisonPositive = featureTopic.map(data => data['Positive']);
let categoryDetailFeatureTopicComparisonNeutral = featureTopic.map(data => data['Neutral']);
let categoryDetailFeatureTopicComparisonNegative = featureTopic.map(data => data['Negative']);

let categoryDetailFeatureTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailFeatureTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailFeatureTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailFeatureTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailFeatureTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailFeatureTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail - Feature Topic Comparison List Table using react-data-table-component

const categoryDetailFeatureTopicComparisonTableDataSet = featureTopic.map((FeatureTopic) => {
    return ({
      id: FeatureTopic.id,
      topic: FeatureTopic['Topic'],
      positive: FeatureTopic['Positive'],
      negative: FeatureTopic['Negative'],
      neutral: FeatureTopic['Neutral'],
      total: FeatureTopic['Total']
    })
  });
  
const categoryDetailFeatureTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Category Detail - Feature - Topic Insight - Topic Phrase - Stack Bar Chart

let categoryDetailFeaturetopicInsightTopicPhraseLabel = categoryDetailTopicInsightTopicPhraseFeatureData.map(data => data['Phrase']);
let categoryDetailFeaturetopicInsightTopicPhrasePositive = categoryDetailTopicInsightTopicPhraseFeatureData.map(data => data['Positive']);
let categoryDetailFeaturetopicInsightTopicPhraseNeutral = categoryDetailTopicInsightTopicPhraseFeatureData.map(data => data['Neutral']);
let categoryDetailFeaturetopicInsightTopicPhraseNegative = categoryDetailTopicInsightTopicPhraseFeatureData.map(data => data['Negative']);

let categoryDetailFeaturetopicInsightPhraseDataSet = {
  type: 'bar',
  labels: categoryDetailFeaturetopicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailFeaturetopicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: categoryDetailFeaturetopicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: categoryDetailFeaturetopicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailFeaturetopicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


const categoryDetailFeaturetopicInsightPhraseTableDataSet = categoryDetailTopicInsightTopicPhraseFeatureData.map((CategoryDetailTopicInsightTopicPhraseFeatureData) => {
    return ({
      id: CategoryDetailTopicInsightTopicPhraseFeatureData.id,
      topic: CategoryDetailTopicInsightTopicPhraseFeatureData['Phrase'],
      positive: CategoryDetailTopicInsightTopicPhraseFeatureData['Positive'],
      negative: CategoryDetailTopicInsightTopicPhraseFeatureData['Negative'],
      neutral: CategoryDetailTopicInsightTopicPhraseFeatureData['Neutral']
    })
  });
  
const categoryDetailFeaturetopicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Feature - Topic Positive - Bar Chart

let categoryDetailTopicInsightPositiveSentimentFeatureLabel = categoryDetailTopicInsightPositiveSentimentFeatureData.map(data => data['WORD']);
let categoryDetailTopicInsightPositiveSentimentFeatureFreq = categoryDetailTopicInsightPositiveSentimentFeatureData.map(data => data['FREQ']);

let categoryDetailFeaturetopicInsightPositiveDataSet = {
  type: 'bar',
  labels: categoryDetailTopicInsightPositiveSentimentFeatureLabel,
  datasets: [
    {
      label: "Positive Words",
      data: categoryDetailTopicInsightPositiveSentimentFeatureFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let categoryDetailFeaturetopicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailFeaturetopicInsightPositiveTableDataSet = categoryDetailTopicInsightPositiveSentimentFeatureData.map((CategoryDetailTopicInsightPositiveSentimentFeatureData) => {
    return ({
      id: CategoryDetailTopicInsightPositiveSentimentFeatureData.id,
      word: CategoryDetailTopicInsightPositiveSentimentFeatureData['WORD'],
      freq: CategoryDetailTopicInsightPositiveSentimentFeatureData['FREQ']
    })
  });
  
const categoryDetailFeaturetopicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Feature - Topic Negative - Bar Chart

let categoryDetailFeaturetopicInsightNegativeLabel = categoryDetailTopicInsightNegativeSentimentFeatureData.map(data => data['WORD']);
let categoryDetailFeaturetopicInsightNegativeFreq = categoryDetailTopicInsightNegativeSentimentFeatureData.map(data => data['FREQ']);

let categoryDetailFeaturetopicInsightNegativeDataSet = {
  type: 'bar',
  labels: categoryDetailFeaturetopicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: categoryDetailFeaturetopicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let categoryDetailFeaturetopicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailFeaturetopicInsightNegativeTableDataSet = categoryDetailTopicInsightNegativeSentimentFeatureData.map((CategoryDetailTopicInsightNegativeSentimentFeatureData) => {
    return ({
      id: CategoryDetailTopicInsightNegativeSentimentFeatureData.id,
      word: CategoryDetailTopicInsightNegativeSentimentFeatureData['WORD'],
      freq: CategoryDetailTopicInsightNegativeSentimentFeatureData['FREQ']
    })
  });
  
const categoryDetailFeaturetopicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Topic Insight Trend - Feature - Line Chart

let categoryDetailFeaturetopicInsightTopicTrendLabel = categoryDetailTopicInsightTopicTrendFeatureData.map(data => data.Date);

let categoryDetailFeaturetopicInsightTopicTrendDataSet = {
  type: 'line',
  labels: categoryDetailFeaturetopicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTopicInsightTopicTrendFeatureData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categoryDetailTopicInsightTopicTrendFeatureData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: categoryDetailTopicInsightTopicTrendFeatureData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let categoryDetailFeaturetopicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Category Detail - Topic Insight - Feature - Table using react-data-table-component

const categoryDetailFeaturetopicInsightTopicTrendTableDataSet = categoryDetailTopicInsightTopicTrendFeatureData.map((CategoryDetailTopicInsightTopicTrendFeatureData) => {
    return ({
      id: CategoryDetailTopicInsightTopicTrendFeatureData.id,
      date: CategoryDetailTopicInsightTopicTrendFeatureData.Date,
      topic: CategoryDetailTopicInsightTopicTrendFeatureData.Topic,
      positiveTrend: CategoryDetailTopicInsightTopicTrendFeatureData['Positive'],
      neutralTrend: CategoryDetailTopicInsightTopicTrendFeatureData['Neutral'],
      negativeTrend: CategoryDetailTopicInsightTopicTrendFeatureData['Negative'],
      total: CategoryDetailTopicInsightTopicTrendFeatureData['Total'],
      posCumulative: CategoryDetailTopicInsightTopicTrendFeatureData['Positive Cumulative'],
      negCumulative: CategoryDetailTopicInsightTopicTrendFeatureData['Negative Cumulative'],
      totalCumulative: CategoryDetailTopicInsightTopicTrendFeatureData['Cumulative Total'],
      posCumulativePercent: CategoryDetailTopicInsightTopicTrendFeatureData['Positive Cumulative Percent'],
      negCumulativePercent: CategoryDetailTopicInsightTopicTrendFeatureData['Negative Cumulative Percent']
    })
  });
  
const categoryDetailFeaturetopicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];






// Category Detail - Sound Topic Comparison List - Stack Bar Chart

let categoryDetailSoundTopicComparisonTopic = soundTopic.map(data => data['Topic']);
let categoryDetailSoundTopicComparisonPositive = soundTopic.map(data => data['Positive']);
let categoryDetailSoundTopicComparisonNeutral = soundTopic.map(data => data['Neutral']);
let categoryDetailSoundTopicComparisonNegative = soundTopic.map(data => data['Negative']);

let categoryDetailSoundTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailSoundTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailSoundTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailSoundTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailSoundTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailSoundTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail - Sound Topic Comparison List Table using react-data-table-component

const categoryDetailSoundTopicComparisonTableDataSet = soundTopic.map((SoundTopic) => {
    return ({
      id: SoundTopic.id,
      topic: SoundTopic['Topic'],
      positive: SoundTopic['Positive'],
      negative: SoundTopic['Negative'],
      neutral: SoundTopic['Neutral'],
      total: SoundTopic['Total']
    })
  });
  
const categoryDetailSoundTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Category Detail - Sound - Topic Insight - Topic Phrase - Stack Bar Chart

let categoryDetailSoundtopicInsightTopicPhraseLabel = categoryDetailTopicInsightTopicPhraseSoundData.map(data => data['Phrase']);
let categoryDetailSoundtopicInsightTopicPhrasePositive = categoryDetailTopicInsightTopicPhraseSoundData.map(data => data['Positive']);
let categoryDetailSoundtopicInsightTopicPhraseNeutral = categoryDetailTopicInsightTopicPhraseSoundData.map(data => data['Neutral']);
let categoryDetailSoundtopicInsightTopicPhraseNegative = categoryDetailTopicInsightTopicPhraseSoundData.map(data => data['Negative']);

let categoryDetailSoundtopicInsightPhraseDataSet = {
  type: 'bar',
  labels: categoryDetailSoundtopicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailSoundtopicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: categoryDetailSoundtopicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: categoryDetailSoundtopicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailSoundtopicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


const categoryDetailSoundtopicInsightPhraseTableDataSet = categoryDetailTopicInsightTopicPhraseSoundData.map((CategoryDetailTopicInsightTopicPhraseSoundData) => {
    return ({
      id: CategoryDetailTopicInsightTopicPhraseSoundData.id,
      topic: CategoryDetailTopicInsightTopicPhraseSoundData['Phrase'],
      positive: CategoryDetailTopicInsightTopicPhraseSoundData['Positive'],
      negative: CategoryDetailTopicInsightTopicPhraseSoundData['Negative'],
      neutral: CategoryDetailTopicInsightTopicPhraseSoundData['Neutral']
    })
  });
  
const categoryDetailSoundtopicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Sound - Topic Positive - Bar Chart

let categoryDetailTopicInsightPositiveSentimentSoundLabel = categoryDetailTopicInsightPositiveSentimentSoundData.map(data => data['WORD']);
let categoryDetailTopicInsightPositiveSentimentSoundFreq = categoryDetailTopicInsightPositiveSentimentSoundData.map(data => data['FREQ']);

let categoryDetailSoundtopicInsightPositiveDataSet = {
  type: 'bar',
  labels: categoryDetailTopicInsightPositiveSentimentSoundLabel,
  datasets: [
    {
      label: "Positive Words",
      data: categoryDetailTopicInsightPositiveSentimentSoundFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let categoryDetailSoundtopicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailSoundtopicInsightPositiveTableDataSet = categoryDetailTopicInsightPositiveSentimentSoundData.map((CategoryDetailTopicInsightPositiveSentimentSoundData) => {
    return ({
      id: CategoryDetailTopicInsightPositiveSentimentSoundData.id,
      word: CategoryDetailTopicInsightPositiveSentimentSoundData['WORD'],
      freq: CategoryDetailTopicInsightPositiveSentimentSoundData['FREQ']
    })
  });
  
const categoryDetailSoundtopicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Detail - Topic Insight - Sound - Topic Negative - Bar Chart

let categoryDetailSoundtopicInsightNegativeLabel = categoryDetailTopicInsightNegativeSentimentSoundData.map(data => data['WORD']);
let categoryDetailSoundtopicInsightNegativeFreq = categoryDetailTopicInsightNegativeSentimentSoundData.map(data => data['FREQ']);

let categoryDetailSoundtopicInsightNegativeDataSet = {
  type: 'bar',
  labels: categoryDetailSoundtopicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: categoryDetailSoundtopicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let categoryDetailSoundtopicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}


const categoryDetailSoundtopicInsightNegativeTableDataSet = categoryDetailTopicInsightNegativeSentimentSoundData.map((CategoryDetailTopicInsightNegativeSentimentSoundData) => {
    return ({
      id: CategoryDetailTopicInsightNegativeSentimentSoundData.id,
      word: CategoryDetailTopicInsightNegativeSentimentSoundData['WORD'],
      freq: CategoryDetailTopicInsightNegativeSentimentSoundData['FREQ']
    })
  });
  
const categoryDetailSoundtopicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Detail - Topic Insight Trend - Sound - Line Chart

let categoryDetailSoundtopicInsightTopicTrendLabel = categoryDetailTopicInsightTopicTrendSoundData.map(data => data.Date);

let categoryDetailSoundtopicInsightTopicTrendDataSet = {
  type: 'line',
  labels: categoryDetailSoundtopicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTopicInsightTopicTrendSoundData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categoryDetailTopicInsightTopicTrendSoundData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: categoryDetailTopicInsightTopicTrendSoundData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let categoryDetailSoundtopicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Category Detail - Topic Insight - Sound - Table using react-data-table-component

const categoryDetailSoundtopicInsightTopicTrendTableDataSet = categoryDetailTopicInsightTopicTrendSoundData.map((CategoryDetailTopicInsightTopicTrendSoundData) => {
    return ({
      id: CategoryDetailTopicInsightTopicTrendSoundData.id,
      date: CategoryDetailTopicInsightTopicTrendSoundData.Date,
      topic: CategoryDetailTopicInsightTopicTrendSoundData.Topic,
      positiveTrend: CategoryDetailTopicInsightTopicTrendSoundData['Positive'],
      neutralTrend: CategoryDetailTopicInsightTopicTrendSoundData['Neutral'],
      negativeTrend: CategoryDetailTopicInsightTopicTrendSoundData['Negative'],
      total: CategoryDetailTopicInsightTopicTrendSoundData['Total'],
      posCumulative: CategoryDetailTopicInsightTopicTrendSoundData['Positive Cumulative'],
      negCumulative: CategoryDetailTopicInsightTopicTrendSoundData['Negative Cumulative'],
      totalCumulative: CategoryDetailTopicInsightTopicTrendSoundData['Cumulative Total'],
      posCumulativePercent: CategoryDetailTopicInsightTopicTrendSoundData['Positive Cumulative Percent'],
      negCumulativePercent: CategoryDetailTopicInsightTopicTrendSoundData['Negative Cumulative Percent']
    })
  });
  
const categoryDetailSoundtopicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];







// Category Detail -  - View Individual Sentence - Table using material-table


const viewIndividualSentenceTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const viewIndividualSentenceDataTableDataSet = viewIndividualSentenceData.map((ViewIndividualSentenceData) => {
  return ({
    id: ViewIndividualSentenceData.id,
    date: ViewIndividualSentenceData.Date,
    website: ViewIndividualSentenceData.Website,
    country: ViewIndividualSentenceData['Country'],
    reviewID: ViewIndividualSentenceData['Reviewer_ID'],
    model: ViewIndividualSentenceData['Model'],
    stars: ViewIndividualSentenceData['Stars'],
    category: ViewIndividualSentenceData['Category'],
    topic: ViewIndividualSentenceData['Topic'],
    individualSentence: ViewIndividualSentenceData['Individual.Sentence'],
    sentimentResult: ViewIndividualSentenceData['Sentiment.Result'],
    posList: ViewIndividualSentenceData['Pos_List'],
    negList: ViewIndividualSentenceData['Neg_List']
  })
});



//All Drill Down


//Unique Trend - Trend Line - Drill Down - Positive Wordcloud

const uniqueTrendPositiveDrillDownDataTopicWords = uniqueTrendPositiveDrillDownData.map((UniqueTrendPositiveDrillDownData) => {
  return({
    text: UniqueTrendPositiveDrillDownData['WORD'],
    value: UniqueTrendPositiveDrillDownData['FREQ']
  })
})

const uniqueTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const uniqueTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function uniqueTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={uniqueTrendPositiveDrillDownDataTopicCallbacks}
    options={uniqueTrendPositiveDrillDownDataTopicOptions}
    words={uniqueTrendPositiveDrillDownDataTopicWords} 
  />
}


// Unique Trend - Trend Line - Drill Down - Positive - Table using react-data-table-component

const uniqueTrendPositiveDrillDownDataTopicTableDataSet = uniqueTrendPositiveDrillDownData.map((UniqueTrendPositiveDrillDownData) => {
    return ({
      id: UniqueTrendPositiveDrillDownData.id,
      topic: UniqueTrendPositiveDrillDownData['WORD'],
      freq: UniqueTrendPositiveDrillDownData['FREQ']
    })
  });
  
const uniqueTrendPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Unique Trend - Trend Line - Drill Down - Negative Wordcloud

const uniqueTrendNegativeDrillDownDataTopicWords = uniqueTrendNegativeDrillDownData.map((UniqueTrendNegativeDrillDownData) => {
  return({
    text: UniqueTrendNegativeDrillDownData['WORD'],
    value: UniqueTrendNegativeDrillDownData['FREQ']
  })
})

const uniqueTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const uniqueTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function uniqueTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={uniqueTrendNegativeDrillDownDataTopicCallbacks}
    options={uniqueTrendNegativeDrillDownDataTopicOptions}
    words={uniqueTrendNegativeDrillDownDataTopicWords} 
  />
}


// Unique Trend - Trend Line - Drill Down - Negative - using react-data-table-component

const uniqueTrendNegativeDrillDownDataTopicTableDataSet = uniqueTrendNegativeDrillDownData.map((UniqueTrendNegativeDrillDownData) => {
    return ({
      id: UniqueTrendNegativeDrillDownData.id,
      topic: UniqueTrendNegativeDrillDownData['WORD'],
      freq: UniqueTrendNegativeDrillDownData['FREQ']
    })
  });
  
const uniqueTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Unique Trend - Trend Line - Drill Down - View Individual Sentence - Table using material-table

const uniqueTrendIndividualSentenceDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const uniqueTrendIndividualSentenceDrillDownDataTableDataSet = uniqueTrendIndividualSentenceDrillDownData.map((UniqueTrendIndividualSentenceDrillDownData) => {
  return ({
    id: UniqueTrendIndividualSentenceDrillDownData.id,
    date: UniqueTrendIndividualSentenceDrillDownData.Date,
    website: UniqueTrendIndividualSentenceDrillDownData.Website,
    country: UniqueTrendIndividualSentenceDrillDownData['Country'],
    reviewID: UniqueTrendIndividualSentenceDrillDownData['Reviewer_ID'],
    model: UniqueTrendIndividualSentenceDrillDownData['Model'],
    stars: UniqueTrendIndividualSentenceDrillDownData['Stars'],
    category: UniqueTrendIndividualSentenceDrillDownData['Category'],
    topic: UniqueTrendIndividualSentenceDrillDownData['Topic'],
    individualSentence: UniqueTrendIndividualSentenceDrillDownData['Individual.Sentence'],
    sentimentResult: UniqueTrendIndividualSentenceDrillDownData['Sentiment.Result'],
    posList: UniqueTrendIndividualSentenceDrillDownData['Pos_List'],
    negList: UniqueTrendIndividualSentenceDrillDownData['Neg_List']
  })
});



// Star Label - Drill Down - Positive Wordcloud

const starRatingPiePositiveDrillDownDataTopicWords = starRatingPiePositiveDrillDownData.map((StarRatingPiePositiveDrillDownData) => {
  return({
    text: StarRatingPiePositiveDrillDownData['WORD'],
    value: StarRatingPiePositiveDrillDownData['FREQ']
  })
})

const starRatingPiePositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const starRatingPiePositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function starRatingPiePositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={starRatingPiePositiveDrillDownDataTopicCallbacks}
    options={starRatingPiePositiveDrillDownDataTopicOptions}
    words={starRatingPiePositiveDrillDownDataTopicWords} 
  />
}


// Star Label - Drill Down - Positive - Table using react-data-table-component

const starRatingPiePositiveDrillDownDataTopicTableDataSet = starRatingPiePositiveDrillDownData.map((StarRatingPiePositiveDrillDownData) => {
    return ({
      id: StarRatingPiePositiveDrillDownData.id,
      topic: StarRatingPiePositiveDrillDownData['WORD'],
      freq: StarRatingPiePositiveDrillDownData['FREQ']
    })
  });
  
const starRatingPiePositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Star Label - Drill Down - Negative Wordcloud

const starRatingPieNegativeDrillDownDataTopicWords = starRatingPieNegativeDrillDownData.map((StarRatingPieNegativeDrillDownData) => {
  return({
    text: StarRatingPieNegativeDrillDownData['WORD'],
    value: StarRatingPieNegativeDrillDownData['FREQ']
  })
})

const starRatingPieNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const starRatingPieNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function starRatingPieNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={starRatingPieNegativeDrillDownDataTopicCallbacks}
    options={starRatingPieNegativeDrillDownDataTopicOptions}
    words={starRatingPieNegativeDrillDownDataTopicWords} 
  />
}


// Star Label - Drill Down - Negative - using react-data-table-component

const starRatingPieNegativeDrillDownDataTopicTableDataSet = starRatingPieNegativeDrillDownData.map((StarRatingPieNegativeDrillDownData) => {
    return ({
      id: StarRatingPieNegativeDrillDownData.id,
      topic: StarRatingPieNegativeDrillDownData['WORD'],
      freq: StarRatingPieNegativeDrillDownData['FREQ']
    })
  });
  
const starRatingPieNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Star Label - Drill Down - View Individual Sentence - Table using material-table

const starRatingPieIndividualSentenceDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const starRatingPieIndividualSentenceDrillDownDataTableDataSet = starRatingPieIndividualSentenceDrillDownData.map((StarRatingPieIndividualSentenceDrillDownData) => {
  return ({
    id: StarRatingPieIndividualSentenceDrillDownData.id,
    date: StarRatingPieIndividualSentenceDrillDownData.Date,
    website: StarRatingPieIndividualSentenceDrillDownData.Website,
    country: StarRatingPieIndividualSentenceDrillDownData['Country'],
    reviewID: StarRatingPieIndividualSentenceDrillDownData['Reviewer_ID'],
    model: StarRatingPieIndividualSentenceDrillDownData['Model'],
    stars: StarRatingPieIndividualSentenceDrillDownData['Stars'],
    category: StarRatingPieIndividualSentenceDrillDownData['Category'],
    topic: StarRatingPieIndividualSentenceDrillDownData['Topic'],
    individualSentence: StarRatingPieIndividualSentenceDrillDownData['Individual.Sentence'],
    sentimentResult: StarRatingPieIndividualSentenceDrillDownData['Sentiment.Result'],
    posList: StarRatingPieIndividualSentenceDrillDownData['Pos_List'],
    negList: StarRatingPieIndividualSentenceDrillDownData['Neg_List']
  })
});




//Category Summary - Radar - Drill Down - Positive Wordcloud

const categorySummarySentimentRadarPositiveDrillDownDataTopicWords = categorySummarySentimentRadarPositiveDrillDownData.map((CategorySummarySentimentRadarPositiveDrillDownData) => {
  return({
    text: CategorySummarySentimentRadarPositiveDrillDownData['WORD'],
    value: CategorySummarySentimentRadarPositiveDrillDownData['FREQ']
  })
})

const categorySummarySentimentRadarPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummarySentimentRadarPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function uniqueTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummarySentimentRadarPositiveDrillDownDataTopicCallbacks}
    options={categorySummarySentimentRadarPositiveDrillDownDataTopicOptions}
    words={categorySummarySentimentRadarPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Radar - Positive - Table using react-data-table-component

const categorySummarySentimentRadarPositiveDrillDownDataTopicTableDataSet = categorySummarySentimentRadarPositiveDrillDownData.map((CategorySummarySentimentRadarPositiveDrillDownData) => {
    return ({
      id: CategorySummarySentimentRadarPositiveDrillDownData.id,
      topic: CategorySummarySentimentRadarPositiveDrillDownData['WORD'],
      freq: CategorySummarySentimentRadarPositiveDrillDownData['FREQ']
    })
  });
  
const categorySummarySentimentRadarPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Radar -  Drill Down - Negative Wordcloud

const categorySummarySentimentRadarNegativeDrillDownDataTopicWords = categorySummarySentimentRadarNegativeDrillDownData.map((CategorySummarySentimentRadarNegativeDrillDownData) => {
  return({
    text: CategorySummarySentimentRadarNegativeDrillDownData['WORD'],
    value: CategorySummarySentimentRadarNegativeDrillDownData['FREQ']
  })
})

const categorySummarySentimentRadarNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummarySentimentRadarNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function uniqueTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummarySentimentRadarNegativeDrillDownDataTopicCallbacks}
    options={categorySummarySentimentRadarNegativeDrillDownDataTopicOptions}
    words={categorySummarySentimentRadarNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Radar - Drill Down - Negative - using react-data-table-component

const categorySummarySentimentRadarNegativeDrillDownDataTopicTableDataSet = categorySummarySentimentRadarNegativeDrillDownData.map((CategorySummarySentimentRadarNegativeDrillDownData) => {
    return ({
      id: CategorySummarySentimentRadarNegativeDrillDownData.id,
      topic: CategorySummarySentimentRadarNegativeDrillDownData['WORD'],
      freq: CategorySummarySentimentRadarNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummarySentimentRadarNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Radar -  Drill Down - View Individual Sentence - Table using material-table

const categorySummarySentimentRadarDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummarySentimentRadarDrillDownDataTableDataSet = categorySummarySentimentRadarDrillDownData.map((CategorySummarySentimentRadarDrillDownData) => {
  return ({
    id: CategorySummarySentimentRadarDrillDownData.id,
    date: CategorySummarySentimentRadarDrillDownData.Date,
    website: CategorySummarySentimentRadarDrillDownData.Website,
    country: CategorySummarySentimentRadarDrillDownData['Country'],
    reviewID: CategorySummarySentimentRadarDrillDownData['Reviewer_ID'],
    model: CategorySummarySentimentRadarDrillDownData['Model'],
    stars: CategorySummarySentimentRadarDrillDownData['Stars'],
    category: CategorySummarySentimentRadarDrillDownData['Category'],
    topic: CategorySummarySentimentRadarDrillDownData['Topic'],
    individualSentence: CategorySummarySentimentRadarDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummarySentimentRadarDrillDownData['Sentiment.Result'],
    posList: CategorySummarySentimentRadarDrillDownData['Pos_List'],
    negList: CategorySummarySentimentRadarDrillDownData['Neg_List']
  })
});



// Category Summary - Star Rating Bar - Drill Down - Positive Wordcloud

const categorySummaryStarRatingPositiveDrillDownDataTopicWords = categorySummaryStarRatingPositiveDrillDownData.map((CategorySummaryStarRatingPositiveDrillDownData) => {
  return({
    text: CategorySummaryStarRatingPositiveDrillDownData['WORD'],
    value: CategorySummaryStarRatingPositiveDrillDownData['FREQ']
  })
})

const categorySummaryStarRatingPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryStarRatingPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryStarRatingPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryStarRatingPositiveDrillDownDataTopicCallbacks}
    options={categorySummaryStarRatingPositiveDrillDownDataTopicOptions}
    words={categorySummaryStarRatingPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Star Rating Bar - Positive - Table using react-data-table-component

const categorySummaryStarRatingPositiveDrillDownDataTopicTableDataSet = categorySummaryStarRatingPositiveDrillDownData.map((CategorySummaryStarRatingPositiveDrillDownData) => {
    return ({
      id: CategorySummaryStarRatingPositiveDrillDownData.id,
      topic: CategorySummaryStarRatingPositiveDrillDownData['WORD'],
      freq: CategorySummaryStarRatingPositiveDrillDownData['FREQ']
    })
  });
  
const categorySummaryStarRatingPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Radar -  Drill Down - Negative Wordcloud

const categorySummaryStarRatingNegativeDrillDownDataTopicWords = categorySummaryStarRatingNegativeDrillDownData.map((CategorySummaryStarRatingNegativeDrillDownData) => {
  return({
    text: CategorySummaryStarRatingNegativeDrillDownData['WORD'],
    value: CategorySummaryStarRatingNegativeDrillDownData['FREQ']
  })
})

const categorySummaryStarRatingNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryStarRatingNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryStarRatingNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryStarRatingNegativeDrillDownDataTopicCallbacks}
    options={categorySummaryStarRatingNegativeDrillDownDataTopicOptions}
    words={categorySummaryStarRatingNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Star Rating Bar - Drill Down - Negative - using react-data-table-component

const categorySummaryStarRatingNegativeDrillDownDataTopicTableDataSet = categorySummaryStarRatingNegativeDrillDownData.map((CategorySummaryStarRatingNegativeDrillDownData) => {
    return ({
      id: CategorySummaryStarRatingNegativeDrillDownData.id,
      topic: CategorySummaryStarRatingNegativeDrillDownData['WORD'],
      freq: CategorySummaryStarRatingNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummaryStarRatingNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Star Rating Bar -  Drill Down - View Individual Sentence - Table using material-table

const categorySummaryStarRatingDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummaryStarRatingDrillDownDataTableDataSet = categorySummaryStarRatingDrillDownData.map((CategorySummaryStarRatingDrillDownData) => {
  return ({
    id: CategorySummaryStarRatingDrillDownData.id,
    date: CategorySummaryStarRatingDrillDownData.Date,
    website: CategorySummaryStarRatingDrillDownData.Website,
    country: CategorySummaryStarRatingDrillDownData['Country'],
    reviewID: CategorySummaryStarRatingDrillDownData['Reviewer_ID'],
    model: CategorySummaryStarRatingDrillDownData['Model'],
    stars: CategorySummaryStarRatingDrillDownData['Stars'],
    category: CategorySummaryStarRatingDrillDownData['Category'],
    topic: CategorySummaryStarRatingDrillDownData['Topic'],
    individualSentence: CategorySummaryStarRatingDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummaryStarRatingDrillDownData['Sentiment.Result'],
    posList: CategorySummaryStarRatingDrillDownData['Pos_List'],
    negList: CategorySummaryStarRatingDrillDownData['Neg_List']
  })
});




// Category Summary - Software Trend - Drill Down - Positive Wordcloud

const categorySummarySoftwareTrendPositiveDrillDownDataTopicWords = categorySummarySoftwareTrendPositiveDrillDownData.map((CategorySummarySoftwareTrendPositiveDrillDownData) => {
  return({
    text: CategorySummarySoftwareTrendPositiveDrillDownData['WORD'],
    value: CategorySummarySoftwareTrendPositiveDrillDownData['FREQ']
  })
})

const categorySummarySoftwareTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummarySoftwareTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummarySoftwareTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummarySoftwareTrendPositiveDrillDownDataTopicCallbacks}
    options={categorySummarySoftwareTrendPositiveDrillDownDataTopicOptions}
    words={categorySummarySoftwareTrendPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Software Trend - Positive - Table using react-data-table-component

const categorySummarySoftwareTrendPositiveDrillDownDataTopicTableDataSet = categorySummarySoftwareTrendPositiveDrillDownData.map((CategorySummarySoftwareTrendPositiveDrillDownData) => {
    return ({
      id: CategorySummarySoftwareTrendPositiveDrillDownData.id,
      topic: CategorySummarySoftwareTrendPositiveDrillDownData['WORD'],
      freq: CategorySummarySoftwareTrendPositiveDrillDownData['FREQ']
    })
  });
  
const CategorySummarySoftwareTrendPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Software Trend -  Drill Down - Negative Wordcloud

const categorySummarySoftwareTrendNegativeDrillDownDataTopicWords = categorySummarySoftwareTrendNegativeDrillDownData.map((CategorySummarySoftwareTrendNegativeDrillDownData) => {
  return({
    text: CategorySummarySoftwareTrendNegativeDrillDownData['WORD'],
    value: CategorySummarySoftwareTrendNegativeDrillDownData['FREQ']
  })
})

const categorySummarySoftwareTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummarySoftwareTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummarySoftwareTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummarySoftwareTrendNegativeDrillDownDataTopicCallbacks}
    options={categorySummarySoftwareTrendNegativeDrillDownDataTopicOptions}
    words={categorySummarySoftwareTrendNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Software Trend - Drill Down - Negative - using react-data-table-component

const categorySummarySoftwareTrendNegativeDrillDownDataTopicTableDataSet = categorySummarySoftwareTrendNegativeDrillDownData.map((CategorySummarySoftwareTrendNegativeDrillDownData) => {
    return ({
      id: CategorySummarySoftwareTrendNegativeDrillDownData.id,
      topic: CategorySummarySoftwareTrendNegativeDrillDownData['WORD'],
      freq: CategorySummarySoftwareTrendNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummarySoftwareTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Software Trend -  Drill Down - View Individual Sentence - Table using material-table

const categorySummarySoftwareTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummarySoftwareTrendDrillDownDataTableDataSet = categorySummarySoftwareTrendDrillDownData.map((CategorySummarySoftwareTrendDrillDownData) => {
  return ({
    id: CategorySummarySoftwareTrendDrillDownData.id,
    date: CategorySummarySoftwareTrendDrillDownData.Date,
    website: CategorySummarySoftwareTrendDrillDownData.Website,
    country: CategorySummarySoftwareTrendDrillDownData['Country'],
    reviewID: CategorySummarySoftwareTrendDrillDownData['Reviewer_ID'],
    model: CategorySummarySoftwareTrendDrillDownData['Model'],
    stars: CategorySummarySoftwareTrendDrillDownData['Stars'],
    category: CategorySummarySoftwareTrendDrillDownData['Category'],
    topic: CategorySummarySoftwareTrendDrillDownData['Topic'],
    individualSentence: CategorySummarySoftwareTrendDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummarySoftwareTrendDrillDownData['Sentiment.Result'],
    posList: CategorySummarySoftwareTrendDrillDownData['Pos_List'],
    negList: CategorySummarySoftwareTrendDrillDownData['Neg_List']
  })
});



// Category Summary - Picture Trend - Drill Down - Positive Wordcloud

const categorySummaryPictureTrendPositiveDrillDownDataTopicWords = categorySummaryPictureTrendPositiveDrillDownData.map((CategorySummaryPictureTrendPositiveDrillDownData) => {
  return({
    text: CategorySummaryPictureTrendPositiveDrillDownData['WORD'],
    value: CategorySummaryPictureTrendPositiveDrillDownData['FREQ']
  })
})

const categorySummaryPictureTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryPictureTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryPictureTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryPictureTrendPositiveDrillDownDataTopicCallbacks}
    options={categorySummaryPictureTrendPositiveDrillDownDataTopicOptions}
    words={categorySummaryPictureTrendPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Picture Trend - Positive - Table using react-data-table-component

const categorySummaryPictureTrendPositiveDrillDownDataTopicTableDataSet = categorySummaryPictureTrendPositiveDrillDownData.map((CategorySummaryPictureTrendPositiveDrillDownData) => {
    return ({
      id: CategorySummaryPictureTrendPositiveDrillDownData.id,
      topic: CategorySummaryPictureTrendPositiveDrillDownData['WORD'],
      freq: CategorySummaryPictureTrendPositiveDrillDownData['FREQ']
    })
  });
  
const CategorySummaryPictureTrendPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Picture Trend -  Drill Down - Negative Wordcloud

const categorySummaryPictureTrendNegativeDrillDownDataTopicWords = categorySummaryPictureTrendNegativeDrillDownData.map((CategorySummaryPictureTrendNegativeDrillDownData) => {
  return({
    text: CategorySummaryPictureTrendNegativeDrillDownData['WORD'],
    value: CategorySummaryPictureTrendNegativeDrillDownData['FREQ']
  })
})

const categorySummaryPictureTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryPictureTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryPictureTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryPictureTrendNegativeDrillDownDataTopicCallbacks}
    options={categorySummaryPictureTrendNegativeDrillDownDataTopicOptions}
    words={categorySummaryPictureTrendNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Picture Trend - Drill Down - Negative - using react-data-table-component

const categorySummaryPictureTrendNegativeDrillDownDataTopicTableDataSet = categorySummaryPictureTrendNegativeDrillDownData.map((CategorySummaryPictureTrendNegativeDrillDownData) => {
    return ({
      id: CategorySummaryPictureTrendNegativeDrillDownData.id,
      topic: CategorySummaryPictureTrendNegativeDrillDownData['WORD'],
      freq: CategorySummaryPictureTrendNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummaryPictureTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Picture Trend -  Drill Down - View Individual Sentence - Table using material-table

const categorySummaryPictureTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummaryPictureTrendDrillDownDataTableDataSet = categorySummaryPictureTrendDrillDownData.map((CategorySummaryPictureTrendDrillDownData) => {
  return ({
    id: CategorySummaryPictureTrendDrillDownData.id,
    date: CategorySummaryPictureTrendDrillDownData.Date,
    website: CategorySummaryPictureTrendDrillDownData.Website,
    country: CategorySummaryPictureTrendDrillDownData['Country'],
    reviewID: CategorySummaryPictureTrendDrillDownData['Reviewer_ID'],
    model: CategorySummaryPictureTrendDrillDownData['Model'],
    stars: CategorySummaryPictureTrendDrillDownData['Stars'],
    category: CategorySummaryPictureTrendDrillDownData['Category'],
    topic: CategorySummaryPictureTrendDrillDownData['Topic'],
    individualSentence: CategorySummaryPictureTrendDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummaryPictureTrendDrillDownData['Sentiment.Result'],
    posList: CategorySummaryPictureTrendDrillDownData['Pos_List'],
    negList: CategorySummaryPictureTrendDrillDownData['Neg_List']
  })
});





// Category Summary - Design Trend - Drill Down - Positive Wordcloud

const categorySummaryDesignTrendPositiveDrillDownDataTopicWords = categorySummaryDesignTrendPositiveDrillDownData.map((CategorySummaryDesignTrendPositiveDrillDownData) => {
  return({
    text: CategorySummaryDesignTrendPositiveDrillDownData['WORD'],
    value: CategorySummaryDesignTrendPositiveDrillDownData['FREQ']
  })
})

const categorySummaryDesignTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryDesignTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryDesignTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryDesignTrendPositiveDrillDownDataTopicCallbacks}
    options={categorySummaryDesignTrendPositiveDrillDownDataTopicOptions}
    words={categorySummaryDesignTrendPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Design Trend - Positive - Table using react-data-table-component

const categorySummaryDesignTrendPositiveDrillDownDataTopicTableDataSet = categorySummaryDesignTrendPositiveDrillDownData.map((CategorySummaryDesignTrendPositiveDrillDownData) => {
    return ({
      id: CategorySummaryDesignTrendPositiveDrillDownData.id,
      topic: CategorySummaryDesignTrendPositiveDrillDownData['WORD'],
      freq: CategorySummaryDesignTrendPositiveDrillDownData['FREQ']
    })
  });
  
const CategorySummaryDesignTrendPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Design Trend -  Drill Down - Negative Wordcloud

const categorySummaryDesignTrendNegativeDrillDownDataTopicWords = categorySummaryDesignTrendNegativeDrillDownData.map((CategorySummaryDesignTrendNegativeDrillDownData) => {
  return({
    text: CategorySummaryDesignTrendNegativeDrillDownData['WORD'],
    value: CategorySummaryDesignTrendNegativeDrillDownData['FREQ']
  })
})

const categorySummaryDesignTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryDesignTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryDesignTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryDesignTrendNegativeDrillDownDataTopicCallbacks}
    options={categorySummaryDesignTrendNegativeDrillDownDataTopicOptions}
    words={categorySummaryDesignTrendNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Design Trend - Drill Down - Negative - using react-data-table-component

const categorySummaryDesignTrendNegativeDrillDownDataTopicTableDataSet = categorySummaryDesignTrendNegativeDrillDownData.map((CategorySummaryDesignTrendNegativeDrillDownData) => {
    return ({
      id: CategorySummaryDesignTrendNegativeDrillDownData.id,
      topic: CategorySummaryDesignTrendNegativeDrillDownData['WORD'],
      freq: CategorySummaryDesignTrendNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummaryDesignTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Design Trend -  Drill Down - View Individual Sentence - Table using material-table

const categorySummaryDesignTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummaryDesignTrendDrillDownDataTableDataSet = categorySummaryDesignTrendDrillDownData.map((CategorySummaryDesignTrendDrillDownData) => {
  return ({
    id: CategorySummaryDesignTrendDrillDownData.id,
    date: CategorySummaryDesignTrendDrillDownData.Date,
    website: CategorySummaryDesignTrendDrillDownData.Website,
    country: CategorySummaryDesignTrendDrillDownData['Country'],
    reviewID: CategorySummaryDesignTrendDrillDownData['Reviewer_ID'],
    model: CategorySummaryDesignTrendDrillDownData['Model'],
    stars: CategorySummaryDesignTrendDrillDownData['Stars'],
    category: CategorySummaryDesignTrendDrillDownData['Category'],
    topic: CategorySummaryDesignTrendDrillDownData['Topic'],
    individualSentence: CategorySummaryDesignTrendDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummaryDesignTrendDrillDownData['Sentiment.Result'],
    posList: CategorySummaryDesignTrendDrillDownData['Pos_List'],
    negList: CategorySummaryDesignTrendDrillDownData['Neg_List']
  })
});




// Category Summary - Feature Trend - Drill Down - Positive Wordcloud

const categorySummaryFeatureTrendPositiveDrillDownDataTopicWords = categorySummaryFeatureTrendPositiveDrillDownData.map((CategorySummaryFeatureTrendPositiveDrillDownData) => {
  return({
    text: CategorySummaryFeatureTrendPositiveDrillDownData['WORD'],
    value: CategorySummaryFeatureTrendPositiveDrillDownData['FREQ']
  })
})

const categorySummaryFeatureTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryFeatureTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryFeatureTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryFeatureTrendPositiveDrillDownDataTopicCallbacks}
    options={categorySummaryFeatureTrendPositiveDrillDownDataTopicOptions}
    words={categorySummaryFeatureTrendPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Feature Trend - Positive - Table using react-data-table-component

const categorySummaryFeatureTrendPositiveDrillDownDataTopicTableDataSet = categorySummaryFeatureTrendPositiveDrillDownData.map((CategorySummaryFeatureTrendPositiveDrillDownData) => {
    return ({
      id: CategorySummaryFeatureTrendPositiveDrillDownData.id,
      topic: CategorySummaryFeatureTrendPositiveDrillDownData['WORD'],
      freq: CategorySummaryFeatureTrendPositiveDrillDownData['FREQ']
    })
  });
  
const CategorySummaryFeatureTrendPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Feature Trend -  Drill Down - Negative Wordcloud

const categorySummaryFeatureTrendNegativeDrillDownDataTopicWords = categorySummaryFeatureTrendNegativeDrillDownData.map((CategorySummaryFeatureTrendNegativeDrillDownData) => {
  return({
    text: CategorySummaryFeatureTrendNegativeDrillDownData['WORD'],
    value: CategorySummaryFeatureTrendNegativeDrillDownData['FREQ']
  })
})

const categorySummaryFeatureTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryFeatureTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryFeatureTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryFeatureTrendNegativeDrillDownDataTopicCallbacks}
    options={categorySummaryFeatureTrendNegativeDrillDownDataTopicOptions}
    words={categorySummaryFeatureTrendNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Feature Trend - Drill Down - Negative - using react-data-table-component

const categorySummaryFeatureTrendNegativeDrillDownDataTopicTableDataSet = categorySummaryFeatureTrendNegativeDrillDownData.map((CategorySummaryFeatureTrendNegativeDrillDownData) => {
    return ({
      id: CategorySummaryFeatureTrendNegativeDrillDownData.id,
      topic: CategorySummaryFeatureTrendNegativeDrillDownData['WORD'],
      freq: CategorySummaryFeatureTrendNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummaryFeatureTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Feature Trend -  Drill Down - View Individual Sentence - Table using material-table

const categorySummaryFeatureTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummaryFeatureTrendDrillDownDataTableDataSet = categorySummaryFeatureTrendDrillDownData.map((CategorySummaryFeatureTrendDrillDownData) => {
  return ({
    id: CategorySummaryFeatureTrendDrillDownData.id,
    date: CategorySummaryFeatureTrendDrillDownData.Date,
    website: CategorySummaryFeatureTrendDrillDownData.Website,
    country: CategorySummaryFeatureTrendDrillDownData['Country'],
    reviewID: CategorySummaryFeatureTrendDrillDownData['Reviewer_ID'],
    model: CategorySummaryFeatureTrendDrillDownData['Model'],
    stars: CategorySummaryFeatureTrendDrillDownData['Stars'],
    category: CategorySummaryFeatureTrendDrillDownData['Category'],
    topic: CategorySummaryFeatureTrendDrillDownData['Topic'],
    individualSentence: CategorySummaryFeatureTrendDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummaryFeatureTrendDrillDownData['Sentiment.Result'],
    posList: CategorySummaryFeatureTrendDrillDownData['Pos_List'],
    negList: CategorySummaryFeatureTrendDrillDownData['Neg_List']
  })
});





// Category Summary - Price Trend - Drill Down - Positive Wordcloud

const categorySummaryPriceTrendPositiveDrillDownDataTopicWords = categorySummaryPriceTrendPositiveDrillDownData.map((CategorySummaryPriceTrendPositiveDrillDownData) => {
  return({
    text: CategorySummaryPriceTrendPositiveDrillDownData['WORD'],
    value: CategorySummaryPriceTrendPositiveDrillDownData['FREQ']
  })
})

const categorySummaryPriceTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryPriceTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryPriceTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryPriceTrendPositiveDrillDownDataTopicCallbacks}
    options={categorySummaryPriceTrendPositiveDrillDownDataTopicOptions}
    words={categorySummaryPriceTrendPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Price Trend - Positive - Table using react-data-table-component

const categorySummaryPriceTrendPositiveDrillDownDataTopicTableDataSet = categorySummaryPriceTrendPositiveDrillDownData.map((CategorySummaryPriceTrendPositiveDrillDownData) => {
    return ({
      id: CategorySummaryPriceTrendPositiveDrillDownData.id,
      topic: CategorySummaryPriceTrendPositiveDrillDownData['WORD'],
      freq: CategorySummaryPriceTrendPositiveDrillDownData['FREQ']
    })
  });
  
const CategorySummaryPriceTrendPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Price Trend -  Drill Down - Negative Wordcloud

const categorySummaryPriceTrendNegativeDrillDownDataTopicWords = categorySummaryPriceTrendNegativeDrillDownData.map((CategorySummaryPriceTrendNegativeDrillDownData) => {
  return({
    text: CategorySummaryPriceTrendNegativeDrillDownData['WORD'],
    value: CategorySummaryPriceTrendNegativeDrillDownData['FREQ']
  })
})

const categorySummaryPriceTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummaryPriceTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummaryPriceTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummaryPriceTrendNegativeDrillDownDataTopicCallbacks}
    options={categorySummaryPriceTrendNegativeDrillDownDataTopicOptions}
    words={categorySummaryPriceTrendNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Price Trend - Drill Down - Negative - using react-data-table-component

const categorySummaryPriceTrendNegativeDrillDownDataTopicTableDataSet = categorySummaryPriceTrendNegativeDrillDownData.map((CategorySummaryPriceTrendNegativeDrillDownData) => {
    return ({
      id: CategorySummaryPriceTrendNegativeDrillDownData.id,
      topic: CategorySummaryPriceTrendNegativeDrillDownData['WORD'],
      freq: CategorySummaryPriceTrendNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummaryPriceTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Price Trend -  Drill Down - View Individual Sentence - Table using material-table

const categorySummaryPriceTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummaryPriceTrendDrillDownDataTableDataSet = categorySummaryPriceTrendDrillDownData.map((CategorySummaryPriceTrendDrillDownData) => {
  return ({
    id: CategorySummaryPriceTrendDrillDownData.id,
    date: CategorySummaryPriceTrendDrillDownData.Date,
    website: CategorySummaryPriceTrendDrillDownData.Website,
    country: CategorySummaryPriceTrendDrillDownData['Country'],
    reviewID: CategorySummaryPriceTrendDrillDownData['Reviewer_ID'],
    model: CategorySummaryPriceTrendDrillDownData['Model'],
    stars: CategorySummaryPriceTrendDrillDownData['Stars'],
    category: CategorySummaryPriceTrendDrillDownData['Category'],
    topic: CategorySummaryPriceTrendDrillDownData['Topic'],
    individualSentence: CategorySummaryPriceTrendDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummaryPriceTrendDrillDownData['Sentiment.Result'],
    posList: CategorySummaryPriceTrendDrillDownData['Pos_List'],
    negList: CategorySummaryPriceTrendDrillDownData['Neg_List']
  })
});




// Category Summary - Sound Trend - Drill Down - Positive Wordcloud

const categorySummarySoundTrendPositiveDrillDownDataTopicWords = categorySummarySoundTrendPositiveDrillDownData.map((CategorySummarySoundTrendPositiveDrillDownData) => {
  return({
    text: CategorySummarySoundTrendPositiveDrillDownData['WORD'],
    value: CategorySummarySoundTrendPositiveDrillDownData['FREQ']
  })
})

const categorySummarySoundTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummarySoundTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummarySoundTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummarySoundTrendPositiveDrillDownDataTopicCallbacks}
    options={categorySummarySoundTrendPositiveDrillDownDataTopicOptions}
    words={categorySummarySoundTrendPositiveDrillDownDataTopicWords} 
  />
}


// Category Summary - Sound Trend - Positive - Table using react-data-table-component

const categorySummarySoundTrendPositiveDrillDownDataTopicTableDataSet = categorySummarySoundTrendPositiveDrillDownData.map((CategorySummarySoundTrendPositiveDrillDownData) => {
    return ({
      id: CategorySummarySoundTrendPositiveDrillDownData.id,
      topic: CategorySummarySoundTrendPositiveDrillDownData['WORD'],
      freq: CategorySummarySoundTrendPositiveDrillDownData['FREQ']
    })
  });
  
const CategorySummarySoundTrendPositiveDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Category Summary - Sound Trend -  Drill Down - Negative Wordcloud

const categorySummarySoundTrendNegativeDrillDownDataTopicWords = categorySummarySoundTrendNegativeDrillDownData.map((CategorySummarySoundTrendNegativeDrillDownData) => {
  return({
    text: CategorySummarySoundTrendNegativeDrillDownData['WORD'],
    value: CategorySummarySoundTrendNegativeDrillDownData['FREQ']
  })
})

const categorySummarySoundTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categorySummarySoundTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categorySummarySoundTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categorySummarySoundTrendNegativeDrillDownDataTopicCallbacks}
    options={categorySummarySoundTrendNegativeDrillDownDataTopicOptions}
    words={categorySummarySoundTrendNegativeDrillDownDataTopicWords} 
  />
}


// Category Summary - Sound Trend - Drill Down - Negative - using react-data-table-component

const categorySummarySoundTrendNegativeDrillDownDataTopicTableDataSet = categorySummarySoundTrendNegativeDrillDownData.map((CategorySummarySoundTrendNegativeDrillDownData) => {
    return ({
      id: CategorySummarySoundTrendNegativeDrillDownData.id,
      topic: CategorySummarySoundTrendNegativeDrillDownData['WORD'],
      freq: CategorySummarySoundTrendNegativeDrillDownData['FREQ']
    })
  });
  
const categorySummarySoundTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Category Summary - Sound Trend -  Drill Down - View Individual Sentence - Table using material-table

const categorySummarySoundTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categorySummarySoundTrendDrillDownDataTableDataSet = categorySummarySoundTrendDrillDownData.map((CategorySummarySoundTrendDrillDownData) => {
  return ({
    id: CategorySummarySoundTrendDrillDownData.id,
    date: CategorySummarySoundTrendDrillDownData.Date,
    website: CategorySummarySoundTrendDrillDownData.Website,
    country: CategorySummarySoundTrendDrillDownData['Country'],
    reviewID: CategorySummarySoundTrendDrillDownData['Reviewer_ID'],
    model: CategorySummarySoundTrendDrillDownData['Model'],
    stars: CategorySummarySoundTrendDrillDownData['Stars'],
    category: CategorySummarySoundTrendDrillDownData['Category'],
    topic: CategorySummarySoundTrendDrillDownData['Topic'],
    individualSentence: CategorySummarySoundTrendDrillDownData['Individual.Sentence'],
    sentimentResult: CategorySummarySoundTrendDrillDownData['Sentiment.Result'],
    posList: CategorySummarySoundTrendDrillDownData['Pos_List'],
    negList: CategorySummarySoundTrendDrillDownData['Neg_List']
  })
});



// Topic Overview - Pie - Drill Down - Positive Wordcloud

const categoryOverviewTopicSentimentPositiveDrillDownDataTopicWords = categoryOverviewTopicSentimentPositiveDrillDownData.map((CategoryOverviewTopicSentimentPositiveDrillDownData) => {
  return({
    text: CategoryOverviewTopicSentimentPositiveDrillDownData['WORD'],
    value: CategoryOverviewTopicSentimentPositiveDrillDownData['FREQ']
  })
})

const categoryOverviewTopicSentimentPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryOverviewTopicSentimentPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryOverviewTopicSentimentPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryOverviewTopicSentimentPositiveDrillDownDataTopicCallbacks}
    options={categoryOverviewTopicSentimentPositiveDrillDownDataTopicOptions}
    words={categoryOverviewTopicSentimentPositiveDrillDownDataTopicWords} 
  />
}


// Topic Overview - Pie - Drill Down - Positive - Table using react-data-table-component

const categoryOverviewTopicSentimentPositiveDrillDownDataTableDataSet = categoryOverviewTopicSentimentPositiveDrillDownData.map((CategoryOverviewTopicSentimentPositiveDrillDownData) => {
    return ({
      id: CategoryOverviewTopicSentimentPositiveDrillDownData.id,
      topic: CategoryOverviewTopicSentimentPositiveDrillDownData['WORD'],
      freq: CategoryOverviewTopicSentimentPositiveDrillDownData['FREQ']
    })
  });
  
const categoryOverviewTopicSentimentPositiveDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Overview - Pie - Drill Down - Negative Wordcloud

const categoryOverviewTopicSentimentNegativeDrillDownDataTopicWords = categoryOverviewTopicSentimentNegativeDrillDownData.map((CategoryOverviewTopicSentimentNegativeDrillDownData) => {
  return({
    text: CategoryOverviewTopicSentimentNegativeDrillDownData['WORD'],
    value: CategoryOverviewTopicSentimentNegativeDrillDownData['FREQ']
  })
})

const categoryOverviewTopicSentimentNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryOverviewTopicSentimentNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryOverviewTopicSentimentNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryOverviewTopicSentimentNegativeDrillDownDataTopicCallbacks}
    options={categoryOverviewTopicSentimentNegativeDrillDownDataTopicOptions}
    words={categoryOverviewTopicSentimentNegativeDrillDownDataTopicWords} 
  />
}


// Topic Overview - Pie - Drill Down - Negative - using react-data-table-component

const categoryOverviewTopicSentimentNegativeDrillDownDataTopicTableDataSet = categoryOverviewTopicSentimentNegativeDrillDownData.map((CategoryOverviewTopicSentimentNegativeDrillDownData) => {
    return ({
      id: CategoryOverviewTopicSentimentNegativeDrillDownData.id,
      topic: CategoryOverviewTopicSentimentNegativeDrillDownData['WORD'],
      freq: CategoryOverviewTopicSentimentNegativeDrillDownData['FREQ']
    })
  });
  
const categoryOverviewTopicSentimentNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Overview - Pie - Drill Down - View Individual Sentence - Table using material-table

const categoryOverviewTopicSentimentDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categoryOverviewTopicSentimentDrillDownDataTableDataSet = categoryOverviewTopicSentimentDrillDownData.map((CategoryOverviewTopicSentimentDrillDownData) => {
  return ({
    id: CategoryOverviewTopicSentimentDrillDownData.id,
    date: CategoryOverviewTopicSentimentDrillDownData.Date,
    website: CategoryOverviewTopicSentimentDrillDownData.Website,
    country: CategoryOverviewTopicSentimentDrillDownData['Country'],
    reviewID: CategoryOverviewTopicSentimentDrillDownData['Reviewer_ID'],
    model: CategoryOverviewTopicSentimentDrillDownData['Model'],
    stars: CategoryOverviewTopicSentimentDrillDownData['Stars'],
    category: CategoryOverviewTopicSentimentDrillDownData['Category'],
    topic: CategoryOverviewTopicSentimentDrillDownData['Topic'],
    individualSentence: CategoryOverviewTopicSentimentDrillDownData['Individual.Sentence'],
    sentimentResult: CategoryOverviewTopicSentimentDrillDownData['Sentiment.Result'],
    posList: CategoryOverviewTopicSentimentDrillDownData['Pos_List'],
    negList: CategoryOverviewTopicSentimentDrillDownData['Neg_List']
  })
});




// Topic Overview - Topic Comparison Positive - Drill Down - Positive Wordcloud

const categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTopicWords = categoryOverviewTopicComparisonSentimentPositiveDrillDownData.map((CategoryOverviewTopicComparisonSentimentPositiveDrillDownData) => {
  return({
    text: CategoryOverviewTopicComparisonSentimentPositiveDrillDownData['WORD'],
    value: CategoryOverviewTopicComparisonSentimentPositiveDrillDownData['FREQ']
  })
})

const categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryOverviewTopicComparisonSentimentPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTopicCallbacks}
    options={categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTopicOptions}
    words={categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTopicWords} 
  />
}


// Topic Overview - Topic Comparison Positive  - Drill Down - Positive - Table using react-data-table-component

const categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTableDataSet = categoryOverviewTopicComparisonSentimentPositiveDrillDownData.map((CategoryOverviewTopicComparisonSentimentPositiveDrillDownData) => {
    return ({
      id: CategoryOverviewTopicComparisonSentimentPositiveDrillDownData.id,
      topic: CategoryOverviewTopicComparisonSentimentPositiveDrillDownData['WORD'],
      freq: CategoryOverviewTopicComparisonSentimentPositiveDrillDownData['FREQ']
    })
  });
  
const categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Overview - Topic Comparison Positive  - Drill Down - Negative Wordcloud

const categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicWords = categoryOverviewTopicComparisonSentimentNegativeDrillDownData.map((CategoryOverviewTopicComparisonSentimentNegativeDrillDownData) => {
  return({
    text: CategoryOverviewTopicComparisonSentimentNegativeDrillDownData['WORD'],
    value: CategoryOverviewTopicComparisonSentimentNegativeDrillDownData['FREQ']
  })
})

const categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryOverviewTopicComparisonSentimentNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicCallbacks}
    options={categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicOptions}
    words={categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicWords} 
  />
}


// Topic Overview - Topic Comparison Positive  - Drill Down - Negative - using react-data-table-component

const categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicTableDataSet = categoryOverviewTopicComparisonSentimentNegativeDrillDownData.map((CategoryOverviewTopicComparisonSentimentNegativeDrillDownData) => {
    return ({
      id: CategoryOverviewTopicComparisonSentimentNegativeDrillDownData.id,
      topic: CategoryOverviewTopicComparisonSentimentNegativeDrillDownData['WORD'],
      freq: CategoryOverviewTopicComparisonSentimentNegativeDrillDownData['FREQ']
    })
  });
  
const categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Overview - Topic Comparison Positive  - Drill Down - View Individual Sentence - Table using material-table

const categoryOverviewTopicComparisonSentimentDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categoryOverviewTopicComparisonSentimentDrillDownDataTableDataSet = categoryOverviewTopicComparisonSentimentDrillDownData.map((CategoryOverviewTopicComparisonSentimentDrillDownData) => {
  return ({
    id: CategoryOverviewTopicComparisonSentimentDrillDownData.id,
    date: CategoryOverviewTopicComparisonSentimentDrillDownData.Date,
    website: CategoryOverviewTopicComparisonSentimentDrillDownData.Website,
    country: CategoryOverviewTopicComparisonSentimentDrillDownData['Country'],
    reviewID: CategoryOverviewTopicComparisonSentimentDrillDownData['Reviewer_ID'],
    model: CategoryOverviewTopicComparisonSentimentDrillDownData['Model'],
    stars: CategoryOverviewTopicComparisonSentimentDrillDownData['Stars'],
    category: CategoryOverviewTopicComparisonSentimentDrillDownData['Category'],
    topic: CategoryOverviewTopicComparisonSentimentDrillDownData['Topic'],
    individualSentence: CategoryOverviewTopicComparisonSentimentDrillDownData['Individual.Sentence'],
    sentimentResult: CategoryOverviewTopicComparisonSentimentDrillDownData['Sentiment.Result'],
    posList: CategoryOverviewTopicComparisonSentimentDrillDownData['Pos_List'],
    negList: CategoryOverviewTopicComparisonSentimentDrillDownData['Neg_List']
  })
});




// Topic Overview - Topic Comparison Negative - Drill Down - Negative Wordcloud

const categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTopicWords = categoryOverviewTopicComparisonSentimentPositiveNegDrillDownData.map((CategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData) => {
  return({
    text: CategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData['WORD'],
    value: CategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData['FREQ']
  })
})

const categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTopicCallbacks}
    options={categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTopicOptions}
    words={categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTopicWords} 
  />
}


// Topic Overview - Topic Comparison Negative  - Drill Down - Negative - Table using react-data-table-component

const categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTableDataSet = categoryOverviewTopicComparisonSentimentPositiveNegDrillDownData.map((CategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData) => {
    return ({
      id: CategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData.id,
      topic: CategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData['WORD'],
      freq: CategoryOverviewTopicComparisonSentimentPositiveNegDrillDownData['FREQ']
    })
  });
  
const categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Overview - Topic Comparison Negative  - Drill Down - Negative Wordcloud

const categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicWords = categoryOverviewTopicComparisonSentimentNegativeNegDrillDownData.map((CategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData) => {
  return({
    text: CategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData['WORD'],
    value: CategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData['FREQ']
  })
})

const categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicCallbacks}
    options={categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicOptions}
    words={categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicWords} 
  />
}


// Topic Overview - Topic Comparison Negative  - Drill Down - Negative - using react-data-table-component

const categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicTableDataSet = categoryOverviewTopicComparisonSentimentNegativeNegDrillDownData.map((CategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData) => {
    return ({
      id: CategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData.id,
      topic: CategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData['WORD'],
      freq: CategoryOverviewTopicComparisonSentimentNegativeNegDrillDownData['FREQ']
    })
  });
  
const categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Overview - Topic Comparison Negative  - Drill Down - View Individual Sentence - Table using material-table

const categoryOverviewTopicComparisonSentimentNegDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const categoryOverviewTopicComparisonSentimentNegDrillDownDataTableDataSet = categoryOverviewTopicComparisonSentimentNegDrillDownData.map((CategoryOverviewTopicComparisonSentimentNegDrillDownData) => {
  return ({
    id: CategoryOverviewTopicComparisonSentimentNegDrillDownData.id,
    date: CategoryOverviewTopicComparisonSentimentNegDrillDownData.Date,
    website: CategoryOverviewTopicComparisonSentimentNegDrillDownData.Website,
    country: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Country'],
    reviewID: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Reviewer_ID'],
    model: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Model'],
    stars: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Stars'],
    category: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Category'],
    topic: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Topic'],
    individualSentence: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Individual.Sentence'],
    sentimentResult: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Sentiment.Result'],
    posList: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Pos_List'],
    negList: CategoryOverviewTopicComparisonSentimentNegDrillDownData['Neg_List']
  })
});





// Topic Insight - Topic Trend - Drill Down - Positive Wordcloud

const topicInsightTrendPositiveDrillDownDataTopicWords = topicInsightTrendPositiveDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicInsightTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicInsightTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicInsightTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicInsightTrendPositiveDrillDownDataTopicCallbacks}
    options={topicInsightTrendPositiveDrillDownDataTopicOptions}
    words={topicInsightTrendPositiveDrillDownDataTopicWords} 
  />
}


// Topic Insight - Topic Trend  - Drill Down - Table using react-data-table-component

const topicInsightTrendPositiveDrillDownDataTableDataSet = topicInsightTrendPositiveDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicInsightTrendPositiveDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Insight - Topic Trend - Drill Down - Negative Wordcloud

const topicInsightTrendNegativeDrillDownDataTopicWords = topicInsightTrendNegativeDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicInsightTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicInsightTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicInsightTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicInsightTrendNegativeDrillDownDataTopicCallbacks}
    options={topicInsightTrendNegativeDrillDownDataTopicOptions}
    words={topicInsightTrendNegativeDrillDownDataTopicWords} 
  />
}


// Topic Insight - Topic Trend  - Drill Down Negative - using react-data-table-component

const topicInsightTrendNegativeDrillDownDataTopicTableDataSet = topicInsightTrendNegativeDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicInsightTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Insight - Topic Trend  - Drill Down - View Individual Sentence - Table using material-table

const topicInsightTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicInsightTrendDrillDownDataTableDataSet = topicInsightTrendDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




// Topic Insight - Topic Phrase - Drill Down - Positive Wordcloud

const topicInsightTopicPhrasedPositiveDrillDownDataTopicWords = topicInsightTopicPhrasedPositiveDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicInsightTopicPhrasedPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicInsightTopicPhrasedPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicInsightTopicPhrasedPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicInsightTopicPhrasedPositiveDrillDownDataTopicCallbacks}
    options={topicInsightTopicPhrasedPositiveDrillDownDataTopicOptions}
    words={topicInsightTopicPhrasedPositiveDrillDownDataTopicWords} 
  />
}


// Topic Insight - Topic Phrase  - Drill Down - Table using react-data-table-component

const topicInsightTopicPhrasedPositiveDrillDownDataTableDataSet = topicInsightTopicPhrasedPositiveDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicInsightTopicPhrasePositiveDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Insight - Topic Phrase - Drill Down - Negative Wordcloud

const topicInsightTopicPhraseNegativeDrillDownDataTopicWords = topicInsightTopicPhraseNegativeDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicInsightTopicPhraseNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicInsightTopicPhraseNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicInsightTopicPhraseNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicInsightTopicPhraseNegativeDrillDownDataTopicCallbacks}
    options={topicInsightTopicPhraseNegativeDrillDownDataTopicOptions}
    words={topicInsightTopicPhraseNegativeDrillDownDataTopicWords} 
  />
}


// Topic Insight - Topic Phrase  - Drill Down Negative - using react-data-table-component

const topicInsightTopicPhraseNegativeDrillDownDataTopicTableDataSet = topicInsightTopicPhraseNegativeDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicInsightTopicPhraseNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Insight - Topic Phrase  - Drill Down - View Individual Sentence - Table using material-table

const topicInsightTopicPhraseDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicInsightTopicPhraseDrillDownDataTableDataSet = topicInsightTopicPhraseDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




// Topic Insight -  Positive Sentiment Word  - Drill Down - View Individual Sentence - Table using material-table

const topicInsightSentimentWordDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicInsightSentimentWordDrillDownDataTableDataSet = topicInsightSentimentWordDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});



// Topic Insight - Negative Sentiment Word  - Drill Down - View Individual Sentence - Table using material-table

const topicInsightSentimentWordDrillDownDataNegTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicInsightSentimentWordDrillDownDataNegTableDataSet = topicInsightSentimentWordDrillDownDataNeg.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




// Topic Search - Trend - Drill Down - Positive Wordcloud

const topicSearchTrendPositiveDrillDownDataTopicWords = topicSearchTrendPositiveDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicSearchTrendPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicSearchTrendPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicSearchTrendPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicSearchTrendPositiveDrillDownDataTopicCallbacks}
    options={topicSearchTrendPositiveDrillDownDataTopicOptions}
    words={topicSearchTrendPositiveDrillDownDataTopicWords} 
  />
}


// Topic Search - Trend - Drill Down - Table using react-data-table-component

const topicSearchTrendPositiveDrillDownDataTableDataSet = topicSearchTrendPositiveDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicSearchTrendPositiveDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Search - Trend - Drill Down - Negative Wordcloud

const topicSearchTrendNegativeDrillDownDataTopicWords = topicSearchTrendNegativeDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicSearchTrendNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicSearchTrendNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicSearchTrendNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicSearchTrendNegativeDrillDownDataTopicCallbacks}
    options={topicSearchTrendNegativeDrillDownDataTopicOptions}
    words={topicSearchTrendNegativeDrillDownDataTopicWords} 
  />
}


// Topic Search - Trend - Drill Down Negative - using react-data-table-component

const topicSearchTrendNegativeDrillDownDataTopicTableDataSet = topicSearchTrendNegativeDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicSearchTrendNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Search - Trend - Drill Down - View Individual Sentence - Table using material-table

const topicSearchTrendDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicSearchTrendDrillDownDataTableDataSet = topicSearchTrendDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




// Topic Search - Topic Breakdown - Drill Down - Positive Wordcloud

const topicSearchTopicBreakdownPositiveDrillDownDataTopicWords = topicSearchTopicBreakdownPositiveDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicSearchTopicBreakdownPositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicSearchTopicBreakdownPositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicSearchTopicBreakdownPositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicSearchTopicBreakdownPositiveDrillDownDataTopicCallbacks}
    options={topicSearchTopicBreakdownPositiveDrillDownDataTopicOptions}
    words={topicSearchTopicBreakdownPositiveDrillDownDataTopicWords} 
  />
}


// Topic Search - Topic Breakdown - Drill Down - Table using react-data-table-component

const topicSearchTopicBreakdownPositiveDrillDownDataTableDataSet = topicSearchTopicBreakdownPositiveDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicSearchTopicBreakdownPositiveDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Search - Topic Breakdown - Drill Down - Negative Wordcloud

const topicSearchTopicBreakdownNegativeDrillDownDataTopicWords = topicSearchTopicBreakdownNegativeDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicSearchTopicBreakdownNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicSearchTopicBreakdownNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicSearchTopicBreakdownNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicSearchTopicBreakdownNegativeDrillDownDataTopicCallbacks}
    options={topicSearchTopicBreakdownNegativeDrillDownDataTopicOptions}
    words={topicSearchTopicBreakdownNegativeDrillDownDataTopicWords} 
  />
}


// Topic Search - Topic Breakdown - Drill Down Negative - using react-data-table-component

const topicSearchTopicBreakdownNegativeDrillDownDataTopicTableDataSet = topicSearchTopicBreakdownNegativeDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicSearchTopicBreakdownNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Search - Topic Breakdown - Drill Down - View Individual Sentence - Table using material-table

const topicSearchTopicBreakdowndDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicSearchTopicBreakdowndDrillDownDataTableDataSet = topicSearchTopicBreakdowndDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




// Topic Search - Topic Combine - Drill Down - Positive Wordcloud

const topicSearchTopicCombinePositiveDrillDownDataTopicWords = topicSearchTopicCombinePositiveDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicSearchTopicCombinePositiveDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicSearchTopicCombinePositiveDrillDownDataTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicSearchTopicCombinePositiveDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicSearchTopicCombinePositiveDrillDownDataTopicCallbacks}
    options={topicSearchTopicCombinePositiveDrillDownDataTopicOptions}
    words={topicSearchTopicCombinePositiveDrillDownDataTopicWords} 
  />
}


// Topic Search - Topic Combine - Drill Down - Table using react-data-table-component

const topicSearchTopicCombinePositiveDrillDownDataTableDataSet = topicSearchTopicCombinePositiveDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicSearchTopicCombinePositiveDrillDownDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Search - Topic Combine - Drill Down - Negative Wordcloud

const topicSearchTopicCombineNegativeDrillDownDataTopicWords = topicSearchTopicCombineNegativeDrillDownData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const topicSearchTopicCombineNegativeDrillDownDataTopicCallbacks = {
  //onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicSearchTopicCombineNegativeDrillDownDataTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicSearchTopicCombineNegativeDrillDownDataWordCloud() {
  return <ReactWordcloud
    callbacks={topicSearchTopicCombineNegativeDrillDownDataTopicCallbacks}
    options={topicSearchTopicCombineNegativeDrillDownDataTopicOptions}
    words={topicSearchTopicCombineNegativeDrillDownDataTopicWords} 
  />
}


// Topic Search - Topic Combine - Drill Down Negative - using react-data-table-component

const topicSearchTopicCombineNegativeDrillDownDataTopicTableDataSet = topicSearchTopicCombineNegativeDrillDownData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const topicSearchTopicCombineNegativeDrillDownDataTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Search - Topic Combine - Drill Down - View Individual Sentence - Table using material-table

const topicSearchTopicCombineDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicSearchTopicCombineDrillDownDataTableDataSet = topicSearchTopicCombineDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});



// Topic Search - Positive Sentiment  - Drill Down - View Individual Sentence - Table using material-table

const topicSearchTopicPositiveSentimentDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicSearchTopicPositiveSentimentDrillDownDataTableDataSet = topicSearchTopicPositiveSentimentDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});




// Topic Search - Negative Sentiment Word  - Drill Down - View Individual Sentence - Table using material-table

const topicSearchTopicNegativeSentimentDrillDownDataTableColumn = [
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  }
];



const topicSearchTopicNegativeSentimentDrillDownDataTableDataSet = topicSearchTopicNegativeSentimentDrillDownData.map((data) => {
  return ({
    id: data.id,
    date: data.Date,
    website: data.Website,
    country: data['Country'],
    reviewID: data['Reviewer_ID'],
    model: data['Model'],
    stars: data['Stars'],
    category: data['Category'],
    topic: data['Topic'],
    individualSentence: data['Individual.Sentence'],
    sentimentResult: data['Sentiment.Result'],
    posList: data['Pos_List'],
    negList: data['Neg_List']
  })
});



// All Drill Down - End


const getUniqueTrendDataCustom = async (trendFilterSelection) => {
  // Unique Trend Data
  setStarRatingTrendUniqueIsLoading(true);
  var restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&trendFilter="}${trendFilterSelection}`;
  restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
  restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

try {
  const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].uniqueTrend}${restGETurl1}`);

  let trendDataApi = resp.data.uniqueTrendData;
  let trendDataPercentApi = resp.data.uniqueTrendDataPercentCum;

  if(trendFilterSelection === "month" | trendFilterSelection === "day"){
    //trendDataApi.pop()
    //trendDataPercentApi.pop()
  }

  setUniqueTrendData(trendDataApi);
  setUniqueTrendDataPercentCum(trendDataPercentApi);
  //setUniqueTrendStarRatingAvg(resp.data.starRating);
  //setUniqueTrendStarColor(resp.data.color);

  setStarRatingTrendUniqueIsLoading(false);
  //setProgress(prevCount => prevCount + 10);

} catch (err) {
    // Handle Error Here
    console.error(err);
    setUniqueTrendData([]);
    setUniqueTrendDataPercentCum([]);
    setUniqueTrendStarRatingAvg(0);
    setUniqueTrendStarColor('info');

    setStarRatingTrendUniqueIsLoading(false);
    //setProgress(prevCount => prevCount + 10);
}


};

const onClickUniqueTrendFilter = data => () => {
  setUniqueTrendFilter(data);
  getUniqueTrendDataCustom(data);

}




const getTrendDataCustom = async (trendFilterSelection) => {
  // Trend Data
  setStarRatingTrendUIsLoading(true);
  var restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&trendFilter="}${trendFilterSelection}`;
  restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
  restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

  try {
    const resp = await axios.get(`${SMATrestAPI.starRatingAnalytic[0].smatTrend}${restGETurl2}`);

    setSmatTrendData(resp.data.smatTrendData);
    setSmatTrendDataPercentCum(resp.data.smatTrendDataPercentCum);
    //setSmatTrendStarRatingAvg(resp.data.starRating);
    //setSmatTrendStarColor(resp.data.color);

    setStarRatingTrendUIsLoading(false);
    //setProgress(prevCount => prevCount + 10);

  } catch (err) {
      // Handle Error Here
      console.error(err);
      setStarRatingTrendUIsLoading(false);
      //setProgress(prevCount => prevCount + 10);
  }


};



const onClickTrendFilter = data => () => {
  setSmatTrendFilter(data);
  getTrendDataCustom(data);

}

const handleTopicOverviewStarSelection = (data) => {

  switch(data) {

    case "fiveStar":
      setTopicOverviewcustomStarSelection((prevState) => {
        return({
          ...prevState,
          fiveStar: !topicOverviewcustomStarSelection.fiveStar
        });
      });
      break;

    case "fourStar":
      setTopicOverviewcustomStarSelection((prevState) => {
        return({
          ...prevState,
          fourStar: !topicOverviewcustomStarSelection.fourStar
        });
      });
      break;

      case "threeStar":
      setTopicOverviewcustomStarSelection((prevState) => {
        return({
          ...prevState,
          threeStar: !topicOverviewcustomStarSelection.threeStar
        });
      });
      break;

      case "twoStar":
      setTopicOverviewcustomStarSelection((prevState) => {
        return({
          ...prevState,
          twoStar: !topicOverviewcustomStarSelection.twoStar
        });
      });
      break;

      case "oneStar":
      setTopicOverviewcustomStarSelection((prevState) => {
        return({
          ...prevState,
          oneStar: !topicOverviewcustomStarSelection.oneStar
        });
      });
      break;

      case "updateChart":

        getTopicOverviewDataFilter();

      break;
    default:


      
  }



  // console.log(data);


 }

 const handleTopicInsightStarSelection1 = (data) => {

  switch(data) {

    case "fiveStar":
      setTopicInsightcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          fiveStar: !topicInsightcustomStarSelection1.fiveStar
        });
      });
      break;

    case "fourStar":
      setTopicInsightcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          fourStar: !topicInsightcustomStarSelection1.fourStar
        });
      });
      break;

      case "threeStar":
        setTopicInsightcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          threeStar: !topicInsightcustomStarSelection1.threeStar
        });
      });
      break;

      case "twoStar":
        setTopicInsightcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          twoStar: !topicInsightcustomStarSelection1.twoStar
        });
      });
      break;

      case "oneStar":
        setTopicInsightcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          oneStar: !topicInsightcustomStarSelection1.oneStar
        });
      });
      break;

      case "updateChart":

        getTopicInsightData2Filter();

      break;
    default:


      
  }



  // console.log(data);


 }



 const handleTopicSearchStarSelection1 = (data) => {

  switch(data) {

    case "fiveStar":
      setTopicSearchcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          fiveStar: !topicSearchcustomStarSelection1.fiveStar
        });
      });
      break;

    case "fourStar":
      setTopicSearchcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          fourStar: !topicSearchcustomStarSelection1.fourStar
        });
      });
      break;

      case "threeStar":
        setTopicSearchcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          threeStar: !topicSearchcustomStarSelection1.threeStar
        });
      });
      break;

      case "twoStar":
        setTopicSearchcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          twoStar: !topicSearchcustomStarSelection1.twoStar
        });
      });
      break;

      case "oneStar":
        setTopicSearchcustomStarSelection1((prevState) => {
        return({
          ...prevState,
          oneStar: !topicSearchcustomStarSelection1.oneStar
        });
      });
      break;

      case "updateChart":

        getTopicSearchDataFilter();

      break;
    default:


      
  }



  // console.log(data);


 }


  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();
  
  return (
    (!progressAll ?  
    <div>

      <div id="pageLoader" className={loadPage1}></div>

      <div id="mainPage" style={{display:loadPage2}}>

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BrandIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Brand</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="brand-select"
                  className={
                    classes.selectLabel
                }>
                  Select Brand
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={brandSelection}
                  onChange={handleBrand}
                  inputProps={
                    {
                      name: "brandSelection",
                      id: "brand-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  brandItems.map((brandItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={brandItems}>
                      {brandItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection}
                  onChange={handleCategory}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems.map((categoryItems, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={categoryItems}>
                        {categoryItems} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                {/* <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel> */}

                {/* <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection}
                  onChange={handleModel}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems.map((modelItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={modelItems}>
                      {modelItems} </MenuItem>
                  ))
                } </Select> */}

              <Autocomplete
                    multiple
                    limitTags={3}
                    disableListWrap
                    fullWidth
                    size="small"
                    id="model-select"
                    disableCloseOnSelect
                    onChange={handleModel}
                    value={modelSelection}
                    inputValue={inputValue}
                    options={modelItems}
                    getOptionLabel={(option) => option ? option : ""}
                    groupBy={() => 'MULTIPLE SELECTION (10 Max)'}
                    style={{ width: "100%"}}
                    ChipProps={{}}
                    classes={{paper: classes.selectLabel}}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => 
                                        <TextField 
                                        {...params} 
                                        onChange={(e) => setInputValue(e.target.value)}
                                        onBlur={() => setInputValue('')}
                                        label="SELECT OR SEARCH MODEL" 
                                        margin="normal"
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                          className: classes.selectLabel
                                         
                                        }}
                                        style={{bottom: "10px"}}
                                        />

                                        
                                      }
                    
                  />
                  

              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <WebsiteIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Website</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="website-select"
                  className={
                    classes.selectLabel
                }>
                  Select Website
                </InputLabel>

                <Select 
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper }
                  }}
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={websiteSelection}
                  onChange={handleWebsite}
                  inputProps={
                    {
                      name: "websiteSelection",
                      id: "website-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  websiteItems.map((websiteItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={websiteItems}>
                      {websiteItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {
                      className: classes.selectMenu,
                      classes: { paper: classes.selectPaper }
                    }
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection}
                  onChange={handleCountry}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems.map((countryItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={countryItems}>
                      {countryItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FilterIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Unique Topic</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>
              <div className={classes.block} style={{height:"75px"}}>
                      <br/>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={uniqueTopic}
                            onChange={event => setUniqueTopic(event.target.checked)}
                            value="uniqueTopic"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={uniqueTopic === true ? "ON" : "OFF (Recommended)"}
                      />
                    </div>
            </CardBody>
          </Card>

          </GridItem>


        <GridItem xs={12}
          sm={12}
          md={6}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody style={{height: '125px'}}>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select Start Date" }}
                    // value={dateStartSelection}
                    // onChange={handleDateStart}
                    // isValidDate={validStart}
                    // viewDate={moment(dateStartSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItem, 'YYYY-MM-DD')}
                    onChange={handleDateStart}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart } 
                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    // timeFormat={false}
                    // inputProps={{ placeholder: "Select End Date" }}
                    // value={dateEndSelection}
                    // onChange={handleDateEnd}
                    // isValidDate={validEnd}
                    // viewDate={moment(dateEndSelection, 'YYYY-MM-DD')}

                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItem, 'YYYY-MM-DD')}
                    onChange={handleDateEnd}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 

                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>


        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

              <Tooltip
                id="tooltip-bottom"
                title='Improve Model Selection Performance'
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >

                <Button color="info" onClick={clearPage}>
                  <ClearIcon className={classes.icons} />
                    Clear Page
                </Button>

              </Tooltip>


              <Tooltip
                id="tooltip-bottom"
                title='Click "Display Chart" First'
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <CSVLink data={rawData} filename={"Raw Data.csv"}>
                  <Button color="behance"> 
                    <DownloadIcon className={classes.icons} />
                      Download Raw
                  </Button>
                </CSVLink>

              </Tooltip>
            
            <Button color="pinterest" onClick={onClickChartData}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>

            
          </GridItem>

          </GridContainer>

          <br/>

{/* Weekly Trend */}
          <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Weekly Star Trend</b> </Button>
          </GridItem>

          </GridContainer>

          <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ArrowUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Highest Weekly Average</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {highWeeklyTrendData}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ArrowDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Lowest Weekly Average</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {lowWeeklyTrendData}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          </GridContainer>

          <GridContainer>

            <GridItem xs={12} sm={12} md={12} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="warning"
                    
                    tabs={[
                      {
                        tabName: "Weekly Star Trend",
                        tabIcon: TrendingUpIcon,
                        tabContent:
                          <div>
                            <Line 
                              height={60} data={weeklyTrendViewTrendDataSet}  
                              options={weeklyTrendViewTrendOptions} /> 
                          </div>

                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent:
                            <div>
                              <DataTable
                                title=""
                                columns={weeklyTrendTrendTableColumn}
                                data={weeklyTrendTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                              <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={weeklyTrendTableDataSet} filename={"Weekly Star Trend.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                            </GridContainer>

                          </div>


                        
                      }
                    ]}
                  /> 

            </GridItem>
          </GridContainer>


{/* Unique Trend */}
        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Star Rating Trend - Unique User</b> </Button>
          </GridItem>

        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>5-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {fiveStarData.reduce(function(a, b) { return a + b; }, 0)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <ThumpsUpIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>4-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {fourStarData.reduce(function(a, b) { return a + b; }, 0)}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <HandIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>3-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {threeStarData.reduce(function(a, b) { return a + b; }, 0)} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>2-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {twoStarData.reduce(function(a, b) { return a + b; }, 0)} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ThumpsDownIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>1-Star Count</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {oneStarData.reduce(function(a, b) { return a + b; }, 0)}  
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>
 
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={uniqueTrendStarColor} stats icon>
                <CardIcon color={uniqueTrendStarColor}>
                  <StarsIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Star Rating</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {uniqueTrendStarRatingAvg} 
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>
          
      </GridContainer>

      
      <GridContainer>

        <GridItem xs={12} sm={12} md={8} >
 
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
                
                tabs={[
                  {
                    tabName: "Cumulative Trend by Percent",
                    tabIcon: TrendingUpIcon,
                    tabContent: (!starRatingTrendUniqueIsLoading ? 
                      <div>
                            <GridContainer >
                              <GridItem 
                                  xs={6}
                                  container
                                  direction="row"
                                  justify="flex-start"
                                  alignItems="center">

                                <div className={classes.buttonGroup}>
                                  <Button disabled color="info" size="sm" className={classes.firstButton}>
                                    Filter:
                                  </Button>
                                  <Button color="white" size="sm" className={classes.firstButton} onClick={onClickUniqueTrendFilter("month")}>
                                    Month
                                  </Button>
                                  <Button color="white" size="sm" className={classes.middleButton} onClick={onClickUniqueTrendFilter("week")}>
                                    Week
                                  </Button>
                                  <Button color="white" size="sm" className={classes.lastButton} onClick={onClickUniqueTrendFilter("day")}>
                                    Day
                                  </Button>
                                </div>
                            
                              </GridItem>

                            </GridContainer>
                        <Line 
                          height={100} data={uniqueTrendViewTrendDataSet}  
                          options={uniqueTrendViewTrendOptions} /> 
                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!starRatingTrendUniqueIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={uniqueTrendTrendTableColumn}
                            data={uniqueTrendTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={uniqueTrendTrendTableDataSet} filename={"Unique Trend.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
  
                      </GridItem>
  
                    </GridContainer>
  
                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={4} >
 
            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="info"
                
                tabs={[
                  {
                    tabName: "Star Rating",
                    tabIcon: PieChartIcon,
                    tabContent: (!starRatingTrendUniqueIsLoading ? 
                      <div>
                        {/* <GridContainer >
                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton}>
                            Week
                          </Button>
                          <Button color="white" size="sm" className={classes.middleButton}>
                            Month
                          </Button>
                          <Button color="white" size="sm" className={classes.lastButton}>
                            Year
                          </Button>
                        </div>
                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton}>
                            Percent
                          </Button>
                          <Button color="white" size="sm" className={classes.middleButton}>
                            Value
                          </Button>
                        </div>

                          </GridItem>

                          </GridContainer>
                        <br/> */}
                        <Doughnut 
                          height={215} data={uniquePieViewTrendDataSet} options={uniquePieViewTrendOptions} plugins={[ChartDataLabels]}/> 

                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!starRatingTrendUniqueIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={uniqueTrendPieTableColumn}
                            data={uniqueTrendPieTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={uniqueTrendPieTableDataSet} filename={"Unique Pie.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>
  
                      </GridItem>
  
                    </GridContainer>
  
                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

        </GridItem>


    </GridContainer>


{/* Star Rating Trend */}
    <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Star Rating Trend - By Topic</b> </Button>
      </GridItem>

      </GridContainer>

      <GridContainer>
      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <ThumpsUpIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>5-Star Count</p>
            <h3 className={dashboardClasses.cardTitle}>
              {fiveStarDataSmat.reduce(function(a, b) { return a + b; }, 0)}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              <ThumpsUpIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>4-Star Count</p>
            <h3 className={dashboardClasses.cardTitle}>
              {fourStarDataSmat.reduce(function(a, b) { return a + b; }, 0)}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2} >
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              <HandIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>3-Star Count</p>
            <h3 className={dashboardClasses.cardTitle}>
              {threeStarDataSmat.reduce(function(a, b) { return a + b; }, 0)} 
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2} >
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <ThumpsDownIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>2-Star Count</p>
            <h3 className={dashboardClasses.cardTitle}>
              {twoStarDataSmat.reduce(function(a, b) { return a + b; }, 0)} 
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2} >
        <Card>
          <CardHeader color="danger" stats icon>
            <CardIcon color="danger">
              <ThumpsDownIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>1-Star Count</p>
            <h3 className={dashboardClasses.cardTitle}>
              {oneStarDataSmat.reduce(function(a, b) { return a + b; }, 0)}  
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color={smatTrendStarColor} stats icon>
            <CardIcon color={smatTrendStarColor}>
              <StarsIcon />
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>Star Rating</p>
            <h3 className={dashboardClasses.cardTitle}>
              {smatTrendStarRatingAvg} 
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      </GridContainer>


      <GridContainer>

      <GridItem xs={12} sm={12} md={8} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Cumulative Trend by Percent",
                tabIcon: TrendingUpIcon,
                tabContent: (!starRatingTrendUIsLoading ? 
                  <div>
                    <GridContainer >
                      <GridItem 
                          xs={6}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button disabled color="info" size="sm" className={classes.firstButton}>
                            Filter:
                          </Button>
                          <Button color="white" size="sm" className={classes.firstButton} onClick={onClickTrendFilter("month")}>
                            Month
                          </Button>
                          <Button color="white" size="sm" className={classes.middleButton} onClick={onClickTrendFilter("week")}>
                            Week
                          </Button>
                          <Button color="white" size="sm" className={classes.lastButton} onClick={onClickTrendFilter("day")}>
                            Day
                          </Button>
                        </div>
                    
                      </GridItem>

                    </GridContainer>
                    <Line 
                      height={100} data={smatTrendViewTrendDataSet}  
                      options={smatTrendViewTrendOptions} /> 
                  </div>
                

                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!starRatingTrendUIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={smatTrendTrendTableColumn}
                        data={smatTrendTrendTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={smatTrendTrendTableDataSet} filename={"SMAT Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

      </GridItem>

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="info"
            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (!starRatingTrendUIsLoading ? 
                  <div>
                    {/* <GridContainer >
                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center">

                    <div className={classes.buttonGroup}>
                      <Button color="white" size="sm" className={classes.firstButton}>
                        Week
                      </Button>
                      <Button color="white" size="sm" className={classes.middleButton}>
                        Month
                      </Button>
                      <Button color="white" size="sm" className={classes.lastButton}>
                        Year
                      </Button>
                    </div>
                    <div className={classes.buttonGroup}>
                      <Button color="white" size="sm" className={classes.firstButton}>
                        Percent
                      </Button>
                      <Button color="white" size="sm" className={classes.middleButton}>
                        Value
                      </Button>
                    </div>

                      </GridItem>

                      </GridContainer>
                    <br/> */}
                    <Doughnut 
                      height={215} data={smatTrendDataPieViewTrendDataSet} options={smatPieViewTrendOptions}  plugins={[ChartDataLabels]}/> 

                  </div>
                

                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!starRatingTrendUIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={smatTrendPieTableColumn}
                        data={smatTrendPieTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={smatTrendPieTableDataSet} filename={"SMAT Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

      </GridItem>


      </GridContainer>

{/* Category Summary  */}
      <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Category Summary</b> </Button>
      </GridItem>

      </GridContainer>


      <GridContainer>
      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color={categoryColor[0]} stats icon>
            <CardIcon color={categoryColor[0]}>
              {IconSelectionCategorySummaryInfo1()}
            </CardIcon>
            <p className={dashboardClasses.cardCategory}> {categoryLabel[0]} </p>
            <h3 className={dashboardClasses.cardTitle}>
              {categoryRating[0]}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color={categoryColor[1]} stats icon>
            <CardIcon color={categoryColor[1]}>
              {IconSelectionCategorySummaryInfo2()}
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>{categoryLabel[1]}</p>
            <h3 className={dashboardClasses.cardTitle}>
              {categoryRating[1]}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color={categoryColor[2]} stats icon>
            <CardIcon color={categoryColor[2]}>
              {IconSelectionCategorySummaryInfo3()}
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>{categoryLabel[2]}</p>
            <h3 className={dashboardClasses.cardTitle}>
            {categoryRating[2]}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color={categoryColor[3]} stats icon>
            <CardIcon color={categoryColor[3]}>
            {IconSelectionCategorySummaryInfo4()}
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>{categoryLabel[3]}</p>
            <h3 className={dashboardClasses.cardTitle}>
            {categoryRating[3]}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color={categoryColor[4]} stats icon>
            <CardIcon color={categoryColor[4]}>
            {IconSelectionCategorySummaryInfo5()}
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>{categoryLabel[4]}</p>
            <h3 className={dashboardClasses.cardTitle}>
              {categoryRating[4]}
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={6} md={2}>
        <Card>
          <CardHeader color={categoryColor[5]} stats icon>
            <CardIcon color={categoryColor[5]}>
              {IconSelectionCategorySummaryInfo6()}
            </CardIcon>
            <p className={dashboardClasses.cardCategory}>{categoryLabel[5]}</p>
            <h3 className={dashboardClasses.cardTitle}>
              {categoryRating[5]} 
            </h3>
          </CardHeader>
          <CardFooter stats>
            <div className={dashboardClasses.stats}>

            </div>
          </CardFooter>
        </Card>
      </GridItem>

      </GridContainer>

{/* Category Summary Radar Chart and Bar Chart */}
      <GridContainer>

        <GridItem xs={12} sm={12} md={6} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
              
              tabs={[
                {
                  tabName: "Sentiment by Category",
                  tabIcon: RadarChartIcon,
                  tabContent: (!categorySummaryIsLoading ? 
                    <div>
                      {/* <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton}>
                          Percent
                        </Button>
                        <Button color="white" size="sm" className={classes.middleButton}>
                          Value
                        </Button>
                      </div>

                        </GridItem>

                        </GridContainer>
                      <br/> */}
                      <Radar 
                        height={215} data={categorySummaryRadarDataSet}  
                        options={categorySummaryRadarOption} /> 
                    </div>
                  

                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!categorySummaryIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={categorySummaryRadarTableColumn}
                          data={categorySummaryRadarTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={categorySummaryRadarTableDataSet} filename={"Sentiment by Category.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={6} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"
              
              tabs={[
                {
                  tabName: "Star Rating by Category",
                  tabIcon: BarChartIcon,
                  tabContent: (!categorySummaryIsLoading ? 
                    <div>
                      {/* <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton}>
                          Week
                        </Button>
                        <Button color="white" size="sm" className={classes.middleButton}>
                          Month
                        </Button>
                        <Button color="white" size="sm" className={classes.lastButton}>
                          Year
                        </Button>
                      </div>
                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton}>
                          Percent
                        </Button>
                        <Button color="white" size="sm" className={classes.middleButton}>
                          Value
                        </Button>
                      </div>

                        </GridItem>

                        </GridContainer>
                      <br/> */}
                      <Bar 
                        height={215} data={categorySummaryBarDataSet} options={categorySummaryBarOption} /> 

                    </div>
                  

                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!categorySummaryIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={categorySummaryBarTableColumn}
                          data={categorySummaryBarTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={categorySummaryBarTableDataSet} filename={"Star Rating by Category.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>


        </GridContainer>


{/* Category Summary Trend Chart */}
    <GridContainer>

    <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="warning"
          
          tabs={[
            {
              tabName: "Software Trend",
              tabIcon: SoftwareIcon,
              tabContent: (!categorySummaryIsLoading ? 
                <div>
                  <Line 
                    height={215} data={categorySummarySoftwareDataSet}  
                    options={categorySummarySoftwareOptions} /> 
                </div>
              

              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!categorySummaryIsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={categorySummarySoftwareTableColumn}
                      data={categorySummarySoftwareTableDataSet}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={categorySummarySoftwareTableDataSet} filename={"Category Summary - Software Trend.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        /> 

    </GridItem>

    <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="warning"
          
          tabs={[
            {
              tabName: "Picture Trend",
              tabIcon: PictureIcon,
              tabContent: (!categorySummaryIsLoading ? 
                <div>
              
                  <Line 
                    height={215} data={categorySummaryPictureDataSet} options={categorySummaryPictureOptions} /> 

                </div>
              

              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!categorySummaryIsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={categorySummaryPictureTableColumn}
                      data={categorySummaryPictureTableDataSet}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={categorySummaryPictureTableDataSet} filename={"Category Summary - Picture Trend.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

              
            }
          ]}
        /> 

    </GridItem>

    <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="warning"
          
          tabs={[
            {
              tabName: "Price Trend",
              tabIcon: PriceIcon,
              tabContent: (!categorySummaryIsLoading ? 
                <div>
                  
                  <Line 
                    height={215} data={categorySummaryPriceDataSet} options={categorySummaryPriceOptions} /> 

                </div>
              

              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (!categorySummaryIsLoading ? 
                  <div>
                    <DataTable
                      title=""
                      columns={categorySummaryPriceTableColumn}
                      data={categorySummaryPriceTableDataSet}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={categorySummaryPriceTableDataSet} filename={"Category Summary - Price Trend.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
                    }
                  ]}
                /> 

            </GridItem>


        </GridContainer>


        <GridContainer>

        <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Design Trend",
                  tabIcon: DesignIcon,
                  tabContent: (!categorySummaryIsLoading ? 
                    <div>
                      <Line 
                        height={215} data={categorySummaryDesignDataSet}  
                        options={categorySummaryDesignOptions} /> 
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!categorySummaryIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={categorySummaryDesignTableColumn}
                          data={categorySummaryDesignTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={categorySummaryDesignTableDataSet} filename={"Category Summary - Design Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Feature Trend",
                  tabIcon: FeatureIcon,
                  tabContent: (!categorySummaryIsLoading ? 
                    <div>
                      
                      <Line 
                        height={215} data={categorySummaryFeatureDataSet} options={categorySummaryFeatureOptions} /> 

                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!categorySummaryIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={categorySummaryFeatureTableColumn}
                          data={categorySummaryFeatureTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={categorySummaryFeatureTableDataSet} filename={"Category Summary - Feature Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>

        <GridItem xs={12} sm={12} md={4} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Sound Trend",
                  tabIcon: SoundIcon,
                  tabContent: (!categorySummaryIsLoading ? 
                    <div>
                      
                      <Line 
                        height={215} data={categorySummarySoundDataSet} options={categorySummarySoundOptions} /> 

                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!categorySummaryIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={categorySummarySoundTableColumn}
                          data={categorySummarySoundTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={categorySummarySoundTableDataSet} filename={"Category Summary - Sound Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                        }
                      ]}
                    /> 

                </GridItem>


            </GridContainer>


{/* Topic Overview - Pie  */}

        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Overview</b> </Button>
          </GridItem>

          </GridContainer>


          <br/>

          <GridContainer>

            <GridItem xs={2}>
              <Button color={topicOverviewcustomStarSelection.fiveStar ? "success" : "white"} size={topicOverviewcustomStarSelection.fiveStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicOverviewStarSelection("fiveStar")}> <b>5-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicOverviewcustomStarSelection.fourStar ? "success" : "white"} size={topicOverviewcustomStarSelection.fourStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicOverviewStarSelection("fourStar")}> <b>4-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicOverviewcustomStarSelection.threeStar ? "warning" : "white"} size={topicOverviewcustomStarSelection.threeStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicOverviewStarSelection("threeStar")}> <b>3-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicOverviewcustomStarSelection.twoStar ? "danger" : "white"} size={topicOverviewcustomStarSelection.twoStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicOverviewStarSelection("twoStar")}> <b>2-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicOverviewcustomStarSelection.oneStar ? "danger" : "white"} size={topicOverviewcustomStarSelection.oneStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicOverviewStarSelection("oneStar")}> <b>1-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color = "info" size = "md" style={{width: "100%"}} onClick={() => handleTopicOverviewStarSelection("updateChart")}> <b>Refresh Chart</b> </Button>
            </GridItem>

          </GridContainer>

                    


         
          <br/>

            

          <GridContainer>

          <GridItem xs={12} sm={12} md={6}>

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"

                tabs={[

                  {
                    tabName: "Topic Sentiment Comparison",
                    tabIcon: CompareIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div> 
                        {/* style={{ height: 300}} */}
                        <Doughnut height={200} 
                                  data={topicOverviewTopicSentimentComparisonDataSet} 
                                  options={topicOverviewTopicSentimentComparisonOption}
                                  plugins={[ChartDataLabels]}
                                  />
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicOverviewIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicOverviewTopicSentimentComparisonTableColumn}
                            data={topicOverviewTopicSentimentComparisonTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"445px"}
                            noHeader={true}
                            />
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicOverviewTopicSentimentComparisonTableDataSet} filename={"Topic Sentiment Comparison.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

            </GridItem>

          <GridItem xs={12} sm={12} md={6}>

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
              
                
                tabs={[
                  {
                    tabName: "Topic Comparison",
                    tabIcon: CompareIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div> 
                        {/* style={{ height: 300}} */}
                        <HorizontalBar
                          height={200}
                          data={topicOverviewTopicComparisonDataSet}
                          options={topicOverviewTopicComparisonOption}
                        />
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },

                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicOverviewIsLoading ? 
                        <div>
                          {topicOverviewTopicComparisonReactTable()}
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicOverviewTopicComparisonTableDataSet} filename={"Topic Comparison List.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>



          </GridContainer>



{/* Topic Overview - Wordcloud  */}

        <GridContainer>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "Positive Topic List",
                    tabIcon: PositiveIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div style={{ height: 500}}>
                        {topicOverViewPositiveWordCloud()}
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicOverviewIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicOverviewPositiveTopicTableColumn}
                            data={topicOverviewPositiveTopicTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicOverviewPositiveTopicTableDataSet} filename={"Topic Overview - Positive.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="danger"
                
                tabs={[
                  {
                    tabName: "Negative Topic List",
                    tabIcon: NegativeIcon,
                    tabContent: (!topicOverviewIsLoading ? 
                      <div style={{ height: 500}}>
                        {topicOverViewNegativeWordCloud()}
                      </div>

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicOverviewIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicOverviewNegativeTopicTableColumn}
                            data={topicOverviewNegativeTopicTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicOverviewNegativeTopicTableDataSet} filename={"Topic Overview - Negative.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          


        </GridContainer>
      




{/* Topic Insight Filter */}
      <GridContainer>

      <GridItem xs={12}>
        <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Insight</b> </Button>
      </GridItem>

      </GridContainer>


      <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={12}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <TopicIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Topic List</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="topicInsight-select"
                  className={
                    classes.selectLabel
                }>
                  Select Topic
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={topicInsightSelection}
                  onChange={handleTopicInsight}
                  inputProps={
                    {
                      name: "topicInsightSelect",
                      id: "topicInsight-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  topicInsightItems.map((TopicInsightItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={TopicInsightItems}>
                      {TopicInsightItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
              
            </CardBody>

          </Card>


          

        </GridItem>
        

        </GridContainer>

        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

            <Button color="pinterest" onClick={onClickTopicInsight}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>
            

          </GridItem>

          </GridContainer>

          <br/>

{/* Topic Insight Topic Trend */}

            <br/>

            <GridContainer>

              <GridItem xs={2}>
                <Button color={topicInsightcustomStarSelection1.fiveStar ? "success" : "white"} size={topicInsightcustomStarSelection1.fiveStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicInsightStarSelection1("fiveStar")}> <b>5-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={topicInsightcustomStarSelection1.fourStar ? "success" : "white"} size={topicInsightcustomStarSelection1.fourStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicInsightStarSelection1("fourStar")}> <b>4-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={topicInsightcustomStarSelection1.threeStar ? "warning" : "white"} size={topicInsightcustomStarSelection1.threeStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicInsightStarSelection1("threeStar")}> <b>3-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={topicInsightcustomStarSelection1.twoStar ? "danger" : "white"} size={topicInsightcustomStarSelection1.twoStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicInsightStarSelection1("twoStar")}> <b>2-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color={topicInsightcustomStarSelection1.oneStar ? "danger" : "white"} size={topicInsightcustomStarSelection1.oneStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicInsightStarSelection1("oneStar")}> <b>1-Star</b> </Button>
              </GridItem>

              <GridItem xs={2}>
                <Button color = "info" size = "md" style={{width: "100%"}} onClick={() => handleTopicInsightStarSelection1("updateChart")}> <b>Refresh Chart</b> </Button>
              </GridItem>

            </GridContainer>

                    


         
          <br/>



        <GridContainer>

        <GridItem xs={12} sm={12} md={12} >

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="warning"
              
              tabs={[
                {
                  tabName: "Topic Trend",
                  tabIcon: TrendingUpIcon,
                  tabContent: (!topicInsightIsLoading ? 
                    <div >
                      <Line 
                        height={75} data={topicInsightTopicTrendDataSet}  
                        options={topicInsightTopicTrendOption} /> 
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!topicInsightIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicInsightTopicTrendTableColumn}
                          data={topicInsightTopicTrendTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"300px"}
                          noHeader={true}
                        /> 
                    <GridContainer>
                    <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">
                  
                      <CSVLink data={topicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                          <DownloadIcon className={classes.icons} />
                            Download CSV 
                        </Button>
                      </CSVLink>

                    </GridItem>

                  </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

        </GridItem>


    </GridContainer>

{/* Topic Insight Charts */}
          <GridContainer>

            <GridItem xs={12} sm={12} md={4} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="primary"
                  
                  tabs={[
                    {
                      tabName: "Topic Phrase",
                      tabIcon: TopicIcon,
                      tabContent: (!topicInsightIsLoading ? 
                        <div>
                          <HorizontalBar 
                            height={215} data={topicInsightPhraseDataSet}  
                            options={topicInsightPhraseOption} /> 
                        </div>
                      

                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!topicInsightIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={topicInsightPhraseTableColumn}
                              data={topicInsightPhraseTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={topicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            <GridItem xs={12} sm={12} md={4} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="success"
                  
                  tabs={[
                    {
                      tabName: "Positive Sentiment",
                      tabIcon: PositiveIcon,
                      tabContent: (!topicInsightIsLoading ? 
                        <div>
                          
                          <HorizontalBar 
                            height={215} data={topicInsightPositiveDataSet} options={topicInsightPositiveOption} /> 

                        </div>
                      

                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!topicInsightIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={topicInsightPositiveTableColumn}
                              data={topicInsightPositiveTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categorySummaryFeatureTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            <GridItem xs={12} sm={12} md={4} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="danger"
                  
                  tabs={[
                    {
                      tabName: "Negative Sentiment",
                      tabIcon: NegativeIcon,
                      tabContent: (!topicInsightIsLoading ? 
                        <div>
                          
                          <HorizontalBar 
                            height={215} data={topicInsightNegativeDataSet} options={topicInsightNegativeOption} /> 

                        </div>
                      

                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!topicInsightIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={topicInsightNegativeTableColumn}
                              data={topicInsightNegativeTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categorySummarySoundTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                            }
                          ]}
                        /> 

                    </GridItem>


                </GridContainer>


{/* Topic Search Filter */}
        <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Search</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>
          <GridItem xs={12}
            sm={12}
            md={12}>

            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <TopicIcon />
                  
                  
                </CardIcon>
                <h4 className={
                  classes.cardIconTitle
                }>
                  <b>Topic Search</b>
                </h4>
              </CardHeader>
              <CardBody>
                <form onSubmit={e => { e.preventDefault(); }}> 
                  <CustomInput
                    labelText="Example: battery, batt, duration, battery life"
                    id="text_topicSearch"
                    value={topicSearchItems}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onBlur: event => {setTopicSearchItems(event.target.value)},
                      type: "text"
                    }}
                    
                />
                
                </form>
                
              </CardBody>

            </Card>


            

          </GridItem>
          

          </GridContainer>

          <GridContainer >

            <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

              <Button color="pinterest" onClick={onClickTopicSearch}>
                <RefreshIcon className={classes.icons} />
                  Display Chart
              </Button>
              

            </GridItem>

            </GridContainer>

            <br/>
      
{/* Topic Search Charts */}

      <br/>

        <GridContainer>

            <GridItem xs={2}>
              <Button color={topicSearchcustomStarSelection1.fiveStar ? "success" : "white"} size={topicSearchcustomStarSelection1.fiveStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicSearchStarSelection1("fiveStar")}> <b>5-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicSearchcustomStarSelection1.fourStar ? "success" : "white"} size={topicSearchcustomStarSelection1.fourStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicSearchStarSelection1("fourStar")}> <b>4-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicSearchcustomStarSelection1.threeStar ? "warning" : "white"} size={topicSearchcustomStarSelection1.threeStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicSearchStarSelection1("threeStar")}> <b>3-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicSearchcustomStarSelection1.twoStar ? "danger" : "white"} size={topicSearchcustomStarSelection1.twoStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicSearchStarSelection1("twoStar")}> <b>2-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color={topicSearchcustomStarSelection1.oneStar ? "danger" : "white"} size={topicSearchcustomStarSelection1.oneStar ? "md" : "sm"} style={{width: "100%"}} onClick={() => handleTopicSearchStarSelection1("oneStar")}> <b>1-Star</b> </Button>
            </GridItem>

            <GridItem xs={2}>
              <Button color = "info" size = "md" style={{width: "100%"}} onClick={() => handleTopicSearchStarSelection1("updateChart")}> <b>Refresh Chart</b> </Button>
            </GridItem>

          </GridContainer>



      <br/>



          <GridContainer>

          <GridItem xs={12} sm={12} md={8} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
                
                tabs={[
                  {
                    tabName: "Topic Trend",
                    tabIcon: TrendingUpIcon,
                    tabContent: (!topicSearchIsLoading ? 
                      <div >
                        <Line 
                          height={100} data={topicSearchTrendDataSet}  
                          options={topicSearchTrendOption} /> 
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicSearchIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={TopicSearchTrendTableColumn}
                            data={topicSearchTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicSearchTrendTableDataSet} filename={"Topic Search - Topic Trend.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>


          <GridItem xs={12} sm={12} md={4} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="info"
                
                tabs={[
                  {
                    tabName: "Topic Breakdown",
                    tabIcon: PieChartIcon,
                    tabContent: (!topicSearchIsLoading ? 
                      <div >
                        <Doughnut 
                          height={215} data={topicSearchBreakdownDataSet}  
                          options={topicSearchBreakdownOptions}  plugins={[ChartDataLabels]}/> 
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicSearchIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicSearchBreakdownTableColumn}
                            data={topicSearchBreakdownTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicSearchBreakdownTableDataSet} filename={"Topic Search - Topic Breakdown.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>


          </GridContainer>

{/* Topic search Charts */}
            <GridContainer>

              <GridItem xs={12} sm={12} md={4} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="primary"
                    
                    tabs={[
                      {
                        tabName: "Topic by Sentiment",
                        tabIcon: TopicIcon,
                        tabContent: (!topicSearchIsLoading ? 
                          <div>
                            <HorizontalBar 
                              height={215} data={topicSearchTopicSentimentDataSet}  
                              options={topicSearchTopicSentimentOption} /> 
                          </div>
                        

                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!topicSearchIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={topicSearchTopicSentimentTableColumn}
                                data={topicSearchTopicSentimentTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicSearchTopicSentimentTableDataSet} filename={"Topic Search - Topic by Sentiment.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={4} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="success"
                    
                    tabs={[
                      {
                        tabName: "Positive Sentiment",
                        tabIcon: PositiveIcon,
                        tabContent: (!topicSearchIsLoading ? 
                          <div>
                            
                            <HorizontalBar 
                              height={215} data={topicSearchPositiveSentimentDataSet} options={topicSearchPositiveSentimentOption} /> 

                          </div>
                        

                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!topicSearchIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={topicSearchPositiveSentimentTableColumn}
                                data={topicSearchPositiveSentimentTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicSearchPositiveSentimentTableDataSet} filename={"Topic Search - Positive Sentiment.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={4} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="danger"
                    
                    tabs={[
                      {
                        tabName: "Negative Sentiment",
                        tabIcon: NegativeIcon,
                        tabContent: (!topicSearchIsLoading ? 
                          <div>
                            
                            <HorizontalBar 
                              height={215} data={topicSearchNegativeSentimentDataDataSet} options={topicSearchNegativeSentimentOption} /> 

                          </div>
                        

                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!topicSearchIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={topicSearchNegativeSentimentTableColumn}
                                data={topicSearchNegativeSentimentTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicSearchNegativeSentimentTableDataSet} filename={"Topic Search - Negative Sentiment.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                              }
                            ]}
                          /> 

                      </GridItem>


                  </GridContainer>


{/* Sentiment Overview - Wordcloud  */}
        <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Sentiment Overview</b> </Button>
        </GridItem>

        </GridContainer>

        <GridContainer>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "Positive Sentiment List",
                    tabIcon: PositiveIcon,
                    tabContent: (!sentimentOverviewPositiveIsLoading ? 
                      <div style={{ height: 500}}>

                      <GridContainer >
                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="center"
                              alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton} onClick={onClickSentimentOverviewPositiveFilter("All")}>
                            All
                          </Button>

                          <Tooltip
                              id="tooltip-top"
                              title="Software"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewPositiveFilter("Software")}>
                                <SoftwareIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Picture"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewPositiveFilter("Picture")}>
                                <PictureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Price"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewPositiveFilter("Price")}>
                                <PriceIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Design"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewPositiveFilter("Design")}>
                                <DesignIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Feature"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewPositiveFilter("Feature")}>
                                <FeatureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Sound"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewPositiveFilter("Sound")}>
                                <SoundIcon />
                              </Button>
                            </Tooltip>

                        </div>
                        

                          </GridItem>

                          </GridContainer>
                        <br/>
                        
                        <div style={{ height: 450}}>
                          {sentimentOverviewPositveWordCloud()}
                        </div>

                        <br />
                        <br />
                      </div>
                    

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!sentimentOverviewPositiveIsLoading ?
                        <div>
                          <DataTable
                            title=""
                            columns={sentimentOverviewPositveTableColumn}
                            data={sentimentOverviewPositveTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={sentimentOverviewPositveTableDataSet} filename={"Sentiment Overview - Positive.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress disableShrink  style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="danger"
                
                tabs={[
                  {
                    tabName: "Negative Sentiment List",
                    tabIcon: NegativeIcon,
                    tabContent: (!sentimentOverviewNegativeIsLoading ? 
                      <div style={{ height: 500}}>

                      <GridContainer >
                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="center"
                              alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton} onClick={onClickSentimentOverviewNegativeFilter("All")}>
                            All
                          </Button>

                          <Tooltip
                              id="tooltip-top"
                              title="Software"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewNegativeFilter("Software")}>
                                <SoftwareIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Picture"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewNegativeFilter("Picture")}>
                                <PictureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Price"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewNegativeFilter("Price")}>
                                <PriceIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Design"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewNegativeFilter("Design")}>
                                <DesignIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Feature"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewNegativeFilter("Feature")}>
                                <FeatureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Sound"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClickSentimentOverviewNegativeFilter("Sound")}>
                                <SoundIcon />
                              </Button>
                            </Tooltip>

                        </div>
                        

                          </GridItem>

                          </GridContainer>
                        <br/>
                        
                        <div style={{ height: 450}}>
                          {sentimentOverviewNegativeWordCloud()}
                        </div>

                        <br />
                        <br />
                      </div>

                    : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!sentimentOverviewNegativeIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={sentimentOverviewNegativeDataTableColumn}
                            data={sentimentOverviewNegativeDataTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={sentimentOverviewNegativeDataTableDataSet} filename={"Sentiment Overview - Negative.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          


        </GridContainer>

{/* Software Summary Value Box  */}

        <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Software Summary</b> </Button>
        </GridItem>

        </GridContainer>

        <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <PositiveIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Positive </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryDetailPositiveCount[0]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={"warning"} stats icon>
              <CardIcon color={"warning"}>
                <RemoveIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Neutral </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryDetailNeutralCount[0]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={"danger"} stats icon>
              <CardIcon color={"danger"}>
                <NegativeIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Negative </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryDetailNegativeCount[0]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={categoryColor[0]} stats icon>
              <CardIcon color={categoryColor[0]}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
              <h3 className={dashboardClasses.cardTitle}>
              {categoryRating[0]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        </GridContainer>

{/* Software Summary Charts  */}
        <GridContainer>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="warning"
                  
                  tabs={[
                    {
                      tabName: "Software Trend",
                      tabIcon: SoftwareIcon,
                      tabContent: (!softwareSummaryIsLoading ? 
                        <div>
                          <Line 
                            height={215} data={categorySummarySoftwareDataSet}  
                            options={categorySummarySoftwareOptions} /> 
                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!softwareSummaryIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categorySummarySoftwareTableColumn}
                              data={categorySummarySoftwareTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categorySummarySoftwareTableDataSet} filename={"Category Summary - Software Trend.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="info"
                  
                  tabs={[
                    {
                      tabName: "Software Topic List",
                      tabIcon: TopicIcon,
                      tabContent: (!softwareSummaryIsLoading ? 
                        <div>
                      
                          <HorizontalBar 
                            height={215} data={categoryDetailSoftwareTopicComparisonDataSet} options={categoryDetailSoftwareTopicComparisonOption} /> 

                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!softwareSummaryIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categoryDetailSoftwareTopicComparisonTableColumn}
                              data={categoryDetailSoftwareTopicComparisonTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"445px"}
                              noHeader={true}
                              /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categoryDetailSoftwareTopicComparisonTableDataSet} filename={"Category Detail - Software Trend.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            

            </GridContainer>

{/* Software Topic Insight Filter  */}
          <GridContainer>
            <GridItem xs={12}
              sm={12}
              md={12}>

              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <TopicIcon />
                    
                    
                  </CardIcon>
                  <h4 className={
                    classes.cardIconTitle
                  }>
                    <b>Topic List</b>
                  </h4>
                </CardHeader>
                <CardBody>
                  <FormControl fullWidth
                    className={
                      classes.selectFormControl
                  }>
                    <InputLabel htmlFor="categoryDetailTopicInsightSoftwareSelect-select"
                      className={
                        classes.selectLabel
                    }>
                      Select Topic
                    </InputLabel>

                    <Select style={
                        {minWidth: "100%"}
                      }
                      MenuProps={
                        {className: classes.selectMenu}
                      }
                      classes={
                        {select: classes.select}
                      }
                      //multiple
                      autoWidth={false}
                      value={categoryDetailTopicInsightSoftwareSelection}
                      onChange={handleCategoryDetailSoftwareTopicInsight}
                      inputProps={
                        {
                          name: "categoryDetailTopicInsightSoftwareSelect",
                          id: "categoryDetailTopicInsightSoftwareSelect-select"
                        }
                    }>
                      <MenuItem disabled
                        classes={
                          {root: classes.selectMenuItem}
                      }>
                        <b>Single Selection</b>
                      </MenuItem>

                      {
                      categoryDetailTopicInsightSoftwareItem.map((CategoryDetailTopicInsightSoftwareItem, index) => (
                        <MenuItem key={index}
                          classes={
                            {
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }
                          }
                          value={CategoryDetailTopicInsightSoftwareItem}>
                          {CategoryDetailTopicInsightSoftwareItem} </MenuItem>
                      ))
                    } </Select>
                  </FormControl>
                  
                </CardBody>

              </Card>


              

            </GridItem>
            

            </GridContainer>

            <GridContainer >

              <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">

                <Button color="pinterest" onClick={onClickCategoryDetailSoftwareTopicInsight}>
                  <RefreshIcon className={classes.icons} />
                    Display Chart
                </Button>
                

              </GridItem>

              </GridContainer>

              <br/>

{/* Software Topic Insight Topic Trend */}
            <GridContainer>

            <GridItem xs={12} sm={12} md={12} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="warning"
                  
                  tabs={[
                    {
                      tabName: "Topic Trend",
                      tabIcon: TrendingUpIcon,
                      tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                        <div >
                          <Line 
                            height={75} data={categoryDetailSoftwaretopicInsightTopicTrendDataSet}  
                            options={categoryDetailSoftwaretopicInsightTopicTrendOption} /> 
                        </div>
                      

                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categoryDetailSoftwaretopicInsightTopicTrendTableColumn}
                              data={categoryDetailSoftwaretopicInsightTopicTrendTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categoryDetailSoftwaretopicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>


          </GridContainer>

{/* Software Topic Insight Charts */}
              <GridContainer>

                <GridItem xs={12} sm={12} md={4} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="primary"
                      
                      tabs={[
                        {
                          tabName: "Topic Phrase",
                          tabIcon: TopicIcon,
                          tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                            <div>
                              <HorizontalBar 
                                height={215} data={categoryDetailSoftwaretopicInsightPhraseDataSet}  
                                options={categoryDetailSoftwaretopicInsightPhraseOption} /> 
                            </div>
                          

                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailSoftwaretopicInsightPhraseTableColumn}
                                  data={categoryDetailSoftwaretopicInsightPhraseTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailSoftwaretopicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

                <GridItem xs={12} sm={12} md={4} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="success"
                      
                      tabs={[
                        {
                          tabName: "Positive Sentiment",
                          tabIcon: PositiveIcon,
                          tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                            <div>
                              
                              <HorizontalBar 
                                height={215} data={categoryDetailSoftwaretopicInsightPositiveDataSet} options={categoryDetailSoftwaretopicInsightPositiveOption} /> 

                            </div>
                          

                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailSoftwaretopicInsightPositiveTableColumn}
                                  data={categoryDetailSoftwaretopicInsightPositiveTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailSoftwaretopicInsightPositiveTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

                <GridItem xs={12} sm={12} md={4} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="danger"
                      
                      tabs={[
                        {
                          tabName: "Negative Sentiment",
                          tabIcon: NegativeIcon,
                          tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                            <div>
                              
                              <HorizontalBar 
                                height={215} data={categoryDetailSoftwaretopicInsightNegativeDataSet} options={categoryDetailSoftwaretopicInsightNegativeOption} /> 

                            </div>
                          

                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!categoryDetailSoftwaretopicInsightIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailSoftwaretopicInsightNegativeTableColumn}
                                  data={categoryDetailSoftwaretopicInsightNegativeTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailSoftwaretopicInsightNegativeTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                                }
                              ]}
                            /> 

                        </GridItem>


                    </GridContainer>





{/* Picture Summary Value Box  */}

        <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Picture Summary</b> </Button>
        </GridItem>

        </GridContainer>

        <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <PositiveIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Positive </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryDetailPositiveCount[1]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={"warning"} stats icon>
              <CardIcon color={"warning"}>
                <RemoveIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Neutral </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryDetailNeutralCount[1]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={"danger"} stats icon>
              <CardIcon color={"danger"}>
                <NegativeIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Negative </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryDetailNegativeCount[1]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={categoryColor[1]} stats icon>
              <CardIcon color={categoryColor[1]}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
              <h3 className={dashboardClasses.cardTitle}>
              {categoryRating[1]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        </GridContainer>

{/* Picture Summary Charts  */}
        <GridContainer>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="warning"
                  
                  tabs={[
                    {
                      tabName: "Picture Trend",
                      tabIcon: PictureIcon,
                      tabContent: (!pictureSummaryIsLoading ? 
                        <div>
                          <Line 
                            height={215} data={categorySummaryPictureDataSet}  
                            options={categorySummaryPictureOptions} /> 
                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!pictureSummaryIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categorySummaryPictureTableColumn}
                              data={categorySummaryPictureTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categorySummaryPictureTableDataSet} filename={"Category Summary - Picture Trend.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="info"
                  
                  tabs={[
                    {
                      tabName: "Picture Topic List",
                      tabIcon: TopicIcon,
                      tabContent: (!pictureSummaryIsLoading ? 
                        <div>
                      
                          <HorizontalBar 
                            height={215} data={categoryDetailPictureTopicComparisonDataSet} options={categoryDetailPictureTopicComparisonOption} /> 

                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!pictureSummaryIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categoryDetailPictureTopicComparisonTableColumn}
                              data={categoryDetailPictureTopicComparisonTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"445px"}
                              noHeader={true}
                              /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categoryDetailPictureTopicComparisonTableDataSet} filename={"Category Detail - Picture Trend.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            

            </GridContainer>

{/* Picture Topic Insight Filter  */}
          <GridContainer>
            <GridItem xs={12}
              sm={12}
              md={12}>

              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <TopicIcon />
                    
                    
                  </CardIcon>
                  <h4 className={
                    classes.cardIconTitle
                  }>
                    <b>Topic List</b>
                  </h4>
                </CardHeader>
                <CardBody>
                  <FormControl fullWidth
                    className={
                      classes.selectFormControl
                  }>
                    <InputLabel htmlFor="categoryDetailTopicInsightPictureSelect-select"
                      className={
                        classes.selectLabel
                    }>
                      Select Topic
                    </InputLabel>

                    <Select style={
                        {minWidth: "100%"}
                      }
                      MenuProps={
                        {className: classes.selectMenu}
                      }
                      classes={
                        {select: classes.select}
                      }
                      //multiple
                      autoWidth={false}
                      value={categoryDetailTopicInsightPictureSelection}
                      onChange={handleCategoryDetailPictureTopicInsight}
                      inputProps={
                        {
                          name: "categoryDetailTopicInsightPictureSelect",
                          id: "categoryDetailTopicInsightPictureSelect-select"
                        }
                    }>
                      <MenuItem disabled
                        classes={
                          {root: classes.selectMenuItem}
                      }>
                        <b>Single Selection</b>
                      </MenuItem>

                      {
                      categoryDetailTopicInsightPictureItem.map((CategoryDetailTopicInsightPictureItem, index) => (
                        <MenuItem key={index}
                          classes={
                            {
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }
                          }
                          value={CategoryDetailTopicInsightPictureItem}>
                          {CategoryDetailTopicInsightPictureItem} </MenuItem>
                      ))
                    } </Select>
                  </FormControl>
                  
                </CardBody>

              </Card>


              

            </GridItem>
            

            </GridContainer>

            <GridContainer >

              <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">

                <Button color="pinterest" onClick={onClickCategoryDetailPictureTopicInsight}>
                  <RefreshIcon className={classes.icons} />
                    Display Chart
                </Button>
                

              </GridItem>

              </GridContainer>

              <br/>

        {/* Picture Topic Insight Topic Trend */}
            <GridContainer>

            <GridItem xs={12} sm={12} md={12} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="warning"
                  
                  tabs={[
                    {
                      tabName: "Topic Trend",
                      tabIcon: TrendingUpIcon,
                      tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                        <div >
                          <Line 
                            height={75} data={categoryDetailPicturetopicInsightTopicTrendDataSet}  
                            options={categoryDetailPicturetopicInsightTopicTrendOption} /> 
                        </div>
                      

                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categoryDetailPicturetopicInsightTopicTrendTableColumn}
                              data={categoryDetailPicturetopicInsightTopicTrendTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={categoryDetailPicturetopicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>


          </GridContainer>

        {/* Picture Topic Insight Charts */}
              <GridContainer>

                <GridItem xs={12} sm={12} md={4} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="primary"
                      
                      tabs={[
                        {
                          tabName: "Topic Phrase",
                          tabIcon: TopicIcon,
                          tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                            <div>
                              <HorizontalBar 
                                height={215} data={categoryDetailPicturetopicInsightPhraseDataSet}  
                                options={categoryDetailPicturetopicInsightPhraseOption} /> 
                            </div>
                          

                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailPicturetopicInsightPhraseTableColumn}
                                  data={categoryDetailPicturetopicInsightPhraseTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailPicturetopicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

                <GridItem xs={12} sm={12} md={4} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="success"
                      
                      tabs={[
                        {
                          tabName: "Positive Sentiment",
                          tabIcon: PositiveIcon,
                          tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                            <div>
                              
                              <HorizontalBar 
                                height={215} data={categoryDetailPicturetopicInsightPositiveDataSet} options={categoryDetailPicturetopicInsightPositiveOption} /> 

                            </div>
                          

                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailPicturetopicInsightPositiveTableColumn}
                                  data={categoryDetailPicturetopicInsightPositiveTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailPicturetopicInsightPositiveTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

                <GridItem xs={12} sm={12} md={4} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="danger"
                      
                      tabs={[
                        {
                          tabName: "Negative Sentiment",
                          tabIcon: NegativeIcon,
                          tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                            <div>
                              
                              <HorizontalBar 
                                height={215} data={categoryDetailPicturetopicInsightNegativeDataSet} options={categoryDetailPicturetopicInsightNegativeOption} /> 

                            </div>
                          

                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!categoryDetailPicturetopicInsightIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailPicturetopicInsightNegativeTableColumn}
                                  data={categoryDetailPicturetopicInsightNegativeTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailPicturetopicInsightNegativeTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                                }
                              ]}
                            /> 

                        </GridItem>


                    </GridContainer>



{/* Price Summary Value Box  */}

            <GridContainer>

            <GridItem xs={12}>
              <Button color="github" size="sm" style={{width: "100%"}}> <b>Price Summary</b> </Button>
            </GridItem>

            </GridContainer>

            <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <PositiveIcon />
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}> Positive </p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categoryDetailPositiveCount[2]}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>

                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color={"warning"} stats icon>
                  <CardIcon color={"warning"}>
                    <RemoveIcon />
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}> Neutral </p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categoryDetailNeutralCount[2]}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>

                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color={"danger"} stats icon>
                  <CardIcon color={"danger"}>
                    <NegativeIcon />
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}> Negative </p>
                  <h3 className={dashboardClasses.cardTitle}>
                    {categoryDetailNegativeCount[2]}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>

                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <Card>
                <CardHeader color={categoryColor[2]} stats icon>
                  <CardIcon color={categoryColor[2]}>
                    <StarsIcon />
                  </CardIcon>
                  <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
                  <h3 className={dashboardClasses.cardTitle}>
                  {categoryRating[2]}
                  </h3>
                </CardHeader>
                <CardFooter stats>
                  <div className={dashboardClasses.stats}>

                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            </GridContainer>

{/* Price Summary Charts  */}
            <GridContainer>

                <GridItem xs={12} sm={12} md={6} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="warning"
                      
                      tabs={[
                        {
                          tabName: "Price Trend",
                          tabIcon: PriceIcon,
                          tabContent: (!priceSummaryIsLoading ? 
                            <div>
                              <Line 
                                height={215} data={categorySummaryPriceDataSet}  
                                options={categorySummaryPriceOptions} /> 
                            </div>
                          

                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!priceSummaryIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categorySummaryPriceTableColumn}
                                  data={categorySummaryPriceTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categorySummaryPriceTableDataSet} filename={"Category Summary - Price Trend.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

                <GridItem xs={12} sm={12} md={6} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="info"
                      
                      tabs={[
                        {
                          tabName: "Price Topic List",
                          tabIcon: TopicIcon,
                          tabContent: (!priceSummaryIsLoading ? 
                            <div>
                          
                              <HorizontalBar 
                                height={215} data={categoryDetailPriceTopicComparisonDataSet} options={categoryDetailPriceTopicComparisonOption} /> 

                            </div>
                          

                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!priceSummaryIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailPriceTopicComparisonTableColumn}
                                  data={categoryDetailPriceTopicComparisonTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"445px"}
                                  noHeader={true}
                                  /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailPriceTopicComparisonTableDataSet} filename={"Category Detail - Price Trend.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

                

                </GridContainer>

{/* Price Topic Insight Filter  */}
              <GridContainer>
                <GridItem xs={12}
                  sm={12}
                  md={12}>

                  <Card>
                    <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                        <TopicIcon />
                        
                        
                      </CardIcon>
                      <h4 className={
                        classes.cardIconTitle
                      }>
                        <b>Topic List</b>
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <FormControl fullWidth
                        className={
                          classes.selectFormControl
                      }>
                        <InputLabel htmlFor="categoryDetailTopicInsightPriceSelect-select"
                          className={
                            classes.selectLabel
                        }>
                          Select Topic
                        </InputLabel>

                        <Select style={
                            {minWidth: "100%"}
                          }
                          MenuProps={
                            {className: classes.selectMenu}
                          }
                          classes={
                            {select: classes.select}
                          }
                          //multiple
                          autoWidth={false}
                          value={categoryDetailTopicInsightPriceSelection}
                          onChange={handleCategoryDetailPriceTopicInsight}
                          inputProps={
                            {
                              name: "categoryDetailTopicInsightPriceSelect",
                              id: "categoryDetailTopicInsightPriceSelect-select"
                            }
                        }>
                          <MenuItem disabled
                            classes={
                              {root: classes.selectMenuItem}
                          }>
                            <b>Single Selection</b>
                          </MenuItem>

                          {
                          categoryDetailTopicInsightPriceItem.map((CategoryDetailTopicInsightPriceItem, index) => (
                            <MenuItem key={index}
                              classes={
                                {
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }
                              }
                              value={CategoryDetailTopicInsightPriceItem}>
                              {CategoryDetailTopicInsightPriceItem} </MenuItem>
                          ))
                        } </Select>
                      </FormControl>
                      
                    </CardBody>

                  </Card>


                  

                </GridItem>
                

                </GridContainer>

                <GridContainer >

                  <GridItem 
                      xs={12}
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center">

                    <Button color="pinterest" onClick={onClickCategoryDetailPriceTopicInsight}>
                      <RefreshIcon className={classes.icons} />
                        Display Chart
                    </Button>
                    

                  </GridItem>

                  </GridContainer>

                  <br/>

{/* Price Topic Insight Topic Trend */}
                <GridContainer>

                <GridItem xs={12} sm={12} md={12} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="warning"
                      
                      tabs={[
                        {
                          tabName: "Topic Trend",
                          tabIcon: TrendingUpIcon,
                          tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                            <div >
                              <Line 
                                height={75} data={categoryDetailPricetopicInsightTopicTrendDataSet}  
                                options={categoryDetailPricetopicInsightTopicTrendOption} /> 
                            </div>
                          

                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                        },
                        {
                          tabName: "Table",
                          tabIcon: AssignmentIcon,
                            tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                              <div>
                                <DataTable
                                  title=""
                                  columns={categoryDetailPricetopicInsightTopicTrendTableColumn}
                                  data={categoryDetailPricetopicInsightTopicTrendTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={categoryDetailPricetopicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>


              </GridContainer>

            {/* Price Topic Insight Charts */}
                  <GridContainer>

                    <GridItem xs={12} sm={12} md={4} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "Topic Phrase",
                              tabIcon: TopicIcon,
                              tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                                <div>
                                  <HorizontalBar 
                                    height={215} data={categoryDetailPricetopicInsightPhraseDataSet}  
                                    options={categoryDetailPricetopicInsightPhraseOption} /> 
                                </div>
                              

                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={categoryDetailPricetopicInsightPhraseTableColumn}
                                      data={categoryDetailPricetopicInsightPhraseTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={categoryDetailPricetopicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="success"
                          
                          tabs={[
                            {
                              tabName: "Positive Sentiment",
                              tabIcon: PositiveIcon,
                              tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                                <div>
                                  
                                  <HorizontalBar 
                                    height={215} data={categoryDetailPricetopicInsightPositiveDataSet} options={categoryDetailPricetopicInsightPositiveOption} /> 

                                </div>
                              

                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={categoryDetailPricetopicInsightPositiveTableColumn}
                                      data={categoryDetailPricetopicInsightPositiveTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={categoryDetailPricetopicInsightPositiveTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="danger"
                          
                          tabs={[
                            {
                              tabName: "Negative Sentiment",
                              tabIcon: NegativeIcon,
                              tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                                <div>
                                  
                                  <HorizontalBar 
                                    height={215} data={categoryDetailPricetopicInsightNegativeDataSet} options={categoryDetailPricetopicInsightNegativeOption} /> 

                                </div>
                              

                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!categoryDetailPricetopicInsightIsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={categoryDetailPricetopicInsightNegativeTableColumn}
                                      data={categoryDetailPricetopicInsightNegativeTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={categoryDetailPricetopicInsightNegativeTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                
                                    }
                                  ]}
                                /> 

                            </GridItem>


                        </GridContainer>




{/* Design Summary Value Box  */}

                <GridContainer>

                <GridItem xs={12}>
                  <Button color="github" size="sm" style={{width: "100%"}}> <b>Design Summary</b> </Button>
                </GridItem>

                </GridContainer>

                <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        <PositiveIcon />
                      </CardIcon>
                      <p className={dashboardClasses.cardCategory}> Positive </p>
                      <h3 className={dashboardClasses.cardTitle}>
                        {categoryDetailPositiveCount[3]}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={dashboardClasses.stats}>

                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color={"warning"} stats icon>
                      <CardIcon color={"warning"}>
                        <RemoveIcon />
                      </CardIcon>
                      <p className={dashboardClasses.cardCategory}> Neutral </p>
                      <h3 className={dashboardClasses.cardTitle}>
                        {categoryDetailNeutralCount[3]}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={dashboardClasses.stats}>

                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color={"danger"} stats icon>
                      <CardIcon color={"danger"}>
                        <NegativeIcon />
                      </CardIcon>
                      <p className={dashboardClasses.cardCategory}> Negative </p>
                      <h3 className={dashboardClasses.cardTitle}>
                        {categoryDetailNegativeCount[3]}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={dashboardClasses.stats}>

                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color={categoryColor[3]} stats icon>
                      <CardIcon color={categoryColor[3]}>
                        <StarsIcon />
                      </CardIcon>
                      <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
                      <h3 className={dashboardClasses.cardTitle}>
                      {categoryRating[3]}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={dashboardClasses.stats}>

                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>

                </GridContainer>

 {/* Design Summary Charts  */}
                <GridContainer>

                    <GridItem xs={12} sm={12} md={6} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="warning"
                          
                          tabs={[
                            {
                              tabName: "Design Trend",
                              tabIcon: DesignIcon,
                              tabContent: (!designSummaryIsLoading ? 
                                <div>
                                  <Line 
                                    height={215} data={categorySummaryDesignDataSet}  
                                    options={categorySummaryDesignOptions} /> 
                                </div>
                              

                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!designSummaryIsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={categorySummaryDesignTableColumn}
                                      data={categorySummaryDesignTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={categorySummaryDesignTableDataSet} filename={"Category Summary - Design Trend.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

                    <GridItem xs={12} sm={12} md={6} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="info"
                          
                          tabs={[
                            {
                              tabName: "Design Topic List",
                              tabIcon: TopicIcon,
                              tabContent: (!designSummaryIsLoading ? 
                                <div>
                              
                                  <HorizontalBar 
                                    height={215} data={categoryDetailDesignTopicComparisonDataSet} options={categoryDetailDesignTopicComparisonOption} /> 

                                </div>
                              

                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!designSummaryIsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={categoryDetailDesignTopicComparisonTableColumn}
                                      data={categoryDetailDesignTopicComparisonTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"445px"}
                                      noHeader={true}
                                      /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={categoryDetailDesignTopicComparisonTableDataSet} filename={"Category Detail - Design Trend.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

                    

                    </GridContainer>

{/* Design Topic Insight Filter  */}
                  <GridContainer>
                    <GridItem xs={12}
                      sm={12}
                      md={12}>

                      <Card>
                        <CardHeader color="rose" icon>
                          <CardIcon color="rose">
                            <TopicIcon />
                            
                            
                          </CardIcon>
                          <h4 className={
                            classes.cardIconTitle
                          }>
                            <b>Topic List</b>
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <FormControl fullWidth
                            className={
                              classes.selectFormControl
                          }>
                            <InputLabel htmlFor="categoryDetailTopicInsightDesignSelect-select"
                              className={
                                classes.selectLabel
                            }>
                              Select Topic
                            </InputLabel>

                            <Select style={
                                {minWidth: "100%"}
                              }
                              MenuProps={
                                {className: classes.selectMenu}
                              }
                              classes={
                                {select: classes.select}
                              }
                              //multiple
                              autoWidth={false}
                              value={categoryDetailTopicInsightDesignSelection}
                              onChange={handleCategoryDetailDesignTopicInsight}
                              inputProps={
                                {
                                  name: "categoryDetailTopicInsightDesignSelect",
                                  id: "categoryDetailTopicInsightDesignSelect-select"
                                }
                            }>
                              <MenuItem disabled
                                classes={
                                  {root: classes.selectMenuItem}
                              }>
                                <b>Single Selection</b>
                              </MenuItem>

                              {
                              categoryDetailTopicInsightDesignItem.map((CategoryDetailTopicInsightDesignItem, index) => (
                                <MenuItem key={index}
                                  classes={
                                    {
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }
                                  }
                                  value={CategoryDetailTopicInsightDesignItem}>
                                  {CategoryDetailTopicInsightDesignItem} </MenuItem>
                              ))
                            } </Select>
                          </FormControl>
                          
                        </CardBody>

                      </Card>


                      

                    </GridItem>
                    

                    </GridContainer>

                    <GridContainer >

                      <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">

                        <Button color="pinterest" onClick={onClickCategoryDetailDesignTopicInsight}>
                          <RefreshIcon className={classes.icons} />
                            Display Chart
                        </Button>
                        

                      </GridItem>

                      </GridContainer>

                      <br/>

{/* Design Topic Insight Topic Trend */}
                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="warning"
                          
                          tabs={[
                            {
                              tabName: "Topic Trend",
                              tabIcon: TrendingUpIcon,
                              tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                <div >
                                  <Line 
                                    height={75} data={categoryDetailDesigntopicInsightTopicTrendDataSet}  
                                    options={categoryDetailDesigntopicInsightTopicTrendOption} /> 
                                </div>
                              

                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                            },
                            {
                              tabName: "Table",
                              tabIcon: AssignmentIcon,
                                tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                  <div>
                                    <DataTable
                                      title=""
                                      columns={categoryDetailDesigntopicInsightTopicTrendTableColumn}
                                      data={categoryDetailDesigntopicInsightTopicTrendTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={categoryDetailDesigntopicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>


                  </GridContainer>

{/* Design Topic Insight Charts */}
                      <GridContainer>

                        <GridItem xs={12} sm={12} md={4} >

                          <CustomTabs
                              //title="Tasks:"
                              centered={true}
                              headerColor="primary"
                              
                              tabs={[
                                {
                                  tabName: "Topic Phrase",
                                  tabIcon: TopicIcon,
                                  tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                    <div>
                                      <HorizontalBar 
                                        height={215} data={categoryDetailDesigntopicInsightPhraseDataSet}  
                                        options={categoryDetailDesigntopicInsightPhraseOption} /> 
                                    </div>
                                  

                                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                                },
                                {
                                  tabName: "Table",
                                  tabIcon: AssignmentIcon,
                                    tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                      <div>
                                        <DataTable
                                          title=""
                                          columns={categoryDetailDesigntopicInsightPhraseTableColumn}
                                          data={categoryDetailDesigntopicInsightPhraseTableDataSet}
                                          //conditionalRowStyles={conditionalRowStyles}
                                          // pagination={true}
                                          // paginationPerPage={10}
                                          // paginationComponentOptions={{ noRowsPerPage: true }}
                                          fixedHeader={true}
                                          //fixedHeaderScrollHeight={"445px"}
                                          fixedHeaderScrollHeight={"300px"}
                                          noHeader={true}
                                        /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categoryDetailDesigntopicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                      </div>
                                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                  
                                }
                              ]}
                            /> 

                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} >

                          <CustomTabs
                              //title="Tasks:"
                              centered={true}
                              headerColor="success"
                              
                              tabs={[
                                {
                                  tabName: "Positive Sentiment",
                                  tabIcon: PositiveIcon,
                                  tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                    <div>
                                      
                                      <HorizontalBar 
                                        height={215} data={categoryDetailDesigntopicInsightPositiveDataSet} options={categoryDetailDesigntopicInsightPositiveOption} /> 

                                    </div>
                                  

                                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                                },
                                {
                                  tabName: "Table",
                                  tabIcon: AssignmentIcon,
                                    tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                      <div>
                                        <DataTable
                                          title=""
                                          columns={categoryDetailDesigntopicInsightPositiveTableColumn}
                                          data={categoryDetailDesigntopicInsightPositiveTableDataSet}
                                          //conditionalRowStyles={conditionalRowStyles}
                                          // pagination={true}
                                          // paginationPerPage={10}
                                          // paginationComponentOptions={{ noRowsPerPage: true }}
                                          fixedHeader={true}
                                          //fixedHeaderScrollHeight={"445px"}
                                          fixedHeaderScrollHeight={"300px"}
                                          noHeader={true}
                                        /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categoryDetailDesigntopicInsightPositiveTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                      </div>
                                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                  
                                }
                              ]}
                            /> 

                        </GridItem>

                        <GridItem xs={12} sm={12} md={4} >

                          <CustomTabs
                              //title="Tasks:"
                              centered={true}
                              headerColor="danger"
                              
                              tabs={[
                                {
                                  tabName: "Negative Sentiment",
                                  tabIcon: NegativeIcon,
                                  tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                    <div>
                                      
                                      <HorizontalBar 
                                        height={215} data={categoryDetailDesigntopicInsightNegativeDataSet} options={categoryDetailDesigntopicInsightNegativeOption} /> 

                                    </div>
                                  

                                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                                },
                                {
                                  tabName: "Table",
                                  tabIcon: AssignmentIcon,
                                    tabContent: (!categoryDetailDesigntopicInsightIsLoading ? 
                                      <div>
                                        <DataTable
                                          title=""
                                          columns={categoryDetailDesigntopicInsightNegativeTableColumn}
                                          data={categoryDetailDesigntopicInsightNegativeTableDataSet}
                                          //conditionalRowStyles={conditionalRowStyles}
                                          // pagination={true}
                                          // paginationPerPage={10}
                                          // paginationComponentOptions={{ noRowsPerPage: true }}
                                          fixedHeader={true}
                                          //fixedHeaderScrollHeight={"445px"}
                                          fixedHeaderScrollHeight={"300px"}
                                          noHeader={true}
                                        /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categoryDetailDesigntopicInsightNegativeTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                      </div>
                                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                    
                                        }
                                      ]}
                                    /> 

                                </GridItem>


                            </GridContainer>



{/* Feature Summary Value Box  */}

          <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Feature Summary</b> </Button>
          </GridItem>

          </GridContainer>

          <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <PositiveIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}> Positive </p>
                <h3 className={dashboardClasses.cardTitle}>
                  {categoryDetailPositiveCount[4]}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color={"warning"} stats icon>
                <CardIcon color={"warning"}>
                  <RemoveIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}> Neutral </p>
                <h3 className={dashboardClasses.cardTitle}>
                  {categoryDetailNeutralCount[4]}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color={"danger"} stats icon>
                <CardIcon color={"danger"}>
                  <NegativeIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}> Negative </p>
                <h3 className={dashboardClasses.cardTitle}>
                  {categoryDetailNegativeCount[4]}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color={categoryColor[4]} stats icon>
                <CardIcon color={categoryColor[4]}>
                  <StarsIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
                <h3 className={dashboardClasses.cardTitle}>
                {categoryRating[4]}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          </GridContainer>

{/* Feature Summary Charts  */}
          <GridContainer>

              <GridItem xs={12} sm={12} md={6} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="warning"
                    
                    tabs={[
                      {
                        tabName: "Feature Trend",
                        tabIcon: FeatureIcon,
                        tabContent: (!featureSummaryIsLoading ? 
                          <div>
                            <Line 
                              height={215} data={categorySummaryFeatureDataSet}  
                              options={categorySummaryFeatureOptions} /> 
                          </div>
                        

                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!featureSummaryIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={categorySummaryFeatureTableColumn}
                                data={categorySummaryFeatureTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={categorySummaryFeatureTableDataSet} filename={"Category Summary - Feature Trend.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={6} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="info"
                    
                    tabs={[
                      {
                        tabName: "Feature Topic List",
                        tabIcon: TopicIcon,
                        tabContent: (!featureSummaryIsLoading ? 
                          <div>
                        
                            <HorizontalBar 
                              height={215} data={categoryDetailFeatureTopicComparisonDataSet} options={categoryDetailFeatureTopicComparisonOption} /> 

                          </div>
                        

                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!featureSummaryIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={categoryDetailFeatureTopicComparisonTableColumn}
                                data={categoryDetailFeatureTopicComparisonTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"445px"}
                                noHeader={true}
                                /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={categoryDetailDesignTopicComparisonTableDataSet} filename={"Category Detail - Design Trend.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              

              </GridContainer>

{/* Feature Topic Insight Filter  */}
            <GridContainer>
              <GridItem xs={12}
                sm={12}
                md={12}>

                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <TopicIcon />
                      
                      
                    </CardIcon>
                    <h4 className={
                      classes.cardIconTitle
                    }>
                      <b>Topic List</b>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <FormControl fullWidth
                      className={
                        classes.selectFormControl
                    }>
                      <InputLabel htmlFor="categoryDetailTopicInsightFeatureSelect-select"
                        className={
                          classes.selectLabel
                      }>
                        Select Topic
                      </InputLabel>

                      <Select style={
                          {minWidth: "100%"}
                        }
                        MenuProps={
                          {className: classes.selectMenu}
                        }
                        classes={
                          {select: classes.select}
                        }
                        //multiple
                        autoWidth={false}
                        value={categoryDetailTopicInsightFeatureSelection}
                        onChange={handleCategoryDetailFeatureTopicInsight}
                        inputProps={
                          {
                            name: "categoryDetailTopicInsightFeatureSelect",
                            id: "categoryDetailTopicInsightFeatureSelect-select"
                          }
                      }>
                        <MenuItem disabled
                          classes={
                            {root: classes.selectMenuItem}
                        }>
                          <b>Single Selection</b>
                        </MenuItem>

                        {
                        categoryDetailTopicInsightFeatureItem.map((CategoryDetailTopicInsightFeatureItem, index) => (
                          <MenuItem key={index}
                            classes={
                              {
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }
                            }
                            value={CategoryDetailTopicInsightFeatureItem}>
                            {CategoryDetailTopicInsightFeatureItem} </MenuItem>
                        ))
                      } </Select>
                    </FormControl>
                    
                  </CardBody>

                </Card>


                

              </GridItem>
              

              </GridContainer>

              <GridContainer >

                <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">

                  <Button color="pinterest" onClick={onClickCategoryDetailFeatureTopicInsight}>
                    <RefreshIcon className={classes.icons} />
                      Display Chart
                  </Button>
                  

                </GridItem>

                </GridContainer>

                <br/>

{/* Feature Topic Insight Topic Trend */}
              <GridContainer>

              <GridItem xs={12} sm={12} md={12} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="warning"
                    
                    tabs={[
                      {
                        tabName: "Topic Trend",
                        tabIcon: TrendingUpIcon,
                        tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                          <div >
                            <Line 
                              height={75} data={categoryDetailFeaturetopicInsightTopicTrendDataSet}  
                              options={categoryDetailFeaturetopicInsightTopicTrendOption} /> 
                          </div>
                        

                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={categoryDetailFeaturetopicInsightTopicTrendTableColumn}
                                data={categoryDetailFeaturetopicInsightTopicTrendTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={categoryDetailFeaturetopicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>


            </GridContainer>

{/* Feature Topic Insight Charts */}
                <GridContainer>

                  <GridItem xs={12} sm={12} md={4} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="primary"
                        
                        tabs={[
                          {
                            tabName: "Topic Phrase",
                            tabIcon: TopicIcon,
                            tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                              <div>
                                <HorizontalBar 
                                  height={215} data={categoryDetailFeaturetopicInsightPhraseDataSet}  
                                  options={categoryDetailFeaturetopicInsightPhraseOption} /> 
                              </div>
                            

                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryDetailFeaturetopicInsightPhraseTableColumn}
                                    data={categoryDetailFeaturetopicInsightPhraseTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={categoryDetailFeaturetopicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment",
                            tabIcon: PositiveIcon,
                            tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                              <div>
                                
                                <HorizontalBar 
                                  height={215} data={categoryDetailFeaturetopicInsightPositiveDataSet} options={categoryDetailFeaturetopicInsightPositiveOption} /> 

                              </div>
                            

                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryDetailFeaturetopicInsightPositiveTableColumn}
                                    data={categoryDetailFeaturetopicInsightPositiveTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={categoryDetailFeaturetopicInsightPositiveTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment",
                            tabIcon: NegativeIcon,
                            tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                              <div>
                                
                                <HorizontalBar 
                                  height={215} data={categoryDetailFeaturetopicInsightNegativeDataSet} options={categoryDetailFeaturetopicInsightNegativeOption} /> 

                              </div>
                            

                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!categoryDetailFeaturetopicInsightIsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryDetailFeaturetopicInsightNegativeTableColumn}
                                    data={categoryDetailFeaturetopicInsightNegativeTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={categoryDetailFeaturetopicInsightNegativeTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                                  }
                                ]}
                              /> 

                          </GridItem>


                      </GridContainer>




{/* Sound Summary Value Box  */}

              <GridContainer>

              <GridItem xs={12}>
                <Button color="github" size="sm" style={{width: "100%"}}> <b>Sound Summary</b> </Button>
              </GridItem>

              </GridContainer>

              <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <PositiveIcon />
                    </CardIcon>
                    <p className={dashboardClasses.cardCategory}> Positive </p>
                    <h3 className={dashboardClasses.cardTitle}>
                      {categoryDetailPositiveCount[5]}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={dashboardClasses.stats}>

                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color={"warning"} stats icon>
                    <CardIcon color={"warning"}>
                      <RemoveIcon />
                    </CardIcon>
                    <p className={dashboardClasses.cardCategory}> Neutral </p>
                    <h3 className={dashboardClasses.cardTitle}>
                      {categoryDetailNeutralCount[5]}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={dashboardClasses.stats}>

                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color={"danger"} stats icon>
                    <CardIcon color={"danger"}>
                      <NegativeIcon />
                    </CardIcon>
                    <p className={dashboardClasses.cardCategory}> Negative </p>
                    <h3 className={dashboardClasses.cardTitle}>
                      {categoryDetailNegativeCount[5]}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={dashboardClasses.stats}>

                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color={categoryColor[4]} stats icon>
                    <CardIcon color={categoryColor[4]}>
                      <StarsIcon />
                    </CardIcon>
                    <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
                    <h3 className={dashboardClasses.cardTitle}>
                    {categoryRating[5]}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={dashboardClasses.stats}>

                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              </GridContainer>

{/* Sound Summary Charts  */}
              <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="warning"
                        
                        tabs={[
                          {
                            tabName: "Sound Trend",
                            tabIcon: SoundIcon,
                            tabContent: (!soundSummaryIsLoading ? 
                              <div>
                                <Line 
                                  height={215} data={categorySummarySoundDataSet}  
                                  options={categorySummarySoundOptions} /> 
                              </div>
                            

                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!soundSummaryIsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummarySoundTableColumn}
                                    data={categorySummarySoundTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={categorySummarySoundTableDataSet} filename={"Category Summary - Sound Trend.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="info"
                        
                        tabs={[
                          {
                            tabName: "Sound Topic List",
                            tabIcon: TopicIcon,
                            tabContent: (!soundSummaryIsLoading ? 
                              <div>
                            
                                <HorizontalBar 
                                  height={215} data={categoryDetailSoundTopicComparisonDataSet} options={categoryDetailSoundTopicComparisonOption} /> 

                              </div>
                            

                            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!soundSummaryIsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryDetailSoundTopicComparisonTableColumn}
                                    data={categoryDetailSoundTopicComparisonTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"445px"}
                                    noHeader={true}
                                    /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={categoryDetailDesignTopicComparisonTableDataSet} filename={"Category Detail - Design Trend.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  

                  </GridContainer>

{/* Sound Topic Insight Filter  */}
                <GridContainer>
                  <GridItem xs={12}
                    sm={12}
                    md={12}>

                    <Card>
                      <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                          <TopicIcon />
                          
                          
                        </CardIcon>
                        <h4 className={
                          classes.cardIconTitle
                        }>
                          <b>Topic List</b>
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <FormControl fullWidth
                          className={
                            classes.selectFormControl
                        }>
                          <InputLabel htmlFor="categoryDetailTopicInsightSoundSelect-select"
                            className={
                              classes.selectLabel
                          }>
                            Select Topic
                          </InputLabel>

                          <Select style={
                              {minWidth: "100%"}
                            }
                            MenuProps={
                              {className: classes.selectMenu}
                            }
                            classes={
                              {select: classes.select}
                            }
                            //multiple
                            autoWidth={false}
                            value={categoryDetailTopicInsightSoundSelection}
                            onChange={handleCategoryDetailSoundTopicInsight}
                            inputProps={
                              {
                                name: "categoryDetailTopicInsightSoundSelect",
                                id: "categoryDetailTopicInsightSoundSelect-select"
                              }
                          }>
                            <MenuItem disabled
                              classes={
                                {root: classes.selectMenuItem}
                            }>
                              <b>Single Selection</b>
                            </MenuItem>

                            {
                            categoryDetailTopicInsightSoundItem.map((CategoryDetailTopicInsightSoundItem, index) => (
                              <MenuItem key={index}
                                classes={
                                  {
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }
                                }
                                value={CategoryDetailTopicInsightSoundItem}>
                                {CategoryDetailTopicInsightSoundItem} </MenuItem>
                            ))
                          } </Select>
                        </FormControl>
                        
                      </CardBody>

                    </Card>


                    

                  </GridItem>
                  

                  </GridContainer>

                  <GridContainer >

                    <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">

                      <Button color="pinterest" onClick={onClickCategoryDetailSoundTopicInsight}>
                        <RefreshIcon className={classes.icons} />
                          Display Chart
                      </Button>
                      

                    </GridItem>

                    </GridContainer>

                    <br/>

{/* Sound Topic Insight Topic Trend */}
                  <GridContainer>

                  <GridItem xs={12} sm={12} md={12} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="warning"
                        
                        tabs={[
                          {
                            tabName: "Topic Trend",
                            tabIcon: TrendingUpIcon,
                            tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                              <div >
                                <Line 
                                  height={75} data={categoryDetailSoundtopicInsightTopicTrendDataSet}  
                                  options={categoryDetailSoundtopicInsightTopicTrendOption} /> 
                              </div>
                            

                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryDetailSoundtopicInsightTopicTrendTableColumn}
                                    data={categoryDetailSoundtopicInsightTopicTrendTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                              <GridContainer>
                              <GridItem 
                                xs={12}
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center">
                            
                                <CSVLink data={categoryDetailSoundtopicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>
                                </CSVLink>

                              </GridItem>

                            </GridContainer>

                                </div>
                              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                            
                          }
                        ]}
                      /> 

                  </GridItem>


                </GridContainer>

              {/* Sound Topic Insight Charts */}
                    <GridContainer>

                      <GridItem xs={12} sm={12} md={4} >

                        <CustomTabs
                            //title="Tasks:"
                            centered={true}
                            headerColor="primary"
                            
                            tabs={[
                              {
                                tabName: "Topic Phrase",
                                tabIcon: TopicIcon,
                                tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                                  <div>
                                    <HorizontalBar 
                                      height={215} data={categoryDetailSoundtopicInsightPhraseDataSet}  
                                      options={categoryDetailSoundtopicInsightPhraseOption} /> 
                                  </div>
                                

                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                              },
                              {
                                tabName: "Table",
                                tabIcon: AssignmentIcon,
                                  tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                                    <div>
                                      <DataTable
                                        title=""
                                        columns={categoryDetailSoundtopicInsightPhraseTableColumn}
                                        data={categoryDetailSoundtopicInsightPhraseTableDataSet}
                                        //conditionalRowStyles={conditionalRowStyles}
                                        // pagination={true}
                                        // paginationPerPage={10}
                                        // paginationComponentOptions={{ noRowsPerPage: true }}
                                        fixedHeader={true}
                                        //fixedHeaderScrollHeight={"445px"}
                                        fixedHeaderScrollHeight={"300px"}
                                        noHeader={true}
                                      /> 
                                  <GridContainer>
                                  <GridItem 
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">
                                
                                    <CSVLink data={categoryDetailSoundtopicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                        <DownloadIcon className={classes.icons} />
                                          Download CSV 
                                      </Button>
                                    </CSVLink>

                                  </GridItem>

                                </GridContainer>

                                    </div>
                                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                
                              }
                            ]}
                          /> 

                      </GridItem>

                      <GridItem xs={12} sm={12} md={4} >

                        <CustomTabs
                            //title="Tasks:"
                            centered={true}
                            headerColor="success"
                            
                            tabs={[
                              {
                                tabName: "Positive Sentiment",
                                tabIcon: PositiveIcon,
                                tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                                  <div>
                                    
                                    <HorizontalBar 
                                      height={215} data={categoryDetailSoundtopicInsightPositiveDataSet} options={categoryDetailSoundtopicInsightPositiveOption} /> 

                                  </div>
                                

                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                              },
                              {
                                tabName: "Table",
                                tabIcon: AssignmentIcon,
                                  tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                                    <div>
                                      <DataTable
                                        title=""
                                        columns={categoryDetailSoundtopicInsightPositiveTableColumn}
                                        data={categoryDetailSoundtopicInsightPositiveTableDataSet}
                                        //conditionalRowStyles={conditionalRowStyles}
                                        // pagination={true}
                                        // paginationPerPage={10}
                                        // paginationComponentOptions={{ noRowsPerPage: true }}
                                        fixedHeader={true}
                                        //fixedHeaderScrollHeight={"445px"}
                                        fixedHeaderScrollHeight={"300px"}
                                        noHeader={true}
                                      /> 
                                  <GridContainer>
                                  <GridItem 
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">
                                
                                    <CSVLink data={categoryDetailSoundtopicInsightPositiveTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                        <DownloadIcon className={classes.icons} />
                                          Download CSV 
                                      </Button>
                                    </CSVLink>

                                  </GridItem>

                                </GridContainer>

                                    </div>
                                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                
                              }
                            ]}
                          /> 

                      </GridItem>

                      <GridItem xs={12} sm={12} md={4} >

                        <CustomTabs
                            //title="Tasks:"
                            centered={true}
                            headerColor="danger"
                            
                            tabs={[
                              {
                                tabName: "Negative Sentiment",
                                tabIcon: NegativeIcon,
                                tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                                  <div>
                                    
                                    <HorizontalBar 
                                      height={215} data={categoryDetailSoundtopicInsightNegativeDataSet} options={categoryDetailSoundtopicInsightNegativeOption} /> 

                                  </div>
                                

                                : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                              },
                              {
                                tabName: "Table",
                                tabIcon: AssignmentIcon,
                                  tabContent: (!categoryDetailSoundtopicInsightIsLoading ? 
                                    <div>
                                      <DataTable
                                        title=""
                                        columns={categoryDetailSoundtopicInsightNegativeTableColumn}
                                        data={categoryDetailSoundtopicInsightNegativeTableDataSet}
                                        //conditionalRowStyles={conditionalRowStyles}
                                        // pagination={true}
                                        // paginationPerPage={10}
                                        // paginationComponentOptions={{ noRowsPerPage: true }}
                                        fixedHeader={true}
                                        //fixedHeaderScrollHeight={"445px"}
                                        fixedHeaderScrollHeight={"300px"}
                                        noHeader={true}
                                      /> 
                                  <GridContainer>
                                  <GridItem 
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">
                                
                                    <CSVLink data={categoryDetailSoundtopicInsightNegativeTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                        <DownloadIcon className={classes.icons} />
                                          Download CSV 
                                      </Button>
                                    </CSVLink>

                                  </GridItem>

                                </GridContainer>

                                    </div>
                                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                                  
                                      }
                                    ]}
                                  /> 

                              </GridItem>


                          </GridContainer>


{/*View Individual Sentence */}

              <GridContainer>

              <GridItem xs={12}>
                <Button color="github" size="sm" style={{width: "100%"}}> <b>View Individual Sentence</b> </Button>
              </GridItem>

              </GridContainer>


                <GridContainer>

                <GridItem xs={12} sm={12} md={12} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="primary"
                      
                      tabs={[
                        {
                          tabName: "View Individual Sentence",
                          tabIcon: AssignmentIcon,
                            tabContent: (!viewIndividualSentenceIsLoading ? 
                              <div>
                                
                                <div style={{ maxWidth: '100%' }}>
                                        <MaterialTable
                                          columns={viewIndividualSentenceTableColumn}
                                          data={viewIndividualSentenceDataTableDataSet}
                                          title="SMAT Review"
                                          options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            filtering: true,
                                            showTitle: false
                                          }}
                                          actions={[
                                            {
                                              icon: TopicIcon,
                                              tooltip: 'Show Full Review',
                                              onClick: (event, rowData) => {
                                                // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                setFullReviewModal(true); // Show Pop-up
  
                                              }

                                            }
                                          ]}
                                          components={{
                                            Action: props => (

                                              <Tooltip
                                                id="tooltip-bottom"
                                                title="Show Full Review"
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                              >
                                                  <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                    <FullReviewIcon />
                                                  </Button>
                                              </Tooltip>
                                              
                                            )
                                          }}
                                        />
                                  </div>

                                  <div>
                                    <Dialog
                                      fullWidth={true}
                                      maxWidth={'lg'}
                                      open={fullReviewModal}
                                      onClose={() => setFullReviewModal(false)}
                                      //TransitionComponent={Transition}
                                      aria-labelledby="full-review-dialog-title1"
                                    >
                                      <DialogTitle id="full-review-dialog-title1"><strong> Full Review </strong></DialogTitle>
                                      <DialogContent>
                                        {/* <DialogContentText> */}
                                        {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                        {/* </DialogContentText> */}
                                      
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={() => setFullReviewModal(false)} color="primary">
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          {/* viewIndividualSentenceData
                          viewIndividualSentenceDataTableDataSet */}

                              <CSVLink data={jdRawData} filename={"JD Raw Data.csv"}>
                                <Button color="behance" size="sm" style={showJDbutton ? {marginTop: "10%"}: {marginTop: "10%", display: "none"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download JD Raw
                                </Button>
                              </CSVLink>

                              <CSVLink data={rawData} filename={"Raw Data.csv"}>
                                <Button color="behance" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download Raw
                                </Button>
                              </CSVLink>

                              <CSVLink data={viewIndividualSentenceData} filename={"View Individual Sentence.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>
                              

                            </GridItem>

                          </GridContainer>

                              </div>
                            : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

    </GridContainer>

{/* Unique Trend - Trend Drill Down */}

    <div>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={openModalUniqueStarRatingTrend}
          onClose={() => setOpenModalUniqueStarRatingTrend(false)}
          //TransitionComponent={Transition}
          aria-labelledby="full-review-dialog-title"
        >
          <DialogTitle id="full-review-dialog-title"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

          <DialogContent>

            <GridContainer>

              <GridItem xs={12} sm={12} md={6} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="success"
                    
                    tabs={[
                      {
                        tabName: "Positive Topic List",
                        tabIcon: PositiveIcon,
                        tabContent: (!drillDownIsLoading ?
                          <div style={{ height: 350}}>
                            {uniqueTrendPositiveDrillDownDataWordCloud()}
                          </div> :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> 
                         )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (
                            <div>
                                <DataTable
                                  title=""
                                  columns={uniqueTrendPositiveDrillDownDataTopicTableColumn}
                                  data={uniqueTrendPositiveDrillDownDataTopicTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                                  <GridContainer>
                                  <GridItem 
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">
                                
                                    <CSVLink data={uniqueTrendPositiveDrillDownDataTopicTableDataSet} filename={"Drill Down - Positive.csv"}>
                                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                        <DownloadIcon className={classes.icons} />
                                          Download CSV 
                                      </Button>
                                    </CSVLink>

                                  </GridItem>

                                </GridContainer>

                            </div>
                           )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={6} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="danger"
                    
                    tabs={[
                      {
                        tabName: "Negative Topic List",
                        tabIcon: NegativeIcon,
                        tabContent: (!drillDownIsLoading ?
                          <div style={{ height: 350}}>
                            {uniqueTrendNegativeDrillDownDataWordCloud()}
                          </div> 
                          :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (
                            <div>
                              <DataTable
                                title=""
                                columns={uniqueTrendNegativeDrillDownDataTopicTableColumn}
                                data={uniqueTrendNegativeDrillDownDataTopicTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={uniqueTrendNegativeDrillDownDataTopicTableDataSet} filename={"Drill Down - Negative.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                            </div>
                           )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              </GridContainer>

                <GridContainer>

                <GridItem xs={12} sm={12} md={12} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="primary"
                      
                      tabs={[
                        {
                          tabName: "View Individual Sentence",
                          tabIcon: AssignmentIcon,
                            tabContent: (!drillDownIsLoading ?
                              <div>
                                
                                <div style={{ maxWidth: '100%' }}>
                                        <MaterialTable
                                          columns={uniqueTrendIndividualSentenceDrillDownDataTableColumn}
                                          data={uniqueTrendIndividualSentenceDrillDownDataTableDataSet}
                                          title="SMAT Review"
                                          options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            filtering: true,
                                            showTitle: false
                                          }}
                                          actions={[
                                            {
                                              icon: TopicIcon,
                                              tooltip: 'Show Full Review',
                                              onClick: (event, rowData) => {
                                                // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                setFullReviewModalUniqueStarRatingTrend(true); // Show Pop-up
  
                                              }

                                            }
                                          ]}
                                          components={{
                                            Action: props => (

                                              <Tooltip
                                                id="tooltip-bottom"
                                                title="Show Full Review"
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                              >
                                                  <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                    <FullReviewIcon />
                                                  </Button>
                                              </Tooltip>
                                              
                                            )
                                          }}
                                        />
                                  </div>

                                  <div>
                                    <Dialog
                                      fullWidth={true}
                                      maxWidth={'lg'}
                                      open={fullReviewModalUniqueStarRatingTrend}
                                      onClose={() => setFullReviewModalUniqueStarRatingTrend(false)}
                                      //TransitionComponent={Transition}
                                      aria-labelledby="full-review-dialog-title3"
                                    >
                                      <DialogTitle id="full-review-dialog-title3"><strong> Full Review </strong></DialogTitle>
                                      <DialogContent>
                                        {/* <DialogContentText> */}
                                        {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                        {/* </DialogContentText> */}
                                      
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={() => setFullReviewModalUniqueStarRatingTrend(false)} color="primary">
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                              <CSVLink data={uniqueTrendIndividualSentenceDrillDownData} filename={"View Individual Sentence.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                             :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                        }
                      ]}
                    /> 

                </GridItem>

            </GridContainer>


          
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModalUniqueStarRatingTrend(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </div> 




{/* Unique Star Label Pie - Trend Drill Down */}
      <div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalUniqueStarRatingPie}
              onClose={() => setOpenModalUniqueStarRatingPie(false)}
              //TransitionComponent={Transition}
              aria-labelledby="star-label-pie-drill-down1"
            >
              <DialogTitle id="star-label-pie-drill-down1"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {starRatingPiePositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={starRatingPiePositiveDrillDownDataTopicTableColumn}
                                      data={starRatingPiePositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={starRatingPiePositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {starRatingPieNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={starRatingPieNegativeDrillDownDataTopicTableColumn}
                                    data={starRatingPieNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={starRatingPieNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={starRatingPieIndividualSentenceDrillDownDataTableColumn}
                                              data={starRatingPieIndividualSentenceDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalUniqueStarRatingPie(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalUniqueStarRatingPie}
                                          onClose={() => setFullReviewModalUniqueStarRatingPie(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                            {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalUniqueStarRatingPie(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={starRatingPieIndividualSentenceDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} />)

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalUniqueStarRatingPie(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Star Rating Trend - Trend Drill Down */}
<div>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={openModalStarRatingTrend}
          onClose={() => setOpenModalStarRatingTrend(false)}
          //TransitionComponent={Transition}
          aria-labelledby="full-review-dialog-title"
        >
          <DialogTitle id="full-review-dialog-title"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

          <DialogContent>

            <GridContainer>

              <GridItem xs={12} sm={12} md={6} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="success"
                    
                    tabs={[
                      {
                        tabName: "Positive Topic List",
                        tabIcon: PositiveIcon,
                        tabContent: (!drillDownIsLoading ? 
                          <div style={{ height: 350}}>
                            {uniqueTrendPositiveDrillDownDataWordCloud()}
                          </div> :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} />
                         )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (
                            <div>
                                <DataTable
                                  title=""
                                  columns={uniqueTrendPositiveDrillDownDataTopicTableColumn}
                                  data={uniqueTrendPositiveDrillDownDataTopicTableDataSet}
                                  //conditionalRowStyles={conditionalRowStyles}
                                  // pagination={true}
                                  // paginationPerPage={10}
                                  // paginationComponentOptions={{ noRowsPerPage: true }}
                                  fixedHeader={true}
                                  //fixedHeaderScrollHeight={"445px"}
                                  fixedHeaderScrollHeight={"300px"}
                                  noHeader={true}
                                /> 
                                  <GridContainer>
                                  <GridItem 
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center">
                                
                                    <CSVLink data={uniqueTrendPositiveDrillDownDataTopicTableDataSet} filename={"Drill Down - Positive.csv"}>
                                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                        <DownloadIcon className={classes.icons} />
                                          Download CSV 
                                      </Button>
                                    </CSVLink>

                                  </GridItem>

                                </GridContainer>

                            </div>
                           )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={6} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="danger"
                    
                    tabs={[
                      {
                        tabName: "Negative Topic List",
                        tabIcon: NegativeIcon,
                        tabContent: (!drillDownIsLoading ? 
                          <div style={{ height: 350}}>
                            {uniqueTrendNegativeDrillDownDataWordCloud()}
                          </div>
                         :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} />)
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (
                            <div>
                              <DataTable
                                title=""
                                columns={uniqueTrendNegativeDrillDownDataTopicTableColumn}
                                data={uniqueTrendNegativeDrillDownDataTopicTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                              
                                  <CSVLink data={uniqueTrendNegativeDrillDownDataTopicTableDataSet} filename={"Drill Down - Negative.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                            </div>
                           )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              </GridContainer>

                <GridContainer>

                <GridItem xs={12} sm={12} md={12} >

                  <CustomTabs
                      //title="Tasks:"
                      centered={true}
                      headerColor="primary"
                      
                      tabs={[
                        {
                          tabName: "View Individual Sentence",
                          tabIcon: AssignmentIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div>
                                
                                <div style={{ maxWidth: '100%' }}>
                                        <MaterialTable
                                          columns={uniqueTrendIndividualSentenceDrillDownDataTableColumn}
                                          data={uniqueTrendIndividualSentenceDrillDownDataTableDataSet}
                                          title="SMAT Review"
                                          options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            filtering: true,
                                            showTitle: false
                                          }}
                                          actions={[
                                            {
                                              icon: TopicIcon,
                                              tooltip: 'Show Full Review',
                                              onClick: (event, rowData) => {
                                                // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                setFullReviewModalStarRatingTrend(true);
  
                                              }

                                            }
                                          ]}
                                          components={{
                                            Action: props => (

                                              <Tooltip
                                                id="tooltip-bottom"
                                                title="Show Full Review"
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                              >
                                                  <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                    <FullReviewIcon />
                                                  </Button>
                                              </Tooltip>
                                              
                                            )
                                          }}
                                        />
                                  </div>

                                  <div>
                                    <Dialog
                                      fullWidth={true}
                                      maxWidth={'lg'}
                                      open={fullReviewModalStarRatingTrend}
                                      onClose={() => setFullReviewModalStarRatingTrend(false)}
                                      //TransitionComponent={Transition}
                                      aria-labelledby="full-review-dialog-title3"
                                    >
                                      <DialogTitle id="full-review-dialog-title3"><strong> Full Review </strong></DialogTitle>
                                      <DialogContent>
                                        {/* <DialogContentText> */}
                                        {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                        {/* </DialogContentText> */}
                                      
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={() => setFullReviewModalStarRatingTrend(false)} color="primary">
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                              <CSVLink data={uniqueTrendIndividualSentenceDrillDownData} filename={"View Individual Sentence.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} />)

                          
                        }
                      ]}
                    /> 

                </GridItem>

            </GridContainer>


          
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModalStarRatingTrend(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </div>    





{/* Unique Star Label Pie - Trend Drill Down */}
      <div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalStarRatingPie}
              onClose={() => setOpenModalStarRatingPie(false)}
              //TransitionComponent={Transition}
              aria-labelledby="star-label-pie-drill-down1"
            >
              <DialogTitle id="star-label-pie-drill-down1"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {starRatingPiePositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={starRatingPiePositiveDrillDownDataTopicTableColumn}
                                      data={starRatingPiePositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={starRatingPiePositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {starRatingPieNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={starRatingPieNegativeDrillDownDataTopicTableColumn}
                                    data={starRatingPieNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={starRatingPieNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={starRatingPieIndividualSentenceDrillDownDataTableColumn}
                                              data={starRatingPieIndividualSentenceDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalStarRatingPie(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalStarRatingPie}
                                          onClose={() => setFullReviewModalStarRatingPie(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalStarRatingPie(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={starRatingPieIndividualSentenceDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalStarRatingPie(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Category Summary - Sentiment Radar -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummarySentimentRadar}
              onClose={() => setOpenModalCategorySummarySentimentRadar(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-sentiment-radar"
            >
              <DialogTitle id="category-summary-sentiment-radar"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {uniqueTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={categorySummarySentimentRadarPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummarySentimentRadarPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummarySentimentRadarPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {uniqueTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummarySentimentRadarNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummarySentimentRadarNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummarySentimentRadarNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummarySentimentRadarDrillDownDataTableColumn}
                                              data={categorySummarySentimentRadarDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummarySentimentRadar(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummarySentimentRadar}
                                          onClose={() => setFullReviewModalCategorySummarySentimentRadar(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummarySentimentRadar(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummarySentimentRadarDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummarySentimentRadar(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    





{/* Category Summary - Star Rating Bar -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummaryStarRating}
              onClose={() => setOpenModalCategorySummaryStarRating(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-rating-bar"
            >
              <DialogTitle id="category-summary-rating-bar"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categorySummaryStarRatingPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={categorySummaryStarRatingPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummaryStarRatingPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummaryStarRatingPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categorySummaryStarRatingNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummaryStarRatingNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummaryStarRatingNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummaryStarRatingNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummaryStarRatingDrillDownDataTableColumn}
                                              data={categorySummaryStarRatingDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummaryStarRating(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummaryStarRating}
                                          onClose={() => setFullReviewModalCategorySummaryStarRating(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummaryStarRating(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummaryStarRatingDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummaryStarRating(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Category Summary - Software Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummarySoftwareTrend}
              onClose={() => setOpenModalCategorySummarySoftwareTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-software-trend"
            >
              <DialogTitle id="category-summary-software-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categorySummarySoftwareTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={CategorySummarySoftwareTrendPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummarySoftwareTrendPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummarySoftwareTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categorySummarySoftwareTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummarySoftwareTrendNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummarySoftwareTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummarySoftwareTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummarySoftwareTrendDrillDownDataTableColumn}
                                              data={categorySummarySoftwareTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummarySoftwareTrend(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummarySoftwareTrend}
                                          onClose={() => setFullReviewModalCategorySummarySoftwareTrend(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummarySoftwareTrend(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummarySoftwareTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummarySoftwareTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    



{/* Category Summary - Picture Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummaryPictureTrend}
              onClose={() => setOpenModalCategorySummaryPictureTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-Picture-trend"
            >
              <DialogTitle id="category-summary-Picture-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categorySummaryPictureTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={CategorySummaryPictureTrendPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummaryPictureTrendPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummaryPictureTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categorySummaryPictureTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummaryPictureTrendNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummaryPictureTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummaryPictureTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummaryPictureTrendDrillDownDataTableColumn}
                                              data={categorySummaryPictureTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummaryPictureTrend(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummaryPictureTrend}
                                          onClose={() => setFullReviewModalCategorySummaryPictureTrend(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummaryPictureTrend(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummaryPictureTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummaryPictureTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    



{/* Category Summary - Price Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummaryPriceTrend}
              onClose={() => setOpenModalCategorySummaryPriceTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-Price-trend"
            >
              <DialogTitle id="category-summary-Price-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categorySummaryPriceTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={CategorySummaryPriceTrendPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummaryPriceTrendPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummaryPriceTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categorySummaryPriceTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummaryPriceTrendNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummaryPriceTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummaryPriceTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummaryPriceTrendDrillDownDataTableColumn}
                                              data={categorySummaryPriceTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummaryPriceTrend(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummaryPriceTrend}
                                          onClose={() => setFullReviewModalCategorySummaryPriceTrend(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummaryPriceTrend(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummaryPriceTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummaryPriceTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    



{/* Category Summary - Design Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummaryDesignTrend}
              onClose={() => setOpenModalCategorySummaryDesignTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-Design-trend"
            >
              <DialogTitle id="category-summary-Design-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categorySummaryDesignTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={CategorySummaryDesignTrendPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummaryDesignTrendPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummaryDesignTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categorySummaryDesignTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummaryDesignTrendNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummaryDesignTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummaryDesignTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummaryDesignTrendDrillDownDataTableColumn}
                                              data={categorySummaryDesignTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummaryDesignTrend(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummaryDesignTrend}
                                          onClose={() => setFullReviewModalCategorySummaryDesignTrend(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummaryDesignTrend(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummaryDesignTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummaryDesignTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Category Summary - Feature Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummaryFeatureTrend}
              onClose={() => setOpenModalCategorySummaryFeatureTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-Feature-trend"
            >
              <DialogTitle id="category-summary-Feature-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categorySummaryFeatureTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={CategorySummaryFeatureTrendPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummaryFeatureTrendPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummaryFeatureTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categorySummaryFeatureTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummaryFeatureTrendNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummaryFeatureTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummaryFeatureTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummaryFeatureTrendDrillDownDataTableColumn}
                                              data={categorySummaryFeatureTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummaryFeatureTrend(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummaryFeatureTrend}
                                          onClose={() => setFullReviewModalCategorySummaryFeatureTrend(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummaryFeatureTrend(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummaryFeatureTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummaryFeatureTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    



{/* Category Summary - Sound Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalCategorySummarySoundTrend}
              onClose={() => setOpenModalCategorySummarySoundTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="category-summary-Sound-trend"
            >
              <DialogTitle id="category-summary-Sound-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categorySummarySoundTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={CategorySummarySoundTrendPositiveDrillDownDataTopicTableColumn}
                                      data={categorySummarySoundTrendPositiveDrillDownDataTopicTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categorySummarySoundTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categorySummarySoundTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categorySummarySoundTrendNegativeDrillDownDataTopicTableColumn}
                                    data={categorySummarySoundTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categorySummarySoundTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categorySummarySoundTrendDrillDownDataTableColumn}
                                              data={categorySummarySoundTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalCategorySummarySoundTrend(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalCategorySummarySoundTrend}
                                          onClose={() => setFullReviewModalCategorySummarySoundTrend(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalCategorySummarySoundTrend(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categorySummarySoundTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalCategorySummarySoundTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Topic Overview - Sentiment Pie -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicOverviewTopicSentiment}
              onClose={() => setOpenModalTopicOverviewTopicSentiment(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-overview-sentiment-pie"
            >
              <DialogTitle id="topic-overview-sentiment-pie"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Topic List",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categoryOverviewTopicSentimentPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={categoryOverviewTopicSentimentPositiveDrillDownDataTableColumn}
                                      data={categoryOverviewTopicSentimentPositiveDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categoryOverviewTopicSentimentPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Topic List",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categoryOverviewTopicSentimentNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryOverviewTopicSentimentNegativeDrillDownDataTopicTableColumn}
                                    data={categoryOverviewTopicSentimentNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categoryOverviewTopicSentimentNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categoryOverviewTopicSentimentDrillDownDataTableColumn}
                                              data={categoryOverviewTopicSentimentDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicOverviewTopicSentimen(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicOverviewTopicSentimen}
                                          onClose={() => setFullReviewModalTopicOverviewTopicSentimen(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicOverviewTopicSentimen(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categoryOverviewTopicSentimentDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicOverviewTopicSentiment(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    



{/* Topic Overview - Topic Comparison Positive -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicOverviewTopicComparisonSentiment}
              onClose={() => setOpenModalTopicOverviewTopicComparisonSentiment(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-overview-sentiment-positive-wordcloud"
            >
              <DialogTitle id="topic-overview-sentiment-positive-wordcloud"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment Words",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categoryOverviewTopicComparisonSentimentPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTableColumn}
                                      data={categoryOverviewTopicComparisonSentimentPositiveDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categoryOverviewTopicComparisonSentimentPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment Words",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categoryOverviewTopicComparisonSentimentNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicTableColumn}
                                    data={categoryOverviewTopicComparisonSentimentNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categoryOverviewTopicComparisonSentimentNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categoryOverviewTopicComparisonSentimentDrillDownDataTableColumn}
                                              data={categoryOverviewTopicComparisonSentimentDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicOverviewTopicComparisonSentimen(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicOverviewTopicComparisonSentimen}
                                          onClose={() => setFullReviewModalTopicOverviewTopicComparisonSentimen(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicOverviewTopicComparisonSentimen(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categoryOverviewTopicComparisonSentimentDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicOverviewTopicComparisonSentiment(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Topic Overview - Topic Comparison Negative -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicOverviewTopicComparisonSentimentNeg}
              onClose={() => setOpenModalTopicOverviewTopicComparisonSentimentNeg(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-overview-sentiment-negative-wordcloud"
            >
              <DialogTitle id="topic-overview-sentiment-negative-wordcloud"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment Words",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTableColumn}
                                      data={categoryOverviewTopicComparisonSentimentPositiveNegDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={categoryOverviewTopicComparisonSentimentPositiveNegDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment Words",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicTableColumn}
                                    data={categoryOverviewTopicComparisonSentimentNegativeNegDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={categoryOverviewTopicComparisonSentimentNegativeNegDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={categoryOverviewTopicComparisonSentimentNegDrillDownDataTableColumn}
                                              data={categoryOverviewTopicComparisonSentimentNegDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicOverviewTopicComparisonSentimentNeg(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicOverviewTopicComparisonSentimentNeg}
                                          onClose={() => setFullReviewModalTopicOverviewTopicComparisonSentimentNeg(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicOverviewTopicComparisonSentimentNeg(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={categoryOverviewTopicComparisonSentimentNegDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicOverviewTopicComparisonSentimentNeg(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    





{/* Topic Insight - Topic Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicInsightTrend}
              onClose={() => setOpenModalTopicInsightTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-insight-topic-trend"
            >
              <DialogTitle id="topic-insight-topic-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment Words",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {topicInsightTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={topicInsightTrendPositiveDrillDownDataTableColumn}
                                      data={topicInsightTrendPositiveDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={topicInsightTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment Words",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {topicInsightTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={topicInsightTrendNegativeDrillDownDataTopicTableColumn}
                                    data={topicInsightTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={topicInsightTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicInsightTrendDrillDownDataTableColumn}
                                              data={topicInsightTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicInsightTrend(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicInsightTrend}
                                          onClose={() => setFullReviewModalTopicInsightTrend(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicInsightTrend(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicInsightTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicInsightTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    







{/* Topic Insight - Topic Phrase -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicInsightTopicPhrase}
              onClose={() => setOpenModalTopicInsightTopicPhrase(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-insight-topic-phrase"
            >
              <DialogTitle id="topic-insight-topic-phrase"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment Words",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {topicInsightTopicPhrasedPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={topicInsightTopicPhrasePositiveDrillDownDataTableColumn}
                                      data={topicInsightTopicPhrasedPositiveDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={topicInsightTopicPhrasedPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment Words",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {topicInsightTopicPhraseNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={topicInsightTopicPhraseNegativeDrillDownDataTopicTableColumn}
                                    data={topicInsightTopicPhraseNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={topicInsightTopicPhraseNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicInsightTopicPhraseDrillDownDataTableColumn}
                                              data={topicInsightTopicPhraseDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicInsightTopicPhrase(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicInsightTopicPhrase}
                                          onClose={() => setFullReviewModalTopicInsightTopicPhrase(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicInsightTopicPhrase(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicInsightTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicInsightTopicPhrase(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Topic Insight - Positive Sentiment Word -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicInsightTopicSentimentWord}
              onClose={() => setOpenModalTopicInsightTopicSentimentWord(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-insight-sentiment-word"
            >
              <DialogTitle id="topic-insight-sentiment-word"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicInsightSentimentWordDrillDownDataTableColumn}
                                              data={topicInsightSentimentWordDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicInsightTopicSentimentWord(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicInsightTopicSentimentWord}
                                          onClose={() => setFullReviewModalTopicInsightTopicSentimentWord(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicInsightTopicSentimentWord(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicInsightSentimentWordDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicInsightTopicSentimentWord(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    



{/* Topic Insight - Negative Sentiment Word -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicInsightTopicSentimentWordNeg}
              onClose={() => setOpenModalTopicInsightTopicSentimentWordNeg(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-insight-sentiment-word-Neg"
            >
              <DialogTitle id="topic-insight-sentiment-word-Neg"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicInsightSentimentWordDrillDownDataNegTableColumn}
                                              data={topicInsightSentimentWordDrillDownDataNegTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicInsightTopicSentimentWordNeg(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicInsightTopicSentimentWordNeg}
                                          onClose={() => setFullReviewModalTopicInsightTopicSentimentWordNeg(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicInsightTopicSentimentWordNeg(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicInsightSentimentWordDrillDownDataNeg} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicInsightTopicSentimentWordNeg(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Topic Search - Trend -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicSearchTrend}
              onClose={() => setOpenModalTopicSearchTrend(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-search-trend"
            >
              <DialogTitle id="topic-search-trend"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment Words",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {topicSearchTrendPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={topicSearchTrendPositiveDrillDownDataTableColumn}
                                      data={topicSearchTrendPositiveDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={topicSearchTrendPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment Words",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {topicSearchTrendNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={topicSearchTrendNegativeDrillDownDataTopicTableColumn}
                                    data={topicSearchTrendNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={topicSearchTrendNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicSearchTrendDrillDownDataTableColumn}
                                              data={topicSearchTrendDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicSearch(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicSearch}
                                          onClose={() => setFullReviewModalTopicSearch(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicSearch(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicSearchTrendDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicSearchTrend(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Topic Search - Topic Search -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicSearchTopicBreakdown}
              onClose={() => setOpenModalTopicSearchTopicBreakdown(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-search-topic-breakdown"
            >
              <DialogTitle id="topic-search-topic-breakdown"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment Words",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {topicSearchTopicBreakdownPositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={topicSearchTopicBreakdownPositiveDrillDownDataTableColumn}
                                      data={topicSearchTopicBreakdownPositiveDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={topicSearchTopicBreakdownPositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment Words",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {topicSearchTopicBreakdownNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={topicSearchTopicBreakdownNegativeDrillDownDataTopicTableColumn}
                                    data={topicSearchTopicBreakdownNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={topicSearchTopicBreakdownNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicSearchTopicBreakdowndDrillDownDataTableColumn}
                                              data={topicSearchTopicBreakdowndDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicSearchTopickBreakdown(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicSearchTopickBreakdown}
                                          onClose={() => setFullReviewModalTopicSearchTopickBreakdown(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicSearchTopickBreakdown(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicSearchTopicBreakdowndDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicSearchTopicBreakdown(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    





{/* Topic Search - Topic Combine -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicSearchTopicCombine}
              onClose={() => setOpenModalTopicSearchTopicCombine(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-search-topic-combine"
            >
              <DialogTitle id="topic-search-topic-combine"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                <GridContainer>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="success"
                        
                        tabs={[
                          {
                            tabName: "Positive Sentiment Words",
                            tabIcon: PositiveIcon,
                            tabContent: (!drillDownIsLoading ? 
                              <div style={{ height: 350}}>
                                {topicSearchTopicCombinePositiveDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                    <DataTable
                                      title=""
                                      columns={topicSearchTopicCombinePositiveDrillDownDataTableColumn}
                                      data={topicSearchTopicCombinePositiveDrillDownDataTableDataSet}
                                      //conditionalRowStyles={conditionalRowStyles}
                                      // pagination={true}
                                      // paginationPerPage={10}
                                      // paginationComponentOptions={{ noRowsPerPage: true }}
                                      fixedHeader={true}
                                      //fixedHeaderScrollHeight={"445px"}
                                      fixedHeaderScrollHeight={"300px"}
                                      noHeader={true}
                                    /> 
                                      <GridContainer>
                                      <GridItem 
                                        xs={12}
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center">
                                    
                                        <CSVLink data={topicSearchTopicCombinePositiveDrillDownData} filename={"Drill Down - Positive.csv"}>
                                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                            <DownloadIcon className={classes.icons} />
                                              Download CSV 
                                          </Button>
                                        </CSVLink>

                                      </GridItem>

                                    </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} >

                    <CustomTabs
                        //title="Tasks:"
                        centered={true}
                        headerColor="danger"
                        
                        tabs={[
                          {
                            tabName: "Negative Sentiment Words",
                            tabIcon: NegativeIcon,
                            tabContent: (!drillDownIsLoading ?  
                              <div style={{ height: 350}}>
                                {topicSearchTopicCombineNegativeDrillDownDataWordCloud()}
                              </div>
                            :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                          },
                          {
                            tabName: "Table",
                            tabIcon: AssignmentIcon,
                              tabContent: (
                                <div>
                                  <DataTable
                                    title=""
                                    columns={topicSearchTopicCombineNegativeDrillDownDataTopicTableColumn}
                                    data={topicSearchTopicCombineNegativeDrillDownDataTopicTableDataSet}
                                    //conditionalRowStyles={conditionalRowStyles}
                                    // pagination={true}
                                    // paginationPerPage={10}
                                    // paginationComponentOptions={{ noRowsPerPage: true }}
                                    fixedHeader={true}
                                    //fixedHeaderScrollHeight={"445px"}
                                    fixedHeaderScrollHeight={"300px"}
                                    noHeader={true}
                                  /> 
                                    <GridContainer>
                                    <GridItem 
                                      xs={12}
                                      container
                                      direction="row"
                                      justify="flex-end"
                                      alignItems="center">
                                  
                                      <CSVLink data={topicSearchTopicCombineNegativeDrillDownData} filename={"Drill Down - Negative.csv"}>
                                        <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                          <DownloadIcon className={classes.icons} />
                                            Download CSV 
                                        </Button>
                                      </CSVLink>

                                    </GridItem>

                                  </GridContainer>

                                </div>
                              )

                            
                          }
                        ]}
                      /> 

                  </GridItem>

                  </GridContainer>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicSearchTopicCombineDrillDownDataTableColumn}
                                              data={topicSearchTopicCombineDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicSearchTopicCombine(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicSearchTopicCombine}
                                          onClose={() => setFullReviewModalTopicSearchTopicCombine(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicSearchTopicCombine(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicSearchTopicCombineDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicSearchTopicCombine(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    





{/* Topic Search - Positive Sentiment Word -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicSearchSentimentPositive}
              onClose={() => setOpenModalTopicSearchSentimentPositive(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-search-sentiment-word-pos"
            >
              <DialogTitle id="topic-search-sentiment-word-pos"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicSearchTopicPositiveSentimentDrillDownDataTableColumn}
                                              data={topicSearchTopicPositiveSentimentDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicSearchSentimentPositive(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicSearchSentimentNegative}
                                          onClose={() => setFullReviewModalTopicSearchSentimentPositive(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicSearchSentimentPositive(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicSearchTopicPositiveSentimentDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicSearchSentimentPositive(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    




{/* Topic Search - Negative Sentiment Word -  Drill Down */}
<div>
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={openModalTopicSearchSentimentNegative}
              onClose={() => setOpenModalTopicSearchSentimentPositive(false)}
              //TransitionComponent={Transition}
              aria-labelledby="topic-search-sentiment-word-neg"
            >
              <DialogTitle id="topic-search-sentiment-word-neg"> {`${"Drill Down: "}${modalTitle}`}</DialogTitle>

              <DialogContent>

                    <GridContainer>

                    <GridItem xs={12} sm={12} md={12} >

                      <CustomTabs
                          //title="Tasks:"
                          centered={true}
                          headerColor="primary"
                          
                          tabs={[
                            {
                              tabName: "View Individual Sentence",
                              tabIcon: AssignmentIcon,
                                tabContent: (!drillDownIsLoading ?  
                                  <div>
                                    
                                    <div style={{ maxWidth: '100%' }}>
                                            <MaterialTable
                                              columns={topicSearchTopicNegativeSentimentDrillDownDataTableColumn}
                                              data={topicSearchTopicNegativeSentimentDrillDownDataTableDataSet}
                                              title="SMAT Review"
                                              options={{
                                                exportButton: true,
                                                exportAllData: true,
                                                filtering: true,
                                                showTitle: false
                                              }}
                                              actions={[
                                                {
                                                  icon: TopicIcon,
                                                  tooltip: 'Show Full Review',
                                                  onClick: (event, rowData) => {
                                                    // Grab the data from the table row that was clicked and pass it to onClickFullReview to grab the full review
                                                    onClickFullReview(rowData.model, rowData.country, rowData.website, rowData.reviewID, ()=>{});
                                                    setFullReviewModalTopicSearchSentimentNegative(true);
      
                                                  }

                                                }
                                              ]}
                                              components={{
                                                Action: props => (

                                                  <Tooltip
                                                    id="tooltip-bottom"
                                                    title="Show Full Review"
                                                    placement="bottom"
                                                    classes={{ tooltip: classes.tooltip }}
                                                  >
                                                      <Button color="rose" size="sm" onClick={(event) => props.action.onClick(event, props.data)}>
                                                        <FullReviewIcon />
                                                      </Button>
                                                  </Tooltip>
                                                  
                                                )
                                              }}
                                            />
                                      </div>

                                      <div>
                                        <Dialog
                                          fullWidth={true}
                                          maxWidth={'lg'}
                                          open={fullReviewModalTopicSearchSentimentNegative}
                                          onClose={() => setFullReviewModalTopicSearchSentimentNegative(false)}
                                          //TransitionComponent={Transition}
                                          aria-labelledby="full-review-dialog-title"
                                        >
                                          <DialogTitle id="full-review-dialog-title"><strong> Full Review </strong></DialogTitle>
                                          <DialogContent>
                                            {/* <DialogContentText> */}
                                              {(!fullReviewDrillDownIsLoading ? fullReviewData :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )}
                                            {/* </DialogContentText> */}
                                          
                                          </DialogContent>
                                          <DialogActions>
                                            <Button onClick={() => setFullReviewModalTopicSearchSentimentNegative(false)} color="primary">
                                              Close
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </div>
                                <GridContainer>
                                <GridItem 
                                  xs={12}
                                  container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center">
                                  <CSVLink data={topicSearchTopicNegativeSentimentDrillDownData} filename={"View Individual Sentence.csv"}>
                                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                      <DownloadIcon className={classes.icons} />
                                        Download CSV 
                                    </Button>
                                  </CSVLink>
                                  

                                </GridItem>

                              </GridContainer>

                                  </div>
                                :  <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                              
                            }
                          ]}
                        /> 

                    </GridItem>

            </GridContainer>


              
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModalTopicSearchSentimentNegative(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
        </div>    






{/* Sample Modal */}
    {/* <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          Open max-width dialog
        </Button>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={open}
          onClose={handleClose}
          //TransitionComponent={Transition}
          aria-labelledby="full-review-dialog-title11"
        >
          <DialogTitle id="full-review-dialog-title11"><strong> Full Review </strong></DialogTitle>
          <DialogContent>
            <DialogContentText>


            </DialogContentText>
          
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    </div>     */}
{/* Sample Modal */}
    
    </div> </div>  : <LinearProgressWithLabel value={progress} style={{textAlign: 'center'}}/>
    )
    // <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
    
  );
}
