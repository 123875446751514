/*eslint-disable*/

import React from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"

// 3rd Party Component
import { Auth, Amplify } from "aws-amplify";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStylesSweetAlert = makeStyles(stylesSweetAlert);


export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });



  React.useEffect(() => {
   
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      },
    });


  });


  // Alert/Modal
  const [alert, setAlert] = React.useState(null);

  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");

  const history = useHistory();
  const homePage = () => history.push('/admin/home');


  const failAlert = (title, body) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
      >
        {body}
      </SweetAlert>
    );
  };


  const hideAlert = () => {
    setAlert(null);
  };



  const logUser = (emailData) => {

    let restGETurl = `${"userEmail="}${emailData}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
  
    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].postLogin}${restGETurl}`).then(function (response) { 
    // handle success
  
  
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });




  }

  function onClickReset() {

    let resetUrl = "https://reset.smatreview.com/login?client_id=1fostuamrc1nltq81pccbao5ip&response_type=code&scope=openid&redirect_uri=https://smatreview.com"
    window.open(resetUrl)
  
  }



  const onClickSignIn = () => {


    Auth.signIn({
      username: userName,
      password: password,
    })
      .then((user) => {

        logUser(user.attributes['email']);

        // Take user to the Dashboard Homepage
        document.cookie = "auth=true";
        homePage();
        
      })
      .catch((err) => {
        console.log(err);
        document.cookie = "auth=false";

        failAlert("Login Error", "Incorrect Username or Password");

      });


  }



  const classes = useStyles();
  const classesSweetAlert = useStylesSweetAlert();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>SMAT Dashboard</h4>
                <div className={classes.socialLine}>
                  {[
                    "fas fa-chart-bar",
                    "fas fa-chart-area",
                    "fas fa-chart-pie"
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        disabled
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  id="UserNameSelection"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onBlur: event => {setUserName(event.target.value)},
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onBlur: event => {setPassword(event.target.value)},
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block onClick={onClickSignIn}>
                  Let{"'"}s Go
                </Button>

                <Button color="rose" simple size="lg" block onClick={onClickReset}>
                  Reset Password
                </Button>

              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>

{/* Sweet Alert */}
      {alert}

    </div>
  );
}
