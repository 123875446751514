/*eslint-disable*/

import React, { Fragment } from "react";

// CSS
import "../../assets/css/page-loading.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import Instruction from "components/Instruction/Instruction.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';



// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import CalculationIcon from "@material-ui/icons/Grade";
import BubbleIcon from "@material-ui/icons/BubbleChart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/Loyalty";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import RatingIcon from "@material-ui/icons/RateReview";
import FilterIcon from "@material-ui/icons/FilterList";
import Warning from "@material-ui/icons/Warning";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
import SoftwareIcon from "@material-ui/icons/Apps";
import PictureIcon from "@material-ui/icons/Image";
import PriceIcon from "@material-ui/icons/AttachMoney";
import DesignIcon from "@material-ui/icons/DataUsage";
import FeatureIcon from "@material-ui/icons/Settings";
import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";


import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"


// 3rd Party Component
import { Bubble, Line, Doughnut, Radar, Bar, Chart, HorizontalBar, Pie } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);


const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



function LinearProgressWithLabel(props) {
  return (
    <div>
      
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" color="secondary" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="h4" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};





export default function CompetitorAnalytic() {


  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Competitor Analytics"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])


  
  // Loading Param
  const [progressBarIsLoading, setProgressBarIsLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(10);


  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");

  // Loading by Section
  const [appDataDataIsLoading, setAppDataDataIsLoading] = React.useState(false);

  // filters
  const [allFilters, setAllFilters] = React.useState([]);

  const [brandItems1, setBrandItems1] = React.useState(['']);
  const [brandSelection1, setBrandSelection1] = React.useState('');

  const [categoryItems1, setCategoryItems1] = React.useState([]);
  const [categorySelection1, setCategorySelection1] = React.useState('');

  const [modelItems1, setModelItems1] = React.useState([]);
  const [modelSelection1, setModelSelection1] = React.useState([]);

  const [dateStartItem1, setDateStartItem1] = React.useState('');
  const [dateStartSelection1, setDateStartSelection1] = React.useState('');

  const [dateEndItem1, setDateEndItem1] = React.useState('');
  const [dateEndSelection1, setDateEndSelection1] = React.useState('');



  const [brandItems2, setBrandItems2] = React.useState(['']);
  const [brandSelection2, setBrandSelection2] = React.useState('');

  const [categoryItems2, setCategoryItems2] = React.useState([]);
  const [categorySelection2, setCategorySelection2] = React.useState('');

  const [modelItems2, setModelItems2] = React.useState([]);
  const [modelSelection2, setModelSelection2] = React.useState([]);

  const [dateStartItem2, setDateStartItem2] = React.useState('');
  const [dateStartSelection2, setDateStartSelection2] = React.useState('');

  const [dateEndItem2, setDateEndItem2] = React.useState('');
  const [dateEndSelection2, setDateEndSelection2] = React.useState('');


  const [brandItems3, setBrandItems3] = React.useState(['']);
  const [brandSelection3, setBrandSelection3] = React.useState('');

  const [categoryItems3, setCategoryItems3] = React.useState([]);
  const [categorySelection3, setCategorySelection3] = React.useState('');

  const [modelItems3, setModelItems3] = React.useState([]);
  const [modelSelection3, setModelSelection3] = React.useState([]);

  const [dateStartItem3, setDateStartItem3] = React.useState('');
  const [dateStartSelection3, setDateStartSelection3] = React.useState('');

  const [dateEndItem3, setDateEndItem3] = React.useState('');
  const [dateEndSelection3, setDateEndSelection3] = React.useState('');


  const [brandItems4, setBrandItems4] = React.useState(['']);
  const [brandSelection4, setBrandSelection4] = React.useState('');

  const [categoryItems4, setCategoryItems4] = React.useState([]);
  const [categorySelection4, setCategorySelection4] = React.useState('');

  const [modelItems4, setModelItems4] = React.useState([]);
  const [modelSelection4, setModelSelection4] = React.useState([]);

  const [dateStartItem4, setDateStartItem4] = React.useState('');
  const [dateStartSelection4, setDateStartSelection4] = React.useState('');

  const [dateEndItem4, setDateEndItem4] = React.useState('');
  const [dateEndSelection4, setDateEndSelection4] = React.useState('');


  const [brandItems5, setBrandItems5] = React.useState(['']);
  const [brandSelection5, setBrandSelection5] = React.useState('');

  const [categoryItems5, setCategoryItems5] = React.useState([]);
  const [categorySelection5, setCategorySelection5] = React.useState('');

  const [modelItems5, setModelItems5] = React.useState([]);
  const [modelSelection5, setModelSelection5] = React.useState([]);

  const [dateStartItem5, setDateStartItem5] = React.useState('');
  const [dateStartSelection5, setDateStartSelection5] = React.useState('');

  const [dateEndItem5, setDateEndItem5] = React.useState('');
  const [dateEndSelection5, setDateEndSelection5] = React.useState('');



  const [brandItems6, setBrandItems6] = React.useState(['']);
  const [brandSelection6, setBrandSelection6] = React.useState('');

  const [categoryItems6, setCategoryItems6] = React.useState([]);
  const [categorySelection6, setCategorySelection6] = React.useState('');

  const [modelItems6, setModelItems6] = React.useState([]);
  const [modelSelection6, setModelSelection6] = React.useState([]);

  const [dateStartItem6, setDateStartItem6] = React.useState('');
  const [dateStartSelection6, setDateStartSelection6] = React.useState('');

  const [dateEndItem6, setDateEndItem6] = React.useState('');
  const [dateEndSelection6, setDateEndSelection6] = React.useState('');

  const [uniqueTopic, setUniqueTopic] = React.useState(["Yes", "No (Recommended)"]);
  const [uniqueTopicSelection, setUniqueTopicSelection] = React.useState("No (Recommended)");


  const [countryItems1, setCountryItems1] = React.useState([]);
  const [countrySelection1, setCountrySelection1] = React.useState([]);

  const [countryItems2, setCountryItems2] = React.useState([]);
  const [countrySelection2, setCountrySelection2] = React.useState([]);

  const [countryItems3, setCountryItems3] = React.useState([]);
  const [countrySelection3, setCountrySelection3] = React.useState([]);

  const [countryItems4, setCountryItems4] = React.useState([]);
  const [countrySelection4, setCountrySelection4] = React.useState([]);

  const [countryItems5, setCountryItems5] = React.useState([]);
  const [countrySelection5, setCountrySelection5] = React.useState([]);

  const [countryItems6, setCountryItems6] = React.useState([]);
  const [countrySelection6, setCountrySelection6] = React.useState([]);


  // Bubble Data
  const [bubbleData, setBubbleData] = React.useState([]);

  // Radar Data
  const [radarData, setRadarData] = React.useState([]);

  // Star Rating and Color
  const [ratingAvg1, setRatingAvg1] = React.useState(0);
  const [starColor1, setStarColor1] = React.useState(['info']);

  const [ratingAvg2, setRatingAvg2] = React.useState(0);
  const [starColor2, setStarColor2] = React.useState('info');

  const [ratingAvg3, setRatingAvg3] = React.useState(0);
  const [starColor3, setStarColor3] = React.useState('info');

  const [ratingAvg4, setRatingAvg4] = React.useState(0);
  const [starColor4, setStarColor4] = React.useState('info');

  const [ratingAvg5, setRatingAvg5] = React.useState(0);
  const [starColor5, setStarColor5] = React.useState('info');

  const [ratingAvg6, setRatingAvg6] = React.useState(0);
  const [starColor6, setStarColor6] = React.useState('info');

  // Star Rating Pie Data
  const [ratingPie1, setRatingPie1] = React.useState([]);
  const [ratingPie2, setRatingPie2] = React.useState([]);
  const [ratingPie3, setRatingPie3] = React.useState([]);
  const [ratingPie4, setRatingPie4] = React.useState([]);
  const [ratingPie5, setRatingPie5] = React.useState([]);
  const [ratingPie6, setRatingPie6] = React.useState([]);

  // Category Sentiment Data
  const [categorySentimentData1, setCategorySentimentData1] = React.useState([]);
  const [categorySentimentData2, setCategorySentimentData2] = React.useState([]);
  const [categorySentimentData3, setCategorySentimentData3] = React.useState([]);
  const [categorySentimentData4, setCategorySentimentData4] = React.useState([]);
  const [categorySentimentData5, setCategorySentimentData5] = React.useState([]);
  const [categorySentimentData6, setCategorySentimentData6] = React.useState([]);

  // Topic Data
  const [topicSentimentData1, setTopicSentimentData1] = React.useState([]);
  const [topicSentimentData2, setTopicSentimentData2] = React.useState([]);
  const [topicSentimentData3, setTopicSentimentData3] = React.useState([]);
  const [topicSentimentData4, setTopicSentimentData4] = React.useState([]);
  const [topicSentimentData5, setTopicSentimentData5] = React.useState([]);
  const [topicSentimentData6, setTopicSentimentData6] = React.useState([]);

  const [categoryFilter1, setCategoryFilter1] = React.useState('All');
  const [categoryFilter2, setCategoryFilter2] = React.useState('All');
  const [categoryFilter3, setCategoryFilter3] = React.useState('All');
  const [categoryFilter4, setCategoryFilter4] = React.useState('All');
  const [categoryFilter5, setCategoryFilter5] = React.useState('All');
  const [categoryFilter6, setCategoryFilter6] = React.useState('All');


  // Sentiment Trend and Pie Data
  const [sentimentTrendData1, setSentimentTrendData1] = React.useState([]);
  const [sentimentTrendData2, setSentimentTrendData2] = React.useState([]);
  const [sentimentTrendData3, setSentimentTrendData3] = React.useState([]);
  const [sentimentTrendData4, setSentimentTrendData4] = React.useState([]);
  const [sentimentTrendData5, setSentimentTrendData5] = React.useState([]);
  const [sentimentTrendData6, setSentimentTrendData6] = React.useState([]);

  const [sentimentPieData1, setSentimentPieData1] = React.useState([]);
  const [sentimentPieData2, setSentimentPieData2] = React.useState([]);
  const [sentimentPieData3, setSentimentPieData3] = React.useState([]);
  const [sentimentPieData4, setSentimentPieData4] = React.useState([]);
  const [sentimentPieData5, setSentimentPieData5] = React.useState([]);
  const [sentimentPieData6, setSentimentPieData6] = React.useState([]);

  

  React.useEffect(() => {
  
  
    const axios = require('axios');
    axios.get(SMATrestAPI.generalFilter[0].allModels).then(function (response) { 
      // handle success

      setAllFilters(response.data.getFilter);

      let brandFilter = response.data.getFilter.map(data => data['Brand']);
      let uniqueBrand = [...new Set(brandFilter)];
      uniqueBrand.unshift("Sony");

      setBrandItems1(uniqueBrand);
      setBrandItems2(uniqueBrand);
      setBrandItems3(uniqueBrand);
      setBrandItems4(uniqueBrand);
      setBrandItems5(uniqueBrand);
      setBrandItems6(uniqueBrand);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });
  
  
    }, [])




  
    const handleBrand1= event => {
      setBrandSelection1(event.target.value);


      let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

      let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
      uniqueCategory = [...new Set(uniqueCategory)];
      uniqueCategory = uniqueCategory.sort();

      setCategoryItems1(uniqueCategory);

      setCategorySelection1('');
      setModelSelection1([]);
      setCountrySelection1([]);
      setDateStartSelection1('');
      setDateEndSelection1('');


    };
  
    const handleCategory1 = event => {
      setCategorySelection1(event.target.value);



      let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection1);
      modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

      let uniqueModel = modelFilter.map(data => data['Model']);
      uniqueModel = [...new Set(uniqueModel)];
      uniqueModel = uniqueModel.sort();

      setModelItems1(uniqueModel);


      setModelSelection1([]);
      setCountrySelection1([]);
      setDateStartSelection1('');
      setDateEndSelection1('');
    };
  


    ////////////////////////////

    const handleModel1 = event => {
      setModelSelection1(event.target.value);
      

      let brandFilter = allFilters.filter(data => data['Brand'] === brandSelection1);
      brandFilter = brandFilter.filter(data => data['Product.Category'] === categorySelection1);

      let selectedItems = event.target.value;
      selectedItems = brandFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9]);

      let uniqueCountry = selectedItems.map(data => data['Country']);
      uniqueCountry = [...new Set(uniqueCountry)];
      uniqueCountry = uniqueCountry.sort();

      setCountryItems1(uniqueCountry);

      setCountrySelection1([]);  
      setDateStartSelection1('');
      setDateEndSelection1('');




    };



    const handleCountry1 = event => {
      setCountrySelection1(event.target.value);


      let restGETurl = `${"brand="}${brandSelection1}${"&model="}${modelSelection1}${"&country="}${event.target.value}`;
      restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
      restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
    
      const axios = require('axios');
    
      axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
        // handle success
    
      setDateStartItem1(response.data.startDate);
      setDateEndItem1(response.data.endDate);
    
      setDateStartSelection1(response.data.startDate);
      setDateEndSelection1(response.data.endDate);

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });

      
    }; 
    
    const handleChangeStartDate1 = event => {

      // Prevent function error if user manually inputs
      if (typeof event === 'object') {
        let aDate = event.format();
        aDate = aDate.substring(0, 10);
  
        setDateStartSelection1(aDate);
      }


    }

    const handleChangeEndDate1 = event => {

      // Prevent function error if user manually inputs
      if (typeof event === 'object') {
        let aDate = event.format();
        aDate = aDate.substring(0, 10);
        
        setDateEndSelection1(aDate);
      }
      

      

    }


  let yesterday1 = moment(dateStartItem1, 'YYYY-MM-DD').subtract(1, "day");
  function validStart1(current) {
    return current.isAfter(yesterday1);
  }

    
  let tommorrow1 = moment(dateEndItem1, 'YYYY-MM-DD').add(1, "day");
  function validEnd1(current) {
    return current.isBefore(tommorrow1);
  }  




  
const handleBrand2= event => {
  setBrandSelection2(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems2(uniqueCategory);

  setCategorySelection2('');
  setModelSelection2([]);
  setCountrySelection2([]);   
  setDateStartSelection2('');
  setDateEndSelection2('');


};

const handleCategory2 = event => {
  setCategorySelection2(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection2);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems2(uniqueModel);


  setModelSelection2([]);
  setCountrySelection2([]);  
  setDateStartSelection2('');
  setDateEndSelection2('');
};

const handleModel2 = event => {
  setModelSelection2(event.target.value);
  

  let brandFilter = allFilters.filter(data => data['Brand'] === brandSelection2);
  brandFilter = brandFilter.filter(data => data['Product.Category'] === categorySelection2);

  let selectedItems = event.target.value;
  selectedItems = brandFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9]);

  let uniqueCountry = selectedItems.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems2(uniqueCountry);

  setCountrySelection2([]);  
  setDateStartSelection2('');
  setDateEndSelection2('');


};


const handleCountry2 = event => {
  setCountrySelection2(event.target.value);


  let restGETurl = `${"brand="}${brandSelection2}${"&model="}${modelSelection2}${"&country="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
    // handle success

  setDateStartItem2(response.data.startDate);
  setDateEndItem2(response.data.endDate);

  setDateStartSelection2(response.data.startDate);
  setDateEndSelection2(response.data.endDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
}; 

const handleChangeStartDate2 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  
    setDateStartSelection2(aDate);
  }
  


}

const handleChangeEndDate2 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
    
    //setDateEndItem2(aDate);
    setDateEndSelection2(aDate);
  }

}


let yesterday2 = moment(dateStartItem2, 'YYYY-MM-DD').subtract(1, "day");
function validStart2(current) {
return current.isAfter(yesterday2);
}


let tommorrow2 = moment(dateEndItem2, 'YYYY-MM-DD').add(1, "day");
function validEnd2(current) {
return current.isBefore(tommorrow2);
}  





const handleBrand3= event => {
  setBrandSelection3(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems3(uniqueCategory);

  setCategorySelection3('');
  setModelSelection3([]);
  setCountrySelection3([]);  
  setDateStartSelection3('');
  setDateEndSelection3('');


};

const handleCategory3 = event => {
  setCategorySelection3(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection3);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems3(uniqueModel);


  setModelSelection3([]);
  setCountrySelection3([]);  
  setDateStartSelection3('');
  setDateEndSelection3('');
};

const handleModel3 = event => {
  setModelSelection3(event.target.value);
  

  let brandFilter = allFilters.filter(data => data['Brand'] === brandSelection3);
  brandFilter = brandFilter.filter(data => data['Product.Category'] === categorySelection3);

  let selectedItems = event.target.value;
  selectedItems = brandFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9]);

  let uniqueCountry = selectedItems.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems3(uniqueCountry);

  setCountrySelection3([]);  
  setDateStartSelection3('');
  setDateEndSelection3('');



};


const handleCountry3 = event => {
  setCountrySelection3(event.target.value);


  let restGETurl = `${"brand="}${brandSelection3}${"&model="}${modelSelection3}${"&country="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
    // handle success

  setDateStartItem3(response.data.startDate);
  setDateEndItem3(response.data.endDate);

  setDateStartSelection3(response.data.startDate);
  setDateEndSelection3(response.data.endDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
}; 


const handleChangeStartDate3 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  
  
    setDateStartSelection3(aDate);
  }

}

const handleChangeEndDate3 = event => {
  

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  

    setDateEndSelection3(aDate);
  }

}


let yesterday3 = moment(dateStartItem3, 'YYYY-MM-DD').subtract(1, "day");
function validStart3(current) {
return current.isAfter(yesterday3);
}


let tommorrow3 = moment(dateEndItem3, 'YYYY-MM-DD').add(1, "day");
function validEnd3(current) {
return current.isBefore(tommorrow3);
}  




const handleBrand4= event => {
  setBrandSelection4(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems4(uniqueCategory);

  setCategorySelection4('');
  setModelSelection4([]);
  setCountrySelection4([]);  
  setDateStartSelection4('');
  setDateEndSelection4('');


};

const handleCategory4 = event => {
  setCategorySelection4(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection4);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems4(uniqueModel);


  setModelSelection4([]);
  setCountrySelection4([]);
  setDateStartSelection4('');
  setDateEndSelection4('');
};

const handleModel4 = event => {
  setModelSelection4(event.target.value);
  

  let brandFilter = allFilters.filter(data => data['Brand'] === brandSelection4);
  brandFilter = brandFilter.filter(data => data['Product.Category'] === categorySelection4);

  let selectedItems = event.target.value;
  selectedItems = brandFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9]);

  let uniqueCountry = selectedItems.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems4(uniqueCountry);

  setCountrySelection4([]); 
  setDateStartSelection4('');
  setDateEndSelection4('');



};


const handleCountry4 = event => {
  setCountrySelection4(event.target.value);


  let restGETurl = `${"brand="}${brandSelection4}${"&model="}${modelSelection4}${"&country="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
    // handle success

  setDateStartItem4(response.data.startDate);
  setDateEndItem4(response.data.endDate);

  setDateStartSelection4(response.data.startDate);
  setDateEndSelection4(response.data.endDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
}; 


const handleChangeStartDate4 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);


    setDateStartSelection4(aDate);
  }

}

const handleChangeEndDate4 = event => {


  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
    
  
    setDateEndSelection4(aDate);
  }

}


let yesterday4 = moment(dateStartItem4, 'YYYY-MM-DD').subtract(1, "day");
function validStart4(current) {
return current.isAfter(yesterday4);
}


let tommorrow4 = moment(dateEndItem4, 'YYYY-MM-DD').add(1, "day");
function validEnd4(current) {
return current.isBefore(tommorrow4);
}  



const handleBrand5= event => {
  setBrandSelection5(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems5(uniqueCategory);

  setCategorySelection5('');
  setModelSelection5([]);
  setCountrySelection5([]);  
  setDateStartSelection5('');
  setDateEndSelection5('');


};

const handleCategory5 = event => {
  setCategorySelection5(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection5);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems5(uniqueModel);


  setModelSelection5([]);
  setCountrySelection5([]); 
  setDateStartSelection5('');
  setDateEndSelection5('');
};

const handleModel5 = event => {
  setModelSelection5(event.target.value);
  let brandFilter = allFilters.filter(data => data['Brand'] === brandSelection5);
  brandFilter = brandFilter.filter(data => data['Product.Category'] === categorySelection5);

  let selectedItems = event.target.value;
  selectedItems = brandFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9]);

  let uniqueCountry = selectedItems.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems5(uniqueCountry);

  setCountrySelection5([]); 
  setDateStartSelection5('');
  setDateEndSelection5('');



};


const handleCountry5 = event => {
  setCountrySelection5(event.target.value);


  let restGETurl = `${"brand="}${brandSelection5}${"&model="}${modelSelection5}${"&country="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
    // handle success

  setDateStartItem5(response.data.startDate);
  setDateEndItem5(response.data.endDate);

  setDateStartSelection5(response.data.startDate);
  setDateEndSelection5(response.data.endDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
}; 

const handleChangeStartDate5 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);


    setDateStartSelection5(aDate);
  }

}

const handleChangeEndDate5 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);

    setDateEndSelection5(aDate);
  }

}


let yesterday5 = moment(dateStartItem5, 'YYYY-MM-DD').subtract(1, "day");
function validStart5(current) {
return current.isAfter(yesterday5);
}


let tommorrow5 = moment(dateEndItem5, 'YYYY-MM-DD').add(1, "day");
function validEnd5(current) {
return current.isBefore(tommorrow5);
}  



const handleBrand6= event => {
  setBrandSelection6(event.target.value);


  let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

  let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
  uniqueCategory = [...new Set(uniqueCategory)];
  uniqueCategory = uniqueCategory.sort();

  setCategoryItems6(uniqueCategory);

  setCategorySelection6('');
  setModelSelection6([]);
  setCountrySelection6([]); 
  setDateStartSelection6('');
  setDateEndSelection6('');


};

const handleCategory6 = event => {
  setCategorySelection6(event.target.value);



  let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection6);
  modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

  let uniqueModel = modelFilter.map(data => data['Model']);
  uniqueModel = [...new Set(uniqueModel)];
  uniqueModel = uniqueModel.sort();

  setModelItems6(uniqueModel);


  setModelSelection6([]);
  setCountrySelection6([]); 
  setDateStartSelection6('');
  setDateEndSelection6('');
};

const handleModel6 = event => {
  setModelSelection6(event.target.value);
  
  let brandFilter = allFilters.filter(data => data['Brand'] === brandSelection6);
  brandFilter = brandFilter.filter(data => data['Product.Category'] === categorySelection6);

  let selectedItems = event.target.value;
  selectedItems = brandFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9]);

  let uniqueCountry = selectedItems.map(data => data['Country']);
  uniqueCountry = [...new Set(uniqueCountry)];
  uniqueCountry = uniqueCountry.sort();

  setCountryItems6(uniqueCountry);

  setCountrySelection6([]);
  setDateStartSelection6('');
  setDateEndSelection6('');




};


const handleCountry6 = event => {
  setCountrySelection6(event.target.value);


  let restGETurl = `${"brand="}${brandSelection6}${"&model="}${modelSelection6}${"&country="}${event.target.value}`;
  restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
  restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

  const axios = require('axios');

  axios.get(`${SMATrestAPI.competitorAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
    // handle success

  setDateStartItem6(response.data.startDate);
  setDateEndItem6(response.data.endDate);

  setDateStartSelection6(response.data.startDate);
  setDateEndSelection6(response.data.endDate);

  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
  });

  
}; 



const handleChangeStartDate6 = event => {


  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);


    setDateStartSelection6(aDate);
  }

}

const handleChangeEndDate6 = event => {

  // Prevent function error if user manually inputs
  if (typeof event === 'object') {
    let aDate = event.format();
    aDate = aDate.substring(0, 10);
  

    setDateEndSelection6(aDate);
  }

}


let yesterday6 = moment(dateStartItem6, 'YYYY-MM-DD').subtract(1, "day");
function validStart6(current) {
return current.isAfter(yesterday6);
}


let tommorrow6 = moment(dateEndItem6, 'YYYY-MM-DD').add(1, "day");
function validEnd6(current) {
return current.isBefore(tommorrow6);
}  



const handleUniqueTopic = event => {
  setUniqueTopicSelection(event.target.value);
};




  const onClickChartData = event => {
    setProgressBarIsLoading(true);

    setProgress(0);
    setProgress(prevCount => prevCount + 10);

    const axios = require('axios');

    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic"}${"&brand="}${brandSelection1}${"&category="}${categorySelection1}${"&model="}${modelSelection1}${"&website="}${"none"}${"&country="}${"none"}`; // countrySelection1
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic"}${"&brand="}${brandSelection2}${"&category="}${categorySelection2}${"&model="}${modelSelection2}${"&website="}${"none"}${"&country="}${"none"}`; // countrySelection2
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic"}${"&brand="}${brandSelection3}${"&category="}${categorySelection3}${"&model="}${modelSelection3}${"&website="}${"none"}${"&country="}${"none"}`; // countrySelection3
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic"}${"&brand="}${brandSelection4}${"&category="}${categorySelection4}${"&model="}${modelSelection4}${"&website="}${"none"}${"&country="}${"none"}`; // countrySelection4
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });


    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic"}${"&brand="}${brandSelection5}${"&category="}${categorySelection5}${"&model="}${modelSelection5}${"&website="}${"none"}${"&country="}${"none"}`; // countrySelection5
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

    // Send Model Information to SMAT DB after user clicks submit
    var restGETurl00 = `${"page="}${"Competitor Analytic"}${"&brand="}${brandSelection6}${"&category="}${categorySelection6}${"&model="}${modelSelection6}${"&website="}${"none"}${"&country="}${"none"}`; // countrySelection6
    restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
    restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });
    
    
    // Bubble Data

    let restGETurl1 = `${"&model1="}${modelSelection1}${"&model2="}${modelSelection2}${"&model3="}${modelSelection3}${"&model4="}${modelSelection4}${"&model5="}${modelSelection5}${"&model6="}${modelSelection6}${"&country1="}${countrySelection1}${"&country2="}${countrySelection2}${"&country3="}${countrySelection3}${"&country4="}${countrySelection4}${"&country5="}${countrySelection5}${"&country6="}${countrySelection6}${"&startDate1="}${dateStartSelection1}${"&startDate2="}${dateStartSelection2}${"&startDate3="}${dateStartSelection3}${"&startDate4="}${dateStartSelection4}${"&startDate5="}${dateStartSelection5}${"&startDate6="}${dateStartSelection6}${"&endDate1="}${dateEndSelection1}${"&endDate2="}${dateEndSelection2}${"&endDate3="}${dateEndSelection3}${"&endDate4="}${dateEndSelection4}${"&endDate5="}${dateEndSelection5}${"&endDate6="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].bubbleData}${restGETurl1}`).then(function (response) { 
      // handle success

      setBubbleData(response.data.bubbleTable);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);

    });




    // Radar Data

    let restGETurl2 = `${"&model1="}${modelSelection1}${"&model2="}${modelSelection2}${"&model3="}${modelSelection3}${"&model4="}${modelSelection4}${"&model5="}${modelSelection5}${"&model6="}${modelSelection6}${"&country1="}${countrySelection1}${"&country2="}${countrySelection2}${"&country3="}${countrySelection3}${"&country4="}${countrySelection4}${"&country5="}${countrySelection5}${"&country6="}${countrySelection6}${"&category1="}${categorySelection1}${"&category2="}${categorySelection2}${"&category3="}${categorySelection3}${"&category4="}${categorySelection4}${"&category5="}${categorySelection5}${"&category6="}${categorySelection6}${"&startDate1="}${dateStartSelection1}${"&startDate2="}${dateStartSelection2}${"&startDate3="}${dateStartSelection3}${"&startDate4="}${dateStartSelection4}${"&startDate5="}${dateStartSelection5}${"&startDate6="}${dateStartSelection6}${"&endDate1="}${dateEndSelection1}${"&endDate2="}${dateEndSelection2}${"&endDate3="}${dateEndSelection3}${"&endDate4="}${dateEndSelection4}${"&endDate5="}${dateEndSelection5}${"&endDate6="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
    restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].radarData}${restGETurl2}`).then(function (response) { 
      // handle success

      setRadarData(response.data.radarData);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
  

    });


    // Star Rating and Color Data - Model 1

    let restGETurl3 = `${"&brand="}${brandSelection1}${"&model="}${modelSelection1}${"&country="}${countrySelection1}${"&category="}${categorySelection1}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
    restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].ratingData}${restGETurl3}`).then(function (response) { 
      // handle success

      let restData = response.data.starRating;

      setRatingAvg1(restData.map(data => data.Rating));
      setStarColor1(restData.map(data => data.Color));
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);

    });


    // Star Rating and Color Data - Model 2

    let restGETurl4 = `${"&brand="}${brandSelection2}${"&model="}${modelSelection2}${"&country="}${countrySelection2}${"&category="}${categorySelection2}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl4 = restGETurl4.replaceAll(",", '%2C'); // replace comma for url
    restGETurl4 = restGETurl4.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].ratingData}${restGETurl4}`).then(function (response) { 
      // handle success

      let restData = response.data.starRating;

      setRatingAvg2(restData.map(data => data.Rating));
      setStarColor2(restData.map(data => data.Color));

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });


    // Star Rating and Color Data - Model 3

    let restGETurl5 = `${"&brand="}${brandSelection3}${"&model="}${modelSelection3}${"&country="}${countrySelection3}${"&category="}${categorySelection3}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl5 = restGETurl5.replaceAll(",", '%2C'); // replace comma for url
    restGETurl5 = restGETurl5.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].ratingData}${restGETurl5}`).then(function (response) { 
      // handle success

      let restData = response.data.starRating;

      setRatingAvg3(restData.map(data => data.Rating));
      setStarColor3(restData.map(data => data.Color));

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });


    // Star Rating and Color Data - Model 4

    let restGETurl6 = `${"&brand="}${brandSelection4}${"&model="}${modelSelection4}${"&country="}${countrySelection4}${"&category="}${categorySelection4}${"&startDate="}${dateStartSelection4}${"&endDate="}${dateEndSelection4}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl6 = restGETurl6.replaceAll(",", '%2C'); // replace comma for url
    restGETurl6 = restGETurl6.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].ratingData}${restGETurl6}`).then(function (response) { 
      // handle success

      let restData = response.data.starRating;

      setRatingAvg4(restData.map(data => data.Rating));
      setStarColor4(restData.map(data => data.Color));

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });


    // Star Rating and Color Data - Model 5

    let restGETurl7 = `${"&brand="}${brandSelection5}${"&model="}${modelSelection5}${"&country="}${countrySelection5}${"&category="}${categorySelection5}${"&startDate="}${dateStartSelection5}${"&endDate="}${dateEndSelection5}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl7 = restGETurl7.replaceAll(",", '%2C'); // replace comma for url
    restGETurl7 = restGETurl7.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].ratingData}${restGETurl7}`).then(function (response) { 
      // handle success

      let restData = response.data.starRating;

      setRatingAvg5(restData.map(data => data.Rating));
      setStarColor5(restData.map(data => data.Color));

      console.table(restData);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });


    // Star Rating and Color Data - Model 6

    let restGETurl8 = `${"&brand="}${brandSelection6}${"&model="}${modelSelection6}${"&country="}${countrySelection6}${"&category="}${categorySelection6}${"&startDate="}${dateStartSelection6}${"&endDate="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl8 = restGETurl8.replaceAll(",", '%2C'); // replace comma for url
    restGETurl8 = restGETurl8.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].ratingData}${restGETurl8}`).then(function (response) { 
      // handle success

      let restData = response.data.starRating;

      setRatingAvg6(restData.map(data => data.Rating));
      setStarColor6(restData.map(data => data.Color));
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });



    // Star Rating Pie Data - Model 1

    let restGETurl9 = `${"&brand="}${brandSelection1}${"&model="}${modelSelection1}${"&country="}${countrySelection1}${"&category="}${categorySelection1}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl9 = restGETurl9.replaceAll(",", '%2C'); // replace comma for url
    restGETurl9 = restGETurl9.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].starPieData}${restGETurl9}`).then(function (response) { 
      // handle success

      setRatingPie1(response.data.starRatingPie);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });


    // Star Rating Pie Data - Model 2

    let restGETurl10 = `${"&brand="}${brandSelection2}${"&model="}${modelSelection2}${"&country="}${countrySelection2}${"&category="}${categorySelection2}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl10 = restGETurl10.replaceAll(",", '%2C'); // replace comma for url
    restGETurl10 = restGETurl10.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].starPieData}${restGETurl10}`).then(function (response) { 
      // handle success

      setRatingPie2(response.data.starRatingPie);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });

    // Star Rating Pie Data - Model 3

    let restGETurl11 = `${"&brand="}${brandSelection3}${"&model="}${modelSelection3}${"&country="}${countrySelection3}${"&category="}${categorySelection3}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl11 = restGETurl11.replaceAll(",", '%2C'); // replace comma for url
    restGETurl11 = restGETurl11.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].starPieData}${restGETurl11}`).then(function (response) { 
      // handle success

      setRatingPie3(response.data.starRatingPie);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });

    // Star Rating Pie Data - Model 4

    let restGETurl12 = `${"&brand="}${brandSelection4}${"&model="}${modelSelection4}${"&country="}${countrySelection4}${"&category="}${categorySelection4}${"&startDate="}${dateStartSelection4}${"&endDate="}${dateEndSelection4}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl12 = restGETurl12.replaceAll(",", '%2C'); // replace comma for url
    restGETurl12 = restGETurl12.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].starPieData}${restGETurl12}`).then(function (response) { 
      // handle success

      setRatingPie4(response.data.starRatingPie);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });


    // Star Rating Pie Data - Model 5

    let restGETurl13 = `${"&brand="}${brandSelection5}${"&model="}${modelSelection5}${"&country="}${countrySelection5}${"&category="}${categorySelection5}${"&startDate="}${dateStartSelection5}${"&endDate="}${dateEndSelection5}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl13 = restGETurl13.replaceAll(",", '%2C'); // replace comma for url
    restGETurl13 = restGETurl13.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].starPieData}${restGETurl13}`).then(function (response) { 
      // handle success

      setRatingPie5(response.data.starRatingPie);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });

    // Star Rating Pie Data - Model 6

    let restGETurl14 = `${"&brand="}${brandSelection6}${"&model="}${modelSelection6}${"&country="}${countrySelection6}${"&category="}${categorySelection6}${"&startDate="}${dateStartSelection6}${"&endDate="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl14 = restGETurl14.replaceAll(",", '%2C'); // replace comma for url
    restGETurl14 = restGETurl14.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].starPieData}${restGETurl14}`).then(function (response) { 
      // handle success

      setRatingPie6(response.data.starRatingPie);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });



    // Category Sentiment Data - Model 1

    let restGETurl15 = `${"&brand="}${brandSelection1}${"&model="}${modelSelection1}${"&country="}${countrySelection1}${"&category="}${categorySelection1}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl15 = restGETurl15.replaceAll(",", '%2C'); // replace comma for url
    restGETurl15 = restGETurl15.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].categorySentimentData}${restGETurl15}`).then(function (response) { 
      // handle success

      setCategorySentimentData1(response.data.categorySentiment);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });


    // Category Sentiment Data - Model 2

    let restGETurl16 = `${"&brand="}${brandSelection2}${"&model="}${modelSelection2}${"&country="}${countrySelection2}${"&category="}${categorySelection2}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl16 = restGETurl16.replaceAll(",", '%2C'); // replace comma for url
    restGETurl16 = restGETurl16.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].categorySentimentData}${restGETurl16}`).then(function (response) { 
      // handle success

      setCategorySentimentData2(response.data.categorySentiment);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });


    // Category Sentiment Data - Model 3

    let restGETurl17 = `${"&brand="}${brandSelection3}${"&model="}${modelSelection3}${"&country="}${countrySelection3}${"&category="}${categorySelection3}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl17 = restGETurl17.replaceAll(",", '%2C'); // replace comma for url
    restGETurl17 = restGETurl17.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].categorySentimentData}${restGETurl17}`).then(function (response) { 
      // handle success

      setCategorySentimentData3(response.data.categorySentiment);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });


     // Category Sentiment Data - Model 4

     let restGETurl18 = `${"&brand="}${brandSelection4}${"&model="}${modelSelection4}${"&country="}${countrySelection4}${"&category="}${categorySelection4}${"&startDate="}${dateStartSelection4}${"&endDate="}${dateEndSelection4}${"&uniqueTopic="}${uniqueTopicSelection}`;
     restGETurl18 = restGETurl18.replaceAll(",", '%2C'); // replace comma for url
     restGETurl18 = restGETurl18.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].categorySentimentData}${restGETurl18}`).then(function (response) { 
       // handle success
 
       setCategorySentimentData4(response.data.categorySentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
       setProgress(prevCount => prevCount + 5);
     });
 


     // Category Sentiment Data - Model 5

     let restGETurl19 = `${"&brand="}${brandSelection5}${"&model="}${modelSelection5}${"&country="}${countrySelection5}${"&category="}${categorySelection5}${"&startDate="}${dateStartSelection5}${"&endDate="}${dateEndSelection5}${"&uniqueTopic="}${uniqueTopicSelection}`;
     restGETurl19 = restGETurl19.replaceAll(",", '%2C'); // replace comma for url
     restGETurl19 = restGETurl19.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].categorySentimentData}${restGETurl19}`).then(function (response) { 
       // handle success
 
       setCategorySentimentData5(response.data.categorySentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });


     // Category Sentiment Data - Model 6

     let restGETurl20 = `${"&brand="}${brandSelection6}${"&model="}${modelSelection6}${"&country="}${countrySelection6}${"&category="}${categorySelection6}${"&startDate="}${dateStartSelection6}${"&endDate="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}`;
     restGETurl20 = restGETurl20.replaceAll(",", '%2C'); // replace comma for url
     restGETurl20 = restGETurl20.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].categorySentimentData}${restGETurl20}`).then(function (response) { 
       // handle success
 
       setCategorySentimentData6(response.data.categorySentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
       setProgress(prevCount => prevCount + 5);
     });



     // Topic Sentiment Data - Model 1

     let restGETurl21 = `${"&brand="}${brandSelection1}${"&model="}${modelSelection1}${"&country="}${countrySelection1}${"&category="}${categorySelection1}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${categoryFilter1}`;
     restGETurl21 = restGETurl21.replaceAll(",", '%2C'); // replace comma for url
     restGETurl21 = restGETurl21.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl21}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData1(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
       setProgress(prevCount => prevCount + 5);
     });


     // Topic Sentiment Data - Model 2

     let restGETurl22 = `${"&brand="}${brandSelection2}${"&model="}${modelSelection2}${"&country="}${countrySelection2}${"&category="}${categorySelection2}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${categoryFilter2}`;
     restGETurl22 = restGETurl22.replaceAll(",", '%2C'); // replace comma for url
     restGETurl22 = restGETurl22.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl22}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData2(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });


     // Topic Sentiment Data - Model 3

     let restGETurl23 = `${"&brand="}${brandSelection3}${"&model="}${modelSelection3}${"&country="}${countrySelection3}${"&category="}${categorySelection3}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${categoryFilter3}`;
     restGETurl23 = restGETurl23.replaceAll(",", '%2C'); // replace comma for url
     restGETurl23 = restGETurl23.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl23}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData3(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
       setProgress(prevCount => prevCount + 5);
     });



     // Topic Sentiment Data - Model 4

     let restGETurl24 = `${"&brand="}${brandSelection4}${"&model="}${modelSelection4}${"&country="}${countrySelection4}${"&category="}${categorySelection4}${"&startDate="}${dateStartSelection4}${"&endDate="}${dateEndSelection4}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${categoryFilter4}`;
     restGETurl24 = restGETurl24.replaceAll(",", '%2C'); // replace comma for url
     restGETurl24 = restGETurl24.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl24}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData4(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
       setProgress(prevCount => prevCount + 5);
     });


     // Topic Sentiment Data - Model 5

     let restGETurl25 = `${"&brand="}${brandSelection5}${"&model="}${modelSelection5}${"&country="}${countrySelection5}${"&category="}${categorySelection5}${"&startDate="}${dateStartSelection5}${"&endDate="}${dateEndSelection5}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${categoryFilter5}`;
     restGETurl25 = restGETurl25.replaceAll(",", '%2C'); // replace comma for url
     restGETurl25 = restGETurl25.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl25}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData5(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
       setProgress(prevCount => prevCount + 5);
     });


      // Topic Sentiment Data - Model 6

      let restGETurl26 = `${"&brand="}${brandSelection6}${"&model="}${modelSelection6}${"&country="}${countrySelection6}${"&category="}${categorySelection6}${"&startDate="}${dateStartSelection6}${"&endDate="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${categoryFilter6}`;
      restGETurl26 = restGETurl26.replaceAll(",", '%2C'); // replace comma for url
      restGETurl26 = restGETurl26.replaceAll(" ", '%20'); // empty space for url
  
      axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl26}`).then(function (response) { 
        // handle success
  
        setTopicSentimentData6(response.data.topicSentiment);
        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        setProgress(prevCount => prevCount + 5);
      });



    // Trend and Pie Sentiment Data - Model 1

    let restGETurl27 = `${"&brand="}${brandSelection1}${"&model="}${modelSelection1}${"&country="}${countrySelection1}${"&category="}${categorySelection1}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl27 = restGETurl27.replaceAll(",", '%2C'); // replace comma for url
    restGETurl27 = restGETurl27.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].sentimentData}${restGETurl27}`).then(function (response) { 
      // handle success

      setSentimentTrendData1(response.data.sentimentTrendData);
      setSentimentPieData1(response.data.sentimentPieData);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });



    // Trend and Pie Sentiment Data - Model 2

    let restGETurl28 = `${"&brand="}${brandSelection2}${"&model="}${modelSelection2}${"&country="}${countrySelection2}${"&category="}${categorySelection2}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl28 = restGETurl28.replaceAll(",", '%2C'); // replace comma for url
    restGETurl28 = restGETurl28.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].sentimentData}${restGETurl28}`).then(function (response) { 
      // handle success

      setSentimentTrendData2(response.data.sentimentTrendData);
      setSentimentPieData2(response.data.sentimentPieData);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
    });


    // Trend and Pie Sentiment Data - Model 3

    let restGETurl29 = `${"&brand="}${brandSelection3}${"&model="}${modelSelection3}${"&country="}${countrySelection3}${"&category="}${categorySelection3}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl29 = restGETurl29.replaceAll(",", '%2C'); // replace comma for url
    restGETurl29 = restGETurl29.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].sentimentData}${restGETurl29}`).then(function (response) { 
      // handle success

      setSentimentTrendData3(response.data.sentimentTrendData);
      setSentimentPieData3(response.data.sentimentPieData);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed

    });


    // Trend and Pie Sentiment Data - Model 4

    let restGETurl30 = `${"&brand="}${brandSelection4}${"&model="}${modelSelection4}${"&country="}${countrySelection4}${"&category="}${categorySelection4}${"&startDate="}${dateStartSelection4}${"&endDate="}${dateEndSelection4}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl30 = restGETurl30.replaceAll(",", '%2C'); // replace comma for url
    restGETurl30 = restGETurl30.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].sentimentData}${restGETurl30}`).then(function (response) { 
      // handle success

      setSentimentTrendData4(response.data.sentimentTrendData);
      setSentimentPieData4(response.data.sentimentPieData);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      //setProgress(prevCount => prevCount + 5);
    });



    // Trend and Pie Sentiment Data - Model 5

    let restGETurl31 = `${"&brand="}${brandSelection5}${"&model="}${modelSelection5}${"&country="}${countrySelection5}${"&category="}${categorySelection5}${"&startDate="}${dateStartSelection5}${"&endDate="}${dateEndSelection5}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl31 = restGETurl31.replaceAll(",", '%2C'); // replace comma for url
    restGETurl31 = restGETurl31.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].sentimentData}${restGETurl31}`).then(function (response) { 
      // handle success

      setSentimentTrendData5(response.data.sentimentTrendData);
      setSentimentPieData5(response.data.sentimentPieData);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      // setProgress(95);
    });


    // Trend and Pie Sentiment Data - Model 6

    let restGETurl32 = `${"&brand="}${brandSelection6}${"&model="}${modelSelection6}${"&country="}${countrySelection6}${"&category="}${categorySelection6}${"&startDate="}${dateStartSelection6}${"&endDate="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}`;
    restGETurl32 = restGETurl32.replaceAll(",", '%2C'); // replace comma for url
    restGETurl32 = restGETurl32.replaceAll(" ", '%20'); // empty space for url

    axios.get(`${SMATrestAPI.competitorAnalytic[0].sentimentData}${restGETurl32}`).then(function (response) { 
      // handle success

      setSentimentTrendData6(response.data.sentimentTrendData);
      setSentimentPieData6(response.data.sentimentPieData);
      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setProgress(prevCount => prevCount + 5);
      setProgressBarIsLoading(false);
      setProgress(0);
    });
         
          
 

    
  }



  const onClicktopicCategoryFilter1 = data => () => {


    const axios = require('axios');
  
    let restGETurl1 = `${"&brand="}${brandSelection1}${"&model="}${modelSelection1}${"&category="}${categorySelection1}${"&startDate="}${dateStartSelection1}${"&endDate="}${dateEndSelection1}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${data}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl1}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData1(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });
  
    
  };


  const onClicktopicCategoryFilter2 = data => () => {


    const axios = require('axios');
  
    let restGETurl1 = `${"&brand="}${brandSelection2}${"&model="}${modelSelection2}${"&category="}${categorySelection2}${"&startDate="}${dateStartSelection2}${"&endDate="}${dateEndSelection2}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${data}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl1}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData2(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });
  
    
  };



  const onClicktopicCategoryFilter3 = data => () => {


    const axios = require('axios');
  
    let restGETurl1 = `${"&brand="}${brandSelection3}${"&model="}${modelSelection3}${"&category="}${categorySelection3}${"&startDate="}${dateStartSelection3}${"&endDate="}${dateEndSelection3}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${data}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl1}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData3(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });
  
    
  };


  const onClicktopicCategoryFilter4 = data => () => {


    const axios = require('axios');
  
    let restGETurl1 = `${"&brand="}${brandSelection4}${"&model="}${modelSelection4}${"&category="}${categorySelection4}${"&startDate="}${dateStartSelection4}${"&endDate="}${dateEndSelection4}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${data}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl1}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData4(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });
  
    
  };



  const onClicktopicCategoryFilter5 = data => () => {


    const axios = require('axios');
  
    let restGETurl1 = `${"&brand="}${brandSelection5}${"&model="}${modelSelection5}${"&category="}${categorySelection5}${"&startDate="}${dateStartSelection5}${"&endDate="}${dateEndSelection5}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${data}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl1}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData5(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });
  
    
  };


  const onClicktopicCategoryFilter6 = data => () => {


    const axios = require('axios');
  
    let restGETurl1 = `${"&brand="}${brandSelection6}${"&model="}${modelSelection6}${"&category="}${categorySelection6}${"&startDate="}${dateStartSelection6}${"&endDate="}${dateEndSelection6}${"&uniqueTopic="}${uniqueTopicSelection}${"&categoryFilter="}${data}`;
    restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
    restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url
 
     axios.get(`${SMATrestAPI.competitorAnalytic[0].topicData}${restGETurl1}`).then(function (response) { 
       // handle success
 
       setTopicSentimentData6(response.data.topicSentiment);
       
     }).catch(function (error) { 
       // handle error
       console.log(error);
     }).then(function () { 
       // always executed
 
     });
  
    
  };



// Start Chart Data


// Bubble Chart
  const bubbleChartData = {
    datasets: 
    bubbleData.map((data) => {
        return ({
          label: data.Model,
          data: [{
            x: data['Negative Percent'],
            y: data['Positive Percent'],
            r: data.size
          }],
          backgroundColor: data.color
        })
    
      })

  }

  const bubbleChartOptions = {
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Positive'
        },
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Negative'
        },
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      }],
    },
    maintainAspectRatio: false 
    //animation: {duration: 0 }
  }


// Bubble Table using react-data-table-component
  const bubbleTableData = bubbleData.map((data) => {
      return ({
        id: data.id,
        model: data.Model,
        positiveCount: data.Positive,
        negativeCount: data.Negative,
        positivePercent: data['Positive Percent'],
        negativePercent: data['Negative Percent'],
        topicCount: data['Topic Count']
      })
    });
    
  const bubbleTableColumn = [
    {
      name: 'Model',
      selector: 'model',
      sortable: true,
      left: true
    },
    {
      name: 'Positive Count',
      selector: 'positiveCount',
      sortable: true,
      center: true
    },
    {
      name: 'Negative Count',
      selector: 'negativeCount',
      sortable: true,
      center: true
    },
    {
      name: 'Positive Percent',
      selector: 'positivePercent',
      sortable: true,
      center: true
    },
    {
      name: 'Negative Percent',
      selector: 'negativePercent',
      sortable: true,
      center: true
    },
    {
      name: 'Topic Count',
      selector: 'topicCount',
      sortable: true,
      center: true
    }
  ];



  // Radar Comparison Chart  

  const datasetKeyProvider=()=>{ 
    return btoa(Math.random()).substring(0,12)
  }

  let modelName = bubbleData.map(data => data.Model);
  let modelColor = bubbleData.map(data => data.color);
  let modelRgbaColor = bubbleData.map(data => data.rgbaColor);


  let radarComparisonDataSet = {
    spanGaps: true,
    type: 'radar',
    labels: ["Design", "Feature", "Picture", "Price", "Software", "Sound"],
    datasets: [

      {
        label: "",
        data: "",
        borderColor: "", // Line Color
        backgroundColor: "",  // Fill Color
        pointBackgroundColor: "", // Dot Color
      },

    ]
    } 

    let modelGroup1 = radarData.filter(data => data.Group === "Group1")
    let modelGroup2 = radarData.filter(data => data.Group === "Group2")
    let modelGroup3 = radarData.filter(data => data.Group === "Group3")
    let modelGroup4 = radarData.filter(data => data.Group === "Group4")
    let modelGroup5 = radarData.filter(data => data.Group === "Group5")
    let modelGroup6 = radarData.filter(data => data.Group === "Group6")


    if(modelName.length === 1){

    radarComparisonDataSet = {
      spanGaps: true,
      type: 'radar',
      labels: ["Design", "Feature", "Picture", "Price", "Software", "Sound"],
      datasets: [
  
        {
          label: modelName[0],
          data: modelGroup1.map(data => data['Positive Percent']),
          borderColor: modelColor[0], // Line Color
          backgroundColor: modelRgbaColor[0],  // Fill Color
          pointBackgroundColor: modelColor[0], // Dot Color
        }
        
      ]
      } 


  }else if(modelName.length === 2){

    radarComparisonDataSet = {
      spanGaps: true,
      type: 'radar',
      labels: ["Design", "Feature", "Picture", "Price", "Software", "Sound"],
      datasets: [
  
        {
          label: modelName[0],
          data: modelGroup1.map(data => data['Positive Percent']),
          borderColor: modelColor[0], // Line Color
          backgroundColor: modelRgbaColor[0],  // Fill Color
          pointBackgroundColor: modelColor[0], // Dot Color
        },
        {
          label: modelName[1],
          data: modelGroup2.map(data => data['Positive Percent']),
          borderColor: modelColor[1], // Line Color
          backgroundColor: modelRgbaColor[1],  // Fill Color
          pointBackgroundColor: modelColor[1], // Dot Color
        }
        
      ]
      } 


  }else if(modelName.length === 3){

    radarComparisonDataSet = {
      spanGaps: true,
      type: 'radar',
      labels: ["Design", "Feature", "Picture", "Price", "Software", "Sound"],
      datasets: [
  
        {
          label: modelName[0],
          data: modelGroup1.map(data => data['Positive Percent']),
          borderColor: modelColor[0], // Line Color
          backgroundColor: modelRgbaColor[0],  // Fill Color
          pointBackgroundColor: modelColor[0], // Dot Color
        },
        {
          label: modelName[1],
          data: modelGroup2.map(data => data['Positive Percent']),
          borderColor: modelColor[1], // Line Color
          backgroundColor: modelRgbaColor[1],  // Fill Color
          pointBackgroundColor: modelColor[1], // Dot Color
        },
        {
          label: modelName[2],
          data: modelGroup3.map(data => data['Positive Percent']),
          borderColor: modelColor[2], // Line Color
          backgroundColor: modelRgbaColor[2],  // Fill Color
          pointBackgroundColor: modelColor[2], // Dot Color
        }
        
      ]
      } 
    

  }else if(modelName.length === 4){

    radarComparisonDataSet = {
      spanGaps: true,
      type: 'radar',
      labels: ["Design", "Feature", "Picture", "Price", "Software", "Sound"],
      datasets: [
  
        {
          label: modelName[0],
          data: modelGroup1.map(data => data['Positive Percent']),
          borderColor: modelColor[0], // Line Color
          backgroundColor: modelRgbaColor[0],  // Fill Color
          pointBackgroundColor: modelColor[0], // Dot Color
        },
        {
          label: modelName[1],
          data: modelGroup2.map(data => data['Positive Percent']),
          borderColor: modelColor[1], // Line Color
          backgroundColor: modelRgbaColor[1],  // Fill Color
          pointBackgroundColor: modelColor[1], // Dot Color
        },
        {
          label: modelName[2],
          data: modelGroup3.map(data => data['Positive Percent']),
          borderColor: modelColor[2], // Line Color
          backgroundColor: modelRgbaColor[2],  // Fill Color
          pointBackgroundColor: modelColor[2], // Dot Color
        },
        {
          label: modelName[3],
          data: modelGroup4.map(data => data['Positive Percent']),
          borderColor: modelColor[3], // Line Color
          backgroundColor: modelRgbaColor[3],  // Fill Color
          pointBackgroundColor: modelColor[3], // Dot Color
        }
        
      ]
      } 
    

  }else if(modelName.length === 5){

    radarComparisonDataSet = {
      spanGaps: true,
      type: 'radar',
      labels: ["Design", "Feature", "Picture", "Price", "Software", "Sound"],
      datasets: [
  
        {
          label: modelName[0],
          data: modelGroup1.map(data => data['Positive Percent']),
          borderColor: modelColor[0], // Line Color
          backgroundColor: modelRgbaColor[0],  // Fill Color
          pointBackgroundColor: modelColor[0], // Dot Color
        },
        {
          label: modelName[1],
          data: modelGroup2.map(data => data['Positive Percent']),
          borderColor: modelColor[1], // Line Color
          backgroundColor: modelRgbaColor[1],  // Fill Color
          pointBackgroundColor: modelColor[1], // Dot Color
        },
        {
          label: modelName[2],
          data: modelGroup3.map(data => data['Positive Percent']),
          borderColor: modelColor[2], // Line Color
          backgroundColor: modelRgbaColor[2],  // Fill Color
          pointBackgroundColor: modelColor[2], // Dot Color
        },
        {
          label: modelName[3],
          data: modelGroup4.map(data => data['Positive Percent']),
          borderColor: modelColor[3], // Line Color
          backgroundColor: modelRgbaColor[3],  // Fill Color
          pointBackgroundColor: modelColor[3], // Dot Color
        },
        {
          label: modelName[4],
          data: modelGroup5.map(data => data['Positive Percent']),
          borderColor: modelColor[4], // Line Color
          backgroundColor: modelRgbaColor[4],  // Fill Color
          pointBackgroundColor: modelColor[4], // Dot Color
        }
        
      ]
      } 
    
    

  }else if(modelName.length === 6){

    radarComparisonDataSet = {
      spanGaps: true,
      type: 'radar',
      labels: ["Design", "Feature", "Picture", "Price", "Software", "Sound"],
      datasets: [
  
        {
          label: modelName[0],
          data: modelGroup1.map(data => data['Positive Percent']),
          borderColor: modelColor[0], // Line Color
          backgroundColor: modelRgbaColor[0],  // Fill Color
          pointBackgroundColor: modelColor[0], // Dot Color
        },
        {
          label: modelName[1],
          data: modelGroup2.map(data => data['Positive Percent']),
          borderColor: modelColor[1], // Line Color
          backgroundColor: modelRgbaColor[1],  // Fill Color
          pointBackgroundColor: modelColor[1], // Dot Color
        },
        {
          label: modelName[2],
          data: modelGroup3.map(data => data['Positive Percent']),
          borderColor: modelColor[2], // Line Color
          backgroundColor: modelRgbaColor[2],  // Fill Color
          pointBackgroundColor: modelColor[2], // Dot Color
        },
        {
          label: modelName[3],
          data: modelGroup4.map(data => data['Positive Percent']),
          borderColor: modelColor[3], // Line Color
          backgroundColor: modelRgbaColor[3],  // Fill Color
          pointBackgroundColor: modelColor[3], // Dot Color
        },
        {
          label: modelName[4],
          data: modelGroup5.map(data => data['Positive Percent']),
          borderColor: modelColor[4], // Line Color
          backgroundColor: modelRgbaColor[4],  // Fill Color
          pointBackgroundColor: modelColor[4], // Dot Color
        },
        {
          label: modelName[5],
          data: modelGroup6.map(data => data['Positive Percent']),
          borderColor: modelColor[5], // Line Color
          backgroundColor: modelRgbaColor[5],  // Fill Color
          pointBackgroundColor: modelColor[5], // Dot Color
        }
        
      ]
      } 
    
  }


    let radarComparisonOption = {

      // Drill Down
      onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);



        }

      },
      
      scale: {
        angleLines: {
            display: false
        },
        pointLabels: {
          fontSize: 20,
        },
        gridLines: {
          color: 'black'
        },
        angleLines: {
          color: 'black' 
        },
        ticks: {
          fontSize: 12,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
          }
        }
    },

    maintainAspectRatio: false
   

    }


  
// Radar Table using react-data-table-component
  const radarTableData = radarData.map((data) => {
    return ({
      id: data.id,
      model: data.Model,
      category: data.Category,
      positiveCount: data.Positive,
      negativeCount: data.Negative,
      positivePercent: data['Positive Percent'],
      negativePercent: data['Negative Percent'],
      total: data['Total']
    })
  });
  
const radarTableColumn = [
  {
    name: 'Model',
    selector: 'model',
    sortable: true,
    left: true
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    left: true
  },
  {
    name: 'Positive Count',
    selector: 'positiveCount',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Count',
    selector: 'negativeCount',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'total',
    sortable: true,
    center: true
  }
];




// Star Rating  - Doughnut Chart - Model 1

let starRatingPieDataSet1 = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: ratingPie1.map(data => data['Percent']),

      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg1
  } 

  let starRatingPieOptions1 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Star Rating Trend Table using react-data-table-component - Model 1

const starRatingPieTableDataSet1 = ratingPie1.map((data) => {
  return ({
    id: data['id'], 
    rating: data['Star Rating'], 
    count: data['Count'], 
    percent: data['Percent']
  })
});

const starRatingPieTableColumn1 = [
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  },
  {
    name: 'Percent',
    selector: 'percent',
    sortable: true,
    center: true
  }
];



// Star Rating  - Doughnut Chart - Model 2

let starRatingPieDataSet2 = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: ratingPie2.map(data => data['Percent']),

      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg2
  } 

  let starRatingPieOptions2 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Star Rating Trend Table using react-data-table-component - Model 2

const starRatingPieTableDataSet2 = ratingPie2.map((data) => {
  return ({
    id: data['id'], 
    rating: data['Star Rating'], 
    count: data['Count'], 
    percent: data['Percent']
  })
});

const starRatingPieTableColumn2 = [
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  },
  {
    name: 'Percent',
    selector: 'percent',
    sortable: true,
    center: true
  }
];



// Star Rating  - Doughnut Chart - Model 3

let starRatingPieDataSet3 = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: ratingPie3.map(data => data['Percent']),

      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg3
  } 

  let starRatingPieOptions3 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Star Rating Trend Table using react-data-table-component - Model 3

const starRatingPieTableDataSet3 = ratingPie3.map((data) => {
  return ({
    id: data['id'], 
    rating: data['Star Rating'], 
    count: data['Count'], 
    percent: data['Percent']
  })
});

const starRatingPieTableColumn3 = [
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  },
  {
    name: 'Percent',
    selector: 'percent',
    sortable: true,
    center: true
  }
];



// Star Rating  - Doughnut Chart - Model 4

let starRatingPieDataSet4 = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: ratingPie4.map(data => data['Percent']),

      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg4
  } 

  let starRatingPieOptions4 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Star Rating Trend Table using react-data-table-component - Model 4

const starRatingPieTableDataSet4 = ratingPie4.map((data) => {
  return ({
    id: data['id'], 
    rating: data['Star Rating'], 
    count: data['Count'], 
    percent: data['Percent']
  })
});

const starRatingPieTableColumn4 = [
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  },
  {
    name: 'Percent',
    selector: 'percent',
    sortable: true,
    center: true
  }
];




// Star Rating  - Doughnut Chart - Model 5

let starRatingPieDataSet5 = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: ratingPie5.map(data => data['Percent']),

      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg5
  } 

  let starRatingPieOptions5 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Star Rating Trend Table using react-data-table-component - Model 5

const starRatingPieTableDataSet5 = ratingPie5.map((data) => {
  return ({
    id: data['id'], 
    rating: data['Star Rating'], 
    count: data['Count'], 
    percent: data['Percent']
  })
});

const starRatingPieTableColumn5 = [
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  },
  {
    name: 'Percent',
    selector: 'percent',
    sortable: true,
    center: true
  }
];




// Star Rating  - Doughnut Chart - Model 6

let starRatingPieDataSet6 = {
  type: 'doughnut',
  labels: [
		'5-Star',
		'4-Star',
    '3-Star',
    '2-Star',
    '1-Star'
	],
  datasets: [
    {
      data: ratingPie6.map(data => data['Percent']),

      backgroundColor: [
        'rgb(26, 115, 55)',
        'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg6
  } 

  let starRatingPieOptions6 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Star Rating Trend Table using react-data-table-component - Model 6

const starRatingPieTableDataSet6 = ratingPie6.map((data) => {
  return ({
    id: data['id'], 
    rating: data['Star Rating'], 
    count: data['Count'], 
    percent: data['Percent']
  })
});

const starRatingPieTableColumn6 = [
  {
    name: 'Rating',
    selector: 'rating',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  },
  {
    name: 'Percent',
    selector: 'percent',
    sortable: true,
    center: true
  }
];



// Category Summary Radar Chart - Model 1

let categorySummaryRadarDataSet1 = {
  spanGaps: true,
  type: 'radar',
  labels: categorySentimentData1.map(data => data['Category']),
  datasets: [


    {
      label: "Negative",
      data: categorySentimentData1.map(data => data['Negative Percent']),
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },

    {
      label: "Positive",
      data: categorySentimentData1.map(data => data['Positive Percent']),
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    },
    
  ]
  } 


  let categorySummaryRadarOption1 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);



      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component - Model 1

const categorySummaryRadarTableDataSet1 = categorySentimentData1.map((data) => {
  return ({
    id: data.id,
    category: data["Category"],
    positiveCount: data["Positive"], 
    negativeCount: data["Negative"],
    total: data["Total"],  
    positivePercent: data["Positive Percent"], 
    negativePercent: data["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn1 = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Negative',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Total',
  selector: 'total',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

];  




// Category Summary Radar Chart - Model 2

let categorySummaryRadarDataSet2 = {
  spanGaps: true,
  type: 'radar',
  labels: categorySentimentData2.map(data => data['Category']),
  datasets: [


    {
      label: "Negative",
      data: categorySentimentData2.map(data => data['Negative Percent']),
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },

    {
      label: "Positive",
      data: categorySentimentData2.map(data => data['Positive Percent']),
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    },
    
  ]
  } 


  let categorySummaryRadarOption2 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);



      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component - Model 2

const categorySummaryRadarTableDataSet2 = categorySentimentData2.map((data) => {
  return ({
    id: data.id,
    category: data["Category"],
    positiveCount: data["Positive"], 
    negativeCount: data["Negative"],
    total: data["Total"],  
    positivePercent: data["Positive Percent"], 
    negativePercent: data["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn2 = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Negative',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Total',
  selector: 'total',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

];  




// Category Summary Radar Chart - Model 3

let categorySummaryRadarDataSet3 = {
  spanGaps: true,
  type: 'radar',
  labels: categorySentimentData3.map(data => data['Category']),
  datasets: [


    {
      label: "Negative",
      data: categorySentimentData3.map(data => data['Negative Percent']),
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },

    {
      label: "Positive",
      data: categorySentimentData3.map(data => data['Positive Percent']),
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    },
    
  ]
  } 


  let categorySummaryRadarOption3 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);



      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component - Model 3

const categorySummaryRadarTableDataSet3 = categorySentimentData3.map((data) => {
  return ({
    id: data.id,
    category: data["Category"],
    positiveCount: data["Positive"], 
    negativeCount: data["Negative"],
    total: data["Total"],  
    positivePercent: data["Positive Percent"], 
    negativePercent: data["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn3 = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Negative',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Total',
  selector: 'total',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

];  



// Category Summary Radar Chart - Model 4

let categorySummaryRadarDataSet4 = {
  spanGaps: true,
  type: 'radar',
  labels: categorySentimentData4.map(data => data['Category']),
  datasets: [


    {
      label: "Negative",
      data: categorySentimentData4.map(data => data['Negative Percent']),
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },

    {
      label: "Positive",
      data: categorySentimentData4.map(data => data['Positive Percent']),
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    },
    
  ]
  } 


  let categorySummaryRadarOption4 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);



      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component - Model 4

const categorySummaryRadarTableDataSet4 = categorySentimentData4.map((data) => {
  return ({
    id: data.id,
    category: data["Category"],
    positiveCount: data["Positive"], 
    negativeCount: data["Negative"],
    total: data["Total"],  
    positivePercent: data["Positive Percent"], 
    negativePercent: data["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn4 = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Negative',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Total',
  selector: 'total',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

];  




// Category Summary Radar Chart - Model 5

let categorySummaryRadarDataSet5 = {
  spanGaps: true,
  type: 'radar',
  labels: categorySentimentData5.map(data => data['Category']),
  datasets: [


    {
      label: "Negative",
      data: categorySentimentData5.map(data => data['Negative Percent']),
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },

    {
      label: "Positive",
      data: categorySentimentData5.map(data => data['Positive Percent']),
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    },
    
  ]
  } 


  let categorySummaryRadarOption5 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);



      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component - Model 5

const categorySummaryRadarTableDataSet5 = categorySentimentData5.map((data) => {
  return ({
    id: data.id,
    category: data["Category"],
    positiveCount: data["Positive"], 
    negativeCount: data["Negative"],
    total: data["Total"],  
    positivePercent: data["Positive Percent"], 
    negativePercent: data["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn5 = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Negative',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Total',
  selector: 'total',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

];  



// Category Summary Radar Chart - Model 6

let categorySummaryRadarDataSet6 = {
  spanGaps: true,
  type: 'radar',
  labels: categorySentimentData6.map(data => data['Category']),
  datasets: [


    {
      label: "Negative",
      data: categorySentimentData6.map(data => data['Negative Percent']),
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },

    {
      label: "Positive",
      data: categorySentimentData6.map(data => data['Positive Percent']),
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    },
    
  ]
  } 


  let categorySummaryRadarOption6 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);



      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


// Category Summary Radar Table using react-data-table-component - Model 6

const categorySummaryRadarTableDataSet6 = categorySentimentData6.map((data) => {
  return ({
    id: data.id,
    category: data["Category"],
    positiveCount: data["Positive"], 
    negativeCount: data["Negative"],
    total: data["Total"],  
    positivePercent: data["Positive Percent"], 
    negativePercent: data["Negative Percent"], 

  })
});


const categorySummaryRadarTableColumn6 = [
{
  name: 'Category',
  selector: 'category',
  sortable: true,
  center: true
},
{
  name: 'Positive',
  selector: 'positiveCount',
  sortable: true,
  center: true
},
{
  name: 'Negative',
  selector: 'negativeCount',
  sortable: true,
  center: true
},
{
  name: 'Total',
  selector: 'total',
  sortable: true,
  center: true
},
{
  name: 'Positive Percent',
  selector: 'positivePercent',
  sortable: true,
  center: true
},
{
  name: 'Negative Percent',
  selector: 'negativePercent',
  sortable: true,
  center: true
}

]; 



// Topic Sentiment - Stack Bar Chart - Model 1

let topicSentimentDataSet1 = {
  type: 'bar',
  labels: topicSentimentData1.map(data => data['Topic']),
  datasets: [
    {
      label: 'Positive',
      data: topicSentimentData1.map(data => data['Positive Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicSentimentData1.map(data => data['Neutral Percent']),
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicSentimentData1.map(data => data['Negative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicSentimentOption1 = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      

    }

  },

  scales: {
    xAxes: [{
        stacked: true,
        ticks: {
          fontSize: 12,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
    }],
    yAxes: [{
        stacked: true
    }]
  },

  maintainAspectRatio: false


}


// Topic Sentiment - Stack Bar Table using react-data-table-component - Model 1

const topicSentimentTableDataSet1 = topicSentimentData1.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      neutral: data['Neutral'],
      negative: data['Negative'],
      positivePercent: data['Positive Percent'],
      neutralPercent: data['Neutral Percent'],
      negativePercent: data['Negative Percent'],
      total: data['Total']
    })
  });
  
const topicSentimentTableColumn1 = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }

];


// Topic Sentiment - Stack Bar Chart - Model 2

let topicSentimentDataSet2 = {
  type: 'bar',
  labels: topicSentimentData2.map(data => data['Topic']),
  datasets: [
    {
      label: 'Positive',
      data: topicSentimentData2.map(data => data['Positive Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicSentimentData2.map(data => data['Neutral Percent']),
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicSentimentData2.map(data => data['Negative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicSentimentOption2 = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      

    }

  },

  scales: {
    xAxes: [{
        stacked: true,
        ticks: {
          fontSize: 12,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
    }],
    yAxes: [{
        stacked: true
    }]
  },

  maintainAspectRatio: false


}


// Topic Sentiment - Stack Bar Table using react-data-table-component - Model 2

const topicSentimentTableDataSet2 = topicSentimentData2.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      neutral: data['Neutral'],
      negative: data['Negative'],
      positivePercent: data['Positive Percent'],
      neutralPercent: data['Neutral Percent'],
      negativePercent: data['Negative Percent'],
      total: data['Total']
    })
  });
  
const topicSentimentTableColumn2 = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }

];



// Topic Sentiment - Stack Bar Chart - Model 3

let topicSentimentDataSet3 = {
  type: 'bar',
  labels: topicSentimentData3.map(data => data['Topic']),
  datasets: [
    {
      label: 'Positive',
      data: topicSentimentData3.map(data => data['Positive Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicSentimentData3.map(data => data['Neutral Percent']),
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicSentimentData3.map(data => data['Negative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicSentimentOption3 = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      

    }

  },

  scales: {
    xAxes: [{
        stacked: true,
        ticks: {
          fontSize: 12,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
    }],
    yAxes: [{
        stacked: true
    }]
  },

  maintainAspectRatio: false


}


// Topic Sentiment - Stack Bar Table using react-data-table-component - Model 3

const topicSentimentTableDataSet3 = topicSentimentData3.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      neutral: data['Neutral'],
      negative: data['Negative'],
      positivePercent: data['Positive Percent'],
      neutralPercent: data['Neutral Percent'],
      negativePercent: data['Negative Percent'],
      total: data['Total']
    })
  });
  
const topicSentimentTableColumn3 = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }

];


// Topic Sentiment - Stack Bar Chart - Model 4

let topicSentimentDataSet4 = {
  type: 'bar',
  labels: topicSentimentData4.map(data => data['Topic']),
  datasets: [
    {
      label: 'Positive',
      data: topicSentimentData4.map(data => data['Positive Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicSentimentData4.map(data => data['Neutral Percent']),
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicSentimentData4.map(data => data['Negative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicSentimentOption4 = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      

    }

  },

  scales: {
    xAxes: [{
        stacked: true,
        ticks: {
          fontSize: 12,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
    }],
    yAxes: [{
        stacked: true
    }]
  },

  maintainAspectRatio: false


}


// Topic Sentiment - Stack Bar Table using react-data-table-component - Model 4

const topicSentimentTableDataSet4 = topicSentimentData4.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      neutral: data['Neutral'],
      negative: data['Negative'],
      positivePercent: data['Positive Percent'],
      neutralPercent: data['Neutral Percent'],
      negativePercent: data['Negative Percent'],
      total: data['Total']
    })
  });
  
const topicSentimentTableColumn4 = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }

];


// Topic Sentiment - Stack Bar Chart - Model 5

let topicSentimentDataSet5 = {
  type: 'bar',
  labels: topicSentimentData5.map(data => data['Topic']),
  datasets: [
    {
      label: 'Positive',
      data: topicSentimentData5.map(data => data['Positive Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicSentimentData5.map(data => data['Neutral Percent']),
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicSentimentData5.map(data => data['Negative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicSentimentOption5 = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      

    }

  },

  scales: {
    xAxes: [{
        stacked: true,
        ticks: {
          fontSize: 12,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
    }],
    yAxes: [{
        stacked: true
    }]
  },

  maintainAspectRatio: false


}


// Topic Sentiment - Stack Bar Table using react-data-table-component - Model 5

const topicSentimentTableDataSet5 = topicSentimentData5.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      neutral: data['Neutral'],
      negative: data['Negative'],
      positivePercent: data['Positive Percent'],
      neutralPercent: data['Neutral Percent'],
      negativePercent: data['Negative Percent'],
      total: data['Total']
    })
  });
  
const topicSentimentTableColumn5 = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }

];


// Topic Sentiment - Stack Bar Chart - Model 6

let topicSentimentDataSet6 = {
  type: 'bar',
  labels: topicSentimentData6.map(data => data['Topic']),
  datasets: [
    {
      label: 'Positive',
      data: topicSentimentData6.map(data => data['Positive Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicSentimentData6.map(data => data['Neutral Percent']),
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicSentimentData6.map(data => data['Negative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicSentimentOption6 = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


      

    }

  },

  scales: {
    xAxes: [{
        stacked: true,
        ticks: {
          fontSize: 12,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
    }],
    yAxes: [{
        stacked: true
    }]
  },

  maintainAspectRatio: false


}


// Topic Sentiment - Stack Bar Table using react-data-table-component - Model 6

const topicSentimentTableDataSet6 = topicSentimentData6.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      neutral: data['Neutral'],
      negative: data['Negative'],
      positivePercent: data['Positive Percent'],
      neutralPercent: data['Neutral Percent'],
      negativePercent: data['Negative Percent'],
      total: data['Total']
    })
  });
  
const topicSentimentTableColumn6 = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }

];




// Sentiment Trend - Line Chart - Model 1

let sentimentTrendDataSet1 = {
  type: 'line',
  labels: sentimentTrendData1.map(data => data.Date),
  datasets: [
    {
      label: 'Positive',
      data: sentimentTrendData1.map(data => data.Positive),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: sentimentTrendData1.map(data => data.Negative),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: sentimentTrendData1.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const sentimentTrendOptions1 = {


// Drill Down
onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


     

    }

  },


  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Star Rating Trend Table using react-data-table-component - Model 1

const sentimentTrendTableDataSet1 = sentimentTrendData1.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positivePercent: data['Positive'],
      negativePercent: data['Negative'],
      neutralPercent: data['Neutral'],
      total: data['Total'],
      cumulative: data['Cumulative Total']
    })
  });
  
const sentimentTrendTableColumn1 = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];



// Sentiment Trend - Line Chart - Model 2

let sentimentTrendDataSet2 = {
  type: 'line',
  labels: sentimentTrendData2.map(data => data.Date),
  datasets: [
    {
      label: 'Positive',
      data: sentimentTrendData2.map(data => data.Positive),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: sentimentTrendData2.map(data => data.Negative),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: sentimentTrendData2.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const sentimentTrendOptions2 = {


// Drill Down
onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


     

    }

  },


  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Star Rating Trend Table using react-data-table-component - Model 2

const sentimentTrendTableDataSet2 = sentimentTrendData2.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positivePercent: data['Positive'],
      negativePercent: data['Negative'],
      neutralPercent: data['Neutral'],
      total: data['Total'],
      cumulative: data['Cumulative Total']
    })
  });
  
const sentimentTrendTableColumn2 = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];





// Sentiment Trend - Line Chart - Model 3

let sentimentTrendDataSet3 = {
  type: 'line',
  labels: sentimentTrendData3.map(data => data.Date),
  datasets: [
    {
      label: 'Positive',
      data: sentimentTrendData3.map(data => data.Positive),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: sentimentTrendData3.map(data => data.Negative),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: sentimentTrendData3.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const sentimentTrendOptions3 = {


// Drill Down
onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


     

    }

  },


  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Star Rating Trend Table using react-data-table-component - Model 3

const sentimentTrendTableDataSet3 = sentimentTrendData3.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positivePercent: data['Positive'],
      negativePercent: data['Negative'],
      neutralPercent: data['Neutral'],
      total: data['Total'],
      cumulative: data['Cumulative Total']
    })
  });
  
const sentimentTrendTableColumn3 = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];





// Sentiment Trend - Line Chart - Model 4

let sentimentTrendDataSet4 = {
  type: 'line',
  labels: sentimentTrendData4.map(data => data.Date),
  datasets: [
    {
      label: 'Positive',
      data: sentimentTrendData4.map(data => data.Positive),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: sentimentTrendData4.map(data => data.Negative),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: sentimentTrendData4.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const sentimentTrendOptions4 = {


// Drill Down
onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


     

    }

  },


  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Star Rating Trend Table using react-data-table-component - Model 4

const sentimentTrendTableDataSet4 = sentimentTrendData4.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positivePercent: data['Positive'],
      negativePercent: data['Negative'],
      neutralPercent: data['Neutral'],
      total: data['Total'],
      cumulative: data['Cumulative Total']
    })
  });
  
const sentimentTrendTableColumn4 = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];





// Sentiment Trend - Line Chart - Model 5

let sentimentTrendDataSet5 = {
  type: 'line',
  labels: sentimentTrendData5.map(data => data.Date),
  datasets: [
    {
      label: 'Positive',
      data: sentimentTrendData5.map(data => data.Positive),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: sentimentTrendData5.map(data => data.Negative),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: sentimentTrendData5.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const sentimentTrendOptions5 = {


// Drill Down
onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


     

    }

  },


  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Star Rating Trend Table using react-data-table-component - Model 5

const sentimentTrendTableDataSet5 = sentimentTrendData5.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positivePercent: data['Positive'],
      negativePercent: data['Negative'],
      neutralPercent: data['Neutral'],
      total: data['Total'],
      cumulative: data['Cumulative Total']
    })
  });
  
const sentimentTrendTableColumn5 = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];




// Sentiment Trend - Line Chart - Model 6

let sentimentTrendDataSet6 = {
  type: 'line',
  labels: sentimentTrendData6.map(data => data.Date),
  datasets: [
    {
      label: 'Positive',
      data: sentimentTrendData6.map(data => data.Positive),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: sentimentTrendData6.map(data => data.Negative),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: sentimentTrendData6.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}


const sentimentTrendOptions6 = {


// Drill Down
onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


     

    }

  },


  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Star Rating Trend Table using react-data-table-component - Model 6

const sentimentTrendTableDataSet6 = sentimentTrendData6.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positivePercent: data['Positive'],
      negativePercent: data['Negative'],
      neutralPercent: data['Neutral'],
      total: data['Total'],
      cumulative: data['Cumulative Total']
    })
  });
  
const sentimentTrendTableColumn6 = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulative',
    sortable: true,
    center: true
  }
];







// Sentiment Comparison - Doughnut Chart - Model 1

let sentimentPieDataSet1 = {
  type: 'doughnut',
  labels: [
		'Positive',
		'Neutral',
    'Negative'
	],
  datasets: [
    {
      data: [sentimentPieData1.map(data => data['Positive Percent']), sentimentPieData1.map(data => data['Neutral Percent']), sentimentPieData1.map(data => data['Negative Percent'])],

      backgroundColor: [
        'rgb(26, 115, 55)',
        //'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        //'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg1
  } 

  let sentimentPieOptions1 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Sentiment Comparison - Doughnut Table using react-data-table-component - Model 1

const sentimentPieTableDataSet1 = sentimentPieData1.map((data) => {
  return ({
    id: data['id'], 
    positive: data['Positive'], 
    neutral: data['Neutral'], 
    negative: data['Negative'],
    positivePercent: data['Positive Percent'], 
    neutralPercent: data['Neutral Percent'], 
    negativePercent: data['Negative Percent'],
    total: data['Total']
  })
});

const sentimentPieTableColumn1 = [
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Sentiment Comparison - Doughnut Chart - Model 2

let sentimentPieDataSet2 = {
  type: 'doughnut',
  labels: [
		'Positive',
		'Neutral',
    'Negative'
	],
  datasets: [
    {
      data: [sentimentPieData2.map(data => data['Positive Percent']), sentimentPieData2.map(data => data['Neutral Percent']), sentimentPieData2.map(data => data['Negative Percent'])],

      backgroundColor: [
        'rgb(26, 115, 55)',
        //'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        //'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg2
  } 

  let sentimentPieOptions2 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Sentiment Comparison - Doughnut Table using react-data-table-component - Model 2

const sentimentPieTableDataSet2 = sentimentPieData2.map((data) => {
  return ({
    id: data['id'], 
    positive: data['Positive'], 
    neutral: data['Neutral'], 
    negative: data['Negative'],
    positivePercent: data['Positive Percent'], 
    neutralPercent: data['Neutral Percent'], 
    negativePercent: data['Negative Percent'],
    total: data['Total']
  })
});

const sentimentPieTableColumn2 = [
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];




// Sentiment Comparison - Doughnut Chart - Model 3

let sentimentPieDataSet3 = {
  type: 'doughnut',
  labels: [
		'Positive',
		'Neutral',
    'Negative'
	],
  datasets: [
    {
      data: [sentimentPieData3.map(data => data['Positive Percent']), sentimentPieData3.map(data => data['Neutral Percent']), sentimentPieData3.map(data => data['Negative Percent'])],

      backgroundColor: [
        'rgb(26, 115, 55)',
        //'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        //'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg3
  } 

  let sentimentPieOptions3 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Sentiment Comparison - Doughnut Table using react-data-table-component - Model 3

const sentimentPieTableDataSet3 = sentimentPieData3.map((data) => {
  return ({
    id: data['id'], 
    positive: data['Positive'], 
    neutral: data['Neutral'], 
    negative: data['Negative'],
    positivePercent: data['Positive Percent'], 
    neutralPercent: data['Neutral Percent'], 
    negativePercent: data['Negative Percent'],
    total: data['Total']
  })
});

const sentimentPieTableColumn3 = [
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// Sentiment Comparison - Doughnut Chart - Model 4

let sentimentPieDataSet4 = {
  type: 'doughnut',
  labels: [
		'Positive',
		'Neutral',
    'Negative'
	],
  datasets: [
    {
      data: [sentimentPieData4.map(data => data['Positive Percent']), sentimentPieData4.map(data => data['Neutral Percent']), sentimentPieData4.map(data => data['Negative Percent'])],

      backgroundColor: [
        'rgb(26, 115, 55)',
        //'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        //'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg4
  } 

  let sentimentPieOptions4 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Sentiment Comparison - Doughnut Table using react-data-table-component - Model 4

const sentimentPieTableDataSet4 = sentimentPieData4.map((data) => {
  return ({
    id: data['id'], 
    positive: data['Positive'], 
    neutral: data['Neutral'], 
    negative: data['Negative'],
    positivePercent: data['Positive Percent'], 
    neutralPercent: data['Neutral Percent'], 
    negativePercent: data['Negative Percent'],
    total: data['Total']
  })
});

const sentimentPieTableColumn4 = [
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];





// Sentiment Comparison - Doughnut Chart - Model 5

let sentimentPieDataSet5 = {
  type: 'doughnut',
  labels: [
		'Positive',
		'Neutral',
    'Negative'
	],
  datasets: [
    {
      data: [sentimentPieData5.map(data => data['Positive Percent']), sentimentPieData5.map(data => data['Neutral Percent']), sentimentPieData5.map(data => data['Negative Percent'])],

      backgroundColor: [
        'rgb(26, 115, 55)',
        //'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        //'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg5
  } 

  let sentimentPieOptions5 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Sentiment Comparison - Doughnut Table using react-data-table-component - Model 5

const sentimentPieTableDataSet5 = sentimentPieData5.map((data) => {
  return ({
    id: data['id'], 
    positive: data['Positive'], 
    neutral: data['Neutral'], 
    negative: data['Negative'],
    positivePercent: data['Positive Percent'], 
    neutralPercent: data['Neutral Percent'], 
    negativePercent: data['Negative Percent'],
    total: data['Total']
  })
});

const sentimentPieTableColumn5 = [
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];





// Sentiment Comparison - Doughnut Chart - Model 6

let sentimentPieDataSet6 = {
  type: 'doughnut',
  labels: [
		'Positive',
		'Neutral',
    'Negative'
	],
  datasets: [
    {
      data: [sentimentPieData6.map(data => data['Positive Percent']), sentimentPieData6.map(data => data['Neutral Percent']), sentimentPieData6.map(data => data['Negative Percent'])],

      backgroundColor: [
        'rgb(26, 115, 55)',
        //'rgb(36, 222, 96)',
        'rgb(245, 200, 15)',
        //'rgb(236, 50, 50)',
        'rgb(163, 30, 30)'
        ],
      //options: options
    }],
    text: ratingAvg6
  } 

  let sentimentPieOptions6 = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);


       

      }

    }

  }


// Sentiment Comparison - Doughnut Table using react-data-table-component - Model 6

const sentimentPieTableDataSet6 = sentimentPieData6.map((data) => {
  return ({
    id: data['id'], 
    positive: data['Positive'], 
    neutral: data['Neutral'], 
    negative: data['Negative'],
    positivePercent: data['Positive Percent'], 
    neutralPercent: data['Neutral Percent'], 
    negativePercent: data['Negative Percent'],
    total: data['Total']
  })
});

const sentimentPieTableColumn6 = [
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Percent',
    selector: 'neutralPercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];

// End Chart Data



  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();



  return (
    (!progressBarIsLoading ? 
    <div>

      <div id="pageLoader" className={loadPage1}></div>

      <div id="mainPage" style={{display:loadPage2}}>

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

      </GridContainer>


      <GridContainer>
        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #1</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #1 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection1}
                    onChange={handleBrand1}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems1.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #1 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection1}
                  onChange={handleCategory1}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems1.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #1 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection1}
                  onChange={handleModel1}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems1.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>


{/* Country Selection #1 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection1}
                  onChange={handleCountry1}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems1.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>

{/*  Date Selection #1 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection1, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem1, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate1}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart1 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection1, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem1, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate1}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd1 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #2</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #2 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection2}
                    onChange={handleBrand2}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems2.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #2 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection2}
                  onChange={handleCategory2}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems2.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #2 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection2}
                  onChange={handleModel2}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems2.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>


{/* Country Selection #2 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection2}
                  onChange={handleCountry2}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems2.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>

{/*  Date Selection #2 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection2, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem2, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate2}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart2 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection2, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem2, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate2}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd2 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #3</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #3 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection3}
                    onChange={handleBrand3}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems3.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #3 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection3}
                  onChange={handleCategory3}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems3.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #3 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection3}
                  onChange={handleModel3}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems3.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>


{/* Country Selection #3 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection3}
                  onChange={handleCountry3}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems3.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>

{/*  Date Selection #3 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection3, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem3, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate3}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart3 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection3, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem3, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate3}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd3 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


      </GridContainer>


      <GridContainer>

      <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #4</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #4 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection4}
                    onChange={handleBrand4}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems4.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #4 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection4}
                  onChange={handleCategory4}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems4.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #4 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection4}
                  onChange={handleModel4}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems4.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>


{/* Country Selection #4 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection4}
                  onChange={handleCountry4}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems4.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>

{/*  Date Selection #4 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection4, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem4, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate4}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart4 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection4, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem4, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate4}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd4 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #5</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #5 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection5}
                    onChange={handleBrand5}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems5.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #5 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection5}
                  onChange={handleCategory5}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems5.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #5 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection5}
                  onChange={handleModel5}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems5.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>


{/* Country Selection #1 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection5}
                  onChange={handleCountry5}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems5.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>

{/*  Date Selection #5 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection5, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem5, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate5}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart5 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection5, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem5, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate5}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd5 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={6}
          md={4}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model #6</b>
              </h4>
            </CardHeader>
            <CardBody>

{/* Brand Selection #6 */}
              <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="brand-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Brand
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={brandSelection6}
                    onChange={handleBrand6}
                    inputProps={
                      {
                        name: "brandSelection",
                        id: "brand-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    brandItems6.map((data, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={data}>
                        {data} </MenuItem>
                    ))
                  } </Select>
                </FormControl>

{/* Category Selection #6 */}
                <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection6}
                  onChange={handleCategory6}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems6.map((data, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={data}>
                        {data} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>


{/* Model Selection #6 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection6}
                  onChange={handleModel6}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems6.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>



{/* Country Selection #6 */}

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection6}
                  onChange={handleCountry6}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems6.map((data, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={data}>
                      {data} </MenuItem>
                  ))
                } </Select>
              </FormControl>

{/*  Date Selection #6 */}

              <GridContainer style={{height: "75px"}}>
                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      value={moment(dateStartSelection6, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateStartItem6, 'YYYY-MM-DD')}
                      onChange={handleChangeStartDate6}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect
                      isValidDate={ validStart6 } 
                      
                    />
                </GridItem>

                <GridItem md={6}>
                  <br/>
                  <Datetime 
                      timeFormat={false}
                      inputProps={{ placeholder: "Select End Date" }}
                      value={moment(dateEndSelection6, 'YYYY-MM-DD')}
                      defaultValue ={moment(dateEndItem6, 'YYYY-MM-DD')}
                      onChange={handleChangeEndDate6}
                      dateFormat='YYYY-MM-DD'
                      closeOnSelect 
                      isValidDate={ validEnd6 } 
                    />
                </GridItem>

            </GridContainer >



            </CardBody>
          </Card>

        </GridItem>

      </GridContainer>


      <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={12}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <TopicIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Unique Topic</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="uniqueTopic-select"
                  className={
                    classes.selectLabel
                }>
                  Select Topic
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={uniqueTopicSelection}
                  onChange={handleUniqueTopic}
                  inputProps={
                    {
                      name: "uniqueTopicSelect",
                      id: "uniqueTopic-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  uniqueTopic.map((uniqueTopic, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={uniqueTopic}>
                      {uniqueTopic} </MenuItem>
                  ))
                } </Select>
              </FormControl>
              
            </CardBody>

          </Card>


          

        </GridItem>
        

        </GridContainer>



        <GridContainer >

        <GridItem 
            xs={12}
            container
            direction="row"
            justify="flex-end"
            alignItems="center">

          <Button color="pinterest" onClick={onClickChartData}>
            <RefreshIcon className={classes.icons} />
              Display Chart
          </Button>
          

        </GridItem>

        </GridContainer>

      <br/>


      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Product Comparison</b> </Button>
        </GridItem>

      </GridContainer>



{/* Bubble Chart */}

      <GridContainer>

      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Sentiment Comparison",
                tabIcon: BubbleIcon,
                tabContent: (
                  <div>
            
                    <Bubble 
                      data={bubbleChartData} 
                      options={bubbleChartOptions} 
                      height={400}
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={bubbleTableColumn}
                        data={bubbleTableData}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={bubbleTableData} filename={"Competitor Analytics - Bubble.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  )

                
              }
            ]}
          /> 

      </GridItem>

{/* Radar Chart */}

      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="info"

            
            tabs={[
              {
                tabName: "Category Comparison",
                tabIcon: RadarChartIcon,
                tabContent: (
                  <div>
                  
                    <Radar 
                      height={400} 
                      data={radarComparisonDataSet}  
                      options={radarComparisonOption}
                      datasetKeyProvider={datasetKeyProvider}
                       /> 

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={radarTableColumn}
                        data={radarTableData}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={radarTableData} filename={"Competitor Analytic - Category.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                 )

                
              }
            ]}
          /> 

      </GridItem>


      </GridContainer>


{/* Model 1 - 3 Model Title */}

      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #1</b> </Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #2</b> </Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #3</b> </Button>
        </GridItem>
     
      </GridContainer>


{/* Model 1 - 3 Star Rating */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color={starColor1} stats icon> 
              <CardIcon color={starColor1}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}>SMAT Star Rating</p>
              <h3 className={dashboardClasses.cardTitle}>
                {ratingAvg1} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>

        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          
          <Card>
            <CardHeader color={starColor2} stats icon> 
              <CardIcon color={starColor2}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}>SMAT Star Rating</p>
              <h3 className={dashboardClasses.cardTitle}>
                {ratingAvg2} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>

        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          
          <Card>
            <CardHeader color={starColor3} stats icon> 
              <CardIcon color={starColor3}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}>SMAT Star Rating</p>
              <h3 className={dashboardClasses.cardTitle}>
                {ratingAvg3} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>

        </GridItem>

     
      </GridContainer>


{/* Pie Chart - Model 1 - Model 3 */}

{/* Model 1 Pie */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={starRatingPieDataSet1} 
                      options={starRatingPieOptions1} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={starRatingPieTableColumn1}
                        data={starRatingPieTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={starRatingPieTableDataSet1} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Model 2 Pie */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={starRatingPieDataSet2} 
                      options={starRatingPieOptions2} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={starRatingPieTableColumn2}
                        data={starRatingPieTableDataSet2}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={starRatingPieTableDataSet2} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Model 3 Pie */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={starRatingPieDataSet3} 
                      options={starRatingPieOptions3} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={starRatingPieTableColumn3}
                        data={starRatingPieTableDataSet3}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={starRatingPieTableDataSet3} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>

      </GridContainer>



{/* Sentiment Trend Chart - Model 1 to Model 3 */}

    <GridContainer>

{/* Sentiment Trend Chart - Model 1 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Sentiment Trend",
                tabIcon: TrendingUpIcon,
                tabContent: (
                  <div>
                    <Line 
                      height={215} data={sentimentTrendDataSet1}  
                      options={sentimentTrendOptions1} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentTrendTableColumn1}
                        data={sentimentTrendTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentTrendTableDataSet1} filename={"Competitor Analytic - Sentiment Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

      </GridItem>


{/* Sentiment Trend Chart - Model 2 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="warning"
          
          tabs={[
            {
              tabName: "Sentiment Trend",
                tabIcon: TrendingUpIcon,
              tabContent: (
                <div>
                  <Line 
                    height={215} data={sentimentTrendDataSet2}  
                    options={sentimentTrendOptions2} /> 
                </div>
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={sentimentTrendTableColumn2}
                      data={sentimentTrendTableDataSet2}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={sentimentTrendTableDataSet2} filename={"Competitor Analytic - Sentiment Trend.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>



{/* Sentiment Trend Chart - Model 3 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Sentiment Trend",
                tabIcon: TrendingUpIcon,
                tabContent: (
                  <div>
                    <Line 
                      height={215} data={sentimentTrendDataSet3}  
                      options={sentimentTrendOptions3} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentTrendTableColumn3}
                        data={sentimentTrendTableDataSet3}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentTrendTableDataSet3} filename={"Competitor Analytic - Sentiment Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

      </GridItem>

      

      </GridContainer>




{/* Sentiment Pie Chart - Model 1 to 3 */}
      <GridContainer>



{/* Sentiment Pie Chart - Model 1 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="danger"
            
            tabs={[
              {
                tabName: "Sentiment Comparison",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                    <Doughnut 
                      height={215} 
                      data={sentimentPieDataSet1}  
                      options={sentimentPieOptions1} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentPieTableColumn1}
                        data={sentimentPieTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentPieTableDataSet1} filename={"Competitor Analytic - Sentiment Comparison.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Sentiment Pie Chart - Model 2 */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="danger"
            
            tabs={[
              {
                tabName: "Sentiment Comparison",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                    <Doughnut 
                      height={215} 
                      data={sentimentPieDataSet2}  
                      options={sentimentPieOptions2} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentPieTableColumn2}
                        data={sentimentPieTableDataSet2}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentPieTableDataSet2} filename={"Competitor Analytic - Sentiment Comparison.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>



{/* Sentiment Pie Chart - Model 3 */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="danger"
            
            tabs={[
              {
                tabName: "Sentiment Comparison",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                    <Doughnut 
                      height={215} 
                      data={sentimentPieDataSet3}  
                      options={sentimentPieOptions3} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentPieTableColumn3}
                        data={sentimentPieTableDataSet3}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentPieTableDataSet3} filename={"Competitor Analytic - Sentiment Comparison.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>




      </GridContainer>











{/* Category Summary Radar Chart - Model 1 to Model 3 */}

      <GridContainer>

{/* Category Summary Radar Chart - Model 1 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="rose"
            
            tabs={[
              {
                tabName: "Sentiment by Category",
                tabIcon: RadarChartIcon,
                tabContent: (
                  <div>
                    <Radar 
                      height={215} data={categorySummaryRadarDataSet1}  
                      options={categorySummaryRadarOption1} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={categorySummaryRadarTableColumn1}
                        data={categorySummaryRadarTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={categorySummaryRadarTableDataSet1} filename={"Competitor Analytic - Sentiment by Category.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

      </GridItem>


{/* Category Summary Radar Chart - Model 2 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="rose"
          
          tabs={[
            {
              tabName: "Sentiment by Category",
              tabIcon: RadarChartIcon,
              tabContent: (
                <div>
                  <Radar 
                    height={215} data={categorySummaryRadarDataSet2}  
                    options={categorySummaryRadarOption2} /> 
                </div>
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={categorySummaryRadarTableColumn2}
                      data={categorySummaryRadarTableDataSet2}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={categorySummaryRadarTableDataSet2} filename={"Competitor Analytic - Sentiment by Category.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>


{/* Category Summary Radar Chart - Model 3 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="rose"
          
          tabs={[
            {
              tabName: "Sentiment by Category",
              tabIcon: RadarChartIcon,
              tabContent: (
                <div>
                  <Radar 
                    height={215} data={categorySummaryRadarDataSet3}  
                    options={categorySummaryRadarOption3} /> 
                </div>
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={categorySummaryRadarTableColumn3}
                      data={categorySummaryRadarTableDataSet3}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={categorySummaryRadarTableDataSet3} filename={"Competitor Analytic - Sentiment by Category.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>

      </GridContainer>


{/* Topic List Bar Chart - Model 1 to Model 3 */}

<GridContainer>

{/* Topic List Bar Chart - Model 1 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="success"
            
            tabs={[
              {
                tabName: "Topic by Sentiment",
                tabIcon: TopicIcon,
                tabContent: (
                    <div style={{ height: 500}}>

                      <GridContainer >
                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="center"
                              alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton} onClick={onClicktopicCategoryFilter1("All")}>
                            All
                          </Button>

                          <Tooltip
                              id="tooltip-top"
                              title="Software"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter1("Software")}>
                                <SoftwareIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Picture"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter1("Picture")}>
                                <PictureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Price"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter1("Price")}>
                                <PriceIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Design"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter1("Design")}>
                                <DesignIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Feature"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter1("Feature")}>
                                <FeatureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Sound"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter1("Sound")}>
                                <SoundIcon />
                              </Button>
                            </Tooltip>

                        </div>
                        

                          </GridItem>

                          </GridContainer>
                        <br/>
                        
                        <div>
                          <HorizontalBar 
                            height={420} 
                            data={topicSentimentDataSet1}  
                            options={topicSentimentOption1} 
                            /> 
                        </div>

                        <br />
                        <br />
                      </div>
                  
                  
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={topicSentimentTableColumn1}
                        data={topicSentimentTableDataSet1}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={topicSentimentTableDataSet1} filename={"Competitor Analytic - Sentiment by Topic.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>
                

                    </div>
                )

                
              }
            ]}
            
          /> 

      </GridItem>



{/* Topic List Bar Chart - Model 2 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="success"
          
          tabs={[
            {
              tabName: "Topic by Sentiment",
              tabIcon: TopicIcon,
              tabContent: (
                  <div style={{ height: 500}}>

                    <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton} onClick={onClicktopicCategoryFilter2("All")}>
                          All
                        </Button>

                        <Tooltip
                            id="tooltip-top"
                            title="Software"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter2("Software")}>
                              <SoftwareIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Picture"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter2("Picture")}>
                              <PictureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Price"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter2("Price")}>
                              <PriceIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Design"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter2("Design")}>
                              <DesignIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Feature"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter2("Feature")}>
                              <FeatureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Sound"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter2("Sound")}>
                              <SoundIcon />
                            </Button>
                          </Tooltip>

                      </div>
                      

                        </GridItem>

                        </GridContainer>
                      <br/>
                      
                      <div>
                        <HorizontalBar 
                          height={420} 
                          data={topicSentimentDataSet2}  
                          options={topicSentimentOption2} 
                          /> 
                      </div>

                      <br />
                      <br />
                    </div>
                
                
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={topicSentimentTableColumn2}
                      data={topicSentimentTableDataSet2}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                <CSVLink data={topicSentimentTableDataSet2} filename={"Competitor Analytic - Sentiment by Topic.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>


{/* Topic List Bar Chart - Model 3 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="success"
          
          tabs={[
            {
              tabName: "Topic by Sentiment",
              tabIcon: TopicIcon,
              tabContent: (
                  <div style={{ height: 500}}>

                    <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton} onClick={onClicktopicCategoryFilter3("All")}>
                          All
                        </Button>

                        <Tooltip
                            id="tooltip-top"
                            title="Software"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter3("Software")}>
                              <SoftwareIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Picture"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter3("Picture")}>
                              <PictureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Price"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter3("Price")}>
                              <PriceIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Design"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter3("Design")}>
                              <DesignIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Feature"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter3("Feature")}>
                              <FeatureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Sound"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter3("Sound")}>
                              <SoundIcon />
                            </Button>
                          </Tooltip>

                      </div>
                      

                        </GridItem>

                        </GridContainer>
                      <br/>
                      
                      <div>
                        <HorizontalBar 
                          height={420} 
                          data={topicSentimentDataSet3}  
                          options={topicSentimentOption3} 
                          /> 
                      </div>

                      <br />
                      <br />
                    </div>
                
                
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={topicSentimentTableColumn3}
                      data={topicSentimentTableDataSet3}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                <CSVLink data={topicSentimentTableDataSet3} filename={"Competitor Analytic - Sentiment by Topic.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>

      </GridContainer>





{/* Charts for Model 4 - 6 */}


    
{/* Model 4 - 6 Model Title */}

<GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #4</b> </Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #5</b> </Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Model #6</b> </Button>
        </GridItem>
     
      </GridContainer>


{/* Model 4 - 6 Star Rating */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader color={starColor4} stats icon> 
              <CardIcon color={starColor4}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}>SMAT Star Rating</p>
              <h3 className={dashboardClasses.cardTitle}>
                {ratingAvg4} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>

        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          
          <Card>
            <CardHeader color={starColor5} stats icon> 
              <CardIcon color={starColor5}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}>SMAT Star Rating</p>
              <h3 className={dashboardClasses.cardTitle}>
                {ratingAvg5} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>

        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          
          <Card>
            <CardHeader color={starColor6} stats icon> 
              <CardIcon color={starColor6}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}>SMAT Star Rating</p>
              <h3 className={dashboardClasses.cardTitle}>
                {ratingAvg6} 
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>

        </GridItem>

     
      </GridContainer>


{/* Pie Chart - Model 4 - Model 6 */}

{/* Model 1 Pie */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={starRatingPieDataSet4} 
                      options={starRatingPieOptions4} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={starRatingPieTableColumn4}
                        data={starRatingPieTableDataSet4}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={starRatingPieTableDataSet4} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Model 2 Pie */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={starRatingPieDataSet5} 
                      options={starRatingPieOptions5} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={starRatingPieTableColumn5}
                        data={starRatingPieTableDataSet5}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={starRatingPieTableDataSet5} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Model 3 Pie */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"

            
            tabs={[
              {
                tabName: "Star Rating",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                  
                  <Doughnut 
                      height={215} 
                      data={starRatingPieDataSet6} 
                      options={starRatingPieOptions6} 
                      />

                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: ( 
                    <div>

                      <DataTable
                        title=""
                        columns={starRatingPieTableColumn6}
                        data={starRatingPieTableDataSet6}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={starRatingPieTableDataSet6} filename={"Competitor Analytic - Pie.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>

      </GridContainer>



{/* Sentiment Trend Chart - Model 4 to Model 6 */}

    <GridContainer>

{/* Sentiment Trend Chart - Model 1 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Sentiment Trend",
                tabIcon: TrendingUpIcon,
                tabContent: (
                  <div>
                    <Line 
                      height={215} data={sentimentTrendDataSet4}  
                      options={sentimentTrendOptions4} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentTrendTableColumn4}
                        data={sentimentTrendTableDataSet4}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentTrendTableDataSet4} filename={"Competitor Analytic - Sentiment Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

      </GridItem>


{/* Sentiment Trend Chart - Model 2 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="warning"
          
          tabs={[
            {
              tabName: "Sentiment Trend",
                tabIcon: TrendingUpIcon,
              tabContent: (
                <div>
                  <Line 
                    height={215} data={sentimentTrendDataSet5}  
                    options={sentimentTrendOptions5} /> 
                </div>
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={sentimentTrendTableColumn5}
                      data={sentimentTrendTableDataSet5}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={sentimentTrendTableDataSet5} filename={"Competitor Analytic - Sentiment Trend.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>



{/* Sentiment Trend Chart - Model 3 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="warning"
            
            tabs={[
              {
                tabName: "Sentiment Trend",
                tabIcon: TrendingUpIcon,
                tabContent: (
                  <div>
                    <Line 
                      height={215} data={sentimentTrendDataSet6}  
                      options={sentimentTrendOptions6} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentTrendTableColumn6}
                        data={sentimentTrendTableDataSet6}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentTrendTableDataSet6} filename={"Competitor Analytic - Sentiment Trend.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

      </GridItem>

      

      </GridContainer>




{/* Sentiment Pie Chart - Model 4 to 6 */}
      <GridContainer>



{/* Sentiment Pie Chart - Model 4 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="danger"
            
            tabs={[
              {
                tabName: "Sentiment Comparison",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                    <Doughnut 
                      height={215} 
                      data={sentimentPieDataSet4}  
                      options={sentimentPieOptions4} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentPieTableColumn4}
                        data={sentimentPieTableDataSet4}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentPieTableDataSet4} filename={"Competitor Analytic - Sentiment Comparison.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>


{/* Sentiment Pie Chart - Model 5 */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="danger"
            
            tabs={[
              {
                tabName: "Sentiment Comparison",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                    <Doughnut 
                      height={215} 
                      data={sentimentPieDataSet5}  
                      options={sentimentPieOptions5} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentPieTableColumn5}
                        data={sentimentPieTableDataSet5}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentPieTableDataSet5} filename={"Competitor Analytic - Sentiment Comparison.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>



{/* Sentiment Pie Chart - Model 6 */}

        <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="danger"
            
            tabs={[
              {
                tabName: "Sentiment Comparison",
                tabIcon: PieChartIcon,
                tabContent: (
                  <div>
                    <Doughnut 
                      height={215} 
                      data={sentimentPieDataSet6}  
                      options={sentimentPieOptions6} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={sentimentPieTableColumn6}
                        data={sentimentPieTableDataSet6}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={sentimentPieTableDataSet6} filename={"Competitor Analytic - Sentiment Comparison.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

        </GridItem>




      </GridContainer>











{/* Category Summary Radar Chart - Model 4 to Model 6 */}

      <GridContainer>

{/* Category Summary Radar Chart - Model 4 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="rose"
            
            tabs={[
              {
                tabName: "Sentiment by Category",
                tabIcon: RadarChartIcon,
                tabContent: (
                  <div>
                    <Radar 
                      height={215} data={categorySummaryRadarDataSet4}  
                      options={categorySummaryRadarOption4} /> 
                  </div>
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={categorySummaryRadarTableColumn4}
                        data={categorySummaryRadarTableDataSet4}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={categorySummaryRadarTableDataSet4} filename={"Competitor Analytic - Sentiment by Category.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                )

                
              }
            ]}
          /> 

      </GridItem>


{/* Category Summary Radar Chart - Model 5 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="rose"
          
          tabs={[
            {
              tabName: "Sentiment by Category",
              tabIcon: RadarChartIcon,
              tabContent: (
                <div>
                  <Radar 
                    height={215} data={categorySummaryRadarDataSet5}  
                    options={categorySummaryRadarOption5} /> 
                </div>
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={categorySummaryRadarTableColumn5}
                      data={categorySummaryRadarTableDataSet5}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={categorySummaryRadarTableDataSet5} filename={"Competitor Analytic - Sentiment by Category.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>


{/* Category Summary Radar Chart - Model 6 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="rose"
          
          tabs={[
            {
              tabName: "Sentiment by Category",
              tabIcon: RadarChartIcon,
              tabContent: (
                <div>
                  <Radar 
                    height={215} data={categorySummaryRadarDataSet6}  
                    options={categorySummaryRadarOption6} /> 
                </div>
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={categorySummaryRadarTableColumn6}
                      data={categorySummaryRadarTableDataSet6}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={categorySummaryRadarTableDataSet6} filename={"Competitor Analytic - Sentiment by Category.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>

      </GridContainer>


{/* Topic List Bar Chart - Model 4 to Model 6 */}

<GridContainer>

{/* Topic List Bar Chart - Model 4 */}

      <GridItem xs={12} sm={12} md={4} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="success"
            
            tabs={[
              {
                tabName: "Topic by Sentiment",
                tabIcon: TopicIcon,
                tabContent: (
                    <div style={{ height: 500}}>

                      <GridContainer >
                          <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="center"
                              alignItems="center">

                        <div className={classes.buttonGroup}>
                          <Button color="white" size="sm" className={classes.firstButton} onClick={onClicktopicCategoryFilter4("All")}>
                            All
                          </Button>

                          <Tooltip
                              id="tooltip-top"
                              title="Software"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter4("Software")}>
                                <SoftwareIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Picture"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter4("Picture")}>
                                <PictureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Price"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter4("Price")}>
                                <PriceIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Design"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter4("Design")}>
                                <DesignIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Feature"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter4("Feature")}>
                                <FeatureIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip
                              id="tooltip-top"
                              title="Sound"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter4("Sound")}>
                                <SoundIcon />
                              </Button>
                            </Tooltip>

                        </div>
                        

                          </GridItem>

                          </GridContainer>
                        <br/>
                        
                        <div>
                          <HorizontalBar 
                            height={420} 
                            data={topicSentimentDataSet4}  
                            options={topicSentimentOption4} 
                            /> 
                        </div>

                        <br />
                        <br />
                      </div>
                  
                  
                )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                      <DataTable
                        title=""
                        columns={topicSentimentTableColumn4}
                        data={topicSentimentTableDataSet4}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink data={topicSentimentTableDataSet4} filename={"Competitor Analytic - Sentiment by Topic.csv"}>
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>
                

                    </div>
                )

                
              }
            ]}
            
          /> 

      </GridItem>



{/* Topic List Bar Chart - Model 5 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="success"
          
          tabs={[
            {
              tabName: "Topic by Sentiment",
              tabIcon: TopicIcon,
              tabContent: (
                  <div style={{ height: 500}}>

                    <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton} onClick={onClicktopicCategoryFilter5("All")}>
                          All
                        </Button>

                        <Tooltip
                            id="tooltip-top"
                            title="Software"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter5("Software")}>
                              <SoftwareIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Picture"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter5("Picture")}>
                              <PictureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Price"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter5("Price")}>
                              <PriceIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Design"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter5("Design")}>
                              <DesignIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Feature"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter5("Feature")}>
                              <FeatureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Sound"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter5("Sound")}>
                              <SoundIcon />
                            </Button>
                          </Tooltip>

                      </div>
                      

                        </GridItem>

                        </GridContainer>
                      <br/>
                      
                      <div>
                        <HorizontalBar 
                          height={420} 
                          data={topicSentimentDataSet5}  
                          options={topicSentimentOption5} 
                          /> 
                      </div>

                      <br />
                      <br />
                    </div>
                
                
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={topicSentimentTableColumn5}
                      data={topicSentimentTableDataSet5}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={topicSentimentTableDataSet5} filename={"Competitor Analytic - Sentiment by Topic.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>


{/* Topic List Bar Chart - Model 6 */}

      <GridItem xs={12} sm={12} md={4} >

      <CustomTabs
          //title="Tasks:"
          centered={true}
          headerColor="success"
          
          tabs={[
            {
              tabName: "Topic by Sentiment",
              tabIcon: TopicIcon,
              tabContent: (
                  <div style={{ height: 500}}>

                    <GridContainer >
                        <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center">

                      <div className={classes.buttonGroup}>
                        <Button color="white" size="sm" className={classes.firstButton} onClick={onClicktopicCategoryFilter6("All")}>
                          All
                        </Button>

                        <Tooltip
                            id="tooltip-top"
                            title="Software"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter6("Software")}>
                              <SoftwareIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Picture"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter6("Picture")}>
                              <PictureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Price"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter6("Price")}>
                              <PriceIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Design"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter6("Design")}>
                              <DesignIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Feature"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter6("Feature")}>
                              <FeatureIcon />
                            </Button>
                          </Tooltip>

                          <Tooltip
                            id="tooltip-top"
                            title="Sound"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button color="white" size="sm" className={classes.middleButton} onClick={onClicktopicCategoryFilter6("Sound")}>
                              <SoundIcon />
                            </Button>
                          </Tooltip>

                      </div>
                      

                        </GridItem>

                        </GridContainer>
                      <br/>
                      
                      <div>
                        <HorizontalBar 
                          height={420} 
                          data={topicSentimentDataSet6}  
                          options={topicSentimentOption6} 
                          /> 
                      </div>

                      <br />
                      <br />
                    </div>
                
                
              )
            },
            {
              tabName: "Table",
              tabIcon: AssignmentIcon,
                tabContent: (
                  <div>
                    <DataTable
                      title=""
                      columns={topicSentimentTableColumn6}
                      data={topicSentimentTableDataSet6}
                      //conditionalRowStyles={conditionalRowStyles}
                      // pagination={true}
                      // paginationPerPage={10}
                      // paginationComponentOptions={{ noRowsPerPage: true }}
                      fixedHeader={true}
                      //fixedHeaderScrollHeight={"445px"}
                      fixedHeaderScrollHeight={"300px"}
                      noHeader={true}
                    /> 
                <GridContainer>
                <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
              
                  <CSVLink data={topicSentimentTableDataSet6} filename={"Competitor Analytic - Sentiment by Topic.csv"}>
                    <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                      <DownloadIcon className={classes.icons} />
                        Download CSV 
                    </Button>
                  </CSVLink>

                </GridItem>

              </GridContainer>

                  </div>
              )

              
            }
          ]}
        /> 

      </GridItem>


      </GridContainer>


    

             





    </div></div>: <LinearProgressWithLabel value={progress} style={{textAlign: 'center'}}/>)
  );
}
