/*eslint-disable*/

import React from "react";

// CSS
import "../../assets/css/page-loading.css"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Zoom from "@material-ui/core/Zoom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


// @material-ui/icons
import WebsiteIcon from "@material-ui/icons/Language";
import CategoryIcon from "@material-ui/icons/Category";
import CountryIcon from "@material-ui/icons/Public";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/GetApp";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import BrandIcon from "@material-ui/icons/Loyalty";
import ModelIcon from "@material-ui/icons/Devices";
import DateIcon from "@material-ui/icons/DateRange";
import FilterIcon from "@material-ui/icons/FilterList";
import ThumpsUpIcon from "@material-ui/icons/ThumbUp";
import ThumpsDownIcon from "@material-ui/icons/ThumbDown";
import HandIcon from "@material-ui/icons/PanTool";
import RemoveIcon from "@material-ui/icons/Remove";
import StarsIcon from "@material-ui/icons/Star";
import PieChartIcon from "@material-ui/icons/DonutSmall";
import RadarChartIcon from "@material-ui/icons/TrackChanges";
import BarChartIcon from "@material-ui/icons/BarChart";
import SoftwareIcon from "@material-ui/icons/Apps";
import PictureIcon from "@material-ui/icons/Image";
import PriceIcon from "@material-ui/icons/AttachMoney";
import DesignIcon from "@material-ui/icons/DataUsage";
import FeatureIcon from "@material-ui/icons/Settings";
import SoundIcon from "@material-ui/icons/VolumeMute";
import PositiveIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import CompareIcon from "@material-ui/icons/Compare";
import TopicIcon from "@material-ui/icons/ListAlt";
import FullReviewIcon from "@material-ui/icons/Message";
import Close from "@material-ui/icons/Close";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"

// 3rd Party Component
import { Line, Doughnut, Radar, Bar, Chart, HorizontalBar } from 'react-chartjs-2'; // 3rd Party Chart Component
import DataTable from 'react-data-table-component'; // 3rd Party Table Component
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import Datetime from "react-datetime"; // react plugin for creating date-time-picker
import moment from "moment"; // Date Time component
import ReactWordcloud from 'react-wordcloud'; // Word Cloud Component
import "tippy.js/dist/tippy.css"; // Word Cloud Component CSS
import "tippy.js/animations/scale.css"; // Word Cloud Component CSS
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component




const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);
const useModalDashboard = makeStyles(modalStyles);


const drillDownLoading = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));



function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="h4" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: 100,
};



export default function AudioRatingAnalytic() {


  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Audio Rating Analytic"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])


  // Loading by Section
  const [progress, setProgress] = React.useState(10);

  // Load Page
  const [loadPage1, setLoadPage1] = React.useState("loader");
  const [loadPage2, setLoadPage2] = React.useState("none");

  const [audioChartUIsLoading, setAudioChartUIsLoading] = React.useState(false);
  const [topicInsightIsLoading, setTopicInsightIsLoading] = React.useState(false);

  // filters
  const [allFilters, setAllFilters] = React.useState([]);

  const [brandItems, setBrandItems] = React.useState(['']);
  const [brandSelection, setBrandSelection] = React.useState('');

  const [categoryItems, setCategoryItems] = React.useState([]);
  const [categorySelection, setCategorySelection] = React.useState('');

  const [modelItems, setModelItems] = React.useState([]);
  const [modelSelection, setModelSelection] = React.useState([]);

  const [websiteItems, setWebsiteItems] = React.useState([]);
  const [websiteSelection, setWebsiteSelection] = React.useState([]);

  const [countryItems, setCountryItems] = React.useState([]);
  const [countrySelection, setCountrySelection] = React.useState([]);

  const [dateStartItem, setDateStartItem] = React.useState('');
  const [dateStartSelection, setDateStartSelection] = React.useState('');

  const [dateEndItem, setDateEndItem] = React.useState('');
  const [dateEndSelection, setDateEndSelection] = React.useState('');

  const [uniqueTopic, setUniqueTopic] = React.useState(false);

  // Category Overview
  const [categorySummaryData, setCategorySummaryData] = React.useState([]);
  const [categoryLabel, setCategoryLabel] = React.useState([]);
  const [categoryRating, setCategoryRating] = React.useState([]); // Order: bass, midrange, treble, soundStage, imaging, distortion
  const [categoryColor, setCategoryColor] = React.useState([]);
  
  const [categoryFiveStarCount, setCategoryFiveStarCount] = React.useState([]);
  const [categoryFourStarCount, setCategoryFourStarCount] = React.useState([]);
  const [categoryThreeStarCount, setCategoryThreeStarCount] = React.useState([]);
  const [categoryTwoStarCount, setCategoryTwoStarCount] = React.useState([]);
  const [categoryOneStarCount, setCategoryOneStarCount] = React.useState([]);

  const [categoryFiveStarPercent, setCategoryFiveStarPercent] = React.useState([]);
  const [categoryFourStarPercent, setCategoryFourStarPercent] = React.useState([]);
  const [categoryThreeStarPercent, setCategoryThreeStarPercent] = React.useState([]);
  const [categoryTwoStarPercent, setCategoryTwoStarPercent] = React.useState([]);
  const [categoryOneStarPercent, setCategoryOneStarPercent] = React.useState([]);

  const [categoryPositiveCount, setCategoryPositiveCount] = React.useState([]);
  const [categoryNegativeCount, setCategoryNegativeCount] = React.useState([]);
  const [categoryNeutralCount, setCategoryNeutralCount] = React.useState([]);

  const [categoryPositivePercent, setCategoryPositivePercent] = React.useState([]);
  const [categoryNegativePercent, setCategoryNegativePercent] = React.useState([]);

  const [categoryBass, setCategoryBass] = React.useState([]);
  const [categoryMidrange, setCategoryMidrange] = React.useState([]);
  const [categoryTreble, setCategoryTreble] = React.useState([]);
  const [categorySoundStage, setCategorySoundStage] = React.useState([]);
  const [categoryImaging, setCategoryImaging] = React.useState([]);
  const [categoryDistortion, setCategoryDistortion] = React.useState([]);


  // Topic Overview
  const [topicOverviewPositiveTopic, setTopicOverviewPositiveTopic] = React.useState([]);
  const [topicOverviewNegativeTopic, setTopicOverviewNegativeTopic] = React.useState([]);

  const [topicComparisonList, setTopicComparisonList] = React.useState([]);
  const [topicSentimentComparisonList, setTopicSentimentComparisonList] = React.useState([]);

  const [topicComparisonListAll, setTopicComparisonListAll] = React.useState([]);

  const [bassTopicList, setBassTopicList] = React.useState([]);
  const [midrangeTopicList, setMidrangeTopicList] = React.useState([]);
  const [trebleTopicList, setTrebleTopicList] = React.useState([]);
  const [soundStageTopicList, setSoundStageTopicList] = React.useState([]);
  const [imagingTopicList, setImagingTopicList] = React.useState([]);
  const [distortionTopicList, setDistortionTopicList] = React.useState([]);


  // Topic Insight
  const [topicInsightItems, setTopicInsightItems] = React.useState(['']);
  const [topicInsightSelection, setTopicInsightSelection] = React.useState('');

  const [topicInsightTopicPhraseData, setTopicInsightTopicPhraseData] = React.useState([]);
  const [topicInsightPositiveSentimentData, setTopicInsightPositiveSentimentData] = React.useState([]);
  const [topicInsightNegativeSentimentData, setTopicInsightNegativeSentimentData] = React.useState([]);
  const [topicInsightTopicTrendData, setTopicInsightTopicTrendData] = React.useState([]);


  // Sentiment Overview
  const [sentimentOverviewPositveData, setSentimentOverviewPositiveData] = React.useState([]);
  const [sentimentOverviewNegativeData, setSentimentOverviewNegativeData] = React.useState([]);
  const [sentimentOverviewFilter, setSentimentOverviewFilter] = React.useState('All');

  // Category Detail Selection
  const [categoryDetailSelection, setCategoryDetailSelection] = React.useState("Bass");
  const [posValueBox, setPosValueBox] = React.useState("Bass");
  const [neuValueBox, setNeuValueBox] = React.useState("Bass");
  const [negValueBox, setNegValueBox] = React.useState("Bass");
  const [ratingValueBox, setRatingValueBox] = React.useState("Bass");

  // View Individual Sentence
  const [viewIndividualSentenceData, setViewIndividualSentenceData] = React.useState([]);
  const [viewIndividualSentenceIsLoading, setViewIndividualSentenceIsLoading] = React.useState(false);

  React.useEffect(() => {
  
  
    const axios = require('axios');
  
    axios.get(`${SMATrestAPI.audioRatingAnalytic[0].Filters}`).then(function (response) { 
      // handle success

      setAllFilters(response.data.getFilter);

      let brandFilter = response.data.getFilter.map(data => data['Brand']);
      let uniqueBrand = [...new Set(brandFilter)];
      uniqueBrand.unshift("Sony");
      setBrandItems(uniqueBrand);

      
    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
      setLoadPage1("");
      setLoadPage2("");
    });
  
  
    }, [])


  
    const handleBrand= event => {
      setBrandSelection(event.target.value);


      let categoryFilter = allFilters.filter(data => data['Brand'] === event.target.value);

      let uniqueCategory = categoryFilter.map(data => data['Product.Category']);
      uniqueCategory = [...new Set(uniqueCategory)];
      uniqueCategory = uniqueCategory.sort();

      setCategoryItems(uniqueCategory);

      setCategorySelection('');
      setModelSelection([]);
      setWebsiteSelection([]);
      setCountrySelection([]);
      setDateStartSelection('');
      setDateEndSelection('');

    };
  
    const handleCategory = event => {
      setCategorySelection(event.target.value);



      let modelFilter = allFilters.filter(data => data['Brand'] === brandSelection);
      modelFilter = modelFilter.filter(data => data['Product.Category'] === event.target.value);

      let uniqueModel = modelFilter.map(data => data['Model']);
      uniqueModel = [...new Set(uniqueModel)];
      uniqueModel = uniqueModel.sort();
      setModelItems(uniqueModel);


      setModelSelection([]);
      setWebsiteSelection([]);
      setCountrySelection([]);
      setDateStartSelection('');
      setDateEndSelection('');
    };
  
    const handleModel = event => {
      setModelSelection(event.target.value);


      let websiteFilter = allFilters.filter(data => data['Brand'] === brandSelection);
      websiteFilter = websiteFilter.filter(data => data['Product.Category'] === categorySelection);

      let selectedItems = event.target.value;
      websiteFilter = websiteFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9] );
  
      let uniqueWebsite = websiteFilter.map(data => data['Website']);
      uniqueWebsite = [...new Set(uniqueWebsite)];
      uniqueWebsite = uniqueWebsite.sort();

      setWebsiteItems(uniqueWebsite);


      setWebsiteSelection([]);
      setCountrySelection([]);
      setDateStartSelection('');
      setDateEndSelection('');

    };
  
  
    const handleWebsite = event => {
      setWebsiteSelection(event.target.value);


      let countryFilter = allFilters.filter(data => data['Brand'] === brandSelection);
      countryFilter = countryFilter.filter(data => data['Product.Category'] === categorySelection);

      let selectedItems = modelSelection;
      countryFilter = countryFilter.filter(data => data['Model'] == selectedItems[0] || data['Model'] == selectedItems[1] || data['Model'] == selectedItems[2] ||  data['Model'] == selectedItems[3] ||  data['Model'] == selectedItems[4] ||  data['Model'] == selectedItems[5] ||  data['Model'] == selectedItems[6] ||  data['Model'] == selectedItems[7] ||  data['Model'] == selectedItems[8] ||  data['Model'] == selectedItems[9] );
  
      let selectedItems2 = event.target.value;
      countryFilter = countryFilter.filter(data => data['Website'] == selectedItems2[0] || data['Model'] == selectedItems2[1] || data['Model'] == selectedItems2[2] ||  data['Model'] == selectedItems2[3] ||  data['Model'] == selectedItems2[4] ||  data['Model'] == selectedItems2[5] ||  data['Model'] == selectedItems2[6] ||  data['Model'] == selectedItems2[7] ||  data['Model'] == selectedItems2[8] ||  data['Model'] == selectedItems2[9] );

      let uniqueCountry = countryFilter.map(data => data['Country']);
      uniqueCountry = [...new Set(uniqueCountry)];
      uniqueCountry = uniqueCountry.sort();

      setCountryItems(uniqueCountry);



      setDateStartSelection('');
      setDateEndSelection('');

    };
  
    const handleCountry = event => {
      setCountrySelection(event.target.value);


      let restGETurl = `${"brandName="}${brandSelection}${"&modelName="}${modelSelection}${"&websiteName="}${websiteSelection}${"&countryName="}${event.target.value}`;
      restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
      restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url
    
      const axios = require('axios');
    
      axios.get(`${SMATrestAPI.audioRatingAnalytic[0].FiltersDates}${restGETurl}`).then(function (response) { 
        // handle success
    
      setDateStartItem(response.data.minDate);
      setDateEndItem(response.data.maxDate);
    
      setDateStartSelection(response.data.minDate);
      setDateEndSelection(response.data.maxDate);

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });

      
    }; 
  
  
    const handleDateStart = event => {

      // Prevent function error if user manually inputs
      if (typeof event === 'object') {
        let aDate = event.format();
        aDate = aDate.substring(0, 10);
    
        setDateStartSelection(aDate);
      }
  
    }
  
    const handleDateEnd = event => {
  
      // Prevent function error if user manually inputs
      if (typeof event === 'object') {
        let aDate = event.format();
        aDate = aDate.substring(0, 10);
        
        setDateEndSelection(aDate);
      }
  
    }
  
  
    var yesterday = moment(dateStartItem, 'YYYY-MM-DD').subtract(1, "day");
    function validStart(current) {
      return current.isAfter(yesterday);
    }
  
    var tommorrow = moment(dateEndItem, 'YYYY-MM-DD').add(1, "day");
    function validEnd(current) {
      return current.isBefore(tommorrow);
    }
  
  


    const onClickChartData = event => {
      // Loading
      setAudioChartUIsLoading(true);

      setProgress(0);
      setProgress(prevCount => prevCount + 15);

      const axios = require('axios');


      // Send Model Information to SMAT DB after user clicks submit
      var restGETurl00 = `${"page="}${"Audio Rating Analytic"}${"&brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}`;
      restGETurl00 = restGETurl00.replaceAll(",", '%2C'); // replace comma for url
      restGETurl00 = restGETurl00.replaceAll(" ", '%20'); // empty space for url

      axios.get(`${SMATrestAPI.smatLog[0].modelInfo}${restGETurl00}`).then(function (response) { 

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
      });



      // Category Rating
      let restGETurl1 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
      restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
      restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

      axios.get(`${SMATrestAPI.audioRatingAnalytic[0].ratingSummary}${restGETurl1}`).then(function (response) { 
        // handle success

        setCategoryRating(response.data.categoryRating);


      }).catch(function (error) { 
        // handle error
        console.log(error);

      }).then(function () { 
        // always executed
        setProgress(prevCount => prevCount + 15);
      });


      // Category Summary
      let restGETurl2 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
      restGETurl2 = restGETurl2.replaceAll(",", '%2C'); // replace comma for url
      restGETurl2 = restGETurl2.replaceAll(" ", '%20'); // empty space for url

      axios.get(`${SMATrestAPI.audioRatingAnalytic[0].categorySummary}${restGETurl2}`).then(function (response) { 
        // handle success

        setCategorySummaryData(response.data.categorySummary);

        let labelData = response.data.categorySummary.map(data => data['Category']);
        setCategoryLabel(labelData);
    
        let fiveStarCountData = response.data.categorySummary.map(data => data['5-Star Count']);
        setCategoryFiveStarCount(fiveStarCountData);
    
        let fourStarCountData = response.data.categorySummary.map(data => data['4-Star Count']);
        setCategoryFourStarCount(fourStarCountData);
    
        let threeStarCountData = response.data.categorySummary.map(data => data['3-Star Count']);
        setCategoryThreeStarCount(threeStarCountData);
    
        let twoStarCountData = response.data.categorySummary.map(data => data['2-Star Count']);
        setCategoryTwoStarCount(twoStarCountData);
    
        let oneStarCountData = response.data.categorySummary.map(data => data['1-Star Count']);
        setCategoryOneStarCount(oneStarCountData);
    
        let fiveStarPercentData = response.data.categorySummary.map(data => data['5-Star Percent']);
        setCategoryFiveStarPercent(fiveStarPercentData);
    
        let fourStarPercentData = response.data.categorySummary.map(data => data['4-Star Percent']);
        setCategoryFourStarPercent(fourStarPercentData);
    
        let threeStarPercentData = response.data.categorySummary.map(data => data['3-Star Percent']);
        setCategoryThreeStarPercent(threeStarPercentData);
    
        let twoStarPercentData = response.data.categorySummary.map(data => data['2-Star Percent']);
        setCategoryTwoStarPercent(twoStarPercentData);
    
        let oneStarPercentData = response.data.categorySummary.map(data => data['1-Star Percent']);
        setCategoryOneStarPercent(oneStarPercentData);
    
        let positiveCountData = response.data.categorySummary.map(data => data['Positive Count']);
        setCategoryPositiveCount(positiveCountData);
    
        let negativeCountData = response.data.categorySummary.map(data => data['Negative Count']);
        setCategoryNegativeCount(negativeCountData);

        let total = response.data.categorySummary.map(data => data['Star Total']);
        let bassNeutralCount = total[0] - (positiveCountData[0] + negativeCountData[0])
        let midrangeNeutralCount = total[1] - (positiveCountData[1] + negativeCountData[1])
        let trebleNeutralCount = total[2] - (positiveCountData[2] + negativeCountData[2])
        let soundStageNeutralCount = total[3] - (positiveCountData[3] + negativeCountData[3])
        let imagingNeutralCount = total[4] - (positiveCountData[4] + negativeCountData[4])
        let distortionNeutralCount = total[5] - (positiveCountData[5] + negativeCountData[5])

        setCategoryNeutralCount([bassNeutralCount, midrangeNeutralCount, trebleNeutralCount, soundStageNeutralCount, imagingNeutralCount, distortionNeutralCount]);

        let positivePercentData = response.data.categorySummary.map(data => data['Positive Percent']);
        setCategoryPositivePercent(positivePercentData);
    
        let negativePercentData = response.data.categorySummary.map(data => data['Negative Percent']);
        setCategoryNegativePercent(negativePercentData);
    
        setCategoryBass(response.data.bassSummary);
        setCategoryMidrange(response.data.midrangeSummary);
        setCategoryTreble(response.data.trebleSummary);
        setCategorySoundStage(response.data.soundStageSummary);
        setCategoryImaging(response.data.imagingSummary);
        setCategoryDistortion(response.data.distortionSummary);


      }).catch(function (error) { 
        // handle error
        console.log(error);

      }).then(function () { 
        // always executed
        setProgress(prevCount => prevCount + 15);
      });



      // Topic Overview Data
      let restGETurl3 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
      restGETurl3 = restGETurl3.replaceAll(",", '%2C'); // replace comma for url
      restGETurl3 = restGETurl3.replaceAll(" ", '%20'); // empty space for url

      axios.get(`${SMATrestAPI.audioRatingAnalytic[0].topicOverview}${restGETurl3}`).then(function (response) { 
        // handle success

        setTopicOverviewPositiveTopic(response.data.positiveTopics);
        setTopicOverviewNegativeTopic(response.data.negativeTopics);
        setTopicComparisonList(response.data.TopicComparisonList);
        setTopicComparisonListAll(response.data.TopicComparisonListAll);
        setTopicSentimentComparisonList(response.data.topicSentimentComparison);
        
        setBassTopicList(response.data.bassTopic);
        setMidrangeTopicList(response.data.midrangeTopic);
        setTrebleTopicList(response.data.trebleTopic);
        setSoundStageTopicList(response.data.soundStageTopic);
        setImagingTopicList(response.data.imagingTopic);
        setDistortionTopicList(response.data.distortionTopic);

        // Topic Insight
        setTopicInsightSelection(''); // Need to Reset the list or a crash will occur

        let topicListForSelection = response.data.TopicComparisonListAll.map(data => data['Topic']);
        setTopicInsightItems(topicListForSelection.sort());

      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        setProgress(prevCount => prevCount + 15);
      });


      // Sentiment Overview Data
      let restGETurl4 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
      restGETurl4 = restGETurl4.replaceAll(",", '%2C'); // replace comma for url
      restGETurl4 = restGETurl4.replaceAll(" ", '%20'); // empty space for url

      axios.get(`${SMATrestAPI.audioRatingAnalytic[0].sentimentOverview}${restGETurl4}`).then(function (response) { 
        // handle success

        setSentimentOverviewPositiveData(response.data.sentimentPos);
        setSentimentOverviewNegativeData(response.data.sentimentNeg);
        
        // console.log(response.data.sentimentPos);
        // console.log(response.data.sentimentNeg);


      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        setProgress(prevCount => prevCount + 15);
      });



      // Individual Sentence
      setViewIndividualSentenceIsLoading(true);
      let restGETurl5 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}`;
      restGETurl5 = restGETurl5.replaceAll(",", '%2C'); // replace comma for url
      restGETurl5 = restGETurl5.replaceAll(" ", '%20'); // empty space for url

      axios.get(`${SMATrestAPI.audioRatingAnalytic[0].viewIndividualSentence}${restGETurl5}`).then(function (response) { 
        // handle success

        setViewIndividualSentenceData(response.data.individualSentence);


      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed
        setProgress(prevCount => prevCount + 15);
        setViewIndividualSentenceIsLoading(false);
      });






      //setTimeout(() => {  setAudioChartUIsLoading(false); }, 1000);

    }



  // SMAT Log
  React.useEffect(() => {
    if(progress > 80){
      //setAudioChartUIsLoading(false)
      setTimeout(() => {  setAudioChartUIsLoading(false); }, 1000);
    }



  }, [progress]) 



  function getRating(category){

    let data = categoryRating.map(data => data["Count"]);
    let result = '';

    if(category === "Bass"){
      result = data[0]
    }

    if(category === "Midrange"){
      result = data[1]
    }

    if(category === "Treble"){
      result = data[2]
    }

    if(category === "Sound Stage"){
      result = data[3]
    }

    if(category === "Imaging"){
      result = data[4]
    }

    if(category === "Noise"){
      result = data[5]
    }


    return result

  }


  function getColor(category){

    let data = categoryRating.map(data => data["Color"]);
    let result = 'info';

    if(category === "Bass"){
      result = data[0]
    }

    if(category === "Midrange"){
      result = data[1]
    }

    if(category === "Treble"){
      result = data[2]
    }

    if(category === "Sound Stage"){
      result = data[3]
    }

    if(category === "Imaging"){
      result = data[4]
    }

    if(category === "Noise"){
      result = data[5]
    }


    return result

  }


  function getIcon(category){

    let data = categoryRating.map(data => data["Color"]);
    let result;

    if(category === "Bass"){
      if(data[0] === "success"){result = <ThumpsUpIcon />;} 
      if(data[0] === "danger"){result = <ThumpsDownIcon />;} 
      if(data[0] === "warning"){result = <HandIcon />;} 
      if(data[0] === "info"){result = <RemoveIcon />;} 
    }

    if(category === "Midrange"){
      if(data[1] === "success"){result = <ThumpsUpIcon />;} 
      if(data[1] === "danger"){result = <ThumpsDownIcon />;} 
      if(data[1] === "warning"){result = <HandIcon />;} 
      if(data[1] === "info"){result = <RemoveIcon />;} 
    }

    if(category === "Treble"){
      if(data[2] === "success"){result = <ThumpsUpIcon />;} 
      if(data[2] === "danger"){result = <ThumpsDownIcon />;} 
      if(data[2] === "warning"){result = <HandIcon />;} 
      if(data[2] === "info"){result = <RemoveIcon />;} 
    }

    if(category === "Sound Stage"){
      if(data[3] === "success"){result = <ThumpsUpIcon />;} 
      if(data[3] === "danger"){result = <ThumpsDownIcon />;} 
      if(data[3] === "warning"){result = <HandIcon />;} 
      if(data[3] === "info"){result = <RemoveIcon />;} 
    }

    if(category === "Imaging"){
      if(data[4] === "success"){result = <ThumpsUpIcon />;} 
      if(data[4] === "danger"){result = <ThumpsDownIcon />;} 
      if(data[4] === "warning"){result = <HandIcon />;} 
      if(data[4] === "info"){result = <RemoveIcon />;} 
    }

    if(category === "Noise"){
      if(data[5] === "success"){result = <ThumpsUpIcon />;} 
      if(data[5] === "danger"){result = <ThumpsDownIcon />;} 
      if(data[5] === "warning"){result = <HandIcon />;} 
      if(data[5] === "info"){result = <RemoveIcon />;} 
    }


    return result

  }


  // Category Summary Radar Chart

let categorySummaryRadarDataSet = {
  spanGaps: true,
  type: 'radar',
  labels: categoryLabel,
  datasets: [

    {
      label: "Negative",
      data:categoryNegativePercent,
      borderColor: 'rgb(163, 30, 30)', // Line Color
      backgroundColor: "rgba(163, 30, 30, .6)", // Fill Color
      pointBackgroundColor: "rgb(163, 30, 30)", // Dot Color
    },
    {
      label: "Positive",
      data:categoryPositivePercent,
      borderColor: 'rgb(26, 115, 55)', // Line Color
      backgroundColor: "rgba(26, 115, 55, .6)", // Fill Color
      pointBackgroundColor: "rgb(26, 115, 55)", // Dot Color
    }
    
  ]
  } 


  let categorySummaryRadarOption = {

    // Drill Down
    onClick: (event, elements) => {

      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        //console.log(dataset.label + " at " + xLabel + ": " + value);


      }

    },
    
    scale: {
      angleLines: {
          display: false
      },
      pointLabels: {
        fontSize: 20,
      },
      gridLines: {
        color: 'black'
      },
      angleLines: {
        color: 'black' 
      },
      ticks: {
        fontSize: 12,
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
         }
      }
  }

  }


  // Category Summary Radar Table using react-data-table-component 

  const categorySummaryRadarTableDataSet = categorySummaryData.map((CategorySummaryData) => {
    return ({
      id: CategorySummaryData.id,
      category: CategorySummaryData["Category"],
      positiveCount: CategorySummaryData["Positive Count"], 
      negativeCount: CategorySummaryData["Negative Count"], 
      positivePercent: CategorySummaryData["Positive Percent"], 
      negativePercent: CategorySummaryData["Negative Percent"], 

    })
  });


  const categorySummaryRadarTableColumn = [
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Count',
    selector: 'positiveCount',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Count',
    selector: 'negativeCount',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Percent',
    selector: 'positivePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Percent',
    selector: 'negativePercent',
    sortable: true,
    center: true
  }

  ];  




  // Category Summary Bar Chart

  let categorySummaryBarDataSet = {
    type: 'bar',
    labels: categoryLabel,
    datasets: [

      {
        label: "5-Star",
        data:categoryFiveStarPercent,
        borderColor: 'rgb(26, 115, 55)', // Line Color
        backgroundColor: "rgba(26, 115, 55, .9)", // Fill Color
      },
      {
        label: "4-Star",
        data:categoryFourStarPercent,
        borderColor: 'rgb(36, 222, 96)', // Line Color
        backgroundColor: "rgba(36, 222, 96, .9)", // Fill Color
      },
      {
        label: "3-Star",
        data:categoryThreeStarPercent,
        borderColor: 'rgb(245, 200, 15)', // Line Color
        backgroundColor: "rgba(245, 200, 15, .9)", // Fill Color
      },
      {
        label: "2-Star",
        data:categoryTwoStarPercent,
        borderColor: 'rgb(236, 50, 50)', // Line Color
        backgroundColor: "rgba(236, 50, 50, .9)", // Fill Color
      }
      ,
      {
        label: "1-Star",
        data:categoryOneStarPercent,
        borderColor: 'rgb(163, 30, 30)', // Line Color
        backgroundColor: "rgba(163, 30, 30, .9)", // Fill Color
      }
      
    ]
    } 


    let categorySummaryBarOption = {

      // Drill Down
      onClick: (event, elements) => {

        if(elements.length > 0){ // undefine cause app to crash
          const chart = elements[0]._chart;
          const element = chart.getElementAtEvent(event)[0];
          const dataset = chart.data.datasets[element._datasetIndex];
          const xLabel = chart.data.labels[element._index];
          const value = dataset.data[element._index];
          console.log(dataset.label + " at " + xLabel + ": " + value);



        }

      },
      
      scales: {
        xAxes: [{
            stacked: true
        }],
        yAxes: [
          {
            stacked: true,
            ticks: {
              fontSize: 12,
              min: 0,
              max: 100,
              callback: function(value) {
                return value + "%"
              }
            }
          }
        ],

        
        scaleLabel: {
          display: true,
          labelString: 'Percentage',
        }
    }

    

    }



  // Category Summary Bar Chart Table using react-data-table-component 

  const categorySummaryBarTableDataSet = categorySummaryData.map((CategorySummaryData) => {
      return ({
        //id: CategorySummaryData.id,
        category: CategorySummaryData['Category'],
        fiveStar: CategorySummaryData['5-Star Count'], 
        fourStar: CategorySummaryData['4-Star Count'], 
        threeStar: CategorySummaryData['3-Star Count'], 
        twoStar: CategorySummaryData['2-Star Count'], 
        oneStar: CategorySummaryData['1-Star Count']
    
      })
    });



  const categorySummaryBarTableColumn = [
    {
      name: 'Category',
      selector: 'category',
      sortable: true,
      center: true
    },
  {
    name: '5-Star',
    selector: 'fiveStar',
    sortable: true,
    center: true
  },
  {
    name: '4-Star',
    selector: 'fourStar',
    sortable: true,
    center: true
  },
  {
    name: '3-Star',
    selector: 'threeStar',
    sortable: true,
    center: true
  },
  {
    name: '2-Star',
    selector: 'twoStar',
    sortable: true,
    center: true
  },
  {
    name: '1-Star',
    selector: 'oneStar',
    sortable: true,
    center: true
  }
  ];


  // Topic Overview - Topic Sentiment Comparison List - Pie Chart

  let topicOverviewTopicSentimentComparisonLabel = topicSentimentComparisonList.map(data => data['Sentiment']);  
  let topicOverviewTopicSentimentComparisonCount = topicSentimentComparisonList.map(data => data['Count']);
  let topicOverviewTopicSentimentComparisonColor = topicSentimentComparisonList.map(data => data['Color']);

  let topicOverviewTopicSentimentComparisonDataSet = {
    type: 'doughnut',
    labels: topicOverviewTopicSentimentComparisonLabel,
    datasets: [
      {
        data: topicOverviewTopicSentimentComparisonCount ,
        backgroundColor: topicOverviewTopicSentimentComparisonColor
      }
    ],
    text: ''

  }

  let topicOverviewTopicSentimentComparisonOption = {
    

    // Drill Down
    onClick: (event, elements) => {



      if(elements.length > 0){ // undefine cause app to crash
        const chart = elements[0]._chart;
        const element = chart.getElementAtEvent(event)[0];
        const dataset = chart.data.datasets[element._datasetIndex];
        const xLabel = chart.data.labels[element._index];
        const value = dataset.data[element._index];
        console.log(dataset.label + " at " + xLabel + ": " + value);

      }

    }


  }




  const topicOverviewTopicSentimentComparisonTableDataSet = topicSentimentComparisonList.map((TopicSentimentComparisonList) => {
    return ({
      id: TopicSentimentComparisonList.id,
      sentiment: TopicSentimentComparisonList['Sentiment'],
      count: TopicSentimentComparisonList['Count']
    })
  });

  const topicOverviewTopicSentimentComparisonTableColumn = [
  {
    name: 'Sentiment',
    selector: 'sentiment',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
    center: true
  }
  ];


  // Topic Overview - Topic Comparison List - Stack Bar Chart

let topicOverviewTopicComparisonTopic = topicComparisonList.map(data => data['Topic']);
let topicOverviewTopicComparisonPositive = topicComparisonList.map(data => data['Positive']);
let topicOverviewTopicComparisonNegative = topicComparisonList.map(data => data['Negative']);

let topicOverviewTopicComparisonDataSet = {
  type: 'bar',
  labels: topicOverviewTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: topicOverviewTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicOverviewTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicOverviewTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Overview - Topic Comparison List Table using react-data-table-component

const topicOverviewTopicComparisonTableDataSet = topicComparisonListAll.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
const topicOverviewTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



function topicOverviewTopicComparisonReactTable() {
  return <DataTable
  title=""
  columns={topicOverviewTopicComparisonTableColumn}
  data={topicOverviewTopicComparisonTableDataSet}
  //conditionalRowStyles={conditionalRowStyles}
  // pagination={true}
  // paginationPerPage={10}
  // paginationComponentOptions={{ noRowsPerPage: true }}
  fixedHeader={true}
  //fixedHeaderScrollHeight={"445px"}
  fixedHeaderScrollHeight={"445px"}
  noHeader={true}
  />
}


// Topic Overview - Positive Wordcloud

const topicOverviewPositiveTopicWords = topicOverviewPositiveTopic.map((TopicOverviewPositiveTopic) => {
  return({
    text: TopicOverviewPositiveTopic['WORD'],
    value: TopicOverviewPositiveTopic['FREQ']
  })
})

const topicOverviewPositiveTopicCallbacks = {
  onWordClick: (word) => {
    //console.log(word.text);

  }, 



  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicOverviewPositiveTopicOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicOverViewPositiveWordCloud() {
  return <ReactWordcloud
    callbacks={topicOverviewPositiveTopicCallbacks}
    options={topicOverviewPositiveTopicOptions}
    words={topicOverviewPositiveTopicWords} 
  />
}


// Topic Overview - Positive Table using react-data-table-component

const topicOverviewPositiveTopicTableDataSet = 
topicOverviewPositiveTopic.map((TopicOverviewPositiveTopic) => {
    return ({
      id: TopicOverviewPositiveTopic.id,
      topic: TopicOverviewPositiveTopic['WORD'],
      freq: TopicOverviewPositiveTopic['FREQ']
    })
  });
  
const topicOverviewPositiveTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Overview - Negative Wordcloud

const topicOverviewNegativeTopicWords = topicOverviewNegativeTopic.map((TopicOverviewNegativeTopic) => {
  return({
    text: TopicOverviewNegativeTopic['WORD'],
    value: TopicOverviewNegativeTopic['FREQ']
  })
})

const topicOverviewNegativeTopicCallbacks = {
  onWordClick: (word) => {
    //console.log(word.text);

  }, 

  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const topicOverviewNegativeTopicOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function topicOverViewNegativeWordCloud() {
  return <ReactWordcloud
    callbacks={topicOverviewNegativeTopicCallbacks}
    options={topicOverviewNegativeTopicOptions}
    words={topicOverviewNegativeTopicWords} 
  />
}


// Topic Overview - Negative Table using react-data-table-component

const topicOverviewNegativeTopicTableDataSet = 
topicOverviewNegativeTopic.map((TopicOverviewNegativeTopic) => {
    return ({
      id: TopicOverviewNegativeTopic.id,
      topic: TopicOverviewNegativeTopic['WORD'],
      freq: TopicOverviewNegativeTopic['FREQ']
    })
  });
  
const topicOverviewNegativeTopicTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



const onClickTopicInsight = () => {
  const axios = require('axios');

  // Topic Insight Data
  setTopicInsightIsLoading(true);
  let restGETurl0 = `${"brand="}${brandSelection}${"&category="}${categorySelection}${"&model="}${modelSelection}${"&website="}${websiteSelection}${"&country="}${countrySelection}${"&startDate="}${dateStartSelection}${"&endDate="}${dateEndSelection}${"&uniqueTopic="}${uniqueTopic}${"&topicInsightSelection="}${topicInsightSelection}`;
  restGETurl0 = restGETurl0.replaceAll(",", '%2C'); // replace comma for url
  restGETurl0 = restGETurl0.replaceAll(" ", '%20'); // empty space for url

  axios.get(`${SMATrestAPI.audioRatingAnalytic[0].topicInsight}${restGETurl0}`).then(function (response) { 
    // handle success

    setTopicInsightTopicPhraseData(response.data.topicPhrase);
    setTopicInsightPositiveSentimentData(response.data.topicSentimentPos);
    setTopicInsightNegativeSentimentData(response.data.topicSentimentNeg);
    setTopicInsightTopicTrendData(response.data.topicTrend);
    
  
    
  }).catch(function (error) { 
    // handle error
    console.log(error);
  }).then(function () { 
    // always executed
    setTopicInsightIsLoading(false);
  });

}



// Topic Insight - Topic Phrase - Stack Bar Chart

let topicInsightTopicPhraseLabel = topicInsightTopicPhraseData.map(data => data['Phrase']);
let topicInsightTopicPhrasePositive = topicInsightTopicPhraseData.map(data => data['Positive']);
let topicInsightTopicPhraseNeutral = topicInsightTopicPhraseData.map(data => data['Neutral']);
let topicInsightTopicPhraseNegative = topicInsightTopicPhraseData.map(data => data['Negative']);

let topicInsightPhraseDataSet = {
  type: 'bar',
  labels: topicInsightTopicPhraseLabel,
  datasets: [
    {
      label: 'Positive',
      data: topicInsightTopicPhrasePositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Neutral',
      data: topicInsightTopicPhraseNeutral,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },

    {
      label: 'Negative',
      data: topicInsightTopicPhraseNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let topicInsightPhraseOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);



    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Insight Phrase Table using react-data-table-component

const topicInsightPhraseTableDataSet = topicInsightTopicPhraseData.map((TopicInsightTopicPhraseData) => {
    return ({
      id: TopicInsightTopicPhraseData.id,
      topic: TopicInsightTopicPhraseData['Phrase'],
      positive: TopicInsightTopicPhraseData['Positive'],
      negative: TopicInsightTopicPhraseData['Negative'],
      neutral: TopicInsightTopicPhraseData['Neutral']
    })
  });
  
const topicInsightPhraseTableColumn = [
  {
    name: 'Topic Phrase',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  }
];



// Topic Insight - Topic Sentiment Positive - Bar Chart

let topicInsightPositiveLabel = topicInsightPositiveSentimentData.map(data => data['WORD']);
let topicInsightPositiveFreq = topicInsightPositiveSentimentData.map(data => data['FREQ']);

let topicInsightPositiveDataSet = {
  type: 'bar',
  labels: topicInsightPositiveLabel,
  datasets: [
    {
      label: "Positive Words",
      data: topicInsightPositiveFreq ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)'
    }


  ]

}

let topicInsightPositiveOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);



    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Sentiment Positive Table using react-data-table-component

const topicInsightPositiveTableDataSet = topicInsightPositiveSentimentData.map((TopicInsightPositiveSentimentData) => {
    return ({
      id: TopicInsightPositiveSentimentData.id,
      word: TopicInsightPositiveSentimentData['WORD'],
      freq: TopicInsightPositiveSentimentData['FREQ']
    })
  });
  
const topicInsightPositiveTableColumn = [
  {
    name: 'Positive Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Topic Insight - Topic Negative Positive - Bar Chart

let topicInsightNegativeLabel = topicInsightNegativeSentimentData.map(data => data['WORD']);
let topicInsightNegativeFreq = topicInsightNegativeSentimentData.map(data => data['FREQ']);

let topicInsightNegativeDataSet = {
  type: 'bar',
  labels: topicInsightNegativeLabel,
  datasets: [
    {
      label: "Negative Words",
      data: topicInsightNegativeFreq ,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)'
    }


  ]

}

let topicInsightNegativeOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },


  scales: {
    xAxes: [{
        stacked: false
    }],
    yAxes: [{
        stacked: false
    }]
}


}

// yAxes: [
//   {
//     stacked: true,
//     ticks: {
//       fontSize: 12,
//       min: 0,
//       max: 100,
//       callback: function(value) {
//         return value + "%"
//        }
//     }
//   }
// ],

// Topic Insight - Topic Sentiment Negative Table using react-data-table-component

const topicInsightNegativeTableDataSet = topicInsightNegativeSentimentData.map((TopicInsightNegativeSentimentData) => {
    return ({
      id: TopicInsightNegativeSentimentData.id,
      word: TopicInsightNegativeSentimentData['WORD'],
      freq: TopicInsightNegativeSentimentData['FREQ']
    })
  });
  
const topicInsightNegativeTableColumn = [
  {
    name: 'Negative Sentiment',
    selector: 'word',
    sortable: true,
    center: true
  },
  {
    name: 'Count',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Topic Insight - Topic Trend - Line Chart

let topicInsightTopicTrendLabel = topicInsightTopicTrendData.map(data => data.Date);

let topicInsightTopicTrendDataSet = {
  type: 'line',
  labels: topicInsightTopicTrendLabel,
  datasets: [
    {
      label: 'Positive',
      data: topicInsightTopicTrendData.map(data => data['Positive Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: topicInsightTopicTrendData.map(data => data['Negative Cumulative Percent']),
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'topicTrendStack',
      yAxisID: 'A'
    },

    {
      label: 'Cumulative Total',
      data: topicInsightTopicTrendData.map(data => data['Cumulative Total']),
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }



  ]

}


let topicInsightTopicTrendOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);

    }

  },



  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  }


}

// Topic Insight - Topic Trend - Table using react-data-table-component

const topicInsightTopicTrendTableDataSet = topicInsightTopicTrendData.map((TopicInsightTopicTrendData) => {
    return ({
      id: TopicInsightTopicTrendData.id,
      date: TopicInsightTopicTrendData.Date,
      topic: TopicInsightTopicTrendData.Topic,
      positiveTrend: TopicInsightTopicTrendData['Positive'],
      neutralTrend: TopicInsightTopicTrendData['Neutral'],
      negativeTrend: TopicInsightTopicTrendData['Negative'],
      total: TopicInsightTopicTrendData['Total'],
      posCumulative: TopicInsightTopicTrendData['Positive Cumulative'],
      negCumulative: TopicInsightTopicTrendData['Negative Cumulative'],
      totalCumulative: TopicInsightTopicTrendData['Cumulative Total'],
      posCumulativePercent: TopicInsightTopicTrendData['Positive Cumulative Percent'],
      negCumulativePercent: TopicInsightTopicTrendData['Negative Cumulative Percent']
    })
  });
  
const topicInsightTopicTrendTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positiveTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutralTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negativeTrend',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'posCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'totalCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative Percent',
    selector: 'posCumulativePercent',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative Percent',
    selector: 'negCumulativePercent',
    sortable: true,
    center: true
  }
];


const handleTopicInsight = event => {
  setTopicInsightSelection(event.target.value);
};


// Sentiment Overview - Positive Wordcloud

const sentimentOverviewPositveWordsData = sentimentOverviewPositveData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const sentimentOverviewPositveCallbacks = {
  onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const sentimentOverviewPositveOptions = {
  colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function sentimentOverviewPositveWordCloud() {
  return <ReactWordcloud
    callbacks={sentimentOverviewPositveCallbacks}
    options={sentimentOverviewPositveOptions}
    words={sentimentOverviewPositveWordsData} 
  />
}


// Sentiment Overview - Positive Table using react-data-table-component

const sentimentOverviewPositveTableDataSet = sentimentOverviewPositveData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const sentimentOverviewPositveTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];


// Sentiment Overview - Negative Wordcloud

const sentimentOverviewNegativeDataWords = sentimentOverviewNegativeData.map((data) => {
  return({
    text: data['WORD'],
    value: data['FREQ']
  })
})

const sentimentOverviewNegativeDataCallbacks = {
  onWordClick: console.log, // TODO: Add function for drill down
  getWordTooltip: (word) =>
    `The word "${word.text}" appears ${word.value} times.`,
}

const sentimentOverviewNegativeDataOptions = {
  //colors: ["#228B22", "#006400", "#3CB371", "#20B2AA", "#2E8B57", "#556B2F", "#6B8E23"],
  colors: ["#8B0000", "#FF4500", "#DC143C", "#FF0000", "#800000", "#DB7093", "#FA8072"],
  enableTooltip: true,
  deterministic: true,
  fontFamily: "impact",
  fontSizes: [20, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 0,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000
};

function sentimentOverviewNegativeWordCloud() {
  return <ReactWordcloud
    callbacks={sentimentOverviewNegativeDataCallbacks}
    options={sentimentOverviewNegativeDataOptions}
    words={sentimentOverviewNegativeDataWords} 
  />
}


// Sentiment Overview - Negative Table using react-data-table-component

const sentimentOverviewNegativeDataTableDataSet = sentimentOverviewNegativeData.map((data) => {
    return ({
      id: data.id,
      topic: data['WORD'],
      freq: data['FREQ']
    })
  });
  
const sentimentOverviewNegativeDataTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Frequency',
    selector: 'freq',
    sortable: true,
    center: true
  }
];



// Bass Summary -  Trend - Combo Chart

let categorySummaryBassDate = categoryBass.map(data => data.Date);
let categorySummaryBassPositiveCumPercent = categoryBass.map(data => data['Positive Cumulative Percent']);
let categorySummaryBassNegativeCumPercent = categoryBass.map(data => data['Negative Cumulative Percent']);
let categorySummaryBassTotalCumPercent = categoryBass.map(data => data['Cumulative Total']);

let categorySummaryBassDataSet = {
  type: 'line',
  labels: categorySummaryBassDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryBassPositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryBassNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryBassTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



let categorySummaryBassOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Category Detail -  Table using react-data-table-component

let categorySummaryBassTableDataSet = categoryBass.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positiveCumulative: data['Positive Cumulative'],
      negativeCumulative: data['Negative Cumulative'],
      neutralCumulative: data['Neutral Cumulative'],
      cumulativeTotal: data['Cumulative Total']
    })
  });
  
  let categorySummaryBassTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Category Detail - Software Topic Comparison List - Stack Bar Chart

let categoryDetailBassTopicComparisonTopic = bassTopicList.map(data => data['Topic']);
let categoryDetailBassTopicComparisonPositive = bassTopicList.map(data => data['Positive']);
let categoryDetailBassTopicComparisonNeutral = bassTopicList.map(data => data['Neutral']);
let categoryDetailBassTopicComparisonNegative = bassTopicList.map(data => data['Negative']);

let categoryDetailBassTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailBassTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailBassTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailBassTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailBassTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailBassTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail -  Topic Comparison List Table using react-data-table-component

let categoryDetailBassTopicComparisonTableDataSet = bassTopicList.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
  let categoryDetailBassTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];


// Midrange Summary -  Trend - Combo Chart

let categorySummaryMidrangeDate = categoryMidrange.map(data => data.Date);
let categorySummaryMidrangePositiveCumPercent = categoryMidrange.map(data => data['Positive Cumulative Percent']);
let categorySummaryMidrangeNegativeCumPercent = categoryMidrange.map(data => data['Negative Cumulative Percent']);
let categorySummaryMidrangeTotalCumPercent = categoryMidrange.map(data => data['Cumulative Total']);

let categorySummaryMidrangeDataSet = {
  type: 'line',
  labels: categorySummaryMidrangeDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryMidrangePositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryMidrangeNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryMidrangeTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



let categorySummaryMidrangeOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Midrange Summary -  Table using react-data-table-component

let categorySummaryMidrangeTableDataSet = categoryMidrange.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positiveCumulative: data['Positive Cumulative'],
      negativeCumulative: data['Negative Cumulative'],
      neutralCumulative: data['Neutral Cumulative'],
      cumulativeTotal: data['Cumulative Total']
    })
  });
  
  let categorySummaryMidrangeTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Midrange Summary - Software Topic Comparison List - Stack Bar Chart

let categoryDetailMidrangeTopicComparisonTopic = midrangeTopicList.map(data => data['Topic']);
let categoryDetailMidrangeTopicComparisonPositive = midrangeTopicList.map(data => data['Positive']);
let categoryDetailMidrangeTopicComparisonNeutral = midrangeTopicList.map(data => data['Neutral']);
let categoryDetailMidrangeTopicComparisonNegative = midrangeTopicList.map(data => data['Negative']);

let categoryDetailMidrangeTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailMidrangeTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailMidrangeTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailMidrangeTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailMidrangeTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailMidrangeTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail -  Topic Comparison List Table using react-data-table-component

let categoryDetailMidrangeTopicComparisonTableDataSet = midrangeTopicList.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
  let categoryDetailMidrangeTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];


// Treble Summary -  Trend - Combo Chart

let categorySummaryTrebleDate = categoryTreble.map(data => data.Date);
let categorySummaryTreblePositiveCumPercent = categoryTreble.map(data => data['Positive Cumulative Percent']);
let categorySummaryTrebleNegativeCumPercent = categoryTreble.map(data => data['Negative Cumulative Percent']);
let categorySummaryTrebleTotalCumPercent = categoryTreble.map(data => data['Cumulative Total']);

let categorySummaryTrebleDataSet = {
  type: 'line',
  labels: categorySummaryTrebleDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryTreblePositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryTrebleNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryTrebleTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



let categorySummaryTrebleOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Treble Summary -  Table using react-data-table-component

let categorySummaryTrebleTableDataSet = categoryTreble.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positiveCumulative: data['Positive Cumulative'],
      negativeCumulative: data['Negative Cumulative'],
      neutralCumulative: data['Neutral Cumulative'],
      cumulativeTotal: data['Cumulative Total']
    })
  });
  
  let categorySummaryTrebleTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Treble Summary - Software Topic Comparison List - Stack Bar Chart

let categoryDetailTrebleTopicComparisonTopic = trebleTopicList.map(data => data['Topic']);
let categoryDetailTrebleTopicComparisonPositive = trebleTopicList.map(data => data['Positive']);
let categoryDetailTrebleTopicComparisonNeutral = trebleTopicList.map(data => data['Neutral']);
let categoryDetailTrebleTopicComparisonNegative = trebleTopicList.map(data => data['Negative']);

let categoryDetailTrebleTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailTrebleTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailTrebleTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailTrebleTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailTrebleTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailTrebleTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail -  Topic Comparison List Table using react-data-table-component

let categoryDetailTrebleTopicComparisonTableDataSet = trebleTopicList.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
  let categoryDetailTrebleTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];



// SoundStage Summary -  Trend - Combo Chart

let categorySummarySoundStageDate = categorySoundStage.map(data => data.Date);
let categorySummarySoundStagePositiveCumPercent = categorySoundStage.map(data => data['Positive Cumulative Percent']);
let categorySummarySoundStageNegativeCumPercent = categorySoundStage.map(data => data['Negative Cumulative Percent']);
let categorySummarySoundStageTotalCumPercent = categorySoundStage.map(data => data['Cumulative Total']);

let categorySummarySoundStageDataSet = {
  type: 'line',
  labels: categorySummarySoundStageDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummarySoundStagePositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummarySoundStageNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummarySoundStageTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



let categorySummarySoundStageOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// SoundStage Summary -  Table using react-data-table-component

let categorySummarySoundStageTableDataSet = categorySoundStage.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positiveCumulative: data['Positive Cumulative'],
      negativeCumulative: data['Negative Cumulative'],
      neutralCumulative: data['Neutral Cumulative'],
      cumulativeTotal: data['Cumulative Total']
    })
  });
  
  let categorySummarySoundStageTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// SoundStage Summary - Software Topic Comparison List - Stack Bar Chart

let categoryDetailSoundStageTopicComparisonTopic = soundStageTopicList.map(data => data['Topic']);
let categoryDetailSoundStageTopicComparisonPositive = soundStageTopicList.map(data => data['Positive']);
let categoryDetailSoundStageTopicComparisonNeutral = soundStageTopicList.map(data => data['Neutral']);
let categoryDetailSoundStageTopicComparisonNegative = soundStageTopicList.map(data => data['Negative']);

let categoryDetailSoundStageTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailSoundStageTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailSoundStageTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailSoundStageTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailSoundStageTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailSoundStageTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail -  Topic Comparison List Table using react-data-table-component

let categoryDetailSoundStageTopicComparisonTableDataSet = soundStageTopicList.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
  let categoryDetailSoundStageTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];


// Imaging Summary -  Trend - Combo Chart

let categorySummaryImagingDate = categoryImaging.map(data => data.Date);
let categorySummaryImagingPositiveCumPercent = categoryImaging.map(data => data['Positive Cumulative Percent']);
let categorySummaryImagingNegativeCumPercent = categoryImaging.map(data => data['Negative Cumulative Percent']);
let categorySummaryImagingTotalCumPercent = categoryImaging.map(data => data['Cumulative Total']);

let categorySummaryImagingDataSet = {
  type: 'line',
  labels: categorySummaryImagingDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryImagingPositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryImagingNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryImagingTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



let categorySummaryImagingOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Imaging Summary -  Table using react-data-table-component

let categorySummaryImagingTableDataSet = categoryImaging.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positiveCumulative: data['Positive Cumulative'],
      negativeCumulative: data['Negative Cumulative'],
      neutralCumulative: data['Neutral Cumulative'],
      cumulativeTotal: data['Cumulative Total']
    })
  });
  
  let categorySummaryImagingTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Imaging Summary - Software Topic Comparison List - Stack Bar Chart

let categoryDetailImagingTopicComparisonTopic = imagingTopicList.map(data => data['Topic']);
let categoryDetailImagingTopicComparisonPositive = imagingTopicList.map(data => data['Positive']);
let categoryDetailImagingTopicComparisonNeutral = imagingTopicList.map(data => data['Neutral']);
let categoryDetailImagingTopicComparisonNegative = imagingTopicList.map(data => data['Negative']);

let categoryDetailImagingTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailImagingTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailImagingTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailImagingTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailImagingTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailImagingTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail -  Topic Comparison List Table using react-data-table-component

let categoryDetailImagingTopicComparisonTableDataSet = imagingTopicList.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
  let categoryDetailImagingTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];


// Distortion Summary -  Trend - Combo Chart

let categorySummaryDistortionDate = categoryDistortion.map(data => data.Date);
let categorySummaryDistortionPositiveCumPercent = categoryDistortion.map(data => data['Positive Cumulative Percent']);
let categorySummaryDistortionNegativeCumPercent = categoryDistortion.map(data => data['Negative Cumulative Percent']);
let categorySummaryDistortionTotalCumPercent = categoryDistortion.map(data => data['Cumulative Total']);

let categorySummaryDistortionDataSet = {
  type: 'line',
  labels: categorySummaryDistortionDate,
  datasets: [
    {
      label: 'Positive',
      data: categorySummaryDistortionPositiveCumPercent ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      yAxisID: 'A'
    },

    {
      label: 'Negative',
      data: categorySummaryDistortionNegativeCumPercent,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      yAxisID: 'A'
    },
    {
      label: 'Cumulative Total',
      data: categorySummaryDistortionTotalCumPercent,
      fill: false,
      backgroundColor: 'rgb(129, 130, 133)',
      borderColor: 'rgba(129, 130, 133, 0.6)',
      type: 'bar',
      yAxisID: 'B'
    }


  ]

}



let categorySummaryDistortionOptions = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      //console.log(dataset.label + " at " + xLabel + ": " + value);


    }

  },

  spanGaps: true,
  scales: {
    yAxes: [
      {
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%"
           }
        }
      },
      {
        id: 'B',
        type: 'linear',
        position: 'right'
      }
    ],

    xAxes: [{
      type: 'time',
      time: {
          unit: 'month'
      }
  }]
  },
}

// Distortion Summary -  Table using react-data-table-component

let categorySummaryDistortionTableDataSet = categoryDistortion.map((data) => {
    return ({
      id: data.id,
      date: data.Date,
      positiveCumulative: data['Positive Cumulative'],
      negativeCumulative: data['Negative Cumulative'],
      neutralCumulative: data['Neutral Cumulative'],
      cumulativeTotal: data['Cumulative Total']
    })
  });
  
  let categorySummaryDistortionTableColumn = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    center: true
  },
  {
    name: 'Positive Cumulative',
    selector: 'positiveCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Negative Cumulative',
    selector: 'negativeCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral Cumulative',
    selector: 'neutralCumulative',
    sortable: true,
    center: true
  },
  {
    name: 'Cumulative Total',
    selector: 'cumulativeTotal',
    sortable: true,
    center: true
  }
];



// Distortion Summary - Software Topic Comparison List - Stack Bar Chart

let categoryDetailDistortionTopicComparisonTopic = distortionTopicList.map(data => data['Topic']);
let categoryDetailDistortionTopicComparisonPositive = distortionTopicList.map(data => data['Positive']);
let categoryDetailDistortionTopicComparisonNeutral = distortionTopicList.map(data => data['Neutral']);
let categoryDetailDistortionTopicComparisonNegative = distortionTopicList.map(data => data['Negative']);

let categoryDetailDistortionTopicComparisonDataSet = {
  type: 'bar',
  labels: categoryDetailDistortionTopicComparisonTopic,
  datasets: [
    {
      label: 'Positive',
      data: categoryDetailDistortionTopicComparisonPositive ,
      fill: false,
      backgroundColor: 'rgb(26, 115, 55)',
      borderColor: 'rgba(26, 115, 55, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Neutral',
      data: categoryDetailDistortionTopicComparisonNeutral ,
      fill: false,
      backgroundColor: 'rgb(245, 200, 15)',
      borderColor: 'rgba(245, 200, 15, 0.6)',
      stack: 'stack1'
    },
    {
      label: 'Negative',
      data: categoryDetailDistortionTopicComparisonNegative,
      fill: false,
      backgroundColor: 'rgb(163, 30, 30)',
      borderColor: 'rgba(163, 30, 30, 0.6)',
      stack: 'stack1'
    }


  ]

}

let categoryDetailDistortionTopicComparisonOption = {

  // Drill Down
  onClick: (event, elements) => {

    if(elements.length > 0){ // undefine cause app to crash
      const chart = elements[0]._chart;
      const element = chart.getElementAtEvent(event)[0];
      const dataset = chart.data.datasets[element._datasetIndex];
      const xLabel = chart.data.labels[element._index];
      const value = dataset.data[element._index];
      console.log(dataset.label + " at " + xLabel + ": " + value);
    }

  },


  scales: {
    xAxes: [{
        stacked: true
    }],
    yAxes: [{
        stacked: true
    }]
}


}


// Category Detail -  Topic Comparison List Table using react-data-table-component

let categoryDetailDistortionTopicComparisonTableDataSet = distortionTopicList.map((data) => {
    return ({
      id: data.id,
      topic: data['Topic'],
      positive: data['Positive'],
      negative: data['Negative'],
      neutral: data['Neutral'],
      total: data['Total']
    })
  });
  
  let categoryDetailDistortionTopicComparisonTableColumn = [
  {
    name: 'Topic',
    selector: 'topic',
    sortable: true,
    center: true
  },
  {
    name: 'Positive',
    selector: 'positive',
    sortable: true,
    center: true
  },
  {
    name: 'Negative',
    selector: 'negative',
    sortable: true,
    center: true
  },
  {
    name: 'Neutral',
    selector: 'neutral',
    sortable: true,
    center: true
  },
  {
    name: 'Total',
    selector: 'total',
    sortable: true,
    center: true
  }
];






// Category Detail -  - View Individual Sentence - Table using material-table

const viewIndividualSentenceTableColumn = [
  {
    title: 'Date',
    field: 'date'
  },
  {
    title: 'Model',
    field: 'model'
  },
  {
    title: 'Website',
    field: 'website'
  },
  {
    title: 'Country',
    field: 'country'
  },
  {
    title: 'Category',
    field: 'category'
  },
  {
    title: 'Topic',
    field: 'topic'
  },
  {
    title: 'Stars',
    field: 'stars'
  },
  {
    title: 'Individual Sentence',
    field: 'individualSentence',
    cellStyle: {
      width: 300,
      minWidth: 300
    },
    headerStyle: {
      width: 300,
      minWidth: 300
    }
  },
  {
    title: 'Sentiment',
    field: 'sentimentResult'
  },
  {
    title: 'Pos',
    field: 'pos'
  },
  {
    title: 'Neg',
    field: 'neg'
  }
];



const viewIndividualSentenceDataTableDataSet = viewIndividualSentenceData.map((ViewIndividualSentenceData) => {
  return ({
    id: ViewIndividualSentenceData.id,
    date: ViewIndividualSentenceData.Date,
    model: ViewIndividualSentenceData['Model'],
    website: ViewIndividualSentenceData.Website,
    country: ViewIndividualSentenceData['Country'],
    category: ViewIndividualSentenceData['Category'],
    topic: ViewIndividualSentenceData['Topic'],
    stars: ViewIndividualSentenceData['Stars'],
    topic: ViewIndividualSentenceData['Topic'],
    individualSentence: ViewIndividualSentenceData['Individual.Sentence'],
    sentimentResult: ViewIndividualSentenceData['Sentiment.Result'],
    pos: ViewIndividualSentenceData['Pos_List'],
    neg: ViewIndividualSentenceData['Neg_List']
  })
});





  
  const classes = useStyles();
  const dashboardClasses = useStylesDashboard();
  const modalClasses = useModalDashboard();
  const drillDownLoadingClasses = drillDownLoading();

  return (
    (!viewIndividualSentenceIsLoading ?
    <div>

    <div id="pageLoader" className={loadPage1}></div>

    <div id="mainPage" style={{display:loadPage2}}>

       <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Select a Product</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BrandIcon />
                
                
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Brand</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="brand-select"
                  className={
                    classes.selectLabel
                }>
                  Select Brand
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={brandSelection}
                  onChange={handleBrand}
                  inputProps={
                    {
                      name: "brandSelection",
                      id: "brand-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  brandItems.map((brandItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }
                      }
                      value={brandItems}>
                      {brandItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>


        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CategoryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Category</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="category-select"
                  className={
                    classes.selectLabel
                }>
                  Select Category
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu}
                  }
                  classes={
                    {select: classes.select}
                  }
                  //multiple
                  autoWidth={false}
                  value={categorySelection}
                  onChange={handleCategory}
                  inputProps={
                    {
                      name: "categorySelection",
                      id: "category-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Single Selection</b>
                  </MenuItem>

                  {
                  categoryItems.map((categoryItems, index) => {
                    return (
                      <MenuItem key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={categoryItems}>
                        {categoryItems} </MenuItem>
                    );
                  })
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <ModelIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Model</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="model-select"
                  className={
                    classes.selectLabel
                }>
                  Select Model
                </InputLabel>

                <Select MenuProps={
                    {className: classes.selectMenu, classes: { paper: classes.selectPaper }}
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={modelSelection}
                  onChange={handleModel}
                  inputProps={
                    {
                      name: "modelSelection",
                      id: "model-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  modelItems.map((modelItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={modelItems}>
                      {modelItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <WebsiteIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Website</b>
              </h4>
            </CardHeader>
            <CardBody>

              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="website-select"
                  className={
                    classes.selectLabel
                }>
                  Select Website
                </InputLabel>

                <Select 
                  MenuProps={{
                    className: classes.selectMenu,
                    classes: { paper: classes.selectPaper }
                  }}
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={websiteSelection}
                  onChange={handleWebsite}
                  inputProps={
                    {
                      name: "websiteSelection",
                      id: "website-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  websiteItems.map((websiteItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={websiteItems}>
                      {websiteItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>


        <GridContainer>
        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <CountryIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Country</b>
              </h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth
                className={
                  classes.selectFormControl
              }>
                <InputLabel htmlFor="country-select"
                  className={
                    classes.selectLabel
                }>
                  Select Country
                </InputLabel>

                <Select style={
                    {minWidth: "100%"}
                  }
                  MenuProps={
                    {
                      className: classes.selectMenu,
                      classes: { paper: classes.selectPaper }
                    }
                  }
                  classes={
                    {select: classes.select}
                  }
                  multiple
                  autoWidth={false}
                  value={countrySelection}
                  onChange={handleCountry}
                  inputProps={
                    {
                      name: "countrySelection",
                      id: "country-select"
                    }
                }>
                  <MenuItem disabled
                    classes={
                      {root: classes.selectMenuItem}
                  }>
                    <b>Multiple Selection</b>
                  </MenuItem>

                  {
                  countryItems.map((countryItems, index) => (
                    <MenuItem key={index}
                      classes={
                        {
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }
                      }
                      value={countryItems}>
                      {countryItems} </MenuItem>
                  ))
                } </Select>
              </FormControl>
            </CardBody>
          </Card>

        </GridItem>

        <GridItem xs={12}
          sm={12}
          md={3}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FilterIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Unique Topic</b>
              </h4>
            </CardHeader>
            <CardBody>
              <div className={classes.block} style={{height:"75px"}}>
                      <br/>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={uniqueTopic}
                            onChange={event => setUniqueTopic(event.target.checked)}
                            value="uniqueTopic"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={uniqueTopic === true ? "ON" : "OFF (Recommended)"}
                      />
                    </div>
            </CardBody>
          </Card>

          </GridItem>


        <GridItem xs={12}
          sm={12}
          md={6}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DateIcon/>
              </CardIcon>
              <h4 className={
                classes.cardIconTitle
              }>
                <b>Date</b>
              </h4>
            </CardHeader>
            <CardBody>

            <GridContainer style={{height: "75px"}}>
              <GridItem md={6}>
                <br/>
                <Datetime 

                    timeFormat={false}
                    inputProps={{ placeholder: "Select Start Date" }}
                    value={moment(dateStartSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateStartItem, 'YYYY-MM-DD')}
                    onChange={handleDateStart}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect
                    isValidDate={ validStart } 
                  />
              </GridItem>

              <GridItem md={6}>
                <br/>
                <Datetime 
                    timeFormat={false}
                    inputProps={{ placeholder: "Select End Date" }}
                    value={moment(dateEndSelection, 'YYYY-MM-DD')}
                    defaultValue ={moment(dateEndItem, 'YYYY-MM-DD')}
                    onChange={handleDateEnd}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect 
                    isValidDate={ validEnd } 

                  />
              </GridItem>

            </GridContainer >
                      
            </CardBody>
          </Card>

        </GridItem>

        </GridContainer>



        <GridContainer >

          <GridItem 
              xs={12}
              container
              direction="row"
              justify="flex-end"
              alignItems="center">

            <Button color="pinterest" onClick={onClickChartData}>
              <RefreshIcon className={classes.icons} />
                Display Chart
            </Button>
            

          </GridItem>

        </GridContainer>

      <br/>


{/* Audio Analysis Overview */}
        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Category Overview</b> </Button>
          </GridItem>

        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={getColor("Bass")} stats icon>
                <CardIcon color={getColor("Bass")}>
                  {getIcon("Bass")}
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Bass</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {getRating("Bass")}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={getColor("Midrange")} stats icon>
                <CardIcon color={getColor("Midrange")}>
                  {getIcon("Midrange")}
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Midrange</p>
                <h3 className={dashboardClasses.cardTitle}>
                {getRating("Midrange")}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={getColor("Treble")} stats icon>
                <CardIcon color={getColor("Treble")}>
                  {getIcon("Treble")}
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Treble</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {getRating("Treble")}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={getColor("Sound Stage")} stats icon>
                <CardIcon color={getColor("Sound Stage")}>
                  {getIcon("Sound Stage")}
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Sound Stage</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {getRating("Sound Stage")}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={getColor("Imaging")} stats icon>
                <CardIcon color={getColor("Imaging")}>
                  {getIcon("Imaging")}
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Imaging</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {getRating("Imaging")}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>
 
                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color={getColor("Noise")} stats icon>
                <CardIcon color={getColor("Noise")}>
                  {getIcon("Noise")}
                </CardIcon>
                <p className={dashboardClasses.cardCategory}>Noise</p>
                <h3 className={dashboardClasses.cardTitle}>
                  {getRating("Noise")}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>
          
      </GridContainer>

{/* Category Summary Radar Chart and Bar Chart */}
      <GridContainer>

      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            
            tabs={[
              {
                tabName: "Sentiment by Category",
                tabIcon: RadarChartIcon,
                tabContent: (!audioChartUIsLoading ? 
                  <div>
                    <Radar 
                      height={215} 
                      data={categorySummaryRadarDataSet}  
                      options={categorySummaryRadarOption} 
                      /> 
                  </div>
                

                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!audioChartUIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={categorySummaryRadarTableColumn}
                        data={categorySummaryRadarTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink 
                      data={categorySummaryData} 
                      filename={"Sentiment by Category.csv"}
                    >
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

      </GridItem>

      <GridItem xs={12} sm={12} md={6} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            
            tabs={[
              {
                tabName: "Star Rating by Category",
                tabIcon: BarChartIcon,
                tabContent: (!audioChartUIsLoading ? 
                  <div>
                    <Bar 
                      height={215} 
                      data={categorySummaryBarDataSet} 
                      options={categorySummaryBarOption} 
                      /> 

                  </div>
                

                : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
              },
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (!audioChartUIsLoading ? 
                    <div>
                      <DataTable
                        title=""
                        columns={categorySummaryBarTableColumn}
                        data={categorySummaryBarTableDataSet}
                        //conditionalRowStyles={conditionalRowStyles}
                        // pagination={true}
                        // paginationPerPage={10}
                        // paginationComponentOptions={{ noRowsPerPage: true }}
                        fixedHeader={true}
                        //fixedHeaderScrollHeight={"445px"}
                        fixedHeaderScrollHeight={"300px"}
                        noHeader={true}
                      /> 
                  <GridContainer>
                  <GridItem 
                    xs={12}
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center">
                
                    <CSVLink 
                      data={categorySummaryData} 
                      filename={"Star Rating by Category.csv"}
                      >
                      <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                        <DownloadIcon className={classes.icons} />
                          Download CSV 
                      </Button>
                    </CSVLink>

                  </GridItem>

                </GridContainer>

                    </div>
                  : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                
              }
            ]}
          /> 

      </GridItem>


      </GridContainer>


{/* Topic Overview - Pie  */}

      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Overview</b> </Button>
        </GridItem>

        </GridContainer>

        <GridContainer>

          <GridItem xs={12} sm={12} md={6}>

          <CustomTabs
              //title="Tasks:"
              centered={true}
              headerColor="primary"

              tabs={[

                {
                  tabName: "Topic Sentiment Comparison",
                  tabIcon: CompareIcon,
                  tabContent: (!audioChartUIsLoading ? 
                    <div> 
                      {/* style={{ height: 300}} */}
                      <Doughnut 
                        height={200} data={topicOverviewTopicSentimentComparisonDataSet} 
                        options={topicOverviewTopicSentimentComparisonOption} 
                        />
                    </div>
                  

                  : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                },
                
                {
                  tabName: "Table",
                  tabIcon: AssignmentIcon,
                    tabContent: (!audioChartUIsLoading ? 
                      <div>
                        <DataTable
                          title=""
                          columns={topicOverviewTopicSentimentComparisonTableColumn}
                          data={topicOverviewTopicSentimentComparisonTableDataSet}
                          //conditionalRowStyles={conditionalRowStyles}
                          // pagination={true}
                          // paginationPerPage={10}
                          // paginationComponentOptions={{ noRowsPerPage: true }}
                          fixedHeader={true}
                          //fixedHeaderScrollHeight={"445px"}
                          fixedHeaderScrollHeight={"445px"}
                          noHeader={true}
                          />
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicOverviewTopicSentimentComparisonTableDataSet} filename={"Topic Sentiment Comparison.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                      </div>
                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                  
                }
              ]}
            /> 

          </GridItem>

          <GridItem xs={12} sm={12} md={6}>

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="primary"
              
                
                tabs={[
                  {
                    tabName: "Topic Comparison",
                    tabIcon: CompareIcon,
                    tabContent: (!audioChartUIsLoading ? 
                      <div> 
                        {/* style={{ height: 300}} */}
                        <HorizontalBar
                          height={200}
                          data={topicOverviewTopicComparisonDataSet}
                          options={topicOverviewTopicComparisonOption}
                        />
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },

                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!audioChartUIsLoading ? 
                        <div>
                          {topicOverviewTopicComparisonReactTable()}
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                          
                              <CSVLink data={topicOverviewTopicComparisonTableDataSet} filename={"Topic Comparison List.csv"}>
                                <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                  <DownloadIcon className={classes.icons} />
                                    Download CSV 
                                </Button>
                              </CSVLink>

                            </GridItem>

                          </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>



        </GridContainer>



{/* Topic Overview - Wordcloud  */}

        <GridContainer>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="success"
                
                tabs={[
                  {
                    tabName: "Positive Topic List",
                    tabIcon: PositiveIcon,
                    tabContent: (!audioChartUIsLoading ? 
                      <div style={{ height: 500}}>
                        {topicOverViewPositiveWordCloud()}
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!audioChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicOverviewPositiveTopicTableColumn}
                            data={topicOverviewPositiveTopicTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicOverviewPositiveTopicTableDataSet} filename={"Topic Overview - Positive.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          <GridItem xs={12} sm={12} md={6} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="danger"
                
                tabs={[
                  {
                    tabName: "Negative Topic List",
                    tabIcon: NegativeIcon,
                    tabContent: (!audioChartUIsLoading ? 
                      <div style={{ height: 500}}>
                        {topicOverViewNegativeWordCloud()}
                      </div>

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!audioChartUIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicOverviewNegativeTopicTableColumn}
                            data={topicOverviewNegativeTopicTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicOverviewNegativeTopicTableDataSet} filename={"Topic Overview - Negative.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>

          


        </GridContainer>



{/* Topic Insight Filter */}
      <GridContainer>

        <GridItem xs={12}>
          <Button color="github" size="sm" style={{width: "100%"}}> <b>Topic Insight</b> </Button>
        </GridItem>

        </GridContainer>


        <GridContainer>
          <GridItem xs={12}
            sm={12}
            md={12}>

            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <TopicIcon />
                  
                  
                </CardIcon>
                <h4 className={
                  classes.cardIconTitle
                }>
                  <b>Topic List</b>
                </h4>
              </CardHeader>
              <CardBody>
                <FormControl fullWidth
                  className={
                    classes.selectFormControl
                }>
                  <InputLabel htmlFor="topicInsight-select"
                    className={
                      classes.selectLabel
                  }>
                    Select Topic
                  </InputLabel>

                  <Select style={
                      {minWidth: "100%"}
                    }
                    MenuProps={
                      {className: classes.selectMenu}
                    }
                    classes={
                      {select: classes.select}
                    }
                    //multiple
                    autoWidth={false}
                    value={topicInsightSelection}
                    onChange={handleTopicInsight}
                    inputProps={
                      {
                        name: "topicInsightSelect",
                        id: "topicInsight-select"
                      }
                  }>
                    <MenuItem disabled
                      classes={
                        {root: classes.selectMenuItem}
                    }>
                      <b>Single Selection</b>
                    </MenuItem>

                    {
                    topicInsightItems.map((TopicInsightItems, index) => (
                      <MenuItem key={index}
                        classes={
                          {
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }
                        }
                        value={TopicInsightItems}>
                        {TopicInsightItems} </MenuItem>
                    ))
                  } </Select>
                </FormControl>
                
              </CardBody>

            </Card>


            

          </GridItem>
          

          </GridContainer>

          <GridContainer >

            <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

              <Button color="pinterest" onClick={onClickTopicInsight}>
                <RefreshIcon className={classes.icons} />
                  Display Chart
              </Button>
              

            </GridItem>

            </GridContainer>

            <br/>

        {/* Topic Insight Topic Trend */}
          <GridContainer>

          <GridItem xs={12} sm={12} md={12} >

            <CustomTabs
                //title="Tasks:"
                centered={true}
                headerColor="warning"
                
                tabs={[
                  {
                    tabName: "Topic Trend",
                    tabIcon: TrendingUpIcon,
                    tabContent: (!topicInsightIsLoading ? 
                      <div >
                        <Line 
                          height={75} data={topicInsightTopicTrendDataSet}  
                          options={topicInsightTopicTrendOption} /> 
                      </div>
                    

                    : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                  },
                  {
                    tabName: "Table",
                    tabIcon: AssignmentIcon,
                      tabContent: (!topicInsightIsLoading ? 
                        <div>
                          <DataTable
                            title=""
                            columns={topicInsightTopicTrendTableColumn}
                            data={topicInsightTopicTrendTableDataSet}
                            //conditionalRowStyles={conditionalRowStyles}
                            // pagination={true}
                            // paginationPerPage={10}
                            // paginationComponentOptions={{ noRowsPerPage: true }}
                            fixedHeader={true}
                            //fixedHeaderScrollHeight={"445px"}
                            fixedHeaderScrollHeight={"300px"}
                            noHeader={true}
                          /> 
                      <GridContainer>
                      <GridItem 
                        xs={12}
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                    
                        <CSVLink data={topicInsightTopicTrendTableDataSet} filename={"Topic Insight - Topic Trend.csv"}>
                          <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                            <DownloadIcon className={classes.icons} />
                              Download CSV 
                          </Button>
                        </CSVLink>

                      </GridItem>

                    </GridContainer>

                        </div>
                      : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                    
                  }
                ]}
              /> 

          </GridItem>


        </GridContainer>

        {/* Topic Insight Charts */}
            <GridContainer>

              <GridItem xs={12} sm={12} md={4} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="primary"
                    
                    tabs={[
                      {
                        tabName: "Topic Phrase",
                        tabIcon: TopicIcon,
                        tabContent: (!topicInsightIsLoading ? 
                          <div>
                            <HorizontalBar 
                              height={215} data={topicInsightPhraseDataSet}  
                              options={topicInsightPhraseOption} /> 
                          </div>
                        

                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!topicInsightIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={topicInsightPhraseTableColumn}
                                data={topicInsightPhraseTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicInsightPhraseTableDataSet} filename={"Topic Insight - Topic Phrase.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={4} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="success"
                    
                    tabs={[
                      {
                        tabName: "Positive Sentiment",
                        tabIcon: PositiveIcon,
                        tabContent: (!topicInsightIsLoading ? 
                          <div>
                            
                            <HorizontalBar 
                              height={215} data={topicInsightPositiveDataSet} options={topicInsightPositiveOption} /> 

                          </div>
                        

                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!topicInsightIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={topicInsightPositiveTableColumn}
                                data={topicInsightPositiveTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicInsightPositiveTableDataSet} filename={"Topic Insight - Positive Sentiment.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                        
                      }
                    ]}
                  /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={4} >

                <CustomTabs
                    //title="Tasks:"
                    centered={true}
                    headerColor="danger"
                    
                    tabs={[
                      {
                        tabName: "Negative Sentiment",
                        tabIcon: NegativeIcon,
                        tabContent: (!topicInsightIsLoading ? 
                          <div>
                            
                            <HorizontalBar 
                              height={215} data={topicInsightNegativeDataSet} options={topicInsightNegativeOption} /> 

                          </div>
                        

                        : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                      },
                      {
                        tabName: "Table",
                        tabIcon: AssignmentIcon,
                          tabContent: (!topicInsightIsLoading ? 
                            <div>
                              <DataTable
                                title=""
                                columns={topicInsightNegativeTableColumn}
                                data={topicInsightNegativeTableDataSet}
                                //conditionalRowStyles={conditionalRowStyles}
                                // pagination={true}
                                // paginationPerPage={10}
                                // paginationComponentOptions={{ noRowsPerPage: true }}
                                fixedHeader={true}
                                //fixedHeaderScrollHeight={"445px"}
                                fixedHeaderScrollHeight={"300px"}
                                noHeader={true}
                              /> 
                          <GridContainer>
                          <GridItem 
                            xs={12}
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center">
                        
                            <CSVLink data={topicInsightNegativeTableDataSet} filename={"Topic Insight - Negative Sentiment.csv"}>
                              <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                                <DownloadIcon className={classes.icons} />
                                  Download CSV 
                              </Button>
                            </CSVLink>

                          </GridItem>

                        </GridContainer>

                            </div>
                          : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                          
                              }
                            ]}
                          /> 

                      </GridItem>


                  </GridContainer>



{/* Sentiment Overview - Wordcloud  */}
        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Sentiment Overview</b> </Button>
          </GridItem>

          </GridContainer>

          <GridContainer>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="success"
                  
                  tabs={[
                    {
                      tabName: "Positive Sentiment List",
                      tabIcon: PositiveIcon,
                      tabContent: (!audioChartUIsLoading ? 
                        <div style={{ height: 500}}>

                          {sentimentOverviewPositveWordCloud()}

                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!audioChartUIsLoading ?
                          <div>
                            <DataTable
                              title=""
                              columns={sentimentOverviewPositveTableColumn}
                              data={sentimentOverviewPositveTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={sentimentOverviewPositveTableDataSet} filename={"Sentiment Overview - Positive.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink  style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="danger"
                  
                  tabs={[
                    {
                      tabName: "Negative Sentiment List",
                      tabIcon: NegativeIcon,
                      tabContent: (!audioChartUIsLoading ? 
                        <div style={{ height: 500}}>

                          {sentimentOverviewNegativeWordCloud()}

                        </div>

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!audioChartUIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={sentimentOverviewNegativeDataTableColumn}
                              data={sentimentOverviewNegativeDataTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink data={sentimentOverviewNegativeDataTableDataSet} filename={"Sentiment Overview - Negative.csv"}>
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            


          </GridContainer>


{/* Category Detail - Bass Summary  */}

        <GridContainer>

          <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Bass Summary</b> </Button>
          </GridItem>

        </GridContainer>



{/* Category Detail Value Box - Bass */}

        <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <PositiveIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Positive </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryPositiveCount[0]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={"warning"} stats icon>
              <CardIcon color={"warning"}>
                <RemoveIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Neutral </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryNeutralCount[0]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={"danger"} stats icon>
              <CardIcon color={"danger"}>
                <NegativeIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> Negative </p>
              <h3 className={dashboardClasses.cardTitle}>
                {categoryNegativeCount[0]}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color={getColor("Bass")} stats icon>
              <CardIcon color={getColor("Bass")}>
                <StarsIcon />
              </CardIcon>
              <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
              <h3 className={dashboardClasses.cardTitle}>
              {getRating("Bass")}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={dashboardClasses.stats}>

              </div>
            </CardFooter>
          </Card>
        </GridItem>

        </GridContainer>

{/* Category Detail Charts - Bass  */}
        <GridContainer>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="warning"
                  
                  tabs={[
                    {
                      tabName: "Bass Trend",
                      tabIcon: TrendingUpIcon,
                      tabContent: (!audioChartUIsLoading ? 
                        <div>
                          <Line 
                            height={215} 
                            data={categorySummaryBassDataSet}  
                            options={categorySummaryBassOptions} 
                            /> 
                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!audioChartUIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categorySummaryBassTableColumn}
                              data={categorySummaryBassTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"300px"}
                              noHeader={true}
                            /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink 
                            data={categorySummaryBassTableDataSet} 
                            filename={"Category Summary Bass - Trend.csv"}
                            >
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            <GridItem xs={12} sm={12} md={6} >

              <CustomTabs
                  //title="Tasks:"
                  centered={true}
                  headerColor="info"
                  
                  tabs={[
                    {
                      tabName: "Topic List",
                      tabIcon: TopicIcon,
                      tabContent: (!audioChartUIsLoading ? 
                        <div>
                      
                          <HorizontalBar 
                            height={215} 
                            data={categoryDetailBassTopicComparisonDataSet} 
                            options={categoryDetailBassTopicComparisonOption} 
                            /> 

                        </div>
                      

                      : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
                    },
                    {
                      tabName: "Table",
                      tabIcon: AssignmentIcon,
                        tabContent: (!audioChartUIsLoading ? 
                          <div>
                            <DataTable
                              title=""
                              columns={categoryDetailBassTopicComparisonTableColumn}
                              data={categoryDetailBassTopicComparisonTableDataSet}
                              //conditionalRowStyles={conditionalRowStyles}
                              // pagination={true}
                              // paginationPerPage={10}
                              // paginationComponentOptions={{ noRowsPerPage: true }}
                              fixedHeader={true}
                              //fixedHeaderScrollHeight={"445px"}
                              fixedHeaderScrollHeight={"445px"}
                              noHeader={true}
                              /> 
                        <GridContainer>
                        <GridItem 
                          xs={12}
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                      
                          <CSVLink 
                            data={categoryDetailBassTopicComparisonTableDataSet} 
                            filename={"Category Detail Bass - Trend.csv"}
                            >
                            <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                              <DownloadIcon className={classes.icons} />
                                Download CSV 
                            </Button>
                          </CSVLink>

                        </GridItem>

                      </GridContainer>

                          </div>
                        : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

                      
                    }
                  ]}
                /> 

            </GridItem>

            

            </GridContainer>



            {/* Category Detail - Midrange Summary  */}

<GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>Midrange Summary</b> </Button>
</GridItem>

</GridContainer>



{/* Category Detail Value Box - Midrange */}

<GridContainer>
<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color="success" stats icon>
    <CardIcon color="success">
      <PositiveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Positive </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryPositiveCount[1]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"warning"} stats icon>
    <CardIcon color={"warning"}>
      <RemoveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Neutral </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNeutralCount[1]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"danger"} stats icon>
    <CardIcon color={"danger"}>
      <NegativeIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Negative </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNegativeCount[1]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={getColor("Midrange")} stats icon>
    <CardIcon color={getColor("Midrange")}>
      <StarsIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
    <h3 className={dashboardClasses.cardTitle}>
    {getRating("Midrange")}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

</GridContainer>

{/* Category Detail Charts - Midrange  */}
<GridContainer>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="warning"
        
        tabs={[
          {
            tabName: "Midrange Trend",
            tabIcon: TrendingUpIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
                <Line 
                  height={215} 
                  data={categorySummaryMidrangeDataSet}  
                  options={categorySummaryMidrangeOptions} 
                  /> 
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categorySummaryMidrangeTableColumn}
                    data={categorySummaryMidrangeTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categorySummaryMidrangeTableDataSet} 
                  filename={"Category Summary Midrange - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="info"
        
        tabs={[
          {
            tabName: "Topic List",
            tabIcon: TopicIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
            
                <HorizontalBar 
                  height={215} 
                  data={categoryDetailMidrangeTopicComparisonDataSet} 
                  options={categoryDetailMidrangeTopicComparisonOption} 
                  /> 

              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categoryDetailMidrangeTopicComparisonTableColumn}
                    data={categoryDetailMidrangeTopicComparisonTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"445px"}
                    noHeader={true}
                    /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categoryDetailMidrangeTopicComparisonTableDataSet} 
                  filename={"Category Detail Midrange - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  

  </GridContainer>

{/* Category Detail - Treble Summary  */}

<GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>Treble Summary</b> </Button>
</GridItem>

</GridContainer>



{/* Category Detail Value Box - Treble */}

<GridContainer>
<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color="success" stats icon>
    <CardIcon color="success">
      <PositiveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Positive </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryPositiveCount[2]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"warning"} stats icon>
    <CardIcon color={"warning"}>
      <RemoveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Neutral </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNeutralCount[2]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"danger"} stats icon>
    <CardIcon color={"danger"}>
      <NegativeIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Negative </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNegativeCount[2]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={getColor("Treble")} stats icon>
    <CardIcon color={getColor("Treble")}>
      <StarsIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
    <h3 className={dashboardClasses.cardTitle}>
    {getRating("Treble")}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

</GridContainer>

{/* Category Detail Charts - Treble  */}
<GridContainer>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="warning"
        
        tabs={[
          {
            tabName: "Treble Trend",
            tabIcon: TrendingUpIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
                <Line 
                  height={215} 
                  data={categorySummaryTrebleDataSet}  
                  options={categorySummaryTrebleOptions} 
                  /> 
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categorySummaryTrebleTableColumn}
                    data={categorySummaryTrebleTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categorySummaryTrebleTableDataSet} 
                  filename={"Category Summary Treble - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="info"
        
        tabs={[
          {
            tabName: "Topic List",
            tabIcon: TopicIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
            
                <HorizontalBar 
                  height={215} 
                  data={categoryDetailTrebleTopicComparisonDataSet} 
                  options={categoryDetailTrebleTopicComparisonOption} 
                  /> 

              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categoryDetailTrebleTopicComparisonTableColumn}
                    data={categoryDetailTrebleTopicComparisonTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"445px"}
                    noHeader={true}
                    /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categoryDetailTrebleTopicComparisonTableDataSet} 
                  filename={"Category Detail Treble - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  

  </GridContainer>


  {/* Category Detail - SoundStage Summary  */}

<GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>SoundStage Summary</b> </Button>
</GridItem>

</GridContainer>



{/* Category Detail Value Box - SoundStage */}

<GridContainer>
<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color="success" stats icon>
    <CardIcon color="success">
      <PositiveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Positive </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryPositiveCount[3]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"warning"} stats icon>
    <CardIcon color={"warning"}>
      <RemoveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Neutral </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNeutralCount[3]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"danger"} stats icon>
    <CardIcon color={"danger"}>
      <NegativeIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Negative </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNegativeCount[3]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={getColor("SoundStage")} stats icon>
    <CardIcon color={getColor("SoundStage")}>
      <StarsIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
    <h3 className={dashboardClasses.cardTitle}>
    {getRating("SoundStage")}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

</GridContainer>

{/* Category Detail Charts - SoundStage  */}
<GridContainer>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="warning"
        
        tabs={[
          {
            tabName: "SoundStage Trend",
            tabIcon: TrendingUpIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
                <Line 
                  height={215} 
                  data={categorySummarySoundStageDataSet}  
                  options={categorySummarySoundStageOptions} 
                  /> 
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categorySummarySoundStageTableColumn}
                    data={categorySummarySoundStageTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categorySummarySoundStageTableDataSet} 
                  filename={"Category Summary SoundStage - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="info"
        
        tabs={[
          {
            tabName: "Topic List",
            tabIcon: TopicIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
            
                <HorizontalBar 
                  height={215} 
                  data={categoryDetailSoundStageTopicComparisonDataSet} 
                  options={categoryDetailSoundStageTopicComparisonOption} 
                  /> 

              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categoryDetailSoundStageTopicComparisonTableColumn}
                    data={categoryDetailSoundStageTopicComparisonTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"445px"}
                    noHeader={true}
                    /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categoryDetailSoundStageTopicComparisonTableDataSet} 
                  filename={"Category Detail SoundStage - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  

  </GridContainer>


  {/* Category Detail - Imaging Summary  */}

<GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>Imaging Summary</b> </Button>
</GridItem>

</GridContainer>



{/* Category Detail Value Box - Imaging */}

<GridContainer>
<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color="success" stats icon>
    <CardIcon color="success">
      <PositiveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Positive </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryPositiveCount[4]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"warning"} stats icon>
    <CardIcon color={"warning"}>
      <RemoveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Neutral </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNeutralCount[4]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"danger"} stats icon>
    <CardIcon color={"danger"}>
      <NegativeIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Negative </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNegativeCount[4]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={getColor("Imaging")} stats icon>
    <CardIcon color={getColor("Imaging")}>
      <StarsIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
    <h3 className={dashboardClasses.cardTitle}>
    {getRating("Imaging")}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

</GridContainer>

{/* Category Detail Charts - Imaging  */}
<GridContainer>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="warning"
        
        tabs={[
          {
            tabName: "Imaging Trend",
            tabIcon: TrendingUpIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
                <Line 
                  height={215} 
                  data={categorySummaryImagingDataSet}  
                  options={categorySummaryImagingOptions} 
                  /> 
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categorySummaryImagingTableColumn}
                    data={categorySummaryImagingTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categorySummaryImagingTableDataSet} 
                  filename={"Category Summary Imaging - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="info"
        
        tabs={[
          {
            tabName: "Topic List",
            tabIcon: TopicIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
            
                <HorizontalBar 
                  height={215} 
                  data={categoryDetailImagingTopicComparisonDataSet} 
                  options={categoryDetailImagingTopicComparisonOption} 
                  /> 

              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categoryDetailImagingTopicComparisonTableColumn}
                    data={categoryDetailImagingTopicComparisonTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"445px"}
                    noHeader={true}
                    /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categoryDetailImagingTopicComparisonTableDataSet} 
                  filename={"Category Detail Imaging - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  

  </GridContainer>


  {/* Category Detail - Distortion Summary  */}

<GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>Distortion Summary</b> </Button>
</GridItem>

</GridContainer>



{/* Category Detail Value Box - Distortion */}

<GridContainer>
<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color="success" stats icon>
    <CardIcon color="success">
      <PositiveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Positive </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryPositiveCount[5]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"warning"} stats icon>
    <CardIcon color={"warning"}>
      <RemoveIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Neutral </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNeutralCount[5]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={"danger"} stats icon>
    <CardIcon color={"danger"}>
      <NegativeIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> Negative </p>
    <h3 className={dashboardClasses.cardTitle}>
      {categoryNegativeCount[5]}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

<GridItem xs={12} sm={6} md={3}>
<Card>
  <CardHeader color={getColor("Distortion")} stats icon>
    <CardIcon color={getColor("Distortion")}>
      <StarsIcon />
    </CardIcon>
    <p className={dashboardClasses.cardCategory}> SMAT Rating </p>
    <h3 className={dashboardClasses.cardTitle}>
    {getRating("Distortion")}
    </h3>
  </CardHeader>
  <CardFooter stats>
    <div className={dashboardClasses.stats}>

    </div>
  </CardFooter>
</Card>
</GridItem>

</GridContainer>

{/* Category Detail Charts - Distortion  */}
<GridContainer>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="warning"
        
        tabs={[
          {
            tabName: "Distortion Trend",
            tabIcon: TrendingUpIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
                <Line 
                  height={215} 
                  data={categorySummaryDistortionDataSet}  
                  options={categorySummaryDistortionOptions} 
                  /> 
              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categorySummaryDistortionTableColumn}
                    data={categorySummaryDistortionTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"300px"}
                    noHeader={true}
                  /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categorySummaryDistortionTableDataSet} 
                  filename={"Category Summary Distortion - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  <GridItem xs={12} sm={12} md={6} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="info"
        
        tabs={[
          {
            tabName: "Topic List",
            tabIcon: TopicIcon,
            tabContent: (!audioChartUIsLoading ? 
              <div>
            
                <HorizontalBar 
                  height={215} 
                  data={categoryDetailDistortionTopicComparisonDataSet} 
                  options={categoryDetailDistortionTopicComparisonOption} 
                  /> 

              </div>
            

            : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )
          },
          {
            tabName: "Table",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  <DataTable
                    title=""
                    columns={categoryDetailDistortionTopicComparisonTableColumn}
                    data={categoryDetailDistortionTopicComparisonTableDataSet}
                    //conditionalRowStyles={conditionalRowStyles}
                    // pagination={true}
                    // paginationPerPage={10}
                    // paginationComponentOptions={{ noRowsPerPage: true }}
                    fixedHeader={true}
                    //fixedHeaderScrollHeight={"445px"}
                    fixedHeaderScrollHeight={"445px"}
                    noHeader={true}
                    /> 
              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
            
                <CSVLink 
                  data={categoryDetailDistortionTopicComparisonTableDataSet} 
                  filename={"Category Detail Distortion - Trend.csv"}
                  >
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : <CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

  

  </GridContainer>




  {/*View Individual Sentence */}

  <GridContainer>

<GridItem xs={12}>
  <Button color="github" size="sm" style={{width: "100%"}}> <b>View Individual Sentence</b> </Button>
</GridItem>

</GridContainer>


  <GridContainer>

  <GridItem xs={12} sm={12} md={12} >

    <CustomTabs
        //title="Tasks:"
        centered={true}
        headerColor="primary"
        
        tabs={[
          {
            tabName: "View Individual Sentence",
            tabIcon: AssignmentIcon,
              tabContent: (!audioChartUIsLoading ? 
                <div>
                  
                  <div style={{ maxWidth: '100%' }}>
                          <MaterialTable
                            columns={viewIndividualSentenceTableColumn}
                            data={viewIndividualSentenceDataTableDataSet}
                            title="SMAT Review"
                            options={{
                              exportButton: true,
                              exportAllData: true,
                              filtering: true,
                              showTitle: false
                            }}

 
                          />
                    </div>

              <GridContainer>
              <GridItem 
                xs={12}
                container
                direction="row"
                justify="flex-end"
                alignItems="center">

                <CSVLink data={viewIndividualSentenceData} filename={"View Individual Sentence.csv"}>
                  <Button color="warning" size="sm" style={{marginTop: "10%"}}> 
                    <DownloadIcon className={classes.icons} />
                      Download CSV 
                  </Button>
                </CSVLink>

              </GridItem>

            </GridContainer>

                </div>
              : < CircularProgress disableShrink style={{marginTop: "25%", marginBottom: "40%", marginLeft: '45%'}} /> )

            
          }
        ]}
      /> 

  </GridItem>

</GridContainer>






    </div> </div> : <LinearProgressWithLabel value={progress} style={{textAlign: 'center'}}/>
    )
  );
}
