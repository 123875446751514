/*eslint-disable*/

import React from "react";


// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"

// 3rd Party Component
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv"; // 3rd Party CSV Download
import MaterialTable from 'material-table' // 3rd Party Table Component
//import MaterialTable from '@material-table/core' // 3rd Party Table Component

// @material-ui/icons
import CardTravel from "@material-ui/icons/CardTravel";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CreateIcon from "@material-ui/icons/Create";
import BuildIcon from "@material-ui/icons/Build";
import SendIcon from "@material-ui/icons/Send";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DownloadIcon from "@material-ui/icons/GetApp";
import EmailIcon from "@material-ui/icons/Email";
import BugReportIcon from "@material-ui/icons/BugReport";
import MessageIcon from "@material-ui/icons/Message";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import TimelineComponent from "components/Timeline/Timeline.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";


import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStylesSweetAlert = makeStyles(stylesSweetAlert);
const useStyles = makeStyles(styles);
const useStylesDashboard = makeStyles(dashboardStyle);


export default function ReleaseNotes() {

  const classesSweetAlert = useStylesSweetAlert();
  const dashboardClasses = useStylesDashboard();
  const classes = useStyles();


  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Release Notes"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });



  }, [])



  // Release Note Data
  const [releaseNotesRaw, setReleaseNotesRaw] = React.useState([]);

  // Alert/Modal
  const [alert, setAlert] = React.useState(null);

  // Loading State 
  const [viewTableIsLoading, setViewTableIsLoading] = React.useState(false);

   // Feature Request
   const [featureEmail, setFeatureEmail] = React.useState('');
   const [featureDescription, setFeatureDescription] = React.useState('');

   // Bug Report
   const [bugEmail, setBugEmail] = React.useState('');
   const [bugDescription, setBugDescription] = React.useState('');



   // Get Data from SMAT REST API
   React.useEffect(() => {
    const axios = require('axios');

    axios.get(SMATrestAPI.releaseNotes[0].releaseNotesData).then(function (response) { 
      // handle success
      setReleaseNotesRaw(response.data.releaseNotesRaw);

    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }, [])



  const onSubmitData1 = (event) => {
    event.preventDefault();

    if((featureEmail === "") || (featureDescription === "")) {

      failAlert();

    }else{

      // Send Data to REST API
      let restGETurl1 = `${"email="}${featureEmail}${"&description="}${featureDescription}`;
      restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
      restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

      const axios = require('axios');
      axios.get(`${SMATrestAPI.releaseNotes[0].featureRequest}${restGETurl1}`).then(function (response) { 
        // handle success

        // Confirmation
        successAlertNewFeature();

        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed

      });

    }

    


  };


  const onSubmitData2 = (event) => {
    event.preventDefault();


    if((bugEmail === "") || (bugDescription === "")) {

      failAlert();

    }else{

      // Send Data to REST API
      let restGETurl1 = `${"email="}${bugEmail}${"&description="}${bugDescription}`;
      restGETurl1 = restGETurl1.replaceAll(",", '%2C'); // replace comma for url
      restGETurl1 = restGETurl1.replaceAll(" ", '%20'); // empty space for url

      const axios = require('axios');
      axios.get(`${SMATrestAPI.releaseNotes[0].bugReport}${restGETurl1}`).then(function (response) { 
        // handle success

        // Confirmation
        successAlertBugReport();

        
      }).catch(function (error) { 
        // handle error
        console.log(error);
      }).then(function () { 
        // always executed

      });

    }


  };



  const successAlertNewFeature = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Request Submitted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
      >
      </SweetAlert>
    );
  };


  const successAlertBugReport = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Bug Submitted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.success}
      >
      </SweetAlert>
    );
  };

  const failAlert = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Missing Information"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesSweetAlert.button + " " + classesSweetAlert.danger}
      >
      </SweetAlert>
    );
  };


  const hideAlert = () => {
    setAlert(null);
  };


  // Value Box
  let bugFixData = releaseNotesRaw.filter(data => data.Category === 'Bug Fix');
  let bugFixCount = bugFixData.length;

  let newFeatureData = releaseNotesRaw.filter(data => data.Category === 'New Feature');
  let newFeatureCount = newFeatureData.length;

  let updateUiData = releaseNotesRaw.filter(data => data.Category === 'Update UI');
  let updateUiCount = updateUiData.length;


  // Model table - Table using material-table

  const releaseNoteTableColumn = [
    {
      title: 'Date',
      field: 'date'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      title: 'Version',
      field: 'version'
    },
    {
      title: 'Body',
      field: 'body'
    }
  ];



  const releaseNoteTableDataSet = releaseNotesRaw.map((data) => {
    return ({
      //id: data.id,
      date: data.Date,
      version: data['Version'],
      category: data['Category'],
      body: data['Body']
    })
  });





  return (
    <div>

      <GridContainer>
        <GridItem xs={6}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Feature Request</b> </Button>
        </GridItem>

        <GridItem xs={6}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Bug Submission</b> </Button>
        </GridItem>

      </GridContainer>

{/* Feature Request */}
      <GridContainer>
   
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}><strong>Feature Request</strong></h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* onSubmit={e => { e.preventDefault()  */}
              <form  id='newFeature-form' onSubmit={onSubmitData1}> 
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Email</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="featureEmailID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "John.Doe@sony.com",
                        onBlur: event => {setFeatureEmail(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><EmailIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Description</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="featureRequestID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Example: Add drill down for all charts within the competitor tab",
                        onBlur: event => {setFeatureDescription(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><MessageIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>



              </form>
            </CardBody>

            <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                <Button color="pinterest" form='newFeature-form' type="submit">
                  <SendIcon className={classes.icons} />
                    Submit
                </Button>
                
              

              </GridItem>

            <br/>
          </Card>
        </GridItem>

{/* Bug Report */}
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}><strong>Bug Submission</strong></h4>
              </CardText>
            </CardHeader>
            <CardBody>
              {/* onSubmit={e => { e.preventDefault()  */}
              <form  id='bugReport-form' onSubmit={onSubmitData2}> 
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Email</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="bugEmailID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "John.Doe@sony.com",
                        onBlur: event => {setBugEmail(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><EmailIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>


                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal} style={{color: "black", fontSize: "14px"}}>
                      <strong>Description</strong>
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10}>
                    <CustomInput
                      id="bugReportID"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "Example: The Word Cloud chart displays an error for WH1000XM4",
                        onBlur: event => {setBugDescription(event.target.value)},
                        endAdornment: (<InputAdornment position="end"><MessageIcon/></InputAdornment>)
                      }}
                    />
                  </GridItem>
                </GridContainer>



              </form>
            </CardBody>

            <GridItem 
                  xs={12}
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                <Button color="pinterest" form='bugReport-form' type="submit">
                  <SendIcon className={classes.icons} />
                    Submit
                </Button>
                
              

              </GridItem>

            <br/>
          </Card>
        </GridItem>

        {/* Sweet Alert  */}
        {alert}
        
      </GridContainer>




{/* Release Notes */}

      <GridContainer>
        <GridItem xs={12}>
            <Button color="github" size="sm" style={{width: "100%"}}> <b>Release Notes</b> </Button>
        </GridItem>
      </GridContainer>


{/* Value Box */}
      <GridContainer>

        <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <CreateIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}> <strong>Update UI Count</strong> </p>
                <h3 className={dashboardClasses.cardTitle}>
                  {bugFixCount}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <AddBoxIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}> <strong>New Feature Count</strong> </p>
                <h3 className={dashboardClasses.cardTitle}>
                  {newFeatureCount }
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>


          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <BuildIcon />
                </CardIcon>
                <p className={dashboardClasses.cardCategory}> <strong>Bug Fix Count</strong> </p>
                <h3 className={dashboardClasses.cardTitle}>
                  {bugFixCount}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={dashboardClasses.stats}>

                </div>
              </CardFooter>
            </Card>
          </GridItem>


      </GridContainer>


{/* Table */}

      <GridContainer>
   
      <GridItem xs={12} sm={12} md={12} >

        <CustomTabs
            //title="Tasks:"
            centered={true}
            headerColor="primary"
            
            tabs={[
              {
                tabName: "Table",
                tabIcon: AssignmentIcon,
                  tabContent: (
                    <div>
                                
                                <div style={{ maxWidth: '100%' }}>
                                        <MaterialTable
                                          columns={releaseNoteTableColumn}
                                          data={releaseNoteTableDataSet}
                                          title="Model List"
                                          options={{
                                            exportButton: true,
                                            exportAllData: true,
                                            filtering: true,
                                            showTitle: false,
                                            searchAutoFocus: true,
                                            pageSize: 10
                                          }}
         
                                        />
                                  </div>



                            <br/>
                            <GridContainer>
                            <GridItem 
                              xs={12}
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center">
                       
                              <CSVLink data={releaseNotesRaw} filename={"Release Notes.csv"}>
                                  <Button color="warning"> 
                                    <DownloadIcon className={classes.icons} />
                                      Download CSV 
                                  </Button>

                              </CSVLink>



                            </GridItem>


                          </GridContainer>

                              </div>
                )

                
              }
            ]}
          /> 

        </GridItem>
        
      </GridContainer>




    </div>
  );
}
