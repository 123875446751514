/*eslint-disable*/

import React from "react";
import { useHistory } from "react-router-dom";

// SMAT REST API
import SMATrestAPI from "../../services/SMATrestAPI.json"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import StarRatingDashboardIcon from "@material-ui/icons/TrendingUp";
import StarRatingAnalyticIcon from "@material-ui/icons/StarRate";
import CustomCategoriesIcon from "@material-ui/icons/Category";

import AudioRatingAnalyticIcon from "@material-ui/icons/VolumeUp";
import CompetitorAnalyticIcon from "@material-ui/icons/Compare";
import AppRatingAnalyticIcon from "@material-ui/icons/PhoneIphone";
import CreateReportIcon from "@material-ui/icons/Mail";
import NewModelRequestIcon from "@material-ui/icons/AddBox";
import ReleaseNotesIcon from "@material-ui/icons/Note";
import SocialAnalyticIcon from "@material-ui/icons/Forum";
import TVratingAnalyticIcon from "@material-ui/icons/Tv";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";


import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";



const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px"
    }
  },
  iconRose: {
    color: roseColor
  },
  marginTop30: {
    marginTop: "30px"
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px"
    }
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999"
  }
};

const useStyles = makeStyles(styles);



export default function Home() {


  // SMAT Log
  React.useEffect(() => {
    let restGETurl = `${"smatCategory="}${"Home"}`;
    restGETurl = restGETurl.replaceAll(",", '%2C'); // replace comma for url
    restGETurl = restGETurl.replaceAll(" ", '%20'); // empty space for url

    const axios = require('axios');
    axios.get(`${SMATrestAPI.smatLog[0].pageLog}${restGETurl}`).then(function (response) { 


    }).catch(function (error) { 
      // handle error
      console.log(error);
    }).then(function () { 
      // always executed
    });

  }, [])

  const classes = useStyles();
  const history = useHistory();

  const onClickStarRatingDashboard = () => {

    history.push('/admin/star-rating-dashboard');

  }

  const onClickStarRatingAnalytics = () => {

    history.push('/admin/star-rating-analytic');

  }

  const onClickCustomCategories = () => {
    
    history.push('/admin/custom-categories');

  }

  const onClickCompetitorAnalytics = () => {

    history.push('/admin/competitor-analytic');

  }

  const onClickAppRatingAnalytic = () => {
    
    history.push('/admin/app-rating-analytic');

  }

  const onClickCreateReport = () => {

    history.push('/admin/create-report');

  }

  const onClickNewModelRequest = () => {
    
    history.push('/admin/new-model-request');

  }

  const onClickReleaseNotes = () => {
    
    history.push('/admin/release-notes');

  }

  // const onClickSocialAnalytic = () => {
    
  //   history.push('/admin/social-analytic');

  // }

  const onClickCompetitorAnalyticCN = () => {
    
    history.push('/admin/competitors-analytic-cn');

  }

  const onClickAudioRatingAnalytic = () => {
    
    history.push('/admin/audio-rating-analytic');

  }


  const onClickTvRatingAnalytic = () => {
    
    history.push('/admin/tv-rating-cn');

  }


  return (
    <div>

      <GridContainer>
            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>Star Rating Dashboard</strong> </h5>
                  <div className={classes.icon}>
                    <StarRatingDashboardIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Trend report for price, website rating, rating count and SMAT Rating for various models
                  </p>

                  <Button round color="rose" onClick={onClickStarRatingDashboard}>
                    View Page
                  </Button>

                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>Star Rating Analytics</strong> </h5>
                  <div className={classes.icon}>
                    <StarRatingAnalyticIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Detail report to understand the positive and negative of a product as well as rating trend
                  </p>

                  <Button round color="rose" onClick={onClickStarRatingAnalytics}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>


            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}> <strong>TV Rating Analytic - CN</strong> </h5>
                  <div className={classes.icon}>
                    <TVratingAnalyticIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Detail report related to TV using native Chinese analytic for the China region
                  </p>

                  <Button round color="rose" onClick={onClickTvRatingAnalytic}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>Custom Categories</strong></h5>
                  <div className={classes.icon}>
                    <CustomCategoriesIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Contains a custom report for multiple topics allowing the user to create custom categories
                  </p>

                  <Button round color="rose" onClick={onClickCustomCategories}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>Audio Rating Analytic</strong></h5>
                  <div className={classes.icon}>
                    <AudioRatingAnalyticIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Detail report to understand the positive and negative topics related to Audio
                  </p>

                  <Button round color="rose" onClick={onClickAudioRatingAnalytic}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>Competitor Analytics</strong></h5>
                  <div className={classes.icon}>
                    <CompetitorAnalyticIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    A side by side comparison with up to 6 model selections including rating and frequent topics
                  </p>

                  <Button round color="rose" onClick={onClickCompetitorAnalytics}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>


            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>App Rating Analytic</strong></h5>
                  <div className={classes.icon}>
                    <AppRatingAnalyticIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Obtain rating trend by version with positive and negative topics for software on iOS and Android
                  </p>

                  <Button round color="rose" onClick={onClickAppRatingAnalytic}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={3}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>Competitor Analytic - CN</strong></h5>
                  <div className={classes.icon}>
                    <CompetitorAnalyticIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    A side by side comparison with up to 3 model selections including rating and frequent topics
                  </p>

                  <Button round color="rose" onClick={onClickCompetitorAnalyticCN}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            
           
          </GridContainer>



          <GridContainer>

          <GridItem xs={12} sm={6} lg={4}>
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>Create Report</strong></h5>
                  <div className={classes.icon}>
                    <CreateReportIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Allows user to create a customize HTML report that can be email to specific individuals
                  </p>

                  <Button round color="rose" onClick={onClickCreateReport}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>


          <GridItem xs={12} sm={6} lg={4}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>New Model Request</strong></h5>
                  <div className={classes.icon}>
                    <NewModelRequestIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Request new models to SMAT and understand the review date for each specific model
                  </p>

                  <Button round color="rose" onClick={onClickNewModelRequest}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} lg={4}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Card pricing>
                <CardBody pricing>
                  <h5 className={classes.cardCategory}><strong>Release Notes</strong></h5>
                  <div className={classes.icon}>
                    <ReleaseNotesIcon className={classes.iconRose} />
                  </div>
                    <br/>
                  <p className={classes.cardDescription}>
                    Latest updates in terms of features and design implementation and submit new request
                  </p>

                  <Button round color="rose" onClick={onClickReleaseNotes}>
                    View Page
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          
           
          </GridContainer>



    </div>
  );
}
